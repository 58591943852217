<div class="container-fluid">
    <div class="content">
        <div class="m-4">
            <div class="me-auto">
                <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

            </div>
            <div class="d-flex gap-2 mb-2">
                <button (click)="closeForm()" type="button" class="btn btn-custom">Cancel</button>
                <app-button-loader (click)="onSaved()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="isSaving">
                </app-button-loader>
            </div>
            <div class="card">
                <div class="card-body m-2">
                    <form [formGroup]="batchForm" (submit)="onSaved()">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="batch_no" class="form-label">Batch No.<font color="red">*</font>
                                        </label>
                                        <input type="text" id="batch_no" class="form-control" formControlName="batch_no"
                                            [ngClass]="{ 'is-invalid': batch_no?.invalid && saved}" readonly>
                                        <div class="invalid-feedback"
                                            *ngIf="batch_no?.invalid || ((batch_no?.dirty || batch_no?.touched) && saved)">
                                            <div *ngIf="batch_no?.errors?.required">{{requiredMessage}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="start_date" class="form-label">Start Date<font color="red">*
                                            </font></label>
                                        <my-date-picker [options]="datePickerOptions" class="datepicker"
                                            formControlName="start_date"
                                            [ngClass]="{ 'is-invalid': start_date?.invalid && saved }"
                                            placeholder="DD/MM/YYYY">
                                        </my-date-picker>
                                        <div class="invalid-feedback"
                                            *ngIf="start_date?.invalid || ((start_date?.dirty || start_date?.touched) && saved)">
                                            <div *ngIf="start_date?.errors?.required">{{requiredMessage}}</div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="end_date" class="form-label">End Date<font color="red">*
                                            </font></label>
                                        <my-date-picker [options]="datePickerOptions" class="datepicker"
                                            formControlName="end_date"
                                            [ngClass]="{ 'is-invalid': end_date?.invalid && saved }"
                                            placeholder="DD/MM/YYYY">
                                        </my-date-picker>
                                        <div class="invalid-feedback"
                                            *ngIf="end_date?.invalid || ((end_date?.dirty || end_date?.touched) && saved)">
                                            <div *ngIf="end_date?.errors?.required">{{requiredMessage}}</div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="time" class="form-label">Time<font color="red">*
                                            </font></label>
                                        <input type="text" id="time" class="form-control" formControlName="time"
                                            [ngClass]="{ 'is-invalid': time?.invalid && saved}">
                                        <div class="invalid-feedback"
                                            *ngIf="time?.invalid || ((time?.dirty || time?.touched) && saved)">
                                            <div *ngIf="time?.errors?.required">{{requiredMessage}}</div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <label for="batch_type" class="form-label">Type<font color="red">*</font>
                                        </label>
                                        <select id="batch_type" class="form-select" formControlName="batch_type"
                                            [ngClass]="{ 'is-invalid': batch_type?.invalid && saved }">
                                            <option value="" disabled selected>Select Type</option>
                                            <option value="Summer Swim">Summer Swim</option>
                                            <option value="Special Swim">Special Swim</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="batch_type?.invalid || ((batch_type?.dirty || batch_type?.touched) && saved)">
                                            <div *ngIf="batch_type?.errors?.required">{{requiredMessage}}</div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <label for="remark" class="form-label">Remark</label>
                                        <textarea id="remark" class="form-control" formControlName="remark"
                                            rows="4"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>