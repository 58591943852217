import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup
} from '@angular/forms';
import { Router } from '@angular/router';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss']
})

export class DomainComponent implements OnInit {
  form: FormGroup;
  selectedDomain: any;
  _showloading = 'Loading ...';

  @Input() domains: any;

  @Output('domainClick')
  emitDomain: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, public kunyekService: KunyekService, private _router: Router,
    private messageService: MessageService) {
    this.form = this.formBuilder.group({
      domains: ['']
    });
    this._showloading = 'Loading ...';
    // synchronous domains
    // this.getDomain();

  }


  ngOnInit(): void {
    if (this.domains) {
      this.selectedDomain = this.domains[0];
      this.emitDomain.emit(this.selectedDomain);
    }
  }
  ngAfterViewInit() {

  }
  chooseDomain(domain: any) {
    this.selectedDomain = domain;
  }

  ok() {
    if (this.selectedDomain) {
      this.emitDomain.emit(this.selectedDomain);
    }
    else {
      this.messageService.openSnackBar("Please choose a domain", "warn");
    }
  }

}
