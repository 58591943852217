import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { OfficeChooseRanksComponent } from '../office-choose-ranks/office-choose-ranks.component';
import { MatDialog } from '@angular/material/dialog';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-requisition-roles',
  templateUrl: './requisition-roles.component.html',
  styleUrls: ['./requisition-roles.component.scss'],
})
export class RequisitionRolesComponent implements OnInit, OnDestroy {
  saveLoading: boolean = false;
  gettingTypes: boolean = false;

  requestTypeList: any = [];
  approveTypeList: any = [];

  orgid: string = '';

  organizations: any = [];

  subs = new SubSink();

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {
    // this.route.data.subscribe((data) => {
    //   if(data.role == 'admin') {
    //     this.isAdmin = true;
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
    // } else {
    //   this.allinoneService.isOfficeView = true;
    // }
    // })
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;

    this.getTypes();
  }

  getTypes() {
    this.gettingTypes = true;
    const data = {
      orgid: this.orgid,
    };
    this.subs.sink = this.kunyekService.getRequisitionRole(data).subscribe(
      (res: any) => {
        if ((res.returncode = '300')) {
          this.requestTypeList = res.requestdatalist;
          this.approveTypeList = res.accessdatalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingTypes = false;
      },
      (err) => {
        this.gettingTypes = false;
      }
    );
  }

  chooseType(type: string, event: Event) {
    // event.stopPropagation();
    const dialog = this.dialog.open(OfficeChooseRanksComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        typelist:
          type == 'request' ? this.requestTypeList : this.approveTypeList,
        orgid: this.orgid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (type == 'request') {
          this.requestTypeList = dialogResult.data.filter(
            (data: { checked: boolean }) => {
              return data.checked == true;
            }
          );
        } else {
          this.approveTypeList = dialogResult.data.filter(
            (data: { checked: boolean }) => {
              return data.checked == true;
            }
          );
        }
      }
    });
  }

  remove(datalist: any[], index: number) {
    datalist.splice(index, 1);
  }

  saveTypes() {
    this.saveLoading = true;
    var finalRequestTypeList: any = [];
    var finalApproveTypeList: any = [];
    this.approveTypeList.map((x: any) => {
      finalApproveTypeList.push(x.id);
    });
    this.requestTypeList.map((x: any) => {
      finalRequestTypeList.push(x.id);
    });
    const data = {
      orgid: this.orgid,
      requestrole: finalRequestTypeList,
      accessrole: finalApproveTypeList,
    };
    this.subs.sink = this.kunyekService.updateRequisitionRole(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.messageService.openSnackBar('Saved successfully.', 'success');
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }
}
