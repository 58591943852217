<section class="text-sm-start">
  <ng-container *ngIf="qrError">
    <div class="center mb-4">
      <div>
        <img
          src="../../../assets/images/wrongurl.jpg"
          alt=""
          style="height: 250px; width: auto"
        />
      </div>
      <div>
        <h1 style="font-weight: normal;">Hmm.. This address doesn't look right.</h1>
        <span style="font-weight: bold"
          >Please check that the URL is correct and try again.</span
        >
      </div>
    </div>
  </ng-container>
  <div *ngIf="!qrError">
    <div class="center mb-4">
      <!-- <div *ngIf="!showRefresh" style="height: 200px;width: 364px;">
                <div class="spinner-border" style="margin-top: 85px;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div> -->
      <div
        *ngIf="showRefresh"
        style="height: 200px; width: 364px"
        (click)="refreshQr()"
      >
        <div
          style="padding-top: 85px; font-size: 30px; cursor: pointer"
          role="status"
        >
          <!-- <span class="visually-hidden">Loading...</span> -->
          <!-- <mat-icon>refresh</mat-icon> -->
          <i class="fa fa-refresh"></i>
        </div>
      </div>
      <ngx-qrcode
        *ngIf="!showRefresh"
        qrc-element-type="canvas"
        [elementType]="elementType"
        [errorCorrectionLevel]="correctionLevel"
        [value]="value"
      ></ngx-qrcode>
    </div>
    <div class="center title mb-3">
      <!-- Get location from {{allinoneService.appname}} by QR Code -->
      Scan QR to Get Location
    </div>
    <ol class="list">
      <li>Open {{ allinoneService.mobileappname }} in your phone</li>
      <li>Go to <strong>More > QR Scan</strong></li>
      <li>Point your phone at this screen to get location</li>
    </ol>
    <!-- <div class="center mt-5">
            <button type="button" class="btn btn-light logintype" (click)="phLogin()">SIGN IN BY EMAIL OR PHONE NUMBER</button>
        </div> -->
  </div>
</section>
