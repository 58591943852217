<div class="fix">
  <header class="title-container mb-3">
    <h2 class="title">Questions ( {{ currentIndex + 1 }} of {{ total }})</h2>
    <div class="close-btn">
      <button mat-icon-button [mat-dialog-close]="answers">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </header>
  <div
    *ngIf="isloading"
    class="d-flex justify-content-center align-items-center"
    style="min-height: 300px"
  >
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div *ngIf="!isloading">
    <section mat-dialog-content>
      <div class="mt-5">
        <span class="form-control mb-3 py-2">{{ question.description }}</span>
        <div>
          <label for="" class="form-label">Description</label>
          <textarea
            class="form-control"
            col="30"
            [(ngModel)]="answer.description"
          ></textarea>
        </div>
      </div>
      <div class="mt-3 mb-3">
        <input
          style="display: none; cursor: pointer"
          type="file"
          (change)="onFileSelect($event)"
          #imageinput
          multiple
        />
        <button class="btn btn-custom" (click)="imageinput.click()">
          Attachments
        </button>
      </div>
      <div class="d-flex flex-column gap-3">
        <div *ngFor="let ans of answer?.file; let i = index" class="image_card">
          <div>
            <img
              width="50"
              height="50"
              [src]="ans.fileurl"
              data-bs-toggle="modal"
              data-bs-target="#comment-image-viewer"
              (click)="viewImage(ans.fileurl)"
              style="cursor: pointer"
            />
            <span class="ms-3">{{ ans.filename }}</span>
          </div>
          <button
            mat-icon-button
            class="icon-button"
            (click)="handleRemove(ans.filename)"
          >
            <mat-icon class="delete-icon">delete</mat-icon>
          </button>
        </div>
      </div>
    </section>
    <footer mat-dialog-actions align="end">
      <button
        class="btn btn-custom"
        *ngIf="currentIndex > 0"
        (click)="onPrevious()"
      >
        Previous
      </button>
      <button
        class="btn btn-custom"
        *ngIf="currentIndex + 1 < total"
        (click)="onNext()"
      >
        Next
      </button>
    </footer>
  </div>
</div>

<div
  class="modal"
  id="comment-image-viewer"
  aria-hidden="true"
  aria-labelledby="imageviewerLabel"
  tabindex="-1"
>
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-more-container">
          <button
            type="button"
            class="btn-icon"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="downloadImage(previewurl)"
          >
            <mat-icon>download</mat-icon>
          </button>
          <button
            type="button"
            class="btn-icon"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div>
          <img [src]="previewurl" alt="" class="image-view" />
        </div>
      </div>
    </div>
  </div>
</div>
