<section>
  <header class="title-container mb-3">
    <h2 class="title">History - {{ displayid }}</h2>
    <div class="close-btn">
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </header>
  <perfect-scrollbar style="position: relative; max-height: 600px;" [perfectScrollbar]="config">
    <div class="placeholder-glow" *ngIf="isLoading">
      <div *ngFor="let item of skeletons" class="placeholder col-12 p-3 mb-3"></div>
    </div>
    <div class="d-flex flex-column gap-2" *ngIf="!isLoading">
      <ng-container *ngFor="let item of history">
        <div *ngIf="item.newContent && item.newContent != ''">
          <span class="form-label">{{ item.name }}</span>
          <div>
            <div class="text" [ngClass]="
                  item.isChange || item.oldContent == '' ? 'newtext' : 'defaulttext'
                ">
              {{ item.newContent }}
            </div>
          </div>
          <div class="mt-2" *ngIf="item.oldContent && item.oldContent != '' && item.isChange">
            <div class="text oldtext">{{ item.oldContent }}</div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="newfiles.length > 0">
        <span>Updated Images</span>
        <div class="d-flex flex-wrap gap-4">
          <ng-container *ngFor="let image of newfiles">
            <div>
              <img width="70" height="70" [src]="image.fileurl" data-bs-toggle="modal"
                data-bs-target="#comment-image-viewer" style="cursor: pointer" (click)="viewImage(image.fileurl)" />
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="oldfiles.length > 0">
        <span>Old Images</span>
        <div class="d-flex flex-wrap gap-4">
          <ng-container *ngFor="let image of oldfiles">
            <div>
              <img width="70" height="70" [src]="image.fileurl" data-bs-toggle="modal"
                data-bs-target="#comment-image-viewer" style="cursor: pointer" (click)="viewImage(image.fileurl)" />
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </perfect-scrollbar>  
</section>
<div class="modal" id="comment-image-viewer" aria-hidden="true" aria-labelledby="imageviewerLabel" tabindex="-1">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-more-container">
          <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close"
            (click)="downloadImage(previewurl)">
            <mat-icon>download</mat-icon>
          </button>
          <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div>
          <img [src]="previewurl" alt="" class="image-view" />
        </div>
      </div>
    </div>
  </div>
</div>