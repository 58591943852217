<div>
    <div class="me-auto">
        <!-- <app-kmember-breadcrumb s_breadcrumb="Monthly Fees" [t_breadcrumb]="selectedMFT?.description"
            (emit)="closeForm()">
        </app-kmember-breadcrumb> -->
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    </div>
    <div class="d-flex gap-2 mb-2">
        <button class="btn btn-custom" (click)="closeForm()">Cancel</button>
        <app-button-loader (click)="saveMonthlyFees()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="createMonthlyFeesTypeLoading">
        </app-button-loader>
    </div>

    <form [formGroup]="monthlyFeeForm">
        <div class="row">
            <div class="col-md-6 mb-3">
                <label for="desc" class="form-label">Name</label>
                <input type="text" id="monthlyfeesname" class="form-control" formControlName="description" required
                    [ngClass]="{ 'is-invalid': submitted && monthlyfeesname.invalid }" />
                <div class="invalid-feedback"
                    *ngIf="
                                monthlyfeesname.invalid || ((monthlyfeesname.dirty || monthlyfeesname.touched) && submitted)">
                    <div *ngIf="monthlyfeesname.errors?.required">{{ requiredMessage }}</div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <label for="shortcode" class="form-label">Short Code</label>
                <input type="text" id="shortcode" class="form-control" formControlName="shortcode">
            </div>
            <div class="col-md-6 mb-3">
                <label for="mtype" class="form-label">Member Type</label>
                <select class="form-select" id="membertype_id" name="membertype" formControlName="membertype_id"
                    required>
                    <option value="">-</option>
                    <option *ngFor="let item of memberTypeList" [value]="item.membertypeid">
                        {{item.name}}</option>
                </select>
            </div>
        </div>
    </form>
    <div class="card mt-4">
        <div class="card-body m-2">
            <div class="d-flex gap-3 align-items-center mb-3">
                <span style="font-size: 18px;">Sub Fees</span>
                <button class="btn btn-custom" (click)="openMonthlyFeeDetailForm()"
                    [disabled]="selectedMFTNotexists">Add</button>
            </div>

            <table class="table table-responsive table-borderless" *ngIf="monthlyFeesTypeDetailList.length>0">
                <thead class="table-header">
                    <tr>
                        <th>Fees Type</th>
                        <th>Member Type</th>
                        <th>Payment</th>
                        <th class="text-algin-right">Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let mFTD of monthlyFeesTypeDetailList">
                        <td>{{mFTD.feeType.description}}</td>
                        <td>{{selectedMFT.memberType.name}}</td>
                        <td>{{mFTD.payPeriod.description}}</td>
                        <td class="mr-4" style="text-align: end;">{{mFTD.amount | number}}</td>
                        <td style="width: 80px;">
                            <button class="btn btn-outline-danger" (click)="deleteMFTD(mFTD.monthlyfee_detail_id)">
                                <i class='fa fa-trash-o'></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>