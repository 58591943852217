<div class="container-fluid">
  <div class="content">
    <app-hcm-breadcrumb *ngIf="allinoneService.isAttendanceView" [s_breadcrumb]="'Hierarchy'">
    </app-hcm-breadcrumb>
    <app-team-breadcrumb *ngIf="!allinoneService.isAttendanceView" [s_breadcrumb]="'Hierarchy'"></app-team-breadcrumb>
    <div class="mt-2  mb-3 d-flex justify-content-between">
      <div class="d-flex flex-wrap">
        <ng-container *ngIf="hcForm.type != 'all'">
          <div>
            <button type="button" class="btn btn-custom" (click)="addHierarchy()"
              [disabled]="gettingHierarchy">
              <i class="fa fa-plus me-1"></i>Add
            </button>
          </div>
          <div class="line"></div>
        </ng-container>
        <div class="me-3">
          <button type="button" class="btn btn-custom" (click)="openPositionForm()">
            Positions
          </button>
        </div>
        <div class="mb-3 me-3">
          <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
            <img class="excel-icon" src="../../../assets/images/excel.png">
            Export
          </button>
        </div>
        <div class="mb-3 me-3" *ngIf="hcForm.type == '1'">
          <button type="button" class="btn btn-custom" (click)="importSheet()" title="Import from Excel"
            *ngIf="importStatus == '001' || importStatus == '005'">
            <img class="excel-icon" src="../../../assets/images/excel.png">
            Import
          </button>
          <div class="d-flex align-items-center" *ngIf="importStatus == '002' || importStatus == '003'">
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-outline-black" disabled>
                <i class="fas fa-circle-notch fa-spin me-2" *ngIf="importStatus == '002'"></i>
                <img src="../../../assets/images/upload.gif" width="20px" class="me-2" *ngIf="importStatus == '003'">

                {{importStatus == '002' ? 'Checking' : 'Uploading'}}
              </button>
              <button type="button" class="btn btn-outline-black" (click)="getImportStatus()">
                <i class="fas fa-sync-alt" [ngClass]="{'fa-spin' : refreshingImportStatus}"></i>
              </button>
            </div>
          </div>
          <button type="button" class="btn btn-outline-danger" (click)="importSheet(true)" *ngIf="importStatus == '004'"
            style="width: auto;">
            <i class="fas fa-exclamation-circle me-2"></i>
            Import Error
          </button>
        </div>

      </div>
      <div class="mb-3 row row-custom col-lg-6 col-md-12 col-sm-12" *ngIf="!isView">
        <label for="type" class="col-form-label col-4 col-md-3">Type</label>
        <div class="col-8 col-md-9">
          <select class="form-select" aria-label="Select type" id="type" name="type" (change)="typeChange($event)"
            [(ngModel)]="hcForm.type" required #type="ngModel" [disabled]="gettingHierarchyType">
            <option value="all">All</option>
            <option *ngFor="let type of hierarchyTypes" [value]="type.id">{{type.name}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingHierarchyType"></mat-progress-bar>
        </div>
      </div>
    </div>
    <div class="row row-custom" *ngIf="isView">
      <div class="mb-3 row row-custom col-lg-6 col-md-12 col-sm-12">
        <label for="type" class="col-form-label col-4 col-md-3">Type</label>
        <div class="col-8 col-md-9">
          <select class="form-select" aria-label="Select type" id="type" name="type" (change)="typeChange($event)"
            [(ngModel)]="hcForm.type" required #type="ngModel" [disabled]="gettingHierarchyType">
            <option value="all">All</option>
            <option *ngFor="let type of hierarchyTypes" [value]="type.id">{{type.name}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingHierarchyType"></mat-progress-bar>
        </div>
      </div>
    </div>
    <div class="chart-container card pb-3" [ngClass]="{'overlay-loading' : gettingHierarchy}">
      <div class="radio">
        <div class="d-flex align-items-center">
          <div class="form-check  me-5">
            <label class="radio-inline">
              <input class="form-check-input" type="radio" name="displaytype" id="chart" [(ngModel)]="displayMode"
                value="List">Tree
            </label>
          </div>
          <div class="form-check me-5" *ngIf="hcForm.type == '1'">
            <label class="radio-inline">
              <input class="form-check-input" type="radio" name="displaytype" id="chart" [(ngModel)]="displayMode"
                value="Chart">Chart
            </label>
          </div>
          <div class="form-check me-5" *ngIf="hcForm.type == '1' || hcForm.type == 'all'">
            <label class="radio-inline">
              <input class="form-check-input" type="radio" name="displaytype" id="chart" [(ngModel)]="displayMode"
                value="Relationship">Relationship
            </label>
          </div>
        </div>
        <div class="d-flex">
          <div>
            <button class="btn btn-custom me-2" matTooltip="Sync the Hierarchy for caching" (click)="syncHierarchy()">
              <i class="fas fa-sync-alt" [class.fa-spin]="syncLoading" 
              style="height: 1.45rem;
              display: flex;
              align-items: center;
              justify-content: center;"></i>
            </button>
          </div>
          <div *ngIf="displayMode != 'Relationship'">
            <button class="btn btn-custom" (click)="expandCollapse()">
              {{allExpand ? 'Collapse All' : 'Expand All'}}
            </button>
          </div>
          <div class="input-group" style="width: 300px !important;" *ngIf="displayMode == 'Relationship'">
            <input class="form-control" type="text" placeholder="&#xf002; Search with Employee ID/Name"
              aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
              [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
            <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
              [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
              <mat-icon class="c-icon">close</mat-icon>
            </button>
          </div>
          <div class="dropdown ms-2">
            <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
              aria-expanded="false" title="Page Size" matTooltip="Order">
              <mat-icon>filter_list</mat-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="dropdown-item">
                <input type="radio" name="order" value="0" (change)="changeOrder($event)" id="asc" class="p-1"
                  [checked]="hcForm.order=='0'">
                <label for="asc" class="col-10 p-1">
                  Use Ascending Order
                </label>
              </li>
              <li class="dropdown-item">
                <input type="radio" name="order" value="1" (change)="changeOrder($event)" id="des" class="p-1"
                  [checked]="hcForm.order=='1'">
                <label for="des" class="col-10 p-1">
                  Use Descending Order
                </label>
              </li>
              <li class="dropdown-item">
                <input type="radio" name="order" value="2" (change)="changeOrder($event)" id="employeeid" class="p-1"
                  [checked]="hcForm.order=='2'">
                <label for="employeeid" class="col-10 p-1">
                  Employee ID
                </label>
              </li>
              <li class="dropdown-item">
                <input type="radio" name="order" value="3" (change)="changeOrder($event)" id="primary" class="p-1"
                  [checked]="hcForm.order=='3'">
                <label for="primary" class="col-10 p-1">
                  Primary
                </label>
              </li>
            </ul>
          </div>
          <div class="ms-2">
            <button class="btn btn-custom" (click)="refresh()" title="Refresh" matTooltip="Refresh">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center" *ngIf="gettingHierarchy"
        style="height: 100%; position: absolute; z-index: 1000; background-color: #ffffff87; width: 100%;">
        <div class="spinner-border download" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center" *ngIf="!gettingHierarchy && data.length == 0"
        style="height: 300px;">
        <div>
          Empty
        </div>
      </div>
      <div *ngIf="data.length > 0">
        <p-tree [value]="data" *ngIf="displayMode == 'List' && hcForm.type == 'all'"
          (onNodeExpand)="onNodeExpand($event)" (onNodeCollapse)="onNodeCollapse($event)">
          <ng-template let-node pTemplate="default">
            <div class="p-tree-container d-flex align-items-center mat-typography"
              style="min-height: 40px; min-width: 100px;">
              <ng-container *ngIf="!node.loading">
                <div>
                  <span aria-expanded="false" data-bs-toggle="dropdown" class="td-edit">
                    <span> {{node.username ? node.username :
                      node.userid}} </span>
                    <span style="color: gray;"> ({{node.employeeid}}) </span>
                    <span *ngIf="node.positionname">{{node.positionname ? " - " + node.positionname : ''}}</span>

                    <span *ngIf="node.htype == '1'">
                      <img *ngIf="node.primary == '1'" src="./../../../assets/images/hierarchy/ro-primary.png"
                        class="all-type-icon" matTooltip="Reporting - Primary">
                    </span>
                    <span *ngIf="node.htype == '50'">
                      <img *ngIf="node.primary != '1'" src="./../../../assets/images/hierarchy/supervision.png"
                        class="all-type-icon" matTooltip="Supervision">
                      <img *ngIf="node.primary == '1'" src="./../../../assets/images/hierarchy/supervision-primary.png"
                        class="all-type-icon" matTooltip="Supervision - Primary">
                    </span>
                    <span *ngIf="node.htype == '51'">
                      <img *ngIf="node.primary != '1'" src="./../../../assets/images/hierarchy/guidance.png"
                        class="all-type-icon" matTooltip="Guidance">
                      <img *ngIf="node.primary== '1'" src="./../../../assets/images/hierarchy/guidance-primary.png"
                        class="all-type-icon" matTooltip="Guidance - Primary">
                    </span>
                    <span *ngIf="node.htype == '52'">
                      <img *ngIf="node.primary!= '1'" src="./../../../assets/images/hierarchy/mentoring.png"
                        class="all-type-icon" matTooltip="Mentoring">
                      <img *ngIf="node.primary== '1'" src="./../../../assets/images/hierarchy/mentoring-primary.png"
                        class="all-type-icon" matTooltip="Mentoring - Primary">
                    </span>
                    <span *ngIf="node.htype == '53'">
                      <img *ngIf="node.primary!= '1'" src="./../../../assets/images/hierarchy/observation.png"
                        class="all-type-icon" matTooltip="Observation">
                      <img *ngIf="node.primary== '1'" src="./../../../assets/images/hierarchy/observation-primary.png"
                        class="all-type-icon" matTooltip="Observation - Primary">
                    </span>
                  </span>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <button mat-menu-item (click)="editHierarchy(node)" *ngIf="node.peid && node.htype == '1'">
                      <img src="../../../assets/images/hierarchy-structure.png" class="member-menu-item menu-img">
                      <span>Edit RO</span>
                    </button>
                    <button mat-menu-item (click)="editHierarchy(node, true)" *ngIf="node.peid && node.htype != '1'">
                      <img src="../../../assets/images/hierarchy-structure.png" class="member-menu-item menu-img">
                      <span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="editDetails(node, true)">
                      <img src="../../../assets/images/resume.png" class="member-menu-item menu-img">
                      <span>Edit Details</span>
                    </button>
                    <button mat-menu-item (click)="viewInfo(node)">
                      <mat-icon class="member-menu-item">info</mat-icon>
                      <span>Info</span>
                    </button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="node.loading">
                <i class="fas fa-circle-notch fa-spin me-2"></i> Loading
              </ng-container>
            </div>
          </ng-template>
        </p-tree>
        <p-tree [value]="data" *ngIf="displayMode == 'List' && hcForm.type == '1'" (onNodeExpand)="onNodeExpand($event)"
          (onNodeCollapse)="onNodeCollapse($event)">
          <ng-template let-node pTemplate="default">
            <div class="p-tree-container d-flex align-items-center mat-typography"
              style="min-height: 40px; min-width: 100px;">
              <div>
                <span class="list-info" aria-expanded="false" data-bs-toggle="dropdown">
                  <span style="font-weight: bold; color: var(--main-color);"> {{node.username ? node.username :
                    node.userid}} </span>
                  <span style="color: gray;"> ({{node.employeeid}}) </span>
                  <span class="pos" *ngIf="node.positionname">{{node.positionname ? node.positionname : ''}}</span>

                </span>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <button mat-menu-item (click)="addHierarchy(node, 3)">
                    <img src="../../../assets/images/addbelow.png" class="member-menu-item menu-img">
                    <span>Add</span>
                  </button>
                  <!-- <button mat-menu-item (click)="addHierarchy(node, 2)" *ngIf="node.children?.length > 0">
                      <img src="../../../assets/images/addbetween.png" class="member-menu-item menu-img">
                      <span>Add Between</span>
                    </button>
                    <button mat-menu-item (click)="addHierarchy(node, 1)">
                      <img src="../../../assets/images/addabove.png" class="member-menu-item menu-img">
                      <span>Add Above</span>
                    </button> -->
                  <button mat-menu-item (click)="editHierarchy(node)" *ngIf="node.peid">
                    <img src="../../../assets/images/hierarchy-structure.png" class="member-menu-item menu-img">
                    <span>Edit RO</span>
                  </button>
                  <button mat-menu-item (click)="editDetails(node)">
                    <img src="../../../assets/images/resume.png" class="member-menu-item menu-img">
                    <span>Edit Details</span>
                  </button>
                  <button mat-menu-item (click)="deleteRO(node)">
                    <mat-icon class="member-menu-item">delete</mat-icon>
                    <span>Delete</span>
                  </button>
                  <button mat-menu-item (click)="viewInfo(node)">
                    <mat-icon class="member-menu-item">info</mat-icon>
                    <span>Info</span>
                  </button>
                </div>
              </div>

            </div>
          </ng-template>
        </p-tree>
        <p-tree [value]="data"
          *ngIf="displayMode == 'List' && (hcForm.type == '50' || hcForm.type == '51' || hcForm.type == '52' || hcForm.type == '53')"
          (onNodeExpand)="onNodeExpand($event)" (onNodeCollapse)="onNodeCollapse($event)">
          <ng-template let-node pTemplate="default">
            <div class="p-tree-container d-flex align-items-center mat-typography">
              <div data-bs-toggle="dropdown">
                <span class="list-info">
                  <span style="font-weight: bold; color: var(--main-color);"> {{node.username ? node.username :
                    node.userid}} </span>
                  <span style="color: gray;"> ({{node.employeeid}}) </span>
                  <span class="pos" *ngIf="node.positionname">{{node.positionname ? node.positionname : ''}}</span>
                </span>
              </div>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <button mat-menu-item (click)="editHierarchy(node, true)" *ngIf="node.peid">
                  <img src="../../../assets/images/hierarchy-structure.png" class="member-menu-item menu-img">
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="editDetails(node)" *ngIf="node.peid">
                  <img src="../../../assets/images/resume.png" class="member-menu-item menu-img">
                  <span>Edit Details</span>
                </button>
                <button mat-menu-item (click)="deleteNode(node)">
                  <mat-icon class="member-menu-item">delete</mat-icon>
                  <span>Delete</span>
                </button>
                <button mat-menu-item (click)="viewInfo(node)">
                  <mat-icon class="member-menu-item">info</mat-icon>
                  <span>Info</span>
                </button>
              </div>
              <!-- <div class="add-delete">
                <span class="list-delete" (click)="deleteNode(node)">Delete</span>
              </div> -->
            </div>
          </ng-template>
        </p-tree>
        <div style="position: absolute; top: 20px; bottom: 0; left: 0; right: 0;  " *ngIf="displayMode == 'Chart'">
          <pan-zoom [config]="panzoomConfig">
            <div style="position: relative; padding-top: 30px;">
              <p-organizationChart [value]="data" [preserveSpace]="preserveSpace" *ngIf="displayMode == 'Chart'"
                (onNodeExpand)="onNodeExpand($event)" (onNodeCollapse)="onNodeCollapse($event)">
                <ng-template let-node pTemplate="default">
                  <div class="dropend">
                    <div class="node-content mat-typography">
                      <div (click)="clickOnNode(node)">
                        <div><b>{{node.username ? node.username : node.userid}} -
                            {{node.employeeid}}</b>
                        </div>
                        <div>{{node.positionname}}</div>
                        <div>{{node.department}}</div>
                        <div>{{node.division}}</div>
                      </div>
                      <ul class="dropdown-menu custom-dropdown">
                        <button mat-menu-item (click)="addHierarchy(node, 3)">
                          <img src="../../../assets/images/addbelow.png" class="member-menu-item menu-img">
                          <span>Add</span>
                        </button>
                        <!-- <button mat-menu-item (click)="addHierarchy(node, 2)" *ngIf="node.children?.length > 0">
                          <img src="../../../assets/images/addbetween.png" class="member-menu-item menu-img">
                          <span>Add Between</span>
                        </button>
                        <button mat-menu-item (click)="addHierarchy(node, 1)">
                          <img src="../../../assets/images/addabove.png" class="member-menu-item menu-img">
                          <span>Add Above</span>
                        </button> -->
                        <button mat-menu-item (click)="editHierarchy(node)" *ngIf="node.peid">
                          <img src="../../../assets/images/hierarchy-structure.png" class="member-menu-item menu-img">
                          <span>Edit RO</span>
                        </button>
                        <button mat-menu-item (click)="editDetails(node)">
                          <img src="../../../assets/images/resume.png" class="member-menu-item menu-img">
                          <span>Edit Details</span>
                        </button>
                        <button mat-menu-item (click)="deleteNode(node)">
                          <mat-icon class="member-menu-item">delete</mat-icon>
                          <span>Delete</span>
                        </button>
                        <button mat-menu-item (click)="viewInfo(node)">
                          <mat-icon class="member-menu-item">info</mat-icon>
                          <span>Info</span>
                        </button>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </p-organizationChart>
            </div>
          </pan-zoom>
        </div>
        <div *ngIf="displayMode == 'Relationship'">
          <div class="table-responsive" matSort>
            <table class="table table-responsive table-borderless">
              <thead class="table-header">
                <tr>
                  <td scope="col" class="col-lg-2 col-md-2">Parent Name
                  </td>
                  <td scope="col" class="col-lg-2 col-md-2">Parent
                    Member ID
                  </td>
                  <td scope="col" class="col-lg-2 col-md-2">Parent
                    Position
                  </td>
                  <td scope="col" class="col-lg-2 col-md-2">Child Name</td>
                  <td scope="col" class="col-lg-2 col-md-2">Child Member ID</td>
                  <td scope="col" class="col-lg-2 col-md-2">Child Position</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of parentList| filter:searchText:'hierarchy'; let index = index"
                  [ngClass]="{'supervision-label' : item.htype == '50', 'guidance-label' : item.htype == '51', 'mentoring-label' : item.htype == '52', 'observation-label' :item.htype == '53'}">
                  <td class="td-data" style="max-width: fit-content;">
                    {{item.parentusername ? item.parentusername : item.parentuserid }}
                    <ng-container *ngIf="hcForm.type == 'all'">
                      <span *ngIf="item.htype == '1'">
                        <img *ngIf="item.primary == '1'" src="./../../../assets/images/hierarchy/ro-primary.png"
                          class="all-type-icon" matTooltip="Reporting - Primary">
                      </span>
                      <span *ngIf="item.htype == '50'">
                        <img *ngIf="item.primary != '1'" src="./../../../assets/images/hierarchy/supervision.png"
                          class="all-type-icon" matTooltip="Supervision">
                        <img *ngIf="item.primary == '1'"
                          src="./../../../assets/images/hierarchy/supervision-primary.png" class="all-type-icon"
                          matTooltip="Supervision - Primary">
                      </span>
                      <span *ngIf="item.htype == '51'">
                        <img *ngIf="item.primary != '1'" src="./../../../assets/images/hierarchy/guidance.png"
                          class="all-type-icon" matTooltip="Guidance">
                        <img *ngIf="item.primary== '1'" src="./../../../assets/images/hierarchy/guidance-primary.png"
                          class="all-type-icon" matTooltip="Guidance - Primary">
                      </span>
                      <span *ngIf="item.htype == '52'">
                        <img *ngIf="item.primary != '1'" src="./../../../assets/images/hierarchy/mentoring.png"
                          class="all-type-icon" matTooltip="Mentoring">
                        <img *ngIf="item.primary== '1'" src="./../../../assets/images/hierarchy/mentoring-primary.png"
                          class="all-type-icon" matTooltip="Mentoring - Primary">
                      </span>
                      <span *ngIf="item.htype == '53'">
                        <img *ngIf="item.primary!= '1'" src="./../../../assets/images/hierarchy/observation.png"
                          class="all-type-icon" matTooltip="Observation">
                        <img *ngIf="item.primary== '1'" src="./../../../assets/images/hierarchy/observation-primary.png"
                          class="all-type-icon" matTooltip="Observation - Primary">
                      </span>
                    </ng-container>
                  </td>
                  <td class="td-data">
                    {{item.peid}}
                  </td>
                  <td class="td-data">
                    {{item.parentpositionname}}
                  </td>
                  <td class="td-data">
                    {{item.username ? item.username : item.userid }}
                  </td>
                  <td class="td-data">
                    {{item.employeeid}}
                  </td>
                  <td class="td-data">
                    {{item.positionname}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>