<div class="d-flex align-items-center justify-content-between mb-2">
    <div>
      <h3 class="mb-0 me-2">{{name}}</h3>
    </div>
    <div>
      <mat-icon class="td-edit" (click)="onDismiss()">close</mat-icon>
    </div>
  </div>
<ngx-extended-pdf-viewer [src]="fileurl" [useBrowserLocale]="true" [textLayer]="true" [zoom]="'page-width'"
    [showHandToolButton]="true" [showPresentationModeButton]="true" [showDownloadButton]="false"
    [showOpenFileButton]="false" height="600px">
</ngx-extended-pdf-viewer>