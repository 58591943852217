<div class="container-fluid">
    <div class="content">

        <div *ngIf="!allInOneService.isAttendanceView">
            <app-team-breadcrumb *ngIf="isFromOrg" [s_breadcrumb]="'Organizations'" [t_breadcrumb]="org_name"
                [f_breadcrumb]="'Locations'"
                [fi_breadcrumb]="locAddorNot ? isEdition? 'Edit Location':  'New Location': ''" (emit)="goToOrg()"
                (emit1)="goToOrgEdit()" (emit2)="cancel()">
            </app-team-breadcrumb>


            <app-team-breadcrumb *ngIf="!isFromOrg" [s_breadcrumb]="'Locations'"
                [t_breadcrumb]="locAddorNot ? isEdition? 'Edit Location': 'New Location': ''" (emit)="cancel()">
            </app-team-breadcrumb>
        </div>

        <div *ngIf="allInOneService.isAttendanceView">
            <app-breadcrumb *ngIf="isFromOrg" [s_breadcrumb]="'Organizations'" [t_breadcrumb]="org_name"
                [f_breadcrumb]="'Locations'"
                [fi_breadcrumb]="locAddorNot ? isEdition? 'Edit Location':  'New Location': ''" (emit)="goToOrg()"
                (emit1)="goToOrgEdit()" (emit2)="cancel()">
            </app-breadcrumb>


            <app-breadcrumb *ngIf="!isFromOrg" [s_breadcrumb]="'Locations'"
                [t_breadcrumb]="locAddorNot ? isEdition? 'Edit Location': 'New Location': ''" (emit)="cancel()">
            </app-breadcrumb>
        </div>


        <div class="d-flex justify-content-between header flex-wrap">


            <div class="mb-3">
                <button *ngIf="!locAddorNot" type="button" class="btn btn-custom" (click)="locAddorNot = !locAddorNot">
                    <i class="fa fa-plus me-1"></i>New Location
                </button>

                <div *ngIf="locAddorNot" class="close-save-button">
                    <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                        Cancel
                    </button>
                    <app-button-loader (click)="submitLocation()" [button_text]="'Save'" [load_button_text]="'Saving'"
                        [isLoading]="saveLoading"></app-button-loader>

                </div>

            </div>
            <div *ngIf="!locAddorNot && !isMobileView" class="mb-3"
                [ngClass]="{'row row-custom col-lg-6 col-md-12 col-sm-12': !isMobileView}">
                <label for="locType" class="col-form-label" [ngClass]="{'no-pad': !isMobileView}">Location Type</label>
                <div [ngClass]="{'input-container': !isMobileView}">
                    <select class="form-select" aria-label="Select Location Type" id="loctype" name="loctype"
                        (change)="locTypeChanged($event)" [(ngModel)]="locForm.loctype" required #loctype="ngModel"
                        [ngClass]="{ 'is-invalid': locformSubmitted && loctype.invalid }">
                        <option selected value=""> {{locAddorNot ? 'Select Type' : '-'}}</option>
                        <option *ngFor="let item of locTypeList" value="{{item.value}}">{{item.name}}</option>
                    </select>
                </div>
                <div class="invalid-feedback"
                    *ngIf="loctype.invalid || (loctype.dirty || loctype.touched) && locformSubmitted">
                    <div *ngIf="loctype.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div>
        </div>
        <form [ngClass]="{'row row-custom': !locAddorNot && !isMobileView}">
            <div *ngIf="locAddorNot">
                <div class="mb-3">
                    <label for="locName" class="form-label">Location Name</label>
                    <input type="text" class="form-control" id="locName" name="locName"
                        placeholder="Enter Location Name" [(ngModel)]="locForm.locname" required #locname="ngModel"
                        [ngClass]="{ 'is-invalid': locformSubmitted && locname.invalid }"
                        (keyup.enter)="submitLocation()">

                    <div class="invalid-feedback"
                        *ngIf="locname.invalid || (locname.dirty || locname.touched) && locformSubmitted">
                        <div *ngIf="locname.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>


                </div>
                <div class="row g-3 mb-3">
                    <div class="col-md-4">
                        <label for="latitude" class="form-label">Latitude</label>
                        <input type="number" class="form-control" id="latitude" name="latitude"
                            placeholder="Enter Latitude" [(ngModel)]="locForm.lat" required #lat="ngModel"
                            [ngClass]="{ 'is-invalid': locformSubmitted && lat.invalid }"
                            (keyup.enter)="submitLocation()">

                        <div class="invalid-feedback"
                            *ngIf="lat.invalid || (lat.dirty || lat.touched) && locformSubmitted">
                            <div *ngIf="lat.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                        <div class="map-wrapper">
                            <a (click)="map()" class="btn-link">Pick Location</a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="longitude" class="form-label">Longitude</label>
                        <input type="number" class="form-control" id="longitude" name="longitude"
                            placeholder="Enter Longitude" [(ngModel)]="locForm.long" required #long="ngModel"
                            [ngClass]="{ 'is-invalid': locformSubmitted && long.invalid }"
                            (keyup.enter)="submitLocation()">

                        <div class="invalid-feedback"
                            *ngIf="long.invalid || (long.dirty || long.touched) && locformSubmitted">
                            <div *ngIf="long.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>

                    </div>

                    <div class="col-md-4">
                        <label for="range" class="form-label">Range</label>
                        <input type="number" class="form-control" id="range" name="range" placeholder="Enter Range"
                            [(ngModel)]="locForm.range" required #range="ngModel"
                            [ngClass]="{ 'is-invalid': locformSubmitted && range.invalid }"
                            (keyup.enter)="submitLocation()">

                        <div class="invalid-feedback"
                            *ngIf="range.invalid || (range.dirty || range.touched) && locformSubmitted">
                            <div *ngIf="range.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="locAddorNot || isMobileView" class="mb-3"
                [ngClass]="{'row row-custom col-lg-6 col-md-12 col-sm-12': !locAddorNot && !isMobileView}">
                <label for="locType" class="col-form-label"
                    [ngClass]="{'no-pad': !locAddorNot && !isMobileView}">Location Type</label>
                <div [ngClass]="{'input-container': !locAddorNot && !isMobileView}">
                    <select class="form-select" aria-label="Select Location Type" id="loctype" name="loctype"
                        (change)="locTypeChanged($event)" [(ngModel)]="locForm.loctype" required #loctype="ngModel"
                        [ngClass]="{ 'is-invalid': locformSubmitted && loctype.invalid }" [disabled]="locAddorNot">
                        <option selected value=""> {{locAddorNot ? 'Select Type' : '-'}}</option>
                        <option *ngFor="let item of locTypeList" value="{{item.value}}">{{item.name}}</option>
                    </select>
                </div>
                <div class="invalid-feedback"
                    *ngIf="loctype.invalid || (loctype.dirty || loctype.touched) && locformSubmitted">
                    <div *ngIf="loctype.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div>

            <div *ngIf="locAddorNot">
                <div class="mb-3">
                    <label for="locDesc" class="form-label">Description</label>
                    <textarea class="form-control" id="locDesc" name="locDesc" rows="5"
                        [(ngModel)]="locForm.description" placeholder="Enter Description for Your Location"></textarea>
                </div>
            </div>
        </form>
        <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center" *ngIf="locAddorNot == false">
            <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
                <input class="form-control" type="text" placeholder="&#xf002; Search with Name/Range/Description"
                    aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                    [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                    [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                    <mat-icon class="c-icon">close</mat-icon>
                </button>
            </div>
            <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
                <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                    aria-expanded="false" title="Page Size">
                    <mat-icon>filter_list</mat-icon>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                        <div c style="text-align: center;">Page size</div>
                    </li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li class="dropdown-item" *ngFor="let page of pg">
                        <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                            id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                        <label for="pg{{page.name}}" class="col-10 p-1">
                            {{page.name}}
                        </label>
                    </li>
                </ul>
            </div>
            <div class="ms-2">
                <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
            <div class="line"></div>
            <div class="d-flex align-items-center">
                <span class="total-count-text">Total:&nbsp;</span> <span
                    class="total-count-num">{{locListToShow.length}}</span>
            </div>
        </div>

        <div class="input-group mb-2" *ngIf="isMobileView && !locAddorNot">
            <input class="form-control" type="text" placeholder="&#xf002; with Name/Range/Description"
                aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
            <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                <mat-icon class="c-icon">close</mat-icon>
            </button>
        </div>

        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
            <table class="table table-borderless" *ngIf="locAddorNot == false && this.locForm.loctype == '3'">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="name">Name</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="location">Location</td>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="range">Range</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="desc">Description</td>
                        <td scope="col" class="d-flex justify-content-center">Action</td>
                    </tr>
                </thead>
                <tbody *ngIf="locListLoading">
                    <tr>
                        <td colspan="6" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="locListLoading == false">
                    <tr *ngIf="locListToShow.length == 0">
                        <td colspan="6" class="nodata">Empty</td>
                    </tr>
                    <tr
                        *ngFor="let item of locListToShow |filter : searchText:type| paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index">
                        <td class="td-data td-edit" (click)="openEditForm(item)">
                            <span [title]="item.name">{{item.name}}</span>
                        </td>
                        <td class="td-data">
                            <span title="{{item.lat}}, {{item.lng}}">{{item.lat}}, {{item.lng}}</span>
                        </td>
                        <td class="td-data td-data-custom">
                            <span [title]="item.range">{{item.range}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.description">{{item.description}}</span>
                        </td>
                        <td class="d-flex justify-content-center">
                            <button class="btn btn-outline-info me-2" (click)="openQR(item)" title="QR Code"
                                [disabled]="item.loading">
                                <i class='fa fa-qrcode' *ngIf="!item.loading"></i>
                                <i class='fa fa-circle-notch fa-spin' *ngIf="item.loading"></i>
                            </button>
                            <button class="btn btn-outline-primary me-2" (click)="openEditForm(item)" title="Edit">
                                <i class='fa fa-edit'></i>
                            </button>
                            <button class="btn btn-outline-danger" (click)="deleteLocation(item.locid, item.typeid)"
                                title="Delete">
                                <i class='fa fa-trash-o'></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-borderless" *ngIf="locAddorNot == false && this.locForm.loctype == '4'">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="userid">User ID</td>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="username">User Name</td>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="name">Name</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="location">Location</td>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="range">Range</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="desc">Description</td>
                        <td scope="col" class="d-flex justify-content-center">Action</td>
                    </tr>
                </thead>
                <tbody *ngIf="locListLoading">
                    <tr>
                        <td colspan="8" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="locListLoading == false">
                    <tr *ngIf="locListToShow.length == 0">
                        <td colspan="8" class="nodata">Empty</td>
                    </tr>
                    <tr
                        *ngFor="let item of locListToShow |filter : searchText:type| paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index">
                        <td class="td-data">
                            <span [title]="item.userid">{{item.userid}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.username">{{item.username}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.name">{{item.name}}</span>
                        </td>
                        <td class="td-data">
                            <span title="{{item.lat}}, {{item.lng}}">{{item.lat}}, {{item.lng}}</span>
                        </td>
                        <td class="td-data td-data-custom">
                            <span [title]="item.range">{{item.range}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.description">{{item.description}}</span>
                        </td>
                        <td class="d-flex justify-content-center">
                            <!-- <button class="btn btn-outline-info me-2" (click)="openQR(item)" title="Edit" [disabled]="item.loading">
                                <i class='fa fa-qrcode' *ngIf="!item.loading"></i>
                                <i class='fa fa-circle-notch fa-spin' *ngIf="item.loading"></i>
                            </button> -->
                            <button class="btn btn-outline-danger" (click)="deleteLocation(item.locid, item.typeid)"
                                title="Delete">
                                <i class='fa fa-trash-o'></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <pagination-controls class="paginate" (pageChange)="p = $event"
                *ngIf="locListToShow.length > itemPerPage && !locAddorNot" previousLabel="Prev" nextLabel="Next">
            </pagination-controls>
        </div>
    </div>
</div>