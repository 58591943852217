import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-voting-qr',
  templateUrl: './voting-qr.component.html',
  styleUrls: ['./voting-qr.component.css'],
})
export class VotingQrComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    clearInterval(this.getQrInterval);
    clearInterval(this.checkQrInterval);
  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.MEDIUM;

  cid: string = '';
  value: string = '';
  camdesc: string = '';

  qrLoading: boolean = false;
  showRefresh: boolean = false;
  isError: boolean = false;
  noRoundError: boolean = false;
  gettingVR: boolean = false;
  loginFormSubmitted: boolean = false;
  saveLoading: boolean = false;

  countGetQr: number = 1;

  getQrInterval: any;
  checkQrInterval: any;
  qrData: any;

  loginForm = {
    userid: '',
    password: '',
  };

  constructor(
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.allinoneService.navbar = false;
  }

  ngOnDestroy() {
    this.allinoneService.navbar = true;
    clearInterval(this.checkQrInterval);
    clearInterval(this.getQrInterval);
  }

  ngOnInit(): void {
    const temp_cid = this.route.snapshot.paramMap.get('roundid');
    if (temp_cid) {
      this.cid = decodeURIComponent(temp_cid);
    } else {
      this.router.navigateByUrl('/qrlogin');
    }
    this.getVotingRound();
  }

  getVotingRound() {
    // Call api here and do the followings if success
    this.gettingVR = true;
    const data = {
      camid: this.cid,
    };
    this.kunyekService.getVotingRoundByCampaignId(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.camdesc = res.description;
          this.getQR();
          this.checkQrInterval = setInterval(() => {
            this.checkQr();
            console.log('checking qr');
          }, 10000);
          this.gettingVR = false;
        } else {
          this.isError = true;
          this.gettingVR = false;
          if (res.returncode == '240') {
            this.noRoundError = true;
          }
        }
      },
      (err) => {
        this.isError = true;
        this.gettingVR = false;
      }
    );
  }

  refresh() {
    this.getQR();
    this.checkQrInterval = setInterval(() => {
      this.checkQr();
      console.log('checking qr');
    }, 10000);
  }

  getQR() {
    this.qrLoading = true;
    this.showRefresh = false;

    this.kunyekService.requestQR('').subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.value = res.qr;
          this.qrLoading = false;
          if (this.countGetQr == 1) {
            this.getQrInterval = setInterval(() => {
              if (this.countGetQr == 3) {
                clearInterval(this.getQrInterval);
                clearInterval(this.checkQrInterval);
                this.showRefresh = true;
                this.countGetQr = 0;
              } else {
                this.getQR();
              }
              this.countGetQr++;
            }, 1000 * 60 * 2 + 50);
          }
        } else {
          this.qrLoading = false;
          this.showRefresh = true;
        }
      },
      (err: any) => {
        this.qrLoading = false;
        this.showRefresh = true;
      }
    );
  }

  checkQr() {
    this.kunyekService.checkQR(this.value).subscribe((res: any) => {
      if (res.returncode == '300') {
        clearInterval(this.checkQrInterval);
        clearInterval(this.getQrInterval);
        this.qrData = res;
        this.checkUser();
      }
    });
  }

  checkUser() {
    console.log('it called this function');
    const data = {
      userid: this.qrData.userid,
      camid: this.cid,
      atoken : this.qrData.atoken,
      token: ''
    };
    this.kunyekService.checkVoter(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.allinoneService.setCookie('voting_userid', res.userid);
          this.allinoneService.setCookie('voting_username', res.name);
          this.allinoneService.setCookie('voting_atoken', res.token);
          this.allinoneService.setCookie(
            'voting_proxy',
            res.proxylist.length > 0 ? JSON.stringify(res.proxylist) : ''
          );
          const camid = encodeURIComponent(this.cid);
          this.router.navigate([`/voting/${camid}/vote`]);
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.countGetQr = 1;
          this.getQR();
          this.checkQrInterval = setInterval(() => {
            this.checkQr();
            console.log('checking qr');
          }, 10000);
        }
      },
      (err) => {
        this.isError = true;
        clearInterval(this.checkQrInterval);
        clearInterval(this.getQrInterval);
      }
    );
  }

  reload() {
    window.location.reload();
  }

  signIn() {
    this.loginFormSubmitted = true;
    if (!this.loginForm.userid || !this.loginForm.password) {
      return;
    }
    this.saveLoading = true;
    const data = {
      userid: this.allinoneService.checkUserId(this.loginForm.userid),
      password: this.loginForm.password,
      camid: this.cid,
    };
    this.kunyekService.voterSignin(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          const data = {
            userid: this.allinoneService.checkUserId(this.loginForm.userid),
            camid: this.cid,
            token: res.token,
            atoken: ''
          };
          this.kunyekService.checkVoter(data).subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.allinoneService.setCookie('voting_userid', res.userid);
                this.allinoneService.setCookie('voting_username', res.name);
                this.allinoneService.setCookie('voting_atoken', res.token);
                this.allinoneService.setCookie('voting_proxy' , res.proxylist.length > 0 ? JSON.stringify(res.proxylist): '');
                const camid = encodeURIComponent(this.cid);
                this.router.navigate([`/voting/${camid}/vote`]);
              } else {
                this.messageService.openSnackBar(res.message, 'warn');
                this.saveLoading = false;
                this.loginFormSubmitted = false;
              }
            },
            (err) => {
              this.isError = true;
              clearInterval(this.checkQrInterval);
              clearInterval(this.getQrInterval);
              this.saveLoading = false;
              this.loginFormSubmitted = false;
            }
          );
        } else {
          this.saveLoading = false;
          this.loginFormSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.loginFormSubmitted = false;
      }
    );
  }
}
