import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-logistics-dashboard',
  templateUrl: './logistics-dashboard.component.html',
  styleUrls: ['./logistics-dashboard.component.scss']
})
export class LogisticsDashboardComponent implements OnInit {

  menu = [
    {
      'name' : 'Order',
      'path' : '/logistics/order',
      'icon' : this.allinoneService.bucketUrl + 'policyicons/orders.png'
    },
    {
      'name' : 'Batch',
      'path' : '/logistics/batch',
      'icon' : this.allinoneService.bucketUrl + 'policyicons/batch.png'
    },
    {
      'name' : 'Assign',
      'path' : '/logistics/assign',
      'icon' : this.allinoneService.bucketUrl + 'policyicons/admin_assignments.png'
    }
  ]

  constructor(
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  goTo(path: string){
    // if(path == '/logistics/assign'){
    //   this.messageService.openSnackBar('This feature is not available yet.', 'info')
    // }
    // else{
      this.router.navigateByUrl(path);
    // }
  }

}
