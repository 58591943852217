import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MemberApprovalHistoryComponent } from '../member-approval-history/member-approval-history.component';

@Component({
  selector: 'app-public-register-status',
  templateUrl: './public-register-status.component.html',
  styleUrls: ['./public-register-status.component.scss']
})
export class PublicRegisterStatusComponent implements OnInit {

  currentMember: any
  relations: any[] = []
  isLoading: boolean = false;
  profileUrl: string = ''
  isRegistered: boolean = false;
  isMember: boolean = false;
  validFromDate: string = '';
  validToDate: string = '';
  memberAcardNo: string = ''

  constructor(
    private kunyekService: KunyekService,
    private dialog: MatDialog,
    private messageService: MessageService,
    public allInOneservice: AllInOneService,
    public datepipe: DatePipe,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.loadMemberInformations();
  }

  loadMemberInformations() {
    this.isLoading = true;
    this.kunyekService.getKMembers({ usersyskey: this.allInOneservice.getKunyekUserId() }).subscribe(
      (res: any) => {
        if (res.datalist.length > 0) {
          this.currentMember = res.datalist[0]
          console.log(res.datalist, 'res...')
          console.log(this.currentMember, 'current member')
          let piamge = JSON.parse(this.currentMember.profileimage);
        this.profileUrl = piamge ? piamge.fileurl : ""
          if(this.currentMember){
            this.isRegistered = true;
          }
          this.getRelationList()
          this.getARegistration()
        } else {
          this.currentMember = {}
        }
        this.isLoading = false
      }, (err: any) => {
        console.log(err)
        this.messageService.openSnackBar(err, 'warn')
        this.isLoading = false;
      }
    )
  }

  getRelationList() {
    if (this.currentMember) {
      this.kunyekService.getKMembers({ parentid: this.currentMember.syskey }).subscribe(
        (res: any) => {
          if (res.datalist.length > 0) {
            this.relations = res.datalist            
            this.relations.forEach((i:any)=>{
              if(i.profileimage){
                const profileObj = JSON.parse(i.profileimage)
                console.log(profileObj)
                console.log(this.relations)
                i.profileUrl = profileObj.fileurl
              }
            })
          } else {
            this.relations = []
          }
        }, (err: any) => {
          console.log(err)
          this.messageService.openSnackBar(err, 'warn')
        }
      )
    }
  }

  openHistory() {
    this.dialog.open(MemberApprovalHistoryComponent, {
      width: "530px",
      height: "500px",
      data: {
        member_id: this.currentMember.syskey
      }
    });
  }

  getARegistration() {
    if (this.currentMember) {
      this.kunyekService.getRegisteredMember({ member_id: this.currentMember.syskey }).subscribe(
        (res: any) => {
          console.log(res)
          if (res.datalist.length > 0 && res.datalist[0].status == "004") {
            const data = res.datalist[0]
            this.isMember = true;
            this.validFromDate = this.allInOneservice.formatDBToShow(data.fromdate)
            this.validToDate = this.allInOneservice.formatDBToShow(data.todate)
            this.memberAcardNo = data.membercard_id            
            // this.relations = res.datalist
            // console.log(this.relations)
            this.isLoading = false
          } else {
            this.isLoading = false
          }
          
        }, (err: any) => {
          console.log(err)
          this.messageService.openSnackBar(err, 'warn')
          this.isLoading = false
        }
      )
    }
  }


}
