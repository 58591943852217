<app-list 
  [url]="url" 
  [breadcrumbs]="breadcrumbs" 
  [configs]="configs" 
  [customTemplate]="customTemplate"
  [searchText]="searchText"
  [cb]="cb"
  >

  <ng-template #customTemplate let-member>
    <td class="justify-contents-between">
      <button class="btn btn-outline-primary me-2" (click)="previewTempSwimmer(member.syskey)">
        <i class="fa fa-eye"></i>
      </button>
  </ng-template>

</app-list>
