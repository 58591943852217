import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-logistics-orderqr',
  templateUrl: './logistics-orderqr.component.html',
  styleUrls: ['./logistics-orderqr.component.scss']
})
export class LogisticsOrderqrComponent implements OnInit {
  qrLoading:boolean=false;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  elementType = NgxQrcodeElementTypes.URL;

  element!: HTMLImageElement;

  qrvalue="  "

  constructor(
    private dialogRef: MatDialogRef<LogisticsOrderqrComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.qrvalue=data.value
    console.log(this.qrvalue);
   }

  ngOnInit(): void {
  }


  downloadQRCode() {
    const fileNameToDownload = "OrderQR";
    const htmlstring = document.getElementsByClassName('bshadow')[0].children[0];

    const base64Img = this.getImgSrc(htmlstring)

    fetch(base64Img)
       .then(res => res.blob())
       .then((blob) => {
             const url = window.URL.createObjectURL(blob);
             const link = document.createElement('a');
             link.href = url;
             link.download = fileNameToDownload;
             link.click();
       })
 }
 getImgSrc(htmlString: any){
  this.element = <HTMLImageElement>htmlString;
  return this.element.src;
}
}
