import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-monthlyfee-list',
  templateUrl: './monthlyfee-list.component.html',
  styleUrls: ['./monthlyfee-list.component.scss'],
})
export class MonthlyfeeListComponent implements OnInit {
  list: any;
  openForm: boolean = false;
  editData: any;
  searchName = ''

  breadcrumbs: any[] = [
    { name: 'Payment', url: 'payment', isActive: true },
    { name: 'Monthly Fees', url: '', isActive: false }
  ];

  constructor(
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router
  ) { }

  monthlyFeesTypeList: any[] = []
  gettingMonthlyFeesTypeList: boolean = true
  isEdit: boolean = true
  selectedMonthlyFeesType: any;

  subscription!: Subscription

  isOpenForm: boolean = false;

  ngOnInit(): void {
    console.log('here');
    this.getMonthlyFeesTypeList()
    console.log(this.monthlyFeesTypeList)
  }

  getMonthlyFeesTypeList() {
    const data = {
      searchName: this.searchName
    }
    this.subscription = this.kunyekService.getMonthlyFeeTypes(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res, 'res....')
          this.monthlyFeesTypeList = res.datalist
          this.gettingMonthlyFeesTypeList = false;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingMonthlyFeesTypeList = false;
      },
      (err) => {
        this.gettingMonthlyFeesTypeList = false;
      }
    );

  }

  newMonthlyeFees() {
    this.isEdit = false
    this.isOpenForm = true;
  }

  closeMemberForm() {
    // this.isOpenForm = false;
    // this.getMonthlyFeesTypeList()
    this.router.navigateByUrl('members/monthlyfeeslist');
  }

  editMonthlyFeetype(monthlyfee: any) {
    console.log(monthlyfee)
    this.selectedMonthlyFeesType = monthlyfee
    this.isEdit = true;
    this.isOpenForm = true;
  }

  deleteMonthlyFee(monthlyfee_id: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      console.log(dialogResult)
      if (dialogResult == true) {
        this.kunyekService.deleteMontlyFees({ monthlyfee_id: monthlyfee_id }).subscribe(
          (res: any) => {
            this.messageService.openSnackBar(res.message, 'warn')
          },
          (error: any) => {
            console.log(error)
            this.messageService.openSnackBar(error, 'warn')
          }
        )
      }
    })

  }

}
