import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { MessageService } from 'src/app/shared/services/message.service';
// import { AllInOneService } from 'projects/payroll/src/app/all-in-one.service';
// import { MessageService } from './../../shared/services/message.service';

@Component({
  selector: 'app-transportation-dashboard',
  templateUrl: './transportation-dashboard.component.html',
  styleUrls: ['./transportation-dashboard.component.css']
})
export class TransportationDashboardComponent implements OnInit {
  transportationList = [
    {
      "title": "Transportation",
      "card": [
        { "appid": "1", "appname": "Vehicles", "icon": "assets/images/vehicles.png", "routname": "transportation/vehicles", "value": "001" },
        { "appid": "2", "appname": "Drivers", "icon": "assets/images/driver.png", "routname": "transportation/driver", "value": "002" },
        { "appid": "3", "appname": "Pick up points", "icon": "assets/images/points.png", "routname": "transportation/pickuppoints", "value": "002" },
        { "appid": "4", "appname": "Drop off points", "icon": "assets/images/points.png", "routname": "transportation/dropoffpoints", "value": "002" },
        { "appid": "5", "appname": "Car Way Schedule", "icon": "assets/images/carwayschedule.png", "routname": "transportation/carwayschedules", "value": "003" },
        { "appid": "6", "appname": "Ferry Requests", "icon": "assets/images/car.png", "routname": "transportation/ferryrequests", "value": "004" },
        { "appid": "7", "appname": "Daily Requests", "icon": "assets/images/dailyrequest.png", "routname": "transportation/dailyrequests", "value": "008" },
        { "appid": "8", "appname": "Outstanding", "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/approval.png", "routname": "transportation/outstandingrequests", "value": "009" },
      ]
    },
  ]


  permission: any = [];
  istransoprationAdmin: boolean = false;

  constructor(
    public router: Router,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
  ) {
    this.istransoprationAdmin = this.allinoneService.isAdminOf("transportation");
  }

  ngOnInit(): void {
  }

  goRoute(app: any, routname: any) {
      this.router.navigateByUrl(`${routname}`);
  }
}
