<div class="container-fluid">
  <div class="content">
    <app-breadcrumb [s_breadcrumb]="'QR 360'" *ngIf="!allinoneService.isQrModule">
    </app-breadcrumb>
    <div class="d-flex flex-wrap">
      <div class="mb-3">
        <button type="button" class="btn btn-custom" (click)="openSetupForm()">
          <i class="fa fa-plus me-1"></i>New QR
        </button>
      </div>
    </div>
    <ng-container>
      <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center">

        <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search with Name/Type"
            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-2' : !isMobileView, 'ms-0': isMobileView}">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">
                {{page.name}}
              </label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="getQrList()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{qrList.length}}</span>
        </div>
      </div>
      <div class="input-group mb-2" *ngIf="isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search with Name/Type" aria-label="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
          [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
          [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <!-- <td scope="col" class="col-lg-1 col-md-1"></td> -->
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="type">Type</td>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="description">Name</td>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="userid">User ID</td>
              <!-- <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="organization">Organization</td> -->
              <!-- <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="contact">Contact File</td> -->
              <td scope="col" class="d-flex justify-content-center">Actions</td>
            </tr>
          </thead>
          <tbody *ngIf="qrListLoading">
            <tr>
              <td colspan="4" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="qrListLoading == false">
            <tr *ngIf="qrList.length == 0">
              <td colspan="4" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of (qrList | filter:searchText:'qr360') | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index">
              <!-- <td class="td-edit">
                <img [src]="'../../../assets/images/' + (item.filetype == 'pdf' ? 'pdf' : 'image') + '.png'"
                  width="20px" />
              </td> -->
              <td class="td-data td-edit" (click)="openSetupForm(item)">
                <span [title]="item.typetodisplay">{{item.typetodisplay}}</span>
              </td>
              <td class="td-data " style="max-width: fit-content;">
                <span [title]="item.description">
                  {{item.description}}
                </span>
              </td>
              <td class="td-data">
                <span [title]="item.accessuserid">{{
                  item.accessuserid
                }}</span>
              </td>
              <!-- <td class="td-data">
                <span [title]="item.orgname">{{
                  item.orgname
                }}</span>
              </td>
              <td class="td-data">
                <span [title]="item.contactfile">{{item.contactfile == '' ? '-' : item.contactfile }}</span>
              </td> -->

              <td class="d-flex justify-content-center">


                <button class="btn btn-outline-info me-2" (click)="openQR(item.qrurl)" title="QR Code">
                  <i class="fa fa-qrcode"></i>
                </button>

                <button class="btn btn-outline-primary me-2" (click)="openSetupForm(item)" title="Edit">
                  <i class='fa fa-edit'></i>
                </button>

                <button class="btn btn-outline-danger" (click)="deleteQr(item.id, index)" title="Delete">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="p = $event"
        *ngIf="(qrList | filter:searchText:'qr360').length > itemPerPage" previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-container>
  </div>
</div>