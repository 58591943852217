<div class="container-fluid">
  <div class="content">
    <!-- <ng-container *ngIf="isAdmin">
      <app-team-breadcrumb *ngIf="!isAdding" s_breadcrumb="Late And Early Out Configuration">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="isAdding" s_breadcrumb="Late And Early Out Configuration"
        [t_breadcrumb]="isEditing ? 'Edit' : 'New' " (emit)="cancel()"></app-team-breadcrumb>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb *ngIf="!isAdding"
        [s_breadcrumb]="'Late And Early Out Configuration'"></app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="isAdding" [s_breadcrumb]="'Late And Early Out Configuration'"
        [t_breadcrumb]="isEditing ? 'Edit' : 'New'" (emit)="cancel()"></app-office-breadcrumb>
    </ng-container> -->
    <app-team-breadcrumb *ngIf="!isAdding" s_breadcrumb="Late And Early Out Configuration">
    </app-team-breadcrumb>
    <app-team-breadcrumb *ngIf="isAdding" s_breadcrumb="Late And Early Out Configuration"
      [t_breadcrumb]="isEditing ? 'Edit' : 'New' " (emit)="cancel()"></app-team-breadcrumb>
    <ng-container *ngIf="isAdding; else listView">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()">
          Cancel
        </button>
        <app-button-loader class="mb-3" (click)="submitForm()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
      <div style="max-width: max-content;">
        <div class="card card-body my-card w-100 mb-3">
          <!-- <div class="row"> -->
          <div class="d-flex flex-wrap align-items-start">
            <div class="mb-3 flex-fill">
              <label for="name" class="form-label">Name</label>
              <input type="text" class="form-control" id="name" [(ngModel)]="leoutForm.name" name="name" #name="ngModel"
                [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" required />
              <div class="invalid-feedback" *ngIf="
                        name.invalid ||
                        ((name.dirty || name.touched) && formSubmitted)
                      ">
                <div *ngIf="name.errors?.required">
                  {{ allinoneService.input_err_msg }}
                </div>
              </div>
            </div>
            <div class="mb-3 flex-fill">
              <label for="description" class="form-label">Description</label>
              <input class="form-control" id="description" [(ngModel)]="leoutForm.description" name="description"
                type="text" />
            </div>
            <div class="mb-3 flex-fill">
              <label for="startdate" class="form-label">Start date</label>
              <!-- <input class="form-control" type="date" id="startdate" [(ngModel)]="leoutForm.startdate" name="startdate"
                (ngModelChange)="compareDate()" #startdate="ngModel" max="9999-12-31" /> -->
              <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
                [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="leoutForm.startdate"
                style="min-width: 200px;" required #startdate="ngModel" (ngModelChange)="compareDate()">
            </div>
            <div class="mb-3 flex-fill">
              <label for="enddate" class="form-label">End date</label>
              <!-- <input class="form-control" type="date" id="enddate" [(ngModel)]="leoutForm.enddate"
                [min]="leoutForm.startdate" name="enddate" #enddate="ngModel" max="9999-12-31" /> -->
              <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
                [bsConfig]="generalDatePickerConfig" [(ngModel)]="leoutForm.enddate" [minDate]="leoutForm.startdate"
                style="min-width: 200px;" required #enddate="ngModel">
            </div>
          </div>
          <!-- </div> -->
        </div>
        <div class="d-flex flex-row flex-wrap" style="column-gap: 1rem;">
          <div class="card card-body my-card mb-3" style="max-width: max-content;">
            <!-- <div class="row"> -->
            <div class="d-flex flex-wrap align-items-center">
              <div class="info mb-3 me-5">Late Time</div>
              <div class="">
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" id="latetime" [(ngModel)]="leoutForm.latetime"
                    name="latetime">
                  <label class="form-check-label ms-1" for="latetime"> Late
                  </label>
                </div>
              </div>
            </div>
            <div class="">
              <ng-container *ngFor="let late of lateConfig; let i=index; let first=first">
                <div class="d-flex gap-3 align-items-end">
                  <div class="mb-3">
                    <label [attr.for]="'latetime'+i" class="form-label">Late
                      Time {{ i + 1 }} (Mins)</label>
                    <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                      [attr.id]="'latetime'+i" [attr.name]="'latetime'+i" [(ngModel)]="late.time" min="0">
                  </div>
                  <div class="mb-3">
                    <label [attr.for]="'latetime'+i" class="form-label">Late Count</label>
                    <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                      [attr.id]="'latetime'+i" [attr.name]="'latetime'+i" [(ngModel)]="late.count" min="0">
                  </div>

                  <!-- <div class="mb-3">
                    <button class="btn btn-custom prize-btn" style="width:50px !important;"
                      (click)="removeSubmitConfig(i, 'latetime')">
                      <mat-icon>remove_circle</mat-icon>
                    </button>
                  </div> -->
                </div>
              </ng-container>
              <!-- <div class="row">
                <div class="">
                  <button class="btn btn-custom" (click)="addSubmitConfig('latetime')">
                    <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span>
                  </button>
                </div>
              </div> -->
            </div>
            <div class="form-check mb-3">
              <input class="form-check-input" type="checkbox" id="latehalfdayabsent"
                [(ngModel)]="leoutForm.latehalfdayabsent" name="latehalfdayabsent">
              <label class="form-check-label ms-1" for="latehalfdayabsent"> Over Late Time Half Day Absent
              </label>
            </div>
            <!-- </div> -->
          </div>
          <div class="card card-body my-card mb-3" style="max-width: max-content;">
            <div class="">
              <div class="d-flex flex-wrap align-items-center">
                <div class="info mb-3 me-5">Early Out Time</div>
                <div class="">
                  <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" id="earlyout" [(ngModel)]="leoutForm.earlyouttime"
                      name="earlyout">
                    <label class="form-check-label ms-1" for="earlyout"> Early Out
                    </label>
                  </div>
                </div>
              </div>
              <div class="">
                <ng-container *ngFor="let earlyout of earlyOutConfig; let i=index; let first=first">
                  <div class="d-flex gap-3 align-items-end">
                    <div class="mb-3">
                      <label [attr.for]="'earlyout'+i" class="form-label">Time {{ i + 1 }} (Mins)
                      </label>
                      <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                        [attr.id]="'earlyout'+i" [attr.name]="'earlyout'+i" [(ngModel)]="earlyout.time" min="0">
                    </div>
                    <div class="mb-3">
                      <label [attr.for]="'earlyout'+i" class="form-label">Count</label>
                      <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                        [attr.id]="'earlyout'+i" [attr.name]="'earlyout'+i" [(ngModel)]="earlyout.count" min="0">
                    </div>
                    <!-- <div class="mb-3">
                      <button class="btn btn-custom prize-btn" style="width:50px !important;"
                        (click)="removeSubmitConfig(i, 'earlyouttime')">
                        <mat-icon>remove_circle</mat-icon>
                      </button>
                    </div> -->
                  </div>
                </ng-container>
                <!-- <div class="row">
                  <div class="col-md-12">
                    <button class="btn btn-custom" (click)="addSubmitConfig('earlyouttime')">
                      <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span>
                    </button>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="form-check mb-3">
              <input class="form-check-input" type="checkbox" id="earlyouthalfdayabsent"
                [(ngModel)]="leoutForm.earlyouthalfdayabsent" name="earlyouthalfdayabsent">
              <label class="form-check-label ms-1" for="earlyouthalfdayabsent"> Over Early Out Time Half Day Absent
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-body my-card post-card">
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <div class="info">
            Post
          </div>
          <ng-container>
            <div>
              <button class="btn btn-custom" (click)="openPopUp()"><i class="fa fa-plus me-1"></i> Add</button>
            </div>
          </ng-container>
        </div>
        <div class="table-responsive" matSort (matSortChange)="sortPost($event)">
          <table class="table table-responsive table-borderless">
            <thead class="table-header">
              <tr>
                <td scope="col" class="col-md-2">No.</td>
                <td scope="col" class="col-md-5" mat-sort-header="name">Name</td>
                <td scope="col" class="col-md-5" mat-sort-header="description">Post</td>
                <ng-container>
                  <td scope="col" class="d-flex justify-content-center">Action</td>
                </ng-container>
              </tr>
            </thead>

            <tbody>
              <tr *ngIf="postList.length == 0">
                <td colspan="3" class="nodata">Empty</td>
              </tr>
              <tr *ngFor="let post of postList; let index = index">
                <td class="td-data td-edit">
                  <span [title]="post.no">{{index + 1}}</span>
                </td>
                <td class="td-data">
                  <span [title]="post.name">{{post.name}}</span>
                </td>
                <td class="td-data">
                  <span [title]="post.description">{{post.description}}</span>
                </td>
                <ng-container>
                  <td class="d-flex justify-content-center align-items-center">
                    <button class="btn btn-outline-danger" (click)="deletePost(index)">
                      <i class='fa fa-trash-o'></i>
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
    <ng-template #listView>
      <div class="d-flex align-items-center mb-3">
        <button class="btn btn-custom me-auto" (click)="newLateAndEarlyout()">
          <i class="fa fa-plus me-1"></i> New
        </button>
      </div>
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description"
            aria-label="Search by Name/Description" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ ( leoutList | filter : searchText: 'lateandearlyout' ).length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="leoutList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-4" mat-sort-header="name">Name</td>
              <td scope="col" class="col-md-4" mat-sort-header="description">Description</td>
              <td scope="col" class="col-md-4" mat-sort-header="latetime">Calculate Late Time</td>
              <td scope="col" class="col-md-4" mat-sort-header="earlyouttime">Calculate Early Out Time</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="leoutLoading">
            <tr>
              <td colspan="4" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!leoutLoading">
            <tr *ngIf="leoutList.length == 0">
              <td colspan="4" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let leout of leoutList | filter : searchText: 'lateandearlyout' | paginate: { itemsPerPage: itemPerPage , currentPage : currentPage } ; let index = index">
              <td class="td-data td-edit" (click)="editleout(leout)">
                <span [title]="leout.name">{{ leout.name }}</span>
              </td>
              <td class="td-data">
                <span [title]="leout.description">{{
                  leout.description
                  }}</span>
              </td>
              <td class="td-data">
                <span [title]="leout.latetime">{{
                  leout.uselate ? "Yes" : "No"
                  }}</span>
              </td>
              <td class="td-data">
                <span [title]="leout.earlyouttime">{{
                  leout.useearlyout ? "Yes" : "No"
                  }}</span>
              </td>
              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2" (click)="editleout(leout)">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-outline-danger" (click)="deleteLeout(leout.configid)">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="leoutList.length > itemPerPage"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-template>
  </div>
</div>