import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-approval-export',
  templateUrl: './approval-export.component.html',
  styleUrls: ['./approval-export.component.scss'],
})
export class ApprovalExportComponent implements OnInit {
  exportingresult: boolean = false;

  requestTypeList: any[] = [];
  requestSubTypeList: any[] = [];
  teamMember: any[] = [];

  subscriptions = new SubSink();

  currentDate = new Date(Date.now());

  generalDatePickerConfig = this.allinoneService.datePickerConfig;

  claimRoleView : boolean = false;


  searchForm: any = {
    orgid: '',
    domainid: '',
    startdate: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1),
    enddate: this.currentDate,
    requeststatus: '',
    requesttype: 'VMAOWEFAMSVNAOWI',
    requestsubtype: '',
    teammember: false,
    approvedstartdate: this.currentDate,
    approvedenddate: this.currentDate,
    paymentstatus: '',
  };

  hideRequest = false;


  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Submitted',
      value: '001',
    },
    {
      name: 'Approved',
      value: '002',
    },
    {
      name: 'Rejected',
      value: '003',
    },
    {
      name: 'Completed',
      value: '004',
    },
  ];

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<ApprovalExportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.claimRoleView = data.claimRoleView;
    this.requestTypeList = data.requestList;
    this.teamMember = data.teamMemberList;
    this.searchForm.orgid = data.orgid;
    this.searchForm.domainid = data.domainid;
    this.searchForm.requeststatus = data.requeststatus;
    if (data.requesttype != '') {
      this.searchForm.requesttype = data.requesttype;
    } else {
      this.searchForm.requesttype = this.requestTypeList[0].requesttypeid;
    }
    if (data.startdate) {
      this.searchForm.startdate = data.startdate;
      this.searchForm.enddate = data.enddate;
      this.searchForm.requesttype = data.requesttype!==''? data.requesttype: "VMAOWEFAMSVNAOWI";
      this.searchForm.requestsubtype = data.requestsubtype;
      this.searchForm.teammember = data.teammember;
      this.searchForm.paymentstatus = data.paymentstatus;
      this.searchForm.approvedstartdate = data.approvedstartdate;
      this.searchForm.approvedenddate = data.approvedenddate;
    }

    this.hideRequest = data.hideRequest;
  }

  ngOnInit(): void {
    // console.log(this.searchForm);    
    // if (
    //   this.searchForm.requesttype != 'NCMLEIWHRNVIE' &&
    //   this.searchForm.requesttype != 'WOEIEPPLMXNW'
    // ) {
    //   // this.searchForm.requesttype = this.requestTypeList[0].requesttypeid;
    //   this.requestSubTypeList = this.requestTypeList[0].child;
    //   this.searchForm.requestsubtype = '';
    // } else {
    //   this.requestSubTypeList = this.requestTypeList.find(
    //     (x: any) => x.requesttypeid == this.searchForm.requesttype
    //   ).child;
    // }
    for (var i = 0; i < this.requestTypeList.length; i++) {
      if (this.requestTypeList[i]['requesttypeid'] == this.searchForm.requesttype && this.requestTypeList[i]['subtype']) {
        this.requestSubTypeList = this.requestTypeList[i].child;
      }
    }
  }

  // checkSubType(id: any)  {
  //   for(var i = 0; i < this.requestTypeList.length; i++) {
  //     if(id == this.requestTypeList[i]['requesttypeid']) {
  //       if(this.requestTypeList[i]['subtype']) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     } else {
  //       return false;
  //     }
  //   }
  // }

  checkSubType(id: any) {
    var sub = this.requestTypeList.find(
      (x: any) => x.requesttypeid == id
    ).subtype;
    return sub;
  }

  requestTypeChange() {
    // check with id 
    // if (
    //   this.searchForm.requesttype == 'NCMLEIWHRNVIE' ||
    //   this.searchForm.requesttype == 'WOEIEPPLMXNW'
    // ) {
    //   this.requestSubTypeList = this.requestTypeList.find(
    //     (x: any) => x.requesttypeid == this.searchForm.requesttype
    //   ).child;
    //   this.searchForm.requestsubtype = '';
    // } else {
    //   this.requestSubTypeList = [];
    //   this.searchForm.requestsubtype = '';
    // }


    this.requestSubTypeList = [];
    this.searchForm.requestsubtype = '';
    for (var i = 0; i < this.requestTypeList.length; i++) {
      if (this.requestTypeList[i]['requesttypeid'] == this.searchForm.requesttype && this.requestTypeList[i]['subtype']) {
        this.requestSubTypeList = this.requestTypeList[i].child;
      }
    }
    console.log(this.requestSubTypeList);
    console.log(this.requestTypeList)
  }

  getSubTypeName(subtype: any) {
    return this.requestSubTypeList.find((x: any) => x.requesttypeid == subtype)
      ?.requesttypename;
  }

  search() {
    if ((this.searchForm.startdate && !this.searchForm.enddate) || (this.searchForm.enddate && !this.searchForm.startdate)) {
      return this.messageService.openSnackBar(
        'Please select both start date and end date.',
        'warn'
      );
    }
    if (this.searchForm.requeststatus == '002' &&
      (this.searchForm.approvedstartdate &&
        !this.searchForm.approvedenddate) ||
      (!this.searchForm.approvedstartdate &&
        this.searchForm.approvedenddate)
    ) {
      return this.messageService.openSnackBar(
        'Please select both start and end approved date.',
        'warn'
      );
    }
    this.dialogRef.close(this.searchForm);
  }

  export() {
    if (this.exportingresult) {
      return;
    }
    if (
      (this.searchForm.approvedstartdate &&
        this.searchForm.approvedenddate == '') ||
      (this.searchForm.approvedstartdate == '' &&
        this.searchForm.approvedenddate)
    ) {
      return this.messageService.openSnackBar(
        'Please select both start and end approved date.',
        'warn'
      );
    }
    this.dialogRef.disableClose = true;
    var _exportList = [];
    this.exportingresult = true;
    let data = {
      orgid: this.searchForm.orgid,
      domainid: this.searchForm.domainid,
      requestsubtype: this.searchForm.requestsubtype,
      requesttype: this.searchForm.requesttype,
      startdate: this.allinoneService.formatDate(this.searchForm.startdate),
      enddate: this.allinoneService.formatDate(this.searchForm.enddate),
      approvedstartdate: this.allinoneService.formatDate(
        this.searchForm.approvedstartdate
      ),
      approvedenddate: this.allinoneService.formatDate(
        this.searchForm.approvedenddate
      ),
      end: '',
      roleid: this.allinoneService.getSelectedRole(),
      requeststatus: '002',
      toexport: true,
      teammember:
        this.searchForm.teammember == 'true' ||
          this.searchForm.teammember == true
          ? true
          : false,
      paymentstatus:
        this.searchForm.paymentstatus == '1'
          ? true
          : this.searchForm.paymentstatus == '2'
            ? false
            : '',
    };

    this.subscriptions.sink = this.kunyekService
      .getApprovalList(data)
      .subscribe(
        (res) => {
          if (res.returncode == 300) {
            var requesttypename = this.requestTypeList.find(
              (x: any) => x.requesttypeid == this.searchForm.requesttype
            )?.requesttypename;
            var requestsubtypename = '';
            if (this.searchForm.requestsubtype != '') {
              requestsubtypename = this.requestSubTypeList.find(
                (x: any) => x.requesttypeid == this.searchForm.requestsubtype
              )?.requesttypename;
            }
            // _exportList = res.requestlist;
            _exportList = res.approvallist;
            var exdata: any = [];
            // var startd = formatDate(new Date("this".allinoneService.formatDBToShowInEdit(this.detailForm.date, '/')), "dd-MM-yyyy", 'en-US').toString()
            // var temp = this.detailForm.type == 'in' ? 'Time In' :  'Time Out';
            var name =
              (requestsubtypename ? requestsubtypename + ' ' : '') +
              requesttypename +
              ' Report' +
              '.xlsx';
            if (this.searchForm.requesttype == 'NCMLEIWHRNVIE') {
              _exportList.map((item: any) => {
                var temp = {
                  ID: item.employeeid,
                  Name: item.username || item.userid,
                  'Ref No': item.requestrefno,
                  'Leave Type': this.getSubTypeName(item.requestsubtype),
                  'Applied Date':
                    item.startdate != ''
                      ? this.allinoneService.formatDBToShow(item.startdate)
                      : '',
                  'Start Date':
                    item.startdate != ''
                      ? this.allinoneService.formatDBToShow(item.startdate)
                      : '',
                  'Start AM/PM': item.starttime,
                  'End Date':
                    item.enddate != ''
                      ? this.allinoneService.formatDBToShow(item.enddate)
                      : '',
                  'End AM/PM': item.endtime,
                  'Duration': item.duration,
                  'Approved Date': this.allinoneService.formatDBToShow(
                    item.approvedtime.slice(0, 8)
                  ),
                  'Approved By': item.approverusername,
                  'Handover Person': '',
                  Reason: '',
                  Remark: item.remark,
                };
                if (item.requeststatus == '002') {
                  exdata.push(temp);
                }
              });
            } else if (this.searchForm.requesttype == 'WOEIEPPLMXNW') {
              //Phone Charges
              if (requestsubtypename == 'Phone Charges') {
                _exportList.map((item: any) => {
                  var temp = {
                    ID: item.employeeid,
                    Name: item.username || item.userid,
                    'Ref No': item.requestrefno,
                    'Applied Date':
                      item.claimdate != ''
                        ? this.allinoneService.formatDBToShow(item.claimdate)
                        : '',
                    'Claims Type': requestsubtypename,
                    Type: 'Claim',
                    'From Date': '',
                    'To Date': '',
                    Amount: item.amount,
                    Currency: item.currencyname,
                    Description: item.remark,
                    'Approved Date':
                      item.approvedtime != ''
                        ? this.allinoneService.formatDBToShow(
                          item.approvedtime.slice(0, 8)
                        )
                        : '',
                    'Approved By ID': item.approveremployeeid,
                    'Approved By Name': item.approverusername,
                  };
                  if (item.requeststatus == '002') {
                    exdata.push(temp);
                  }
                });
                // Taxi Fare
              } else if (requestsubtypename == 'Taxi Fare') {
                _exportList.map((item: any) => {
                  var temp = {
                    ID: item.employeeid,
                    Name: item.username || item.userid,
                    'Ref No': item.requestrefno,
                    'Applied Date': item.claimdate
                      ? this.allinoneService.formatDBToShow(item.claimdate)
                      : '',
                    'Claims Type': this.getSubTypeName(item.requestsubtype),
                    Type: 'Claim',
                    'From Date':
                      item.date != ''
                        ? this.allinoneService.formatDBToShow(item.date)
                        : '',
                    'To Date': '',
                    Time: '',
                    'From Place': '',
                    'To Place': '',
                    Amount: item.amount,
                    Currency: item.currencyname,
                    Description: item.remark,
                    'Approved Date':
                      item.approvedtime != ''
                        ? this.allinoneService.formatDBToShow(
                          item.approvedtime.slice(0, 8)
                        )
                        : '',
                    'Approved By ID': item.approveremployeeid,
                    'Approved By Name': item.approverusername,
                  };
                  if (item.requeststatus == '002') {
                    exdata.push(temp);
                  }
                });
              } else {
                _exportList.map((item: any) => {
                  var temp = {
                    ID: item.employeeid,
                    Name: item.username || item.userid,
                    'Ref No': item.requestrefno,
                    'Applied Date':
                      item.claimdate != ''
                        ? this.allinoneService.formatDBToShow(item.claimdate)
                        : '',
                    'Claims Type': this.getSubTypeName(item.requestsubtype),
                    Type: 'Claim',
                    Amount: item.amount,
                    Currency: item.currencyname,
                    Description: item.remark,
                    'Approved Date': this.allinoneService.formatDBToShow(
                      item.approvedtime.slice(0, 8)
                    ),
                    'Approved By ID': item.approveremployeeid,
                    'Approved By Name': item.approverusername,
                  };
                  if (item.requeststatus == '002') {
                    exdata.push(temp);
                  }
                });
              }
            } else {
              _exportList.map((item: any) => {
                var temp = {
                  ID: item.employeeid,
                  Name: item.username || item.userid,
                  'Applied Date': this.allinoneService.formatDBToShow(
                    item.claimdate
                  ),
                  'Claims Type': this.getSubTypeName(item.requestsubtype),
                  Type: 'Claim',
                  'From Date': item.date
                    ? this.allinoneService.formatDBToShow(item.date)
                    : '',
                  'To Date': item.date
                    ? this.allinoneService.formatDBToShow(item.date)
                    : '',
                  'Start Time': item.starttime
                    ? this.allinoneService.formatTimeToShow(item.starttime)
                    : '',
                  'End Time': item.endtime
                    ? this.allinoneService.formatTimeToShow(item.endtime)
                    : '',
                  Amount: item.amount,
                  Currency: item.currencyname,
                  Description: '',
                  'Approved Date': this.allinoneService.formatDBToShow(
                    item.approvedtime.slice(0, 8)
                  ),
                  'Approved By ID': item.approveremployeeid,
                  'Approved By Name': item.approverusername,
                };
                if (item.requeststatus == '002') {
                  exdata.push(temp);
                }
              });
            }
            this.allinoneService.exportEcecl(exdata, name);
          } else {
            this.messageService.openSnackBar(
              res.message || res.stutus || 'Server Error',
              'warn'
            );
          }
          this.exportingresult = false;
          this.dialogRef.disableClose = false;
        },
        (err) => {
          this.exportingresult = false;
          this.dialogRef.disableClose = false;
        }
      );
  }

  removeStartEndDate() {
    this.searchForm.startdate = '';
    this.searchForm.enddate = '';
  }

  removeDate() {
    this.searchForm.approvedstartdate = '';
    this.searchForm.approvedenddate = '';
  }
}
