<div class="container-fluid">
    <div class="content">
        <!-- <app-breadcrumb [s_breadcrumb]="'App Restrictions'">
        </app-breadcrumb> -->
        <app-team-breadcrumb [s_breadcrumb]="'App Restrictions'">
        </app-team-breadcrumb>
        <div class="d-flex flex-wrap">
            <div class="mb-3">
                <button type="button" class="btn btn-custom" (click)="openSetupForm()">
                    <i class="fa fa-plus me-1"></i>Add
                </button>
            </div>
        </div>
        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
            <table class="table table-responsive table-borderless">
                <thead class="table-header">
                    <tr>
                        <!-- <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="type">Type</td> -->
                        <td scope="col" class="col-lg-3 col-md-3">User ID/Organization/Version</td>
                        <td scope="col" class="col-lg-3 col-md-3">Type</td>
                        <td scope="col" class="col-lg-3 col-md-3">OS</td>
                        <td scope="col" class="col-lg-3 col-md-3">Message</td>
                        <td scope="col" class="col-lg-3 col-md-3">Created By</td>
                        <td scope="col" class="col-lg-3 col-md-3">Modified By</td>
                        <td scope="col" class="d-flex justify-content-center">Action</td>
                    </tr>
                </thead>
                <tbody *ngIf="gettingData">
                    <tr>
                        <td colspan="7" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="gettingData == false">
                    <tr *ngIf="restrictionList.length == 0">
                        <td colspan="7" class="nodata">Empty</td>
                    </tr>
                    <tr *ngFor="let item of restrictionList; let index = index">
                        <!-- <td class="td-data" style="max-width: fit-content;">
                            <span *ngIf="item.version">Update</span>
                            <span *ngIf="item.version == ''">Sign Out</span>
                        </td> -->
                        <td class="td-data td-edit" (click)="openSetupForm(item)">
                            <span *ngIf="item.version" style="color: var(--main-color)">Version {{item.exactversion ? item.version : item.version + ' and lower'}}</span>
                            <ng-container *ngIf="item.version == ''">
                                <span *ngIf="item.type == '001'">
                                    {{item.userid}}
                                </span> 
                                <span *ngIf="item.type == '002'">
                                    {{item.orgname}}
                                </span>
                            </ng-container>
                        </td>
                        <td class="td-data">
                            <span *ngIf="item.version" class="status" [ngClass]="{'status-red' : item.forcedupdate, 'status-gray' : !item.forcedupdate}">
                                {{item.forcedupdate ? 'Force Update' : 'Warn Update'}}
                            </span>
                            <span *ngIf="item.version == ''" class="status status-red">
                                Force Sign Out
                            </span>
                        </td>
                        <td class="td-data">
                            <span *ngIf="item.version">
                               {{item.os == '003' ? 'Andriod, iOS' : item.os == '001' ? 'Andriod' : 'iOS'}} 
                            </span>
                        </td>
                        <td class="td-data">
                            <span>
                                {{item.message}}
                            </span>
                        </td>
                        <td class="td-data">
                            <span>
                                {{item.createdby}}
                            </span>
                        </td>
                        <td class="td-data">
                            <span>
                                {{item.modifiedby}}
                            </span>
                        </td>
                        <td class="d-flex justify-content-center">
                            <button class="btn btn-outline-primary me-2" (click)="openSetupForm(item)" title="Edit" [disabled]="item.version == ''">
                                <i class='fa fa-edit'></i>
                            </button>

                            <button class="btn btn-outline-danger" (click)="deleteRestriction(item, index)" title="Delete">
                                <i class="fa fa-trash-o"></i>
                            </button>


                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>