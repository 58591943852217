import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-late-earlyout-time-report',
  templateUrl: './late-earlyout-time-report.component.html',
  styleUrls: ['./late-earlyout-time-report.component.scss'],
})
export class LateEarlyoutTimeReportComponent implements OnInit, OnDestroy {
  isLate: boolean = false;

  mobileViewWidth: any = 426;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  itemPerPage: number = 100;
  currentPage: number = 1;
  searchText: string = '';
  searchLoading: boolean = false;
  orgid: string = '';
  getList!: Subscription;

  reportDataList: any = [];
  organizations: any = [];

  gettingMemberPosition: boolean = false;
  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];
  monthlist: any[] = [];
  openfilter: boolean = false;
  getMemberSub!: Subscription;

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  formatToShow = 'yyyy-MM-dd';
  dateRangeStartDate: any = '';
  minDate = new Date();
  date = new Date();
  // get last 30th day from current date
  startdate = new Date();
  firstDay = new Date(this.startdate.setDate(this.startdate.getDate() - 30));

  currentStartDate: any = this.datePipe.transform(this.firstDay, 'yyyy-MM-dd');
  currentEndDate: any = this.datePipe.transform(this.date, 'yyyy-MM-dd');

  submitForm = {
    // date : this.currentDate,
    // enddate : this.lastDay,
    startdate: this.firstDay,
    enddate: this.date,
    department: 'All',
    division: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
    requestFilterName: 'No Request',
    requestFilterValue: '',
    status: '001'
  };

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  requestFilterList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Submitted',
      value: '001',
    },
    {
      name: 'Approved',
      value: '002',
    },
    {
      name: 'Rejected',
      value: '003',
    },
    {
      name: 'No Request',
      value: '005',
    },
  ];

  userStatusFilterList = this.allinoneService.userStatusFilterList;

  subscriptions = new SubSink();

  // websockect
  connectSocketLoading: boolean = true;
  private socket: WebSocket | undefined;
  public socketConnectionStatus: string = 'disconnected';
  public socketReceivedMessage: string = '';
  connectionId: string = '';
  drsocketfileurl: any = '';
  updateCId: boolean = false;
  socketConnectTime: any;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe,
    private datePipe: DatePipe,
    private http: HttpClient
  ) {
    this.route.data.subscribe((data: any) => {
      console.log('rote data');
      console.log(data);

      if (data.isFromLate) {
        this.isLate = true;
      } else {
        this.isLate = false;
      }
    });
  }

  ngOnInit(): void {
    var addDays = 35;
    this.dateRangeStartDate = this.currentStartDate;
    var maxEndDate = new Date(this.dateRangeStartDate);
    maxEndDate.setTime(maxEndDate.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(
    //   maxEndDate,
    //   this.formatToShow,
    //   'en-US'
    // ).toString();
    this.minDate = maxEndDate;
    // this.monthlist = this.getCurrentAndPreviousYearMonths();
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
  }

  ngOnDestroy(): void {
    this.socket?.close(3001);
    this.getList && this.getList.unsubscribe();
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.subscriptions.unsubscribe();
    clearTimeout(this.socketConnectTime);
  }

  search() {
    this.connectWebSocket();
    this.closeSocketWithTime();
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '004',
    };
    this.kunyekService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      this.updateCId = false;
      console.log(res);
    });
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.searchLoading = true;
    this.connectSocketLoading = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      // this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionId = tempData.connectionId;
        if (this.updateCId) {
          this.updateConnectionID();
        }
        this.LateEarlyoutReportData();
      } else {
        this.drsocketfileurl = tempData.fileurl;

        if (this.drsocketfileurl) {
          this.http.get(this.drsocketfileurl).subscribe((res: any) => {
            console.log('Your get data res');
            console.log(res);
            this.reportDataList = res;
            this.searchLoading = false;
            this.connectionId = '';
            this.socket?.close(3001);
            clearTimeout(this.socketConnectTime);
          });
        }
      }
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });
  }

  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(() => {
      if (this.socketConnectionStatus != 'disconnected') {
        this.socket?.close(3001);
        this.connectionId = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoading = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  LateEarlyoutReportData() {
    if (!this.submitForm.startdate) {
      return this.messageService.openSnackBar(
        'Start Date cannot be blank.',
        'warn'
      );
    }
    if (!this.submitForm.enddate) {
      return this.messageService.openSnackBar(
        'End Date cannot be blank.',
        'warn'
      );
    }
    this.searchLoading = true;
    var data = {
      orgid: this.orgid,
      startdate: this.submitForm.startdate
        ? this.allinoneService.parseDate(this.submitForm.startdate)
        : '',
      enddate: this.submitForm.enddate
        ? this.allinoneService.parseDate(this.submitForm.enddate)
        : '',
      department:
        !this.openfilter || this.submitForm.department == 'All'
          ? ''
          : this.submitForm.department,
      division:
        !this.openfilter || this.submitForm.division == 'All'
          ? ''
          : this.submitForm.division,
      teamid:
        !this.openfilter || this.submitForm.teamid == 'All'
          ? ''
          : this.submitForm.teamid,
      costcenter:
        !this.openfilter || this.submitForm.costcenter == 'All'
          ? ''
          : this.submitForm.costcenter,
      subdivision:
        !this.openfilter || this.submitForm.subdivision == 'All'
          ? ''
          : this.submitForm.subdivision,
      requestfilter: this.submitForm.requestFilterValue,
      searchtype: this.isLate ? '001' : '002',
      connectionid: this.connectionId,
      activestatus: this.openfilter ? this.submitForm.status : '001',
    };
    // console.log(data);
    // return
    this.getList && this.getList.unsubscribe();
    this.getList = this.kunyekService.getLateEarlyoutTimeReport(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          // this.reportDataList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
          this.searchLoading = false;
        }
      },
      (err: any) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.searchLoading = false;
      }
    );
  }

  toggleFilter() {
    if (
      this.departmentList.length < 2 
      // ||
      // this.divisionList.length < 2 ||
      // this.teamidList.length < 2 ||
      // this.costCenterList.length < 2 ||
      // this.subDivisionList.length < 2
    ) {
      this.getMemberPosition();
    }
    this.openfilter = !this.openfilter;
  }

  departmentChange(dep: any) {
    if (this.submitForm.department != dep) {
      this.submitForm.department = dep;
      this.submitForm.division = 'All';
      this.submitForm.teamid = 'All';
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  divisionChange(divi: any) {
    if (this.submitForm.division != divi) {
      this.submitForm.division = divi;
      this.submitForm.teamid = 'All';
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  teamidChange(teamid: any) {
    if (this.submitForm.teamid != teamid) {
      this.submitForm.teamid = teamid;
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  costCenterChange(costcenter: any) {
    if (this.submitForm.costcenter != costcenter) {
      this.submitForm.costcenter = costcenter;
      this.submitForm.subdivision = 'All';
    }
  }

  subDivisionChange(subdivision: any) {
    if (this.submitForm.subdivision != subdivision) {
      this.submitForm.subdivision = subdivision;
    }
  }

  requestFilterChange(d: any) {
    if (this.submitForm.requestFilterValue != d.value) {
      this.submitForm.requestFilterName = d.name;
      this.submitForm.requestFilterValue = d.value;
    }
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.getMemberSub = this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.departmentList = this.departmentList.concat(res.departmentlist);
          this.divisionList = this.divisionList.concat(res.divisionlist);
          this.teamidList = this.teamidList.concat(res.teamidlist);
          this.costCenterList = this.costCenterList.concat(res.costcenterlist);
          this.subDivisionList = this.subDivisionList.concat(res.subdivision);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingMemberPosition = false;
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  clear() {
    this.searchText = '';
  }

  sortData(sort: Sort) {
    const data = this.reportDataList;
    if (!sort.active || sort.direction === '') {
      this.reportDataList = data;
      return;
    }
    this.reportDataList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'employeeid') {
        return this.allinoneService.compare(a.employeeid, b.employeeid, isAsc);
      } else if (sort.active == 'username') {
        return this.allinoneService.compare(
          a.username ? a.username : a.userid,
          b.username ? b.username : b.userid,
          isAsc
        );
      } else if (sort.active == 'date') {
        return this.allinoneService.compare(a.date, b.date, isAsc);
      } else if (sort.active == 'shifttime') {
        return this.allinoneService.compare(a.shifttime, b.shifttime, isAsc);
      } else if (sort.active == 'time') {
        return this.allinoneService.compare(a.time, b.time, isAsc);
      } else if (sort.active == 'latetime') {
        return this.allinoneService.compare(
          a.actuallatemin,
          b.actuallatemin,
          isAsc
        );
      } else if (sort.active == 'requestduration') {
        return this.allinoneService.compare(
          a.requestduration,
          b.requestduration,
          isAsc
        );
      } else if (sort.active == 'requeststatus') {
        return this.allinoneService.compare(
          a.requeststatus,
          b.requeststatus,
          isAsc
        );
      }
      return 0;
    });
  }

  downloadSheet() {
    var exdata: any = [];
    // var date = this.allinoneService.formatDBToShow(this.allinoneService.formatDate(this.submitForm.date));
    // var startdate = this.allinoneService.formatDBToShow(this.allinoneService.formatDate(this.submitForm.startdate));
    // var enddate = this.allinoneService.formatDBToShow(this.allinoneService.formatDate(this.submitForm.enddate));
    // var name = 'Time Card Report (' + startdate + '-' + enddate + ').xlsx'
    const startdate = this.submitForm.startdate
      ? this.allinoneService.formatDBToShow(
          this.allinoneService.parseDate(this.submitForm.startdate)
        )
      : '';
    const enddate = this.submitForm.enddate
      ? this.allinoneService.formatDBToShow(
          this.allinoneService.parseDate(this.submitForm.enddate)
        )
      : '';
    var name =
      (this.isLate ? 'Late Time Report' : 'Early Out Time Report') +
      ' (' +
      startdate +
      ' - ' +
      enddate +
      ').xlsx';
    // var name = 'Time Card Report.xlsx'
    var filteredData = this.filterPipe.transform(
      this.reportDataList,
      this.searchText,
      'timecardreport'
    );

    filteredData.map((item: any) => {
      var temp = {
        'Employee ID': item.employeeid,
        'User ID': item.userid,
        'User Name': item.username,
        Department: item.department,
        Division: item.division,
        'Team ID': item.teamid,
        'Cost Center': item.costcenter,
        Date: this.allinoneService.formatDBToShow(item.date),
        'Shift Time': item.shifttime,
        ['Actual ' + this.isLate ? 'Time In' : 'Time Out']:
          this.allinoneService.formatTimeToShow(item.time),
        [this.isLate ? 'Late Time' : 'Early Out Time']: item.formattedlatemin,
        ['Requested ' + this.isLate ? 'Late Time' : 'Early Out Time']:
          item.requeststatus == '' ? '-' : item.formattedduration,
        'Request Status':
          item.requeststatus == '001'
            ? 'Submitted'
            : item.requeststatus == '002'
            ? 'Approved'
            : item.requeststatus == '003'
            ? 'Rejected'
            : item.requeststatus == '004'
            ? 'Completed'
            : '-',
      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  dateChange() {
    var addDays = 35;
    this.dateRangeStartDate = this.submitForm.startdate;
    var maxEndDate = new Date(this.dateRangeStartDate);
    maxEndDate.setTime(maxEndDate.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(
    //   maxEndDate,
    //   this.formatToShow,
    //   'en-US'
    // ).toString();

    this.minDate = maxEndDate;

    if (this.submitForm.enddate > this.minDate) {
      this.submitForm.enddate = this.minDate;
    }

    if (this.submitForm.enddate < this.submitForm.startdate) {
      this.submitForm.enddate = this.submitForm.startdate;
    }
  }
}
