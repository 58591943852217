<div class="container-fluid">
    <div class="content" *ngIf="!isOpenForm">
        <div class="me-auto">
            <app-kmember-breadcrumb s_breadcrumb="Member">

            </app-kmember-breadcrumb>
        </div>

        <div class="d-flex">
            <div class="input-group mb-2 me-2" style="width: 300px !important">
                <input class="form-control" type="text" placeholder="&#xf002; Search with Name" aria-label="Search"
                    style="font-family: FontAwesome, Ubuntu" [(ngModel)]="searchName" (keyup.enter)="searchByName()">
                <button class="btn btn-custom me-2" matTooltip="Refresh" (click)="searchByName()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
            <div class="line"></div>
            <div class="d-flex align-items-center">
                <span class="total-count-text">Total:&nbsp;</span> <span
                    class="total-count-num">{{memberList.length}}</span>
            </div>
        </div>
        <table class="table table-responsive table-borderless">
            <thead class="table-header">
                <tr>
                    <th>MemberID</th>
                    <th>အမည်</th>
                    <th>မှတ်ပုံတင်အမှတ်</th>
                    <th>ကဒ်အမျိုးအစား</th>
                    <th>မွေးနေ့</th>
                    <!-- <th>နေရပ်လိပ်စာ</th> -->
                    <th>ဖုန်းနံပါတ်</th>
                    <th>Status</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                </tr>
            </thead>
            <tbody *ngIf="!gettingMemberList && memberList.length>0">
                <tr *ngFor="let member of memberList " class="py-4" style="cursor: pointer;">
                    <td (click)="editMember(member)">
                        {{member.membercard_id}}
                    </td>
                    <td (click)="editMember(member)">
                        {{member.name}}
                    </td>
                    <td (click)="editMember(member)">
                        {{member.nrc}}
                    </td>
                    <td (click)="editMember(member)">
                        {{member.membertype}}
                    </td>
                    <td (click)="editMember(member)">
                        {{allInOneService.formatDBToShow(member.birthdate)}}
                    </td>
                    <!-- <td (click)="editMember(member)">
                        {{member.current_address}}
                    </td> -->
                    <td (click)="editMember(member)">
                        {{member.phone}}
                    </td>
                    <td class="td-edit td-data-2" (click)="editMember(member)">
                        <span class="status" [ngClass]="{
                            'status-green': member.status == '004',
                            'status-gray': member.status == '005'
                          }">
                            {{member.status=="004"? "Active": "Inactive"}}
                        </span>

                    </td>
                    <td (click)="editMember(member)">
                        {{allInOneService.formatDBToShow(member.fromdate)}}
                    </td>
                    <td (click)="editMember(member)">
                        {{allInOneService.formatDBToShow(member.todate)}}
                    </td>

                    <!-- <td class="justify-contents-end">
                        <button class="btn btn-outline-primary me-2" (click)="editMember(member)">
                            <i class="fa fa-edit"></i>
                        </button>
                    </td> -->

                </tr>
            </tbody>
            <tbody *ngIf="!gettingMemberList && memberList.length == 0">
                <tr>
                    <td colspan="9" class="nodata">
                        Empty
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="gettingMemberList">
                <tr>
                    <td colspan="9" class="nodata">
                        <div class="spinner-border" entitle="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="content" *ngIf="isOpenForm">
        <!-- <app-member-register [currentMember]="currentMember" [status]="action"
            (closeFormHandler)="closeMemberForm()"></app-member-register> -->
        <app-member-card-registor [currentMember]="currentMember"
            (closeFormHandler)="closeMemberForm()"></app-member-card-registor>
    </div>
</div>