<div class="mb-3">
    <label for="startdate" class="form-label">Start Date</label>
    <input type="date" class="form-control" id="startdate" name="startdate"
        [(ngModel)]="startdate" max="9999-12-31" required step="1">
</div>

<div class="mb-3">
    <label for="enddate" class="form-label">End Date</label>
    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddate"
        required [min]="startdate" max="9999-12-31" step="1">
</div>

<div mat-dialog-actions align="end">
    <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary mx-2">
        Cancel
    </button>
    <button type="button" (click)="search()" class="btn btn-custom" cdkFocusInitial>
        Search
    </button>
</div>