<!-- <div class="container-fluid">
    <div class = "content card">
        <div class = "titlep">
            <div style="border-radius: 50%;">
                <img src="../../../assets/images/neovision.png" width="80px" >
            </div>
            <div class = "titlediv ms-1">
                <div class = "title">
                    Neo Vision
                </div>
            </div>
        </div>
        
    </div>
</div> -->

<div fxFlex class="container1">
    <div class="navbar1">
        <img src="../../../assets/images/neovision.png" alt="Logo" class="logo">
        <h1>Neo Vision</h1>
    </div>

    <div class="content">
        <!-- <a href="">2021 Collections</a> -->
        <h1>Read and listen your email <br/> using voice commands</h1>
        <p>We'll always connect the world with you.</p>
    </div>

    <img src="../../../assets/images/img1.png" class="feature-img" alt="">

    <div class="contact-links">
        <h5>Contact Us :</h5>
        <p>neovision@nirvasoft.com</p>

    </div>


</div>