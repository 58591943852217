import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ClasscodeComponent } from '../classcode/classcode.component';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-pay-level',
  templateUrl: './pay-level.component.html',
  styleUrls: ['./pay-level.component.scss'],
})
export class PayLevelComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild('tbody') tbody!: ElementRef;

  paylevelFormSubmitted: boolean = false;
  editFormSubmitted: boolean = false;
  gettingCcList: boolean = false;
  isEditing: boolean = false;
  disable: boolean = true;
  saveLoading: boolean = false;
  fromOrg: boolean = false;
  fromHCM: boolean = false;
  classCodeAddOrNot: boolean = false;

  payLevelList: any[] = [];
  organizations: any[] = [];
  membershiptype: any = '';
  domainid: string = '';

  p: number = 1;

  paylevelForm = {
    orgid: '',
    paylevelid: '',
    name: '',
  };

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ClasscodeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fromOrg = data.fromOrg;
    this.fromHCM = data.fromHCM ? data.fromHCM : false;
    if (this.fromOrg) {
      console.log(data.org);
      this.organizations.push(data.org);
      this.paylevelForm.orgid = data.org.orgid;
    } else {
      this.paylevelForm.orgid = data.orgid;
    }
  }

  ngOnInit(): void {
    // if (!this.fromOrg) {
    //   this.organizations = this.allinoneService.orgs;
    //   // this.paylevelForm.orgid = this.allinoneService.selectedOrg ? this.allinoneService.selectedOrg : this.organizations[0].orgid
    // }
    // if (this.fromHCM) {
    //   this.organizations = this.allinoneService.hrOrgs;
    //   console.log(this.organizations);
    // }
    this.organizations = this.allinoneService.orgs;
    const index = this.organizations.findIndex(
      (x: any) => x.orgid == this.paylevelForm.orgid
    );
    this.getPayLevelList();
  }

  submit() {
    this.paylevelFormSubmitted = true;
    if (this.paylevelForm.name.trim() == '') {
      return;
    }
    this.saveLoading = true;
    const data = {
      paylevelid: this.paylevelForm.paylevelid,
      orgid: this.paylevelForm.orgid,
      name: this.paylevelForm.name,
    };

    var fun = this.isEditing
      ? this.kunyekService.updatePaylevel(data)
      : this.kunyekService.addPaylevel(data);
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.cancel();
          this.getPayLevelList();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.paylevelFormSubmitted = false;
      },
      (err) => {
        this.saveLoading = false;
        this.paylevelFormSubmitted = false;
      }
    );
  }

  editData(data: any) {
    this.isEditing = true;
    this.classCodeAddOrNot = true;
    this.paylevelForm = {
      orgid: this.paylevelForm.orgid,
      paylevelid: data.paylevelid,
      name: data.name,
    };
  }

  cancel() {
    this.isEditing = false;
    this.classCodeAddOrNot = false;
    this.paylevelFormSubmitted = false;
    this.paylevelForm = {
      orgid: this.paylevelForm.orgid,
      paylevelid: '',
      name: '',
    };
  }

  sortData(sort: Sort) {
    const data = this.payLevelList;
    if (!sort.active || sort.direction === '') {
      this.payLevelList = data;
      return;
    }

    this.payLevelList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'classcode':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  getPayLevelList() {
    this.gettingCcList = true;
    const data = {
      orgid: this.paylevelForm.orgid,
    };
    this.kunyekService.getPaylevel(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.payLevelList = res.datalist;
          this.gettingCcList = false;
        } else {
          this.gettingCcList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingCcList = false;
      }
    );
  }

  inputChange() {
    if (this.paylevelForm.name.trim() == '') {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  checkOverflow(element: any) {
    return element.offsetHeight < element.scrollHeight;
  }
}
