
import { DatePipe, } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,

} from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { FilterPipe } from 'src/app/shared/filter.pipe';


@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit {

  gettingData: boolean = false;
  driverList: any = [
    // {
    //   "carid": "393939",
    //   "carno": "8D:2938",
    //   "drivername": "U Aung",
    //   "driverphone": "09767064706",
    //   "maxpassenger": 4
    // }
  ];
  adddriver: boolean = false;
  saveLoading: boolean = false;
  gettingVehicle: boolean = false;
  carFormSave: boolean = false;
  isEditing: boolean = false;
  orgid: any = "";
  organizations: any = [];
  searchText: any = "";

  itemPerPage: any = "";
  currentPage: any = 1;
  text1: any = "";
  text2: any = "";

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  carForm = {
    "driverid":"",
    "drivername": "",
    "driverphone": "",
  }
  istransoprationAdmin: boolean = false;
  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private filter: FilterPipe
  ) {
    this.istransoprationAdmin = this.allinoneService.isAdminOf("transportation");
    if (this.istransoprationAdmin == false) {
      this.router.navigateByUrl(`transportation`);
    }
    this.organizations = this.allinoneService.tranOrgs;
    this.orgid = this.allinoneService.selectedTranOrg
      ? this.allinoneService.selectedTranOrg
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {

    this.getDriver();
  }


  new() {
    this.adddriver = true;
    this.carForm.drivername = "";
    this.carForm.driverphone = '';
    this.carForm.driverid = "";
    this.carFormSave = false;

  }

  cancelFunction() {
    this.adddriver = false;
    this.isEditing = false;
  }
  submitVehicle() {
    this.carFormSave = true;
    console.log(this.carForm.drivername)
    console.log(this.carForm.driverphone)
    if ( this.carForm.drivername == "" || this.carForm.driverphone == "" ) {
      console.log("111")
      return;
    } else {




      this.saveLoading = true;

      var data = {
        "orgid": this.orgid,
        "domainid": this.allinoneService.getDomain().domainid,
        "name": this.carForm.drivername,
        "mobile": this.allinoneService.checkUserId(this.carForm.driverphone),
        // "taxi":this.checkTaxi
      }


      if (this.isEditing) {
        data = Object.assign(data,
          {
            "driverid": this.carForm.driverid,
          }
        );
      }

      if (this.isEditing == true) {
        this.kunyekService.updatedriver(data).subscribe(
          (res: any) => {

            if (res.returncode == '300') {
              this.adddriver = false;
              this.saveLoading = false;
              this.isEditing = false;
              this.getDriver();
            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
          }
        );
      } else {
        this.kunyekService.adddriver(data).subscribe(
          (res: any) => {

            if (res.returncode == '300') {
              this.adddriver = false;
              this.isEditing = false;
              this.saveLoading = false;
              this.getDriver();
            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
          }
        );
      }

    }

  }


  getDriver() {
    this.gettingData = true;
    this.driverList = [];
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getDriver(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          res.datalist.map((item: any) => {
            this.driverList.push({
              "driverid": item.driverid,
              "name": item.name,
              "mobile": item.mobile,
              // "taxi":item.taxi,

            })
          });
          this.gettingData = false;
        } else {

          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingData = false;
      }
    );
  }


  customerSelect(data: any) {
    console.log(data);


  }

  async vehicleEdit(data: any) {
    this.isEditing = true;
    this.carForm.drivername = data.name;
    this.carForm.driverphone = data.mobile;
    this.carForm.driverid = data.driverid;
    

    // this.checkTaxi  = data.taxi;

  }

  refreshgetData() {
    this.getDriver();
  }


  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    this.refreshgetData();
  }


  sortData(sort: Sort) {
    const data = this.driverList;
    if (!sort.active || sort.direction === '') {
      this.driverList = data;
    }

    this.driverList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       
        case 'drivername':
          return this.allinoneService.compare(
            a.drivername.toLocaleLowerCase(),
            b.drivername.toLocaleLowerCase(),
            isAsc
          );
        case 'driverphone':
          return this.allinoneService.compare(
            a.driverphone.toLocaleLowerCase(),
            b.driverphone.toLocaleLowerCase(),
            isAsc
          );
        default:
          return 0;
      }

    });

  }

  downloadSheet() {
    var driverexport = this.filter.transform(
      this.driverList,
      this.searchText,
      "driver"
    );

    var data: any = []
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Driver' + time + '.xlsx';
    driverexport.map((veh: any) => {
      data.push({
        "Driver Name": veh.name,
        "Driver Phone": veh.mobile,
      });
    });
    console.log(data);
    this.allinoneService.exportEcecl(data, name);

  }

  checkTaxi: boolean = false;
  checktexi(data: any) {
    if (data.target.checked == true) {
      this.checkTaxi = true;
    } else {
      this.checkTaxi = false;
    }
  }

}
