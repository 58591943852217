<div class="me-auto px-5 mt-3">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
</div>

<div class="d-flex flex-column align-items-center gap-2 mb-3">
    <div class="d-flex ms-auto btn-action">
        <button class="btn btn-custom me-2" (click)="printPage()">Print</button>
        <button class="btn btn-custom" (click)="exportPDF()">Export PDF</button>
    </div>
    <div class="card px-5 py-3" id="printableCard" style="width: 780px;">
        <div class="d-flex flex-column gap-1 align-items-center mt-3">
            <h2>KOKINE SWIMMING CLUB</h2>
            <h2>GYM MEMBER APPLICATION FORM</h2>
        </div>
        <div class="p-3 mb-3" style=" background-color: white; border: solid 1px #ddd; border-radius: 6px;">
            <div>
                <table style="width: 100%; border-collapse: collapse;">
                    <tbody>
                        <tr>
                            <td></td>
                            <td class="text-end">
                                <img [src]="gymmember?.profileimage ? gymmember.profileimage : '../../../assets/images/member.png'"
                                    class="img-fluid border border-primary" alt="gymmember image" width="120px">
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Account No</td>
                            <td class="me-5 dashed-line">{{ gymmember?.accountno }}</td>
                        </tr>

                        <tr>
                            <td style="width: 300px;">Card Code</td>
                            <td class="me-5 dashed-line">{{ gymmember?.cardcode }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Voucher No</td>
                            <td class="me-5 dashed-line">{{ gymmember?.voucherno }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Name </td>
                            <td class="me-5 dashed-line">{{ gymmember?.name }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Father Name</td>
                            <td class="me-5 dashed-line">{{ gymmember?.fathername }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">NRC No</td>
                            <td class="me-5 dashed-line">{{ gymmember?.phone }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Join Date</td>
                            <td class="me-5 dashed-line">{{ allInOneService.formatDBToShow(gymmember?.joindate) }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Last Pay Date</td>
                            <td class="me-5 dashed-line">{{ allInOneService.formatDBToShow(gymmember?.lastpaydate)
                                }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Race</td>
                            <td class="me-5 dashed-line">{{ gymmember?.race }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Religion</td>
                            <td class="me-5 dashed-line">{{ gymmember?.religion }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Length</td>
                            <td class="me-5 dashed-line">{{ gymmember?.length }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Weight</td>
                            <td class="me-5 dashed-line">{{ gymmember?.weight }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Phone</td>
                            <td class="me-5 dashed-line">{{ gymmember?.phone }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Occupation</td>
                            <td class="me-5 dashed-line">{{ gymmember?.occupation }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Member Status</td>
                            <td class="me-5 dashed-line">{{ gymmember?.memberstatus }}</td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Member Status Date</td>
                            <td class="me-5 dashed-line">{{
                                allInOneService.formatDBToShow(gymmember?.memberstatusdate) }}</td>
                        </tr>
                        <tr *ngIf="gymmember?.directory">
                            <td style="width: 200px;">State/Division</td>
                            <td class="me-5 dashed-line">{{
                                gymmember?.directory?.ward + '၊' }} {{
                                gymmember?.directory?.town + '၊' }} {{ gymmember?.directory?.region + '၊၊'}}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 300px;">Addres</td>
                            <td class="me-5 dashed-line">{{ gymmember?.address }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>