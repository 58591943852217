<div class="container-fluid">
  <div class="content">
      <app-breadcrumb [s_breadcrumb]="'Project Report'"
          t_breadcrumb="{{detailForm.username ? detailForm.username : detailForm.userid}} - {{detailForm.projectname}}"
          (emit)="backToCIA()">
      </app-breadcrumb>
      <div class="mt-2 d-flex justify-content-between align-items-center flex-wrap">
          <div class="d-flex align-items-center mb-3">
              <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
                  <img class="excel-icon" src="../../../assets/images/excel.png">
                  Export
              </button>
          </div>
          <div class="d-flex align-items-center mb-3">
              <div class="d-flex align-items-center">
                  <span class="total-count-text">Total:&nbsp;</span> <span
                      class="total-count-num">{{totalTime}}</span>
              </div>
              <div class="line">
              </div>
              <div class="input-group" style="width: 300px !important;">
                  <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                      (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText" (ngModelChange) = "search()"
                      [ngClass]="{'search-input' : !searchText, 'search-input-with-clear' : searchText }"
                      style="font-family: FontAwesome, Ubuntu;">
                  <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                      [ngClass]="{'clear' : isFocus, 'clear-not-focus': !isFocus}">
                      <mat-icon class="c-icon">close</mat-icon>
                  </button>
              </div>
          </div>
      </div>
      <div>
          <div class="table-responsive" matSort (matSortChange)="sortData($event)">
              <table class="table table-responsive table-borderless">
                  <thead class="table-header">
                      <tr>
                          <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="jobname">Job Name</td>
                          <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="date">Date Time</td>
                          <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="autoid">Task ID</td>
                          <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="desc">Task Name</td>
                          <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="hours">Hours</td>
                      </tr>
                  </thead>
                  <tbody *ngIf="gettingDetails">
                      <tr>
                          <td colspan="4" class="nodata">
                              <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                              </div>
                          </td>
                      </tr>
                  </tbody>
                  <tbody *ngIf="!gettingDetails">
                      <tr *ngIf="detailList.length == 0">
                          <td colspan="4" class="nodata">Empty</td>
                      </tr>
                      <tr
                          *ngFor="let item of detailList | filter : searchText: type |paginate: { itemsPerPage: 100, currentPage: p }; let index = index">
                          <td>
                              <span [title]="item.autoid">{{item.jobname}} </span>
                          </td>
                          <td class="td-data">
                              <span
                                  title="{{allinoneService.formatDBToShow(item.date)}} {{item.starttime}}">{{allinoneService.formatDBToShow(item.date)}}
                                  {{item.starttime}} </span>
                          </td>
                          <td>
                              <span [title]="item.autoid">{{item.autoid}} </span>
                          </td>
                          <td>
                              <span [title]="item.taskdescription">{{item.taskdescription}} </span>
                          </td>
                          <td>
                              <span [title]="item.hourstoshow">{{item.hourstoshow}}</span>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <pagination-controls class="paginate" (pageChange)="p = $event" *ngIf="detailList.length > 100"
              previousLabel="Prev" nextLabel="Next">
          </pagination-controls>
      </div>
  </div>
</div>