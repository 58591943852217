import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-monthlyfeedetail',
  templateUrl: './monthlyfeedetail.component.html',
  styleUrls: ['./monthlyfeedetail.component.scss']
})
export class MonthlyfeedetailComponent implements OnInit {

  monthlyFeedDetailForm!: FormGroup;
  feetypes: Array<any> = [];
  submitted: boolean = false;
  selectedMonthlyFeeId: string = '';
  addedMonthlyFee: any
  paymentPeriodsList: any[] = []
  createDetailLoading: boolean = false;

   dialogData: any;
  constructor(
    private fb: FormBuilder,
    private kunyekService: KunyekService,
    private dialogRef: MatDialogRef<MonthlyfeedetailComponent>,
    private injector: Injector,
    private messageService: MessageService,
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.selectedMonthlyFeeId = this.dialogData
    console.log(this.dialogData)
    this.monthlyFeedDetailForm = this.fb.group({
      payperiod_id: ['',Validators.required],
      feetype_id: ['', Validators.required],
      amount: ['', Validators.required]
    })
  }

  reqObj: any

  getFeeTypes() {
    this.kunyekService.getFeeTypes({ searchName: '' }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.feetypes = res.datalist;
        }
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  addMonthlyFeesDetail(){
    if(this.monthlyFeedDetailForm.valid){
      this.createDetailLoading = true;
      console.log(this.monthlyFeedDetailForm.value, '................')
      this.reqObj = this.monthlyFeedDetailForm.value
      console.log(this.selectedMonthlyFeeId)
      this.reqObj.monthlyfee_id = this.selectedMonthlyFeeId

      console.log(this.reqObj)

      this.kunyekService.createMonthlyFeesTypeDetail(this.reqObj).subscribe({
        next: (res) => {
          if (res.returncode == "300") {
            let feetypename = ''
            this.messageService.openSnackBar(res.message,"success")
            console.log(this.feetypes)
            console.log(this.reqObj)
            this.feetypes.forEach((feetype:any)=>{
              if(feetype.feetype_id == this.reqObj.feetype_id){
                console.log('found')
                console.log()
                feetypename = feetype.description
              }
            })
            console.log(feetypename)

            this.dialogRef.close({
              success: true,
              newmtfd: {
                feeType: {description: feetypename},
                amount: this.reqObj.amount
            } })
          }
        },
        error: (err) => {
          this.messageService.openSnackBar(err,"warn")
          console.log(err)
          this.createDetailLoading = false;
        }
      })
    }else{
    }
    
  }

  ngOnInit(): void {
    this.getFeeTypes();
    this.getPaymenPeriods()
  }

  getPaymenPeriods(){
    this.kunyekService.getPaymentPeriods({}).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.paymentPeriodsList = res.datalist;
        }
        console.log(this.paymentPeriodsList,'paymentperiod list.............')
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  close(){
    this.dialogRef.close()
  }

}
