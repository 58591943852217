<div class='container-fluid'>
    <div class="content">



        <div class="d-flex justify-content-between align-items-center">
            <app-hcm-breadcrumb *ngIf="allinoneService.isAttendanceView" s_breadcrumb="Leave Summary">
            </app-hcm-breadcrumb>
            <app-team-breadcrumb *ngIf="!allinoneService.isAttendanceView" s_breadcrumb="Leave Summary"></app-team-breadcrumb>
            <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="(summaryData | filter :
    searchText:'LeaveSummaryReport').length > itemPerPage" previousLabel="Prev" nextLabel="Next">
            </pagination-controls>
        </div>

        <div class="d-flex flex-wrap align-items-start justify-content-between">
            <div class="mb-3 d-flex gap-2 flex-wrap justify-content-start align-items-center">

                <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
                    <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Employee ID" aria-label="Search by Name/Employee ID" (focus)="isFocus = true" (blur)="isFocus = false" (ngModelChange)="searchTextChange()" [(ngModel)]="searchText" [ngClass]="{ 'c-search-input-with-clear': searchText }"
                        style="font-family: FontAwesome, Ubuntu">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
                </div>
                <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-1' : !isMobileView }">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
                    <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
                        <li>
                            <div style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                            <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
                        </li>
                    </ul>
                </div>
                <!-- <div class="ms-2"> -->

                <button type="button" class="btn btn-custom" id="togglefilter" (click)="toggleFilter()">
          <mat-icon>{{openfilter ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </button>

                <!-- <div>
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div> -->

                <div class="line g-0"></div>

                <!-- <div class="line g-0"></div> -->


                <div class="filtter-btn align-items-center" [matMenuTriggerFor]="yearmenu">
                    <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Year&nbsp;:&nbsp;{{ reportForm.year }}

            <mat-menu #yearmenu="matMenu" xPosition="before">
              <ng-container *ngIf="allCalendarList.length > 0; else emptyTemplate">
                <button mat-menu-item *ngFor="let year of allCalendarList" (click)="yearChange(year)">
                  <span [style.color]="year.active && year.valid ? 'black' : 'red'">
                    {{ year.year }}
                  </span>
                </button>
                    </ng-container>
                    <ng-template #emptyTemplate>
                        <div mat-menu-item>
                            No Calendar Found.
                        </div>
                    </ng-template>
                    </mat-menu>
                    <span *ngIf="calenderListLoading" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                    </button>
                </div>
                <!-- <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'" [isLoading]="searchLoading"
          (click)="search()">
        </app-button-loader> -->

                <div class="line g-0"></div>

                <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>

                <app-button-loader class="" [button_text]="'Search'" [load_button_text]="'Searching'" [isLoading]="searchLoading" (click)="search()">
                </app-button-loader>

                <div class="line g-0"></div>
                <div class="d-flex align-items-start flex-wrap">
                    <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{(summaryData | filter :
            searchText:'LeaveSummaryReport').length}}</span>
                </div>

                <div class="input-group mt-2" *ngIf="isMobileView">
                    <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Employee ID" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText" [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
                        (ngModelChange)="searchTextChange()">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
                </div>
            </div>
            <!-- <div class="mb-3 d-flex flex-wrap align-items-center gap-2">
        <pagination-controls (pageChange)="currentPage = $event" *ngIf="lateReportList.length > itemPerPage"
          previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div> -->

        </div>

        <div *ngIf="openfilter" class="d-flex gap-3 flex-wrap" style="row-gap: 0 !important">
            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="departmentmenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Department&nbsp;:&nbsp;{{ reportForm.department.length > 20 ? (reportForm.department |
          slice:0:20)+'...' : reportForm.department}}
          <mat-menu #departmentmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let department of departmentList" (click)="departmentChange(department)">
              {{ department }}
            </button>
                </mat-menu>
                <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                </button>
            </div>
            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="divisionmenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Division&nbsp;:&nbsp;{{ reportForm.division.length > 22 ? (reportForm.division |
          slice:0:22)+'...' : reportForm.division}}
          <mat-menu #divisionmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let division of divisionList" (click)="divisionChange(division)">
              {{ division }}
            </button>
                </mat-menu>
                <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                </button>
            </div>
            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="teamidmenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Team ID&nbsp;:&nbsp;{{ reportForm.teamid.length > 22 ? (reportForm.teamid |
          slice:0:22)+'...' : reportForm.teamid}}
          <mat-menu #teamidmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let teamid of teamidList" (click)="teamidChange(teamid)">
              {{ teamid }}
            </button>
                </mat-menu>
                <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                </button>
            </div>


            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="costcenterMenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Cost Center&nbsp;:&nbsp;{{ reportForm.costcenter.length > 22 ? (reportForm.costcenter |
          slice:0:22)+'...' : reportForm.costcenter}}
          <mat-menu #costcenterMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let costcenter of costCenterList" (click)="costCenterChange(costcenter)">
              {{ costcenter }}
            </button>
                </mat-menu>
                <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                </button>
            </div>

            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="subdivisionMenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Sub Division&nbsp;:&nbsp;{{ reportForm.subdivision.length > 22 ? (reportForm.subdivision |
          slice:0:22)+'...' : reportForm.subdivision}}
          <mat-menu #subdivisionMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let subdivision of subDivisionList" (click)="subDivisionChange(subdivision)">
              {{ subdivision }}
            </button>
                </mat-menu>
                <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                </button>
            </div>

            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="statusMenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Status&nbsp;:&nbsp;{{ reportForm.status == '001' ? 'Active' : 'Inactive' }}
          <mat-menu #statusMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let status of userStatusFilterList" (click)="reportForm.status = status.id">
              {{ status.name }}
            </button>
                </mat-menu>
                </button>
            </div>

        </div>

        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
            <table class="table table-responsive table-borderless">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="username">Name</td>
                        <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="employeeid">Employee ID</td>
                        <!-- <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="joineddate">Joined Date</td> -->
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="postname">Post</td>
                        <!-- <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="department">Department</td>
            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="division">Division</td> -->

                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="leavetype">Leave Type</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="broughtforward">Brought Forward</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="entitledleave">Entitled Leave</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="opening">Opening</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="usedleave">Used Leave</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="balanceleave">Balanced Leave</td>
                    </tr>
                </thead>

                <tbody *ngIf="searchLoading">
                    <tr>
                        <td colspan="10" class="nodata">
                            <div class="spinner-border" entitle="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tbody *ngIf="!searchLoading">
                    <tr *ngIf="summaryData.length == 0">
                        <td colspan="10" class="nodata">Empty</td>
                    </tr>

                    <tr *ngFor="let user of summaryData| filter :searchText:'LeaveSummaryReport' | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage}; let index = index">

                        <td class="td-data">
                            <span [title]="user.username || user.userid">{{user.username || user.userid}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="user.employeeid">{{user.employeeid}}</span>
                        </td>
                        <!-- <td class="td-data">
              <span
                [title]="allinoneService.formatDBToShow(user.joineddate)">{{allinoneService.formatDBToShow(user.joineddate)}}</span>
            </td> -->
                        <td class="td-data">
                            <span [title]="user.postname">{{user.postname}}</span>
                        </td>
                        <!-- <td class="td-data">
              <span [title]="user.department">{{user.department}}</span>
            </td>
            <td class="td-data">
              <span [title]="user.division">{{user.division}}</span>
            </td> -->
                        <td class="td-data">
                            <span [title]="user.leavetype">{{user.leavetype}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="user.broughtforward">{{user.broughtforward}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="user.entitledleave">{{user.entitledleave}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="user.opening">{{user.opening}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="user.usedleave">{{user.usedleave}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="user.balanceleave">{{user.balanceleave}}</span>
                        </td>

                    </tr>


                </tbody>
            </table>
        </div>
        <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="(summaryData | filter :
    searchText:'LeaveSummaryReport').length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
    </div>
</div>