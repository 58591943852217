import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { MapComponent } from '../map/map.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { ChatInfoImageEditorComponent } from '../chat-info-image-editor/chat-info-image-editor.component';
// import { UserProfileComponent } from '../user-profile/user-profile.component';

@Component({
  selector: 'app-chat-info',
  templateUrl: './chat-info.component.html',
  styleUrls: ['./chat-info.component.scss'],
})
export class ChatInfoComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  qrvalue = {
    channelid: '',
    domain: this.allinoneService.getDomain().shortcode,
    type: 2,
  };
  giForm = {
    groupname: '',
    orgid: '',
    lat: '',
    long: '',
    location: '',
    hierarchy: false,
    payroll: false,
    class: false,
    hidden: false,
    custom: false,
    support: false,
  };

  value = '';
  name: any;
  organizations: any;

  loading: boolean = false;
  giFormSubmitted: boolean = false;
  saveLoading: boolean = false;
  isMemberListLoading: boolean = false;
  gettingOrgList: boolean = false;
  isAdmin: boolean = false;
  gettingInfo: boolean = false;

  @Input() openedChat!: any;

  @Output('leave')
  leave: EventEmitter<any> = new EventEmitter<any>();

  @Output('reload')
  reload: EventEmitter<any> = new EventEmitter<any>();

  @Output('createGroupWith')
  createGroupWith: EventEmitter<any> = new EventEmitter<any>();

  @Output('addMember')
  addMember: EventEmitter<any> = new EventEmitter<any>();

  @Output('block')
  block: EventEmitter<any> = new EventEmitter<any>();

  @Output('delete')
  delete: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('gname') groupNameInput: ElementRef | undefined;

  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    public dialog: MatDialog,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    public kunyekService: KunyekService,
    public changeDetectorRef: ChangeDetectorRef
  ) // @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  {
    // this.openedChat = data.chat
  }

  tempUserList: any = [];

  ngOnInit(): void {
    this.isAdmin =
      this.allinoneService.isAdmin(
        this.allinoneService.getKunyekUserId(),
        this.openedChat.adminlist
      ) || this.openedChat.admin == this.allinoneService.getKunyekUserId();
    this.qrvalue.channelid = this.openedChat.channelid;

    this.value = JSON.stringify(this.qrvalue);
    this.getUserName();
    if (this.openedChat.type == '2') {
      this.getMemberList();
      this.getOrganization();
    }
    // this.sortUser()
  }

  getCurrentPosition() {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          console.log(position);
          this.giForm.lat = String(position.coords.latitude);
          this.giForm.long = String(position.coords.longitude);
          //this.changeDetectorRef.detectChanges()
          resolve('success');
        },
        (err) => {
          console.log('err', err);
          resolve('error');
        }
      );
    });
  }

  getOrganization() {
    this.gettingOrgList = true;
    this.organizations = [];
    //this.changeDetectorRef.detectChanges()
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
    };

    this.kunyekService.getOrgs(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        res.list.map((org: any) => {
          this.organizations.push({
            orgid: org.orgid,
            admin: org.admin,
            orgimagename: org.orgimagename,
            orgimageurl: org.orgimageurl,
            name: org.name,
            type: org.type,
          });
        });
        this.gettingOrgList = false;
        // this.giForm.orgid = this.organizations[0].orgid
        //this.changeDetectorRef.detectChanges()
      } else {
        //this.changeDetectorRef.detectChanges()
        this.messageService.openSnackBar(res.message, 'warn');
      }
    });
  }

  map() {
    const dialog = this.dialog.open(MapComponent, {
      height: '400px',
      width: '65vw',
      closeOnNavigation: true,
      panelClass: ['map-modal', 'mat-typography'],
      data: {
        lat: this.giForm.lat,
        long: this.giForm.long,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.giForm.lat = dialogResult.lat;
        this.giForm.long = dialogResult.long;
        //this.changeDetectorRef.detectChanges()
      }
    });
  }

  sortUser() {
    this.tempUserList.forEach((item: any, i: any) => {
      if (
        item.userid == this.openedChat.admin ||
        this.allinoneService.isAdmin(item.userid, this.openedChat.adminlist)
      ) {
        this.tempUserList.splice(i, 1);
        this.tempUserList.unshift(item);
      }
    });
    //this.changeDetectorRef.detectChanges()
  }

  getUserName() {
    if (this.openedChat.type == '1') {
      if (this.openedChat.userlist[0].username == '') {
        this.name = this.openedChat.userlist[0].userid;
      } else {
        this.name = this.openedChat.userlist[0].username;
      }
    } else if (this.openedChat.type == '2') {
      this.name = this.openedChat.channelname;
    } else {
      if (this.openedChat.username == '') {
        this.name = this.openedChat.userid;
      } else {
        this.name = this.openedChat.username;
      }
    }
    //this.changeDetectorRef.detectChanges()
  }

  deleteGroup() {
    this.allinoneService.isLoading = true;
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      channelid: this.openedChat.channelid,
    };

    this.kunyekService.deleteChat(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.allinoneService.isLoading = false;
          this.delete.emit();
        } else {
          this.allinoneService.isLoading = false;
          this.messageService.openSnackBar(res.message || res.status, 'warn');
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        //this.changeDetectorRef.detectChanges()
        this.allinoneService.isLoading = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  pfImageEdit(url : any) {    
    const dialog = this.dialog.open(ChatInfoImageEditorComponent, {
      maxWidth: '400px',
      width : '400px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data : {
        imgurl : url,
        channelid : this.openedChat.channelid,
        channelname : this.openedChat.channelname
      }
    });
    dialog.afterClosed().subscribe((dialogResult : any) => {
      console.log(dialogResult);
      
      if(dialogResult) {
        // if(dialogResult.success) {
        //   this.openedChat.channelimage.imageurl = dialogResult.imgurl;
        // }
        if (dialogResult.success) {
          if (this.openedChat.channelimage == '') {
            this.openedChat.channelimage = {
              imageexp: '',
              imagename: '',
              imageurl: dialogResult.imgurl,
            };
          } else {
            this.openedChat.channelimage.imageurl = dialogResult.imgurl;
          }
        }
      }
    })
  }

  leaveGroup() {
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      channelid: this.openedChat.channelid,
    };

    this.kunyekService.leaveGroup(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.loading = false;
          this.emitLeave();
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        //this.changeDetectorRef.detectChanges()
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  addContact(userid: string) {
    this.loading = true;
    //this.changeDetectorRef.detectChanges()
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      recordstatus: '1',
      contact: [userid],
      count: 0,
    };

    this.kunyekService.addContact(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.getMemberList();
        } else {
          this.loading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        this.loading = false;
        //this.changeDetectorRef.detectChanges()
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  makeAdmin(user: any, type: number) {
    this.allinoneService.isLoading = true;
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      contact: [],
      channelname: this.openedChat.channelname,
      date: '',
      sort: '',
      channelid: this.openedChat.channelid,
      admin: '',
      removeadmin: '',
    };

    //1 - make admin, 2 - remove admin
    if (type == 1) {
      data.admin = user.userid;
    } else {
      data.removeadmin = user.userid;
    }
    //this.changeDetectorRef.detectChanges()
    this.kunyekService.makeAdmin(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.allinoneService.isLoading = false;
          this.getMemberList();
          // user.admin = 'true'
        } else {
          this.allinoneService.isLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        this.allinoneService.isLoading = false;
        //this.changeDetectorRef.detectChanges()
      }
    );
  }

  getMemberList() {
    this.isMemberListLoading = true;
    this.gettingInfo = true;
    //this.changeDetectorRef.detectChanges()
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      contact: [],
      channelname: '',
      date: '',
      sort: '',
      channelid: this.openedChat.channelid,
    };

    this.kunyekService.getChannelMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.gettingInfo = false;
          this.giForm.groupname = res.channelname;
          this.giForm.orgid = res.orgid;
          this.giForm.lat = res.lat;
          this.giForm.long = res.long;
          this.giForm.location = res.location;

          this.giForm.payroll = res.payroll == 'false' ? false : true;
          this.giForm.class = res.class == 'false' ? false : true;
          this.giForm.hierarchy = res.hierarchy == 'false' ? false : true;
          this.giForm.hidden = res.hidden == 'false' ? false : true;
          this.giForm.custom = res.custom == 'false' ? false : true;
          this.giForm.support = res.support == 'false' ? false : true;
          this.tempUserList = res.list;
          this.openedChat.userlist = res.list;
          this.openedChat.adminlist = res.adminlist;
          this.sortUser();
          this.loading = false;
          this.isMemberListLoading = false;
        } else {
          this.gettingInfo = false;
          this.loading = false;
          this.isMemberListLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        this.gettingInfo = false;
        this.loading = false;
        this.isMemberListLoading = false;
        //this.changeDetectorRef.detectChanges()
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  emitLeave() {
    this.leave.emit();
  }

  emitCreateGroupWith(userid: string) {
    this.createGroupWith.emit(userid);
  }

  emitReload() {
    this.reload.emit();
  }

  deleteChatConfirm() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.deleteGroup();
      }
    });
  }

  leaveGroupConfirm() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    //this.changeDetectorRef.detectChanges();

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.loading = true;
        this.leaveGroup();
      }
    });
  }

  focus() {
    this.giFormSubmitted = false;
    this.saveLoading = false;
    this.giForm.groupname = this.openedChat.channelname;
    //this.changeDetectorRef.detectChanges()
    // this.groupNameInput?.nativeElement.focus()
  }

  submitGroupinfo() {
    if (this.giForm.groupname == '') {
      return;
    } else {
      this.giFormSubmitted = true;
      this.saveLoading = true;

      this.giForm.lat = this.giForm.class ? this.giForm.lat : '';
      this.giForm.long = this.giForm.class ? this.giForm.long : '';
      this.giForm.location = this.giForm.class ? this.giForm.location : '';
      if (!this.giForm.class) {
        this.getCurrentPosition();
      }
      const data = {
        domain: this.allinoneService.getDomain().shortcode,
        recordstatus: '2',
        contact: [],
        channelname: this.giForm.groupname,
        date: '',
        sort: '',
        channelid: this.openedChat.channelid,
        admin: '',
        removeadmin: '',
        custom: this.giForm.custom ? 'true' : 'false',
        hierarchy: this.giForm.hierarchy ? 'true' : 'false',
        support: this.giForm.support ? 'true' : 'false',
        payroll: this.giForm.payroll ? 'true' : 'false',
        class: this.giForm.class ? 'true' : 'false',
        hidden: this.giForm.hidden ? 'true' : 'false',
        orgid: this.giForm.orgid,
        lat: this.giForm.lat.toString(),
        long: this.giForm.long.toString(),
        location: this.giForm.location,
        channelimage : this.openedChat.channelimage != '' ? this.openedChat.channelimage.imagename : '',
        deletechannelimage : ''
      };
      //this.changeDetectorRef.detectChanges()
      this.kunyekService.updateGroupInfo(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.emitReload();
            this.giFormSubmitted = false;
            this.saveLoading = false;
            this.openedChat.channelname = this.giForm.groupname;
            (this.openedChat.payroll = this.giForm.payroll ? 'true' : 'false'),
              (this.openedChat.class = this.giForm.class ? 'true' : 'false'),
              (this.openedChat.hidden = this.giForm.hidden ? 'true' : 'false'),
              (this.openedChat.custom = this.giForm.custom ? 'true' : 'false'),
              (this.openedChat.support = this.giForm.support
                ? 'true'
                : 'false'),
              (this.openedChat.hierarchy = this.giForm.hierarchy
                ? 'true'
                : 'false'),
              (this.openedChat.orgid = this.giForm.orgid),
              (this.openedChat.lat = this.giForm.lat),
              (this.openedChat.long = this.giForm.long),
              (this.openedChat.location = this.giForm.location);
            this.messageService.openSnackBar('Successful', 'success');
          } else {
            this.giFormSubmitted = false;
            this.saveLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
          //this.changeDetectorRef.detectChanges()
        },
        (err) => {
          this.giFormSubmitted = false;
          this.saveLoading = false;
          //this.changeDetectorRef.detectChanges()
          this.messageService.openSnackBar(err, 'warn');
        }
      );
    }
  }

  emitAddMember() {
    var temp: any[] = [];
    this.tempUserList.map((data: any) => {
      temp.push(data.userid);
    });
    //this.changeDetectorRef.detectChanges()
    this.addMember.emit(temp);
  }

  viewProfile(user: any) {
    const dialog = this.dialog.open(UserProfileComponent, {
      height: '400px',
      width: '400px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        user: user,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.getMemberList();
      }
    });
  }

  orgChanged(event: any) {
    this.giForm.orgid = event.target.value;
    //this.changeDetectorRef.detectChanges()
  }
  checkClass(event: any) {
    if (event.target.checked) {
      if (this.giForm.lat == '' && this.giForm.long == '') {
        this.getCurrentPosition();
      }
    }
    //this.changeDetectorRef.detectChanges()
  }

  blockUser(userid: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    //this.changeDetectorRef.detectChanges();

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.allinoneService.isLoading = true;
        const data = {
          domain: this.allinoneService.getDomain().shortcode,
          domainid: this.allinoneService.getDomain().domainid,
          date: this.allinoneService.getCurrentDateToDB(),
          time: this.allinoneService.getCurrentTimeToDB(),
          contact: userid,
        };
        this.kunyekService.blockUser(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.openedChat.block = 'true';
              this.allinoneService.isLoading = false;
              console.log(this.openedChat);
              this.block.emit(this.openedChat);
            } else {
              this.allinoneService.isLoading = false;
            }
            //this.changeDetectorRef.detectChanges()
          },
          (err) => {
            this.allinoneService.isLoading = false;
            //this.changeDetectorRef.detectChanges()
          }
        );
      }
    });
  }

  removeFromGroup(userid: string) {
    this.allinoneService.isLoading = true;
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      channelid: this.openedChat.channelid,
      remove: userid,
    };
    //this.changeDetectorRef.detectChanges()
    this.kunyekService.leaveGroup(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.userRemoveMsg(userid);
          this.getMemberList();
          this.allinoneService.isLoading = false;
        } else {
          this.allinoneService.isLoading = false;
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        this.allinoneService.isLoading = false;
        //this.changeDetectorRef.detectChanges()
      }
    );
  }

  userRemoveMsg(userid: string) {
    const data2 = {
      domain: this.allinoneService.getDomain().shortcode,
      contact: '',
      channelname: '',
      date: this.allinoneService.getCurrentDateToDB(),
      time: this.allinoneService.getCurrentTimeToDB(),
      message: 'User removed',
      imagename: '',
      imagedata: '',
      channelid: this.openedChat.channelid,
      name:
        this.allinoneService.getKunyekUserName() != ''
          ? this.allinoneService.getKunyekUserName()
          : this.allinoneService.getUserId(),
      mention: [],
      replysyskey: '',
      filename: '',
      actionuser: [userid],
      chattype: 'remove',
      notitype: '010',
    };
    this.kunyekService.sendMessage(data2).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
        } else {
          this.allinoneService.isLoading = false;
        }
      },
      (err) => {
        this.allinoneService.isLoading = false;
      }
    );
  }
}
