import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MemberPositionControlComponent } from '../member-position-control/member-position-control.component';

@Component({
  selector: 'app-member-info-sync-dialog',
  templateUrl: './member-info-sync-dialog.component.html',
  styleUrls: ['./member-info-sync-dialog.component.scss']
})
export class MemberInfoSyncDialogComponent implements OnInit {

  currentDate = new Date(Date.now());

  submitSyncLoading: boolean = false;
  orgid: string = '';

  generalDatePickerConfig = this.allinoneService.datePickerConfig;

  syncForm: any = {
    startdate: this.currentDate,
    enddate: this.currentDate,
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<MemberPositionControlComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.orgid = data.orgid;
  }

  ngOnInit(): void {}

  submitSyncData() {
    this.submitSyncLoading = true;
    this.dialogRef.disableClose = true;
    var data = {
      startdate: this.allinoneService.parseDate(this.syncForm.startdate),
      enddate: this.allinoneService.parseDate(this.syncForm.enddate),
      orgid: this.orgid,
    };
    
    var fun = this.kunyekService.memberInfoSyncData(data);
    console.log(data,'////')
    fun.subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          this.submitSyncLoading = false;
          this.dialogRef.close();
          this.messageService.openSnackBar(
            'It can take up to 15 minutes to update the position control data.',
            'success'
          );
        } else {
          this.dialogRef.disableClose = false;
          this.submitSyncLoading = false;
          this.messageService.openSnackBar(
            res.status || res.message || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.dialogRef.disableClose = false;
        this.submitSyncLoading = false;
        this.messageService.openSnackBar(err.status, 'warn');
      }
    );
  }

  checkDate() {
    if (this.syncForm.enddate < this.syncForm.startdate) {
      this.syncForm.enddate = this.syncForm.startdate;
    }
  }

}
