<h2>Sync data from {{neoPayroll ? 'Neo Payroll' : 'HCMEE'}}</h2>
<div class="row">
  <div class="mb-3">
    <label for="startdate" class="form-label">Start Date</label>
    <input type="text" id='startdate' name='startdate' class="form-control datepicker-readonly" placeholder="dd/mm/yyyy"
      bsDatepicker [bsConfig]="generalDatePickerConfig" [bsValue]="syncForm.startdate"
      [bsConfig]="{ adaptivePosition: true }"
      placement="right"
      [(ngModel)]="syncForm.startdate" readonly (ngModelChange)="checkDate()">
  </div>
  <div class="mb-3">
    <label for="enddate" class="form-label">End Date</label>
    <input type="text" id='enddate' name='enddate' class="form-control datepicker-readonly" placeholder="dd/mm/yyyy"
      bsDatepicker [bsConfig]="generalDatePickerConfig"  [bsValue]="syncForm.enddate"
      [bsConfig]="{ adaptivePosition: true }"
      placement="right"
      [(ngModel)]="syncForm.enddate" readonly [minDate]="syncForm.startdate">
  </div>
</div>
<div mat-dialog-actions align="end">
  <button type="button" class="btn btn-outline-secondary me-2" mat-dialog-close>
    Cancel
  </button>
  <!-- <button type="button" class="btn btn-custom ">
        Save
      </button> -->
  <app-button-loader (click)="submitSyncData()" [button_text]=" 'Save'" [load_button_text]=" 'Loading'"
    [isLoading]="submitSyncLoading"></app-button-loader>
</div>