import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-template-type',
  templateUrl: './office-template-type.component.html',
  styleUrls: ['./office-template-type.component.scss'],
})
export class OfficeTemplateTypeComponent implements OnInit, OnDestroy {
  domainid: string = '';
  orgid: string = '';
  typeSearch: string = '';
  selectedTypes: any[] = [];
  typeList: any[] = [];

  gettingType: boolean = false;

  subscriptions = new SubSink();

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<OfficeTemplateTypeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.domainid = data.domainid;
    this.orgid = data.orgid;
    this.selectedTypes = JSON.parse(JSON.stringify(data.typelist));
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.getType();
  }

  getType() {
    this.gettingType = true;
    var data = {
      orgid: this.orgid,
      domainid: this.domainid,
      roleid: this.allinoneService.getSelectedRole()
    };
    this.subscriptions.sink = this.kunyekService.getRequestType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.typeList =
            res.requesttypelist.find(
              (item: any) => item.requesttypeid == 'NCMLEIWHRNVIE'
            ).child || [];
          if (this.selectedTypes.length > 0) {
            this.typeList.map((item: any) => {
              if (
                this.selectedTypes.find(
                  (x: any) => x.requesttypeid == item.requesttypeid
                )
              ) {
                item.checked = true;
              } else {
                item.checked = false;
              }
            });
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingType = false;
      },
      (err) => {
        this.gettingType = false;
      }
    );
  }

  selectType(type: any) {
    console.log('type select');
    if (type.checked) {
      //uncheck
      type.checked = false;
      var checkexists = this.selectedTypes.findIndex(
        (item: any) => item.requesttypeid == type.requesttypeid
      );
      if (checkexists != -1) {
        this.selectedTypes.splice(checkexists, 1);
      }
    } else {
      //check
      type.checked = true;
      var checkexists = this.selectedTypes.findIndex(
        (item: any) => item.requesttypeid == type.requesttypeid
      );
      if (checkexists == -1) {
        this.selectedTypes.push(type);
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  done() {
    this.dialogRef.close(this.selectedTypes);
  }
}
