<div class="d-flex align-items-center justify-content-between mb-2">
    <div>
        <h3 class="mb-0">
            {{ userdata.username ? userdata.username : userdata.userid }} ({{userdata.employeeid}})
        </h3>
        <!-- <button (click)="getData()">test</button> -->
    </div>
    <div class="d-flex align-items-center">
        <!-- <button mat-icon-button mat-dialog-close> -->
        <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
        <!-- </button> -->
    </div>
</div>
<div class="d-flex align-items-center mb-2">
    <div>
        Total Leave (Days) : {{userdata.duration}}
    </div>
    <!-- <div>
        <div class="line"></div>
    </div>
    <div>
        <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
            <img class="excel-icon" src="../../../assets/images/excel.png">
            Export
        </button>
    </div> -->
</div>
<div class="table-responsive" matSort (matSortChange)="sortData($event)">
    <table class="table table-responsive table-borderless table-fixed">
        <thead class="table-header">
            <tr>
                <td scope="col" class="col-md-2" mat-sort-header="type">Leave Type</td>
                <td scope="col" class="col-md-2" mat-sort-header="startdate">Start Date</td>
                <td scope="col" class="col-md-2" mat-sort-header="enddate">End Date</td>
                <td scope="col" class="" mat-sort-header="starttime">Start Time</td>
                <td scope="col" class="" mat-sort-header="endtime">End Time</td>
                <td scope="col" class="" mat-sort-header="duration">Duration</td>
                <td scope="col" class="col-md-2">Remark</td>
            </tr>
        </thead>
        <tbody *ngIf="loadingDetails">
            <tr>
                <td colspan="7" class="nodata">
                    <div class="spinner-border" entitle="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!loadingDetails">
            <tr *ngFor="let item of userdata.detail">
                <td class="td-data td-temp" [title]="item.requesttypename">
                    {{item.requesttypename}}
                </td>
                <td class="td-data td-temp" [title]="allinoneService.formatDBToShow(item.startdate)">
                    {{allinoneService.formatDBToShow(item.startdate)}}
                </td>
                <td class="td-data td-temp" [title]="allinoneService.formatDBToShow(item.enddate)">
                    {{allinoneService.formatDBToShow(item.enddate)}}
                </td>
                <td class="td-temp" [title]="item.starttime">
                    {{item.starttime}}
                </td>
                <td class="td-temp" [title]="item.endtime">
                    {{item.endtime}}
                </td>
                <td class="td-temp" [title]="item.duration">
                    {{item.duration}}
                </td>
                <td class="td-data td-temp" [title]="item.duration">
                    {{item.remark}}
                </td>

            </tr>
        </tbody>
    </table>
</div>

<ng-template #noData>
    -
</ng-template>