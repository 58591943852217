import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-block-user',
  templateUrl: './block-user.component.html',
  styleUrls: ['./block-user.component.scss']
})
export class BlockUserComponent implements OnInit {

  post : any;
  saveLoading : boolean = false;

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<BlockUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
      this.post = data.post
    }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.saveLoading = true
    this.dialogRef.disableClose = true
    this.changeDetectorRef.detectChanges()
    const data = {
      "domain": this.allinoneService.getDomain().shortcode,
      "domainid": this.allinoneService.getDomain().domainid,
      "date": this.allinoneService.getCurrentDateToDB(),
      "time": this.allinoneService.getCurrentTimeToDB(),
      "contact": this.post.adminid
    }
    this.kunyekService.blockUser(data).subscribe((res: any) => {
      if(res.returncode == "300"){
        this.dialogRef.disableClose = false
        this.saveLoading = false
        this.dialogRef.close(true);
      }
      else{
        this.dialogRef.disableClose = false
        this.saveLoading = false
        this.messageService.openSnackBar(res.message, 'warn')
      }
      this.changeDetectorRef.detectChanges()
    },
      err => {
        this.dialogRef.disableClose = false
        this.saveLoading = false
        this.changeDetectorRef.detectChanges()
      }
    )
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }


}
