<div class="cc-container">
  <div class="d-flex justify-content-between align-items-center mb-2">
    <h3 class="mb-0">{{type == '001' ? 'Employment Type' : 'Transaction Type'}}</h3>
    <div class="close-btn">
      <button mat-icon-button mat-dialog-close [disabled]="saveLoading">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="mb-2s d-flex header">
    <div>
      <button *ngIf="!classCodeAddOrNot" type="button" class="btn btn-custom" (click)="classCodeAddOrNot = true">
        <i class="fa fa-plus me-1"></i>New
      </button>

      <div *ngIf="classCodeAddOrNot" class="close-save-button">
        <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
          Cancel
        </button>
        <app-button-loader (click)="submitCc()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
    </div>
  </div>


  <div *ngIf="classCodeAddOrNot" class="my-3">
    <div class="mb-3">
      <label for="newcc" class="form-label">Name</label>

      <input type="text" class="form-control" id="newcc" name="newcc" [(ngModel)]="ccForm.name" required #cc="ngModel"
        placeholder="New, Permanent, Resign" [ngClass]="{ 'is-invalid': ccFormSubmitted && cc.invalid }"
        (keyup.enter)="submitCc()">

      <div class="invalid-feedback" *ngIf="cc.invalid || (cc.dirty || cc.touched) && ccFormSubmitted">
        <div *ngIf="cc.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>
    <div class="mb-3" *ngIf="type == '002'">
      <label for="status" class="form-label">Status</label>
      <select class="form-select" [(ngModel)]="ccForm.status" #status="ngModel"
        [ngClass]="{ 'is-invalid': ccFormSubmitted && status.invalid }" required>
        <option value="">-</option>
        <option *ngFor="let item of statusList" [value]="item.id">{{item.name}}</option>
      </select>
      <div class="invalid-feedback" *ngIf="status.invalid || (status.dirty || status.touched) && ccFormSubmitted">
        <div *ngIf="status.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label for="desc" class="form-label">Description</label>
      <textarea class="form-control" id="desc" name="desc" [(ngModel)]="ccForm.description" rows="3"></textarea>
    </div>

  </div>

  <ng-container *ngIf="type == '001'">
    <div *ngIf="!classCodeAddOrNot" class="table-responsive mt-2" matSort (matSortChange)="sortData($event)">
      <table class="table table-responsive table-borderless table-fixed">
        <thead class="table-header">
          <tr>
            <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4" mat-sort-header="classcode">Name
            </td>
            <td scope="col" class="col-lg-7 col-md-7 col-sm-7 col-7" mat-sort-header="description">Description
            </td>
            <td scope="col" class="d-flex justify-content-center">Action</td>
          </tr>
        </thead>
        <tbody *ngIf="gettingCcList">
          <tr>
            <td colspan="3" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="!gettingCcList" #tbody>
          <tr *ngIf="ccList.length == 0">
            <td colspan="3" class="nodata">Empty</td>
          </tr>

          <tr *ngFor="let cc of ccList; let i = index;">
            <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4 td-edit td-data" (click)="editClasscode(cc)"
              title="{{cc.name}}">{{cc.name}}
            </td>
            <td scope="col" class="col-lg-7 col-md-7 col-sm-7 col-7 td-data" title="{{cc.description}}">
              {{cc.description}}
            </td>
            <td class="d-flex justify-content-center">
              <button class="btn btn-outline-primary" (click)="editClasscode(cc)">
                <i class='fa fa-edit'></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <ng-container *ngIf="type == '002'">
    <div *ngIf="!classCodeAddOrNot" class="table-responsive mt-2" matSort (matSortChange)="sortData($event)">
      <table class="table table-borderless table-fixed">
        <thead class="table-header">
          <tr>
            <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4" mat-sort-header="classcode">Name
            </td>
            <td scope="col" class="col-lg-3 col-md-3 col-sm-3 col-3" mat-sort-header="status">Status
            </td>
            <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4" mat-sort-header="description">Description
            </td>
            <td scope="col" class="d-flex justify-content-center">Action</td>
          </tr>
        </thead>
        <tbody *ngIf="gettingCcList">
          <tr>
            <td colspan="4" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="!gettingCcList" #tbody>
          <tr *ngIf="ccList.length == 0">
            <td colspan="4" class="nodata">Empty</td>
          </tr>

          <tr *ngFor="let cc of ccList; let i = index;">
            <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4 td-edit td-data" (click)="editClasscode(cc)"
              title="{{cc.name}}">{{cc.name}}
            </td>
            <td scope="col" class="col-lg-3 col-md-3 col-sm-3 col-3 td-data" title="{{cc.statusname}}">
              {{cc.statusname}}
            </td>
            <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4 td-data" title="{{cc.description}}">
              {{cc.description}}
            </td>
            <td class="d-flex justify-content-center">
              <button class="btn btn-outline-primary" (click)="editClasscode(cc)">
                <i class='fa fa-edit'></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</div>