import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { formatDate } from '@angular/common';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';


@Component({
  selector: 'app-special-swimmer-preview',
  templateUrl: './special-swimmer-preview.component.html',
  styleUrls: ['./special-swimmer-preview.component.scss']
})
export class SpecialSwimmerPreviewComponent implements OnInit {
  specialswimmer: any;
  specialName: any;
  profileUrl: string = '';
  id!: string;
  breadcrumbs: any[] = [
    { name: 'Swim', url: 'swim', isActive: true },
    { name: 'Special Swimmer', url: 'members/specialswim', isActive: true },
    { name: '', url: '', isActive: false }
  ];

  constructor(
    private router: Router,
    private kunyek: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public allInOneService: AllInOneService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(value => this.id = value.get('id') || '')
    this.getSpecialSwimmerByID();
  }

  getSpecialSwimmerByID() {
    this.kunyek.specialMember().get({ id: this.id }).subscribe({
      next: (res) => {
        this.specialswimmer = res.data;
        this.specialName = res.data.name;
        this.breadcrumbs[2].name = this.specialName;
        if (this.specialswimmer.profileimage) {
          let pimage = JSON.parse(this.specialswimmer.profileimage);
          this.profileUrl = pimage ? pimage.fileurl : "";
          this.loadImageToSessionStorage(this.profileUrl, 'profileImage');
        } else {
          this.clearProfileImage();
        }
      },
      error: (err) => {
        this.messageService.openSnackBar('Failed to get special swimmer preview', 'warn');
      }
    });
  }

  loadImageToSessionStorage(url: string, key: string) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          console.log(reader.result, 'buffered string')
          sessionStorage.setItem(key, reader.result as string);
        };
        reader.readAsDataURL(blob);
      })
      .catch(error => console.error('Error loading image from S3:', error));
  }

  getProfileImage(): string {
    return sessionStorage.getItem('profileImage') || '';
  }

  clearProfileImage() {
    sessionStorage.removeItem('profileImage');
  }

  async loadImages(container: HTMLElement): Promise<void> {
    const images = Array.from(container.getElementsByTagName('img'));
    const promises = images.map((img) => {
      return new Promise<void>((resolve, reject) => {
        if (img.complete && img.naturalHeight !== 0) {
          resolve();
        } else {
          img.onload = () => resolve();
          img.onerror = () => reject(`Failed to load image: ${img.src}`);
        }
      });
    });
    return Promise.all(promises).then(() => { });
  }

  closeForm() {
    this.router.navigate(['members', 'specialswim']);
  }

  goBackToList() {
    this.router.navigateByUrl('members/specialswim');
  }

  printPage() {
    const printContent = document.getElementById('printableCard');
    if (printContent) {
      const printClone = printContent.cloneNode(true);
      const printContainer = document.createElement('div');
      printContainer.style.position = 'absolute';
      printContainer.style.left = '-9999px';
      printContainer.appendChild(printClone);
      document.body.appendChild(printContainer);
      const style = document.createElement('style');
      style.innerHTML = `
        @media print {
          .me-auto, .navbar, .side-nav, .side-nav-expand, .btn-action {
            display: none !important;
          }
          #print-container, #print-container * {
            visibility: visible;
          }
          #print-container {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `;
      document.head.appendChild(style);
      printContainer.id = 'print-container';
      window.print();
      document.body.removeChild(printContainer);
      document.head.removeChild(style);
    } else {
      console.error('printableCard element not found');
    }
  }



  exportPDF() {
    const name = this.specialName + "-" || '';
    const data = document.getElementById('printableCard');
    if (data) {
      html2canvas(data, {
        useCORS: true,
        allowTaint: false,
        logging: true
      }).then(canvas => {
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 0;

        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(`${name}-summer-swim-form.pdf`);

      }).catch(error => {
        console.error('Error generating PDF:', error);
      });
    } else {
      console.error('printableCard element not found');
    }
  }

  formatDate(date: string) {
    if (date == '') return '';
    const pattern = 'dd-MM-yyyy';
    const date1 = formatDate(new Date(date), pattern, 'en-US');
    return date1;
  }
}
