import { Directive, HostListener } from '@angular/core';
declare var bootstrap: any;
declare var $: any;


@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  @HostListener("mouseover") onMouseEnter() {
    this.tooltip();
  }
  @HostListener("mouseleave") onMouseLeave() {
    this.tooltip();
  }
  @HostListener("mouseup") onMouseUp() {
    $('[data-bs-toggle="tooltip"]').tooltip("hide");
  }

  constructor() { }

  tooltip() {
    Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      .forEach(tooltipNode => new bootstrap.Tooltip(tooltipNode))
  }
}
