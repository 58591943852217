import { Component, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { formatDate } from "@angular/common";
import { ContactTracingDetailsComponent } from '../contact-tracing-details/contact-tracing-details.component';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { domain } from 'process';

declare var $: any;
declare var bootstrap: any;

@Component({
  selector: 'app-contacts-tracing',
  templateUrl: './contacts-tracing.component.html',
  styleUrls: ['./contacts-tracing.component.scss']
})
export class ContactsTracingComponent implements OnInit {
  searchLoading: boolean = false;
  ctFormSubmitted: boolean = false;
  evFormSubmitted: boolean = false;

  gettingOrgList: boolean = false;
  gettingChannelList: boolean = false;
  eventListLoading: boolean = false;
  gettingAttendance: boolean = false;
  showDT: boolean = false;

  gettingDomainList: boolean = false;
  gettingLocList: boolean = false;
  formTypeEvent:boolean=true;
  role: any;

  contactsListByPerson: any = [];
  contactsListByLocation: any = [];
  channels: any = [];
  events: any = [];
  attendanceList:any=[];


  organizations: any = []
  locations: any = []
  domains: any = []

  dateFormat = 'yyyyMMdd';
  timeFormat = 'hh:mm:ss aa'
  formatToShow = 'yyyy-MM-ddTHH:mm:ss'

  p: number = 1;

  now = Date.now()
  oneWeekBefore = new Date(this.now).setDate(new Date(this.now).getDate() - 14);

  currentEndDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString()
  currentStartDate = formatDate(new Date(this.oneWeekBefore), this.formatToShow, 'en-US').toString()

  ctForm = {
    "domain": "",
    "org": "",
    "startdate": this.currentStartDate,
    "enddate": this.currentEndDate,
    "starttime": "",
    "endtime": "",
    "range": "10",
    "shortcode": this.allinoneService.getDomain().shortcode,
    "searchtype": "event",
    "searchdata": ""

  }
  evForm = {
    "domainid": "",
    "orgid": "",
    "channelid": ""
  }

  ctFormLocation = {
    "searchdata": ""
  }
  event={
    eventid:"",
    startdate:"",
    enddate:""
  }
  selectedLocation:any=[]


  constructor(public allinoneService: AllInOneService,
    public dialog: MatDialog,
    private kunyekService: KunyekService,
    private router: Router,
    private messageService: MessageService) { 
      if(!this.allinoneService.isAdminOf("org")){
        this.router.navigateByUrl('/home');
      }
    }

  ngOnInit(): void {
    console.log('active router -> ' + this.allinoneService.activeroute);
    this.ctForm.domain = this.allinoneService.getDomain().domainid;
    this.organizations = this.allinoneService.orgs;
    this.ctForm.org = this.allinoneService.selectedOrg? this.allinoneService.selectedOrg : this.organizations[0].orgid
    // this.getOrganization()
    // if (this.role == "300") {
    //   this.ctForm.org = this.allinoneService.getOrganization().orgid;
    // }
    
    this.getLocation("")
    this.getChannelList();

    this.getEventList();
  }

  sortByPersonData(sort: Sort) {
    const data = this.contactsListByPerson
    if (!sort.active || sort.direction === '') {
      this.contactsListByPerson = data
      return;
    }

    this.contactsListByPerson = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid': return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username': return this.allinoneService.compare(a.username, b.username, isAsc);
        default: return 0;
      }
    });
  }

  sortByEventData(sort: Sort) {
    const data = this.attendanceList
    if (!sort.active || sort.direction === '') {
      this.attendanceList = data
      return;
    }

    this.attendanceList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid': return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username': return this.allinoneService.compare(a.username, b.username, isAsc);
        default: return 0;
      }
    });
  }

  sortByLocationData(sort: Sort) {
    const data = this.contactsListByLocation
    if (!sort.active || sort.direction === '') {
      this.contactsListByLocation = data
      return;
    }

    this.contactsListByLocation = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid': return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username': return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'date': return this.allinoneService.compare(a.startdate + a.starttime, b.startdate + b.starttime, isAsc);
        default: return 0;
      }
    });
  }


  // domainChanged(event: any) {
  //   this.ctForm.domain = event.target.value;
  //   if (this.role == "100") {
  //     this.ctForm.org = ""
  //   }
  //   this.getOrganization()
  // }

  getDomainList(): void {
    this.gettingDomainList = true;
    this.domains = []
    this.kunyekService.getDomains().subscribe((res: any) => {
      if (res.returncode == "300") {
        res.domains.map((domain: any) => {
          this.domains.push({
            id: domain.id,
            appid: domain.appid,
            shortcode: domain.shortcode,
            domainid: domain.domainid,
            description: domain.description
          })
        });
        this.gettingDomainList = false;
      }
      else {
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }
    )
  }

  getLocation(g:string) {
    this.locations = []
    this.gettingLocList = true
    var data;
    data = {
      "global": "",
      "domainid": "",
      "orgid": [this.ctForm.org],
      "userid":""
    }
    this.kunyekService.getLocations(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.datalist.map((loc: any) => {
          this.locations.push({
            locid: loc.locid,
            lat: loc.lat,
            lng: loc.lng,
            name: loc.name,
            range: loc.range,
            type: loc.type,
            typeid: loc.typeid,
            description: loc.description
          })
          // Const basicMultiselectComboBox = document.querySelector('#locations');
          // basicMultiselectComboBox.items = this.locations;
        }
        )
        this.gettingLocList = false;
      }
      else {
        this.gettingLocList = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    })
  }

  orgChanged(event: any) {
    this.ctForm.org = event.target.value
    this.allinoneService.selectedOrg = this.ctForm.org
    this.allinoneService.setSelectedOrg(this.ctForm.org)
    if (this.ctForm.searchtype == "location" && this.ctForm.org == "") {
      this.selectedLocation = [];
   
    }
    this.selectedLocation = [];
  if (this.ctForm.org==""){
    this.channels=[]
  }else{
    this.getLocation("");
    this.getChannelList();
    this.getEventList();
  }
 
  }

  gpChanged(event: any) {
    this.evForm.channelid = event.target.value
    this.getLocation("")
    this.getEventList();
  }
  eventChanged(event: any) {
    
    this.event.eventid = event.target.value
    if (this.event.eventid==""){
      this.showDT=false;
      this.event.startdate="";
      this.event.enddate="";
      return
    }
    var resEvent=this.events.filter((x:any)=>x.eventid==this.event.eventid);
    this.showDT=true;
    this.event.startdate=resEvent[0].startdatetoshow;
    this.event.enddate=resEvent[0].enddatetoshow;
    console.log(this.event)

  }
  getAttendance() {
    this.ctFormSubmitted=false;
    this.gettingAttendance = true;
    const param = {
      "eventid": this.event.eventid,
      "orgid" : this.ctForm.org,
      "domainid" : this.ctForm.org,
    }
    this.kunyekService.getAttendance(param).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.attendanceList = res.datalist;
        console.log(res);
        this.searchLoading=false;
      }
      else {
        this.messageService.openSnackBar(res.message, "warn");
      }
      this.gettingAttendance = false;
    }, err => {
      this.searchLoading=false;

      this.gettingAttendance = false;

    });
  }
  getOrganization() {
    this.gettingOrgList = true;
    this.organizations = []
    this.evForm.channelid="";
    this.kunyekService.getOrgByDomainId(this.ctForm.domain).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.datalist.map((org: any) => {
          this.organizations.push({
            orgid: org.orgid,
            appid: org.appid,
            createdby: org.createdby,
            createddate: org.createddate,
            domainid: org.domainid,
            name: org.name,
            description: org.description
          })
        }
        )
        this.gettingOrgList = false;
        this.ctForm.org = this.organizations[0].orgid
        this.getLocation("")
        if(this.ctForm.org!=""){
          this.getChannelList();
        }
        this.getEventList();
      }
      else {
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }
    )
  }
  getEventList() {
    this.events = []
    this.event.eventid=""
    this.eventListLoading = true;
    const format = 'dd/MM/yyyy hh:mm aa';
    // this.eventForm.startdatetime = formatDate(new Date(this.eventForm.startdatetime), format,'en-US').toString()
    const data = {
      "domainid": this.ctForm.domain,
      "orgid": this.ctForm.org,
      "channelid": this.evForm.channelid
    }
    this.kunyekService.getEvents(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.datalist.map((event: any) => {
          var tempStartDate = formatDate(new Date(event.startdate), format, 'en-US').toString()
          var tempEndDate = formatDate(new Date(event.enddate), format, 'en-US').toString()
          this.events.push({
            eventid: event.eventid,
            appid: event.appid,
            createdby: event.createdby,
            createddate: event.createddate,
            description: event.description,
            domainid: event.domainid,
            enddatetime: event.enddate,
            locid: event.locid,
            locname: event.locname,
            name: event.name,
            orgid: event.orgid,
            startdatetime: event.startdate,
            occurrence: event.occurrence,
            startdatetoshow: tempStartDate,
            enddatetoshow: tempEndDate,
          })
        }
        )
        this.eventListLoading = false;
      }
      else {
        this.eventListLoading = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    })
  }
  getChannelList() {
    this.channels = []
    this.gettingChannelList = true
    const domain = this.allinoneService.getDomain();

    const data = {
      "domain": domain.shortcode,
      "orgid": this.ctForm.org,
      "domainid" : domain.domainid
    }

    this.kunyekService.getChannels(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.list.map((channel: any) => {
          this.channels.push({
            "channelid": channel.channelid,
            "channelname": channel.channelname
          })
          console.log(res)
        }
        )
        this.gettingChannelList = false;
      }
      else {
        this.gettingChannelList = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    })
  }

  search() {
    // console.log(this.ctForm);
    // return
    this.ctFormSubmitted = true;
    // if ((this.role == "100" || this.role == "200") && this.ctForm.domain == "") {
    //   return;
    // }
    if (this.ctForm.searchtype == "person" && (this.ctForm.searchdata == "" || this.ctForm.range == "")) {
      return;
    }
    else if (this.ctForm.searchtype == "location" && this.selectedLocation.length == 0 ) {
      return;
    }
   
    else if (this.ctForm.startdate == "" || this.ctForm.enddate == "" || this.ctForm.org == "") {
      return;
    }
    else if (this.ctForm.enddate < this.ctForm.startdate) {
      this.messageService.openSnackBar("Please select the valid end date!", 'warn');
    }
    else if (this.ctForm.searchtype == "event") {
      if(this.event.eventid==""){
        return;
      }else{
        this.searchLoading = true;
        this.getAttendance();
        
      }
    }
    else {
      this.ctForm.starttime = formatDate(new Date(this.ctForm.startdate), this.timeFormat, 'en-US').toString()
      this.ctForm.endtime = formatDate(new Date(this.ctForm.enddate), this.timeFormat, 'en-US').toString()
      var tempStartdate = formatDate(new Date(this.ctForm.startdate), this.dateFormat, 'en-US').toString()
      var tempEnddate = formatDate(new Date(this.ctForm.enddate), this.dateFormat, 'en-US').toString()
      var param;
      this.searchLoading = true;
      if (this.ctForm.searchtype == "person") {
        param = {
          "orgid": this.ctForm.org,
          "domainid": this.ctForm.domain,
          "startdate": tempStartdate,
          "enddate": tempEnddate,

          "starttime": this.ctForm.starttime,
          "endtime": this.ctForm.endtime,
          "range": this.ctForm.range,
          "shortcode": this.ctForm.shortcode,
          "searchtype": this.ctForm.searchtype,
          "searchdata": this.allinoneService.checkUserId(this.ctForm.searchdata)
        }
      }
      else {
        param = {
          "orgid": this.ctForm.org,
          "domainid": this.ctForm.domain,
          "startdate": tempStartdate,
          "enddate": tempEnddate,
          "starttime": this.ctForm.starttime,
          "endtime": this.ctForm.endtime,
          "range": "",
          "shortcode": this.ctForm.shortcode,
          "searchtype": this.ctForm.searchtype,
          "searchdata": this.selectedLocation
        }
      }
      this.kunyekService.getContactstracing(param).subscribe((res: any) => {
        if (res.returncode == "300") {
          if (this.ctForm.searchtype == "person") {
            this.contactsListByPerson = []
            this.contactsListByPerson = res['datalist']
          }
          else {
            this.contactsListByLocation = []
            this.contactsListByLocation = res['datalist']
          }
          this.ctFormSubmitted = false;
          console.log(res)
        }
        else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.ctFormSubmitted = false;
        }
        this.searchLoading = false;
      }, err => {
        this.searchLoading = false;
      })
    }
  }

  searchTypeChange() {
    console.log(this.ctForm.searchtype)
    this.ctFormSubmitted = false
  }

  clear() {
    this.contactsListByLocation = []
    this.contactsListByPerson = []
    this.ctForm = {
      "domain": this.ctForm.domain,
      "org": this.ctForm.org,
      "startdate": this.currentStartDate,
      "enddate": this.currentEndDate,
      "starttime": "",
      "endtime": "",
      "range": "10",
      "shortcode": this.allinoneService.getDomain().shortcode,
      "searchtype": this.ctForm.searchtype,
      "searchdata": ""
    }
    this.ctFormLocation = {
      "searchdata": ""
    }
    this.ctFormSubmitted = false
  }

  viewDetails(item: any) {
    const dialog = this.dialog.open(ContactTracingDetailsComponent, {
      width: '40vw',
      closeOnNavigation: true,
      panelClass: ['event-modal','mat-typography', 'contact-tracing-details-modal'],
      data: {
        item: item
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }

}
