    <div class="title-container mb-3">
        <div class="title">
            Select Group
        </div>
        <div class="close-btn">
            <button mat-icon-button mat-dialog-close (click)="cancel()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>

    <perfect-scrollbar style=" max-height: calc(100% - 80px);" [config]="config" [scrollIndicators]="true">
        <div *ngFor="let group of groupList; let i = index;">
            <label class="form-check mb-2 mt-2" for="flexRadioDefault{{i}}">
                <div *ngIf="group.userlist.length > 2"
                    class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="3">
                    <div class="conversation-summary-avatar-group-member-wrapper">
                        <img class="conversation-summary-avatar-group-member-wrapper"
                            [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="group.userlist[0].imagename">
                    </div>

                    <div class="conversation-summary-avatar-group-member-wrapper">
                        <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                            [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="group.userlist[1].imagename">
                    </div>

                    <div class="conversation-summary-avatar-group-member-wrapper">
                        <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                            [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="group.userlist[2].imagename">
                    </div>
                </div>
                <div *ngIf="group.userlist.length == 2"
                    class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="2">
                    <div class="conversation-summary-avatar-group-member-wrapper">
                        <img class="conversation-summary-avatar-group-member-wrapper"
                            [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="group.userlist[0].imagename">
                    </div>

                    <div class="conversation-summary-avatar-group-member-wrapper">
                        <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                            [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="group.userlist[1].imagename">
                    </div>
                </div>
                <label for="flexRadioDefault{{i}}" class="radio-label">
                    {{group.channelname}}
                </label>
                <input class="form-check-input" (click)="checked(group.channelid)"  type="radio"
                    name="flexRadioDefault" id="flexRadioDefault{{i}}">
            </label>
        </div>
    </perfect-scrollbar>


    <div >
        <button class="btn btn-custom" [disabled]="isdisabled" (click)="share()"  *ngIf="!isLoading" >Share</button>
        <app-button-loader *ngIf="isLoading"
              [button_text]="'Share'" [load_button_text]="'Sharing '" [isLoading]="isLoading"
            [width]="'100'">
        </app-button-loader>
    </div>

