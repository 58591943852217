import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QrSetupComponent } from 'src/app/qr360/qr-setup/qr-setup.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-attendance-qr-setup',
  templateUrl: './attendance-qr-setup.component.html',
  styleUrls: ['./attendance-qr-setup.component.scss'],
})
export class AttendanceQrSetupComponent implements OnInit {
  saveLoading: boolean = false;
  submitted: boolean = false;
  isEditing: boolean = false;

  locations: any = [];
  gettingLocList: boolean = false;

  idToEdit: string = '';

  orgid: string = '';

  attForm = {
    name: '',
    locid: '',
    qrid: '',
    showhistory: false,
    defaulttype: 'time'
  };

  typeList = [
    {
      name: 'Auto',
      value: 'auto',
    },
    {
      name: 'Check In',
      value: 'checkin',
    },
    {
      name: 'Time In/Out',
      value: 'time',
    },
  ];

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<QrSetupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isEditing = data.isEditing;
    this.orgid = data.orgid;
    if (data.locations.length == 0) {
      this.getLocation();
    } else {
      this.locations = data.locations;
    }
    if (this.isEditing) {
      var temp = data.attQrData;
      this.idToEdit = temp.qrid;
      // this.attForm.setValue({
      //   name : temp.name,
      //   locid : temp.locid,
      //   qrid : temp.qrid,
      // })
      this.attForm.name = temp.name;
      this.attForm.locid = temp.locid;
      this.attForm.qrid = temp.qrid;
      this.attForm.defaulttype = temp.defaulttype || 'time';
      this.attForm.showhistory = temp.showhistory;
    }
  }

  ngOnInit(): void {}

  submitForm() {
    this.submitted = true;
    if (this.attForm.name == '') {
      return this.messageService.openSnackBar('Please enter Name.', 'warn');
    }
    if (this.attForm.locid == '') {
      return this.messageService.openSnackBar(
        'Please select Location.',
        'warn'
      );
    }
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    const data = {
      orgid: this.orgid,
      name: this.attForm.name,
      locid: this.attForm.locid,
      qrid: this.isEditing ? this.attForm.qrid : '',
      defaulttype: this.attForm.defaulttype,
      showhistory: this.attForm.showhistory,
    };

    console.log(data);

    var fun = this.isEditing
      ? this.kunyekService.updateAttendanceQr(data)
      : this.kunyekService.createAttendanceQr(data);
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var locationName = '';
          for (var i = 0; i < this.locations.length; i++) {
            if (this.locations[i]['locid'] == this.attForm.locid) {
              locationName = this.locations[i]['name'];
            }
          }
          var temp = {
            qrid: this.isEditing ? this.attForm.qrid : res.qrid,
            name: this.attForm.name,
            locid: this.attForm.locid,
            locname: locationName,
            defaulttype: this.attForm.defaulttype,
            showhistory: this.attForm.showhistory,
          };
          this.dialogRef.close(temp);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  getLocation() {
    this.locations = [];
    this.gettingLocList = true;
    // data = {
    //   "global": "",
    //   "domainid": this.eventForm.domain,
    //   "orgid": [this.eventForm.org],
    //   "userid": ""
    // }
    // if (this.role == "300" || this.eventForm.org != "") {
    var data = {
      global: '',
      domainid: '',
      orgid: [this.orgid],
      personal: '',
    };
    // }
    this.kunyekService.getLocations(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        res.datalist.map((loc: any) => {
          this.locations.push({
            locid: loc.locid,
            lat: loc.lat,
            lng: loc.lng,
            name: loc.name,
            range: loc.range,
            type: loc.type,
            typeid: loc.typeid,
            description: loc.description,
          });
        });
        this.gettingLocList = false;
      } else {
        this.gettingLocList = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    });
  }

  locChanged(event: any) {
    this.attForm.locid = event.target.value;
  }

  cancel() {
    this.dialogRef.close();
  }
}
