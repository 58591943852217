<div class="d-flex align-items-center justify-content-between mb-3">
  <div>
    <h3 class="mb-0">
      {{ typeName }}
        <span *ngIf="typeName == 'Leave' || typeName == 'Absence'"  class="tit-user">({{ listData.username ? listData.username : listData.userid }})</span>
    </h3>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="row" *ngIf="typeName == 'Leave'">
  <!-- {{ listData.leavedatalist }} -->
  <div class="table-responsive" matSort (matSortChange)="sortProjData($event)">
    <table class="table table-responsive table-borderless">
      <thead class="table-header">
        <tr>
          <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="date">
            Date
          </td>
          <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="requesttypename">
            Leave Type
          </td>
          <td scope="col" class="col-lg-4 col-md-4">

          </td>
        </tr>
      </thead>

      <tbody>
        <tr *ngIf="listData.leavedatalist.length == 0">
          <td colspan="4" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.leavedatalist; let index = index">
          <td class="td-data">
            <span [title]="item.date">
              {{ item.date.split(',').length > 1 ?
              allinoneService.formatDBToShow(item.date.split(',')[0]) + " - " +
              allinoneService.formatDBToShow(item.date.split(',')[1])
              : allinoneService.formatDBToShow(item.date.split(',')[0])
              }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.requesttypename">{{ item.requesttypename }}
            </span>
          </td>
          <td class="td-data">
            <span>
              {{ item.duration && item.duration == '0.5' ? 'Half Leave' : ''}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row" *ngIf="typeName == 'Absence'">
  <div class="table-responsive" matSort (matSortChange)="sortProjData($event)">
    <table class="table table-responsive table-borderless">
      <thead class="table-header">
        <tr>
          <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="date">
            Date
          </td>
          <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="halfdayabsent">
            
          </td>

        </tr>
      </thead>
      <tbody *ngIf="typeName == 'Absence'">
        <tr *ngIf="listData.absencedatalist.length == 0 && listData.halfdayabsentlist.length == 0 ">
          <td colspan="4" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of absenceList; let index = index">
          <td class="td-data">
              <span [title]="item.date">{{ allinoneService.formatDBToShow(item.date) }}
              </span>
          </td>
          <td class="td-data">
            <span [title]="item.halfdayabsent">{{ item.halfdayabsent ? 'Half day absent' : '' }}
            </span>
        </td>
        </tr>
        <!-- <tr *ngFor="let item of listData.halfdayabsentlist; let index = index">
          <td class="td-data">
            <span [title]="item['halfdayabsent']">{{ allinoneService.formatDBToShow(item['date']) }}
            </span>
          </td>
          <td class="td-data">
              <span [title]="item['halfdayabsent']">{{ item['halfdayabsent'] }}
              </span>
          </td>
        </tr> -->
      </tbody>
    </table>
  </div>
</div>

<div class="row" *ngIf="typeName =='Late time 1' || typeName =='Late time 2' || typeName =='Late time 3' || typeName =='Late time 4' ||
typeName =='Early out time 1' || typeName =='Early out time 2' || typeName =='Early out time 3' || typeName =='Early out time 4' || typeName =='Half Day Absence' ">
  <!-- {{ listData.absencedatalist }} -->
  <div class="table-responsive" matSort (matSortChange)="sortProjData($event)">
  <!-- <div class="table-responsive"> -->
    <table class="table table-responsive table-borderless">
      <thead class="table-header">
        <tr>
          <td scope="col" class="col-md-2" mat-sort-header="emid">
            Employee ID
          </td>
          <td scope="col" class="col-md-2" mat-sort-header="username">
            Name
          </td>
          <td scope="col" class="col-md-2" mat-sort-header="userid">
            Use ID
          </td>
          
          <td scope="col" class="col-md-2" mat-sort-header="date">
            Date
          </td>
          <td scope="col" class="col-md-2" mat-sort-header="timein">
            {{ from =="earlyout" ? 'Time Out' : 'Time In'  }}
          </td>
        </tr>
      </thead>

      <tbody *ngIf="typeName == 'Early out time 1' || typeName == 'Early out time 2' || typeName == 'Early out time 3' || typeName == 'Early out time 4' || typeName == 'Half Day Absence' || typeName == 'Late time 1' || typeName == 'Late time 2' || typeName == 'Late time 3' || typeName == 'Late time 4'">
        <tr *ngIf="listData[dateListName].length == 0">
          <td colspan="5" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData[dateListName]; let index = index">
          <td class="td-data">
            <span title="emid">{{ emid }}
            </span>
          </td>
          <td class="td-data">
            <span title="username">{{ username }}
            </span>
          </td>
          <td class="td-data">
            <span title="userid">{{ userid }}
            </span>
          </td>
          
          
          <td class="td-data">
            <span [title]="item.date">{{ allinoneService.formatDBToShow(item.date) }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.time">{{ item.time }}
            </span>
          </td>

        </tr>
      </tbody>
      <!-- <tbody *ngIf="typeName == 'Late time 1'">
        <tr *ngIf="listData.latetime1datelist.length == 0">
          <td colspan="5" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.latetime1datelist; let index = index">
          <td class="td-data">
            <span title="userid">{{ userid }}
            </span>
          </td>
          <td class="td-data">
            <span title="username">{{ username }}
            </span>
          </td>
          <td class="td-data">
            <span title="emid">{{ emid }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.date">{{ allinoneService.formatDBToShow(item.date) }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.time">{{ item.time }}
            </span>
          </td>

        </tr>
      </tbody>
      <tbody *ngIf="typeName == 'Late time 2'">
        <tr *ngIf="listData.latetime2datelist.length == 0">
          <td colspan="5" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.latetime2datelist; let index = index">
          <td class="td-data">
            <span title="userid">{{ userid }}
            </span>
          </td>
          <td class="td-data">
            <span title="username">{{ username }}
            </span>
          </td>
          <td class="td-data">
            <span title="emid">{{ emid }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.date">{{ allinoneService.formatDBToShow(item.date) }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.time">{{ item.time }}
            </span>
          </td>

        </tr>
      </tbody>
      <tbody *ngIf="typeName == 'Late time 3'">
        <tr *ngIf="listData.latetime3datelist.length == 0">
          <td colspan="5" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.latetime3datelist; let index = index">
          <td class="td-data">
            <span title="userid">{{ userid }}
            </span>
          </td>
          <td class="td-data">
            <span title="username">{{ username }}
            </span>
          </td>
          <td class="td-data">
            <span title="emid">{{ emid }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.date">{{ allinoneService.formatDBToShow(item.date) }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.time">{{ item.time }}
            </span>
          </td>

        </tr>
      </tbody> -->
      <!-- <tbody *ngIf="typeName == 'Late time 3'">
        <tr *ngIf="listData.latetime3datelist.length == 0">
          <td colspan="4" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.latetime3datelist; let index = index">
          <td class="td-data">
            <span [title]="item">{{ allinoneService.formatDBToShow(item) }}
            </span>
          </td>

        </tr>
      </tbody> -->
      <!-- <tbody *ngIf="typeName == 'Late time 4'">
        <tr *ngIf="listData.latetime4datelist.length == 0">
          <td colspan="5" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.latetime4datelist; let index = index">
          <td class="td-data">
            <span title="userid">{{ userid }}
            </span>
          </td>
          <td class="td-data">
            <span title="username">{{ username }}
            </span>
          </td>
          <td class="td-data">
            <span title="emid">{{ emid }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.date">{{ allinoneService.formatDBToShow(item.date) }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.time">{{ item.time }}
            </span>
          </td>

        </tr>
      </tbody> -->
      <!-- <tbody *ngIf="typeName == 'Early out time 1'">
        <tr *ngIf="listData.earlyouttime1datelist.length == 0">
          <td colspan="4" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.earlyouttime1datelist; let index = index">
          <td class="td-data">
            <span [title]="item">{{ allinoneService.formatDBToShow(item) }}
            </span>
          </td>

        </tr>
      </tbody> -->

      <!-- cmt -->
      <!-- <tbody *ngIf="typeName == 'Early out time 2'">
        <tr *ngIf="listData.earlyouttime2datelist.length == 0">
          <td colspan="5" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.earlyouttime2datelist; let index = index">
          <td class="td-data">
            <span title="userid">{{ userid }}
            </span>
          </td>
          <td class="td-data">
            <span title="username">{{ username }}
            </span>
          </td>
          <td class="td-data">
            <span title="emid">{{ emid }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.date">{{ allinoneService.formatDBToShow(item.date) }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.time">{{ item.time }}
            </span>
          </td>

        </tr>
      </tbody>
      <tbody *ngIf="typeName == 'Early out time 3'">
        <tr *ngIf="listData.earlyouttime3datelist.length == 0">
          <td colspan="5" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.earlyouttime3datelist; let index = index">
          <td class="td-data">
            <span title="userid">{{ userid }}
            </span>
          </td>
          <td class="td-data">
            <span title="username">{{ username }}
            </span>
          </td>
          <td class="td-data">
            <span title="emid">{{ emid }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.date">{{ allinoneService.formatDBToShow(item.date) }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.time">{{ item.time }}
            </span>
          </td>

        </tr>
      </tbody>
      <tbody *ngIf="typeName == 'Early out time 4'">
        <tr *ngIf="listData.earlyouttime4datelist.length == 0">
          <td colspan="5" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.earlyouttime4datelist; let index = index">
          <td class="td-data">
            <span title="userid">{{ userid }}
            </span>
          </td>
          <td class="td-data">
            <span title="username">{{ username }}
            </span>
          </td>
          <td class="td-data">
            <span title="emid">{{ emid }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.date">{{ allinoneService.formatDBToShow(item.date) }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.time">{{ item.time }}
            </span>
          </td>

        </tr>
      </tbody>
      
      <tbody *ngIf="typeName == 'Half Day Absent'">
        <tr *ngIf="listData.halfdayabsentlist.length == 0">
          <td colspan="5" class="nodata">Empty</td>
        </tr>
        <tr *ngFor="let item of listData.halfdayabsentlist; let index = index">
          <td class="td-data">
            <span title="userid">{{ userid }}
            </span>
          </td>
          <td class="td-data">
            <span title="username">{{ username }}
            </span>
          </td>
          <td class="td-data">
            <span title="emid">{{ emid }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.date">{{ allinoneService.formatDBToShow(item.date) }}
            </span>
          </td>
          <td class="td-data">
            <span [title]="item.time">{{ item.time }}
            </span>
          </td>

        </tr>
      </tbody> -->
    </table>
  </div>
</div>