import { Component, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-pay-period',
  templateUrl: './pay-period.component.html',
  styleUrls: ['./pay-period.component.css']
})
export class PayPeriodComponent implements OnInit {

  isEditting: boolean = false;
  editData: any;
  isLoading: boolean = false;
  periods: any = [];
  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService
  ) { }

  ngOnInit(): void {
    this.getPeriodData()
  }


  getPeriodData() {
    const data = {
      "orgid": this.allinoneService.selectedOrg,
      "userid": this.allinoneService.getUserId(),
      "domainid": this.allinoneService.getDomain(),
    }
    this.isLoading = true;
    this.kunyekService.getPeriodData(data).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.isLoading = false;
          if (res.periodlist.length > 0) {
            const list = res.periodlist;
            for (let i = 0; i < list.length; i++) {
              list[i].startdate = this.fomratDate(list[i].startdate)
              list[i].enddate = this.fomratDate(list[i].enddate)
              this.periods.push(list[i])
            }
          }
        } else {
          throw res.message
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.log(err)
      }
    })
  }

  fomratDate(value: string): string {
    if (value === "") return "";
    const pattern = /(\d{4})(\d{2})(\d{2})/;
    return value.replace(pattern, '$3/$2/$1');
  }

  handleEditData(data: any) {
    this.editData = data;
    this.isEditting = true;
  }

  handleNewProid() {
    this.editData = null;
    this.isEditting = true;
  }

  closeEdit() {
    this.isEditting = false;
    this.periods = []
    this.getPeriodData()
  }

}
