<nav class="navbar navbar-expand-lg sticky-top navbar-light" (mouseleave)="closeMenu()">

    <div class="d-flex align-items-center">
        <a href="/" *ngIf="!user">
            <img src="assets/images/kunyek.png" alt="" class="navbar-brand-logo"
                *ngIf="allinoneService.getAtoken() == ''">
        </a>

        <!-- <div class="apps-container" data-bs-toggle="offcanvas" href="#offcanvasWithBothOptions"
            *ngIf="(allinoneService.isAttendanceView || (!allinoneService.isAttendanceView )) && !allinoneService.isTransportionView && !allinoneService.isQrModule && !allinoneService.isCs365View">
            <mat-icon class="apps">menu</mat-icon>
        </div> -->

        <div class="apps-container" (click)="toggleSideBar()"
            *ngIf="(allinoneService.isAttendanceView || (!allinoneService.isAttendanceView )) && !allinoneService.isTransportionView && !allinoneService.isQrModule && !allinoneService.isCs365View && !allinoneService.isMemberModule">
            <mat-icon class="apps">menu</mat-icon>
        </div>

        <div class="apps-container" (click)="toggleKokineSideBar()" *ngIf="isHRAdmin && isorgAdmin && allinoneService.isMemberModule && 
            !allinoneService.isTransportionView && 
            !allinoneService.isQrModule && 
            !allinoneService.isCs365View && 
            !allinoneService.isAttendanceView">
            <mat-icon class="apps">menu</mat-icon>
        </div>


        <!-- <div style="height: 50px;" class="d-flex flex-column justify-content-center align-items-start ms-2">
            <p style="margin: 0;padding: 0;font-size: 10px;color: white; line-height: 0.4;" >365</p>
            <p style="margin: 0;padding: 0;font-size: 16px;color: white;">Connect</p>
        </div> -->
        <!-- [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }" -->
        <div class="logo-container ms-1" *ngIf="!allinoneService.isCs365View && !allinoneService.isMemberModule"
            [ngClass]="{'ms-4' : allinoneService.isAttendanceView  || allinoneService.isTransportionView || allinoneService.isQrModule || allinoneService.isCs365View}">
            <span style="line-height: 0.4;font-size: 10px;margin-top: 3px;">{{ allinoneService.appView == '001' ? '365'
                : 'Neo' }} </span>
            <span style="font-size: 15px;">{{ allinoneService.appView == '001' ? 'Connect' : 'Teams' }}</span>
        </div>

        <!-- kokine nav logo text -->
        <!-- <div class="logo-container ms-1"
            *ngIf="!allinoneService.isCs365View && !allinoneService.isAttendanceView && !allinoneService.isTransportionView &&  !allinoneService.isQrModule"
            [ngClass]="{'ms-4' : allinoneService.isMemberModule }">
            <span style="line-height: 0.4;font-size: 10px;margin-top: 3px;">{{ allinoneService.appView == '001' ?
                'Member'
                : '' }} </span>
            <span style="font-size: 15px;">{{ allinoneService.appView == '001' ? 'Connect' : '' }}</span>
        </div> -->

        <div class="logo-container ms-4" *ngIf="allinoneService.isCs365View">
            <span style="font-size: 15px;">CS 365</span>
        </div>
    </div>



    <div class="container-fluid" style="background-color: var(--button-color);">
        <div class="nav-right" *ngIf="authenticate">
            <ng-container *ngIf="!allinoneService.isQrModule">
                <ng-container *ngIf=" !this.allinoneService.isTransportionView">
                    <div class="domain-dd"
                        *ngIf="!allinoneService.isCRMServiceView && !allinoneService.isCustomerCare && orgList.length>0"
                        (click)="$event.stopPropagation();" [matMenuTriggerFor]="orgmenu">
                        <div *ngIf="selected_domain" class="org-name">
                            {{selected_org.name}}
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fas fa-caret-down" style="color: white;"></i>
                        </div>
                    </div>

                    <mat-menu #orgmenu="matMenu" class="org-menu" xPoition="before">
                        <button mat-menu-item *ngFor="let org of orgList" (click)="chooseOrg(org)">{{org.name}}
                        </button>
                    </mat-menu>
                    <!-- *ngIf="(allinoneService.isCRMServiceView || allinoneService.isOfficeView) && !allinoneService.isCustomerCare && allinoneService.selected_orgcrm != undefined" -->
                    <div class="domain-dd"
                        *ngIf="allinoneService.isCRMServiceView && !allinoneService.isCustomerCare && allinoneService.selected_orgcrm != undefined"
                        (click)="$event.stopPropagation();" [matMenuTriggerFor]="orgmenus">
                        <div *ngIf="selected_domain && allinoneService.selected_orgcrm" class="org-name">
                            {{allinoneService.selected_orgcrm.name}}
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fas fa-caret-down" style="color: white;"></i>
                        </div>
                    </div>

                    <mat-menu #orgmenus="matMenu" class="org-menu" xPosition="before">
                        <button mat-menu-item *ngFor="let org of orgListcrm" (click)="chooseOrgCRM(org)">{{org.name}}
                        </button>
                    </mat-menu>
                </ng-container>

                <ng-container *ngIf="this.allinoneService.isTransportionView && isTransportationAdmin">
                    <div class="domain-dd" (click)="$event.stopPropagation();" [matMenuTriggerFor]="orgmenu">
                        <div *ngIf="selected_domain" class="org-name">
                            {{selected_orgtran.name}}
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fas fa-caret-down" style="color: white;"></i>
                        </div>
                    </div>

                    <mat-menu #orgmenu="matMenu" class="org-menu" xPoition="before">
                        <button mat-menu-item *ngFor="let org of orgListTran"
                            (click)="chooseTranOrg(org)">{{org.name}}</button>
                    </mat-menu>
                </ng-container>
                <div *ngIf="!allinoneService.isCustomerCare && !allinoneService.isTransportionView">
                    <div class="line-2">
                    </div>
                </div>

                <div *ngIf="allinoneService.isTransportionView">
                    <div class="line-2">
                    </div>
                </div>
            </ng-container>


            <div class="apps-container-2" [matMenuTriggerFor]="appmenu" *ngIf="!allinoneService.isMemberModule">
                <mat-icon class="apps">apps</mat-icon>
            </div>

            <mat-menu #appmenu="matMenu" class="appmenu">
                <div class="d-flex flex-wrap px-3 justify-content-start align-items-center">
                    <!-- <ng-container *ngFor="let app of appsmenu">
                        <div class="p-1">
                            <div class="card d-flex flex-row align-items-start justify-content-between p-2 mb-1 appmenu-card"
                                (click)="openapp(app)" data-bs-dismiss="offcanvas">
                                <div class="d-flex flex-column">
                                    <div class="mb-1">
                                        <svg-icon [src]="app.icon" [stretch]="false"
                                            [svgStyle]="{'width.px':20, 'height.px':20}">
                                        </svg-icon>
                                    </div>
                                    <div>
                                        {{app.name}}
                                    </div>
                                </div>
                                <div class="icon-n" *ngIf="app.open == '0'">
                                    <mat-icon>open_in_new</mat-icon>
                                </div>
                            </div>
                        </div>
                    </ng-container> -->

                    <ng-container *ngFor="let app of appsmenu">
                        <div class="d-flex flex-row align-items-center justify-content-center p-2 mb-1"
                            (click)="openapp(app)" data-bs-dismiss="offcanvas">
                            <!-- <div class="appmenu-card">
                                <div class="mb-1">
                                    <svg-icon [src]="app.icon" [stretch]="false"
                                        [svgStyle]="{'width.px':30, 'height.px':30}">
                                    </svg-icon>
                                </div>
                                <div>
                                    {{app.name}}
                                </div>
                            </div> -->
                            <div class="appmenu-card">
                                <div class="mb-1">
                                    <img [src]="app.icon" width="30" height="30" style="border-radius: 100%;">
                                </div>
                                <div style="font-size: 13px;">
                                    {{app.name}}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
                <!-- <div class="row">
                    <ng-container *ngFor="let app of apps">
                        
                        <div *ngIf="app.name == 'Learn' || app.name == 'Employee' || app.name == 'Neo Payroll' " class="col-6 p-1">
                            <div class="card d-flex flex-row align-items-start justify-content-between p-2 mb-1"
                                (click)="openapp(app)" data-bs-dismiss="offcanvas">
                                <div class="d-flex flex-column">
                                    <div class="mb-1">
                                        <svg-icon [src]="app.icon" [stretch]="false"
                                            [svgStyle]="{'width.px':20, 'height.px':20}">
                                        </svg-icon>
                                    </div>
                                    <div>
                                        {{app.name}}
                                    </div>
                                </div>
                                <div class="icon" *ngIf="app.open == '0'">
                                    <mat-icon>open_in_new</mat-icon>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div> -->
            </mat-menu>

            <div *ngIf="allinoneService.appView == '002'" class="apps-container-3" [matMenuTriggerFor]="settingmenu">
                <mat-icon class="apps">settings</mat-icon>
            </div>

            <mat-menu #settingmenu="matMenu" class="appmenu">
                <ng-container *ngFor="let setting of settingmenulist">
                    <div class="setting-container" (click)="opensetting(setting)">
                        <p class="m-0 p-0">{{ setting.name }}</p>
                    </div>
                </ng-container>
            </mat-menu>

            <div *ngIf="authenticate && addAttr" [matMenuTriggerFor]="menu">
                <a style="outline: none;" *ngIf="user">
                    <img src="../../../assets/images/profile.png" class="navbar-brand-logo-p profile" [alt]="appname"
                        *ngIf="user.imagename == ''">
                    <img [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="user.imagename"
                        class="navbar-brand-logo-p profile" [alt]="appname" *ngIf="user.imagename != ''">
                </a>
            </div>

            <div class="profile-container" *ngIf="authenticate && !addAttr" [matMenuTriggerFor]="menu">

                <!-- <span class="user-name">{{allinoneService.getKunyekUserName()}}</span> -->

                <a style="outline: none;" *ngIf="user" (mouseleave)="closeMenu()">

                    <img src="../../../assets/images/profile.png" class="navbar-brand-logo-p profile" [alt]="appname"
                        *ngIf="user.imagename == ''">


                    <img [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="user.imagename"
                        class="navbar-brand-logo-p profile" [alt]="appname" *ngIf="user.imagename != ''">
                </a>
            </div>

            <mat-menu #menu="matMenu" class="profile-menu">

                <div class="d-flex flex-column align-items-center justify-content-center" style="padding: 0 20px;">
                    <div class="img-wrapper" (click)="editProfile()">
                        <img src="../../../assets/images/profile.png" class="menu-img-profile" [alt]="appname"
                            *ngIf="user.imagename == ''">

                        <img [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="user.imagename"
                            class="menu-img-profile" [alt]="appname" *ngIf="user.imagename != ''">

                    </div>
                    <div class="menu-name mt-1" (click)="editProfile()"> {{allinoneService.getKunyekUserName()}} </div>
                    <div class="menu-userid" (click)="editProfile()"> {{allinoneService.getUserId()}} </div>
                    <div class="menu-version"> Version 3.239</div>

                    <div class="mt-2 w-100 signout-container">
                        <div class="d-flex align-items-center justify-content-start" *ngIf="!gettingdomain"
                            (click)="$event.stopPropagation();" [matMenuTriggerFor]="domainmenu">
                            <mat-icon>flag</mat-icon>
                            <!-- <div class="domain-dd" *ngIf="!gettingdomain" (click)="$event.stopPropagation();"
                                [matMenuTriggerFor]="domainmenu"> -->
                            <div class="domain-dd">
                                <div *ngIf="selected_domain" class="domain-name">
                                    {{selected_domain.description}}
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="fas fa-caret-down"></i>
                                </div>
                            </div>
                        </div>
                        <mat-menu #domainmenu="matMenu" class="domain-menu">
                            <button mat-menu-item *ngFor="let domain of domains"
                                (click)="chooseDomain(domain)">{{domain.description}}</button>
                        </mat-menu>
                    </div>
                    <div class="mt-1 w-100 signout-container" (click)="logout()">
                        <div class="d-flex align-items-center justify-content-start">
                            <mat-icon>exit_to_app</mat-icon>
                            <div class="signout-btn-n ms-3">Sign out</div>
                        </div>
                    </div>
                </div>

                <!-- <div class="d-flex justify-content-between">
                    <div class="domain-dd" *ngIf="!gettingdomain" (click)="$event.stopPropagation();"
                        [matMenuTriggerFor]="domainmenu">
                        <div *ngIf="selected_domain" class="domain-name">
                            {{selected_domain.description}}
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fas fa-caret-down"></i>
                        </div>
                    </div>
                    <mat-menu #domainmenu="matMenu" class="domain-menu">
                        <button mat-menu-item *ngFor="let domain of domains"
                            (click)="chooseDomain(domain)">{{domain.description}}</button>
                    </mat-menu>
                    <div class="signout-btn" (click)="logout()">Sign out</div>
                </div>
                <div class="img-wrapper">
                    <div class="img-wrapper" (click)="editProfile()">
                        <img src="../../../assets/images/profile.png" class="menu-img-profile" [alt]="appname"
                            *ngIf="user.imagename == ''">

                        <img [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="user.imagename"
                            class="menu-img-profile" [alt]="appname" *ngIf="user.imagename != ''">

                    </div>
                    <div class="profile-name mx-2">
                        <div class="menu-name" (click)="editProfile()"> {{allinoneService.getKunyekUserName()}} </div>
                        <div class="menu-userid" (click)="editProfile()"> {{allinoneService.getUserId()}} </div>
                        <div class="menu-version"> Version 3.201 </div>
                    </div>
                </div> -->
            </mat-menu>

            <!-- profile old ui design -->
            <!-- <mat-menu #menu="matMenu" class="profile-menu">
                <div class="d-flex justify-content-between">
                    <div class="domain-dd" *ngIf="!gettingdomain" (click)="$event.stopPropagation();"
                        [matMenuTriggerFor]="domainmenu">
                        <div *ngIf="selected_domain" class="domain-name">
                            {{selected_domain.description}}
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fas fa-caret-down"></i>
                        </div>
                    </div>
                    <mat-menu #domainmenu="matMenu" class="domain-menu">
                        <button mat-menu-item *ngFor="let domain of domains"
                            (click)="chooseDomain(domain)">{{domain.description}}</button>
                    </mat-menu>
                    <div class="signout-btn" (click)="logout()">Sign out</div>
                </div>
                <div class="img-wrapper">
                    <div class="img-wrapper" (click)="editProfile()">
                        <img src="../../../assets/images/profile.png" class="menu-img-profile" [alt]="appname"
                            *ngIf="user.imagename == ''">

                        <img [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="user.imagename"
                            class="menu-img-profile" [alt]="appname" *ngIf="user.imagename != ''">

                    </div>
                    <div class="profile-name mx-2">
                        <div class="menu-name" (click)="editProfile()"> {{allinoneService.getKunyekUserName()}} </div>
                        <div class="menu-userid" (click)="editProfile()"> {{allinoneService.getUserId()}} </div>
                        <div class="menu-version"> Version 3.201 </div>
                    </div>
                </div>
            </mat-menu> -->
        </div>
    </div>
</nav>

<div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions"
    aria-labelledby="offcanvasWithBothOptionsLabel">
    <div class="offcanvas-header">
        <span class="offcanvas-title" data-bs-dismiss="offcanvas" id="offcanvasWithBothOptionsLabel">
            <mat-icon style="color: var(--main-color);">menu</mat-icon>
        </span>
        <span class="offcanvas-title" data-bs-dismiss="offcanvas" id="offcanvasWithBothOptionsLabel">
            <mat-icon>close</mat-icon>
        </span>
    </div>
    <div class="offcanvas-body pt-0" style="padding: 0 !important;">
        <h3 (click)="t()" style="padding: 0 1rem;"><strong>Apps Menu</strong></h3>
        <div>
            <ng-container *ngFor="let app of apps">
                <div (click)="openapp(app)" data-bs-dismiss="offcanvas" class="menu-card">
                    <div class="d-flex flex-column">
                        <div class="d-flex align-content-center justify-content-start">
                            <div class="">
                                <svg-icon [src]="app.icon" [stretch]="false"
                                    [svgStyle]="{'width.px':20, 'height.px':20}">
                                </svg-icon>
                            </div>
                            <div class="ms-3">
                                {{app.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- <div class="row">
            <ng-container *ngFor="let app of apps">
                <div class="col-6 p-1">
                    <div class="card d-flex flex-row align-items-start justify-content-between p-2 mb-1 custom-card"
                        (click)="openapp(app)" data-bs-dismiss="offcanvas">
                        <div class="d-flex flex-column">
                            <div class="mb-1">
                                <svg-icon [src]="app.icon" [stretch]="false"
                                    [svgStyle]="{'width.px':20, 'height.px':20}">
                                </svg-icon>
                            </div>
                            <div>
                                {{app.name}}
                            </div>
                        </div>
                        <div class="icon" *ngIf="app.open == '0'">
                            <mat-icon>open_in_new</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="col-6 p-1" *ngIf="app.path == '/adminconsole'">

                </div>
            </ng-container>
        </div> -->
    </div>
</div>

<!-- old menu ui -->
<!-- <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions"
    aria-labelledby="offcanvasWithBothOptionsLabel">
    <div class="offcanvas-header">
        <span class="offcanvas-title" data-bs-dismiss="offcanvas" id="offcanvasWithBothOptionsLabel">
            <mat-icon style="color: var(--main-color);">apps</mat-icon>
        </span>
        <span class="offcanvas-title" data-bs-dismiss="offcanvas" id="offcanvasWithBothOptionsLabel">
            <mat-icon>close</mat-icon>
        </span>
    </div>
    <div class="offcanvas-body pt-0">
        <h3><strong>Apps Menu</strong></h3>
        <div class="row">
            <ng-container *ngFor="let app of apps">
                <div class="col-6 p-1">
                    <div class="card d-flex flex-row align-items-start justify-content-between p-2 mb-1 custom-card"
                        (click)="openapp(app)" data-bs-dismiss="offcanvas">
                        <div class="d-flex flex-column">
                            <div class="mb-1">
                                <svg-icon [src]="app.icon" [stretch]="false"
                                    [svgStyle]="{'width.px':20, 'height.px':20}">
                                </svg-icon>
                            </div>
                            <div>
                                {{app.name}}
                            </div>
                        </div>
                        <div class="icon" *ngIf="app.open == '0'">
                            <mat-icon>open_in_new</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="col-6 p-1" *ngIf="app.path == '/adminconsole'">

                </div>
            </ng-container>
        </div>
    </div>
</div> -->