<!-- <div (click)="test()" >test</div> -->
<div class="container-fluid">
  <div class="content">
    <!-- breadcrumb admin -->
    <!-- <ng-container *ngIf="isAdmin">
            <app-hcm-breadcrumb *ngIf="!viewingRequest && !transportationView && !transportationDetail "
                s_breadcrumb="Approval">
            </app-hcm-breadcrumb>
            <app-hcm-breadcrumb *ngIf="viewingRequest && !transportationView && !transportationDetail "
                s_breadcrumb="Approval" [t_breadcrumb]="'Ref No: ' + selectedRequest.requestrefno" (emit)="cancel()">
            </app-hcm-breadcrumb>
            <app-hcm-breadcrumb *ngIf="transportationView && !transportationDetail" [s_breadcrumb]="'Approval'"
                [t_breadcrumb]="'Ref No. ' + tranrefno" (emit)="transportationcancel()"></app-hcm-breadcrumb>
            <app-hcm-breadcrumb *ngIf="!transportationView && transportationDetail" [s_breadcrumb]="'Approval'"
                [t_breadcrumb]="'Ref No. ' + tranrefno" [f_breadcrumb]="'Details'" (emit)="cancelToRequest()"
                (emit1)="transportationcancel2()" (emit2)="transportationcancel2()"></app-hcm-breadcrumb>
        </ng-container> -->

    <!-- breadcrumb hxm -->
    <!-- <ng-container *ngIf="!isAdmin">
            <app-office-breadcrumb *ngIf="!viewingRequest && !transportationView && !transportationDetail "
                [s_breadcrumb]="'Approval'">
            </app-office-breadcrumb>
            <app-office-breadcrumb *ngIf="viewingRequest && !transportationView && !transportationDetail "
                [s_breadcrumb]="'Approval'" [t_breadcrumb]="'Ref No: ' + selectedRequest.requestrefno"
                (emit)="cancel()">
            </app-office-breadcrumb>
            <app-office-breadcrumb *ngIf="transportationView && !transportationDetail" [s_breadcrumb]="'Approval'"
                [t_breadcrumb]="'Ref No. ' + tranrefno" (emit)="transportationcancel()"></app-office-breadcrumb>
            <app-office-breadcrumb *ngIf="!transportationView && transportationDetail" [s_breadcrumb]="'Approval'"
                [t_breadcrumb]="'Ref No. ' + tranrefno" [f_breadcrumb]="'Details'" (emit)="cancelToRequest()"
                (emit1)="transportationcancel2()" (emit2)="transportationcancel2()"></app-office-breadcrumb>
        </ng-container> -->

    <ng-container *ngIf="allinoneService.appView == '002'">
      <app-team-breadcrumb
        *ngIf="
          !viewingRequest &&
          !addingRequest &&
          !transportationView &&
          !transportationDetail
        "
        [s_breadcrumb]="cashAdvncedView ? 'Cash Approval' : 'Approval'"
      >
      </app-team-breadcrumb>

      <app-team-breadcrumb
        *ngIf="addingRequest && !transportationView && !transportationDetail"
        [s_breadcrumb]="cashAdvncedView ? 'Cash Approval' : 'Approval'"
        [t_breadcrumb]="'New'"
        (emit)="cancel()"
      >
      </app-team-breadcrumb>

      <app-team-breadcrumb
        *ngIf="
          viewingRequest &&
          !addingRequest &&
          !transportationView &&
          !transportationDetail
        "
        [s_breadcrumb]="cashAdvncedView ? 'Cash Approval' : 'Approval'"
        [t_breadcrumb]="'Ref No: ' + selectedRequest.requestrefno"
        (emit)="cancel()"
      >
      </app-team-breadcrumb>
      <app-team-breadcrumb
        *ngIf="transportationView && !transportationDetail"
        [s_breadcrumb]="'Approval'"
        [t_breadcrumb]="'Ref No. ' + tranrefno"
        (emit)="transportationcancel()"
      ></app-team-breadcrumb>
      <app-team-breadcrumb
        *ngIf="!transportationView && transportationDetail"
        [s_breadcrumb]="'Approval'"
        [t_breadcrumb]="'Ref No. ' + tranrefno"
        [f_breadcrumb]="'Details'"
        (emit)="cancelToRequest()"
        (emit1)="transportationcancel2()"
        (emit2)="transportationcancel2()"
      ></app-team-breadcrumb>
    </ng-container>

    <ng-container *ngIf="allinoneService.appView == '001' && !isAdmin">
      <app-office-breadcrumb
        *ngIf="!viewingRequest && !transportationView && !transportationDetail"
        [s_breadcrumb]="cashAdvncedView ? 'Cash Approval' : 'Approval'"
      >
      </app-office-breadcrumb>
      <app-office-breadcrumb
        *ngIf="viewingRequest && !transportationView && !transportationDetail"
        [s_breadcrumb]="cashAdvncedView ? 'Cash Approval' : 'Approval'"
        [t_breadcrumb]="'Ref No: ' + selectedRequest.requestrefno"
        (emit)="cancel()"
      >
      </app-office-breadcrumb>
      <app-office-breadcrumb
        *ngIf="transportationView && !transportationDetail"
        [s_breadcrumb]="'Approval'"
        [t_breadcrumb]="'Ref No. ' + tranrefno"
        (emit)="transportationcancel()"
      ></app-office-breadcrumb>
      <app-office-breadcrumb
        *ngIf="!transportationView && transportationDetail"
        [s_breadcrumb]="'Approval'"
        [t_breadcrumb]="'Ref No. ' + tranrefno"
        [f_breadcrumb]="'Details'"
        (emit)="cancelToRequest()"
        (emit1)="transportationcancel2()"
        (emit2)="transportationcancel2()"
      ></app-office-breadcrumb>
    </ng-container>

    <ng-container *ngIf="allinoneService.appView == '001' && isAdmin">
      <app-hcm-breadcrumb
        *ngIf="!viewingRequest && !transportationView && !transportationDetail"
        s_breadcrumb="Approval"
      >
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb
        *ngIf="viewingRequest && !transportationView && !transportationDetail"
        s_breadcrumb="Approval"
        [t_breadcrumb]="'Ref No: ' + selectedRequest.requestrefno"
        (emit)="cancel()"
      >
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb
        *ngIf="transportationView && !transportationDetail"
        [s_breadcrumb]="'Approval'"
        [t_breadcrumb]="'Ref No. ' + tranrefno"
        (emit)="transportationcancel()"
      ></app-hcm-breadcrumb>
      <app-hcm-breadcrumb
        *ngIf="!transportationView && transportationDetail"
        [s_breadcrumb]="'Approval'"
        [t_breadcrumb]="'Ref No. ' + tranrefno"
        [f_breadcrumb]="'Details'"
        (emit)="cancelToRequest()"
        (emit1)="transportationcancel2()"
        (emit2)="transportationcancel2()"
      ></app-hcm-breadcrumb>
    </ng-container>

    <!-- request table view -->
    <ng-container *ngIf="!addingRequest && !transportationView">
      <div *ngIf="!viewingRequest">
        <div class="d-flex align-items-center flex-wrap">
          <div class="me-3 mb-3" *ngIf="isAdmin">
            <button
              type="button"
              class="btn btn-custom"
              (click)="newRequest()"
              title="New Request"
            >
              New Request
            </button>
          </div>
          <div class="{{ importStatus == '000' ? 'mb-3' : 'mb-3 me-3' }}">
            <button
              type="button"
              class="btn btn-custom"
              (click)="export()"
              title="Export To Excel"
              [disabled]="approvalList.length == 0"
            >
              <img class="excel-icon" src="../../../assets/images/excel.png" />

              {{
                this.checkCount == 0
                  ? "Export"
                  : "Export (" + this.checkCount + ")"
              }}
            </button>
          </div>

          <!-- <button (click)="t()">t</button> -->

          <div class="d-flex flex-wrap-align-items-center mb-3">
            <button
              type="button"
              class="btn btn-custom"
              (click)="importSheet()"
              title="Import from Excel"
              *ngIf="importStatus == '001' || importStatus == '005'"
              [disabled]="this.searchForm.requesttype != 'NCMLEIWHRNVIE'"
            >
              <img class="excel-icon" src="../../../assets/images/excel.png" />
              Import
            </button>
            <div
              class="d-flex align-items-center"
              *ngIf="importStatus == '002' || importStatus == '003'"
            >
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-black" disabled>
                  <i
                    class="fas fa-circle-notch fa-spin me-2"
                    *ngIf="importStatus == '002'"
                  ></i>
                  <img
                    src="../../../assets/images/upload.gif"
                    width="20px"
                    class="me-2"
                    *ngIf="importStatus == '003'"
                  />

                  {{ importStatus == "002" ? "Checking" : "Uploading" }}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-black"
                  (click)="getImportStatus()"
                >
                  <i
                    class="fas fa-sync-alt"
                    [ngClass]="{ 'fa-spin': refreshingImportStatus }"
                  ></i>
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-outline-danger"
              (click)="importSheet(true)"
              *ngIf="importStatus == '004'"
              style="width: auto"
            >
              <i class="fas fa-exclamation-circle me-2"></i>
              Import Error
            </button>
          </div>
          <div class="mb-3">
            <div class="line"></div>
          </div>
          <div
            class="align-items-center mb-3 me-2"
            [matMenuTriggerFor]="statusmenu"
          >
            <button
              class="btn btn-secondary dropdown-toggle advanced-filter-btn"
              type="button"
              id="dropdownMenuButton"
              aria-expanded="false"
              mdbDropdownToggle
              style="color: white; min-width: 100px"
            >
              Status&nbsp;:&nbsp;{{ searchForm.requeststatusname }}
              <mat-menu #statusmenu="matMenu" xPosition="before">
                <button
                  mat-menu-item
                  *ngFor="let status of statusList"
                  (click)="statusChange(status)"
                >
                  {{ status.name }}
                </button>
              </mat-menu>
            </button>
          </div>
          <!-- <div class="me-2 btn-group mb-3 d-flex align-items-center" role="group">
                        <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
                            Request Type: {{ searchForm.requesttypename }}
                        </button>
                        <button (click)="clearFilter()" type="button" class="btn btn-secondary advanced-filter-btn">
                            <img src="assets/images/icon.png" alt=""
                                style="width: 11px; height: 11px; margin-top: -3px" />
                        </button>
                    </div> -->
          <div
            class="align-items-center mb-3 me-2"
            [matMenuTriggerFor]="requesttypemenu"
            *ngIf="!cashAdvncedView"
          >
            <button
              class="btn btn-secondary dropdown-toggle advanced-filter-btn"
              type="button"
              id="dropdownMenuButton"
              aria-expanded="false"
              mdbDropdownToggle
              style="color: white; min-width: 100px"
            >
              Request Type&nbsp;:&nbsp;{{ searchForm.requesttypename }}
              <mat-menu #requesttypemenu="matMenu" xPosition="before">
                <button
                  mat-menu-item
                  *ngFor="let status of filterReqTypeList"
                  (click)="requestTypeChange(status)"
                >
                  {{ status.requesttypename }}
                </button>
              </mat-menu>
            </button>
          </div>

          <div class="d-flex align-items-center mb-3 me-2">
            <!-- <label for="startdate" class="form-label">
                            Start Date
                        </label> -->
            <div class="me-1">From</div>
            <div class="input-group">
              <input
                type="text"
                id="startdate"
                name="startdate"
                class="form-control datepicker-readonly"
                placeholder="dd/mm/yyyy"
                bsDatepicker
                [bsConfig]="{
                  containerClass: 'custom-datepicker',
                  dateInputFormat: 'DD/MM/YYYY',
                  showWeekNumbers: false,
                  showClearButton: false,
                  adaptivePosition: true
                }"
                placement="right"
                [bsValue]="searchForm.startdate"
                [(ngModel)]="searchForm.startdate"
                readonly
                (bsValueChange)="onDatepickerShown()"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mb-3 me-2">
            <!-- <label for="enddate" class="form-label">
                            End Date
                        </label> -->
            <div class="me-1">To</div>
            <div class="input-group">
              <input
                type="text"
                id="startdate"
                name="startdate"
                class="form-control datepicker-readonly"
                placeholder="dd/mm/yyyy"
                bsDatepicker
                [bsConfig]="{
                  containerClass: 'custom-datepicker',
                  dateInputFormat: 'DD/MM/YYYY',
                  showWeekNumbers: false,
                  showClearButton: false,
                  adaptivePosition: true
                }"
                placement="left"
                [bsValue]="searchForm.enddate"
                [(ngModel)]="searchForm.enddate"
                readonly
                (bsValueChange)="onDatepickerShown()"
                [minDate]="searchForm.startdate"
              />
            </div>
          </div>
          <!-- <ng-container *ngIf="searchForm.startdate && searchForm.enddate"> -->
          <ng-container>
            <div
              *ngIf="searchForm.startdate && searchForm.enddate"
              class="me-2 btn-group mb-3 d-flex align-items-center"
              role="group"
            >
              <button
                type="button"
                class="btn btn-secondary advanced-filter-btn"
                (click)="filter()"
              >
                Date: {{ searchForm.startdate | date : "dd/MM/yyyy" }} -
                {{ searchForm.enddate | date : "dd/MM/yyyy" }}
              </button>
              <button
                (click)="clearDate()"
                type="button"
                class="btn btn-secondary advanced-filter-btn"
              >
                <img
                  src="assets/images/icon.png"
                  alt=""
                  style="width: 11px; height: 11px; margin-top: -3px"
                />
              </button>
            </div>
            <div
              class="me-2 btn-group mb-3 d-flex align-items-center"
              role="group"
              *ngIf="
                searchForm.requeststatus == '002' &&
                searchForm.approvedstartdate &&
                searchForm.approvedenddate
              "
            >
              <button
                type="button"
                class="btn btn-secondary advanced-filter-btn"
                (click)="filter()"
              >
                Approved Date:
                {{ searchForm.approvedstartdate | date : "dd/MM/yyyy" }} -
                {{ searchForm.approvedenddate | date : "dd/MM/yyyy" }}
              </button>
              <button
                (click)="clearApproveDate()"
                type="button"
                class="btn btn-secondary advanced-filter-btn"
              >
                <img
                  src="assets/images/icon.png"
                  alt=""
                  style="width: 11px; height: 11px; margin-top: -3px"
                />
              </button>
            </div>
            <!-- <div class="me-2 btn-group mb-3 d-flex align-items-center" role="group">
                            <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
                                Request Type: {{ searchForm.requesttypename }}
                            </button>
                            <button (click)="clearFilter()" type="button" class="btn btn-secondary advanced-filter-btn">
                                <img src="assets/images/icon.png" alt=""
                                    style="width: 11px; height: 11px; margin-top: -3px" />
                            </button>
                        </div> -->
            <!-- <div class="me-2 btn-group mb-3 d-flex align-items-center" role="group" *ngIf="
                searchForm.requesttype == 'NCMLEIWHRNVIE' ||
                searchForm.requesttype == this.claimid
              ">
                            <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
                                Request Sub Type: {{ searchForm.requestsubtypename }}
                            </button>
                            <button (click)="clearFilter()" type="button" class="btn btn-secondary advanced-filter-btn">
                                <img src="assets/images/icon.png" alt=""
                                    style="width: 11px; height: 11px; margin-top: -3px" />
                            </button>
                        </div> -->
            <!-- <div class="me-2 btn-group mb-3 d-flex align-items-center" role="group"
                            *ngIf="checkSubType(searchForm.requesttype) != false"> -->
            <div
              class="me-2 btn-group mb-3 d-flex align-items-center"
              role="group"
              *ngIf="showSubType"
            >
              <button
                type="button"
                class="btn btn-secondary advanced-filter-btn"
                (click)="filter()"
              >
                Request Sub Type: {{ searchForm.requestsubtypename }}
              </button>
              <button
                (click)="clearFilter()"
                type="button"
                class="btn btn-secondary advanced-filter-btn"
              >
                <img
                  src="assets/images/icon.png"
                  alt=""
                  style="width: 11px; height: 11px; margin-top: -3px"
                />
              </button>
            </div>
            <div
              *ngIf="showType"
              class="me-2 btn-group mb-3 d-flex align-items-center"
              role="group"
            >
              <button
                type="button"
                class="btn btn-secondary advanced-filter-btn"
                (click)="filter()"
              >
                Type: {{ searchForm.teammember ? "Members" : "All" }}
              </button>
              <button
                (click)="clearFilter()"
                type="button"
                class="btn btn-secondary advanced-filter-btn"
              >
                <img
                  src="assets/images/icon.png"
                  alt=""
                  style="width: 11px; height: 11px; margin-top: -3px"
                />
              </button>
            </div>
            <div
              class="me-2 btn-group mb-3 d-flex align-items-center"
              role="group"
              *ngIf="
                searchForm.requesttype == this.claimid &&
                (searchForm.requeststatus == '002' ||
                  searchForm.requeststatus == '004') &&
                showPayment
              "
            >
              <button
                type="button"
                class="btn btn-secondary advanced-filter-btn"
                (click)="filter()"
              >
                Payment:
                {{
                  searchForm.paymentstatus == "1"
                    ? "Paid"
                    : searchForm.paymentstatus == "2"
                    ? "Unpaid"
                    : "All"
                }}
              </button>
              <button
                (click)="clearFilter()"
                type="button"
                class="btn btn-secondary advanced-filter-btn"
              >
                <img
                  src="assets/images/icon.png"
                  alt=""
                  style="width: 11px; height: 11px; margin-top: -3px"
                />
              </button>
            </div>
          </ng-container>
        </div>
        <div
          class="mb-3 d-flex flex-wrap justify-content-start align-items-center"
        >
          <div
            class="input-group"
            style="width: 300px !important"
            *ngIf="!isMobileView"
          >
            <input
              class="form-control"
              type="text"
              placeholder="&#xf002; Search by Employee ID/Name"
              aria-label="Search by Employee ID/Name"
              (focus)="isFocus = true"
              (blur)="isFocus = false"
              [(ngModel)]="searchText"
              (ngModelChange)="searchTextChange()"
              [ngClass]="{ 'c-search-input-with-clear': searchText }"
              style="font-family: FontAwesome, Ubuntu"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              *ngIf="searchText"
              (click)="clear()"
              [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
            >
              <mat-icon class="c-icon">close</mat-icon>
            </button>
          </div>

          <div class="ms-2">
            <button
              class="btn btn-custom"
              (click)="filter()"
              title="Filter"
              [disabled]="gettingRequestType"
            >
              <i class="fa fa-filter"></i>
            </button>
          </div>

          <div class="ms-2">
            <button class="btn btn-custom" (click)="refresh()" title="Refresh">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>

          <div class="line"></div>
          <app-button-loader
            *ngIf="approveCount > 0"
            class="{{ rejectCount > 0 || paidCount > 0 ? 'me-3' : 'me-0' }}"
            (click)="submitApproved()"
            [button_text]="'Approve (' + this.approveCount + ')'"
            [load_button_text]="'Updating'"
            [isLoading]="approveLoading"
            [disabled]="rejectLoading || paidLoading"
          >
          </app-button-loader>
          <app-button-loader
            *ngIf="rejectCount > 0"
            [outlinedanger]="true"
            class="{{ paidCount > 0 ? 'me-3' : 'me-0' }}"
            (click)="submitRejected()"
            [button_text]="'Reject (' + this.rejectCount + ')'"
            [load_button_text]="'Updating'"
            [isLoading]="rejectLoading"
            [disabled]="approveLoading || paidLoading"
          >
          </app-button-loader>
          <app-button-loader
            *ngIf="paidCount > 0"
            class=""
            (click)="submitUnpaid()"
            [button_text]="'Paid (' + this.paidCount + ')'"
            [load_button_text]="'Updating'"
            [isLoading]="paidLoading"
            [disabled]="approveLoading || rejectLoading"
          >
          </app-button-loader>
          <div
            *ngIf="approveCount > 0 || rejectCount > 0 || paidCount > 0"
            class="line"
          ></div>
          <div class="d-flex align-items-center">
            <span class="total-count-text">Total:&nbsp;</span>
            <span class="total-count-num">{{
              (approvalList | filter : searchText : "approvalList").length
            }}</span>
          </div>
          <!-- <ng-container *ngIf="selectedRequests > 0">
                        <div class="line"></div>
                        <div class="d-flex align-items-center">
                            <span class="total-count-text">Selected Requests:&nbsp;</span>
                            <span class="total-count-num">{{ selectedRequests }}</span>
                        </div>
                        <div class="line"></div>
                        <div>
                            <app-button-loader class="mb-3" (click)="submitMutipleUpload()" [button_text]="'Paid'"
                                [load_button_text]="'Updating'" [isLoading]="multipleLoading">
                            </app-button-loader>
                        </div>
                    </ng-container> -->

          <div class="input-group mt-2" *ngIf="isMobileView">
            <input
              class="form-control"
              type="text"
              placeholder="&#xf002; Search by Employee ID/Name"
              aria-label="Search"
              (focus)="isFocus = true"
              (blur)="isFocus = false"
              [(ngModel)]="searchText"
              [ngClass]="{ 'c-search-input-with-clear': searchText }"
              style="font-family: FontAwesome, Ubuntu"
              (ngModelChange)="searchTextChange()"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              *ngIf="searchText"
              (click)="clear()"
              [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
            >
              <mat-icon class="c-icon">close</mat-icon>
            </button>
          </div>

          <pagination-controls
            class="ms-auto paginate"
            (pageChange)="currentPage = $event"
            *ngIf="approvalList.length > itemPerPage"
            previousLabel="Prev"
            nextLabel="Next"
          >
          </pagination-controls>
          <!-- <pagination-template class="ms-auto" *ngIf="approvalList.length > 0" #p="paginationApi" (pageChange)="pageChange($event)">
                        <div class="d-flex align-items-center justify-content-center">
                            <div [class]="
                      p.isFirstPage()
                        ? 'custom-pagination-disabled'
                        : 'custom-pagination'
                    " (click)="p.previous()">
                                <span class="me-1">&laquo;</span>&nbsp;Prev
                            </div>
                            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                    <span>{{ page.label }}</span>
                                </span>
                                <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                    <span>{{ page.label }}</span>
                                </div>
                            </div>
                            <div [class]="
                      nextDisabled
                        ? 'custom-pagination-disabled'
                        : 'custom-pagination'
                    " (click)="p.next()">
                                Next&nbsp;<span class="ms-1">&raquo;</span>
                            </div>
                        </div>
                    </pagination-template> -->
        </div>
        <!-- <div class="d-flex align-items-center flex-wrap">
                    
                </div> -->

        <div
          class="table-responsive"
          matSort
          (matSortChange)="sortData($event)"
        >
          <table class="table table-responsive table-borderless">
            <thead class="table-header">
              <tr>
                <td scope="col">
                  <div class="form-check">
                    <input
                      class="form-check-input td-edit"
                      type="checkbox"
                      id="primary"
                      [(ngModel)]="currentPageAllSelected"
                      (ngModelChange)="currentPageSelectAll()"
                    />
                  </div>
                </td>

                <td scope="col" mat-sort-header="refno">Ref No.</td>
                <td
                  scope="col"
                  class="col-lg-1 col-md-1"
                  mat-sort-header="employeeid"
                >
                  Employee ID
                </td>
                <td
                  scope="col"
                  class="col-lg-1 col-md-1"
                  mat-sort-header="username"
                >
                  Name
                </td>
                <td
                  scope="col"
                  class="col-lg-2 col-md-2"
                  mat-sort-header="date"
                >
                  Date
                </td>
                <td
                  *ngIf="searchForm.requeststatus == '002'"
                  scope="col"
                  class="col-lg-2 col-md-2"
                  mat-sort-header="date"
                >
                  Approved Date
                </td>
                <td
                  scope="col"
                  class="col-lg-1 col-md-1"
                  mat-sort-header="status"
                >
                  Status
                </td>
                <td
                  scope="col"
                  class="col-lg-1 col-md-1"
                  mat-sort-header="type"
                >
                  Type
                </td>
                <td
                  scope="col"
                  class="col-lg-1 col-md-1"
                  mat-sort-header="subtype"
                >
                  Sub Type
                </td>
                <td scope="col" class="col-lg-1 col-md-1" *ngIf="isAdmin">
                  Requested By
                </td>
                <td scope="col" class="col-lg-2 col-md-2" *ngIf="isAdmin">
                  {{
                    searchForm.requeststatus == "003"
                      ? "Rejected By"
                      : "Approved By"
                  }}
                </td>

                <!-- *ngIf="selectedRequest.requesttype == workfromhomeid" -->

                <td
                  *ngIf="searchForm.requesttype == workfromhomeid"
                  scope="col"
                  class="col-lg-2 col-md-2"
                >
                  Rank
                </td>

                <td
                  *ngIf="searchForm.requesttype == workfromhomeid"
                  scope="col"
                  class="col-lg-2 col-md-2"
                >
                  Designated Location
                </td>

                <td
                  *ngIf="searchForm.requesttype == workfromhomeid"
                  scope="col"
                  class="col-lg-2 col-md-2"
                >
                  Time In Location
                </td>

                <td
                  scope="col"
                  class="col-lg-2 col-md-2"
                  mat-sort-header="remark"
                >
                  Remark
                </td>

                <td
                  scope="col"
                  class="d-flex align-items-center justify-content-center"
                  *ngIf="isAdmin"
                >
                  Action
                </td>
              </tr>
            </thead>

            <tbody *ngIf="approvalListLoading">
              <tr>
                <td
                  [attr.colspan]="
                    isAdmin && searchForm.requesttype != workfromhomeid
                      ? 11
                      : searchForm.requesttype == workfromhomeid && isAdmin
                      ? 15
                      : searchForm.requesttype == workfromhomeid && !isAdmin
                      ? 13
                      : 9
                  "
                  class="nodata"
                >
                  <div class="spinner-border" entitle="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="!approvalListLoading">
              <tr *ngIf="approvalList.length == 0">
                <!-- "isAdmin ? 11 : 9 "  -->
                <td
                  [attr.colspan]="
                    isAdmin && searchForm.requesttype != workfromhomeid
                      ? 11
                      : searchForm.requesttype == workfromhomeid && isAdmin
                      ? 13
                      : searchForm.requesttype == workfromhomeid && !isAdmin
                      ? 11
                      : 9
                  "
                  class="nodata"
                >
                  Empty
                </td>
              </tr>

              <tr
                *ngFor="
                  let approval of approvalList
                    | filter : searchText : 'approvalList'
                    | paginate
                      : { itemsPerPage: itemPerPage, currentPage: currentPage };
                  let index = index
                "
              >
                <td style="vertical-align: top; padding-top: 10px !important">
                  <div class="form-check">
                    <input
                      class="form-check-input td-edit"
                      type="checkbox"
                      id="primary"
                      [(ngModel)]="approval.isChecked"
                      (ngModelChange)="selectRequest(approval)"
                      [disabled]="approval.requesttype == transportationid"
                    />
                  </div>
                </td>

                <td
                  class="td-edit td-data-3"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span [title]="approval.requestrefno">{{
                    approval.requestrefno
                  }}</span>
                </td>

                <td
                  class="td-data td-edit"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span [title]="approval.employeeid">{{
                    approval.employeeid
                  }}</span>
                </td>
                <td
                  class="td-data td-edit"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span [title]="approval.username || approval.userid">{{
                    approval.username || approval.userid
                  }}</span>
                </td>
                <td
                  class="td-data td-edit"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span
                    *ngIf="
                      approval.requesttype != this.reservationid &&
                      approval.requesttype != this.lateid &&
                      approval.requesttype != this.earlyoutid &&
                      approval.requesttype != this.offinlieuid
                    "
                    [title]="
                      approval.claimdate
                        ? allinoneService.formatDBToShow(approval.claimdate)
                        : approval.requesttype == 'VMAOWEFAMSVNAOWI'
                        ? allinoneService.formatDBToShow(approval.startdate)
                        : allinoneService.formatDBToShow(approval.startdate) +
                          ' - ' +
                          allinoneService.formatDBToShow(approval.enddate)
                    "
                  >
                    {{
                      approval.claimdate
                        ? allinoneService.formatDBToShow(approval.claimdate)
                        : approval.requesttype == "VMAOWEFAMSVNAOWI"
                        ? allinoneService.formatDBToShow(approval.startdate)
                        : approval.startdate && approval.enddate
                        ? allinoneService.formatDBToShow(approval.startdate) +
                          " - " +
                          allinoneService.formatDBToShow(approval.enddate)
                        : "-"
                    }}</span
                  >
                  <span
                    *ngIf="
                      approval.requesttype == this.reservationid &&
                      approval.reservationtype == '001'
                    "
                  >
                    {{
                      allinoneService.formatDBToShow(approval.startdate)
                    }}</span
                  >
                  <span
                    *ngIf="
                      approval.requesttype == this.reservationid &&
                      approval.reservationtype == '002'
                    "
                  >
                    {{ allinoneService.formatDBToShow(approval.startdate) }} -
                    {{ allinoneService.formatDBToShow(approval.enddate) }}
                  </span>
                  <span
                    *ngIf="
                      approval.requesttype == this.lateid ||
                      approval.requesttype == this.earlyoutid ||
                      approval.requesttype == this.offinlieuid
                    "
                  >
                    {{ allinoneService.formatDBToShow(approval.startdate) }}
                  </span>
                </td>

                <td
                  *ngIf="searchForm.requeststatus == '002'"
                  class="td-data td-edit"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span
                    [title]="
                      approval.approvedtime
                        ? this.allinoneService.formatDBToShow(
                            approval.approvedtime.slice(0, 8)
                          )
                        : ''
                    "
                    >{{
                      approval.approvedtime
                        ? this.allinoneService.formatDBToShow(
                            approval.approvedtime.slice(0, 8)
                          )
                        : ""
                    }}</span
                  >
                </td>

                <td
                  class="td-edit td-data-2"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span
                    *ngIf="approval.requesttype != 'ANVAHSEFAMVAJDAJ'"
                    [title]="approval.requeststatusname"
                    class="status"
                    [ngClass]="{
                      'status-green': approval.requeststatus == '002',
                      'status-red': approval.requeststatus == '003',
                      'status-gray': approval.requeststatus == '001',
                      'status-main': approval.requeststatus == '004'
                    }"
                    >{{ approval.requeststatusname }}</span
                  >
                  <span *ngIf="approval.requesttype == 'ANVAHSEFAMVAJDAJ'"
                    >-</span
                  >
                  <span
                    class="status ms-2"
                    *ngIf="
                      approval.requeststatus == '002' &&
                      approval.requesttype == this.claimid
                    "
                    [ngClass]="{
                      'status-green': approval.paymentstatus,
                      'status-red': !approval.paymentstatus
                    }"
                    >{{ approval.paymentstatus ? "Paid" : "Unpaid" }}</span
                  >
                </td>

                <td
                  class="td-edit td-data-2"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span
                    [title]="approval.requesttypename"
                    style="color: var(--main-color)"
                  >
                    {{ approval.requesttypename }}</span
                  >
                </td>
                <td
                  class="td-edit td-data-2"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span
                    [title]="approval.requestsubtypename"
                    style="color: var(--main-color)"
                  >
                    {{
                      approval.requestsubtypename
                        ? approval.requestsubtypename
                        : "-"
                    }}</span
                  >
                </td>

                <td
                  class="td-data td-edit"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                  *ngIf="isAdmin"
                >
                  <span [title]="approval.requestedbyname">{{
                    approval.requestedbyname
                  }}</span>
                </td>
                <td
                  class="td-data td-edit approvers"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                  *ngIf="isAdmin"
                >
                  <span
                    [title]="approval.approverusername"
                    *ngIf="approval.requeststatus != '001'"
                    >{{ approval.approverusername }}</span
                  >
                  <ng-container *ngIf="approval.requeststatus == '001'">
                    <span
                      *ngIf="
                        approval.approvetype.includes('1') ||
                        approval.approvetype.includes('2')
                      "
                      style="color: var(--main-color)"
                      matTooltip="RO"
                    >
                      RO{{ approval.rolename.length > 0 ? ", " : "" }}
                    </span>
                    <span
                      *ngFor="let role of approval.rolename; let last = last"
                      style="color: grey"
                      matTooltip="Role - {{ role }}"
                    >
                      {{ role }}<span *ngIf="!last">,&nbsp;</span>
                    </span>
                    {{
                      (approval.rolename.length > 0 ||
                        approval.approvetype.includes("1") ||
                        approval.approvetype.includes("2")) &&
                      approval.approvernamelist?.length > 0
                        ? ", "
                        : ""
                    }}
                    <span
                      *ngFor="
                        let approver of approval.approvernamelist;
                        let last = last
                      "
                    >
                      {{ approver }}<span *ngIf="!last">,&nbsp;</span>
                    </span>
                  </ng-container>
                </td>

                <td
                  *ngIf="searchForm.requesttype == workfromhomeid"
                  class="td-data td-edit"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span [title]="approval.rank">{{ approval.rank }}</span>
                </td>

                <td
                  *ngIf="searchForm.requesttype == workfromhomeid"
                  class="td-data td-edit"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span [title]="approval.location"
                    >{{ approval.location }} ( {{ approval.lat }},
                    {{ approval.long }} )</span
                  >
                </td>

                <td
                  *ngIf="searchForm.requesttype == workfromhomeid"
                  class="td-data td-edit"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span [title]="approval.actual_location"
                    >{{ approval.actual_location }} (
                    {{ approval.actual_lat ? approval.actual_lat : "-" }},
                    {{
                      approval.actual_long ? approval.actual_long : "-"
                    }}
                    )</span
                  >
                </td>

                <td
                  class="td-data td-edit"
                  (click)="
                    clickapprovalList(
                      approval,
                      approval.requesttype == 'ANVAHSEFAMVAJDAJ'
                    )
                  "
                >
                  <span [title]="approval.remark">{{ approval.remark }}</span>
                </td>
                <!-- <td class="td-data td-edit" (click)="clickapprovalList(approval)">
                                    <span [title]="approval.approvalremark">{{
                                        approval.approvalremark
                                        }}</span>
                                </td> -->
                <td
                  class="d-flex justify-contentz`-center align-items-center"
                  *ngIf="isAdmin"
                >
                  <button
                    class="btn btn-outline-danger"
                    (click)="deleteRequest(approval.requestid)"
                    [disabled]="
                      approval.requesttype == transportationid ||
                      approval.requeststatus != '001'
                    "
                  >
                    <i class="fa fa-trash-o"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div *ngIf="viewingRequest">
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn btn-custom mb-3"
            (click)="cancel(transportationDetail)"
            *ngIf="!isApproving && !isRejecting && !isDeleting"
          >
            Cancel
          </button>
          <ng-container *ngIf="selectedRequest.requeststatus == '002'">
            <div
              class="mb-3"
              *ngIf="!isApproving && !isRejecting && !isDeleting"
            >
              <div class="line"></div>
            </div>
            <app-button-loader
              class="mb-3"
              (click)="submitApproval('003')"
              [button_text]="'Reject'"
              [load_button_text]="'Rejecting'"
              [isLoading]="isRejecting"
              [outlinedanger]="true"
            >
            </app-button-loader>
          </ng-container>
          <ng-container *ngIf="selectedRequest.requeststatus == '003'">
            <div
              class="mb-3"
              *ngIf="!isApproving && !isRejecting && !isDeleting"
            >
              <div class="line"></div>
            </div>
            <app-button-loader
              class="mb-3"
              (click)="submitApproval('002')"
              [button_text]="'Approve'"
              [load_button_text]="'Approving'"
              [isLoading]="isApproving"
            >
            </app-button-loader>
          </ng-container>
          <ng-container
            *ngIf="
              selectedRequest.requesttype == this.claimid &&
              selectedRequest.requeststatus == '002' &&
              !selectedRequest.paymentstatus
            "
          >
            <div
              class="mb-3"
              *ngIf="!isApproving && !isRejecting && !isDeleting"
            >
              <div class="line"></div>
            </div>
            <app-button-loader
              class="mb-3"
              (click)="submitApproval('002', true, true)"
              [button_text]="'Paid and Save'"
              [load_button_text]="'Saving'"
              [isLoading]="isApproving"
            >
            </app-button-loader>
          </ng-container>
          <ng-container *ngIf="selectedRequest.requeststatus == '001'">
            <div
              class="mb-3"
              *ngIf="!isApproving && !isRejecting && !isDeleting"
            >
              <div class="line"></div>
            </div>
            <app-button-loader
              class="mb-3 me-3"
              (click)="submitApproval('003')"
              [button_text]="'Reject'"
              [load_button_text]="'Rejecting'"
              [isLoading]="isRejecting"
              [outlinedanger]="true"
              *ngIf="!isApproving && !isDeleting"
            >
            </app-button-loader>
            <app-button-loader
              class="mb-3"
              (click)="submitApproval('002')"
              [button_text]="'Approve'"
              [load_button_text]="'Approving'"
              [isLoading]="isApproving"
              *ngIf="!isRejecting && !isDeleting"
            >
            </app-button-loader>
            <ng-container
              *ngIf="
                selectedRequest.requesttype == this.claimid && !isApproving
              "
            >
              <div
                class="mb-3"
                *ngIf="!isApproving && !isRejecting && !isDeleting"
              >
                <div class="line"></div>
              </div>
              <app-button-loader
                class="mb-3"
                (click)="submitApproval('002', true)"
                [button_text]="'Paid and Approve'"
                [load_button_text]="'Approving'"
                [isLoading]="isApproving"
              >
              </app-button-loader>
            </ng-container>

            <ng-container
              *ngIf="
                isAdmin &&
                !transportationDetail &&
                selectedRequest.requesttype != transportationid &&
                selectedRequest.requeststatus == '001' &&
                !isApproving &&
                !isRejecting
              "
            >
              <div class="mb-3" *ngIf="!isDeleting">
                <div class="line"></div>
              </div>
              <app-button-loader
                class="mb-3"
                (click)="deleteRequest(selectedRequest.requestid)"
                [button_text]="'Delete'"
                [load_button_text]="'Deleting'"
                [isLoading]="isDeleting"
                [outlinedanger]="true"
              >
              </app-button-loader>
            </ng-container>
          </ng-container>
          <div
            class="d-flex align-items-center ms-auto"
            *ngIf="
              selectedRequest.requeststatus != '001' ||
              (selectedRequest.requeststatus == '001' &&
                selectedRequest.requesttype != 'NCMLEIWHRNVIE')
            "
          >
            <span
              [title]="selectedRequest.requeststatusname"
              class="status"
              [ngClass]="{
                'status-green': selectedRequest.requeststatus == '002',
                'status-red': selectedRequest.requeststatus == '003',
                'status-gray': selectedRequest.requeststatus == '001',
                'status-main': selectedRequest.requeststatus == '004'
              }"
            >
              <span
                *ngIf="
                  (selectedRequest.requeststatus == '002' &&
                    !transportationDetail) ||
                  (selectedRequest.requeststatus == '003' &&
                    !transportationDetail)
                "
              >
                {{ selectedRequest.requeststatusname }} by:
                {{ selectedRequest.personname || selectedRequest.rejectperson }}
              </span>
              <span
                *ngIf="
                  (selectedRequest.requeststatus == '002' &&
                    transportationDetail) ||
                  (selectedRequest.requeststatus == '003' &&
                    transportationDetail)
                "
              >
                {{ selectedRequest.requeststatusname }}
              </span>
              <span
                *ngIf="
                  selectedRequest.requeststatus != '002' &&
                  selectedRequest.requeststatus != '003'
                "
              >
                {{ selectedRequest.requeststatusname }}
              </span>
            </span>
            <span
              *ngIf="
                selectedRequest.requeststatus == '002' &&
                selectedRequest.requesttype == this.claimid
              "
              class="ms-2 status"
              [ngClass]="{
                'status-green': selectedRequest.paymentstatus,
                'status-red': !selectedRequest.paymentstatus
              }"
            >
              {{ selectedRequest.paymentstatus ? "Paid" : "Unpaid" }}
            </span>
          </div>
          <div
            class="d-flex align-items-center ms-auto mb-3"
            *ngIf="
              selectedRequest.requeststatus == '001' &&
              selectedRequest.requesttype == 'NCMLEIWHRNVIE'
            "
          >
            <button
              class="btn btn-custom"
              type="button"
              (click)="openLeaveSummaryForm()"
            >
              <i class="fas fa-clipboard-list me-2"></i>Leave Summary
            </button>
          </div>
        </div>
        <div>
          <div class="row">
            <div *ngIf="!transportationDetail" class="mb-3 col-md-6">
              <label for="userid" class="form-label">Ref No: </label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="userid"
                  name="userid"
                  placeholder="Ref No"
                  [value]="selectedRequest.requestrefno"
                  disabled="true"
                />
              </div>
            </div>
            <div *ngIf="!transportationDetail" class="mb-3 col-md-6">
              <label for="name" class="form-label"> Employee </label>
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                placeholder="Employee"
                [value]="selectedRequest.username"
                disabled="true"
              />
            </div>
            <div *ngIf="!transportationDetail" class="mb-3 col-md-6">
              <label for="userid" class="form-label"> User ID </label>
              <input
                type="text"
                class="form-control"
                id="userid"
                name="userid"
                placeholder="User ID"
                [value]="selectedRequest.userid"
                disabled="true"
              />
            </div>
            <div *ngIf="!transportationDetail" class="mb-3 col-md-6">
              <label for="employeeid" class="form-label"> Employee ID </label>
              <input
                type="text"
                class="form-control"
                id="employeeid"
                name="employeeid"
                placeholder="Employee ID"
                [value]="selectedRequest.employeeid || '-'"
                disabled="true"
              />
            </div>
            <div
              *ngIf="!transportationDetail"
              class="mb-3 col-md-6"
              [ngClass]="{
                'col-md-12': selectedRequest.requesttype == cashadvancedid
              }"
            >
              <label for="name" class="form-label"> Type </label>
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                placeholder="Employee"
                [value]="selectedRequest.requesttypename"
                disabled="true"
              />
            </div>
            <!-- Start Leave -->
            <ng-container
              *ngIf="selectedRequest.requesttype == 'NCMLEIWHRNVIE'"
            >
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Leave Type </label>
                <input
                  type="text"
                  class="form-control"
                  id="leavetype"
                  name="leavetype"
                  placeholder="Leave Type"
                  [value]="selectedRequest.requestsubtypename"
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Start Date </label>
                <input
                  type="text"
                  class="form-control"
                  id="startdate"
                  name="startdate"
                  placeholder="Start Date"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.startdate)
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Start Time </label>
                <input
                  type="text"
                  class="form-control"
                  id="starttime"
                  name="starttime"
                  placeholder="Start Time"
                  [value]="selectedRequest.starttime"
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> End Date </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  name="name"
                  placeholder="End Date"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.enddate)
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> End Time </label>
                <input
                  type="text"
                  class="form-control"
                  id="endtime"
                  name="endtime"
                  placeholder="End Time"
                  [value]="selectedRequest.endtime"
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-12">
                <label for="name" class="form-label"> Duration </label>
                <input
                  type="text"
                  class="form-control"
                  id="duration"
                  name="duration"
                  placeholder="Duration"
                  [value]="selectedRequest.duration"
                  disabled="true"
                />
              </div>
            </ng-container>
            <!-- End Leave -->
            <!-- Start Claim -->
            <ng-container *ngIf="selectedRequest.requesttype == this.claimid">
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Claim Type </label>
                <input
                  type="text"
                  class="form-control"
                  id="claimtype"
                  name="claimtype"
                  placeholder="Claim Type"
                  [value]="selectedRequest.requestsubtypename"
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Date </label>
                <input
                  type="text"
                  class="form-control"
                  id="claimdate"
                  name="claimdate"
                  placeholder="Claim Date"
                  [value]="
                    selectedRequest.claimdate
                      ? allinoneService.formatDBToShow(
                          selectedRequest.claimdate
                        )
                      : '-'
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Amount </label>
                <input
                  type="text"
                  class="form-control"
                  id="amount"
                  name="amount"
                  placeholder="Amount Date"
                  [value]="selectedRequest.amount"
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Currency Type </label>
                <input
                  type="text"
                  class="form-control"
                  id="currencytype"
                  name="currencytype"
                  placeholder="Currency Type"
                  [value]="selectedRequest.currencyname"
                  disabled="true"
                />
              </div>
              <ng-container *ngIf="selectedRequest.taxifare">
                <div class="mb-3 col-md-6">
                  <label for="claimfrom" class="form-label">From Place</label>
                  <input
                    class="form-control"
                    type="text"
                    id="claimfrom"
                    name="claimfrom"
                    [value]="selectedRequest.fromplace"
                    disabled
                  />
                </div>
                <div class="mb-3 col-md-6">
                  <label for="claimto" class="form-label">To Place</label>
                  <input
                    class="form-control"
                    type="text"
                    id="claimto"
                    name="claimto"
                    [value]="selectedRequest.toplace"
                    disabled
                  />
                </div>
              </ng-container>
            </ng-container>
            <!-- End Claim -->
            <!-- Start Overtime -->
            <ng-container
              *ngIf="selectedRequest.requesttype == 'UEYVNDLOAPQWXQW'"
            >
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Overtime Type </label>
                <input
                  type="text"
                  class="form-control"
                  id="hour"
                  name="hour"
                  placeholder="Overtime Type"
                  [value]="selectedRequest.requestsubtypename"
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-12">
                <label for="name" class="form-label"> Hour </label>
                <input
                  type="text"
                  class="form-control"
                  id="hour"
                  name="hour"
                  placeholder="Hour"
                  [value]="selectedRequest.duration"
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Start Date </label>
                <input
                  type="text"
                  class="form-control"
                  id="startdate"
                  name="startdate"
                  placeholder="Start Date"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.startdate)
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Start Time </label>
                <input
                  type="text"
                  class="form-control"
                  id="starttime"
                  name="starttime"
                  placeholder="Start Time"
                  [value]="
                    allinoneService.formatTimeAmpm(selectedRequest.starttime)
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> End Date </label>
                <input
                  type="text"
                  class="form-control"
                  id="enddate"
                  name="enddate"
                  placeholder="End Date"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.enddate)
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> End Time </label>
                <input
                  type="text"
                  class="form-control"
                  id="endtime"
                  name="endtime"
                  placeholder="End Time"
                  [value]="
                    allinoneService.formatTimeAmpm(selectedRequest.endtime)
                  "
                  disabled="true"
                />
              </div>
            </ng-container>
            <!-- End Overtime -->
            <!-- Start Travel -->
            <ng-container
              *ngIf="selectedRequest.requesttype == travelrequestid"
            >
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Days </label>
                <input
                  type="text"
                  class="form-control"
                  id="day"
                  name="day"
                  placeholder="Days"
                  [value]="selectedRequest.duration"
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Departure Date </label>
                <input
                  type="text"
                  class="form-control"
                  id="depdate"
                  name="depdate"
                  placeholder="Departure Date"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.startdate)
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Departure Time </label>
                <input
                  type="text"
                  class="form-control"
                  id="deptime"
                  name="deptime"
                  placeholder="Departure Time"
                  [value]="
                    allinoneService.formatTimeAmpm(selectedRequest.starttime)
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Arrival Date </label>
                <input
                  type="text"
                  class="form-control"
                  id="retdate"
                  name="retdate"
                  placeholder="Arrival Date"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.enddate)
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Planned Return </label>
                <input
                  type="text"
                  class="form-control"
                  id="rettime"
                  name="rettime"
                  placeholder="Planned Return"
                  [value]="
                    allinoneService.formatTimeAmpm(selectedRequest.endtime)
                  "
                  disabled="true"
                />
              </div>

              <div class="mb-3 col-md-6">
                <label for="product" class="form-label">Product</label>
                <input
                  class="form-control"
                  type="text"
                  id="product"
                  [value]="selectedRequest.productname"
                  name="product"
                  disabled
                />
              </div>

              <div class="mb-3 col-md-6">
                <label for="project" class="form-label">Project</label>
                <input
                  class="form-control"
                  type="text"
                  id="project"
                  [value]="selectedRequest.projectname"
                  name="project"
                  disabled
                />
              </div>

              <div class="mb-3 col-md-6">
                <label for="amount" class="form-label">Estimated Budget</label>
                <input
                  class="form-control"
                  name="amount"
                  id="amount"
                  type="number"
                  [value]="selectedRequest.amount"
                  [min]="0"
                  (keypress)="($event.charCode != 45)"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="currency" class="form-label">Currency</label>
                <input
                  class="form-control"
                  type="text"
                  id="project"
                  [value]="selectedRequest.currencyname"
                  name="project"
                  disabled
                />
              </div>

              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Mode of Travel </label>
                <input
                  type="text"
                  class="form-control"
                  id="mode"
                  name="mode"
                  placeholder="Mode of Travel"
                  [value]="selectedRequest.modeoftravelname.join(', ')"
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Vehicle Use </label>
                <input
                  type="text"
                  class="form-control"
                  id="vehicle"
                  name="vehicle"
                  placeholder="Vehicle Use"
                  [value]="selectedRequest.vehicleusename.join(', ')"
                  disabled="true"
                />
              </div>
            </ng-container>
            <!-- End Travel -->
            <!-- Start General -->
            <ng-container
              *ngIf="selectedRequest.requesttype == 'VMAOWEFAMSVNAOWI'"
            >
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Date </label>
                <input
                  type="text"
                  class="form-control"
                  id="startdate"
                  name="startdate"
                  placeholder="Start Date"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.startdate)
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Time </label>
                <input
                  type="text"
                  class="form-control"
                  id="starttime"
                  name="starttime"
                  placeholder="Start Time"
                  [value]="
                    allinoneService.formatDBToShowInEdit(
                      selectedRequest.startdate
                    ) +
                      ' ' +
                      allinoneService.formatTimeAmpm(selectedRequest.starttime)
                      | date : 'hh:mm a'
                  "
                  disabled="true"
                />
              </div>
            </ng-container>
            <!-- End General-->
            <!-- Start Transportation -->
            <!-- <ng-container *ngIf="selectedRequest.requesttype == this.transportationid || transportationDetail "> -->
            <ng-container *ngIf="transportationDetail">
              <!-- <div class="mb-3 col-md-6">
                                <label for="trantype" class="form-label"> Transportation Type </label>
                                <input type="text" class="form-control" id="trantype" name="trantype"
                                    placeholder="Transportation Type" [value]="selectedRequest.requestsubtypename"
                                    disabled="true" />
                            </div> -->
              <div class="mb-3 col-md-12">
                <label for="trantype" class="form-label">
                  Transportation Type
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="trantype"
                  name="trantype"
                  placeholder="Transportation Type"
                  [value]="transubtypename"
                  disabled="true"
                />
              </div>
              <!-- <div class="mb-3 col-md-6">
                                <label for="name" class="form-label"> Start Date </label>
                                <input type="text" class="form-control" id="startdate" name="startdate"
                                    placeholder="Start Date" [value]="
                    allinoneService.formatDBToShow(selectedRequest.startdate)
                  " disabled="true" />
                            </div>
                            <div class="mb-3 col-md-6">
                                <label for="name" class="form-label"> End Date </label>
                                <input type="text" class="form-control" id="name" name="name" placeholder="End Date"
                                    [value]="
                    allinoneService.formatDBToShow(selectedRequest.enddate)
                  " disabled="true" />
                            </div> -->
              <div class="mb-3 col-md-12">
                <label for="name" class="form-label"> Date </label>
                <input
                  type="text"
                  class="form-control"
                  id="startdate"
                  name="startdate"
                  placeholder="Date"
                  [value]="allinoneService.formatDBToShow(selectedRequest.date)"
                  disabled="true"
                />
              </div>
              <div
                class="mb-3 col-md-12"
                *ngIf="selectedRequest.trantype == '001'"
              >
                <label class="form-label" for="pickuppoint"
                  >Pick Up Points
                </label>
                <input
                  disabled
                  class="form-control"
                  name="pickuppoint"
                  id="pickuppoint"
                  [value]="
                    selectedRequest.pickuppointname
                      ? selectedRequest.pickuppointname
                      : '_'
                  "
                />
                <!-- <select class="form-select" id="pickuppoint" name="pickuppoint"
                                    [(ngModel)]="selectedRequest.pickuppointid" disabled>
                                    
                                    <option *ngFor="let type of pickupList" [value]="type.pickuppointid">
                                        {{ type.name }}
                                    </option>
                                </select>
                                <mat-progress-bar mode="indeterminate" *ngIf="gettingPickUp"></mat-progress-bar> -->
              </div>
              <!-- <div class="row">
                                <div class="d-flex flex-wrap align-items-center">
                                    <ng-container *ngFor="let day of weekDay;let i = index;">
                                        <div class="form-check mb-3 me-5">
                                            <input class="form-check-input" type="checkbox" [attr.name]="day.name"
                                                [attr.id]="day.name"
                                                [checked]="selectedRequest.weekday?.includes(day.value)"
                                                disabled="true">
                                            <label class="form-check-label" [attr.for]="day.name"> {{day.name}}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div> -->
              <ng-container *ngIf="selectedRequest.trantype == '002'">
                <div class="mb-3 col-md-12">
                  <label for="toplace" class="form-label">To Place</label>
                  <input
                    class="form-control"
                    type="text"
                    id="toplace"
                    name="toplace"
                    [(ngModel)]="selectedRequest.toplace"
                    disabled="true"
                  />
                </div>
                <div
                  class="d-flex align-items-center align-items-center-custom justify-content-start w-100"
                >
                  <div class="form-check mb-3 me-5">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="going"
                      name="going"
                      [(ngModel)]="selectedRequest.going"
                      disabled="true"
                    />
                    <label class="form-check-label ms-1" for="going">
                      Going
                    </label>
                  </div>
                  <div class="form-check mb-3 me-5">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="return"
                      name="return"
                      [(ngModel)]="selectedRequest.return"
                      disabled="true"
                    />
                    <label class="form-check-label ms-1" for="return">
                      Return
                    </label>
                  </div>
                </div>
                <ng-container *ngIf="selectedRequest.going">
                  <div
                    class="col-md-6 mb-3"
                    [ngClass]="{
                      'col-md-12':
                        !selectedRequest.going || !selectedRequest.return
                    }"
                  >
                    <label for="arrivetime" class="form-label"
                      >Arrive Time</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="arrivetime"
                      name="arrivetime"
                      [(ngModel)]="selectedRequest.arrivetime"
                      disabled="true"
                    />
                  </div>
                </ng-container>
                <ng-container *ngIf="selectedRequest.return">
                  <div
                    class="col-md-6 mb-3"
                    [ngClass]="{
                      'col-md-12':
                        !selectedRequest.going || !selectedRequest.return
                    }"
                  >
                    <label for="returntime" class="form-label"
                      >Return Time</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="returntime"
                      name="returntime"
                      [(ngModel)]="selectedRequest.returntime"
                      disabled="true"
                    />
                  </div>
                </ng-container>
                <div class="mb-3 col-md-6">
                  <label for="dailyfrom" class="form-label"
                    >Pick Up Place</label
                  >
                  <input
                    class="form-control"
                    type="text"
                    id="dailyfrom"
                    name="dailyfrom"
                    [(ngModel)]="selectedRequest.userfrom"
                    disabled
                  />
                </div>
                <div class="mb-3 col-md-6">
                  <label for="dailyto" class="form-label">Drop Off Place</label>
                  <input
                    class="form-control"
                    type="text"
                    id="dailyto"
                    name="dailyto"
                    [(ngModel)]="selectedRequest.userto"
                    disabled
                  />
                </div>
              </ng-container>
            </ng-container>
            <!-- End Transportation -->
            <!-- Start Reservation -->
            <ng-container
              *ngIf="
                selectedRequest.requesttype == this.reservationid &&
                selectedRequest.reservationtype == '001'
              "
            >
              <div class="mb-3 col-md-6">
                <label for="startdate" class="form-label">Date</label>
                <input
                  class="form-control"
                  type="text"
                  id="startdate"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.startdate)
                  "
                  name="startdate"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="starttime" class="form-label">Start Time</label>
                <input
                  class="form-control"
                  type="text"
                  id="starttime"
                  [value]="
                    allinoneService.formatTimeAmpm(selectedRequest.starttime)
                  "
                  name="starttime"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="endtime" class="form-label">End Time</label>
                <input
                  class="form-control"
                  type="text"
                  id="endtime"
                  [value]="
                    allinoneService.formatTimeAmpm(selectedRequest.endtime)
                  "
                  name="endtime"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="room" class="form-label">Room</label>
                <input
                  class="form-control"
                  type="text"
                  id="room"
                  [value]="selectedRequest.roomname"
                  name="room"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="maxpeople" class="form-label"
                  >Max No. of People</label
                >
                <input
                  class="form-control"
                  type="text"
                  id="maxpeople"
                  [value]="selectedRequest.maxpeople"
                  name="maxpeople"
                  disabled
                />
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                selectedRequest.requesttype == this.reservationid &&
                selectedRequest.reservationtype == '002'
              "
            >
              <div class="mb-3 col-md-3">
                <label for="startdate" class="form-label">Start Date</label>
                <input
                  class="form-control"
                  type="text"
                  id="startdate"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.startdate)
                  "
                  name="startdate"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="starttime" class="form-label">Start Time</label>
                <input
                  class="form-control"
                  type="text"
                  id="starttime"
                  [value]="
                    allinoneService.formatTimeAmpm(selectedRequest.starttime)
                  "
                  name="starttime"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="enddate" class="form-label">End Date</label>
                <input
                  class="form-control"
                  type="text"
                  id="enddate"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.enddate)
                  "
                  name="enddate"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="endtime" class="form-label">End Time</label>
                <input
                  class="form-control"
                  type="text"
                  id="endtime"
                  [value]="
                    allinoneService.formatTimeAmpm(selectedRequest.endtime)
                  "
                  name="endtime"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="floor" class="form-label">Floor</label>
                <input
                  class="form-control"
                  type="text"
                  id="floor"
                  [value]="selectedRequest.floorname"
                  name="floor"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="desk" class="form-label">Desk</label>
                <input
                  class="form-control"
                  type="text"
                  id="desk"
                  [value]="selectedRequest.deskid"
                  name="desk"
                  disabled
                />
              </div>
            </ng-container>
            <!-- End Reservation -->
            <!-- Start Late and Early Out -->
            <ng-container
              *ngIf="
                selectedRequest.requesttype == lateid ||
                selectedRequest.requesttype == earlyoutid
              "
            >
              <div class="mb-3 col-md-6">
                <label for="date" class="form-label">Date</label>
                <input
                  class="form-control"
                  type="text"
                  id="date"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.startdate)
                  "
                  name="date"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-4">
                <label for="starttime" class="form-label">Start Time</label>
                <input
                  class="form-control"
                  type="text"
                  id="endtime"
                  [value]="
                    allinoneService.formatTimeAmpm(selectedRequest.starttime)
                  "
                  name="starttime"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-4">
                <label for="endtime" class="form-label">End Time</label>
                <input
                  class="form-control"
                  type="text"
                  id="endtime"
                  [value]="
                    allinoneService.formatTimeAmpm(selectedRequest.endtime)
                  "
                  name="endtime"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-4">
                <label for="duration" class="form-label">Duration</label>
                <input
                  class="form-control"
                  type="text"
                  id="duration"
                  [value]="selectedRequest.duration"
                  name="duration"
                  disabled
                />
              </div>
            </ng-container>
            <!-- End Late and Early Out -->
            <!-- Start Work From Home -->
            <ng-container *ngIf="selectedRequest.requesttype == workfromhomeid">
              <div class="mb-3 col-md-6">
                <label for="location" class="form-label">Rank</label>
                <input
                  class="form-control"
                  type="text"
                  id="rank"
                  [value]="selectedRequest.rank"
                  name="location"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="date" class="form-label">Start Date</label>
                <input
                  class="form-control"
                  type="text"
                  id="date"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.startdate)
                  "
                  name="date"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="enddate" class="form-label">End Date</label>
                <input
                  class="form-control"
                  type="text"
                  id="enddate"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.enddate)
                  "
                  name="enddate"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="location" class="form-label"
                  >Designated Location</label
                >
                <input
                  class="form-control"
                  type="text"
                  id="location"
                  [value]="selectedRequest.location"
                  name="location"
                  disabled
                />
                <a
                  class="link-primary"
                  style="cursor: pointer"
                  (click)="goToMap(selectedRequest.lat, selectedRequest.long)"
                  >{{ selectedRequest.lat }}, {{ selectedRequest.long }}</a
                >
              </div>

              <div class="mb-3 col-md-6">
                <label for="actual_location" class="form-label"
                  >Time In Location</label
                >
                <input
                  class="form-control"
                  type="text"
                  id="actual_location"
                  [value]="selectedRequest.actual_location"
                  name="actual_location"
                  disabled
                />
                <a
                  class="link-primary"
                  style="cursor: pointer"
                  (click)="
                    goToMap(
                      selectedRequest.actual_lat,
                      selectedRequest.actual_long
                    )
                  "
                  >{{
                    selectedRequest.actual_lat
                      ? selectedRequest.actual_lat
                      : "-"
                  }},
                  {{
                    selectedRequest.actual_long
                      ? selectedRequest.actual_long
                      : "-"
                  }}</a
                >
              </div>
            </ng-container>
            <!-- End Work From Home -->
            <!-- Start Off in lieu -->
            <ng-container *ngIf="selectedRequest.requesttype == offinlieuid">
              <div class="mb-3 col-md-6">
                <label for="date" class="form-label">Date</label>
                <input
                  class="form-control"
                  type="text"
                  id="date"
                  [value]="allinoneService.formatDBToShow(selectedRequest.date)"
                  name="date"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-12">
                <label for="date" class="form-label">OT Date</label>
                <input
                  class="form-control"
                  type="text"
                  id="date"
                  [value]="
                    allinoneService.formatDBToShow(selectedRequest.startdate)
                  "
                  name="date"
                  disabled
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="date" class="form-label">Start Time</label>
                <input
                  class="form-control"
                  type="time"
                  id="date"
                  [value]="selectedRequest.starttime"
                  name="date"
                  disabled
                />
              </div>
              <!-- <div class="mb-3 col-md-6">
                                <label for="enddate" class="form-label">End Date</label>
                                <input class="form-control" type="text" id="enddate"
                                    [value]="allinoneService.formatDBToShow(selectedRequest.enddate)" name="enddate"
                                    disabled />
                            </div> -->
              <div class="mb-3 col-md-6">
                <label for="date" class="form-label">End Time</label>
                <input
                  class="form-control"
                  type="time"
                  id="date"
                  [value]="selectedRequest.endtime"
                  name="date"
                  disabled
                />
              </div>
            </ng-container>
            <!-- End Off in Lieu -->

            <!-- Start Cash Advanced -->
            <ng-container *ngIf="selectedRequest.requesttype == cashadvancedid">
              <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Date </label>
                <input
                  type="text"
                  class="form-control"
                  id="claimdate"
                  name="claimdate"
                  placeholder="Claim Date"
                  [value]="
                    selectedRequest.claimdate
                      ? allinoneService.formatDBToShow(
                          selectedRequest.claimdate
                        )
                      : '-'
                  "
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Amount </label>
                <input
                  type="text"
                  class="form-control"
                  id="amount"
                  name="amount"
                  placeholder="Amount Date"
                  [value]="selectedRequest.amount"
                  disabled="true"
                />
              </div>
              <div class="mb-3 col-md-3">
                <label for="name" class="form-label"> Currency Type </label>
                <input
                  type="text"
                  class="form-control"
                  id="currencytype"
                  name="currencytype"
                  placeholder="Currency Type"
                  [value]="selectedRequest.currencyname"
                  disabled="true"
                />
              </div>
            </ng-container>
            <!-- End Cash Advanced -->

            <div class="mb-3 col-md-12">
              <!-- <label for="name" class="form-label"> {{ selectedRequest.requesttype == workfromhomeid ?
                                'Work From Home Reason' ? selectedRequest.requesttype == offinlieuid : 'Off In Lieu
                                Reason' : selectedRequest.requesttype == travelrequestid ? 'Travel Purpose' : 'Remark' }}
                            </label> -->

              <label for="name" class="form-label">
                {{
                  selectedRequest.requesttype == workfromhomeid
                    ? "Work From Home Reason"
                    : selectedRequest.requesttype == offinlieuid
                    ? "Off In Lieu
                                Reason"
                    : selectedRequest.requesttype == travelrequestid
                    ? "Travel Purpose"
                    : "Remark"
                }}
              </label>

              <textarea
                class="form-control"
                id="remark"
                [value]="selectedRequest.remark"
                name="remark"
                rows="3"
                placeholder="Remark"
                disabled="true"
              ></textarea>
            </div>
            <!-- <div class="col-md-12"
                            *ngIf="selectedRequest.userfromto.length > 0 && selectedRequest.trantype == '002' ">
                            <h3> Selected Members ({{ selectedRequest.userfromto.length }})</h3>
                            <div class="list-group">
                                <div class="profile-container col-md-4" *ngFor="let user of selectedRequest.userlist">
                                    <span>
                                        <img class="profile-image me-3"
                                            [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="
                                      user.photo || user.image || '../../../assets/images/profile.png'
                                    " />
                                    </span>

                                    <label
                                        class="d-flex align-items-center align-items-center-custom justify-content-center w-100">
                                        <div class="profile-item-container">
                                            <div class="profile-item">
                                                <div class="username" style="cursor: pointer">
                                                    <b>{{ user.username || user.userid }}</b>
                                                </div>
                                                <div style="color: grey; margin-top: 1px; font-size: 13.5px"
                                                    *ngIf="user.username != ''">
                                                    {{ user.userid }}
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div> -->
            <!-- *** -->
            <ng-container *ngIf="!transportationDetail">
              <ng-container>
                <div
                  class="col-md-12 row"
                  *ngIf="selectedRequest.image.imgurl.length > 0"
                >
                  <label for="name" class="form-label"> Attached </label>
                  <div
                    class="img-container col-lg-3 col-md-4 col-sm-4 col-6"
                    *ngFor="
                      let item of selectedRequest.image.imgurl;
                      let index = index
                    "
                  >
                    <div
                      class="img-border card"
                      data-bs-toggle="modal"
                      data-bs-target="#comment-image-viewer"
                      style="cursor: pointer"
                      (click)="viewImage(item)"
                    >
                      <img [src]="item" />
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  selectedRequest.accompanyperson.length > 0 &&
                  selectedRequest.requesttype == travelrequestid
                "
              >
                <div class="col-md-6">
                  <h3>
                    Selected Accompany Person ({{
                      selectedRequest.accompanyperson.length
                    }})
                  </h3>
                  <div class="list-group">
                    <div
                      class="profile-container d-flex"
                      *ngFor="
                        let user of selectedRequest.accompanyperson;
                        let index = index
                      "
                    >
                      <span>
                        <img
                          class="profile-image me-3"
                          [defaultImage]="'../../../assets/images/profile.png'"
                          [lazyLoad]="
                            user.photo ||
                            user.image ||
                            '../../../assets/images/profile.png'
                          "
                        />
                      </span>

                      <label
                        class="d-flex align-items-center align-items-center-custom justify-content-center w-100"
                      >
                        <div class="profile-item-container">
                          <div class="profile-item">
                            <div class="username" style="cursor: pointer">
                              <b>{{ user }}</b>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  selectedRequest.approver.length > 0 &&
                  selectedRequest.requesttype != this.transportationid
                "
              >
                <div class="col-md-6">
                  <h3>
                    Selected Approvers ({{ selectedRequest.approver.length }})
                  </h3>
                  <div class="list-group">
                    <div
                      class="profile-container d-flex"
                      *ngFor="
                        let user of selectedRequest.approver;
                        let index = index
                      "
                    >
                      <span>
                        <img
                          class="profile-image me-3"
                          [defaultImage]="'../../../assets/images/profile.png'"
                          [lazyLoad]="
                            user.photo ||
                            user.image ||
                            '../../../assets/images/profile.png'
                          "
                        />
                      </span>

                      <label
                        class="d-flex align-items-center align-items-center-custom justify-content-center w-100"
                      >
                        <div class="profile-item-container">
                          <div class="profile-item">
                            <div class="username" style="cursor: pointer">
                              <b>{{ user }}</b>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  selectedRequest.userfromto.length > 0 &&
                  selectedRequest.requesttype == this.transportationid &&
                  selectedRequest.trantype == '002'
                "
              >
                <div class="col-md-12">
                  <h3>
                    Selected Members ({{ selectedRequest.userfromto.length }})
                  </h3>
                  <div class="row">
                    <ng-container
                      *ngFor="let user of selectedRequest.userfromto"
                    >
                      <div class="col-md-4">
                        <div class="card p-3 my-card">
                          <div class="d-flex align-items-center">
                            <span>
                              <img
                                class="profile-image me-3"
                                [defaultImage]="
                                  '../../../assets/images/profile.png'
                                "
                                [lazyLoad]="
                                  user.photo ||
                                  user.image ||
                                  '../../../assets/images/profile.png'
                                "
                              />
                            </span>

                            <label
                              class="d-flex align-items-center justify-content-between w-100"
                            >
                              <div class="profile-item-container">
                                <div class="profile-item">
                                  <div class="username" style="cursor: pointer">
                                    <b>{{ user.username || user.userid }}</b>
                                  </div>
                                  <div
                                    style="
                                      color: grey;
                                      margin-top: 1px;
                                      font-size: 13.5px;
                                    "
                                    *ngIf="user.username != ''"
                                  >
                                    {{ user.userid }}
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6">
                              <input
                                class="form-control"
                                type="text"
                                [id]="'from-' + user.userid"
                                [name]="'from-' + user.userid"
                                [(ngModel)]="user.fromplace"
                                placeholder="Pick Up Place"
                                disabled
                              />
                            </div>
                            <div class="col-md-6">
                              <input
                                class="form-control"
                                type="text"
                                [id]="'to-' + user.userid"
                                [name]="'to-' + user.userid"
                                [(ngModel)]="user.toplace"
                                placeholder="Drop Off Place"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- request add form -->
    <ng-container *ngIf="addingRequest && !transportationView">
      <app-office-request
        [orgid]="approvalForm.orgid"
        [memberList]="memberList"
        (cancel)="cancelReqeust()"
      ></app-office-request>
    </ng-container>

    <!-- transportation view -->
    <ng-container *ngIf="transportationView">
      <div
        class="mb-3 d-flex flex-wrap justify-content-start align-items-center"
      >
        <div
          class="input-group"
          style="width: 300px !important"
          *ngIf="!isMobileView"
        >
          <input
            class="form-control"
            type="text"
            placeholder="&#xf002; Search by User ID/User Name/Date"
            aria-label="Search by User ID/User Name/Date"
            (focus)="isFocus = true"
            (blur)="isFocus = false"
            [(ngModel)]="transearchText"
            (ngModelChange)="searchTextChange()"
            [ngClass]="{ 'c-search-input-with-clear': transearchText }"
            style="font-family: FontAwesome, Ubuntu"
          />
          <button
            class="btn btn-outline-secondary"
            type="button"
            *ngIf="transearchText"
            (click)="tranclear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
          >
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <div class="ms-2">
          <button
            class="btn btn-custom"
            (click)="tranrefresh()"
            title="Refresh"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div class="align-items-center ms-2" [matMenuTriggerFor]="statusmenu">
          <button
            class="btn btn-secondary dropdown-toggle advanced-filter-btn"
            type="button"
            id="dropdownMenuButton"
            aria-expanded="false"
            mdbDropdownToggle
            style="color: white; min-width: 100px"
          >
            Status&nbsp;:&nbsp;{{ searchForm.trequeststatusname }}
            <mat-menu #statusmenu="matMenu" xPosition="before">
              <button
                mat-menu-item
                *ngFor="let status of statusList"
                (click)="transtatusChange(status)"
              >
                {{ status.name }}
              </button>
            </mat-menu>
          </button>
        </div>

        <div class="line"></div>
        <app-button-loader
          *ngIf="approveCount > 0"
          class="{{ rejectCount > 0 || paidCount > 0 ? 'me-3' : 'me-0' }}"
          (click)="submitApproved()"
          [button_text]="'Approve (' + this.approveCount + ')'"
          [load_button_text]="'Updating'"
          [isLoading]="approveLoading"
          [disabled]="rejectLoading || paidLoading"
        >
        </app-button-loader>
        <app-button-loader
          *ngIf="rejectCount > 0"
          [outlinedanger]="true"
          class="{{ paidCount > 0 ? 'me-3' : 'me-0' }}"
          (click)="submitRejected()"
          [button_text]="'Reject (' + this.rejectCount + ')'"
          [load_button_text]="'Updating'"
          [isLoading]="rejectLoading"
          [disabled]="approveLoading || paidLoading"
        >
        </app-button-loader>
        <app-button-loader
          *ngIf="paidCount > 0"
          class=""
          (click)="submitUnpaid()"
          [button_text]="'Paid (' + this.paidCount + ')'"
          [load_button_text]="'Updating'"
          [isLoading]="paidLoading"
          [disabled]="approveLoading || rejectLoading"
        >
        </app-button-loader>
        <div
          *ngIf="approveCount > 0 || rejectCount > 0 || paidCount > 0"
          class="line"
        ></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{
            (transportaionList | filter : transearchText : "tranView").length
          }}</span>
        </div>
        <!-- <ng-container *ngIf="selectedRequests > 0">
                    <div class="line"></div>
                    <div class="d-flex align-items-center">
                        <span class="total-count-text">Selected Requests:&nbsp;</span>
                        <span class="total-count-num">{{ selectedRequests }}</span>
                    </div>
                    <div class="line"></div>
                    <div>
                        <app-button-loader class="mb-3" (click)="submitMutipleUpload()" [button_text]="'Paid'"
                            [load_button_text]="'Updating'" [isLoading]="multipleLoading">
                        </app-button-loader>
                    </div>
                </ng-container> -->

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input
            class="form-control"
            type="text"
            placeholder="&#xf002; Search by User ID/User Name/Date"
            aria-label="Search"
            (focus)="isFocus = true"
            (blur)="isFocus = false"
            [(ngModel)]="transearchText"
            [ngClass]="{ 'c-search-input-with-clear': transearchText }"
            style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()"
          />
          <button
            class="btn btn-outline-secondary"
            type="button"
            *ngIf="transearchText"
            (click)="tranclear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
          >
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <pagination-controls
          class="ms-auto paginate"
          (pageChange)="tranCurrentPage = $event"
          *ngIf="transportaionList.length > tranItemPerPage"
          previousLabel="Prev"
          nextLabel="Next"
        >
        </pagination-controls>
      </div>

      <div
        class="table-responsive"
        matSort
        (matSortChange)="tranSortData($event)"
      >
        <table
          *ngIf="tabletrantype == '001'"
          class="table table-responsive table-borderless"
        >
          <thead class="table-header">
            <tr>
              <!-- <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="refno">
                          Ref No.
                        </td> -->
              <td scope="col" class="">
                <div class="form-check">
                  <input
                    class="form-check-input td-edit"
                    type="checkbox"
                    id="primary"
                    [(ngModel)]="currentPageAllSelected"
                    (ngModelChange)="transportationPageSelectAll()"
                  />
                </div>
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="userid">
                User ID
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="username">
                User Name
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="date">Date</td>
              <td scope="col" class="col-md-1" mat-sort-header="type">Type</td>
              <td scope="col" class="col-md-2" mat-sort-header="status">
                Status
              </td>
              <td scope="col" class="col-md-2" mat-sort-header="pickuppointid">
                Pick Up Point
              </td>
              <td scope="col" class="col-md-4" mat-sort-header="remark">
                Remark
              </td>
            </tr>
          </thead>

          <tbody *ngIf="gettingtran">
            <tr>
              <td colspan="8" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>

          <!-- *** -->
          <tbody *ngIf="!gettingtran">
            <tr *ngIf="transportaionList.length == 0">
              <td colspan="8" class="nodata">Empty</td>
            </tr>

            <tr
              *ngFor="
                let request of transportaionList
                  | filter : transearchText : 'tranView'
                  | paginate
                    : {
                        itemsPerPage: tranItemPerPage,
                        currentPage: tranCurrentPage
                      };
                let index = index
              "
            >
              <td style="vertical-align: top; padding-top: 10px !important">
                <div class="form-check">
                  <input
                    class="form-check-input td-edit"
                    type="checkbox"
                    id="primary"
                    [(ngModel)]="request.isChecked"
                    (ngModelChange)="selectRequest(request)"
                  />
                </div>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="request.userid">{{ request.userid }}</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="request.username">{{ request.username }}</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="allinoneService.formatDBToShow(request.date)">{{
                  allinoneService.formatDBToShow(request.date)
                }}</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span style="color: var(--main-color)">Transportation</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span
                  [title]="request.requeststatusname"
                  class="status"
                  [ngClass]="{
                    'status-green': request.requeststatus == '002',
                    'status-red': request.requeststatus == '003',
                    'status-gray': request.requeststatus == '001',
                    'status-main': request.requeststatus == '004'
                  }"
                  >{{ request.requeststatusname }}</span
                >
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="request.pickuppointname">{{
                  request.pickuppointname
                }}</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="request.remark">{{ request.remark }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          *ngIf="tabletrantype == '002'"
          class="table table-responsive table-borderless"
        >
          <thead class="table-header">
            <tr>
              <!-- <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="refno">
                          Ref No.
                        </td> -->
              <td scope="col" class="">
                <div class="form-check">
                  <input
                    class="form-check-input td-edit"
                    type="checkbox"
                    id="primary"
                    [(ngModel)]="currentPageAllSelected"
                    (ngModelChange)="transportationPageSelectAll()"
                  />
                </div>
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="userid">
                User ID
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="username">
                User Name
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="date">Date</td>
              <td scope="col" class="col-md-1" mat-sort-header="type">Type</td>
              <td scope="col" class="col-md-1" mat-sort-header="status">
                Status
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="toplace">
                To Place
              </td>
              <td scope="col" class="col-md-2" mat-sort-header="userfrom">
                Pick Up Place
              </td>
              <td scope="col" class="col-md-2" mat-sort-header="userto">
                Drop off Place
              </td>
              <td scope="col" class="col-md-2" mat-sort-header="remark">
                Remark
              </td>
            </tr>
          </thead>

          <tbody *ngIf="gettingtran">
            <tr>
              <td colspan="10" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>

          <!-- *** -->
          <tbody *ngIf="!gettingtran">
            <tr *ngIf="transportaionList.length == 0">
              <td colspan="10" class="nodata">Empty</td>
            </tr>

            <tr
              *ngFor="
                let request of transportaionList
                  | filter : transearchText : 'tranView'
                  | paginate
                    : {
                        itemsPerPage: tranItemPerPage,
                        currentPage: tranCurrentPage
                      };
                let index = index
              "
            >
              <!-- <td class="td-data td-edit" (click)="clickapprovalList(request, request.requesttype == 'ANVAHSEFAMVAJDAJ' )">
                          <span [title]="request.requestrefno">{{
                            request.requestrefno
                            }}</span>
                        </td> -->
              <td style="vertical-align: top; padding-top: 10px !important">
                <div class="form-check">
                  <input
                    class="form-check-input td-edit"
                    type="checkbox"
                    id="primary"
                    [(ngModel)]="request.isChecked"
                    (ngModelChange)="selectRequest(request)"
                  />
                </div>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="request.userid">{{ request.userid }}</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="request.username">{{ request.username }}</span>
              </td>

              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="allinoneService.formatDBToShow(request.date)">{{
                  allinoneService.formatDBToShow(request.date)
                }}</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span style="color: var(--main-color)">Transportation</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span
                  [title]="request.requeststatusname"
                  class="status"
                  [ngClass]="{
                    'status-green': request.requeststatus == '002',
                    'status-red': request.requeststatus == '003',
                    'status-gray': request.requeststatus == '001',
                    'status-main': request.requeststatus == '004'
                  }"
                  >{{ request.requeststatusname }}</span
                >
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="request.toplace">{{ request.toplace }}</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="request.userfrom">{{ request.userfrom }}</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="request.userto">{{ request.userto }}</span>
              </td>
              <td
                class="td-data td-edit"
                (click)="clickapprovalList(request, false, true)"
              >
                <span [title]="request.remark">{{ request.remark }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>

<div
  class="modal"
  id="comment-image-viewer"
  aria-hidden="true"
  aria-labelledby="imageviewerLabel"
  tabindex="-1"
>
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-more-container">
          <button
            type="button"
            class="btn-icon"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="downloadImage(currentImageview)"
          >
            <mat-icon>download</mat-icon>
          </button>
          <button
            type="button"
            class="btn-icon"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div>
          <img [src]="currentImageview" alt="" class="image-view" />
        </div>
      </div>
    </div>
  </div>
</div>
