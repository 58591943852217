import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AllInOneService } from '../services/all-in-one.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private allinoneService: AllInOneService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const atoken = this.allinoneService.getAtoken();

    if (!atoken) {
      if (route.url.toString().includes('service')) {
        const customerid = route.queryParams.customerid || '';
        const ticketno = route.queryParams.ticketno || '';
        const selfservice = route.queryParams.v || '';
        this.allinoneService.replaceLocation(
          route.url.toString(),
          selfservice,
          customerid,
          ticketno
        );
      } else {
        var nextroute = route.url.join(',');
        this.allinoneService.logout(nextroute);
      }
      return false;
    } else {
      const currentRoute = state.url;
      const mainRoutes = [
        '/posts',
        '/checkin',
        '/message',
        '/office',
        '/adminconsole',
        '/sales',
        '/services',
      ];
      if (mainRoutes.includes(currentRoute)) {
        this.allinoneService.behaviourMenu.subscribe((value) => {
          if (value.sidebar) {
            const foundObject = value.sidebar.find(
              (item: any) => item.path === currentRoute
            );
            if (!foundObject) {
              if (value.sidebar.length > 0) {
                this.router.navigateByUrl(value.sidebar[0].path);
              } else {
                this.router.navigateByUrl('/page-not-found');
              }
            }
          }
        });
      }
      return true;
    }
  }
}
