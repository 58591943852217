import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { IMyDpOptions } from 'mydatepicker';

function numericStringValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isValid = typeof value === 'string' && /^[0-9]*$/.test(value);
    return isValid ? null : { numericString: { value: control.value } };
  };
}

@Component({
  selector: 'app-tempswim-detail',
  templateUrl: './temp-detail.component.html',
  styleUrls: ['./temp-detail.component.scss']
})
export class TempDetailComponent implements OnInit {

  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy'
  };

  id!: string;
  profileImageSrc: string = '../../../assets/images/uploadphoto.png';
  isSaving: boolean = false;
  guestForm!: FormGroup;
  saved = false;
  requiredMessage = 'This field must not be empty';
  breadcrumbs: any[] = [
    { name: 'Swim', url: 'swim', isActive: true },
    { name: 'Temporary Swimmer', url: 'members/templist', isActive: true },
    { name: '', url: '', isActive: false }
  ];
  attachments: any = {
    passportCopy: null,
    visaCopy: null,
    arrivalCopy: null,
    colorPhotos: [],
    visitingCard: null
  };
  regionList: any = [];
  filteredTowns: any[] = [];
  filteredWards: any[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private messageService: MessageService,
    private kunyek: KunyekService,
    private allInOneService: AllInOneService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(value => this.id = value.get('id') || '')
    this.guestForm = this.fb.group({
      syskey: ['', Validators.required],
      name: ['', Validators.required],
      nationality: [''],
      country: [''],
      dateOfBirth: ['', Validators.required],
      gender: ['', Validators.required],
      occupation: [''],
      company: [''],
      dateOfArrival: ['', Validators.required],
      address: [''],
      contactNo: ['', [Validators.required, numericStringValidator()]],
      email: ['', [Validators.required, Validators.email]],
      options: ['', Validators.required],
      region: ['',],
      town: ['',],
      ward: ['',]
    });

    if (this.id) {
      this.getOneTempSwimmer();
    }
    this.getRegion();
  }

  getOneTempSwimmer() {
    this.kunyek.tempMember().get({ id: this.id }).subscribe({
      next: (res) => {
        const guestData = res.data;
        this.guestForm.patchValue({
          syskey: guestData.syskey,
          name: guestData.name,
          nationality: guestData.nationality,
          country: guestData.country,
          dateOfBirth: this.allInOneService.changeStringToDateobject(guestData.birthdate),
          gender: guestData.gender,
          occupation: guestData.occupation,
          company: guestData.company,
          dateOfArrival: this.allInOneService.changeStringToDateobject(guestData.arrivaldate),
          address: guestData.address,
          contactNo: guestData.phone,
          email: guestData.email,
          options: guestData.option || '',
          region: guestData.directory?.region,
          town: guestData.directory?.town,
          ward: guestData.directory?.postcode
        });
        this.breadcrumbs[2].name = guestData.name
        if (guestData.directory?.region && guestData.directory?.town) {
          this.onRegionChange({ target: { value: guestData?.directory?.region } });
          this.onTownChange({ target: { value: guestData?.directory?.town } });
        }
      },
      error: (err) => {
        this.messageService.openSnackBar('Failed to fetch guest details', 'warn');
      }
    });
  }
  onFileChange(event: any, type: string): void {
    const files = event.target.files;
    if (type === 'colorPhotos') {
      this.attachments.colorPhotos = files;
    } else {
      this.attachments[type] = files[0];
    }
  }

  onProfileClick() {
    const input = document.createElement('input');
    input.hidden = true;
    input.type = 'file';
    input.accept = 'image/*';
    input.addEventListener('change', (event: Event) => {
      this.onImageChange(event);
    });
    input.click();
  }

  private onImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = e => this.profileImageSrc = reader.result as string;
      reader.readAsDataURL(file);
    }
  }

  onSaved() {
    this.saved = true;
    const formattedBirthDate = this.dateOfBirth?.value?.formatted || this.dateOfBirth?.value;
    const formattedArrivalDate = this.dateOfArrival?.value.formatted || this.dateOfArrival?.value;

    if (this.guestForm.valid) {
      this.isSaving = true;
      const formData = {
        syskey: this.syskey?.value,
        name: this.name?.value,
        nationality: this.nationality?.value,
        country: this.country?.value,
        birthdate: this.allInOneService.convertDate(formattedBirthDate),
        gender: this.gender?.value,
        occupation: this.occupation?.value,
        company: this.company?.value,
        arrivaldate: this.allInOneService.convertDate(formattedArrivalDate),
        address: this.address?.value,
        phone: this.contactNo?.value,
        email: this.email?.value,
        option: this.options?.value,
        postcode: this.ward?.value
      };

      this.kunyek.tempMember().update(formData).subscribe({
        next: (res) => {
          switch (res.returncode) {
            case "301":
              this.isSaving = false;
              this.messageService.openSnackBar(res.message, 'warn');
              break;
            default:
              this.isSaving = false;
              this.messageService.openSnackBar('Success!', 'success');
              this.closeForm()
          }
        },
        error: (err) => {
          this.messageService.openSnackBar('Failed to update guest member', 'warn');
          this.isSaving = false;
        }
      });

    } else {
      this.messageService.openSnackBar('Invalid Form', 'warn');
      this.isSaving = false;
    }
  }

  getRegion() {
    const data = {};
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.regionList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onRegionChange(region: any) {
    const data = {
      region: region.target.value
    };
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredTowns = res.datalist;
          this.filteredWards = [];
          this.resetDropdowns('town');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  resetDropdowns(type: string) {
    if (type === 'town') {
      this.filteredWards = [];
    }
  }

  onTownChange(town: any) {
    const data = {
      town: town.target.value
    };
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredWards = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  closeForm() {
    this.router.navigate(['members', 'templist']);
  }

  get name() { return this.guestForm.get('name'); }
  get nationality() { return this.guestForm.get('nationality'); }
  get country() { return this.guestForm.get('country'); }
  get dateOfBirth() { return this.guestForm.get('dateOfBirth'); }
  get gender() { return this.guestForm.get('gender'); }
  get occupation() { return this.guestForm.get('occupation'); }
  get company() { return this.guestForm.get('company'); }
  get dateOfArrival() { return this.guestForm.get('dateOfArrival'); }
  get address() { return this.guestForm.get('address'); }
  get contactNo() { return this.guestForm.get('contactNo'); }
  get email() { return this.guestForm.get('email'); }
  get options() { return this.guestForm.get('options'); }
  get syskey() { return this.guestForm.get('syskey'); }
  get region() { return this.guestForm.get('region'); }
  get town() { return this.guestForm.get('town'); }
  get ward() { return this.guestForm.get('ward'); }
}
