import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-neo-privacy-policy',
  templateUrl: './neo-privacy-policy.component.html',
  styleUrls: ['./neo-privacy-policy.component.scss']
})
export class NeoPrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
