import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
@Component({
  selector: 'app-member-payment',
  templateUrl: './member-payment.component.html',
  styleUrls: ['./member-payment.component.scss'],
})
export class MemberPaymentComponent implements OnInit {
  // memberinfo: any;
  // fees: any;
  // profileimage: string = "";
  // totalAmount = 0;

  payStatus = [
    { name: "All", value: "" },
    { name: "Paid", value: "true" },
    { name: "Unpaid", value: "false" },
  ];

  selectePaystatus = "false";
  selectePaystatusName = "Unpaid";

  isOpenForm: boolean = false;
  isOpenRegisterForm: boolean = false;
  isHistoryForm: boolean = false;
  searchName: string = "";
  gettingMemberList: boolean = false;
  memberList: Array<any> = [];
  currentMember: any;
  profileUrl: any;

  breadcrumbs: any[] = [
    { name: 'Payment', url: '/payment', isActive: true },
    { name: 'Member Payment', url: '', isActive: false }
  ];

  constructor(
    private kunyekService: KunyekService,
    public allInOneService: AllInOneService
  ) { }


  ngOnInit(): void {
    this.getMembers();
  }

  // getMemberInfo() {
  //   this.kunyekService.getMemberInfo().subscribe({
  //     next: (res: any) => {
  //       if (res.returncode == '300') {
  //         this.memberinfo = res.data;
  //         this.fees = res.data.fees;
  //         let imgob = JSON.parse(this.memberinfo.profileimage);
  //         this.profileimage = imgob.fileurl;
  //         let totalAmount = 0
  //         this.fees.forEach((fee: any) => {
  //           fee.monthly_detail.forEach((detail: any) => {
  //             totalAmount += parseFloat(detail?.amount);
  //           });
  //         });
  //         this.totalAmount = totalAmount;
  //       }
  //     },
  //   });
  // }

  // handlePay() {
  //   const data = {
  //     usersyskey: this.memberinfo.syskey,
  //     paymentstatus: true
  //   }
  //   this.kunyekService.payfee(data).subscribe({
  //     next: (res: any) => {
  //       if (res.returncode == "300") {
  //         this.messageService.openSnackBar("Payment Successfully", 'success');
  //         this.memberinfo.paymentstatus = res.paymentstatus
  //       }
  //     }
  //   })
  // }

  handleReresh() {
    this.memberList = [];
    this.getMembers();
  }

  getMembers() {
    this.gettingMemberList = true;
    const data = {
      searchName: this.searchName,
      membertypeid: this.selectePaystatus == 'false' ? 'ASDLFKJ' : '',
      parentid: "",
      formstatus: "002",
      paymentstatus: this.selectePaystatus
    };
    this.kunyekService.getKPaymentMembers(data).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.memberList = res.datalist;
        }
        this.gettingMemberList = false;
      },
      error: (err) => {
        this.gettingMemberList = false;
      }
    })
  }

  editMember(member: any) {
    if (member.paymentstatus) {
      this.isOpenRegisterForm = true;
    } else {
      this.isOpenForm = true;
    }

    this.currentMember = member;
  }

  closeForm() {
    this.isOpenForm = false;
    this.isOpenRegisterForm = false;
    this.isHistoryForm = false;
    this.currentMember = null;
    this.getMembers();
  }

  openHistory(member: any) {
    this.isHistoryForm = true;
    this.currentMember = member;
  }

  handlePaySelected(status: any) {
    this.selectePaystatus = status.value;
    this.selectePaystatusName = status.name;
    this.handleReresh();
  }

  formateDate(date: string) {
    if (date == '') return '';
    const pattern = 'dd-MM-yyyy';
    const date1 = formatDate(new Date(date), pattern, 'en-US');
    return date1;
  }

}