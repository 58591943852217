<div class="d-flex align-items-center justify-content-between mb-2">
    <div>
        <h3 class="mb-0">
            {{ userdata.username ? userdata.username : userdata.userid }} ({{userdata.employeeid}})
        </h3>
        <!-- <button (click)="getData()">test</button> -->
    </div>
    <div class="d-flex align-items-center">
        <!-- <button mat-icon-button mat-dialog-close> -->
        <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
        <!-- </button> -->
    </div>
</div>
<div class="d-flex align-items-center mb-2">
    <div>
        Total Absent Days : {{userdata.count}}
    </div>
    <!-- <div>
        <div class="line"></div>
    </div>
    <div>
        <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
            <img class="excel-icon" src="../../../assets/images/excel.png">
            Export
        </button>
    </div> -->
</div>
<div class="table-responsive" matSort (matSortChange)="sortData($event)">
    <table class="table table-responsive table-borderless table-fixed">
        <thead class="table-header">
            <tr>
                <td scope="col" class="col-md-3">Date</td>
                <td scope="col" class="col-md-3">Time In</td>
                <td scope="col" class="col-md-3">Activity</td>
                <td scope="col" class="col-md-3">Leave</td>
            </tr>
        </thead>
        <tbody *ngIf="tableLoading">
            <tr>
                <td colspan="3" class="nodata">
                    <div class="spinner-border" entitle="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!tableLoading">
            <tr *ngFor="let item of userdata.detail">
                <td class="td-data" [title]="allinoneService.formatDBToShow(item.date)">
                    {{allinoneService.formatDBToShow(item.date)}}
                </td>
                <td class="td-data">
                    <ng-container *ngIf="item.attendance.length > 0; else noData">
                        {{item.attendance[0].starttime}}
                        <div>
                            <ng-container
                                *ngIf="item.attendance[0].location != '' || (item.attendance[0].lat != '' && item.attendance[0].long != '')">
                                <a *ngIf="item.attendance[0].lat && item.attendance[0].long"
                                    href="https://www.google.com/maps/search/?api=1&query={{item.attendance[0].lat}},{{item.attendance[0].long}}"
                                    target="_blank" [ngClass]="{'red' : item.attendance[0].detail.locationtype != '3'}">
                                    <span class="d-flex flex-column loc">
                                        <div *ngIf="item.attendance[0].location">
                                            <span
                                                *ngIf="item.attendance[0].detail.locationtype == '1' || item.attendance[0].detail.locationtype == '2' || item.attendance[0].detail.locationtype=='3' || item.attendance[0].detail.locationtype == '5'">@</span>
                                            <span *ngIf="item.attendance[0].detail.locationtype == '4'">#</span>
                                            {{item.attendance[0].location}}
                                        </div>
                                        <div class="td-location">
                                            {{item.attendance[0].lat}}, {{item.attendance[0].long}}
                                        </div>
                                    </span>
                                </a>
                                <span *ngIf="item.attendance[0].lat == '' && item.attendance[0].long == ''"
                                    [ngClass]="{'red' : item.attendance[0].detail.locationtype != '3'}">
                                    <span
                                        *ngIf="item.attendance[0].detail.locationtype == '1' || item.attendance[0].detail.locationtype == '2' || item.attendance[0].detail.locationtype=='3' || item.attendance[0].detail.locationtype == '5'">@</span>
                                    <span *ngIf="item.attendance[0].detail.locationtype == '4'">#</span>
                                    {{item.attendance[0].location}}

                                </span>
                            </ng-container>
                            <ng-container
                                *ngIf="item.attendance[0].location == '' && item.attendance[0].lat == '' && item.attendance[0].long == ''">
                                <span class="gray">Unknown Location</span>
                            </ng-container>
                        </div>
                    </ng-container>
                </td>
                <td class="td-data">
                    <ng-container *ngIf="item.activity.length > 0; else noData">
                        <div>
                            {{item.activity[0].starttime}} - {{item.activity[0].endtime}}
                        </div>
                        <div style="white-space: pre-line; white-space: pre-line;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;">
                            {{item.activity[0].description}}
                        </div>
                    </ng-container>
                </td>
                <td class="td-data">
                    <ng-container *ngIf="item.leave; else noData">
                        {{item.leavename}}&nbsp;&nbsp;<span [title]="item.leavestatus" class="status" [ngClass]="{
                            'status-green': item.leavestatus == '002',
                            'status-red': item.leavestatus == '003',
                            'status-gray': item.leavestatus == '001',
                            'status-main': item.leavestatus == '004'
                          }">{{item.leavestatus == '001'? 'Submitted' : item.leavestatus == '002' ? 'Approved' :
                            item.leavestatus == '003' ? 'Rejected' : 'Completed'}}</span>
                    </ng-container>
                </td>

            </tr>
        </tbody>
    </table>
</div>

<ng-template #noData>
    -
</ng-template>