<div class="container-fluid">
  <div class="content">
    <app-cs365-breadcrumb
      s_breadcrumb="Warranty"
      [t_breadcrumb]="editData ? ' Edit' : 'New'"
      (emit)="closeEdit()"
    ></app-cs365-breadcrumb>
    <div class="card card-body my-card p-4">
      <div class="d-flex">
        <button class="btn btn-custom me-3" (click)="closeEdit()">
          Cancel
        </button>
        <button class="btn btn-custom" (click)="handleSave()">
          <span
            *ngIf="saveLoading"
            class="spinner-border spinner-border-sm button-spinner me-2"
            role="status"
            aria-hidden="true"
          ></span>
          Save
        </button>
      </div>

      <div class="row mb-3 mt-4">
        <div class="col">
          <label class="form-label">Code</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="warrantyForm.warrantycode"
            #code="ngModel"
            required
            [ngClass]="{ 'is-invalid': submitted && code.errors }"
            [readonly]="editData"
          />
          <div
            class="invalid-feedback"
            *ngIf="code.invalid || ((code.dirty || code.touched) && submitted)"
          >
            This field may not be blank
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md">
          <label class="form-label">Month</label>
          <input
            onlynumber
            type="number"
            class="form-control"
            #description="ngModel"
            required
            [(ngModel)]="warrantyForm.month"
            [ngClass]="{ 'is-invalid': submitted && description.errors }"
          />
          <div
            class="invalid-feedback"
            *ngIf="
              description.invalid ||
              ((description.dirty || description.touched) && submitted)
            "
          >
            This field may not be blank
          </div>
        </div>
      </div>
      <div clas="row mb-3">
        <div class="col-md mb-3">
          <label for="remark" class="form-label">Remark</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="warrantyForm.remark"
          />
        </div>
      </div>
    </div>
  </div>
</div>
