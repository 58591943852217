import { formatDate } from '@angular/common';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { OfficeCalenderTableComponent } from '../office-calender-table/office-calender-table.component';
import { ThemeService } from 'ng2-charts';

@Component({
  selector: 'app-office-calendar',
  templateUrl: './office-calendar.component.html',
  styleUrls: ['./office-calendar.component.scss'],
})
export class OfficeCalendarComponent implements OnInit, OnDestroy {
  @ViewChild('calendarTable') calendarTable!: OfficeCalenderTableComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isMobileView: boolean = false;
  saveLoading: boolean = false;
  getListSubscription!: Subscription;
  getCalendarSubscription!: Subscription;
  getRosterSubscription!: Subscription;
  getShiftSubscription!: Subscription;
  subscriptions = new SubSink();
  isFocus: boolean = false;

  shiftListLoading: boolean = false;
  rosterListLoading: boolean = false;

  searchText: string = '';
  orgid: string = '';
  maxdate: any = '';
  mindate: any = '';

  shiftList: any = [];
  rosterList: any = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];

  calendarListLoading = false;
  calendarList: any = [];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  addCalendar: boolean = false;
  isAdmin: boolean = false;
  isEditing: boolean = false;
  calendarFormSubmitted: boolean = false;

  currentDate = new Date();
  // end date =  1 months from current date
  endDate = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth() + 1,
    this.currentDate.getDate()
  );
  formattedEndDate = formatDate(this.endDate, 'yyyy-MM-dd', 'en-US');

  weeks: any[][] = this.allinoneService.generateCalendar(this.currentDate, this.endDate);

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  calendarForm : any = {
    calendarid: '',
    name: '',
    description: '',
    type: '002', // 001 = auto, 002 = manual
    rosterid: '',
    startdate: new Date(Date.now()),
    enddate: new Date(),
  };

  selectedRoster: any = '';

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnInit(): void {
    this.setLastDat();
    this.weeks = this.allinoneService.generateCalendar(
      new Date(this.calendarForm.startdate),
      new Date(this.calendarForm.enddate)
    );
    if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getRosterList();
    this.getShiftList();
    this.getCalendarList();
  }

  ngOnDestroy(): void {
    this.getCalendarSubscription && this.getCalendarSubscription.unsubscribe();
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getRosterSubscription && this.getRosterSubscription.unsubscribe();
    this.getShiftSubscription && this.getShiftSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  getCalendarList() {
    this.calendarListLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getCalendarSubscription && this.getCalendarSubscription.unsubscribe();
    this.getRosterSubscription = this.kunyekService
      .getOfficeCalendar(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.calendarList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.calendarListLoading = false;
        },
        (err) => {
          this.calendarListLoading = false;
        }
      );
  }

  newCalendar() {
    this.addCalendar = true;
  }

  editCalendar(calendar: any) {
    this.isEditing = true;
    this.addCalendar = true;
    this.calendarForm = {
      calendarid: calendar.calendarid,
      name: calendar.name,
      description: calendar.description,
      type: calendar.type,
      rosterid: calendar.rosterid,
      startdate: this.allinoneService.parseDateToShowInEdit(calendar.startdate),
      enddate: this.allinoneService.parseDateToShowInEdit(calendar.enddate),
    };

    var startdate = new Date(
      this.allinoneService.formatDBToShowInEdit(calendar.startdate)
    );
    var enddate = new Date(
      this.allinoneService.formatDBToShowInEdit(calendar.enddate)
    );
    this.weeks = this.allinoneService.generateCalendar(startdate, enddate);

    this.weeks.map((week: any) => {
      week.map((day: any) => {
        day.shift = calendar.calendar[day.date] || '';
      });
    });
  }

  submitCalendar() {
    this.calendarFormSubmitted = true;
    this.calendarTable.sendDataToParent();
    if (this.calendarForm.name.trim() == '') {
      return;
    }
    if (this.calendarForm.enddate < this.calendarForm.startdate) {
      return this.messageService.openSnackBar(
        'End date must be greater than start date',
        'warn'
      );
    }
    var noShift = true;
    this.weeks.map((week: any) => {
      week.map((day: any) => {
        if (day.shift != '') {
          noShift = false;
        }
      });
    });
    if (noShift) {
      return this.messageService.openSnackBar(
        'Please select at least one shift.',
        'warn'
      );
    }
    this.saveCalendar();
  }

  saveCalendar() {
    this.saveLoading = true;

    console.log(this.weeks);
    var shiftDataList: any[] = [];
    var shifts: any = {};
    this.weeks.map((week: any) => {
      week.map((day: any) => {
        if (day.date) {
          Object.assign(shifts, {
            [day.date]: day.shift,
          });
        }
        shiftDataList.push(day.shift);
      });
    });

    console.log(shiftDataList);

    var uniShiftList = [...new Set(shiftDataList)];
    var shiftData = {};

    for (var i = 0; i < uniShiftList.length; i++) {
      for (var x = 0; x < this.shiftList.length; x++) {
        if (uniShiftList[i] == this.shiftList[x]['shiftid']) {
          let a = this.shiftList[x]['shiftid'];
          Object.assign(shiftData, {
            [uniShiftList[i]]: this.shiftList[x],
          });
        }
      }
    }

    console.log(this.calendarForm);
    const data = {
      calendarid: this.calendarForm.calendarid,
      name: this.calendarForm.name.trim(),
      description: this.calendarForm.description.trim(),
      startdate: this.allinoneService.parseDate(this.calendarForm.startdate),
      enddate: this.allinoneService.parseDate(this.calendarForm.enddate),
      orgid: this.orgid,
      type: this.calendarForm.type,
      rosterid:
        this.calendarForm.type == '002' ? '' : this.calendarForm.rosterid,
      shiftdata: shiftData,
      calendar: shifts,
    };
    var fun = this.isEditing
      ? this.kunyekService.updateOfficeCalendar(data)
      : this.kunyekService.addOfficeCalendar(data);
    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.refresh();
          this.cancel();
          this.messageService.openSnackBar('Successful.', 'success');
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.calendarFormSubmitted = false;
      },
      (err) => {
        this.saveLoading = false;
        this.calendarFormSubmitted = false;
      }
    );
  }

  cancel() {
    this.addCalendar = false;
    this.isEditing = false;
    this.calendarFormSubmitted = false;
    this.calendarForm = {
      calendarid: '',
      name: '',
      description: '',
      type: '002', // 001 = auto, 002 = manual
      rosterid: '',
      // startdate: this.allinoneService.formatDBToShowInEdit(
      //   this.allinoneService.getCurrentDateToDB()
      // ),
      // enddate: '',
      startdate: new Date(Date.now()),
      enddate: new Date(),
    };
    this.setLastDat();
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  clear() {
    this.searchText = '';
  }

  refresh() {
    this.currentPage = 1;
    this.getCalendarList();
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  sortData(sort: Sort) {
    const data = this.calendarList;
    if (!sort.active || sort.direction === '') {
      this.calendarList = data;
      return;
    }
    this.calendarList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'name') {
        return this.allinoneService.compare(a.name, b.name, isAsc);
      } else if (sort.active == 'description') {
        return this.allinoneService.compare(
          a.description,
          b.description,
          isAsc
        );
      }
      return 0;
    });
  }

  deleteCalendar(calendarid: string, index: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.calendarListLoading = true;
        const data = {
          orgid: this.orgid,
          calendarid: calendarid,
        };
        this.kunyekService.deleteOfficeCalendar(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              var i = this.calendarList.findIndex(
                (x: any) => x.calendarid == calendarid
              );
              this.calendarList.splice(i, 1);
              // this.calendarList.splice(index, 1);
              if (
                Math.round(this.calendarList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.calendarListLoading = false;
          },
          (err) => {
            this.calendarListLoading = false;
          }
        );
      }
    });
  }

  getShiftList() {
    this.shiftListLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getListSubscription = this.kunyekService.getShift(data).subscribe(
      (res: any) => {
        console.log('Roster List');

        console.log(res);

        if (res.returncode == '300') {
          this.shiftList = res.datalist;
          console.log(this.shiftList);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.shiftListLoading = false;
      },
      (err) => {
        this.shiftListLoading = false;
      }
    );
  }

  getRosterList() {
    this.rosterListLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getRosterSubscription && this.getRosterSubscription.unsubscribe();
    this.getRosterSubscription = this.kunyekService.getRoster(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.rosterList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.rosterListLoading = false;
      },
      (err) => {
        this.rosterListLoading = false;
      }
    );
  }

  rosterChange() {
    if (this.calendarForm.rosterid != '') {
      this.selectedRoster = this.rosterList.find(
        (x: any) => x.rosterid == this.calendarForm.rosterid
      );
      console.log(this.selectedRoster);
    } else {
      this.selectedRoster = '';
    }
  }

  dateChange(isStart: boolean) {
    console.log('date change', isStart);
    if (this.calendarForm.enddate < this.calendarForm.startdate) {
      this.calendarForm.enddate = this.calendarForm.startdate;
      // return;
    }
    if (isStart) {
      this.setLastDat();
    }
    this.weeks = this.allinoneService.generateCalendar(
      new Date(this.calendarForm.startdate),
      new Date(this.calendarForm.enddate)
    );
  }

  updateShiftData(event: any) {
    this.weeks = event;
  }

  setLastDat() {
    // var date = this.calendarForm.startdate;
    console.log('this');
    
    console.log(this.calendarForm.startdate);
    console.log(this.allinoneService.parseDate(this.calendarForm.startdate));

    
    var date = this.allinoneService.formatDBToShowInEdit(this.allinoneService.parseDate(this.calendarForm.startdate));
    console.log(this.calendarForm.startdate);
    console.log(date);
    
    var datearr = date.split('-');
    var y = parseInt(datearr[0]);
    var month = parseInt(datearr[1]);
    var nd = new Date(y, month, 0);
    console.log(nd);
    const format = 'yyyy-MM-dd';
    // this.calendarForm.enddate = formatDate(
    //   new Date(nd),
    //   format,
    //   'en-US'
    // ).toString();
    this.calendarForm.enddate = new Date(nd);
    this.maxdate = this.calendarForm.enddate;
  }
}
