<div class="title-container mb-3">
    <div class="title">
      Travels
    </div>
    <div class="close-btn">
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
<hr>

<!-- <div class="form-group mb-3">
    <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"
        name="'contactsearch" id="contactsearch" >
</div> -->


<div style="height: 400px; overflow-y: hidden;">
    <div *ngIf="!isLeaveOpening">
        <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
            (scrolled)="onScrolledDown()" [perfectScrollbar]="config" class="chat-container"
            style="height: 390px !important;position: relative;" #chatPS>
            <ul class="list-group" *ngIf="travelList.length > 0">
                <div *ngFor="let travel of travelList">
                    <div class="travel-card-container" [ngClass]="{'selected-travel': travel.selected}">
                        <div class=" d-flex justify-content-between" (click)="selectTravel(travel)">
                            <div class="d-flex gap-2 align-items-start">
                                <div class="approve-status"></div>
                                <div style="max-width: 96%;">
                                    <div class="travel-detail">
                                        <div class="request-person-name"> &nbsp;{{kunyekusername}} - &nbsp;</div>
                                        <div class="ref-no">Ref No: {{travel.requestrefno}}</div>
                                    </div>
                                    <div class="default-text">
                                        {{allinoneService.formatDBToShow(travel.startdate)}} -
                                        {{allinoneService.formatDBToShow(travel.enddate)}}
                                    </div>
                                    <div *ngIf="travel.requeststatus=='001'" class="default-text">
                                        {{travel.duration}} days of {{travel.remark}} travel submitted
                                    </div>
                                    <div *ngIf="travel.requeststatus=='002'" class="default-text">
                                        {{travel.duration}} days of {{travel.remark}} travel approved by
                                        {{travel.personname}}
                                    </div>
                                    <!-- <div *ngIf="travel.requeststatus"=="003">
                                    
                                </div>
                                <div *ngIf="travel.requeststatus"=="004">
                                    
                                </div> -->
                                    <!-- <input type="checkbox" class="form-check-input" [id]="travel.requestid"
                                [checked]="travel.checked" (change)="selectTravel($event,travel)"> -->
                                </div>
                            </div>
                            <div class="text-lg justify-content-between">
                                <div>{{travel.requesttypename}}</div>
                                <div class="approved-text">{{travel.requeststatus == '002'? "Approved": ""}}</div>
                            </div>

                        </div>
                    </div>
                </div>

            </ul>

            <ng-container *ngIf="gettingTravelList">
                <div *ngFor="let i of [1,2,3,4]" class="d-flex align-items-center flex-row">
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            'width.px' : 400, 
            'height.px': 40}"></ngx-skeleton-loader>

                </div>
            </ng-container>

            <div *ngIf="!gettingTravelList && travelList.length == 0"
                class="d-flex align-items-center justify-content-center" style="height: 100px; width: 100%;">
                <div>
                    No Travels
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="isLeaveOpening">
        <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
            (scrolled)="onScrolledDown()" [perfectScrollbar]="config" class="chat-container"
            style="height: 390px !important;position: relative;" #chatPS>
            <ul class="list-group" *ngIf="travelList.length > 0">
                <div class="profile-container" *ngFor="let user of travelList" (click)="openLeaveOpeningForm(user)">
                    <span>
                        <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
                            [lazyLoad]="user.photo || '../../../assets/images/profile.png'">
                    </span>

                    <label class="d-flex align-items-center justify-content-center" style="cursor: pointer;">
                        <div class="profile-item-container">
                            <div class="profile-item">
                                <div class="username">
                                    <b>{{user.username ? user.username : user.newuserid ? user.userid + ', ' +
                                        user.newuserid : user.userid}}&nbsp;<span style="color: var(--main-color);"
                                            *ngIf="user.employeeid">( {{user.employeeid}} )</span></b>

                                </div>
                                <div style="color: grey;
                                  margin-top: 1px;
                                  font-size: 13.5px;" *ngIf="user.username != ''">{{user.userid}}{{user.newuserid ? ''
                                    + user.newuserid : ''}}</div>
                            </div>
                        </div>
                    </label>

                </div>
            </ul>

            <ng-container *ngIf="gettingTravelList">
                <div *ngFor="let i of [1,2,3,4]" class="d-flex align-items-center flex-row">
                    <div class="me-3">
                        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
            'width.px' : 40, 
            'height.px': 40}"></ngx-skeleton-loader>
                    </div>
                    <div class="d-flex flex-column">
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            'width.px' : 150, 
            'height.px': 10}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
              'width.px' : 100, 
              'height.px': 10}"></ngx-skeleton-loader>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="!gettingTravelList && travelList.length == 0"
                class="d-flex align-items-center justify-content-center" style="height: 100px; width: 100%;">
                <div>
                    No Travels
                </div>
            </div>

        </div>
    </div>
</div>