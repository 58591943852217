import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { h } from '@vime/core/dist/types/stencil-public-runtime';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-employee-requestion',
  templateUrl: './employee-requestion.component.html',
  styleUrls: ['./employee-requestion.component.scss'],
})
export class EmployeeRequestionComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isAdmin: boolean = false;
  isMobileView: boolean = false;
  isAdding: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  subscriptions = new SubSink();
  isFocus: boolean = false;

  listLoading: boolean = false;
  searchText: string = '';
  orgid: string = '';
  formSubmitted: boolean = false;

  templateList: any = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];
  tableLoading: boolean = false;

  subs = new SubSink();

  getTemplateSubscription!: Subscription;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  submitForm = {
    reqtempid: '',
    name: '',
    requirements: '',
    description: '',
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe
  ) {
    this.onResize(event);
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }

    this.getTemplates();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.getTemplateSubscription && this.getTemplateSubscription.unsubscribe();
  }

  getTemplates() {
    this.listLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getTemplateSubscription && this.getTemplateSubscription.unsubscribe();
    this.getTemplateSubscription = this.kunyekService
      .getRequisitionTemplate(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.templateList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.listLoading = false;
        },
        (err) => {
          this.listLoading = false;
        }
      );
  }

  submit() {
    this.formSubmitted = true;
    if (
      this.submitForm.name == '' ||
      this.submitForm.requirements == '' ||
      this.submitForm.description == ''
    ) {
      return;
    }
    this.saveTemplate();
  }

  saveTemplate() {
    this.saveLoading = true;
    const data = {
      reqtempid: this.submitForm.reqtempid,
      orgid: this.orgid,
      name: this.submitForm.name,
      requirements: this.submitForm.requirements,
      descriptions: this.submitForm.description,
    };
    var fun = this.isEditing
      ? this.kunyekService.updateRequisitionTemplate(data)
      : this.kunyekService.createRequisitionTemplate(data);
    this.subs.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if(this.isEditing){
            var index = this.templateList.findIndex((x: any) => x.reqtempid == this.submitForm.reqtempid);
            if(index > -1){
              this.templateList[index].name = this.submitForm.name;
              this.templateList[index].requirements = this.submitForm.requirements,
              this.templateList[index].descriptions = this.submitForm.description
            }
          }
          else{
            const temp = {
              reqtempid: res.reqtempid,
              name: this.submitForm.name,
              requirements: this.submitForm.requirements,
              descriptions: this.submitForm.description
            }
            this.templateList.push(temp);
          }
          this.cancel();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  new() {
    this.isAdding = true;
  }

  searchTextChange() {}

  editTemplate(data: any) {
    this.isAdding = true;
    this.isEditing = true;
    this.submitForm = {
      reqtempid: data.reqtempid,
      name: data.name,
      requirements: data.requirements,
      description: data.descriptions,
    };
  }

  deleteTemplate(template: any){
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult : any) => {
      if(dialogResult) {
        this.listLoading = true;
        
        const data = {
          reqtempid : template.reqtempid,
          orgid : this.orgid,
        }
        this.kunyekService.deleteRequisitionTemplate(data).subscribe(
          (res : any) => {
            if(res.returncode == '300') {
              const index = this.templateList.findIndex((x: any) => x.reqtempid == template.reqtempid);
              if(index > -1){
                this.templateList.splice(index, 1);
              }
              if (
                Math.round(this.templateList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.listLoading = false;
          }, 
          (err) => {
            this.listLoading = false;
          }
        )
      }
    })
  }

  sortData(sort: Sort) {
    const data = this.templateList;
    if (!sort.active || sort.direction === '') {
      this.templateList = data;
      return;
    }
    this.templateList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'requirements':
          return this.allinoneService.compare(a.requirements, b.requirements, isAsc);
        case 'descriptions':
          return this.allinoneService.compare(a.descriptions, b.descriptions, isAsc);
        default:
          return 0;
      }
    })
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {}

  refresh() {
    this.currentPage = 1;
    this.getTemplates();
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.saveLoading = false;
    this.submitForm = {
      reqtempid: '',
      name: '',
      requirements: '',
      description: '',
    };
  }
}
