<div class="d-flex align-items-center justify-content-between mb-3">
  <div>
    <h3 class="mb-0">Leave Summary</h3>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<ng-container *ngIf="gettingLeaveEntitlement; else elseTemplate;">
  <div class="d-flex align-items-center justify-content-center" style="height: 300px;">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-container>

<ng-template #elseTemplate>
  <ng-container *ngIf="summaryList.length == 0">
    <div class="d-flex align-items-center justify-content-center" style="height: 300px;">
      Empty
    </div>
  </ng-container>
  <ng-container *ngIf="summaryList.length > 0">
    <div class="card card-body mb-3 d-flex flex-row justify-content-between">
      <div>
        Total Leave Taken
      </div>
      <div>
        {{parseFloatFun(totalLeaveTaken)}}
      </div>
    </div>
    <!-- <hr> -->
    <div *ngFor="let summary of summaryList;" class="card card-body mb-3 d-flex flex-row justify-content-between">
      <div>
        {{summary.requesttypename}}
      </div>
      <div>
        <!-- {{summary.taken}}/{{summary.left | number:'1.1'}} -->
        <!-- {{ summary.opening ? summary.opening + summary.broughtforward : summary.noofdays + summary.broughtforward }} -->
        <!-- {{ summary.opening ? parseInt(summary.opening) + summary.broughtforward : summary.noofdays + summary.broughtforward }} -->
        {{parseFloatFun(summary.taken)}} / {{ getTotalDay(summary) }}
      </div>
    </div>
  </ng-container>
</ng-template>