import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../services/message.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import { SelectMemberComponent } from 'src/app/members/select-member/select-member.component';
import { AllInOneService } from '../../services/all-in-one.service';
import { KunyekService } from '../../services/kunyek.service';

@Component({
  selector: 'app-leave-select-member',
  templateUrl: './leave-select-member.component.html',
  styleUrls: ['./leave-select-member.component.scss']
})
export class LeaveSelectMemberComponent implements OnInit {
  memberSearch = '';
  memberList: any = [];
  selectedMemberList: any = [];
  gettingMemberList: boolean = true;
  // feetypeName: string = '';
  // feetypeTitle: string = '';
  startCount: any = 0;
  endCount: any = 30;
  isAdmin: boolean = false;
  isAllMembers: boolean = false;
  subscription!: Subscription;
  private dialogData: any;

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<SelectMemberComponent>,
    private injector: Injector,
    private router: Router,
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    // this.feetypeName = this.dialogData.feetypeName;
  }

  ngOnInit(): void {
    this.homesearchMember();
  }

  searchChange() {
    this.memberList.filter = this.memberSearch.trim().toLowerCase();
  }

  homesearchMember() {
    this.memberList = [];
    // const data = {
    //   searchName: this.memberSearch
    // };

    this.gettingMemberList = true;
    const amember_data = {
      formstatus: '002',
      searchName: this.memberSearch
    }
    this.kunyekService.getKMembers(amember_data).subscribe({
      next: (res)=>{
        this.memberList = res.datalist
        this.gettingMemberList = false
      },
      error:(err)=>{
        this.messageService.openSnackBar("Error fetching members", 'error');
        this.gettingMemberList = false;
      }
    });
  }

  selectMember(member: any) {
    this.memberList.forEach((m: any) => {
      if (m.syskey !== member.syskey) {
        m.selected = false;
      }
    });

    member.selected = !member.selected;

    this.selectedMemberList = member.selected ? [member] : [];

    const senderInput = document.getElementById('user') as HTMLInputElement;
    if (senderInput) {
      senderInput.value = member.selected ? member.name : '';
    }

    this.dialogRef.close({ data: this.selectedMemberList });
  }


  close() {
    this.dialogRef.close();
  }

  done() {
    this.dialogRef.close({ data: this.selectedMemberList });
  }

  searchByName() {
    this.homesearchMember();
  }

}
