<div class="me-auto px-5 mt-3">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
</div>

<div class="d-flex flex-column align-items-center gap-2 mb-5">
    <div class="d-flex ms-auto btn-action">
        <button class="btn btn-custom me-2" (click)="printPage()">Print</button>
        <button class="btn btn-custom" (click)="exportPDF()">Export PDF</button>
    </div>
    <div class="card px-5 py-3" id="printableCard" style="width: 780px;">
        <div class="d-flex flex-column gap-1 align-items-center mt-3">
            <h2>ကုက္ကိုင်းရေကူးအသင်း</h2>
            <h2>အထူးရေကူးသင်တန်း (ယာယီ)</h2>
        </div>
        <div class="p-3 mb-3" style=" background-color: white; border: solid 1px #ddd; border-radius: 6px;">
            <div>
                <table style="width: 100%; border-collapse: collapse;">
                    <tbody>
                        <tr>
                            <td></td>
                            <td class="text-end">
                                <img [defaultImage]="'../../../assets/images/member.png'" [lazyLoad]="getProfileImage()"
                                    class="img-fluid border border-primary" alt="specialswimmer image"
                                    style="width: 100px; height: 100px;">
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အမည် </td>
                            <td class="me-5 dashed-line">{{ specialswimmer?.name }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အဖအမည်</td>
                            <td class="me-5 dashed-line">{{ specialswimmer?.fathername }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">မွေးသက္ကရာဇ်</td>
                            <td class="me-5 dashed-line">{{
                                allInOneService.formatDBToShow(specialswimmer?.birthdate) }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အရပ်အမြင့်</td>
                            <td class="me-5 dashed-line">{{ specialswimmer?.height }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အသင်းဝင်အမှတ်</td>
                            <td class="me-5 dashed-line">{{ specialswimmer?.membershipnumber }}</td>
                        </tr>
                        <tr *ngIf="specialswimmer?.directory">
                            <td style="width: 200px;">တိုင်းဒေသကြီး/ပြည်နယ်</td>
                            <td class="me-5 dashed-line">{{
                                specialswimmer?.directory?.ward + '၊' }} {{
                                specialswimmer?.directory?.town + '၊' }} {{ specialswimmer?.directory?.region + '၊၊'}}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">နေရပ်လိပ်စာ</td>
                            <td class="me-5 dashed-line">{{ specialswimmer?.address }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">ဆက်သွယ်ရန်ဖုန်းနံပါတ်</td>
                            <td class="me-5 dashed-line">{{ specialswimmer?.phone }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အပါတ်စဉ်</td>
                            <td class="me-5 dashed-line">
                                {{ specialswimmer?.batch.batch_no }} ( {{
                                allInOneService.formatDBToShow(specialswimmer?.batch.start_date)}} - {{
                                allInOneService.formatDBToShow(specialswimmer?.batch.end_date)}} {{
                                specialswimmer?.batch.time }} )
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">သင်ကြားမည့် ဆရာ</td>
                            <td class="me-5 dashed-line">{{ specialswimmer?.teacher }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">သင်တန်းသားအမှတ်</td>
                            <td class="me-5 dashed-line">{{ specialswimmer?.studentnumber }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">သင်တန်းသားလက်မှတ်</td>
                            <td class="me-5 dashed-line"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>