import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { OfficeChooseMembersComponent } from '../office-choose-members/office-choose-members.component';

@Component({
  selector: 'app-late-earlyout-request',
  templateUrl: './late-earlyout-request.component.html',
  styleUrls: ['./late-earlyout-request.component.scss'],
})
export class LateEarlyoutRequestComponent implements OnInit, OnChanges {
  @Input() requestData: any;
  @Input() backdateRequest: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() isEarlyout: boolean = false;
  @Input() orgid: string = '';
  @Input() userid: string = '';
  @Input() approverList: any = [];

  @Output() save = new EventEmitter<any>();

  // current date with format as yyyy-MM-dd
  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  requestForm = {
    date: this.currentDate,
    starttime: '',
    endtime: '',
    duration: '',
    remark: '',
  };

  gettingShift: boolean = false;

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.isEditing) {
      this.requestForm.date = this.requestData.startdate;
      this.requestForm.starttime = this.requestData.starttime;
      this.requestForm.endtime = this.requestData.endtime;
      this.requestForm.remark = this.requestData.remark;
      this.requestForm.duration = this.requestData.duration;
    }
    if (this.backdateRequest) {
      console.log('backdate');
      console.log(this.requestData);
      this.requestForm.date = this.allinoneService.formatDBToShowInEdit(
        this.requestData.date
      );
      if (this.isEarlyout) {
        this.requestForm.duration = this.requestData.earlyout_time;
        this.requestForm.starttime =
          this.allinoneService.format12HourTimeTo24Hour(
            this.allinoneService.formatTimeToShow(
              this.requestData.actual_timeout
            )
          );
        this.requestForm.endtime =
          this.allinoneService.format12HourTimeTo24Hour(this.requestData.shift);
      } else {
        this.requestForm.duration = this.requestData.late_time;
        this.requestForm.starttime =
          this.allinoneService.format12HourTimeTo24Hour(this.requestData.shift);
        this.requestForm.endtime =
          this.allinoneService.format12HourTimeTo24Hour(
            this.allinoneService.formatTimeToShow(
              this.requestData.actual_timein
            )
          );
      }
      console.log(this.requestForm);
    }

    if (!this.isEditing && !this.backdateRequest) {
      this.getShift();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isEditing) {
      this.requestForm.starttime = '';
      this.getShift();
    }
  }

  getShift() {
    this.gettingShift = true;
    const data = {
      startdate: this.allinoneService.formatDate(this.requestForm.date),
      orgid: this.orgid,
    };
    this.kunyekService.getLateEarlyOutShift(data).subscribe(
      (response: any) => {
        if (response.returncode == '300') {
          if (this.isEarlyout) {
            if (response.end_time_shift == '') {
              this.messageService.openSnackBar(
                'No shift found for this date',
                'warn'
              );
            } else {
              this.requestForm.endtime =
                this.allinoneService.format12HourTimeTo24Hour(
                  response.end_time_shift
                );
            }
          } else {
            if (response.start_time_shift == '') {
              this.messageService.openSnackBar(
                'No shift found for this date', 
                'warn'
              );
            } else {
              this.requestForm.starttime =
                this.allinoneService.format12HourTimeTo24Hour(
                  response.start_time_shift
                );
            }
          }
          this.calculateDuration();
        } else {
          this.messageService.openSnackBar(
            response.message || response.status || 'Server Error',
            'warn'
          );
        }
        this.gettingShift = false;
      },
      (error) => {
        this.gettingShift = false;
      }
    );
  }

  submitRequest() {
    if (this.requestForm.date == '') {
      return this.messageService.openSnackBar('Date cannot be empty', 'warn');
    }
    if (this.gettingShift) {
      return this.messageService.openSnackBar(
        'Please wait while getting shift.',
        'warn'
      );
    }
    if (
      this.requestForm.starttime == '' ||
      this.requestForm.endtime == '' ||
      this.requestForm.starttime === null ||
      this.requestForm.endtime === null
    ) {
      return this.messageService.openSnackBar(
        'Start time and end time cannot be empty',
        'warn'
      );
    } else if (this.requestForm.starttime >= this.requestForm.endtime) {
      return this.messageService.openSnackBar(
        'End time cannot be less than start time',
        'warn'
      );
    }
    if (
      this.requestForm.duration === null ||
      Number(this.requestForm.duration) <= 0
    ) {
      return this.messageService.openSnackBar('Invalid duration.', 'warn');
    }
    const emitData = {
      requestData: this.requestForm,
      approverList: this.approverList,
    };
    this.save.emit(emitData);
  }

  chooseUser() {
    const dialog = this.dialog.open(OfficeChooseMembersComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        userlist: this.approverList,
        isLeaveOpening: false,
        orgid: this.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        const tempApprovalList = dialogResult.data.filter(
          (data: { checked: boolean; userid: string }) => {
            return data.checked == true && data.userid != this.userid;
          }
        );
        const oldApproverList = JSON.parse(JSON.stringify(this.approverList));
        this.approverList = [];
        tempApprovalList.map((x: any) => {
          const temp = oldApproverList.find(
            (user: any) => user.userid == x.userid
          );
          this.approverList.push({
            userid: x.userid,
            employeeid: x.employeeid,
            username: x.username,
            from: temp ? temp.from : '',
            to: temp ? temp.to : '',
            checked: true,
          });
        });
      }
    });
  }

  calculateDuration(isStartTimeChange: boolean = true) {
    if (this.requestForm.starttime && this.requestForm.endtime) {
      // check if endtime is less than starttime
      const startTime = new Date(
        this.currentDate + ' ' + this.requestForm.starttime
      );
      const endTime = new Date(
        this.currentDate + ' ' + this.requestForm.endtime
      );
      if (startTime > endTime) {
        if (isStartTimeChange) {
          this.requestForm.starttime = this.requestForm.endtime;
        } else {
          this.requestForm.endtime = this.requestForm.starttime;
        }
        this.requestForm.duration = '0';
        return this.messageService.openSnackBar(
          'End time cannot be less than start time',
          'warn'
        );
      }

      const duration = (endTime.getTime() - startTime.getTime()) / 60000;
      console.log(duration);
      this.requestForm.duration = duration.toString();
    }
  }
}
