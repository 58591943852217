import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import ListConfig from 'src/app/shared/interfaces/listconfig';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-gym-list',
  templateUrl: './gym-list.component.html',
  styleUrls: ['./gym-list.component.scss']
})
export class GymListComponent {
  configs: ListConfig[] = [
    { title: 'Name', property: 'name' },
    { title: 'Phone', property: 'phone' },
    { title: 'NRC', property: 'nrc' },
    { title: 'Account No', property: 'accountno' },
    { title: 'Card Code', property: 'cardcode' },
    { title: 'Voucher No', property: 'voucherno' },
    { title: 'Occupation', property: 'occupation' },
    { title: 'Join Date', property: 'joindate',type:'date' },
    { title: 'Last Pay Date', property: 'lastpaydate',type:'date' }
];

  url = ['members/gymlist']
  searchName: string = '';
  searchText = "Search Gym Member"
  cb = this.kunyekService.gymMember().get.bind(this.kunyekService)
  breadcrumbs: any[] = [
    { name: 'Gym', url: 'gym', isActive: true },
    { name: 'Gym Member', url: '', isActive: false }
  ];

  constructor(
    private router: Router,
    private kunyekService: KunyekService,
  ) { }

  previewGymMember(id: string) {
    this.router.navigate([...this.url,'preview',id])
  }




}
