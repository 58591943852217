<div mat-dialog-content>
  <label for="mtitle" class="form-label">Conference Call</label>
  <input type="text" class="form-control" required [(ngModel)]="meetingtitle" id="mtitle" name="mtitle"
    placeholder="Title" (keyup.enter)="onConfirm()" #mtitle="ngModel"
    [ngClass]="{ 'is-invalid': formSubmitted && mtitle.invalid }">
  <small style="color: red;" *ngIf = "invalidtitle">Special characters are not allowed.</small>

  <!-- <div class="invalid-feedback" *ngIf="mtitle.invalid || (mtitle.dirty || mtitle.touched) && formSubmitted">
    <div *ngIf="mtitle.errors?.required">
      {{allinoneService.input_err_msg}}
    </div>
  </div> -->

</div>

<div mat-dialog-actions align="end">
  <button class="btn btn-outline-secondary me-2" mat-dialog-close>Cancel</button>
  <app-button-loader (click)="onConfirm()" [button_text]="'Start'" [load_button_text]="'Start'"
    [isLoading]="loading"></app-button-loader>
</div>