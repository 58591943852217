import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-absentreport-details',
  templateUrl: './absentreport-details.component.html',
  styleUrls: ['./absentreport-details.component.scss'],
})
export class AbsentreportDetailsComponent implements OnInit, OnDestroy {
  userdata: any;
  tableLoading : boolean = false;
  getList!: Subscription;

  statusList = [
    {
      name: 'Submitted',
      value: '001',
    },
    {
      name: 'Approved',
      value: '002',
    },
    {
      name: 'Rejected',
      value: '003',
    },
    {
      name: 'Completed',
      value: '004',
    },
  ];

  constructor(
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private kunyekService: KunyekService,
    private dialogRef: MatDialogRef<AbsentreportDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userdata = data.data;
    console.log(this.userdata);
  }

  ngOnInit(): void {
    this.getData();
    // this.userdata.detail.map((x: any) => ({
    //   ...x,
    //   statusname: x.leave ? this.statusList.find((y: any) => y.value == x.leavestatus)?.name  : ''
    // }))
  }

  ngOnDestroy(): void {
    this.getList && this.getList.unsubscribe();
  }

  getData() {
    var tempkey : any = [];
    var detailList = this.userdata.detail
    
    detailList.map((x:any) => {
      if(x.attendance.length > 0) {
        tempkey.push(x.attendance[0].syskey)
      }
    }
    )
    
    if(tempkey.length > 0) {
      this.tableLoading = true;
    
    const data = {
      syskey : tempkey,
      userid : this.userdata.userid,
      accesskey: "a3VueWVrQHMzcnZpYzMtS0VZZjByYWNjZXNza2V5"
    }
    this.getList && this.getList.unsubscribe();
    this.getList = this.kunyekService.getAbsentReportDetails(data).subscribe(
      (res:any) => {
        if(res.returncode == 300) {

        detailList.map((x:any, index : any) => {
          for(var i = 0; i < res.datalist.length; i++) {
            if(x.attendance.length > 0 && x.attendance[0].syskey == res.datalist[i].syskey) {
              detailList[index].attendance[0]['location'] = res.datalist[i]['location'];
              detailList[index].attendance[0]['lat'] = res.datalist[i]['lat'];
              detailList[index].attendance[0]['long'] = res.datalist[i]['long'];
              detailList[index].attendance[0]['detail'] = res.datalist[i]['detail'];
              detailList[index].attendance[0]['description'] = res.datalist[i]['description'];
            }
          }
        });
        
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.tableLoading = false;
        
      },
      (err) => {
        this.messageService.openSnackBar('Server Error',
          'warn'
        );
        this.tableLoading = false;
      }
    )
    }
  }

  sortData(sort: Sort) {}

  downloadSheet() {
    var exdata: any = [];
    var name = this.userdata.username ? this.userdata.username : this.userdata.userid + '('+this.userdata.employeeid+')\' Absent Report' + '.xlsx';
    this.userdata.detail.map((item: any) => {
      var temp = {
        'Date': this.allinoneService.formatDBToShow(item.date),
        'Time In': item.attendance.length > 0 ? item.attendance[0].starttime: '-',
        'Activity' : item.activity.length > 0 ? item.activity[0].starttime + ' - ' + item.activity[0].endtime: '-',
        'Leave' : item.leave ? item.leavename : '-',
        'Leave Status' : item.leave ? this.statusList.find((x: any) => x.value == item.leavestatus)?.name : '-',
      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }
}
