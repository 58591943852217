import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-requisition-reason',
  templateUrl: './requisition-reason.component.html',
  styleUrls: ['./requisition-reason.component.css'],
})
export class RequisitionReasonComponent implements OnInit, OnDestroy {
  callingReason: boolean = false;
  orgid: string = '';
  requisitionid: string = '';
  people: string = '';
  hrremark: string = '';
  role: string = '';

  saveLoading: boolean = false;

  getReasonSub!: Subscription;

  reasonList: any = [];

  reasonForm: any = {
    reason: '',
    reasontype: '',
    hrremark: '',
    customreason: '',
  };

  domainid = this.allinoneService.getDomain().domainid;

  constructor(
    public dialogRef: MatDialogRef<RequisitionReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private allinoneService : AllInOneService
  ) {
    console.log(data);
    this.orgid = data.orgid;
    this.requisitionid = data.requisitionid;
    this.people = data.people;
    this.hrremark = data.hrremark;
    this.role = data.role;
  }

  ngOnInit(): void {
    this.getReasonList();
  }

  ngOnDestroy(): void {
    this.getReasonSub && this.getReasonSub.unsubscribe();
  }

  reasonChange() {
    console.log(this.reasonForm.reason);
    for (var i = 0; i < this.reasonList.length; i++) {
      if (this.reasonForm.reason == this.reasonList[i]['text']) {
        this.reasonForm.reasontype = this.reasonList[i]['type'];
      }
    }
  }

  getReasonList() {
    this.callingReason = true;
    var data = {
      "domainid": this.domainid,
      "orgid": this.orgid,
      "reasontype" : "002"
    }
    this.kunyekService.getRequisitionReason(data).subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          this.reasonList = res.datalist;
          this.reasonForm.reason = this.reasonList[0].text;
          this.reasonForm.reasontype = this.reasonList[0].type;
        }else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.callingReason = false;
      },
      (err : any) => {
        this.messageService.openSnackBar('Server Error',
          'warn'
        );
        this.callingReason = false;
      }
    )
  }

  // getReasonList() {
  //   this.callingReason = true;
  //   const data = {
  //     orgid: this.orgid,
  //   };
  //   this.getReasonSub && this.getReasonSub.unsubscribe();
  //   this.getReasonSub = this.kunyekService.getRejectRequisitionReason(data).subscribe(
  //     (res: any) => {
  //       console.log(res);
  //       if (res.returncode == 300) {
  //         this.reasonList = res.datalist;
  //         this.reasonForm.reason = this.reasonList[0].text;
  //         this.reasonForm.type = this.reasonList[0].type;
  //       } else {
  //         this.messageService.openSnackBar(
  //           res.message || res.status || 'Server Error',
  //           'warn'
  //         );
  //       }
  //       this.callingReason = false;
  //     },
  //     (err: any) => {
  //       this.callingReason = false;
  //       this.messageService.openSnackBar('Server Error', 'warn');
  //     }
  //   );
  // }

  onConfirm(): void {
    if (
      this.reasonForm.reasontype == '002' &&
      (this.reasonForm.customreason == '' ||
        this.reasonForm.customreason == null)
    ) {
      this.messageService.openSnackBar('Reason cannot be blank.', 'warn');
      return;
    }
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var tempReason =
      this.reasonForm.reasontype == '002'
        ? this.reasonForm.customreason
        : this.reasonForm.reason;
    var data = {
      orgid: this.orgid,
      requisitionid: this.requisitionid,
      status: '003',
      people: this.people,
      role: this.role,
      reason: tempReason,
      hrremark: this.hrremark,
    };
    console.log(data);
    // return
    this.kunyekService.approveRequisition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.messageService.openSnackBar('Successful.', 'success');
          this.dialogRef.close(true);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
        this.messageService.openSnackBar('Server Error', 'warn');
      }
    );
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
