import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-check-in-share',
  templateUrl: './check-in-share.component.html',
  styleUrls: ['./check-in-share.component.scss'],
})
export class CheckInShareComponent implements OnInit {
  saveLoading: boolean = false;
  groups: any[] = [];
  activitysyskey: string = '';
  channelid: string = '';

  constructor(
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private dialogRef: MatDialogRef<CheckInShareComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.groups = data.groups || [];
    this.activitysyskey = data.syskey;
  }

  ngOnInit(): void {}

  trackBySort(index: any, item: any) {
    return item.sort;
  }

  share() {
    if (this.channelid == '') {
      this.messageService.openSnackBar('Please select a group.', 'warn');
      return;
    }
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    const domain = this.allinoneService.getDomain();
    const username =
      this.allinoneService.profile.username ||
      this.allinoneService.profile.userid;
    const current_date = this.allinoneService.getCurrentDateToDB();
    const current_time = this.allinoneService.getCurrentTimeToDB();
    const data = {
      domain: domain.shortcode,
      domainid: domain.domainid,
      contact: '',
      channelname: '',
      date: current_date,
      time: current_time,
      message: 'Activity',
      notimessage: username + "'s Activity",
      imagename: '',
      imagedata: '',
      channelid: this.channelid,
      name: username,
      mention: [],
      eventid: '',
      timeinoutid: this.activitysyskey,
    };
    this.kunyekService.shareCheckin(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.messageService.openSnackBar('Shared successfully.', 'success');
          this.dialogRef.close();
        } else {
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar(
            res.message || res.status || res.error,
            'warn'
          );
        }
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  close(){
    if(this.saveLoading){
      return;
    }
    this.dialogRef.close();
  }
}
