import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

type FileArray = {
  filename: string;
  fileurl: string;
  fileexp: string;
};

@Component({
  selector: 'app-history-detail',
  templateUrl: './history-detail.component.html',
  styleUrls: ['./history-detail.component.css'],
})
export class HistoryDetailComponent implements OnInit {
  previewurl: string = '';
  pageSize: number = 100;
  formatToShow = 'yyyy-MM-dd';
  enddatetime = formatDate(new Date(), this.formatToShow, 'en-US').toString();
  oldData: any;
  displayLables = [
    { name: 'Ref No.', id: 'refno' },
    {
      name: 'Description',
      id: 'servicedesc',
    },
    { name: 'Service Type', id: 'CRMServiceTypeOrganization' },
    { name: 'Status', id: 'CRMServiceStatus' },
    { name: 'Priority', id: 'priority' },
    { name: 'Product', id: 'product' },
    { name: 'Project', id: 'projectname' },
    { name: 'Assigned To', id: 'assignedto' },
    { name: 'MER', id: 'mer' },
    { name: 'Start Date | Time', id: 'startdatetime' },
    { name: 'End Date | Time', id: 'enddatetime' },
    { name: 'Target Date | Time', id: 'targetdatetime' },
    { name: 'Due Date | Time', id: 'duedatetime' },
    { name: 'Customer', id: 'CRMCustomer' },
    { name: 'Contact', id: 'CRMContact' },
    { name: 'Phone', id: 'customerphone' },
    { name: 'Email', id: 'customeremail' },
    { name: 'Details', id: 'details' },
    { name: 'Remark', id: 'remark' },
    { name: 'Last Updated', id: 'reportedbyname' },
  ];
  history: any = [];
  skeletons = new Array(15).fill(null);
  isLoading: boolean = false;
  displayid: string = '';
  oldfiles: FileArray[] = [];
  newfiles: FileArray[] = [];
  assignPeople: any = [];
  orgid = "";

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  constructor(
    private allInOneService: AllInOneService,
    private kunkyekService: KunyekService,
    public dialogRef: MatDialogRef<HistoryDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {

    if (this.data) {
      this.displayid = this.data.servicedata.autoid;
      this.newfiles = this.data.servicedata.filelist;
      this.orgid = this.data.orgid;
    }
    this.getAssignPeople();
    this.getHistoryDetail();
  }

  getAssignPeople() {

    const data = {
      domainid: this.allInOneService.getDomain().domainid,
      orgid: this.allInOneService.selectedOrg
    };
    this.kunkyekService.getCrmPIC(data).subscribe({
      next: (res) => {
        if (res.returncode == '300') {
          this.assignPeople = res.list;
          console.log(res)
        }
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getAssingToName(array: any[]) {
    const result = array.map(x => {
      const name = this.assignPeople.find((p: any) => p.picid == x)?.picname;
      return name;
    }).join(",")
    console.log('result', result, array);
    return result;
  }

  getHistoryDetail() {
    const data = {
      domainid: this.allInOneService.getDomain().domainid,
      orgid: this.data.orgid,
      customerview: false,
      pagesize: this.pageSize,
      serviceid: this.data.servicedata.serviceid,
      sortby: this.data.servicedata.sortby,
      startdate: '',
      enddate: this.enddatetime,
      newpath: true,
      detail: true,
    };
    this.isLoading = true;
    this.kunkyekService.getHistory(data).subscribe({
      next: (res) => {
        if (res.returncode == '300') {
          this.isLoading = false;
          this.oldData = res.list[0];
          if (this.oldData) {
            this.oldfiles = this.oldData.filelist;
            this.oldfiles.forEach((oldfile) => {
              this.newfiles = this.newfiles.filter(
                (newfile) => newfile.filename !== oldfile.filename,
              );
            });
          }
          console.log(this.newfiles, this.oldfiles);
          this.checkChanges();
        } else {
          throw Error(res.error);
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.log(err);
      },
    });
  }

  checkChanges() {
    this.displayLables.forEach((item) => {
      let newContent;
      let oldContent;
      switch (item.id) {
        case 'CRMCustomer':
        case 'CRMServiceStatus':
        case 'CRMServiceTypeOrganization':
        case 'CRMServiceStatus':
        case 'product':
        case 'project':
          newContent = this.data.servicedata[item.id].name;
          oldContent = this.oldData ? this.oldData[item.id].name : '';
          break;
        case 'startdatetime':
        case 'enddatetime':
        case 'duedatetime':
        case 'targetdatetime':
          newContent = this.getDataFormat(this.data.servicedata[item.id]);
          oldContent = this.oldData
            ? this.getDataFormat(this.oldData[item.id])
            : '';
          break;
        case 'priority':
          newContent = this.getPriority(this.data.servicedata[item.id]);
          oldContent = this.oldData
            ? this.getPriority(this.oldData[item.id])
            : '';
          break;
        case 'assignedto':
          newContent = this.getAssingToName(this.data.servicedata[item.id])
          oldContent = this.oldData ? this.getAssingToName(this.oldData[item.id]) : ''
          break;
        default:
          newContent = this.data.servicedata[item.id];
          oldContent = this.oldData ? this.oldData[item.id] : '';
          break;
      }
      let isChange = oldContent != '' && newContent != oldContent;
      this.history.push({
        name: item.name,
        isChange,
        oldContent,
        newContent,
      });
    });
    console.log(this.history);
  }

  getDataFormat(value: string) {
    if (value == '') return;
    const date = new Date(value);
    const time = moment(date, 'HH:mm').format('hh:mm A');

    const formatted = formatDate(value, 'dd/MM/yyyy', 'en-US');
    return `${formatted}  ${time}`;
  }

  viewImage(filename: string) {
    this.previewurl = filename;
  }

  downloadImage(url: string) {
    window.open(url, '_blank');
  }

  getPriority(value: string) {
    let result = '';
    switch (value) {
      case '':
        result = '';
        break;
      case '00010':
        result = 'Low';
        break;
      case '00500':
        result = 'High';
        break;
      default:
        result = 'Critical';
        break;
    }
  }
}
