<section class="container-fluid">
    <div class="content">
        <div class="d-flex">
            <div class="me-auto">
                <app-cs365-breadcrumb [s_breadcrumb]="'Label'"> </app-cs365-breadcrumb>
            </div>
        </div>

        <div class="card card-body my-card">
            <div class="d-flex" *ngIf="isEdit">
                <button class="btn btn-custom me-3" (click)="handleCancel()">
                    Cancel
                </button>
                <button class="btn btn-custom" (click)="handleSave()">
                    <span *ngIf="submitLoading" class="spinner-border spinner-border-sm button-spinner me-2"
                        role="status" aria-hidden="true"></span>
                    Save
                </button>
            </div>

            <div *ngIf="!isEdit">
                <button class="btn btn-custom" (click)="handleEdit()">Edit</button>
            </div>

            <div class="row mt-4">
                <article *ngFor="let form of labelForm" class="col-md-6 mb-3">
                    <div class="row">
                        <div class="col-6">
                            <label [for]="form.id" class="form-label">{{ form.name }}</label>
                            <input [id]="form.id" type="text" class="form-control" [(ngModel)]="form.subname"
                                [disabled]="!isEdit" />
                        </div>
                        <div class="col-6 d-flex align-items-center">
                            <div class="d-flex align-items-center gap-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value=""
                                        [id]="form.id + 'remove-label'" [checked]="isRemoval(form)"
                                        [disabled]="!isEdit || form.mandatory" (change)="handleRemoveLabel(form)" />
                                    <label class="form-check-label" [for]="form.id + 'remove-label'"> Remove </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" [checked]="form.mandatory"
                                        [id]="form.id + 'mandatory-label'" [disabled]="!isEdit || isRemoval(form)"
                                        (change)="handleSelectMandatoryLabel(form)" />
                                    <label class="form-check-label" [for]="form.id + 'mandatory-label'">
                                        Mandatory
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</section>