<div class="fix">
    <div class="title-container mb-3">
        <div class="title">
            Info
        </div>
        <div class="close-btn">
            <!-- <button mat-icon-button mat-dialog-close [disabled]="saveLoading"> -->
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class = "mt-3">
        <ul class="list-group">
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        User ID
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{user.userid}}{{user.newuserid ? ', ' + user.newuserid : ''}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Username
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{user.username ? user.username : user.userid}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Employee ID
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{user.employeeid}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Post
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{user.positionname || '-'}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Department
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{user.department ? user.department : "-"}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Division
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{user.division ? user.division : "-"}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Order
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{user.order ? user.order : "0"}}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>