<div class="d-flex align-items-center justify-content-between mb-3">
    <div>
        <h3 class="mb-0">Brought Forward</h3>
    </div>
    <div>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div class="row">
    <div class="mb-3 col-md-12">
        <div class="mb-3">
            <label for="calendar" class="form-label">Previous Calendar</label>
            <select class="form-select" [(ngModel)]="calendarid">
                <option value="">-</option>
                <option *ngFor="let cal of calendarList" [value]="cal.calendarid">
                    {{cal.year}}
                </option>
            </select>
        </div>
        <div>
            <label for="calendar" class="form-label">Current Calendar</label>
            <input class="form-control" type="text" [value]="currentYear.yearname" readonly disabled>
        </div>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button type="button" class="btn btn-outline-secondary me-2" mat-dialog-close>
        Cancel
    </button>
    <app-button-loader (click)="calculate()" [button_text]=" 'Calculate'" [load_button_text]="'Calculating'"
        [isLoading]="isCalculating"></app-button-loader>
</div>