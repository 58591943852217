import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AllInOneService } from '../../services/all-in-one.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  attendanceView: boolean = false;

  @Input() s_breadcrumb!: string;
  @Input() t_breadcrumb!: string;
  @Input() f_breadcrumb!: string;
  @Input() fi_breadcrumb!: string;


  @Output('emit')
  change: EventEmitter<any> = new EventEmitter<any>();

  @Output('emit1')
  change1: EventEmitter<any> = new EventEmitter<any>();


  @Output('emit2')
  change2: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router, private allinoneService: AllInOneService) { }

  ngOnInit(): void {
    this.attendanceView = this.allinoneService.isAttendanceView;
  }
  goToSecond() {
    if (this.t_breadcrumb) {
      this.change.emit(false);
    }
  }
  goToThird() {
    if (this.f_breadcrumb) {
      this.change1.emit(false);
    }
  }
  goToFourth() {
    if (this.fi_breadcrumb) {
      this.change2.emit(false);
    }
  }

}
