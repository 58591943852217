import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectMemberComponent } from '../select-member/select-member.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { IMyDpOptions } from 'mydatepicker';

@Component({
  selector: 'app-general-payment-invoice-form',
  templateUrl: './general-payment-invoice-form.component.html',
  styleUrls: ['./general-payment-invoice-form.component.scss']
})
export class GeneralPaymentInvoiceFormComponent implements OnInit {

  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy'
  };

  isSaving: boolean = false;
  paymentInvoiceForm!: FormGroup;
  saved = false;
  requiredMessage = 'This field must not be empty';
  breadcrumbs: any[] = [];
  loggedUser: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private kunyek: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private allInOneService: AllInOneService
  ) { }

  ngOnInit(): void {
    this.loggedUser = this.allInOneService.getKunyekUserName();
    this.paymentInvoiceForm = this.fb.group({
      feetype: ['', Validators.required],
      sender: [{ value: '', disabled: true }, Validators.required],
      amount: ['', [Validators.required, Validators.pattern('^[0-9]+(\.[0-9]+)?$')]],
      paymentdate: ['', Validators.required],
      receiver: [this.loggedUser ? this.loggedUser : '', Validators.required],
      paymenttype: ['', Validators.required]
    });
    this.setBreadcrumbs();
  }

  setBreadcrumbs() {
    this.breadcrumbs = [
      { name: 'Payment', url: 'payment', isActive: true },
      { name: 'General Payment', url: 'members/paymentinvoice', isActive: true },
      { name: 'New', url: '', isActive: false }
    ];
  }

  onSaved() {
    this.isSaving = true;
    this.saved = true;
    const formattedPaymentDate = this.paymentdate?.value?.formatted;
    const paymentData = {
      feetype: this.feetype?.value,
      sender: this.sender?.value,
      amount: this.amount?.value,
      receiver: this.receiver?.value,
      paymentdate: this.allInOneService.convertDate(formattedPaymentDate),
      paymenttype: this.paymenttype?.value
    };

    if (this.paymentInvoiceForm.valid) {
      this.kunyek.getPaymentInvoice().create(paymentData).subscribe({
        next: (res) => {
          this.isSaving = false;
          switch (res.returncode) {
            case '301':
              this.messageService.openSnackBar(res.message, 'warn');
              break;
            default:
              this.messageService.openSnackBar('Success!', 'success');
              this.goBackToList();
          }
        },
        error: (err) => {
          console.error('Error saving payment invoice:', err);
          this.messageService.openSnackBar('Failed to save payment invoice', 'warn');
          this.isSaving = false;
        }
      });
    } else {
      this.messageService.openSnackBar('Invalid Form', 'warn');
      setTimeout(() => {
        this.isSaving = false;
      }, 1000);
    }
  }

  onFeeTypeChange(event: any) {
    const feetype = event.target.value;
    if (!feetype) {
      this.messageService.openSnackBar('Please Select Fee Type', 'warn');
    }
  }

  checkAndOpenMemberModal(feetypeName: string) {
    if (!feetypeName) {
      this.messageService.openSnackBar('Please Select Fee Type', 'warn');
      return;
    }
    this.openMemberModal(feetypeName);
  }

  openMemberModal(feetypeName: string) {
    const dialog = this.dialog.open(SelectMemberComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        feetypeName: feetypeName
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.paymentInvoiceForm.patchValue({ sender: dialogResult.data[0].name });
        this.paymentInvoiceForm.get('sender')?.enable();
      }
    });
  }

  closeForm() {
    this.router.navigate(['payment']);
  }

  goBackToList() {
    this.router.navigateByUrl('members/paymentinvoice');
  }

  get feetype() { return this.paymentInvoiceForm.get('feetype'); }
  get sender() { return this.paymentInvoiceForm.get('sender'); }
  get amount() { return this.paymentInvoiceForm.get('amount'); }
  get paymentdate() { return this.paymentInvoiceForm.get('paymentdate'); }
  get receiver() { return this.paymentInvoiceForm.get('receiver'); }
  get paymenttype() { return this.paymentInvoiceForm.get('paymenttype'); }
}
