<div class="container-fluid">
    <div class="content">
        <div class="d-flex justify-content-between align-items-center">
            <!-- <app-hcm-breadcrumb [s_breadcrumb]="isLate ? 'Late Policy Report' : 'Early Out Policy Report' ">
            </app-hcm-breadcrumb> -->
            <app-team-breadcrumb [s_breadcrumb]="isLate ? 'Late Policy' : 'Early Out Policy' "></app-team-breadcrumb>
            <pagination-controls (pageChange)="currentPage = $event" *ngIf="reportDataList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
            </pagination-controls>
        </div>
        <div class="d-flex flex-wrap align-items-start justify-content-between">
            <div class="mb-3 d-flex gap-2 flex-wrap justify-content-start align-items-center">
                <div class="me-2">

                    <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" [bsConfig]="{containerClass: 'custom-placement'}" readonly [(ngModel)]="submitForm.startdate" style="min-width: 200px;"
                        required #startdate="ngModel" (ngModelChange)="dateChange()">

                    <div class="invalid-feedback" *ngIf="startdate.invalid || (startdate.dirty || startdate.touched)">
                        <div *ngIf="startdate.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>

                <div class="">

                    <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="submitForm.enddate" [minDate]="submitForm.startdate" [maxDate]="minDate" style="min-width: 200px;"
                        required #enddate="ngModel">

                    <div class="invalid-feedback" *ngIf="enddate.invalid || (enddate.dirty || enddate.touched)">
                        <div *ngIf="enddate.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>

                <div class="line g-0"></div>
                <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
                    <input type="text" class="form-control" placeholder="&#xf002; Search by Employee ID/Name" aria-label="Search by Employee ID/Name" (focus)="isFocus = true" (blur)="isFocus = false" (ngModelChange)="searchTextChange()" [(ngModel)]="searchText" [ngClass]="{ 'c-search-input-with-clear': searchText }"
                        style="font-family: FontAwesome, Ubuntu">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
                </div>
                <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-1' : !isMobileView }">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
                    <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
                        <li>
                            <div style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                            <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
                        </li>
                    </ul>
                </div>
                <button type="button" class="btn btn-custom" id="togglefilter" (click)="toggleFilter()">
          <mat-icon>{{openfilter ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </button>

                <div class="line g-0"></div>
                <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>
                <app-button-loader class="" [button_text]="'Search'" [load_button_text]="'Searching'" [isLoading]="searchLoading" (click)="search()">
                </app-button-loader>

                <div class="line g-0"></div>

                <div class="d-flex align-items-center">
                    <span class="total-count-text">Total:&nbsp;</span>
                    <span class="total-count-num">{{ (reportDataList | filter : searchText: 'timecardreport' ).length }}</span>
                </div>

                <div class="input-group mt-2" *ngIf="isMobileView">
                    <input type="text" class="form-control" placeholder="&#xf002; Search by Employee ID/Name" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText" [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
                        (ngModelChange)="searchTextChange()">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
                </div>

            </div>
        </div>
        <div *ngIf="openfilter" class="d-flex gap-3 flex-wrap" style="row-gap: 0 !important">
            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="departmentmenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Department&nbsp;:&nbsp;{{ submitForm.department.length > 20 ? (submitForm.department |
          slice:0:20)+'...' : submitForm.department}}
          <mat-menu #departmentmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let department of departmentList" (click)="departmentChange(department)">
              {{ department }}
            </button>
                </mat-menu>
                <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                </button>
            </div>
            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="divisionmenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Division&nbsp;:&nbsp;{{ submitForm.division.length > 22 ? (submitForm.division |
          slice:0:22)+'...' : submitForm.division}}
          <mat-menu #divisionmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let division of divisionList" (click)="divisionChange(division)">
              {{ division }}
            </button>
                </mat-menu>
                <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                </button>
            </div>
            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="teamidmenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Team ID&nbsp;:&nbsp;{{ submitForm.teamid.length > 22 ? (submitForm.teamid |
          slice:0:22)+'...' : submitForm.teamid}}
          <mat-menu #teamidmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let teamid of teamidList" (click)="teamidChange(teamid)">
              {{ teamid }}
            </button>
                </mat-menu>
                <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                </button>
            </div>

            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="costcenterMenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Cost Center&nbsp;:&nbsp;{{ submitForm.costcenter.length > 22 ? (submitForm.costcenter |
          slice:0:22)+'...' : submitForm.costcenter}}
          <mat-menu #costcenterMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let costcenter of costCenterList" (click)="costCenterChange(costcenter)">
              {{ costcenter }}
            </button>
                </mat-menu>
                <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                </button>
            </div>

            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="subdivisionMenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Sub Division&nbsp;:&nbsp;{{ submitForm.subdivision.length > 22 ? (submitForm.subdivision |
          slice:0:22)+'...' : submitForm.subdivision}}
          <mat-menu #subdivisionMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let subdivision of subDivisionList" (click)="subDivisionChange(subdivision)">
              {{ subdivision }}
            </button>
                </mat-menu>
                <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                </button>
            </div>

            <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="statusMenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Status&nbsp;:&nbsp;{{ submitForm.status == '001' ? 'Active' : 'Inactive' }}
          <mat-menu #statusMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let status of userStatusFilterList" (click)="submitForm.status = status.id">
              {{ status.name }}
            </button>
                </mat-menu>
                </button>
            </div>

        </div>
        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
            <!-- table-borderless -->
            <table class="table table-responsive table-borderless">
                <thead class="table-header">
                    <!-- <tr>
                  <td scope="col" mat-sort-header="employeeid">Employee ID</td>
                  <td scope="col" mat-sort-header="employeename">Name</td>
                  <td scope="col" mat-sort-header="date">Date</td>
                  <td scope="col" mat-sort-header="shift">Shift Time</td>
                  <td scope="col" mat-sort-header="time">{{ isLate ? 'Actual Time In' : 'Actual Time Out' }}</td>
                  <td scope="col" mat-sort-header="latetime">{{ isLate ? 'Late Time' : 'Early Out Time' }}</td>
                  <td scope="col" [mat-sort-header]="isLate ? 'requestedlatetime' : 'earlyoutrequestedlatetime' ">{{ isLate ?
                    'Requested Late Time' : 'Requested Early Out Time' }}</td>
                  <td scope="col" mat-sort-header="requeststatus">Request Status</td>
                </tr> -->
                    <tr>
                        <td scope="col" mat-sort-header="employeeid" rowspan="2" class="text-start">Employee</td>
                        <td scope="col" mat-sort-header="username" rowspan="2" class="text-start">Username</td>
                        <ng-container *ngFor="let count of titleArray">
                            <td scope="col" colspan="2" class="text-center">{{ (isLate ? 'Late ' : 'Early Out ') + (count+1) }}</td>
                        </ng-container>
                        <td scope="col" rowspan="2" class="text-center">Leave Count</td>
                    </tr>
                    <tr>
                        <ng-container *ngFor="let count of titleArray">
                            <td scope="col" class="text-center">{{isLate ? 'Late ' : 'Early Out '}}Count</td>
                            <td scope="col" class="text-center d-flex justify-content-center">Leave Count</td>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngIf="reportDataList.length == 0">
                        <td colspan="10" class="nodata">Empty</td>
                    </tr>
                    <tr *ngFor="let data of reportDataList | filter:searchText : 'timecardreport' | paginate : {itemsPerPage : itemPerPage , currentPage : currentPage}; let index = index ">
                        <td>
                            <span [title]="data.employeeid">{{ data.employeeid }}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="data.username ? data.username : data.userid ">{{ data.username ? data.username :
                data.userid}}</span>
                        </td>

                        <ng-container *ngFor="let count of titleArray">
                            <td class="text-center">
                                <span>{{ data['time'+(count+1)].count }}</span>
                            </td>
                            <td class="text-center">
                                <span>{{ data['time'+(count+1)].leave }}</span>
                            </td>
                        </ng-container>

                        <td class="text-center">
                            <span [title]="data.leavecount">{{ data.leavecount }}</span>
                        </td>


                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>