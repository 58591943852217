import { Component} from '@angular/core';
import ListConfig from 'src/app/shared/interfaces/listconfig';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-guest-list',
  templateUrl: './guest-list.component.html',
  styleUrls: ['./guest-list.component.scss']
})
export class GuestListComponent {

  
  configs: ListConfig[] = [
    { title: 'Name', property: 'name' },
    { title: 'Date', property: 'date',type:'date' },
    { title: 'Time', property: 'time',type: 'time' },
    { title: 'Person Count', property: 'guest_count' }
];

  url = ['/members/guestlist']
  searchName: string = '';
  searchText = "Search Guest"
  cb = this.kunyekService.guestMember().get.bind(this.kunyekService)
  breadcrumbs: any[] = [
    { name: 'Swim', url: 'swim', isActive: true },
    { name: 'Guest', url: '', isActive: false }
  ];

  constructor(
    private kunyekService: KunyekService,
  ) { }

}
