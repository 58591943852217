<div class="m-4">
  <div class="me-auto">
    <app-kmember-breadcrumb [s_breadcrumb]="'Member Information'">
    </app-kmember-breadcrumb>
  </div>

  <div class="d-flex me-2" *ngIf="!isLoading">
    
    <div class="d-flex ms-auto gap-2">
      <div class="btn status-green" *ngIf="isMember">
        Valid From  ({{validFromDate}})  To  ({{validToDate}})
      </div>

      <div class="btn status-gray" *ngIf="currentMember && currentMember.paymentstatus == false">
        Unpaid
      </div>
  
      <div class="btn status-green" *ngIf="currentMember && currentMember.paymentstatus == true">
        Paid
      </div>
  
      <div class="btn status-gray" *ngIf="currentMember && currentMember.formstatus == '001'">
        Submitted
      </div>
  
      <div class="btn status-green" *ngIf="currentMember && currentMember.formstatus == '002'" (click)="openHistory()">
        Approved
      </div>
    </div>
   
  </div>

  <div class="d-flex " *ngIf="!isLoading && isRegistered">
    <div class="col-md-6 p-2">
      <div class="card card-body member-card">
        <h3>A-Member</h3>
        <div class="row">
          <div class="col-md-4">
            <div class="profile-img-container">
              <div style="position: relative">
                <div class="uploadfilecontainer" appDragDrop>
                  <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                    [lazyLoad]="profileUrl ? profileUrl : '../../../assets/images/uploadphoto.png'" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 row" style="height: 250px;">
            <div class="col-md-6 mb-2">
              အမည်
            </div>
            <div class="col-md-6 mb-2">
              {{currentMember.name}}
            </div>
            <div class="col-md-6 mb-2">
              နိုင်ငံသားမှတ်ပုံတင်အမှတ်
            </div>
            <div class="col-md-6 mb-2">
              {{currentMember.nrc}}
            </div>
            <div class="col-md-6 mb-2">
              မွေးနေ့
            </div>
            <div class="col-md-6 mb-2">
              {{allInOneservice.formatDBToShow(currentMember.birthdate)}}
            </div>
            <div class="col-md-6 mb-2">
              ကဒ်အမျိုးအစား
            </div>
            <div class="col-md-6 mb-2">
              {{currentMember.memberType.name}}
            </div>
            <div class="col-md-6 mb-2">
              ဖုန်းနံပါတ်
            </div>
            <div class="col-md-6 mb-2">
              {{currentMember.phone}}
            </div>
            <!-- <div class="col-md-6 mb-2">
              ကဒ်နံပါတ်
            </div>
            <div class="col-md-6 mb-2 dashed-line">
              {{memberAcardNo}}               
            </div>
            <div class="col-md-6 mb-2">
              Status
            </div>
            <div class="col-md-6 mb-2" [ngClass]="{
              'active': currentMember.status == '004',
              'inactive': currentMember.status != '004'
            }">
              {{currentMember.status == '004'? 'Active': 'Inactive'}}               
            </div> -->
            <div class="col-md-6 mb-2" *ngIf="currentMember.registration">
              ကဒ်နံပါတ်
            </div>
            <div class="col-md-6 mb-2 dashed-line" *ngIf="currentMember.registration">
              {{currentMember.registration.membercard_id}}               
            </div>
            <div class="col-md-6 mb-2" *ngIf="currentMember.registration">
              Status
            </div>
            <div class="col-md-6 mb-2" *ngIf="currentMember.registration" [ngClass]="{
              'active': currentMember.registration && currentMember.registration.status == '001',
              'inactive': currentMember.registration && currentMember.registration.status != '001'
            }">
              {{currentMember.registration && currentMember.registration.status == '001'? 'Active': 'Inactive'}}               
            </div>

          </div>
        </div>
      </div>
      <!-- <button (click)="viewHistory(currentMember)">View History</button>
        <button (click)="managePayment(currentMember)">Manage Payment</button> -->
    </div>

    <div class="col-md-6 p-2">
      <div class="">
        <h3>B-Members</h3>
        <div style="height: 400px; overflow-y: auto;">
          <div *ngFor="let member of relations" class="card card-body mb-2">
            <div class="row">
              <div class="col-md-12">
                <div class="profile-img-container">
                  <div style="position: relative">
                    <div class="uploadfilecontainer" appDragDrop>
                      <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="member.profileimage ? member.profileUrl : '../../../assets/images/uploadphoto.png'" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-2">
                အမည်
              </div>
              <div class="col-md-6 mb-2">
                {{member.name}}
              </div>
              <div class="col-md-6 mb-2">
                နိုင်ငံသားမှတ်ပုံတင်အမှတ်
              </div>
              <div class="col-md-6 mb-2">
                {{member.nrc}}
              </div>
              <div class="col-md-6 mb-2">
                မွေးနေ့
              </div>
              <div class="col-md-6 mb-2">
                {{allInOneservice.formatDBToShow(member.birthdate)}}
              </div>
              <div class="col-md-6 mb-2">
                ကဒ်အမျိုးအစား
              </div>
              <div class="col-md-6 mb-2">
                {{member.memberType.name}}
              </div>
              <div class="col-md-6 mb-2">
                ဖုန်းနံပါတ်
              </div>
              <div class="col-md-6 mb-2">
                {{member.phone}}
              </div>
              <div class="col-md-6 mb-2" *ngIf="member.registration">
                ကဒ်နံပါတ်
              </div>
              <div class="col-md-6 mb-2 dashed-line" *ngIf="member.registration">
                {{member.registration.membercard_id}}               
              </div>
              <div class="col-md-6 mb-2" *ngIf="member.registration">
                Status
              </div>
              <div class="col-md-6 mb-2" *ngIf="member.registration" [ngClass]="{
                'active': member.registration && member.registration.status == '001',
                'inactive': member.registration && member.registration.status != '001'
              }">
                {{member.registration && member.registration.status == '001'? 'Active': 'Inactive'}}               
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <button (click)="viewHistory(member)">View History</button>
        <button (click)="managePayment(member)">Manage Payment</button> -->
    </div>
  </div>

  <div *ngIf="isLoading" class="d-flex justify-content-center h-100">
    <div class="spinner-border" entitle="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div *ngIf="!isLoading && !isRegistered" class="d-flex justify-content-center">
    <div>
      Not Registered Yet
    </div>
  </div>
</div>