import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-meeting-title',
  templateUrl: './meeting-title.component.html',
  styleUrls: ['./meeting-title.component.css'],
})
export class MeetingTitleComponent implements OnInit {
  meetingtitle: string = '';
  loading: boolean = false;
  formSubmitted: boolean = false;
  invalidtitle: boolean = false;
  openedChat: any;

  constructor(
    public dialogRef: MatDialogRef<MeetingTitleComponent>,
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.openedChat = data.chat;
    // if(this.openedChat.type == '2'){
    //   this.meetingtitle = this.openedChat.channelname;
    // }
    // else if(this.openedChat.type = '1'){
    //   this.meetingtitle = this.openedChat.username
    // }
    // else{
    //   this.meetingtitle = this.openedChat.userlist[0].username
    // }
  }

  ngOnInit(): void {
    this.meetingtitle = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < 2; i++) {
      this.meetingtitle += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    for (let i = 0; i < 8; i++) {
      this.meetingtitle += Math.floor(Math.random() * 10).toString();
    }
  }

  containsSpecialChars(str: string) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  onConfirm(): void {
    this.formSubmitted = true;
    if(this.containsSpecialChars(this.meetingtitle)){
      // this.messageService.openSnackBar("Please enter valid title.", 'warn');
      this.invalidtitle = true;
      return;
    }
    if (this.meetingtitle != '') {
      this.loading = true;
      const current_date = this.allinoneService.getCurrentDateToDB();
      const current_time = this.allinoneService.getCurrentTimeToDB();
      const data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        contact: this.openedChat.userid ? this.openedChat.userid : '',
        channelname: '',
        date: current_date,
        time: current_time,
        message: '',
        imagename: '',
        imagedata: '',
        imagelist: '',
        tmpimage: '',
        channelid: this.openedChat.channelid
          ? this.openedChat.channelid != ''
            ? this.openedChat.channelid
            : '0'
          : '0',
        name:
          this.allinoneService.getKunyekUserName() != ''
            ? this.allinoneService.getKunyekUserName()
            : this.allinoneService.getKunyekUserId(),
        mention: [],
        eventid: '',
        replysyskey: '',
        filename: '',
        notimessage: '',
        meetingtitle: this.meetingtitle.trim(),
      };
      this.kunyekService.sendMessage(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            const url = res.jitsiurl;
            const data = res.list ? res.list[0] : res.channelid;
            window.open(url, '_blank');
            this.dialogRef.close(data);
          } else {
            this.messageService.openSnackBar(
              res.message || 'Server Error',
              'warn'
            );
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
        }
      );
    }
  }

  onDismiss(): void {
    this.dialogRef.close('');
  }
}
