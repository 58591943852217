<div class="container-fluid">
    <div class="content" *ngIf="!isOpenForm">
        <div class="me-auto">
            <!-- <app-kmember-breadcrumb s_breadcrumb="Monthly Fees"></app-kmember-breadcrumb> -->
            <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
        </div>

        <div class="d-flex">
            <div class="input-group mb-2 me-2" style="width: 300px !important">
                <input [(ngModel)]="searchName" class="form-control" type="text" placeholder="&#xf002; Search with Name"
                    aria-label="Search" style="font-family: FontAwesome, Ubuntu"
                    (keyup.enter)="getMonthlyFeesTypeList()">
                <button class="btn btn-custom me-2" matTooltip="Refresh" (click)="getMonthlyFeesTypeList()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
            <button class="btn btn-custom mb-2" (click)="newMonthlyeFees()">New</button>
        </div>
        <table class="table table-responsive table-borderless">
            <thead class="table-header">
                <tr>
                    <th>Name</th>
                    <th>Member Type</th>
                    <th>Short Code</th>
                    <!-- <th></th> -->
                </tr>
            </thead>
            <tbody *ngIf="!gettingMonthlyFeesTypeList && monthlyFeesTypeList.length>0">
                <tr *ngFor="let monthlyFeesType of monthlyFeesTypeList" style="cursor: pointer;">
                    <td (click)="editMonthlyFeetype(monthlyFeesType)">{{monthlyFeesType.description}}</td>
                    <td (click)="editMonthlyFeetype(monthlyFeesType)">{{monthlyFeesType.memberType?.name}}</td>
                    <td (click)="editMonthlyFeetype(monthlyFeesType)">{{monthlyFeesType.shortcode}}</td>
                    <!-- <td style="width: 80px;">
                        <button class="btn btn-outline-danger" (click)="deleteMonthlyFee(monthlyFeesType.monthlyfee_id)">
                            <i class='fa fa-trash-o'></i>
                        </button>
                    </td> -->
                </tr>
            </tbody>
            <tbody *ngIf="!gettingMonthlyFeesTypeList && monthlyFeesTypeList.length == 0">
                <tr>
                    <td colspan="6" class="nodata">
                        Empty
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="gettingMonthlyFeesTypeList">
                <tr>
                    <td colspan="6" class="nodata">
                        <div class="spinner-border" entitle="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <div class="content" *ngIf="isOpenForm">
        <app-member-register [currentMember]="currentMember" [status]="action"
            (closeFormHandler)="closeMemberForm()"></app-member-register>
    </div> -->
    <div class="content" *ngIf="isOpenForm">
        <app-monthlyfeesetup (closeFormHandler)="closeMemberForm()" [selectedMFT]="selectedMonthlyFeesType"
            [isEdit]="isEdit"></app-monthlyfeesetup>
    </div>
</div>