import { Component, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-counts',
  templateUrl: './counts.component.html',
  styleUrls: ['./counts.component.scss']
})
export class CountsComponent implements OnInit {

  domain: any;
  orgImage: any;
  selectedOrg: any = "";
  domainCount : any[] = []
  orgCount : any[] = []
  orgList : any[] = []

  gettingDomainCount : boolean = false
  gettingOrgCount : boolean = false
  gettingOrgList : boolean = false

  public pieChartOptions: ChartOptions = {
    responsive: false,
  };
  public pieChartLabels: Label[] = ['Others', 'Today'];
  public pieChartDomainData: SingleDataSet = [0,0];
  public pieChartOrgData: SingleDataSet = [0,0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = false;
  public pieChartPlugins = [];
  public pieChartColors = [
    {
      backgroundColor: [ '#11698E', '#16C79A'],
    },
  ];

  constructor(
    public allinoneService : AllInOneService,
    public kunyekService : KunyekService,
    public messageService : MessageService,
  ) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
   }

  ngOnInit(): void {
    this.domain = this.allinoneService.getDomain() 
    this.getDomainCount()
    this.getDomainList()
    this.getOrganization()
  }

  getDomainCount(){
    this.gettingDomainCount = true
    const data = {
      "domain" : this.domain.shortcode,
      "domainid" : this.domain.domainid
    }
    this.kunyekService.getDomainCounts(data).subscribe((res: any) => {
      if(res.returncode == "300"){
        this.gettingDomainCount = false
        this.domainCount = [res.total, res.today]
        this.pieChartDomainData = [res.total-res.today , res.today]
      }
      else{ 
        this.gettingDomainCount = false
        this.messageService.openSnackBar(res.message, 'warn')
      }
    }, err => {
      this.gettingDomainCount = false
    }
    )
  }

  getOrganization() {
    this.gettingOrgCount = true
    this.gettingOrgList = false
    this.kunyekService.getOrgByDomainId(this.domain.domainid).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.orgList = res.datalist
        this.selectedOrg = this.orgList[0]
        this.gettingOrgList = false
        this.getOrgCount()
      }
      else {
        this.gettingOrgList = false
        this.gettingOrgCount = false
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err => {
      this.gettingOrgList = false
      this.gettingOrgCount = false
    }
    )
  }

  orgChanged(index : any){
    var tempSelectedOrg = this.orgList[index]
    if(this.selectedOrg.orgid != tempSelectedOrg.orgid){
      this.selectedOrg = tempSelectedOrg
      this.getOrgCount()
    }
  }

  getOrgCount(){
    this.gettingOrgCount = true
    const data = {
      "domain" : this.domain.shortcode,
      "domainid" : this.domain.domainid,
      "orgid" : this.selectedOrg.orgid
    }
    this.kunyekService.getOrgCounts(data).subscribe((res: any) => {
      if(res.returncode == "300"){
        this.gettingOrgCount = false
        this.orgCount = [res.total, res.today]
        this.pieChartOrgData = [res.total-res.today, res.today]
      }
      else{ 
        this.gettingOrgCount = false
        this.messageService.openSnackBar(res.message, 'warn')
      }
    }, err => {
      this.gettingOrgCount = false
    }
    )
  }

  getDomainList(): void {
    this.kunyekService.getDomains().subscribe((res: any) => {
      if (res.returncode == "300") {
        const index = res.domains.findIndex((x: any) => x.domainid == this.domain.domainid)
        this.domain = res.domains[index]
        console.log("Domain Changed")
      }
      else {
        
      }
    }, err => {
     
    })
  }

}
