import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownButtonComponent),
      multi: true,
    },
  ],
})
export class DropdownButtonComponent implements ControlValueAccessor, OnInit {

  value = "";
  name = "All"
  @Input('bindLabel') bindLabel = ''
  @Input('bindValue') bindValue = ''
  @Input('name') label: string = '';
  @Input('items') items: any = [];
  searchVal: string = ''

  onChange = (value: any) => {};
  onTouched = () => {};

  ngOnInit() {

  }

  handleSelect(item: any) {
    this.name = item == "" ? 'All' : item[this.bindLabel];
    this.value = item[this.bindValue] !== undefined ? item[this.bindValue] : "";
    this.onChange(this.value);
    console.log('value', this.value)
  }

  writeValue(value: any): void {
    this.value = value;
    console.log(value, 'write')
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  clearSearch() {
    this.searchVal = ""
  }
}
