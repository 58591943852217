import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import { ClasscodeComponent } from 'src/app/admin/classcode/classcode.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-logistic-orders-batches',
  templateUrl: './logistic-orders-batches.component.html',
  styleUrls: ['./logistic-orders-batches.component.scss']
})
export class LogisticOrdersBatchesComponent implements OnInit,OnDestroy {
  panelOpenState = false;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  formSubmitted: boolean = false;
  gettingOrder: boolean = false;
  gettingBatches: boolean = false;
  showorder: boolean = false;
  showOrderList: boolean = false;
  showOrderDetails: boolean = false;
  showDetails: boolean = false;
  isAllOrder: boolean = false;
  isAllBatch: boolean = false;
  order: any;
  orderSubscription!: Subscription;
  batchSubscription!: Subscription;

  endOrder = '';
  endBatches = '';
  orderSearch = '';
  batchSearch = '';
  mytimeoutBatch: any;
  mytimeoutOrder: any;
  orderForm = {
    domainid: this.allinoneService.getDomain().domainid,
    orgid: this.allinoneService.selectedOrg,
    status: '',
    searchorder: '',
    end: '',
  };
  orders: any = [];
  batches: any = [];
  selectedOrder: any = [];
  selectedBatches: any = [];
  selectedImageUrl: string = '';
  selectedImageIndex: number = 0;
  currentTap = "orders"; 
  tabBtnClassOrder = "nav-link active"
  tabBtnClassBatches = "nav-link"
  tabClassOrder  = "tab-pane fade show active";
  tabClassBatches = "tab-pane fade";

  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'New',
      value: '001',
    },
    {
      name: 'Pending',
      value: '002',
    },
    {
      name: 'On the way',
      value: '003',
    },
    {
      name: 'Transit',
      value: '004',
    },
    {
      name: 'Delivered',
      value: '005',
    },
    {
      name: 'Approved',
      value: '006',
    },
  ];

  constructor(
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public route: ActivatedRoute,
    private dialogRef: MatDialogRef<ClasscodeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedOrder = JSON.parse(JSON.stringify(data.orderlist));
    this.selectedBatches = JSON.parse(JSON.stringify(data.batchlist));
    this.showDetails = data.showDetails;
    this.order = data.order;
    this.currentTap = data.curTab;
  }
  ngOnDestroy(): void {
    this.batchSubscription && this.batchSubscription.unsubscribe();
    this.orderSubscription && this.orderSubscription.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.showDetails) {
      this.getOrder();
      this.getBatches();
    } else {
      this.showOrderDetails = true;
      this.showDetails = true;
      this.order.statusName = this.statusList.find(
        (item: any) => item.value == this.order.status
      )?.name;
      if (this.order.image.imgurl.length > 0) {
        this.selectedImageUrl = this.order.image.imgurl[0];
        this.selectedImageIndex = 0;
      }
    }
  }

  onScrolledDown() {
    if (!this.isAllOrder) {
      this.getOrder();
    }
    if (!this.isAllBatch) {
      this.getBatches();
    }
  }

  goBack() {
    this.showDetails = false;
    this.showOrderDetails = false;

    if (this.currentTap == 'batches'){
    this.tabBtnClassBatches = "nav-link active"
    this.tabBtnClassOrder = "nav-link"
    this.tabClassBatches  = "tab-pane fade show active"
    this.tabClassOrder  = "tab-pane fade"
    }

  }
  viewOrderDeails(index: any) {
    this.currentTap = "orders";
    this.showOrderDetails = true;
    this.showDetails = false;
    this.order = this.orders[index];
    this.order.statusName = this.statusList.find(
      (item: any) => item.value == this.order.status
    )?.name;
  }
  viewOrderDeailsInBatch(orderindex: any, batchindex: any) {
    this.currentTap = "batches";
    this.showOrderDetails = true;
    this.showDetails = false;
    this.order = this.batches[batchindex]['orderlist'][orderindex];
    this.order.statusName = this.statusList.find(
      (item: any) => item.value == this.order.status
    )?.name;
  }

  close() {
    this.dialogRef.close({ status: false, data: [] });
  }
  save() {
    this.dialogRef.close({ status: true, data: [
      {
        "orders" :this.selectedOrder,
        "batches": this.selectedBatches
      }
    ] });
  }

  selectOrder(event: any, order: any) {
    var index = this.orders.findIndex((od: any) => od.orderno == order.orderno);
    if (index != -1) {
      this.orders[index].checked = !this.orders[index].checked;
    }
    if (event.target.checked) {
      var index = this.selectedOrder.findIndex(
        (od: any) => od.orderno == order.orderno
      );
      if (index == -1) {
        order.checked = true;
        this.selectedOrder.push(order);
      } else {
        this.selectedOrder[index].checked = true;
      }
    } else {
      var index = this.selectedOrder.findIndex(
        (od: any) => od.orderno == order.orderno
      );
      if (index != -1) {
        this.selectedOrder[index].checked = false;
      }
    }
  }

  selectBatches(event: any, batches: any) {
    var index = this.batches.findIndex((bt: any) => bt.batchno == batches.batchno);
    if (index != -1) {
      this.batches[index].checked = !this.batches[index].checked;
    }
    if (event.target.checked) {
      var index = this.selectedBatches.findIndex(
        (bt: any) => bt.batchno == batches.batchno
      );
      if (index == -1) {
        batches.checked = true;
        this.selectedBatches.push(batches);
      } else {
        this.selectedBatches[index].checked = true;
      }
    } else {
      var index = this.selectedBatches.findIndex(
        (bt: any) => bt.batchno == batches.batchno
      );
      if (index != -1) {
        this.selectedBatches[index].checked = false;
      }
    }
  }

  searchChangeOrders() {
    this.endOrder = '';
    this.orders = [];
    clearTimeout(this.mytimeoutOrder);
    this.mytimeoutOrder = setTimeout(() => {
      this.getOrder();
    }, 500);
  }

  searchChangeBatches() {
    this.endBatches = '';
    this.batches = [];
    clearTimeout(this.mytimeoutBatch);
    this.mytimeoutBatch = setTimeout(() => {
      this.getBatches();
    }, 500);
  }


  getOrder() {
    this.gettingOrder = true;
    const data = {
      domainid: this.orderForm.domainid,
      orgid: this.orderForm.orgid,
      status: '',
      searchorder: this.orderSearch,
      end: this.endOrder,
      batch: true,
    };
    this.orderSubscription && this.orderSubscription.unsubscribe();

    this.orderSubscription = this.kunyekService
      .getLogisticsOrders(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            // this.orders
            var temp = res.list;
            if (res.list.length < 10) {
              this.isAllOrder = true;
            }
            this.endOrder = res.end;
            if (this.selectedOrder.length > 0) {
              for (var i = 0; i < temp.length; i++) {
                var res = this.selectedOrder.filter(
                  (x: any) => x.orderno == temp[i].orderno && x.checked
                );
                if (res.length > 0) {
                  temp[i].checked = true;
                }
              }
            } else {
              temp = temp.map((item: any) => ({
                ...item,
                checked: false,
              }));
            }
            this.orders.push.apply(this.orders, temp);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingOrder = false;
        },
        (err) => {
          this.gettingOrder = false;
        }
      );
  }

  getBatches() {
    this.gettingBatches = true;
    const data = {
      domainid: this.orderForm.domainid,
      orgid: this.orderForm.orgid,
      status: '',
      searchbatch: this.batchSearch,
      end: this.endBatches,
      batch: false,
      assignment: true,
    };
    this.batchSubscription && this.batchSubscription.unsubscribe();

    this.batchSubscription = this.kunyekService
      .getAllBatchs(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            var temp = res.list;
            if (res.list.length < 10) {
              this.isAllBatch = true;
            }
            this.endBatches = res.end;
            if (this.selectedBatches.length > 0) {
              for (var i = 0; i < temp.length; i++) {
                var res = this.selectedBatches.filter(
                  (x: any) => x.batchid == temp[i].batchid && x.checked
                );
                if (res.length > 0) {
                  temp[i].checked = true;
                }
              }
            } else {
              temp = temp.map((item: any) => ({
                ...item,
                checked: false,
              }));
            }
            this.batches.push.apply(this.batches, temp);
            console.log("Batches >>>");

            console.log(this.batches);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingBatches = false;
        },
        (err) => {
          this.gettingBatches = false;
        }
      );
  }

  nextImage(imgList: any) {
    if (this.selectedImageIndex < imgList.length - 1) {
      this.selectedImageIndex += 1;
    } else {
      this.selectedImageIndex = 0;
    }
  }
  previousImage(imgList: any) {
    if (this.selectedImageIndex > 0) {
      this.selectedImageIndex -= 1;
    } else {
      this.selectedImageIndex = imgList.length - 1;
    }
  }


}
