<div class="cc-container">
  <div class="d-flex justify-content-between align-items-center mb-2">
    <div class="label title">Position</div>
    <div class="close-btn">
      <button mat-icon-button mat-dialog-close [disabled]="saveLoading">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="mb-2 d-flex header">
    <div class="d-flex align-items-center">
      <ng-container *ngIf="!classCodeAddOrNot">
        <button type="button" class="btn btn-custom me-2" (click)="classCodeAddOrNot = true">
          <i class="fa fa-plus me-1"></i>Add Position
        </button>

        <button type="button" class="btn btn-custom" (click)="importPosition()" title="Import from Excel">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Import
        </button>
        <div class="line"></div>
        <div class="input-group" style="width: 250px !important">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Post/Department/Division"
              aria-label="Search by Floor" (focus)="isFocus = true" (blur)="isFocus = false"
             [(ngModel)]="searchText"
              [ngClass]="{ 'c-search-input-with-clear': searchText }"
              style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
              [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
              <mat-icon class="c-icon">close</mat-icon>
          </button>
      </div>
      <div class="line"></div>
      <div class="d-flex align-items-center">
        <span class="total-count-text">Total:&nbsp;</span>
        <span class="total-count-num">{{ (ccList | filter : searchText: 'hierarchyPosition' ).length }}</span>
      </div>
      </ng-container>


      <div *ngIf="classCodeAddOrNot" class="close-save-button">
        <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
          Cancel
        </button>
        <app-button-loader (click)="submitCc()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
    </div>
  </div>

  <form class="my-3">
    <div *ngIf="classCodeAddOrNot && isImporting">
      <div class="mb-3">
        <label for="file" class="form-label">Excel Template (<span class="cc-label"
                (click)="downloadSampleFile()">Download Sample Template</span>)</label>
        <div class="input-group">
            <input class="form-control" type="file" (change)="onExcelFileSelect($event)" accept=".xlsx"
                id="formFile" #excelFile [disabled]="excel">
            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="excel"
                (click)="removeExcel()">Remove</button>
            <button class="input-group-text btn btn-custom" style="border-left: 1px solid white;"
                id="btnGroupAddon2" [disabled]="!excel || checkingExcelFile" (click)="checkExcelFile()">
                <span *ngIf="!checkingExcelFile">
                    Check
                </span>
                <span *ngIf="checkingExcelFile">
                    <span class="spinner-border spinner-border-sm button-spinner" role="status"
                        aria-hidden="true"></span>
                    <span class="mx-1">Checking</span>
                </span>
            </button>
        </div>
    </div>
    <div style="color : green" *ngIf="allok">
        <i class="fa fa-check me-2"></i> Template is correct.
    </div>
    <div class="d-flex flex-wrap mt-3" *ngIf="!checkingExcelFile">
        <div class="col-md-6 pe-1" *ngIf="errorList.length > 0">
          <div class="alert alert-danger custom-box" role="alert" >
            <div class="d-flex align-items-center">
                <mat-icon class="me-2">
                    error
                </mat-icon>
                <div>
                    Error
                </div>
            </div>
            <hr>
            <ul style="padding-left: 1rem;">
                <li *ngFor="let e of errorList;">
                    <span *ngIf="e.line">In line no. {{e.line}},&nbsp;</span>{{e.error}}
                </li>
            </ul>
        </div>
        </div>

        <div class="col-md-6 ps-1" *ngIf="warningList.length >0">
          <div class="alert alert-warning custom-box">

            <div class="d-flex align-items-center">
                <mat-icon class="me-2">
                    warning
                </mat-icon>
                <div>
                    Warning
                </div>
            </div>
            <hr>

            <ul style="padding-left: 1rem;">
                <li *ngFor="let e of warningList;">
                    In line no. {{e.line}}, {{e.error}}
                </li>
            </ul>


        </div>
        </div>
        <!-- </div> -->
    </div>
    </div>
    <div *ngIf="classCodeAddOrNot && !isImporting">
      <div class="mb-3">
        <label for="newcc" class="form-label">Post</label>

        <input type="text" class="form-control" id="newcc" name="newcc" [(ngModel)]="ccForm.classcode" required
          placeholder="Enter new post" #cc="ngModel" [ngClass]="{ 'is-invalid': ccFormSubmitted && cc.invalid }"
          (keyup.enter)="submitCc()">

        <div class="invalid-feedback" *ngIf="cc.invalid || (cc.dirty || cc.touched) && ccFormSubmitted">
          <div *ngIf="cc.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="department" class="form-label">Department</label>

        <input type="text" class="form-control" id="department" name="department" [(ngModel)]="ccForm.department"
          required placeholder="Enter department">

      </div>
      <div class="mb-3">
        <label for="division" class="form-label">Division</label>

        <input type="text" class="form-control" id="division" name="division" [(ngModel)]="ccForm.division" required
          placeholder="Enter division">

      </div>

    </div>
  </form>
  <div *ngIf="!classCodeAddOrNot" class="table-responsive mt-2" matSort (matSortChange)="sortData($event)">
    <table class="table table-responsive table-borderless table-fixed">
      <thead class="table-header">
        <tr>
          <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4" mat-sort-header="name">Post
          </td>
          <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4" mat-sort-header="department">Department
          </td>
          <td scope="col" class="col-lg-3 col-md-3 col-sm-3 col-3" mat-sort-header="division">Division
          </td>
 
          <td scope="col" class="d-flex justify-content-center">Action</td>
        </tr>
      </thead>
      <tbody *ngIf="gettingCcList">
        <tr>
          <td colspan="3" class="nodata">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody *ngIf="!gettingCcList" #tbody>
        <tr *ngIf="ccList.length == 0">
          <td colspan="3" class="nodata">Empty</td>
        </tr>

        <tr *ngFor="let cc of ccList | filter : searchText: 'hierarchyPosition'; let i = index;">
          <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4 td-edit td-data" (click)="editClasscode(cc)"
            title="{{cc.name}}">{{cc.name}}
          </td>
          <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4 td-edit td-data" (click)="editClasscode(cc)"
            title="{{cc.department}}">{{cc.department}}
          </td>
          <td scope="col" class="col-lg-3 col-md-3 col-sm-3 col-3 td-edit  td-data" (click)="editClasscode(cc)"
            title="{{cc.division}}">{{cc.division}}
          </td>

          <td class="d-flex justify-content-center">
            <button class="btn btn-outline-primary" (click)="editClasscode(cc)">
              <i class='fa fa-edit'></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>