<div class="cc-container">

  <!-- <app-breadcrumb *ngIf="!isviewornew && !isFromMemberCreate"
            [s_breadcrumb]="isFromOrganization ? 'Organizations' : 'Members'" t_breadcrumb="Roles" (emit)="gotoOrg()">
        </app-breadcrumb>
        <app-breadcrumb *ngIf="isviewornew && !isFromMemberCreate"
            [s_breadcrumb]="isFromOrganization ? 'Organizations' : 'Members'" t_breadcrumb="Roles"
            [f_breadcrumb]="isEditing ? 'Role' : 'New Role'" (emit)="gotoOrg()" (emit1)="cancel()">
        </app-breadcrumb> -->

  <div class="d-flex justify-content-between align-items-center mb-2">
    <div class="label title">Role</div>
    <div class="close-btn">
      <button mat-icon-button mat-dialog-close [disabled]="saveLoading">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="close-save-button mb-3" *ngIf="isviewornew">
    <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
      Cancel
    </button>
    <app-button-loader (click)="submitRule()" [button_text]="'Save'" [load_button_text]="'Saving'"
      [isLoading]="saveLoading">
    </app-button-loader>
  </div>

  <div class="row row-custom header" *ngIf="!isviewornew">
    <div class="col-md-6 mb-3 d-flex">
      <button class="btn btn-custom me-2" (click)="newRole()">
        <i class="fa fa-plus me-1"></i> Role
      </button>
    </div>
  </div>

  <div *ngIf="isviewornew">
    <form action="" class="row">

      <div class="mb-3 col-md-6">
        <label for="type" class="form-label">
          Type
        </label>
        <select class="form-select" id="type" name="type" (change)="typeChange($event)" [(ngModel)]="roleTypeID"
          required #type="ngModel" [ngClass]="{ 'is-invalid': roleFormSubmitted && type.invalid }">
          <option *ngFor="let tl of roleTypeList; trackBy: trackById" value="{{tl.id}}"
            [selected]="tl.id == roleTypeID">{{tl.name}}
          </option>
        </select>
        <div class="invalid-feedback" *ngIf="type.invalid || (type.dirty || type.touched) && roleFormSubmitted">
          <div *ngIf="type.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>


      <div class="mb-3 col-md-6">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="roleName" required #name="ngModel"
          [ngClass]="{ 'is-invalid': roleFormSubmitted && name.invalid }">
        <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && roleFormSubmitted">
          <div *ngIf="name.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>

      <div class="mb-3 col-md-12">
        <label for="remark" class="form-label">Description</label>
        <textarea class="form-control" id="remark" name="remark" [(ngModel)]="roleDesc" rows="3"></textarea>
      </div>
      <div class="col-md-12" *ngIf="roleTypeID == '001'">
        <label for="remark" class="form-label">Permission</label>
        <div class="card card-body">
          <div class="row g-0">
            <ng-container *ngFor="let op of officePermission; let index = index">
              <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                <input class="form-check-input" type="checkbox" [id]="op.id" [name]="op.id" [(ngModel)]="op.checked">
                <label class="form-check-label" [for]="op.id">
                  {{op.name}}
                </label>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="roleTypeID == '002'">
        <label for="remark" class="form-label">Permission</label>
        <div class="card card-body">
          <table class="table table-borderless">
            <thead class="table-header">
              <tr>
                <td scope="col" class="col-lg-2 col-md-2"></td>
                <td scope="col" class="col-lg-2 col-md-2"></td>
                <td scope="col" class="col-lg-1 col-md-1">View</td>
                <td scope="col" class="col-lg-1 col-md-1">Create</td>
                <td scope="col" class="col-lg-1 col-md-1">Edit</td>
                <td scope="col" class="col-lg-1 col-md-1">Delete</td>
              </tr>
            </thead>
            <tbody>
              <!-- <div *ngFor="let item of PermissionList"> -->
              <tr *ngFor="let i of list;let j = index;" class="workflow-permission">
                <td>
                  {{i.title}}
                </td>
                <!-- <td>
                        <div class="form-check " *ngIf="i.title">
                            <input class="form-check-input" type="checkbox" id="{{i.title}}" value="i.title" name="check" (click)="checkALL($event,i,j)">
                            <label class="form-check-label mb-1 ms-1" for="{{i.title}}">
                                {{i.title}}
                            </label>
                        </div>
                    </td> -->
                <td>
                  <div class="form-check " *ngIf="i.value">
                    <input class="form-check-input" type="checkbox" id="check{{j}}" name="check" value="i.value"
                      checked="checked" (click)="checkPermission($event,i,j)">
                    <label class="form-check-label mb-1 ms-1" for="check{{j}}">
                      {{i.appname}}
                    </label>
                  </div>
                  <div class="form-check " *ngIf="!i.value">
                    <input class="form-check-input" type="checkbox" id="check{{j}}" name="check" value="i.value"
                      (click)="checkPermission($event,i,j)">
                    <label class="form-check-label mb-1 ms-1" for="check{{j}}">
                      {{i.appname}}
                    </label>
                  </div>
                </td>



                <td>
                  <div class="form-check " *ngIf="i.view != 'null'">
                    <div *ngIf="i.view">
                      <input class="form-check-input" type="checkbox" checked="checked" name="view" value="i.view"
                        (click)="checkNewpermission($event,i,j,'view')">

                    </div>
                    <div *ngIf="!i.view">
                      <input class="form-check-input" type="checkbox" name="view" value="i.view"
                        (click)="checkNewpermission($event,i,j,'view')">
                    </div>
                  </div>

                </td>
                <td>
                  <div class="form-check " *ngIf="i.create != 'null'">
                    <div *ngIf="i.create">
                      <input class="form-check-input" type="checkbox" name="check" checked="create" value="i.create"
                        (click)="checkNewpermission($event,i,j,'create')">
                    </div>

                    <div *ngIf="!i.create">
                      <input class="form-check-input" type="checkbox" name="create" value="i.create"
                        (click)="checkNewpermission($event,i,j,'create')">
                    </div>

                  </div>
                </td>
                <td>
                  <div class="form-check " *ngIf="i.edit != 'null'">
                    <div *ngIf="i.edit">
                      <input class="form-check-input" type="checkbox" checked="checked" name="edit" value="i.edit"
                        (click)="checkNewpermission($event,i,j,'edit')">

                    </div>
                    <div *ngIf="!i.edit">
                      <input class="form-check-input" type="checkbox" name="edit" value="i.edit"
                        (click)="checkNewpermission($event,i,j,'edit')">
                    </div>
                  </div>
                </td>
                <td>
                  <div class="form-check " *ngIf="i.delete  != 'null'">
                    <div *ngIf="i.delete">
                      <input class="form-check-input" type="checkbox" checked="checked" name="delete" value="i.delete"
                        (click)="checkNewpermission($event,i,j,'delete')">

                    </div>
                    <div *ngIf="!i.delete">
                      <input class="form-check-input" type="checkbox" name="delete" value="i.delete"
                        (click)="checkNewpermission($event,i,j,'delete')">
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>


          </table>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="!isviewornew">
    <div class="table-responsive mt-2" matSort (matSortChange)="sortData($event)">
      <table class="table table-responsive table-borderless table-fixed">
        <thead class="table-header">
          <tr>
            <td class="col-lg-4 col-md-4" mat-sort-header="name">Name
            </td>
            <td class="col-lg-5 col-md-5" mat-sort-header="desc">Description
            </td>
            <td class="col-lg-2 col-md-2" mat-sort-header="type">Type
            </td>
            <td class="d-flex justify-content-center">Action</td>
          </tr>
        </thead>
        <tbody *ngIf="roleListLoading">
          <tr>
            <td colspan="4" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="!roleListLoading" class="role-list-body">
          <tr *ngIf="roleList.length == 0">
            <td colspan="4" class="nodata">Empty</td>
          </tr>

          <tr *ngFor="let role of roleList; let i = index;">
            <td class="col-lg-4 col-md-4 td-edit" (click)="editRole(role)" title="{{role.name}}">{{role.name}}
            </td>
            <td class="col-lg-5 col-md-5" title="{{role.desc}}">
              {{role.desc}}
            </td>
            <td class="col-lg-2 col-md-2" title="{{role.type == '001' ? 'Office' : 'CRM'}}">
              {{role.type == '001' ? 'Office' :
              'CRM'}}
            </td>
            <td class="d-flex justify-content-center">
              <button class="btn btn-outline-primary" (click)="editRole(role)">
                <i class='fa fa-edit'></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>