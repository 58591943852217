<div class="container-fluid">
    <div class="content">
        <div class="me-auto">
            <!-- <app-kmember-breadcrumb s_breadcrumb="General Payment"></app-kmember-breadcrumb> -->
            <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
        </div>
        <div class="d-flex justify-content-between">
            <div class="d-flex">
              <div class="input-group me-2 search-size" style="width: 300px !important">
                <input class="form-control" type="text" placeholder="&#xf002; Search General Payment" aria-label="Search"
                  style="font-family: FontAwesome, Ubuntu" [(ngModel)]="searchName" (keyup.enter)="searchByName()">
                <button class="btn btn-custom me-2" matTooltip="Refresh" (click)="searchByName()">
                  <mat-icon>refresh</mat-icon>
                </button>
              </div>
              <button class="btn btn-custom btn-size" (click)="newMember()">New</button>
            </div>
            <pagination [q]="searchName" [url]="url" [currentPage]="page" [totalPages]="totalPages"></pagination>
          </div>
        <table class="table table-responsive table-borderless m-table">
            <thead class="table-header">
                <tr>
                    <th>Sender</th>
                    <th>Fee Type</th>
                    <th>Payment Date</th>
                    <th>Receiver</th>
                    <th>Payment Type</th>
                    <th>Amount</th>
                    <th></th>
                </tr>
            </thead>
            <tbody *ngIf="!gettingPaymentInvoiceList && paymentInvoiceList.length > 0">
                <tr *ngFor="let payment of paymentInvoiceList" class="py-4" style="cursor: pointer;">
                    <td (click)="editPayment(payment.syskey)">{{payment.sender}}</td>
                    <td (click)="editPayment(payment.syskey)">{{payment.feetype}}</td>
                    <td (click)="editPayment(payment.syskey)">
                        {{allInOneService.formatDBToShow(payment.paymentdate)}}</td>
                    <td (click)="editPayment(payment.syskey)">{{payment.receiver}}</td>
                    <td class="td-edit td-data-2" (click)="editPayment(payment.syskey)">
                        <span [ngClass]="getBadgeClass(payment.paymenttype)">
                            {{ payment.paymenttype }}
                        </span>
                    </td>
                    <td (click)="editPayment(payment.syskey)">{{payment.amount | number}}</td>
                    <td class="justify-contents-between">
                        <button class="btn btn-outline-primary me-2" (click)="viewDetailPayment(payment.syskey)">
                            <i class="fa fa-eye"></i>
                        </button>
                        <!-- <button class="btn btn-outline-primary me-2" (click)="editPayment(payment.syskey)">
                            <i class="fa fa-edit"></i>
                        </button> -->
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!gettingPaymentInvoiceList && paymentInvoiceList.length == 0">
                <tr>
                    <td colspan="9" class="nodata">Empty</td>
                </tr>
            </tbody>
            <tbody *ngIf="gettingPaymentInvoiceList">
                <tr>
                    <td colspan="9" class="nodata">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
      <pagination [url]="url" [currentPage]="page" [totalPages]="totalPages" [q]="searchName"></pagination>

    </div>
</div>