import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { RulesAndRegulationSetupComponent } from '../rules-and-regulation-setup/rules-and-regulation-setup.component';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FileViewerComponent } from 'src/app/shared/components/file-viewer/file-viewer.component';

@Component({
  selector: 'app-rules-and-regulation',
  templateUrl: './rules-and-regulation.component.html',
  styleUrls: ['./rules-and-regulation.component.scss']
})
export class RulesAndRegulationComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isAdmin: boolean = false;
  isMobileView: boolean = false;
  isAdding : boolean = false;
  isEditing : boolean = false;
  saveLoading: boolean = false;
  subscriptions = new SubSink();
  isFocus : boolean = false;

  listLoading : boolean = false;
  searchText : string = '';
  orgid : string = '';
  formSubmitted : boolean = false;

  dataList : any = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];
  tableLoading : boolean = false;

  getRulesSub!: Subscription;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  typeList = [
    {
      name: 'Document',
      id: '001',
    },
    {
      name: 'Other',
      id: '002',
    },
  ];

  submitForm = {
    name : ''
  }

  constructor(
    public allinoneService : AllInOneService,
    private kunyekService : KunyekService,
    private messageService : MessageService,
    private route : ActivatedRoute,
    private router : Router,
    private dialog : MatDialog,
    private filterPipe : FilterPipe
  ) { 
    this.onResize(event);
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
   }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.getRules();
  }

  ngOnDestroy(): void {
    
  }

  submit() {

  }

  getRules() {
    this.tableLoading = true;
    const data = {
      orgid : this.orgid,
    }
    this.getRulesSub && this.getRulesSub.unsubscribe();
    this.getRulesSub = this.kunyekService.getRules(data).subscribe(
      (res:any) => {
        console.log(res);
        if(res.returncode == 300) {
          this.dataList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.tableLoading = false;
      },
      (err:any) => {
        this.tableLoading = false;
        this.messageService.openSnackBar('Server Error', 'warn');
      }
    )
  }

  getType(id:any) {
    for(var i = 0; i < this.typeList.length; i++) {
      if(this.typeList[i].id == id) {
        return this.typeList[i].name;
      }
    }
    return '';
  }

  openSetupForm(data? : any) {
    // this.isAdding = true;
    const dialog = this.dialog.open(RulesAndRegulationSetupComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        orgid : this.orgid,
        isEditing : data ? true : false,
        rulesdata : data
      },
    });
    dialog.afterClosed().subscribe((dialogResult :any) => {
      if(dialogResult) {
        console.log('d r');
        
        console.log(dialogResult);
        console.log(data);
        
        if(data) {
          data.ruleid = dialogResult.ruleid;
          data.filename = dialogResult.filename;
          data.name = dialogResult.name;
          data.description = dialogResult.description;
          data.type = dialogResult.type;
          data.filetype = dialogResult.filetype;
          data.uploadfilename = dialogResult.uploadfilename;
          data.fileurl = dialogResult.fileurl;
        } else {
          this.dataList.push(dialogResult);
        }
      }
    })
  }

  viewFile(data : any) {
    if(data.filetype.toLowerCase() != 'pdf'){
      return;
    }
    // this.isAdding = true;
    const dialog = this.dialog.open(FileViewerComponent, {
      maxWidth: '95vw',
      width: '850px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        name: data.name,
        fileurl: data.fileurl
      },
    });
  }

  searchTextChange() {

  }

  deleteRules(id:any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult : any) => {
      if(dialogResult) {
        this.tableLoading = true;
        const data = {
          ruleid : id,
          orgid : this.orgid
        }
        console.log(data);
        
        this.kunyekService.deleteRules(data).subscribe(
          (res:any) => {
            if(res.returncode == '300') {
              var index = this.dataList.findIndex(
                (x:any) => x.ruleid == id
              );
              this.dataList.splice(index, 1);
              if (
                Math.round(this.dataList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            }
            else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.tableLoading = false;
          },
          (err :any) => {
            this.tableLoading = false;
            this.messageService.openSnackBar('Server Error',
              'warn'
            );
          }
        )
      }
    })
  }

  sortData(sort : Sort) {
    const data = this.dataList;
    if (!sort.active || sort.direction === '') {
      this.dataList = data;
      return;
    }
    this.dataList = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'name') {
        return this.allinoneService.compare(a.name, b.name, isAsc);
      }
      else if (sort.active == 'description') {
        return this.allinoneService.compare(a.description, b.description, isAsc);
      }
      else if (sort.active == 'type') {
        return this.allinoneService.compare(a.type, b.type, isAsc);
      }
      else if (sort.active == 'filename') {
        return this.allinoneService.compare(a.filename, b.filename, isAsc);
      }
      return 0;
    })
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event:any) {

  }

  refresh() {
    this.currentPage = 1;
    this.getRules();
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.saveLoading = false;
    this.submitForm = {
      name : ''
    }

  }

}
