import {
  Component,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { RoleComponent } from '../../role/role.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { SubSink } from 'subsink';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-request-type',
  templateUrl: './request-type.component.html',
  styleUrls: ['./request-type.component.scss'],
})
export class RequestTypeComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  x: boolean = false;

  leaveid: string = 'NCMLEIWHRNVIE';
  wfhid: string = 'EUOMXJSOQNCUWI';

  attendanceView: boolean = false;

  mobileViewWidth: any = 490;
  itemPerPage: any = 100;
  total: any = 0;
  currentPage: any = 1;
  searchText: any = '';
  orgID: any = '';

  isviewornew: boolean = false;
  isMobileView: boolean = false;
  requestTypeListLoading: boolean = false;
  isFocus: boolean = false;
  isEditing: boolean = false;
  isParentReqEditing: boolean = false;
  isdomainAdmin: boolean = false;
  merchantListLoading: boolean = false;
  saveLoading: boolean = false;
  reqTypeFormSubmitted: boolean = false;
  isAdmin: boolean = false;
  workingDayLeaveTypeLoading: boolean = false;
  gettingProratedMethods: boolean = false;
  gettingPolicyList: boolean = false;
  isPolicyForm: boolean = false;
  isWfhPolicyForm: boolean = false;

  wfhData: any;

  reqtypeList: any = [];
  reqleaveList: any = [];
  merchantList: any = [];

  subscriptions = new SubSink();

  // ---
  roleListLoading: boolean = false;

  roleList: any = [];
  roleIdList: any = [];
  typeList: any = [];
  leaveList: any = [];
  proratedMethods: any = [];
  roundingMethods: any = [];
  policyList: any = [];

  workingDayMonthData = [
    {
      name: 'January',
      id: '001',
      value: '',
      max: 31,
    },
    {
      name: 'February',
      id: '002',
      value: '',
      max: 29,
    },
    {
      name: 'March',
      id: '003',
      value: '',
      max: 31,
    },
    {
      name: 'April',
      id: '004',
      value: '',
      max: 30,
    },
    {
      name: 'May',
      id: '005',
      value: '',
      max: 31,
    },
    {
      name: 'June',
      id: '006',
      value: '',
      max: 30,
    },
    {
      name: 'July',
      id: '007',
      value: '',
      max: 31,
    },
    {
      name: 'Auguest',
      id: '008',
      value: '',
      max: 31,
    },
    {
      name: 'September',
      id: '009',
      value: '',
      max: 30,
    },
    {
      name: 'October',
      id: '010',
      value: '',
      max: 31,
    },
    {
      name: 'November',
      id: '011',
      value: '',
      max: 30,
    },
    {
      name: 'December',
      id: '012',
      value: '',
      max: 31,
    },
  ];

  weekDay = [
    {
      name: 'Gazaetted Holiday',
      value: '1',
    },
    {
      name: 'Monday',
      value: 'Monday',
    },
    {
      name: 'Tuesday',
      value: 'Tuesday',
    },
    {
      name: 'Wednesday',
      value: 'Wednesday',
    },
    {
      name: 'Thursday',
      value: 'Thursday',
    },
    {
      name: 'Friday',
      value: 'Friday',
    },
    {
      name: 'Saturday',
      value: 'Saturday',
    },
    {
      name: 'Sunday',
      value: 'Sunday',
    },
  ];

  preSubmitConfig = [
    {
      from: '',
      to: '',
      day: '',
    },
  ];
  postSubmitConfig = [
    {
      from: '',
      to: '',
      day: '',
    },
  ];

  approvalTypeSubmitConfig = [
    {
      status: '',
      value: '',
    }
  ]

  approvalTypeList = [
    { "step": "001", "name": "RO" },
    { "step": "002", "name": "HR Admin" },
    { "step": "003", "name": "Role" },
    { "step": "004", "name": "Org Admin" },
  ];

  approvaltype: string = '001';

  reqTypeForm: any = {
    id: '',
    typeid: '',
    subtype: '',
    subtypeadd: false,
    ro: false,
    roleList: [],
    trantype: '001', // transportation type
    reservationtype: '001',
    taxifare: false,
    policy: '', // policy config
    unlimited: false,
  };

  reservationid: string = 'AVASDAFNMVHSJESAM';
  transportationid: string = 'ANVAHSEFAMVAJDAJ';
  claimid: string = 'WOEIEPPLMXNW';

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private injector: Injector
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
    this.onResize(event);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.allinoneService.isOfficeView = false;
  }

  ngOnInit(): void {
    this.attendanceView = this.allinoneService.isAttendanceView;
    this.isdomainAdmin = this.allinoneService.isAdminOf('domain');
    this.getOrgList();
  }

  t() {
    // console.log(this.roleList);
    // console.log(this.approvalTypeSubmitConfig);
    console.log(this.typeList);
  }

  tt: boolean = true;

  approvalTypeChange(type: string) {
    if (type == '002') {
      this.reqTypeForm.ro = false;
      this.reqTypeForm.roleList = [];
      this.approvalTypeSubmitConfig = [
        {
          status: '',
          value: '',
        }
      ];
    } else {
      this.approvalTypeSubmitConfig = [
        {
          status: '',
          value: '',
        }
      ];
    }
  }

  checkApprovalSubmitConfig(data: any) {
    var ai = this.approvalTypeSubmitConfig.findIndex((x: any) => x.status == data);
    if (ai > -1) {
      return true;
    } else {
      return false;
    }
    // return true;
  }

  approvalSelectChange(status: string, i: any) {
    console.log('changed');

    console.log(this.approvalTypeSubmitConfig);


    var ai = this.approvalTypeSubmitConfig.findIndex((x: any) => x.status == status);
    console.log(ai);

    if (ai > -1) {
      this.approvalTypeSubmitConfig[i].status = '';
    } else {
      this.approvalTypeSubmitConfig[i].status = status;
    }

    console.log(this.approvalTypeSubmitConfig);

  }

  clear() {
    this.searchText = '';
  }

  refresh() {
    this.getRequestTpyeList();
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

  cancel() {
    this.isEditing = false;
    this.isParentReqEditing = false;
    this.isviewornew = false;
    this.reqTypeFormSubmitted = false;
    this.roleIdList = [];
    this.reqTypeForm = {
      id: '',
      typeid: '',
      subtype: '',
      ro: false,
      roleList: [],
      trantype: '001',
      reservationtype: '001',
      policy: '',
      taxifare: false,
      unlimited: false,
    };
    this.approvaltype = '001';
    this.approvalTypeSubmitConfig = [
      {
        status: '',
        value: '',
      }
    ];
    // this.preSubmitConfig = [
    //   {
    //     from: '',
    //     to: '',
    //     day: '',
    //   },
    // ];
    // this.postSubmitConfig = [
    //   {
    //     from: '',
    //     to: '',
    //     day: '',
    //   },
    // ];
    // this.workingDayMonthData = [
    //   {
    //     name: 'January',
    //     id: '001',
    //     value: '',
    //     max: 31,
    //   },
    //   {
    //     name: 'February',
    //     id: '002',
    //     value: '',
    //     max: 29,
    //   },
    //   {
    //     name: 'March',
    //     id: '003',
    //     value: '',
    //     max: 31,
    //   },
    //   {
    //     name: 'April',
    //     id: '004',
    //     value: '',
    //     max: 30,
    //   },
    //   {
    //     name: 'May',
    //     id: '005',
    //     value: '',
    //     max: 31,
    //   },
    //   {
    //     name: 'June',
    //     id: '006',
    //     value: '',
    //     max: 30,
    //   },
    //   {
    //     name: 'July',
    //     id: '007',
    //     value: '',
    //     max: 31,
    //   },
    //   {
    //     name: 'Auguest',
    //     id: '008',
    //     value: '',
    //     max: 31,
    //   },
    //   {
    //     name: 'September',
    //     id: '009',
    //     value: '',
    //     max: 30,
    //   },
    //   {
    //     name: 'October',
    //     id: '010',
    //     value: '',
    //     max: 31,
    //   },
    //   {
    //     name: 'November',
    //     id: '011',
    //     value: '',
    //     max: 30,
    //   },
    //   {
    //     name: 'December',
    //     id: '012',
    //     value: '',
    //     max: 31,
    //   },
    // ];
  }

  submitReqType() {
    this.reqTypeFormSubmitted = true;
    if (this.reqTypeForm.subtype == '' || this.reqTypeForm.typeid == '') {
      return;
    } else if (
      this.reqTypeForm.typeid == this.leaveid &&
      !this.validateLeaveConfig()
    ) {
      return;
    } else {
      if (this.isEditing || this.isParentReqEditing) {
        this.updateReqType();
      } else {
        this.addReqType();
      }
    }
  }

  getOrgList() {
    if (this.isAdmin) {
      this.merchantList = this.allinoneService.orgs;
      if (this.orgID == '') {
        this.orgID = this.allinoneService.selectedOrg
          ? this.allinoneService.selectedOrg
          : this.merchantList[0].orgid;
      }
    } else {
      this.merchantList = this.allinoneService.orgsCRm;
      if (this.orgID == '') {
        this.orgID = this.allinoneService.selected_orgcrm
          ? this.allinoneService.selected_orgcrm.orgid
          : this.merchantList[0].orgid;
      }
    }

    this.getRequestTpyeList();
    // this.getLeaveList();
    // this.getProratedMethods();
    this.getRoleList();
    this.getPolicyList();
  }

  filter() { }

  getPolicyList() {
    this.gettingPolicyList = true;
    const data = {
      orgid: this.orgID,
    };
    this.subscriptions.sink = this.kunyekService.getLeavePolicy(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.policyList = res.policylist;
        } else {
          this.messageService.openSnackBar(
            res.status || res.message || 'Server Error',
            'warn'
          );
        }
        this.gettingPolicyList = false;
      },
      (err) => {
        this.gettingPolicyList = false;
      }
    );
  }

  getProratedMethods() {
    this.gettingProratedMethods = true;
    const data = {
      orgid: this.orgID,
    };
    this.subscriptions.sink = this.kunyekService
      .getProratedMethods(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.proratedMethods = res.list;
            this.roundingMethods = res.roundinglist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingProratedMethods = false;
        },
        (err) => {
          this.gettingProratedMethods = false;
        }
      );
  }

  getRequestTpyeList() {
    this.requestTypeListLoading = true;
    let data = {
      orgid: this.orgID ? this.orgID : '',
      domainid: this.allinoneService.getDomain().domainid,
      roleid: this.allinoneService.getSelectedRole(),
      leavetype: this.attendanceView,
    };

    this.subscriptions.sink = this.kunyekService.getRequestType(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.reqtypeList = res.requesttypelist;
          this.typeList = [];
          for (var i = 0; i < this.reqtypeList.length; i++) {
            this.typeList.push({
              id: this.reqtypeList[i]['requesttypeid'],
              name: this.reqtypeList[i]['requesttypename'],
              issubtype : this.reqtypeList[i]['subtype'],
            });
          }
        } else {
          this.messageService.openSnackBar(res.status, 'warn');
        }
        this.requestTypeListLoading = false;
      },
      (err) => {
        this.requestTypeListLoading = false;
        this.messageService.openSnackBar(err.status, 'warn');
      }
    );
  }

  getLeaveList() {
    this.workingDayLeaveTypeLoading = true;
    let data = {
      orgid: this.orgID ? this.orgID : '',
      leavetype: true,
    };

    this.subscriptions.sink = this.kunyekService.getRequestType(data).subscribe(
      (res) => {
        // console.log("Your res");
        // console.log(res);
        if (res.returncode == 300) {
          this.reqleaveList = res.requesttypelist[0].child;
          this.leaveList = [];
          for (var i = 0; i < this.reqleaveList.length; i++) {
            this.leaveList.push({
              id: this.reqleaveList[i]['requesttypeid'],
              name: this.reqleaveList[i]['requesttypename'],
            });
          }
        } else {
          this.messageService.openSnackBar(res.status, 'warn');
        }
        this.workingDayLeaveTypeLoading = false;
      },
      (err) => {
        this.workingDayLeaveTypeLoading = false;
        this.messageService.openSnackBar(err.status, 'warn');
      }
    );
  }

  newReqType() {
    this.isviewornew = true;
    this.isEditing = false;
    if (this.orgID == '') {
      this.orgID = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.merchantList[0].orgid;
    }
    if (this.attendanceView) {
      this.reqTypeForm.typeid = this.typeList[0]['id'];
    } else {
      this.reqTypeForm.typeid = this.typeList[1]['id'];
    }
  }

  editReqType(reqtypeid: any, reqtypechild: any) {
    console.log('your edit fun 1');
    this.isEditing = true;
    this.isviewornew = true;

    let selectedRole: any = [];
    for (var j = 0; j < reqtypechild.rolelist.length; j++) {
      var role = this.roleList.filter(function (role: any) {
        return role.roleid == reqtypechild.rolelist[j];
      });
      if (role.length > 0) {
        console.log(role);
        selectedRole.push({
          roleid: role[0]['roleid'],
          name: role[0]['name'],
        });
      }
    }

    // console.log(reqtypeid);

    // console.log(reqtypechild);
    console.log(reqtypechild['policy']);

    var data = reqtypechild['policy'];

    let _isRoleSelected = reqtypechild['approvetype'].includes('2');
    this.reqTypeForm = {
      id: reqtypechild.requesttypeid,
      typeid: reqtypeid,
      subtype: reqtypechild.requesttypename,
      ro: _isRoleSelected,
      roleList: selectedRole,
      trantype: reqtypechild.trantype,
      reservationtype: reqtypechild.reservationtype,
      policy: reqtypechild.policyid ? reqtypechild.policyid : '',
      taxifare: reqtypechild.taxifare,
      unlimited: reqtypechild.unlimited || false,
      // Leave Policy Data
      serviceyear: '',
      carry: false,
      carryyear: '',
      maxleavepermonth: '',
      maxleaveperday: '',
      minleaveperday: '',
      attachfile: false,
      prorated: false,
      proratedmethod: '',
      roundingpolicy: false,
      roundingpolicylist: '',
      workingday: false,
      workingdayleavetype: [],
      presubmit: false,
      postsubmit: false,
      todaysubmit: false,
      presubmittype: '1',
      presubmitday: '',
      presubmitcount: [],
      postsubmittype: '1',
      postsubmitday: '',
      postsubmitcount: [],
      postgazaettedholiday: false,
      postsaturday: false,
      postsunday: false,
      pregazaettedholiday: false,
      presaturday: false,
      presunday: false,
      pairleave: false,
      pairleavetype: '1',
      pairleavecustomtype: [],
      leavecountingpreoff: false,
      leavecountingpostoff: false,
      leavedurationgazaettedholiday: false,
      leavedurationsaturday: false,
      leavedurationsunday: false,
      decreaseserviceyear: false,
      leavecount: '',
      serviceyearday: '',
      limitationday: false,
      limitationdaypreoff: false,
      limitationdaypostoff: false,
      limitationdayweekday: [''],
      maternity: false,
      maternitybeforeweek: '',
      maternityafterweek: '',
      paternity: false,
      paternitybeforeweek: '',
      paternityafterweek: '',
      compassionate: false,
      compassionateremark: '',
      encashmenton: false,
      remark: '',
    };

    this.approvaltype = reqtypechild.step.length > 0 ? '002' : '001',
      this.approvalTypeSubmitConfig = reqtypechild.step;

    for (var i = 0; i < data.length; i++) {
      if (data[i]['001']) {
        this.reqTypeForm.serviceyear = data[i]['001']['value'];
      }
      if (data[i]['002']) {
        this.reqTypeForm.maxleavepermonth = data[i]['002']['value'];
      }
      if (data[i]['003']) {
        this.reqTypeForm.maxleaveperday = data[i]['003']['value'];
      }
      if (data[i]['004']) {
        this.reqTypeForm.carry = true;
        this.reqTypeForm.carryyear = data[i]['004']['value'];
      }
      if (data[i]['005']) {
        this.reqTypeForm.attachfile = true;
      }
      if (data[i]['006']) {
        this.reqTypeForm.minleaveperday = data[i]['006']['value'];
      }

      // Working Day
      if (data[i]['007']) {
        this.reqTypeForm.workingday = true;
        for (var x = 0; x < data[i]['007']['day'].length; x++) {
          var id = this.workingDayMonthData[x].id;
          this.workingDayMonthData[x].value = data[i]['007']['day'][x][id];
        }

        data[i]['007']['count'].map((x: any) => {
          if (x == '1') {
            this.reqTypeForm.workingdaygazaettedholiday = true;
          } else if (x == '2') {
            this.reqTypeForm.workingdaysaturday = true;
          } else if (x == '3') {
            this.reqTypeForm.workingdaysunday = true;
          }
        });

        for (let y = 0; y < data[i]['007']['leave'].length; y++) {
          var leave = this.leaveList.filter(function (leave: any) {
            return leave.id == data[i]['007']['leave'][y];
          });
          if (leave.length > 0) {
            this.reqTypeForm.workingdayleavetype.push({
              name: leave[0]['name'],
              id: leave[0]['id'],
            });
          }
        }
      }

      // Pre, Post, Today Submit
      if (data[i]['008']) {
        data[i]['008']['list'].map((item: any) => {
          if (item['1'] != undefined) {
            this.reqTypeForm.presubmit = true;

            if (
              item['1'].length == 1 &&
              item['1'][0]['from'] == '' &&
              item['1'][0]['to'] == ''
            ) {
              this.reqTypeForm.presubmittype = '1';
              this.reqTypeForm.presubmitday = item['1'][0]['value'];
            } else {
              this.reqTypeForm.presubmittype = '2';
              this.preSubmitConfig = [];
              for (var i = 0; i < item['1'].length; i++) {
                this.preSubmitConfig.push({
                  from: item['1'][i].from,
                  to: item['1'][i].to,
                  day: item['1'][i].value,
                });
              }
            }
            for (var i = 0; i < item['count'].length; i++) {
              if (item['count'][i] == '1') {
                this.reqTypeForm.pregazaettedholiday = true;
              }
              if (item['count'][i] == '2') {
                this.reqTypeForm.presaturday = true;
              }
              if (item['count'][i] == '3') {
                this.reqTypeForm.presunday = true;
              }
            }
          }
          if (item['2'] != undefined) {
            this.reqTypeForm.postsubmit = true;
            if (
              item['2'].length == 1 &&
              item['2'][0]['from'] == '' &&
              item['2'][0]['to'] == ''
            ) {
              this.reqTypeForm.postsubmittype = '1';
              this.reqTypeForm.postsubmitday = item['2'][0]['value'];
            } else {
              this.reqTypeForm.postsubmittype = '2';
              this.postSubmitConfig = [];
              for (var i = 0; i < item['2'].length; i++) {
                this.postSubmitConfig.push({
                  from: item['2'][i].from,
                  to: item['2'][i].to,
                  day: item['2'][i].value,
                });
              }
            }
            for (var i = 0; i < item['count'].length; i++) {
              if (item['count'][i] == '1') {
                this.reqTypeForm.postgazaettedholiday = true;
              }
              if (item['count'][i] == '2') {
                this.reqTypeForm.postsaturday = true;
              }
              if (item['count'][i] == '3') {
                this.reqTypeForm.postsunday = true;
              }
            }
          }
          if (item['3'] != undefined) {
            // console.warn('today submit')
            this.reqTypeForm.todaysubmit = true;
          }
        });
      }

      // Pair leave
      if (data[i]['009']) {
        this.reqTypeForm.pairleave = true;
        if (data[i]['009']['type'][0] == '1') {
          this.reqTypeForm.pairleavetype = '1';
        } else {
          this.reqTypeForm.pairleavetype = '2';
          for (let x = 0; x < data[i]['009']['leave'].length; x++) {
            var leave = this.leaveList.filter(function (leave: any) {
              return leave.id == data[i]['009']['leave'][x];
            });
            if (leave.length > 0) {
              this.reqTypeForm.pairleavecustomtype.push({
                name: leave[0]['name'],
                id: leave[0]['id'],
              });
            }
          }
        }
      }

      // Leave Counting
      if (data[i]['010']) {
        if (data[i]['010']['type'].includes('1')) {
          this.reqTypeForm.leavecountingpreoff = true;
        }
        if (data[i]['010']['type'].includes('2')) {
          this.reqTypeForm.leavecountingpostoff = true;
        }
        if (data[i]['010']['count'].includes('1')) {
          this.reqTypeForm.leavedurationgazaettedholiday = true;
        }
        if (data[i]['010']['count'].includes('2')) {
          this.reqTypeForm.leavedurationsaturday = true;
        }
        if (data[i]['010']['count'].includes('3')) {
          this.reqTypeForm.leavedurationsunday = true;
        }
      }

      // Limitations Day
      if (data[i]['011']) {
        this.reqTypeForm.limitationday = true;
        if (data[i]['011']['type'].includes('1')) {
          this.reqTypeForm.limitationdaypreoff = true;
        }
        if (data[i]['011']['type'].includes('2')) {
          this.reqTypeForm.limitationdaypostoff = true;
        }
        this.reqTypeForm.limitationdayweekday = data[i]['011']['day'];
      }

      // Decrease Service Year
      if (data[i]['012']) {
        this.reqTypeForm.decreaseserviceyear = true;
        this.reqTypeForm.leavecount = data[i]['012']['count'];
        this.reqTypeForm.serviceyearday = data[i]['012']['day'];
      }

      // Maternity
      if (data[i]['013']) {
        this.reqTypeForm.maternity = true;
        this.reqTypeForm.maternitybeforeweek = data[i]['013']['before'];
        this.reqTypeForm.maternityafterweek = data[i]['013']['after'];
      }

      // Paternity
      if (data[i]['014']) {
        this.reqTypeForm.paternity = true;
        this.reqTypeForm.paternitybeforeweek = data[i]['014']['before'];
        this.reqTypeForm.paternityafterweek = data[i]['014']['after'];
      }

      // Compassionate
      if (data[i]['015']) {
        this.reqTypeForm.compassionate = true;
        this.reqTypeForm.remark = data[i]['015']['remark'];
      }

      // Encashment On
      if (data[i]['016']) {
        this.reqTypeForm.encashmenton = true;
      }
      // Prorated
      if (data[i]['017']) {
        this.reqTypeForm.prorated = true;
        this.reqTypeForm.proratedmethod = data[i]['017']['value'];
      }
      // Rounding Policy
      if (data[i]['018']) {
        this.reqTypeForm.roundingpolicy = true;
        this.reqTypeForm.roundingpolicylist = data[i]['018']['value'];
      }
    }
  }

  editParentReqType(reqtypeid: any, index: any) {
    console.log('your edit fun 2');

    this.isParentReqEditing = true;
    this.isviewornew = true;

    let selectedRole: any = [];
    for (var j = 0; j < this.reqtypeList[index].rolelist.length; j++) {
      var role = this.roleList.filter((role: any) => {
        return role.roleid == this.reqtypeList[index].rolelist[j];
      });
      selectedRole.push({
        roleid: role[0]['roleid'],
        name: role[0]['name'],
      });
    }

    let _isRoleSelected = this.reqtypeList[index]['approvetype'].includes('2');
    this.reqTypeForm = {
      id: reqtypeid,
      typeid: reqtypeid,
      subtype: this.reqtypeList[index].requesttypename,
      ro: _isRoleSelected,
      roleList: selectedRole,
    };

    this.approvaltype = this.reqtypeList[index].step.length > 0 ? '002' : '001';
    this.approvalTypeSubmitConfig = this.reqtypeList[index].step;

    console.log(this.reqTypeForm);
  }

  getPolicy() {
    var tempPolicy = this.policyList.find(
      (x: any) => x.policyid == this.reqTypeForm.policy
    );
    if (tempPolicy) {
      return tempPolicy.policylist;
    }
    return [];
    var policy: any = [];
    if (this.reqTypeForm.typeid == this.leaveid) {
      policy = [
        {
          // service year
          '001': {
            value:
              this.reqTypeForm.serviceyear == null
                ? ''
                : this.reqTypeForm.serviceyear.toString(),
          },
        },
        {
          // Max leave taken day per month
          '002': {
            value:
              this.reqTypeForm.maxleavepermonth == null
                ? ''
                : this.reqTypeForm.maxleavepermonth.toString(),
          },
        },
        {
          // Max leave taken day at once
          '003': {
            value:
              this.reqTypeForm.maxleaveperday == null
                ? ''
                : this.reqTypeForm.maxleaveperday.toString(),
          },
        },
        {
          // Min leave taken day at once
          '006': {
            value:
              this.reqTypeForm.minleaveperday == null
                ? ''
                : this.reqTypeForm.minleaveperday.toString(),
          },
        },
      ];
      // Carry
      if (this.reqTypeForm.carry) {
        policy.push({
          '004': {
            value:
              this.reqTypeForm.carryyear == null
                ? ''
                : this.reqTypeForm.carryyear.toString(),
          },
        });
      }
      // Attach
      if (this.reqTypeForm.attachfile) {
        policy.push({
          '005': {
            value: '',
          },
        });
      }
      // Working Day
      if (this.reqTypeForm.workingday) {
        console.log(this.reqTypeForm.workingdayleavetype);
        var tempday: any = [];
        this.workingDayMonthData.map((month: any) => {
          tempday.push({
            [month.id]: month.value.toString(),
          });
        });
        var countlist: any = [];
        if (this.reqTypeForm.workingdaygazaettedholiday) {
          countlist.push('1');
        }
        if (this.reqTypeForm.workingdaysaturday) {
          countlist.push('2');
        }
        if (this.reqTypeForm.workingdaysunday) {
          countlist.push('3');
        }
        policy.push({
          '007': {
            day: tempday,
            count: countlist,
            leave:
              this.reqTypeForm.workingdayleavetype.length == 0
                ? []
                : // error
                this.reqTypeForm.workingdayleavetype.map((x: any) => {
                  return x.id;
                }),
            // : this.reqTypeForm.workingdayleavetype
          },
        });
      }

      // Pre, Post, Today Submit
      if (
        this.reqTypeForm.presubmit ||
        this.reqTypeForm.postsubmit ||
        this.reqTypeForm.todaysubmit
      ) {
        if (this.reqTypeForm.todaysubmit) {
          policy.push({
            '008': {
              list: [
                {
                  '3': [{}],
                },
              ],
            },
          });
        }

        if (!this.reqTypeForm.todaysubmit) {
          var submitData = [];
          if (this.reqTypeForm.presubmit) {
            var preSubmitData: any = [];
            var preSubmitCount: any = [];
            if (this.reqTypeForm.presubmittype == '1') {
              preSubmitData.push({
                from: '',
                to: '',
                value: this.reqTypeForm.presubmitday.toString(),
              });
            }
            if (this.reqTypeForm.presubmittype == '2') {
              for (var i = 0; i < this.preSubmitConfig.length; i++) {
                preSubmitData.push({
                  from: this.preSubmitConfig[i].from.toString(),
                  to: this.preSubmitConfig[i].to.toString(),
                  value: this.preSubmitConfig[i].day.toString(),
                });
              }
            }
            if (this.reqTypeForm.pregazaettedholiday) {
              preSubmitCount.push('1');
            }
            if (this.reqTypeForm.presaturday) {
              preSubmitCount.push('2');
            }
            if (this.reqTypeForm.presunday) {
              preSubmitCount.push('3');
            }
            submitData.push({
              '1': preSubmitData,
              count: preSubmitCount,
            });
          }

          if (this.reqTypeForm.postsubmit) {
            var postSubmitData: any = [];
            var postSubmitCount: any = [];
            if (this.reqTypeForm.postsubmittype == '1') {
              postSubmitData.push({
                from: '',
                to: '',
                value: this.reqTypeForm.postsubmitday.toString(),
              });
            }
            if (this.reqTypeForm.postsubmittype == '2') {
              for (var i = 0; i < this.postSubmitConfig.length; i++) {
                postSubmitData.push({
                  from: this.postSubmitConfig[i].from.toString(),
                  to: this.postSubmitConfig[i].to.toString(),
                  value: this.postSubmitConfig[i].day.toString(),
                });
              }
            }
            if (this.reqTypeForm.postgazaettedholiday) {
              postSubmitCount.push('1');
            }
            if (this.reqTypeForm.postsaturday) {
              postSubmitCount.push('2');
            }
            if (this.reqTypeForm.postsunday) {
              postSubmitCount.push('3');
            }
            submitData.push({
              '2': postSubmitData,
              count: postSubmitCount,
            });
          }
          policy.push({
            '008': {
              list: submitData,
            },
          });
        }
      }

      // Pair leave
      if (this.reqTypeForm.pairleave) {
        policy.push({
          '009': {
            type: [this.reqTypeForm.pairleavetype],
            leave:
              this.reqTypeForm.pairleavetype == '1'
                ? []
                : this.reqTypeForm.pairleavecustomtype.map((x: any) =>
                  x.id.toString()
                ),
          },
        });
      }

      // Leave Counting
      var leaveCountingType: any = [];
      if (this.reqTypeForm.leavecountingpreoff) {
        leaveCountingType.push('1');
      }
      if (this.reqTypeForm.leavecountingpostoff) {
        leaveCountingType.push('2');
      }
      var leavingCounting: any = [];
      if (this.reqTypeForm.leavedurationgazaettedholiday) {
        leavingCounting.push('1');
      }
      if (this.reqTypeForm.leavedurationsaturday) {
        leavingCounting.push('2');
      }
      if (this.reqTypeForm.leavedurationsunday) {
        leavingCounting.push('3');
      }
      policy.push({
        '010': {
          type: leaveCountingType,
          count: leavingCounting,
        },
      });

      // Decrease Service Year
      if (this.reqTypeForm.decreaseserviceyear) {
        policy.push({
          '012': {
            count: this.reqTypeForm.leavecount.toString(),
            day: this.reqTypeForm.serviceyearday.toString(),
          },
        });
      }

      // Limitations Day
      if (this.reqTypeForm.limitationday) {
        var limitationDayType = [];
        if (this.reqTypeForm.limitationdaypreoff) {
          limitationDayType.push('1');
        }
        if (this.reqTypeForm.limitationdaypostoff) {
          limitationDayType.push('2');
        }
        policy.push({
          '011': {
            type: limitationDayType,
            day: this.reqTypeForm.limitationdayweekday,
          },
        });
      }

      // Maternity
      if (this.reqTypeForm.maternity) {
        policy.push({
          '013': {
            before: this.reqTypeForm.maternitybeforeweek.toString(),
            after: this.reqTypeForm.maternityafterweek.toString(),
          },
        });
      }

      // Paternity
      if (this.reqTypeForm.paternity) {
        policy.push({
          '014': {
            before: this.reqTypeForm.paternitybeforeweek.toString(),
            after: this.reqTypeForm.paternityafterweek.toString(),
          },
        });
      }

      // Compassionate
      if (this.reqTypeForm.compassionate) {
        policy.push({
          '015': {
            remark: this.reqTypeForm.remark.toString(),
          },
        });
      }

      // Encashment On
      if (this.reqTypeForm.encashmenton) {
        policy.push({
          '016': {
            value: '',
          },
        });
      }

      // Prorated
      if (this.reqTypeForm.prorated) {
        policy.push({
          '017': {
            value: this.reqTypeForm.proratedmethod,
          },
        });
      }

      // Rounding Policy
      if (this.reqTypeForm.roundingpolicy) {
        policy.push({
          '018': {
            value: this.reqTypeForm.roundingpolicylist,
          },
        });
      }
    }
    return policy;
  }

  validateSTS() {
    for (var i = 0; i < this.approvalTypeSubmitConfig.length; i++) {
      if (this.approvalTypeSubmitConfig[i].status == '') {
        return false;
      } else if (this.approvalTypeSubmitConfig[i].status == '003' && this.approvalTypeSubmitConfig[i].value == '') {
        return false;
      }
    }
    return true;
  }

  addReqType() {
    if (this.approvaltype == '002') {
      var v = this.validateSTS();
      if(!v) {
        this.messageService.openSnackBar('Invalid Step By Step.', 'warn');
        return;
      }
    }

    this.reqTypeFormSubmitted = true;

    // console.log(policy);
    // return;
    this.saveLoading = true;
    this.roleIdList = Object.values(this.reqTypeForm.roleList).map(
      (value: any) => value.roleid
    );
    let _approveType: any = [];

    if (this.reqTypeForm.ro) {
      _approveType.push('2');
    }

    if (this.roleIdList.length > 0) {
      _approveType.push('3');
    }

    let data = {
      requesttypename: this.reqTypeForm.subtype.toString().trim(),
      rolelist: this.roleIdList,
      parentid: this.reqTypeForm.typeid,
      approvetype: _approveType.length > 0 ? _approveType : ['2'],
      orgid: this.orgID,
      domainid: this.allinoneService.getDomain().domainid,
      roleid: this.allinoneService.getSelectedRole(),
      policyid:
        this.reqTypeForm.typeid == this.leaveid
          ? this.reqTypeForm.policy
          : '',
      policy:
        this.reqTypeForm.typeid == this.leaveid ? this.getPolicy() : [],
      trantype: this.reqTypeForm.trantype,
      reservationtype: this.reqTypeForm.reservationtype,
      taxifare: this.reqTypeForm.taxifare,
      unlimited: this.reqTypeForm.unlimited,
      step: this.approvaltype == '002' ? this.approvalTypeSubmitConfig : []
    };

    this.kunyekService.addReqType(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.saveLoading = false;

          this.cancel();
          this.getRequestTpyeList();

          this.messageService.openSnackBar('Saved successfully', 'success');
        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.status, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.messageService.openSnackBar(err.status, 'warn');
      }
    );
  }

  updateReqType() {
    if (this.approvaltype == '002') {
      var v = this.validateSTS();
      if(!v) {
        this.messageService.openSnackBar('Invalid Step By Step.', 'warn');
        return;
      }
    }

    this.saveLoading = true;
    this.roleIdList = Object.values(this.reqTypeForm.roleList).map(
      (value: any) => value.roleid
    );
    let _approveType: any = [];

    if (this.reqTypeForm.ro) {
      _approveType.push('2');
    }

    if (this.roleIdList.length > 0) {
      _approveType.push('3');
    }

    let data = {
      requesttypeid: this.reqTypeForm.id,
      requesttypename: this.reqTypeForm.subtype.toString().trim(),
      parentid: this.isParentReqEditing
        ? ''
        : this.reqTypeForm.typeid,
      approvetype: _approveType.length > 0 ? _approveType : ['2'],
      rolelist: this.roleIdList,
      orgid: this.orgID,
      domainid: this.allinoneService.getDomain().domainid,
      roleid: this.allinoneService.getSelectedRole(),
      policyid:
        this.reqTypeForm.typeid == this.leaveid
          ? this.reqTypeForm.policy
          : '',
      policy:
        this.reqTypeForm.typeid == this.leaveid ? this.getPolicy() : [],
      trantype: this.reqTypeForm.trantype,
      reservationtype: this.reqTypeForm.reservationtype,
      taxifare: this.reqTypeForm.taxifare,
      unlimited: this.reqTypeForm.unlimited,
      step: this.approvaltype == '002' ? this.approvalTypeSubmitConfig : []
    };

    this.kunyekService.updateReqType(data).subscribe(
      (res) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.cancel();
          this.getRequestTpyeList();
          this.messageService.openSnackBar('Updated successfully', 'success');
        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.status, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.messageService.openSnackBar(err.status, 'warn');
      }
    );
  }

  delReqType(reqtypeid: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.removeReqType(reqtypeid);
      }
    });
  }

  removeReqType(reqtypeid: any) {
    this.requestTypeListLoading = true;
    let data = {
      requesttypeid: reqtypeid,
      orgid: this.orgID,
      domainid: this.allinoneService.getDomain().domainid,
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.kunyekService.deleteRequestType(data).subscribe(
      (res) => {
        if (res.returncode == '300') {
          // this.refresh();
          // this.messageService.openSnackBar(
          //   'Deleted successfully.',
          //   'success'
          // )

          let data = {
            orgid: this.orgID ? this.orgID : '',
            domainid: this.allinoneService.getDomain().domainid,
            roleid: this.allinoneService.getSelectedRole(),
          };

          this.subscriptions.sink = this.kunyekService
            .getRequestType(data)
            .subscribe(
              (res) => {
                if (res.returncode == 300) {
                  this.messageService.openSnackBar(
                    'Deleted successfully.',
                    'success'
                  );
                  this.requestTypeListLoading = false;
                  this.reqtypeList = res.requesttypelist;
                  this.typeList = [];
                  for (var i = 0; i < this.reqtypeList.length; i++) {
                    this.typeList.push({
                      id: this.reqtypeList[i]['requesttypeid'],
                      name: this.reqtypeList[i]['requesttypename'],
                      issubtype : this.reqtypeList[i]['subtype'],
                    });
                  }
                } else {
                  this.requestTypeListLoading = false;
                  this.messageService.openSnackBar(res.status, 'warn');
                }
              },
              (err) => {
                this.requestTypeListLoading = false;
                this.messageService.openSnackBar(err.status, 'warn');
              }
            );
        } else {
          this.requestTypeListLoading = false;
          this.messageService.openSnackBar(res.status, 'warn');
        }
      },
      (err) => { }
    );
  }

  getRoleList() {
    this.roleListLoading = true;
    let data = {
      orgid: this.orgID,
      domainid: this.allinoneService.getDomain().domainid,
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.kunyekService.getRoleList(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.roleListLoading = false;
          this.roleList = [];
          for (var i = 0; i < res.datalist.length; i++) {
            this.roleList.push({
              roleid: res.datalist[i]['roleid'],
              name: res.datalist[i]['name'],
            });
          }
          let selectedRole: any = [];
          for (var j = 0; j < this.reqTypeForm.roleList.length; j++) {
            var role = this.roleList.filter((role: any) => {
              return role.roleid == this.reqTypeForm.roleList[j]['roleid'];
            });
            selectedRole.push({
              roleid: role[0]['roleid'],
              name: role[0]['name'],
            });
          }
          this.reqTypeForm.roleList = selectedRole;
        } else {
          this.roleListLoading = false;
        }
      },
      (err) => {
        this.roleListLoading = false;
      }
    );
  }

  addRoleList() {
    const dialog = this.dialog.open(RoleComponent, {
      width: '700px',
      height: '555px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        fromCreate: true,
        orgid: this.orgID,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getRoleList();
    });
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  typeChange(event: any) {
    this.reqTypeForm.typeid = event.target.value;
  }

  submitTypeChange(today: boolean) {
    if (today) {
      if (this.reqTypeForm.todaysubmit) {
        this.reqTypeForm.presubmit = false;
        this.reqTypeForm.postsubmit = false;
      }
    } else {
      if (this.reqTypeForm.presubmit || this.reqTypeForm.postsubmit) {
        this.reqTypeForm.todaysubmit = false;
      }
    }
  }

  addApprovalConfig() {
    if (this.approvalTypeSubmitConfig.length < 4) {
      this.approvalTypeSubmitConfig.push({
        status: '',
        value: ''
      });
    }
  }

  addSubmitConfig(type: string) {
    if (type == 'pre') {
      this.preSubmitConfig.push({
        from: '',
        to: '',
        day: '',
      });
    } else {
      this.postSubmitConfig.push({
        from: '',
        to: '',
        day: '',
      });
    }
  }


  removeApprovalConfig(index: number) {
    if (this.approvalTypeSubmitConfig.length > 1) {
      this.approvalTypeSubmitConfig.splice(index, 1);
    }
  }

  checkShowRoleSelect(index: number) {
    if (this.approvalTypeSubmitConfig[index].status == '003') {
      return true;
    } else {
      return false;
    }
  }

  removeSubmitConfig(index: number, type: string) {
    if (type == 'pre') {
      if (this.preSubmitConfig.length > 1) {
        this.preSubmitConfig.splice(index, 1);
      }
    } else {
      if (this.postSubmitConfig.length > 1) {
        this.postSubmitConfig.splice(index, 1);
      }
    }
  }

  addLimitations(event: any, value: any) {
    if (event.target.checked == true) {
      this.reqTypeForm.limitationdayweekday.push(value);
    } else {
      if (this.reqTypeForm.limitationdayweekday != 0) {
        for (var i = 0; i < this.reqTypeForm.limitationdayweekday.length; i++) {
          if (this.reqTypeForm.limitationdayweekday[i] == value) {
            this.reqTypeForm.limitationdayweekday.splice(i, 1);
          }
        }
      }
    }
    // console.log(this.reqTypeForm.limitationdayweekday);
  }
  validateLeaveConfig() {
    this.reqTypeFormSubmitted = true;
    // if (this.reqTypeForm.policy == '') {
    //   return false;
    // }
    return true;

    // Below Codes are for the Old Flow of Leave Policy
    // Working day
    if (this.reqTypeForm.workingday == true) {
      for (var i = 0; i < this.workingDayMonthData.length; i++) {
        if (
          this.workingDayMonthData[i].value == '' ||
          this.workingDayMonthData[i].value == null
        ) {
          this.messageService.openSnackBar("Working day can't blank.", 'warn');
          return false;
        }
        if (
          Number(this.workingDayMonthData[i].value) >
          this.workingDayMonthData[i].max
        ) {
          this.messageService.openSnackBar(
            'Invalid value for ' +
            this.workingDayMonthData[i].name +
            ' in Working Day.',
            'warn'
          );
          return false;
        }
      }
    }
    // presubmit day
    if (this.reqTypeForm.presubmit) {
      console.log('check pre submit day');

      if (this.reqTypeForm.presubmittype == '1') {
        if (
          this.reqTypeForm.presubmitday == '' ||
          this.reqTypeForm.presubmitday == null
        ) {
          this.messageService.openSnackBar(
            "Pre submit day can't blank.",
            'warn'
          );
          return false;
        }
      }
      if (this.reqTypeForm.presubmittype == '2') {
        for (var i = 0; i < this.preSubmitConfig.length; i++) {
          if (
            this.preSubmitConfig[i].from == '' ||
            this.preSubmitConfig[i].from == null ||
            this.preSubmitConfig[i].to == '' ||
            this.preSubmitConfig[i].to == null ||
            this.preSubmitConfig[i].day == '' ||
            this.preSubmitConfig[i].day == null
          ) {
            this.messageService.openSnackBar(
              "Pre submit day can't blank.",
              'warn'
            );
            return false;
          }
        }
      }
    }
    // post submit day
    if (this.reqTypeForm.postsubmit) {
      if (this.reqTypeForm.postsubmittype == '1') {
        if (
          this.reqTypeForm.postsubmitday == '' ||
          this.reqTypeForm.postsubmitday == null
        ) {
          this.messageService.openSnackBar(
            "Post submit day can't blank.",
            'warn'
          );
          return false;
        }
      }
      if (this.reqTypeForm.postsubmittype == '2') {
        for (var i = 0; i < this.postSubmitConfig.length; i++) {
          if (
            this.postSubmitConfig[i].from == '' ||
            this.postSubmitConfig[i].from == null ||
            this.postSubmitConfig[i].to == '' ||
            this.postSubmitConfig[i].to == null ||
            this.postSubmitConfig[i].day == '' ||
            this.postSubmitConfig[i].day == null
          ) {
            this.messageService.openSnackBar(
              "Post submit day can't blank.",
              'warn'
            );
            return false;
          }
        }
      }
    }
    // pair leave
    if (this.reqTypeForm.pairleave) {
      if (this.reqTypeForm.pairleavetype == '2') {
        if (
          this.reqTypeForm.pairleavecustomtype.length == 0 ||
          this.reqTypeForm.pairleavecustomtype == null
        ) {
          this.messageService.openSnackBar("Pair leave can't blank.", 'warn');
          return false;
        }
      }
    }
    // Decrease Service Year
    if (this.reqTypeForm.decreaseserviceyear) {
      if (
        this.reqTypeForm.leavecount == '' ||
        this.reqTypeForm.leavecount == null ||
        this.reqTypeForm.serviceyearday == '' ||
        this.reqTypeForm.serviceyearday == null
      ) {
        this.messageService.openSnackBar(
          "Decrease service year can't blank.",
          'warn'
        );
        return false;
      }
    }
    // Maternity
    if (this.reqTypeForm.maternity) {
      if (
        this.reqTypeForm.maternitybeforeweek == '' ||
        this.reqTypeForm.maternitybeforeweek == null ||
        this.reqTypeForm.maternityafterweek == '' ||
        this.reqTypeForm.maternityafterweek == null
      ) {
        this.messageService.openSnackBar("Maternity can't blank.", 'warn');
        return false;
      }
    }
    //Paternity
    if (this.reqTypeForm.paternity) {
      if (
        this.reqTypeForm.paternitybeforeweek == '' ||
        this.reqTypeForm.paternitybeforeweek == null ||
        this.reqTypeForm.paternityafterweek == '' ||
        this.reqTypeForm.paternityafterweek == null
      ) {
        this.messageService.openSnackBar("Paternity can't blank.", 'warn');
        return false;
      }
    }
    // Compassionate
    if (this.reqTypeForm.compassionate) {
      if (this.reqTypeForm.remark == '' || this.reqTypeForm.remark == null) {
        this.messageService.openSnackBar("Remark can't blank.", 'warn');
        return false;
      }
    }

    // Prorated
    if (this.reqTypeForm.prorated) {
      if (this.reqTypeForm.proratedmethod == '') {
        this.messageService.openSnackBar(
          'Prorated method cannot be blank.',
          'warn'
        );
        return false;
      }
    }

    // Prorated
    if (this.reqTypeForm.roundingpolicy) {
      if (this.reqTypeForm.roundingpolicylist == '') {
        this.messageService.openSnackBar(
          'Rounding Policy cannot be blank.',
          'warn'
        );
        return false;
      }
    }

    return true;
  }

  checkMaxForMonth(month: any) {
    if (month.value > month.max) {
      month.value = month.max;
    }
  }

  policyForm(isWfh: boolean = false, policy: any = {}, requesttypeid: string = '') {
    if (isWfh) {
      this.isWfhPolicyForm = true;
      this.wfhData = {
        requesttypeid: requesttypeid,
        orgid: this.orgID,
        policylist: policy
      };
      console.log(this.wfhData)
    }
    else {
      if (this.isAdmin) {
        this.router.navigateByUrl('hxm/requesttype/leavepolicy');
      } else {
        this.router.navigateByUrl('office/requesttype/leavepolicy');
      }
    }
  }

  cancelWfhPolicy(event: any) {
    this.isWfhPolicyForm = false;
    this.wfhData = {};
    if (!event.cancel) {
      var index = this.reqtypeList.findIndex((x: any) => x.requesttypeid == this.wfhid)
      if (index > -1) {
        this.reqtypeList.policy = event.policy;
      }

    }
  }
}
