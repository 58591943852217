import { ViewportScroller } from '@angular/common';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Renderer2,
  RendererStyleFlags2,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MatDialog } from '@angular/material/dialog';
import { AddContactComponent } from '../add-contact/add-contact.component';
import { UnblockUserComponent } from '../unblock-user/unblock-user.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat-contacts',
  templateUrl: './chat-contacts.component.html',
  styleUrls: ['./chat-contacts.component.scss'],
})
export class ChatContactsComponent implements OnInit {
  contacts: any[] = [];
  contactsList: any[] = [];
  contactsListToShow: any[] = [];
  chooseMembers: any[] = [];
  contactListForInfiniteScroll: any[] = [];
  classCodeList: any[] = [];
  chooseClassCode: any[] = [];
  orgs: any[] = [];

  gettingContactList: boolean = false;
  isFirstTime: boolean = true;
  gettingMoreContact: boolean = false;
  gettingClassCode: boolean = false;
  isCheckAll: boolean = false;

  currentCount: number = 10;
  type: number = 2;
  currentStart: number = 0;
  currentEnd: number = 10;
  domain: any;
  selectedOrgid: string = '';
  selectedSettings: string = '003';

  chatLoaderArray = [1, 2, 3, 4, 5, 6, 7];
  chatLoaderArray2 = [1, 2];
  public config: PerfectScrollbarConfigInterface = {};

  @Input() searchData!: string;
  @Input() isGroupChatCreate: boolean = false;
  @Input() areMembersChosen: boolean = false;
  @Input() createWithThisMember: any;
  @Input() isAddMember: boolean = false;
  @Input() membersAlreadyInGroup: any;
  @Input() channelid: any;

  @Output('clickChat')
  openChat: EventEmitter<any> = new EventEmitter<any>();
  @Output('next')
  selectMembers: EventEmitter<any> = new EventEmitter<any>();
  @Output('createGroupDone')
  createGroupDone: EventEmitter<any> = new EventEmitter<any>();
  @Output('addMemberFinish')
  addMemberFinish: EventEmitter<any> = new EventEmitter<any>();
  @Output('clearSearch')
  clearSearch: EventEmitter<any> = new EventEmitter<any>();

  settings = [
    {
      id: '001',
      name: 'All members',
    },
    {
      id: '002',
      name: 'Member Types',
    },
    {
      id: '003',
      name: 'Selected Members',
    },
  ];

  getContactSubscription!: Subscription;

  constructor(
    private scroll: ViewportScroller,
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialog: MatDialog
  ) {
    this.domain = this.allinoneService.getDomain();
    this.orgs = this.allinoneService.orgs.filter(
      (org: any) => org.membership != '003'
    );
    this.selectedOrgid = this.allinoneService.selectedOrg || '';
  }

  ngOnInit(): void {
    if (this.selectedOrgid != '') {
      this.getClassCode();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.areMembersChosen != undefined ||
      changes.areMembersChosen != null
    ) {
      this.areMembersChosen = changes.areMembersChosen.currentValue;
    }
    console.log(this.searchData);
    if (changes.searchData) {
      this.isFirstTime = true;
      this.currentStart = 0;
      this.currentEnd = 10;
      this.currentCount = 10;
      this.getContactList(this.searchData);
    }
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }
  clickChat(item: any) {
    if (this.isGroupChatCreate) {
      this.addToMember(item);
    } else {
      this.emitChatItem(item);
    }
  }

  onScrollDown() {
    this.isFirstTime = false;
    if (this.contactListForInfiniteScroll.length == this.currentEnd) {
      this.currentStart = this.currentEnd;
      this.currentEnd += 10;
      this.getContactList();
    }
  }

  settingChanged() {}

  orgChanged() {
    if (this.selectedOrgid == '') {
      this.selectedSettings = '003';
    }
    this.isFirstTime = true;
    this.currentStart = 0;
    this.currentEnd = 10;
    this.currentCount = 10;
    this.searchData = '';
    this.chooseMembers = [];
    this.clearSearch.emit();
    this.getContactList();
    this.getClassCode();
  }

  addToMember(item: any) {
    if (this.membersAlreadyInGroup.indexOf(item.userid) == -1) {
      item.checked = !item.checked;
      if (item.checked) {
        this.chooseMembers.push(item);
      } else {
        this.removeMember(item);
      }
    }
    //this.changeDetectorRef.detectChanges()
  }
  removeMember(item: any) {
    this.contactListForInfiniteScroll.find(
      (x: any) => x.userid == item.userid
    ).checked = false;
    item.checked = false;
    let index = this.chooseMembers.findIndex(
      (x: any) => x.userid == item.userid
    );
    // const classname = 'member-wrapper-' + index;
    // const el = document.getElementsByClassName(classname)[0];
    // this.renderer.setStyle(
    //   el,
    //   'animation',
    //   'scaleOut 0.15s ease forwards',
    //   RendererStyleFlags2.Important
    // );

    this.chooseMembers.splice(index, 1);
    //this.changeDetectorRef.detectChanges()
  }
  splitUserName(username: any) {
    return username.split(' ').slice(0, 1);
  }

  emitChatItem(item: any) {
    const tempItem = Object.assign(item, {
      type: '3',
    });
    //this.changeDetectorRef.detectChanges()
    console.log(tempItem);
    this.openChat.emit(tempItem);
  }

  emitselectMembers() {
    this.selectMembers.emit();
  }

  emitCreateGroup(channelid: string) {
    this.areMembersChosen = false;
    this.createGroupDone.emit(channelid);
  }

  createGroup2(channelid: string) {
    this.emitCreateGroup(channelid);
  }

  getContactList(search?: string) {
    if (this.isFirstTime) {
      this.gettingContactList = true;
      this.contactsList = [];
      this.contactListForInfiniteScroll = [];
    } else {
      this.gettingMoreContact = true;
    }
    const data = {
      contact: [],
      count: this.currentCount,
      start: this.currentStart,
      end: this.currentEnd,
      search: search ? search : '',
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      orgid: this.selectedOrgid,
    };
    this.getContactSubscription && this.getContactSubscription.unsubscribe();
    this.getContactSubscription = this.kunyekService
      .getContactList(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.contactListForInfiniteScroll.push.apply(
              this.contactListForInfiniteScroll,
              res.list
            );
            var templist: any[] = [];
            if (this.isGroupChatCreate) {
              res.list.map((item: any) => {
                if (item.block == '') {
                  item.checked = false;
                  if (
                    this.chooseMembers.find((x: any) => x.userid == item.userid)
                  ) {
                    item.checked = true;
                  }

                  templist.push(item);
                }
              });
              // this.contactsList.push.apply(this.contactsList, templist);
            } else {
              templist = res.list;
              // this.contactsList.push.apply(this.contactsList, res.list);
            }

            if (this.isAddMember) {
              templist.map((data: any) => {
                if (this.membersAlreadyInGroup.indexOf(data.userid) > -1) {
                  // this.clickChat(data)
                  data.checked = true;
                } else {
                  data.checked = false;
                }
              });
            } else {
              templist.map((data: any) => {
                if (data.userid == this.createWithThisMember) {
                  this.clickChat(data);
                } else {
                  data.checked = data.checked ? data.checked : false;
                }
              });
            }
            this.contactsList.push.apply(this.contactsList, res.list);
            this.contactsListToShow = this.allinoneService.groupContacts(
              this.contactsList
            );
            this.contactsListToShow.sort((a: any, b: any) => {
              let fa = a.key.toLowerCase(),
                fb = b.key.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            this.gettingContactList = false;
            this.gettingMoreContact = false;
          } else {
            this.gettingContactList = false;
            this.gettingMoreContact = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
          //this.changeDetectorRef.detectChanges()
        },
        (err) => {
          this.gettingContactList = false;
          this.gettingMoreContact = false;
          //this.changeDetectorRef.detectChanges()
          this.messageService.openSnackBar(err, 'warn');
        }
      );
  }

  next() {
    if (
      ((this.selectedOrgid && this.selectedSettings == '003') ||
        this.selectedOrgid == '') &&
      this.chooseMembers.length < 2
    ) {
      this.messageService.openSnackBar(
        'Please select at least two members.',
        'warn'
      );
    } else if (
      this.selectedOrgid &&
      this.selectedSettings == '002' &&
      this.chooseClassCode.length == 0
    ) {
      this.messageService.openSnackBar(
        'Please select at one member type.',
        'warn'
      );
    } else {
      console.log(this.chooseMembers);
      this.areMembersChosen = true;
      //this.changeDetectorRef.detectChanges()
      this.emitselectMembers();
    }
  }

  addMemberDone() {
    var tempContacts: any[] = [];
    this.chooseMembers.map((data: any) => {
      if (this.membersAlreadyInGroup.indexOf(data.userid) == -1) {
        tempContacts.push(data.userid);
      }
    });
    if (tempContacts.length == 0 && this.selectedSettings == '003') {
      this.messageService.openSnackBar(
        'Please select members you want to add.',
        'warn'
      );
      return;
    }
    if (this.selectedSettings == '002' && this.classCodeList.length == 0) {
      this.messageService.openSnackBar(
        'Please select at least one member type.',
        'warn'
      );
      return;
    }
    this.allinoneService.isLoading = true;
    //this.changeDetectorRef.detectChanges()
    var temp: any[] = [];
    if (this.selectedSettings == '002') {
      this.chooseClassCode.map((item) => {
        temp.push(item.name);
      });
    }
    if (this.selectedSettings == '001') {
      this.classCodeList.map((item) => {
        temp.push(item.name);
      });
    }
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      contact: tempContacts,
      channelname: '',
      date: '',
      sort: '',
      channelid: this.channelid,
      addmember: 'true',
      memberorgid: this.selectedOrgid,

      membertype:
        this.selectedOrgid == '' ||
        (this.selectedOrgid && this.selectedSettings == '003')
          ? []
          : temp,
    };

    this.kunyekService.addGroupMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (this.selectedOrgid) {
            this.allinoneService.isLoading = false;
            this.addMemberFinish.emit();
          } else {
            this.userAddMsg(tempContacts);
          }
        } else {
          this.allinoneService.isLoading = false;
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        this.allinoneService.isLoading = false;
        //this.changeDetectorRef.detectChanges()
      }
    );
  }

  userAddMsg(userlist: any) {
    const data2 = {
      domain: this.allinoneService.getDomain().shortcode,
      contact: '',
      channelname: '',
      date: this.allinoneService.getCurrentDateToDB(),
      time: this.allinoneService.getCurrentTimeToDB(),
      message: 'User added',
      imagename: '',
      imagedata: '',
      channelid: this.channelid,
      name:
        this.allinoneService.getKunyekUserName() != ''
          ? this.allinoneService.getKunyekUserName()
          : this.allinoneService.getKunyekUserId(),
      mention: [],
      replysyskey: '',
      filename: '',
      actionuser: userlist,
      chattype: 'add',
      notitype: '010',
    };
    this.kunyekService.sendMessage(data2).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          //  this.allinoneService.isLoading = false
          this.addMemberFinish.emit();
        } else {
          this.allinoneService.isLoading = false;
          this.addMemberFinish.emit();
        }
      },
      (err) => {
        this.allinoneService.isLoading = false;
      }
    );
  }

  addContact() {
    const dialog = this.dialog.open(AddContactComponent, {
      maxWidth: '500px',
      width: '400px',
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult);
      }
    });
  }

  unblockUser(user: any) {
    const dialog = this.dialog.open(UnblockUserComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      data: {
        user: user,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        user.block = '';
        //this.changeDetectorRef.detectChanges()
      }
    });
  }

  trackByUserId(index: any, item: any) {
    return item.userid;
  }

  getClassCode() {
    this.isCheckAll = false;
    this.gettingClassCode = true;
    this.classCodeList = [];
    this.chooseClassCode = [];
    const data = {
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      orgid: this.selectedOrgid,
    };
    this.kunyekService.getClassCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var temp = res.list;
          temp.map((x: any) => {
            var item = {
              name: x,
              checked: false,
            };
            this.classCodeList.push(item);
          });
          this.checkAll();
          this.gettingClassCode = false;
        } else {
          this.gettingClassCode = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingClassCode = false;
      }
    );
  }

  checkAll() {
    if (this.isCheckAll == false) {
      this.classCodeList.map((item: any) => {
        if (!item.checked) {
          item.checked = true;
          this.chooseClassCode.push(item);
        }
      });
      this.isCheckAll = true;
    } else {
      this.classCodeList.map((item: any) => {
        if (item.checked) {
          this.removeClasscode(item);
        }
      });
      this.isCheckAll = false;
    }
  }

  addToClassCode(item: any) {
    item.checked = !item.checked;
    if (item.checked) {
      this.chooseClassCode.push(item);
      if (this.chooseClassCode.length == this.classCodeList.length) {
        this.isCheckAll = true;
      }
    } else {
      this.removeClasscode(item);
    }
  }

  removeClasscode(item: any) {
    let index = this.chooseClassCode.indexOf(item);
    this.chooseClassCode.splice(index, 1);
    item.checked = false;
    this.isCheckAll = false;
  }
}
