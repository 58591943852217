<div class='container-fluid'>
  <div class="content">

    <!-- <ng-container *ngIf="isAdmin">
      <app-hcm-breadcrumb *ngIf="!addTemplate" s_breadcrumb="Leave Template">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="addTemplate" s_breadcrumb="Leave Template"
        [t_breadcrumb]="isEditing ? 'Edit Template' : 'New Tempate'" (emit)="cancel()">
      </app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb *ngIf="!addTemplate" [s_breadcrumb]="'Leave Template'">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="addTemplate" [s_breadcrumb]="'Leave Template'"
        [t_breadcrumb]="isEditing ? 'Edit Template' : 'New Tempate'" (emit)="cancel()">
      </app-office-breadcrumb>
    </ng-container> -->

    <ng-container *ngIf="allinoneService.isAttendanceView && isAdmin">
      <app-hcm-breadcrumb *ngIf="!addTemplate" s_breadcrumb="Leave Template">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="addTemplate" s_breadcrumb="Leave Template"
        [t_breadcrumb]="isEditing ? 'Edit Template' : 'New Tempate'" (emit)="cancel()">
      </app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="allinoneService.appView == '001' && !isAdmin">
      <app-office-breadcrumb *ngIf="!addTemplate" [s_breadcrumb]="'Leave Template'">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="addTemplate" [s_breadcrumb]="'Leave Template'"
        [t_breadcrumb]="isEditing ? 'Edit Template' : 'New Tempate'" (emit)="cancel()">
      </app-office-breadcrumb>
    </ng-container>

    <ng-container *ngIf="allinoneService.appView == '002' && !allinoneService.isAttendanceView">
      <app-team-breadcrumb *ngIf="!addTemplate" s_breadcrumb="Leave Template">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="addTemplate" s_breadcrumb="Leave Template"
        [t_breadcrumb]="isEditing ? 'Edit Template' : 'New Tempate'" (emit)="cancel()">
      </app-team-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!addTemplate">
      <div class="d-flex justify-content-between">
        <div class="mb-3">
          <button class="btn btn-custom me-auto" (click)="newTemplate()">
            <i class="fa fa-plus me-1"></i> New
          </button>
        </div>
        <!-- <div class="mb-3">
          <div class="align-items-center" [matMenuTriggerFor]="statusmenu">
            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
              mdbDropdownToggle style="
                background-color: #87929d;
                border-color: #87929d;
                color: white;
                min-width: 100px;
              ">
              Year&nbsp;:&nbsp;{{selectedCalendar.year}}
            </button>
          </div>
          <mat-menu #statusmenu="matMenu" id="service-menu" xPosition="before" class="calendar-menu">
            <button mat-menu-item *ngFor="let cal of calendarList" (click)="calendarChange(cal)">
              {{ cal.year }}
            </button>
          </mat-menu>
        </div> -->
      </div>
    </ng-container>
    <ng-container *ngIf="addTemplate">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!isLoading">
          Cancel
        </button>
        <app-button-loader class="mb-3" (click)="submitTemplate()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="isLoading">
        </app-button-loader>

      </div>
      <div class="row">
        <div class="mb-3 col-md-12">
          <label for="templatename" class="form-label">Template Name</label>
          <input class="form-control" id="templatename" [(ngModel)]="templateForm.templatename" name="templatename"
            #templatename="ngModel" [ngClass]="{ 'is-invalid': formSubmitted && templatename.invalid }" required />
          <div class="invalid-feedback" *ngIf="
          templatename.invalid ||
              ((templatename.dirty || templatename.touched) && formSubmitted)
            ">
            <div *ngIf="templatename.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div>
        <!-- <div class="mb-3 col-md-6">
          <label for="templatename" class="form-label">Year</label>
          <input class="form-select" [(ngModel)]="templateForm.year" [disabled]="gettingCalendar"
            [matMenuTriggerFor]="calendarMenu" readonly>
          <mat-menu #calendarMenu="matMenu" id="service-menu" xPosition="after" class="input-calendar-menu">
            <ng-container *ngIf="isEditing">
              <button mat-menu-item *ngIf="allCalendarList.length == 0">
                -
              </button>
              <ng-container *ngFor="let cal of allCalendarList">
                <button mat-menu-item *ngIf="cal.calendarid != ''" [ngClass]="{'text-danger' : !cal.valid}"
                  (click)="changeCalenderforTemp(cal)">
                  {{ cal.year }}<mat-icon class="selected-year" *ngIf="cal.year == templateForm.year">check</mat-icon>
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!isEditing">
              <button mat-menu-item *ngIf="activeCalendarList.length == 0">
                -
              </button>
              <ng-container *ngFor="let cal of activeCalendarList">
                <button mat-menu-item *ngIf="cal.calendarid != ''" [ngClass]="{'text-danger' : !cal.valid}"
                  (click)="changeCalenderforTemp(cal)">
                  {{ cal.year }}<mat-icon class="selected-year" *ngIf="cal.year == templateForm.year">check</mat-icon>
                </button>
              </ng-container>
            </ng-container>
          </mat-menu>
          <mat-progress-bar *ngIf="gettingCalendar" mode="indeterminate" color="primary"></mat-progress-bar>
        </div> -->
        <div class="mb-3 col-md-12">
          <button class="btn btn-custom" (click)="addTypes()">
            <i class="fas fa-plus-circle me-2"></i> Types
          </button>
        </div>
        <ng-container *ngFor="let type of requestSubTypeList; let i = index;">
          <div class="col-md-6 d-flex align-items-center mb-3">
            <div class="col-8">
              {{type.requesttypename}}
            </div>
            <div class="col-4">

              <!-- <input class="form-control" type="number" [(ngModel)]="type.noofdays" 
              > -->
              <input class="form-control" type="number" [(ngModel)]="type.noofdays" [min]="0"
                (keypress)="$event.charCode != 45" *ngIf="!type.unlimited">
              <input class="form-control" type="number" value="0" *ngIf="type.unlimited" disabled>
            </div>
          </div>
        </ng-container>
      </div>



    </ng-container>

    <div *ngIf="!addTemplate">
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center ">
        <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by name" aria-label="Search by name"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div class="line "></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{leaveTemplateList.length}}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by name" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <pagination-template class="ms-auto" *ngIf="leaveTemplateList.length > 0" #p="paginationApi"
          (pageChange)="pageChange($event)">
          <div class="d-flex align-items-center justify-content-center">
            <div [class]="
                    p.isFirstPage()
                      ? 'custom-pagination-disabled'
                      : 'custom-pagination'
                  " (click)="p.previous()">
              <span class="me-1">&laquo;</span>&nbsp;Prev
            </div>
            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </span>
              <div class="active-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div [class]="
                    nextDisabled
                      ? 'custom-pagination-disabled'
                      : 'custom-pagination'
                  " (click)="p.next()">
              Next&nbsp;<span class="ms-1">&raquo;</span>
            </div>
          </div>
        </pagination-template>

      </div>

      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="name">Template</td>
              <!-- <td scope="col" class="col-lg-2 col-md-2"  mat-sort-header="year">Year</td> -->
              <td scope="col" class="col-lg-8 col-md-8">Types</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>

          <tbody *ngIf="leaveTemplateListLoading">
            <tr>
              <td colspan="4" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!leaveTemplateListLoading">
            <tr *ngIf="leaveTemplateList.length == 0">
              <td colspan="4" class="nodata">Empty</td>
            </tr>

            <tr
              *ngFor="let template of leaveTemplateList | filter : searchText:'leaveTemplateList' | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage}; let index = index">
              <td class="td-data td-edit" (click)="editLeaveTemplate(template)">
                <span [title]="template.name">{{template.name}}</span>
              </td>
              <!-- 
              <td class="td-data td-edit">
                <span [title]='template.year'>{{template.year}}</span>
              </td> -->

              <td class="td-data">
                <span *ngFor="let item of template.requestsubtypelist; let i = index">
                  {{item.requesttypename}}<span *ngIf="i != template.requestsubtypelist.length - 1">,&nbsp;</span>
                </span>
              </td>

              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-primary" (click)="editLeaveTemplate(template)">
                  <i class='fa fa-edit'></i>
                </button>
                <!-- <button class="btn btn-outline-danger" (click)="deleteLeaveTemplate(template.templateid)">
                  <i class='fa fa-trash-o'></i>
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>