import {
  Component,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { OfficeChooseMembersComponent } from '../office-choose-members/office-choose-members.component';
import { OfficeChooseTypeComponent } from '../office-choose-type/office-choose-type.component';
import { SubSink } from 'subsink';
import { ImportLeaveEntitlementComponent } from '../import-leave-entitlement/import-leave-entitlement.component';
import { CalcuateBroughtForwardComponent } from '../calcuate-brought-forward/calcuate-brought-forward.component';

@Component({
  selector: 'app-office-leave-entitlement',
  templateUrl: './office-leave-entitlement.component.html',
  styleUrls: ['./office-leave-entitlement.component.scss'],
})
export class OfficeLeaveEntitlementComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  mobileViewWidth: any = 490;
  itemPerPage: any = 100;
  total: any = 0;
  currentPage: any = 1;
  searchText: any = '';
  memberSearchText: string = '';
  _end = '';

  isviewornew: boolean = false;
  isMobileView: boolean = false;
  leaveEntitleListLoading: boolean = true;
  isFocus: boolean = false;
  isMemberSearchFocus: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  leavEntitleFormSubmitted: boolean = false;
  calenderListLoading: boolean = false;
  templateListLoading: boolean = false;
  isAdmin: boolean = false;

  memberList: any = [];
  memberTypeList: any = [];

  leaveEntititleList: any = [];
  organizations: any[] = [];
  yearList: any = [];
  templeteList: any = [];
  activeCalendarList: any[] = [];
  allCalendarList: any[] = [];

  excelFilename: string = '';

  leaveEntitlementForm = {
    id: '',
    orgid: '',
    domainid: '',
    year: '',
    template: '',
    templatename: '',
    yearname: '',
  };

  searchForm = {
    year: '',
    yearname: '-',
    active: false,
  };

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  subscriptions = new SubSink();

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private injector: Injector
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
    this.onResize(event);
  }
  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;

    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.leaveEntitlementForm.domainid =
      this.allinoneService.getDomain().domainid;
    if (this.isAdmin) {
      // this.organizations = this.allinoneService.orgs;
      // this.leaveEntitlementForm.orgid = this.allinoneService.selectedOrg
      //   ? this.allinoneService.selectedOrg
      //   : this.organizations[0].orgid;
      this.organizations = this.allinoneService.orgs;
      this.leaveEntitlementForm.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.leaveEntitlementForm.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getCalendarList();
    // this.getleaveEntititleList();
  }

  clear() {
    this.searchText = '';
  }

  clearMemberSearch() {
    this.memberSearchText = '';
  }

  refresh() {
    this._end = '';
    this.getleaveEntititleList();
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
  }

  sortData(sort: Sort) {
    const data = this.leaveEntititleList;
    if (!sort.active || sort.direction === '') {
      this.leaveEntititleList = data;
      return;
    }
    this.leaveEntititleList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'template':
          return this.allinoneService.compare(a.template, b.template, isAsc);
        default:
          return 0;
      }
    });
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

  cancel() {
    this.isviewornew = false;
    this.leavEntitleFormSubmitted = false;
    this.memberList = [];
    this.memberTypeList = [];
    this.excelFilename = '';
    this.leaveEntitlementForm = {
      id: '',
      domainid: this.leaveEntitlementForm.domainid,
      orgid: this.leaveEntitlementForm.orgid,
      year: '',
      template: '',
      templatename: '',
      yearname: '',
    };
    this.clearMemberSearch();
  }

  submitEntitle() {
    this.leavEntitleFormSubmitted = true;
    // if (this.reqTypeForm.subtype == '' || this.reqTypeForm.typeid == '') {

    // } else {
    if (this.isEditing) {
      this.updateEntitle();
    } else {
      this.addEntitle();
    }
    // }
  }

  // getOrgList() {
  //   this.merchantList = this.allinoneService.orgs;
  //   if (this.orgID == '') {
  //     this.orgID = this.allinoneService.selectedOrg
  //       ? this.allinoneService.selectedOrg
  //       : this.merchantList[0].orgid;
  //   }

  //   setTimeout(() => {
  //     this.getleaveEntititleList();
  //     this.getRoleList();
  //   }, 100);

  //   this.merchantListLoading = false;
  // }

  changeCalenderforTemp(cal: any) {
    this.leaveEntitlementForm.year = cal.calendarid;
    this.leaveEntitlementForm.yearname = cal.year;
  }

  searchYearChange(cal: any) {
    this.searchForm.year = cal.calendarid;
    this.searchForm.yearname = cal.year;
    this.searchForm.active = cal.active && cal.valid;
    this.getleaveEntititleList();
  }

  filter() { }

  getleaveEntititleList() {
    this.leaveEntitleListLoading = true;
    this.leaveEntititleList = [];
    let data = {
      orgid: this.leaveEntitlementForm.orgid,
      domainid: this.leaveEntitlementForm.domainid,
      calendarid: this.searchForm.year,
      templateid: '',
      end: this._end,
      roleid: this.allinoneService.getSelectedRole(),
    };

    this.subscriptions.sink = this.kunyekService
      .getLeaveEntitlement(data)
      .subscribe(
        (res) => {
          if (res.returncode == 300) {
            this.leaveEntitleListLoading = false;

            this.leaveEntititleList = res['entitlementlist'];

            for (var a = 0; a < this.leaveEntititleList.length; a++) {
              let users: any = [];
              for (
                var b = 0;
                b < this.leaveEntititleList[a]['userlist'].length;
                b++
              ) {
                let username =
                  this.leaveEntititleList[a]['userlist'][b]['username'] != '' &&
                    this.leaveEntititleList[a]['userlist'][b]['username'] != null
                    ? this.leaveEntititleList[a]['userlist'][b]['username']
                    : this.leaveEntititleList[a]['userlist'][b]['userid'];
                users.push(username);
              }
              this.leaveEntititleList[a]['users'] = users;
            }
          } else {
            this.leaveEntitleListLoading = false;
            this.messageService.openSnackBar(
              res.status || res.message || 'Server Error',
              'warn'
            );
          }
        },
        (err) => {
          this.leaveEntitleListLoading = false;
          this.messageService.openSnackBar(err.status, 'warn');
        }
      );
  }

  newLeaveEntitle() {
    this.isviewornew = true;
    this.isEditing = false;
    this.leaveEntitlementForm.year = this.yearList[0]['calendarid'];
    this.leaveEntitlementForm.yearname = this.yearList[0]['year'];
    if (this.templeteList.length > 0) {
      this.leaveEntitlementForm.template = this.templeteList[0]['templateid'];
    }
    // this.getTemplateList();
  }

  editLeaveEntitle(entitle: any) {
    this.isEditing = true;
    this.isviewornew = true;

    // this.leaveEntitlementForm.template
    console.log(entitle.templateid);

    this.leaveEntitlementForm = {
      id: entitle.entitlementid,
      orgid: this.leaveEntitlementForm.orgid,
      domainid: this.leaveEntitlementForm.domainid,
      year: entitle.calendarid,
      template: entitle.templateid,
      templatename: entitle.templatename,
      yearname: this.allCalendarList.find(
        (x: any) => x.calendarid == entitle.calendarid
      ).year,
    };
    // this.getTemplateListID();
    let _memtypelist = [];
    for (var i = 0; i < entitle.membertype.length; i++) {
      _memtypelist.push({ classname: entitle.membertype[i] });
    }
    // this.memberTypeList = _memtypelist;
    // this.memberList = entitle.userlist;

    this.memberTypeList = _memtypelist.map((item: any) => ({
      ...item,
      checked: true,
    }));
    this.memberList = entitle.userlist.map((item: any) => ({
      ...item,
      checked: true,
    }));
  }

  addEntitle() {
    let checked_members = this.memberList.filter(
      (user: { checked: boolean }) => {
        return user.checked == true;
      }
    );
    let members = Object.values(checked_members).map(
      (value: any) => value.userid
    );

    let checked_membertype = this.memberTypeList.filter(
      (user: { checked: boolean }) => {
        return user.checked == true;
      }
    );
    let membersType = Object.values(checked_membertype).map(
      (value: any) => value.classname
    );
    if (members.length == 0 && membersType.length == 0) {
      return this.messageService.openSnackBar(
        'Please choose at least one member or post.',
        'warn'
      );
    }
    this.saveLoading = true;

    let data = {
      calendarid: this.leaveEntitlementForm.year,
      templateid: this.leaveEntitlementForm.template,
      type: '',
      userlist: members,
      membertype: membersType,
      orgid: this.leaveEntitlementForm.orgid,
      domainid: this.leaveEntitlementForm.domainid,
      roleid: this.allinoneService.getSelectedRole(),
      filename: this.excelFilename,
    };

    console.log(data);

    this.subscriptions.sink = this.kunyekService
      .addLeaveEntitlement(data)
      .subscribe(
        (res) => {
          if (res.returncode == 300) {
            this.saveLoading = false;

            this.cancel();
            this.getleaveEntititleList();

            this.messageService.openSnackBar('Saved successfully', 'success');
          } else {
            this.saveLoading = false;

            this.messageService.openSnackBar(res.status, 'warn');
          }
        },
        (err) => {
          this.saveLoading = false;
        }
      );
  }

  updateEntitle() {
    let checked_members = this.memberList.filter(
      (user: { checked: boolean }) => {
        return user.checked == true;
      }
    );
    let members = Object.values(checked_members).map(
      (value: any) => value.userid
    );

    let checked_membertype = this.memberTypeList.filter(
      (user: { checked: boolean }) => {
        return user.checked == true;
      }
    );
    let membersType = Object.values(checked_membertype).map(
      (value: any) => value.classname
    );

    if (members.length == 0 && membersType.length == 0) {
      return this.messageService.openSnackBar(
        'Please choose at least one member or post.',
        'warn'
      );
    }
    this.saveLoading = true;

    let data = {
      calendarid: this.leaveEntitlementForm.year,
      templateid: this.leaveEntitlementForm.template,
      type: '',
      userlist: members,
      membertype: membersType,
      orgid: this.leaveEntitlementForm.orgid,
      domainid: this.leaveEntitlementForm.domainid,
      entitlementid: this.leaveEntitlementForm.id,
      roleid: this.allinoneService.getSelectedRole(),
      filename: this.excelFilename,
    };

    console.log(data);

    this.subscriptions.sink = this.kunyekService
      .updateLeaveEntitlement(data)
      .subscribe(
        (res) => {
          if (res.returncode == 300) {
            this.saveLoading = false;

            this.cancel();
            this.getleaveEntititleList();

            this.messageService.openSnackBar('Updated successfully', 'success');
          } else {
            this.saveLoading = false;
            this.messageService.openSnackBar(
              res.status || res.message || 'Server Error',
              'warn'
            );
          }
        },
        (err) => {
          this.saveLoading = false;
        }
      );
  }

  delLeaveEntitle(entitlementid: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.removeLeaveEntitle(entitlementid);
      }
    });
  }

  removeLeaveEntitle(entitlementid: any) {
    this.leaveEntitleListLoading = true;
    let data = {
      entitlementid: entitlementid,
      orgid: this.leaveEntitlementForm.orgid,
      domainid: this.leaveEntitlementForm.domainid,
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.subscriptions.sink = this.kunyekService
      .deleteLeaveEntitlement(data)
      .subscribe(
        (res) => {
          if (res.returncode == '300') {
            this.refresh();
            this.messageService.openSnackBar(
              'Deleted successfully.',
              'success'
            );
          } else {
            this.leaveEntitleListLoading = false;
            this.messageService.openSnackBar(
              res.status || res.message || 'Server Error',
              'warn'
            );
          }
        },
        (err) => {
          this.leaveEntitleListLoading = false;
        }
      );
  }

  // trackById(index: number, item: any) {
  //   return item.id;
  // }

  // typeChange(event: any) {
  //   this.reqTypeForm.typeid = event.target.value;
  // }

  showCheckedCount(list: any) {
    let _list = list.filter((data: { checked: boolean }) => {
      return data.checked == true;
    });
    return _list.length.toString();
  }

  getCalendarList() {
    this.calenderListLoading = true;
    let data = {
      orgid: this.leaveEntitlementForm.orgid,
      domainid: this.leaveEntitlementForm.domainid,
      active: '',
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.subscriptions.sink = this.kunyekService.getCalendar(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.calenderListLoading = false;
          let _yearAll: any = [];
          _yearAll = res['calendarlist'];
          this.yearList = _yearAll.filter(
            (data: any) => data.active && data.valid
          );
          if (this.yearList.length > 0) {
            this.leaveEntitlementForm.year = this.yearList[0]['calendarid'];
            this.leaveEntitlementForm.yearname = this.yearList[0]['year'];
            this.searchForm.year = this.yearList[0]['calendarid'];
            this.searchForm.yearname = this.yearList[0]['year'];
            this.searchForm.active = this.yearList[0] && this.yearList[0].valid;
          }
          this.allCalendarList = res.calendarlist;
          this.activeCalendarList = res.calendarlist.filter(
            (item: any) => item.valid && item.active
          );
          this.getleaveEntititleList();
          this.getTemplateList();
        } else {
          this.calenderListLoading = false;
        }
      },
      (err) => {
        this.calenderListLoading = false;
      }
    );
  }

  getTemplateList() {
    this.templateListLoading = true;
    let data = {
      orgid: this.leaveEntitlementForm.orgid,
      domainid: this.leaveEntitlementForm.domainid,
      calendarid: this.leaveEntitlementForm.year,
      end: '',
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.subscriptions.sink = this.kunyekService.getTemplate(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.templateListLoading = false;
          this.templeteList = res['templatelist'];

          if (this.templeteList.length > 0) {
            this.leaveEntitlementForm.template =
              this.templeteList[0]['templateid'];
          }
        } else {
          this.templateListLoading = false;
        }
      },
      (err) => {
        this.templateListLoading = false;
      }
    );
  }

  getTemplateListID() {
    this.templateListLoading = true;
    let data = {
      orgid: this.leaveEntitlementForm.orgid,
      domainid: this.leaveEntitlementForm.domainid,
      calendarid: this.leaveEntitlementForm.year,
      end: '',
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.subscriptions.sink = this.kunyekService.getTemplate(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.templateListLoading = false;
          this.templeteList = res['templatelist'];
          var a = 0;
          if (this.templeteList.length > 0) {
            for (var i = 0; i < this.templeteList.length; i++) {
              if (
                this.templeteList[i]['templateid'] ==
                this.leaveEntitlementForm.template
              ) {
                this.leaveEntitlementForm.template =
                  this.templeteList[i]['templateid'];
                a = 1;
              }
            }
            if (this.templeteList.length > 0 && a == 0) {
              this.leaveEntitlementForm.template =
                this.templeteList[0]['templateid'];
            }
          }
        } else {
          this.templateListLoading = false;
        }
      },
      (err) => {
        this.templateListLoading = false;
      }
    );
  }

  chooseUser() {
    if (this.isEditing && !this.searchForm.active) {
      return;
    }
    const dialog = this.dialog.open(OfficeChooseMembersComponent, {
      width: '750px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        userlist: this.memberList,
        isLeaveOpening: false,
        orgid: this.leaveEntitlementForm.orgid,
        domainid: this.leaveEntitlementForm.domainid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.memberList = dialogResult.data.filter(
          (data: { checked: boolean }) => {
            return data.checked == true;
          }
        );
        console.log(this.memberList);
      }
    });
  }

  chooseType() {
    if (this.isEditing && !this.searchForm.active) {
      return;
    }
    const dialog = this.dialog.open(OfficeChooseTypeComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        typelist: this.memberTypeList,
        orgid: this.leaveEntitlementForm.orgid,
        domainid: this.leaveEntitlementForm.domainid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.memberTypeList = dialogResult.data.filter(
          (data: { checked: boolean }) => {
            return data.checked == true;
          }
        );
      }
    });
  }

  calendarChange(event: any) {
    this.leaveEntitlementForm.year = event.target.value;
    // this.getTemplateList();
  }
  templateChange(event: any) {
    this.leaveEntitlementForm.template = event.target.value;
  }

  downloadSheet() {
    var data: any = [
      {
        name: 'Members',
        data: [],
      },
      {
        name: 'Posts',
        data: [],
      },
    ];
    var name =
      this.leaveEntitlementForm.yearname +
      '-' +
      this.leaveEntitlementForm.templatename +
      '.xlsx';

    this.memberList.map((member: any) => {
      data[0].data.push({
        'User ID': member.userid,
        Name: member.username,
      });
    });

    this.memberTypeList.map((type: any) => {
      data[1].data.push({
        Post: type.classname,
      });
    });
    this.allinoneService.exportExcelMultipleSheets(data, name);
  }

  importFromPrevEntitlement() {
    const dialog = this.dialog.open(ImportLeaveEntitlementComponent, {
      maxWidth: '95vw',
      width: '500px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        entitlementList: this.leaveEntititleList,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult);
        this.memberList = dialogResult.memberList;
        this.excelFilename = dialogResult.filename;
      }
    });
  }

  calculateBroughtForward() {
    const dialog = this.dialog.open(CalcuateBroughtForwardComponent, {
      maxWidth: '95vw',
      width: '500px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        calendarList: this.allCalendarList,
        currentYear: this.searchForm,
        orgid: this.leaveEntitlementForm.orgid,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }
}
