import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-admins-root',
  templateUrl: './admins-root.component.html',
  styleUrls: ['./admins-root.component.scss']
})
export class AdminsRootComponent implements OnInit {


  rootAdminAddorNot: boolean = false
  saveLoading: boolean = false
  rootadminListLoading: boolean = false
  rootadminFormSubmitted: boolean = false
  checkingMember: boolean = false
  isadminValid: boolean = false

  rootadminListToShow: any[] = []

  username: any = ""
  p: number = 1;
  
  rootAdminForm = {
    'id': '',
    'userid' : '',
  }
  constructor(
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    public kunyekService: KunyekService,
    public dialog : MatDialog,
    private router: Router,
  ) {
    if(!this.allinoneService.isAdminOf("root")){
      this.router.navigateByUrl('/home');
    }
   }

  ngOnInit(): void {
    this.getrootAdminAdminList()
  }

  sortData(sort: Sort) {
    const data = this.rootadminListToShow
    if (!sort.active || sort.direction === '') {
      this.rootadminListToShow = data
      return;
    }

    this.rootadminListToShow = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid': return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username': return this.allinoneService.compare(a.username, b.username, isAsc);
        default: return 0;
      }
    });
  }

  cancel() {
    this.rootAdminAddorNot = false;
    this.rootadminFormSubmitted = false;
    this.rootAdminForm = {
      'id': '',
      'userid': '',
    };
    this.isadminValid = false;
    this.saveLoading = false;
    this.username = ''
  }

  addRootAdmin() {
    this.rootAdminAddorNot = true
  }

  submitRootadmin() {
    this.rootadminFormSubmitted = true
    if (this.rootAdminForm.id != "" && this.rootAdminForm.userid != "") {
      this.saveRootAdminAdmin()
    }
  }
  
  removeAdmin(id: any, userid: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      width: "250px",
      panelClass: ['mat-typography'],
      closeOnNavigation: true
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.rootadminListLoading = true;
        this.kunyekService.removeRootAdmin(id, userid).subscribe((res: any) => {
          if (res.returncode == "300") {
            this.getrootAdminAdminList()
          }
          else {
            this.rootadminListLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        })
      }
    });
  }

  saveRootAdminAdmin() {
    this.saveLoading = true
    this.kunyekService.addRootAdmin(this.rootAdminForm.id, this.allinoneService.checkUserId(this.rootAdminForm.userid)).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.cancel()
        this.getrootAdminAdminList()
      }
      else {
        this.saveLoading = false
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err => {
      this.saveLoading = false
    }
    )
  }

  checkMember() {
    this.checkingMember = true;
    const data = {
      "searchuserid": this.allinoneService.checkUserId(this.rootAdminForm.userid),
      "shortcode" : this.allinoneService.getDomain().shortcode
    }
    this.kunyekService.checkUser(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.username = res.username
        this.rootAdminForm.id = res.id
        this.checkingMember = false
        this.isadminValid = true
      }
      else {
        this.checkingMember = false
        this.isadminValid = false
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err => {
      this.checkingMember = false
      this.isadminValid = false
    }
    )
  }

  getrootAdminAdminList(): void {
    this.rootadminListLoading = true;
    this.rootadminListToShow = []
    this.kunyekService.getAllRootAdmins().subscribe((res: any) => {
      if (res.returncode == "300") {
        var tempList = res.data;
        tempList.map((data: any) => {
          if(data.userid != this.allinoneService.getKunyekUserId()){
            this.rootadminListToShow.push(data)
          }
        })
        // this.rootadminListToShow = res.data
        this.rootadminListLoading = false;
      }
      else {
        this.rootadminListLoading = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err => {
      this.rootadminListLoading = false
    })
  }

  useridChange() {
    this.username = ""
    this.isadminValid = false
  }

}
