import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-member-approval-dialog',
  templateUrl: './member-approval-dialog.component.html',
  styleUrls: ['./member-approval-dialog.component.scss']
})
export class MemberApprovalDialogComponent implements OnInit {

  generalDatePickerConfig = this.allinoneService.datePickerConfig;
  remark: string = "";
  isApproving = false;
  currentDateTime :any;
  username:string="";

  constructor(
    private allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MemberApprovalDialogComponent>,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.currentDateTime = new Date();
    this.username = this.allinoneService.getKunyekUserName();
  }

  approveMember() {
    this.isApproving = true;
    this.kunyekService.approveMember({
      usersyskey: this.data.usersyskey,
      formstatus: this.data.formstatus,
      username: this.allinoneService.getKunyekUserName(),
      fromdate: '',
      todate: '',
      remark: this.remark
    }).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.isApproving = false;
          this.messageService.openSnackBar(res.message, 'success');
          this.dialogRef.close({ data: this.data });
          // this.currentMember.formstatus = res.formstatus
          // this.currentMember['approved'] = res.approved
          // this.isNotApproved = false;
          // this.closeFormHandler.emit();
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.isApproving = false;
        }
      },
      (err) => {
        // this.messageService.openSnackBar(err, 'warn');
        this.isApproving = false;
      }
    )
  }

  close() {
    this.dialogRef.close()
  }

}
