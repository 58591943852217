import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datetimeago'
})
export class DatetimeagoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {


      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 60) // less than 60 seconds ago will show as 'Just now'
        return 'Just now';
      var intervals: any = {
        // 'year': 31536000,
        // 'month': 2592000,
        // 'week': 604800,
        'day': 86400,
        // 'hour': 3600,
        // 'minute': 60,
        // 'second': 1
      };
      var day = 86400;
      var hour = 3600;
      var minute = 60;
      var formatToShow = "MMM d";
      var yearFormat = "MMM d, y"
      const myintervals = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
      var year = 31536000

      let y;
      y = (+new Date().getFullYear()) - (+new Date(value).getFullYear())


      if (y === 0) {
        var today = new Date().getDate();
        var postday = new Date(value).getDate()
        // var counterday = today - postday
       var counterday = Math.floor(seconds / day);

        // if (counterday === 1) {
        //   return 'Yesterday';
        // }

        if (counterday === 0) {
          var h = Math.floor(seconds / hour);
          if (h === 0) {
            var m = Math.floor(seconds / minute);
            if (m === 0) {
              return 'Just now';
            } else {
              return m + 'm';
            }
          } else {
            return h + 'h';
          }
        } else if (counterday > 0 && counterday < 8) {
          return counterday + 'd';
        } else {
          var date = formatDate(new Date(value), formatToShow, 'en-US')
          return date;
        }
      } else {
        var date = formatDate(new Date(value), yearFormat, 'en-US')
        return date;
      }


      // let counter;
      // counter = Math.floor(seconds / day);
      // if (counter === 0) {
      //   var h = Math.floor(seconds / hour);
      //   if (h === 0) {
      //     var m = Math.floor(seconds / minute);
      //     return m + 'm';
      //   } else {
      //     return h + 'h';
      //   }
      // }

      // if (counter === 1) {
      //   // return counter + ' ' + i + ' ago'; // singular (1 day ago)
      //   return 'Yesterday';
      // } else if (counter > 7) {
      //   var date = formatDate(new Date(value), formatToShow, 'en-US')
      //   return date;
      // }
      // else {
      //   return counter + 'd'; // plural (2 days ago)
      // }

      // for (const i in intervals) {
      //     counter = 1;
      //     counter = Math.floor(seconds / intervals[i]);
      //         if (counter === 0) {
      //           return new Date()
      //         }
      // if (counter === 1) {
      //     // return counter + ' ' + i + ' ago'; // singular (1 day ago)
      //     return 'Yesterday'
      // } else if(counter > 7) {
      //   return value + ' ' + 'counter ' + counter;
      //   // return counter;
      // }
      // else {
      //     // return counter + ' ' + i + 's ago'; // plural (2 days ago)
      //     return counter + 'd'; // plural (2 days ago)
      //     // return counter;
      // }
      // }
    }
    return value;
  }

}
