import { formatDate } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import * as moment from 'moment';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import * as jwt from 'jsonwebtoken';

@Component({
  selector: 'app-qr-login',
  templateUrl: './qr-login.component.html',
  styleUrls: ['./qr-login.component.scss'],
})
export class QrLoginComponent implements OnInit, OnDestroy {

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  qrLoading: boolean = true;
  showRefresh: boolean = false;

  createdDate = '';
  formatTime = '';
  value: any = '';
  value2 : any = '';
  // value2 : any = 'https://www.connectportal.cloud/qrlogin?qrvalue='

  countGetQr = 1;

  getQrInterval: any;
  checkQrInterval: any;

  dateFormat = 'yyyyMMddhhmmss';
  secretkey = '@s3cr#CTKeyF0rQRL)g1nT0ken';

  form = {
    appid: 'kunyek',
    uniquekey: '',
  };

  nextroute: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService
  ) {
    if (this.allinoneService.getAtoken()) {
      this.router.navigateByUrl('/home');
    }
  }
  ngOnDestroy(): void {
    clearInterval(this.getQrInterval);
    clearInterval(this.checkQrInterval);
  }

  ngOnInit(): void {
    this.nextroute = this.route.snapshot.queryParamMap.get('next') || "";
    if (this.allinoneService.getAtoken()) {
      this.router.navigateByUrl('/home');
    } else {
      this.getQR();
      this.checkQrInterval = setInterval(() => {
        this.checkQr();
        console.log('checking qr');
      }, 5000);
      // }, 1000);
    }
  }

  phLogin() {
    if (this.nextroute) {
      this.router.navigateByUrl(`/login?next=${this.nextroute}`);
    }
    else {
      this.router.navigateByUrl(`/login`);
    }
  }

  getQR() {
    console.log('getting qr');

    this.qrLoading = true;
    this.showRefresh = false;

    this.kunyekService.requestQR(this.value).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.value = res.qr;
          this.value = 'https://www.connectportal.cloud/qrlogin?qrvalue=' + res.qr;
          this.value2 = res.qr;

          this.qrLoading = false;

          if (this.countGetQr == 1) {
            this.getQrInterval = setInterval(() => {
              if (this.countGetQr == 2) {
                clearInterval(this.getQrInterval);
                this.phLogin();
              } else {
                this.getQR();
              }
              this.countGetQr++;
            }, 1000 * 60 * 2 + 50);
            // }, 10000 );
          }
        } else {
          this.qrLoading = false;
          this.showRefresh = true;
        }
      },
      (err: any) => {
        this.qrLoading = false;
        this.showRefresh = true;
      }
    );

    // this.form.uniquekey = formatDate(Date.now(), this.dateFormat, 'en-US').toString()
    // for (let i = 0; i < 5; i++) {
    //   this.form.uniquekey += Math.floor(Math.random() * (9 - 0 + 1)) + 0;
    // }

    // console.log(this.form)

    // let token = jwt.sign({
    //   appid: this.form.appid,
    //   uniquekey: this.form.uniquekey,
    // }, this.secretkey, { expiresIn: 60 * 3 })

    // this.value = "kunyek://signin?token=" + token

    // console.log(this.value)

    // this.qrLoading = false;

    // if (this.countGetQr == 1) {
    //   this.getQrInterval = setInterval(() => {
    //     if (this.countGetQr == 2) {
    //       clearInterval(this.getQrInterval)
    //       this.phLogin()
    //     }
    //     else {
    //       this.getQR();
    //     }
    //     this.countGetQr++
    //   }, (1000 * 60 * 2) + 50);
    //   // }, 10000 );
    // }
  }

  checkQr() {
    this.kunyekService.checkQR(this.value2).subscribe((res: any) => {
      if (res.returncode == '300') {
        this.allinoneService.setCookie('kunyek_userid', res.userid);
        this.allinoneService.setCookie('userid', res.userid);
        this.allinoneService.setCookie('kunyek_username', res.username);
        this.allinoneService.setCookie('atoken', res.atoken);
        this.allinoneService.setCookie('role', res.type);
        this.allinoneService.setCookie(
          'domain',
          JSON.stringify(res.domain) || 'undefined'
        );
        this.allinoneService.setCookie(
          'organization',
          JSON.stringify(res.organization) || 'undefined'
        );
        this.allinoneService.setCookie(
          'organizations',
          JSON.stringify(res.organizations) || 'undefined'
        );
        this.nextPage();
      }
    });
  }

  nextPage() {
    console.log(this.nextroute);
    if (this.nextroute != '') {
      const routes = this.nextroute.split(',');
      if (routes[2] && routes[2] != "" && routes[3] != "" && routes[4] != "" && routes[5] != "") {
        // window.location.replace(`${routes[0]}/${routes[1]}/${routes[2]}?v=${routes[3]}&customerid=${routes[4]}&ticketno=${routes[5]}`)
        window.location.replace(`${routes[0]}/${routes[1]}?v=${routes[2]}&customerid=${routes[3]}&ticketno=${routes[4]}`)
      } else {
        // window.location.replace(`${routes[0]}/${routes[1]}`)
        window.location.replace(`${routes[0]}`)
      }
    }
    else {
      window.location.reload();
    }
  }



}
