<!-- <div class="row mb-2">
    <div class="col-md-6">
        <label for="fromdate" class="form-label">From Date</label>
        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" [bsConfig]="{showClearButton: true}">
    </div>
    <div class="col-md-6">
        <label for="todate" class="form-label">To Date</label>
        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" [bsConfig]="{showClearButton: true}">
    </div>
</div> -->
<div class="title-container mb-3">
    <div class="title">
        Approval
    </div>
    <div class="close-btn">
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<hr>
<div class="row">
    <div class="col">
        <div class="row col-md-12"> 
            <div class="col-md-6">
                <label for="reamark" class="form-label">Approved By : {{username}}</label>
            </div>
            <div class="col-md-6">
                <label for="reamark" class="form-label">Date/Time : {{ currentDateTime | date: 'MM/dd/yyyy - HH:mm:ss' }}</label>
            </div>
        </div>
        <label for="reamark" class="form-label">Remark</label>
        <textarea name="remark" id="remark" class="form-control" [(ngModel)]="remark"></textarea>
    </div>
</div>

<mat-dialog-actions class="close-save-button justify-content-end mt-3">
    <button type="button" class="btn btn-custom me-2" (click)="close()">
        Cancel
    </button>
    <app-button-loader [button_text]="data.approvestatus == '002' ? 'Approve' : 'Reject'" [load_button_text]="'Approve'"
        [isLoading]="isApproving"
        (click)="approveMember()"></app-button-loader>
</mat-dialog-actions>