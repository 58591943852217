<div class='container-fluid'>
    <div class="content">

        <!-- <ng-container *ngIf="isAdmin">
            <app-hcm-breadcrumb s_breadcrumb="Service Years">
            </app-hcm-breadcrumb>
        </ng-container>

        <ng-container *ngIf="!isAdmin">
            <app-office-breadcrumb [s_breadcrumb]="'Service Years'">
            </app-office-breadcrumb>
        </ng-container> -->


        <ng-container>
            <app-team-breadcrumb [s_breadcrumb]="'Service Years'">
            </app-team-breadcrumb>
        </ng-container>


        <!-- <div class="row" [ngClass]="{'row-custom' : !isMobileView }">
            <div class="mb-3 col-md-12 col-sm-12 col-lg-6" [ngClass]="{'row row-custom' : !isMobileView}">
                <label for="month" class="col-form-label ps-0" [ngClass]="{'custom-label' : !isMobileView}">
                    Month</label>

                <div [ngClass]="{'w-75': !isMobileView , 'w-100' : isMobileView}">
                    <select class="form-select" aria-label="Select Month" id="month" name="month"
                    [(ngModel)]="month" (ngModelChange)="monthChange()" required>
                        <option *ngFor="let month of monthList;let index=index;" value="{{month.value}}">
                            {{month.name}}</option>
                    </select>

                </div>
            </div>
            <div class="mb-3 col-md-12 col-sm-12 col-lg-6" [ngClass]="{'row row-custom' : !isMobileView}">
                <label for="day" class="col-form-label"
                    [ngClass]="{'custom-label' : !isMobileView, 'ps-0' : isTabletView}">
                    Day</label>

                <div [ngClass]="{'w-75': !isMobileView , 'w-100' : isMobileView}">
                    <select class="form-select" aria-label="Select Day" id="day" name="day"
                    [(ngModel)]="day" (ngModelChange)="refresh()"  required>
                        <option *ngFor="let day of dayList;let index=index;" [value]="day">
                            {{day}}</option>
                    </select>

                </div>
            </div>
        </div> -->

        


        <div class="d-flex flex-wrap justify-content-start align-items-center ">
            <div class="input-group mb-3" style="width: 300px !important;" *ngIf="!isMobileView">
                <input class="form-control" type="text" placeholder="&#xf002; Search by Name/ID/Post"
                    aria-label="Search by Name/ID/Post" (focus)="isFocus = true" (blur)="isFocus = false"
                    [(ngModel)]="searchText" [ngClass]="{'c-search-input-with-clear' : searchText }"
                    style="font-family: FontAwesome,Ubuntu;">
                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                    [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                    <mat-icon class="c-icon">close</mat-icon>
                </button>
            </div>
            <div class="dropdown ms-2  mb-3">
                <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                    aria-expanded="false" title="Page Size">
                    <mat-icon>filter_list</mat-icon>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                        <div c style="text-align: center;">Page size</div>
                    </li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li class="dropdown-item" *ngFor="let page of pg">
                        <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                            id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                        <label for="pg{{page.name}}" class="col-10 p-1">
                            {{page.name}}
                        </label>
                    </li>
                </ul>
            </div>

            <div class="ms-2  mb-3">
                <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
            <div class="ms-2  mb-3">
                <button type="button" class="btn btn-custom" (click)="export()" title="Export To Excel"
                    [disabled]="userList.length == 0">
                    <img class="excel-icon" src="../../../assets/images/excel.png" />
                    Export
                </button>
            </div>

            <div class="line  mb-3"></div>
            <div class="filtter-btn align-items-center  mb-3 me-2" [matMenuTriggerFor]="monthmenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
                  aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
                  Month&nbsp;:&nbsp;{{monthName}}
                  <mat-menu #monthmenu="matMenu" xPosition="before">
                    <button mat-menu-item *ngFor="let month of monthList" (click)="monthChange(month)">
                      {{ month.name }}
                    </button>
                  </mat-menu>
                </button>
              </div>
              <div class="filtter-btn  mb-3 align-items-center" [matMenuTriggerFor]="daymenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
                  aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
                  Day&nbsp;:&nbsp;{{day}}
                  <mat-menu #daymenu="matMenu" xPosition="before">
                    <button mat-menu-item *ngFor="let day of dayList" (click)="dayChange(day)">
                      {{ day }}
                    </button>
                  </mat-menu>
                </button>
              </div>
            <div class="line  mb-3"></div>
            <div class="d-flex  mb-3 align-items-center">
                <span class="total-count-text">Total:&nbsp;</span>
                <span class="total-count-num">{{(userList | filter : searchText:'LeaveSummaryReport').length}}</span>
            </div>

            <div class="input-group mt-2  mb-3" *ngIf="isMobileView">
                <input class="form-control" type="text" placeholder="&#xf002; Search by Name/ID/Post"
                    aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                    [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                    [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                    <mat-icon class="c-icon">close</mat-icon>
                </button>
            </div>


            <pagination-controls id="rule" class="pagination upper-paginate  mb-3 ms-auto" (pageChange)="currentPage = $event"
                *ngIf="userList.length > 0 " previousLabel="Prev" nextLabel="Next">
            </pagination-controls>


        </div>

        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
            <table class="table table-responsive table-borderless">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="username">Name</td>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="employeeid">Employee ID</td>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="post">Post</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="department">Department</td>
                        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="division">Divison</td>
                        <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="year">Year</td>
                    </tr>
                </thead>

                <tbody *ngIf="userListLoading">
                    <tr>
                        <td colspan="6" class="nodata">
                            <div class="spinner-border" entitle="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tbody *ngIf="!userListLoading">
                    <tr *ngIf="userList.length == 0">
                        <td colspan="6" class="nodata">Empty</td>
                    </tr>

                    <tr
                        *ngFor="let user of userList | filter : searchText:'LeaveSummaryReport' | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, id : 'rule' }; let index = index">
                        <td class="td-data">
                            <span [title]="user.username">{{user.username}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="user.employeeid">{{user.employeeid}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="user.type">{{user.type}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="user.department">{{user.department}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="user.division">{{user.division}}</span>
                        </td>
                        <td>
                            <span [title]="user.year">{{user.year}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</div>