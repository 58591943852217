<div class="container">
    <div class="title-holder">
        
        <mat-icon class="filter-icon">filter_list</mat-icon>
        
        <div class="filter-title">
            Filter
        </div>
    </div>
    <div class="form-check radio-btn">
        <label class="radio-inline">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
            [(ngModel)]="type" value="1">All
        </label>
    </div>
    <div *ngIf="isDomainAdmin">
        <div class="form-check radio-btn">
            <label class="radio-inline">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                [(ngModel)]="type" value="2">View as Admin
            </label>
        </div>
    </div>
    <div *ngIf = "orgList.length > 0">
        <div class="org-title">
            Organizations
        </div>
        <div *ngFor="let org of orgList" class="form-check radio-btn">
            <label class="radio-inline">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="{{org.orgid}}"
                [(ngModel)]="type" [value]="org.orgid">{{org.name}} 
                <img class ="bluemark" *ngIf = "org.admin == 'true'" src = "../../../assets/images/bluemark.png">
            </label>
        </div>
    </div>

</div>
<div mat-dialog-actions align="end">
    <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary mx-2">
        Cancel
    </button>
    <button type="button" (click)="onConfirm()" class="btn btn-custom" cdkFocusInitial>
        Ok
    </button>

</div>
