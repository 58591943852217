// import { Injectable, Pipe, PipeTransform } from '@angular/core';

// @Injectable({
//   providedIn: 'root',
// })
// @Pipe({
//   name: 'memberFilter',
//   pure: false
// })
// export class MemberFilterPipe implements PipeTransform {
//   transform(
//     items: any[],
//     searchText: string,
//     searchPost: string,
//     searchDepartment: string,
//     searchDivision: string,
//     searchCostCenter: string,
//     searchStatus: string
//   ) {
//     if (!items) {
//       return [];
//     }
//     if (!searchText) {      
//       searchPost = searchPost.toLocaleLowerCase();
//       searchDepartment = searchDepartment.toLocaleLowerCase();
//       searchDivision = searchDivision.toLocaleLowerCase();
//       searchCostCenter = searchCostCenter.toLocaleLowerCase();
//       searchStatus = searchStatus.toLocaleLowerCase();
//       var returnItems: any[];

//       returnItems = items.filter((it) => {
//         return (
//           (searchPost == 'all'
//             ? true
//             : it.type.toString().toLocaleLowerCase() == searchPost) &&
//           (searchDepartment == 'all'
//             ? true
//             : it.department.toString().toLocaleLowerCase() ==
//             searchDepartment) &&
//           (searchDivision == 'all'
//             ? true
//             : it.division.toString().toLocaleLowerCase() ==
//             searchDivision) &&
//           (searchCostCenter == 'all'
//             ? true
//             : it.costcenter.toString().toLocaleLowerCase() ==
//             searchCostCenter) &&
//           (searchStatus == 'all'
//             ? true
//             : it.transactiontypestatus?.toString().toLocaleLowerCase() ==
//             searchStatus)
//         );
//       });
//       return returnItems;
//     } else {
//       searchText = searchText.toLocaleLowerCase();
//       searchPost = searchPost.toLocaleLowerCase();
//       searchDepartment = searchDepartment.toLocaleLowerCase();
//       searchDivision = searchDivision.toLocaleLowerCase();
//       searchCostCenter = searchCostCenter.toLocaleLowerCase();
//       searchStatus = searchStatus.toLocaleLowerCase();
//       var returnItems: any[];

//       returnItems = items.filter((it) => {
//         return (
//           (it.username.toString().toLocaleLowerCase().includes(searchText) ||
//             it.userid.toString().toLocaleLowerCase().includes(searchText) ||
//             it.employeeid.toString().toLocaleLowerCase().includes(searchText) ||
//             it.division?.toString().toLocaleLowerCase().includes(searchText)) &&
//           (searchPost == 'all'
//             ? true
//             : it.type?.toString().toLocaleLowerCase() == searchPost) &&
//           (searchDepartment == 'all'
//             ? true
//             : it.department?.toString().toLocaleLowerCase() ==
//             searchDepartment) &&
//           (searchDivision == 'all'
//             ? true
//             : it.division?.toString().toLocaleLowerCase() ==
//             searchDivision) &&
//           (searchCostCenter == 'all'
//             ? true
//             : it.costcenter?.toString().toLocaleLowerCase() ==
//             searchCostCenter) &&
//           (searchStatus == 'all'
//             ? true
//             : it.transactiontypestatus?.toString().toLocaleLowerCase() ==
//             searchStatus)
//         );
//       });
//       return returnItems;
//     }
//   }
// }


import { formatDate, DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'memberFilter',
})
export class MemberFilterPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(
    items: any[],
    searchText: string,
    searchPost: string,
    searchDepartment: string,
    searchDivision: string,
    searchCostCenter: string,
    searchStatus: string,
    searchBranch: string,
    searchPaylevel: string,
    searchEmploymentType: string,
    searchTransactionType: string,
    searchTeamid: string,
    searchSection: string,
    searchSubDivision: string,
    firstJoineddate: string,
    secondJoineddate: string,
    joineddateopt: string,
    firstEffectivedate: string,
    secondEffectivedate: string,
    effectivedateopt: string
  ): any[] {
    if (!items) return [];

    // Convert search terms to lowercase for case-insensitive comparison
    searchText = searchText?.toLowerCase();
    searchPost = searchPost?.toLowerCase();
    searchDepartment = searchDepartment?.toLowerCase();
    searchDivision = searchDivision?.toLowerCase();
    searchCostCenter = searchCostCenter?.toLowerCase();
    searchStatus = searchStatus?.toLowerCase();
    searchBranch = searchBranch?.toLowerCase();
    searchPaylevel = searchPaylevel?.toLowerCase();
    searchEmploymentType = searchEmploymentType?.toLowerCase();
    searchTransactionType = searchTransactionType?.toLowerCase();
    searchTeamid = searchTeamid?.toLowerCase();
    searchSection = searchSection?.toLowerCase();
    searchSubDivision = searchSubDivision?.toLowerCase();

    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0); // Reset time for accurate comparisons

    // Helper function to convert string dates to Date objects
    const parseDate = (dateString: string | undefined): number | null => {
      if (!dateString) return null;
      
      // Parse the date from string
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return null;
    
      // Format it as YYYYMMDD
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits
      const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    
      return parseInt(`${year}${month}${day}`, 10);
    };

    return items.filter((it) => {
      const itemJoinedDate = it.joineddate ? parseInt(it.joineddate, 10) : null;
      const itemEffectiveDate = it.effectivedate ? parseInt(it.effectivedate, 10) : null;

      const parsedFirstJoinedDate = parseDate(firstJoineddate);
      const parsedSecondJoinedDate = parseDate(secondJoineddate);
      const parsedFirstEffectiveDate = parseDate(firstEffectivedate);
      const parsedSecondEffectiveDate = parseDate(secondEffectivedate);

      let matchesJoinedDate = true;
      let matchesEffectiveDate = true;

      // Apply Joined Date Filters
      if (joineddateopt && parsedFirstJoinedDate) {
        switch (joineddateopt) {
          case '1': // Equal to
            matchesJoinedDate = itemJoinedDate == parsedFirstJoinedDate;
            break;
          case '2': // Greater than
            matchesJoinedDate = itemJoinedDate !== null && itemJoinedDate > parsedFirstJoinedDate;
            break;
          case '3': // Less than
            matchesJoinedDate = itemJoinedDate !== null && itemJoinedDate < parsedFirstJoinedDate;
            break;
          case '4': // Greater than or equal
            matchesJoinedDate = itemJoinedDate !== null && itemJoinedDate >= parsedFirstJoinedDate;
            break;
          case '5': // Less than or equal
            matchesJoinedDate = itemJoinedDate !== null && itemJoinedDate <= parsedFirstJoinedDate;
            break;
          case '6': // Between
            if (parsedSecondJoinedDate) {
              matchesJoinedDate =
                itemJoinedDate !== null &&
                itemJoinedDate >= parsedFirstJoinedDate &&
                itemJoinedDate <= parsedSecondJoinedDate;
            }
            break;
        }
      }

      // Apply Effective Date Filters
      if (effectivedateopt && parsedFirstEffectiveDate) {
        switch (effectivedateopt) {
          case '1': // Equal to
            matchesEffectiveDate = itemEffectiveDate == parsedFirstEffectiveDate;
            break;
          case '2': // Greater than
            matchesEffectiveDate = itemEffectiveDate !== null && itemEffectiveDate > parsedFirstEffectiveDate;
            break;
          case '3': // Less than
            matchesEffectiveDate = itemEffectiveDate !== null && itemEffectiveDate < parsedFirstEffectiveDate;
            break;
          case '4': // Greater than or equal
            matchesEffectiveDate = itemEffectiveDate !== null && itemEffectiveDate >= parsedFirstEffectiveDate;
            break;
          case '5': // Less than or equal
            matchesEffectiveDate = itemEffectiveDate !== null && itemEffectiveDate <= parsedFirstEffectiveDate;
            break;
          case '6': // Between
            if (parsedSecondEffectiveDate) {
              matchesEffectiveDate =
                itemEffectiveDate !== null &&
                itemEffectiveDate >= parsedFirstEffectiveDate &&
                itemEffectiveDate <= parsedSecondEffectiveDate;
            }
            break;
        }
      }

      // Other Filters
      const matchesText =
        !searchText ||
        it.username?.toLowerCase().includes(searchText) ||
        it.userid?.toLowerCase().includes(searchText) ||
        it.newuserid?.toLowerCase().includes(searchText) ||
        it.employeeid?.toLowerCase().includes(searchText) ||
        it.division?.toLowerCase().includes(searchText);

      const matchesPost = searchPost === 'all' || it.type?.toLowerCase() === searchPost;
      const matchesDepartment = searchDepartment === 'all' || it.department?.toLowerCase() === searchDepartment;
      const matchesDivision = searchDivision === 'all' || it.division?.toLowerCase() === searchDivision;
      const matchesCostCenter = searchCostCenter === 'all' || it.costcenter?.toLowerCase() === searchCostCenter;
      const matchesBranch = searchBranch === 'all' || it.post?.toLowerCase() === searchBranch;
      const matchesPaylevel = searchPaylevel === 'all' || it.paylevel?.toLowerCase() === searchPaylevel;
      const matchesEmploymentType = searchEmploymentType === 'all' || it.employmenttypename?.toLowerCase() === searchEmploymentType;
      const matchesTransactionType = searchTransactionType === 'all' || it.transactiontypename?.toLowerCase() === searchTransactionType;
      const matchesTeamid = searchTeamid === 'all' || it.teamid?.toLowerCase() === searchTeamid;
      const matchesSection = searchSection === 'all' || it.section?.toLowerCase() === searchSection;
      const matchesSubDivision = searchSubDivision === 'all' || it.subdivision?.toLowerCase() === searchSubDivision;

      let matchesStatus = searchStatus === 'all' || it.transactiontypestatus?.toString() === searchStatus;

      return (
        matchesText &&
        matchesPost &&
        matchesDepartment &&
        matchesDivision &&
        matchesCostCenter &&
        matchesStatus &&
        matchesBranch &&
        matchesPaylevel &&
        matchesEmploymentType &&
        matchesTransactionType &&
        matchesTeamid &&
        matchesSection &&
        matchesSubDivision &&
        matchesJoinedDate &&
        matchesEffectiveDate
      );
    });
  }
}
