<section class="container-fluid">
    <ng-container *ngIf="isHRAdmin;else noAccessTemplate">
      <div class="content">
        <ng-container *ngFor="let admin of menus">
          <div class="admin-title mt-3" *ngIf="admin.list">
            {{admin.name}}
          </div>
          <div class="row g-0">
    
            <ng-container *ngFor="let item of admin.list; let index = index">
              <!-- <div
                *ngIf="((allinoneService.removeContactTracing && item.path != '/contacttracing' && item.path != '/campaigns' && item.path != '/admin/campaign' ) || !allinoneService.removeContactTracing) && !isArrayFun(item)"
                class="p-2 ps-0"> -->
                <!-- col-lg-2 col-md-2 col-sm-4 col-6  -->
                <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card col-sm-4 col-6" *ngIf="((allinoneService.removeContactTracing && item.path != '/contacttracing' && item.path != '/campaigns' && item.path != '/admin/campaign' ) || !allinoneService.removeContactTracing) && !isArrayFun(item)"
                  (click)="callFromWhere(admin.role)" [routerLink]="item.path">
                  <div class='d-flex flex-column'>
                    <div class="mb-2">
                      <img [src]="item.icon" width="30px">
                    </div>
                    <div class="name">
                      {{item.name}}
                    </div>
                  </div>
                  <div *ngIf="approvalCount > 0 && item.name == 'Approval'">
                    <div class="bg-danger custom-badge">
                      {{approvalCount}}
                    </div>
                  </div>
                  <div *ngIf="requisitionCount > 0 && item.name == 'Requsitions'">
                    <div class="bg-danger custom-badge">
                      {{requisitionCount}}
                    </div>
                  </div>
                </div>
              <!-- </div> -->
              <div *ngIf="isArrayFun(item)" class="row g-0">
                <ng-container *ngFor="let itemtwo of item; let indextwo = index">
                  <!-- <div
                    *ngIf="(allinoneService.removeContactTracing && itemtwo.path != '/contacttracing' && itemtwo.path != '/campaigns' && itemtwo.path != '/admin/campaign') || !allinoneService.removeContactTracing"
                    class="p-2 ps-0"> -->
                    <!-- col-lg-2 col-md-2 col-sm-4 col-6  -->
                    <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card" *ngIf="(allinoneService.removeContactTracing && itemtwo.path != '/contacttracing' && itemtwo.path != '/campaigns' && itemtwo.path != '/admin/campaign') || !allinoneService.removeContactTracing"
                      (click)="callFromWhere(admin.role)" [routerLink]="itemtwo.path">
                      <div class='d-flex flex-column'>
                        <div class="mb-2">
                          <img [src]="itemtwo.icon" width="30px">
                        </div>
                        <div class="name">
                          {{itemtwo.name}}
                        </div>
                      </div>
                      <div *ngIf="approvalCount > 0 && itemtwo.name == 'Approval'">
                        <div class="bg-danger custom-badge">
                          {{approvalCount}}
                        </div>
                      </div>
                      <div *ngIf="requisitionCount > 0 && itemtwo.name == 'Requsitions'">
                        <div class="bg-danger custom-badge">
                          {{requisitionCount}}
                        </div>
                      </div>
                    </div>
                  <!-- </div> -->
                </ng-container>
                <br>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noAccessTemplate>
      <div class="no-access">
        <div>
          You don't have permission to access Employee Central.
        </div>
      </div>
    </ng-template>
</section>