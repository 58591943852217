import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-member-approval-history',
  templateUrl: './member-approval-history.component.html',
  styleUrls: ['./member-approval-history.component.scss']
})
export class MemberApprovalHistoryComponent implements OnInit {

  constructor(
    private kunyekService: KunyekService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  approvedhistory: Array<any> = [];
  getting: boolean = false;

  ngOnInit(): void {
    this.getHistory();
  }

  getHistory() {
    this.getting = true;
    this.kunyekService.getApproveHistory(this.data).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.approvedhistory = res.datalist;
        }
        this.getting = false;
      },
      error: (err) => {
        this.getting = false
      }
    })
  }

}
