import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-leave-count-report-details',
  templateUrl: './leave-count-report-details.component.html',
  styleUrls: ['./leave-count-report-details.component.scss']
})
export class LeaveCountReportDetailsComponent implements OnInit {

  userdata: any;
  loadingDetails: boolean = false;
  orgid: string = '';
  startdate: string = '';
  enddate: string = '';

  getDetailsSubscription!: Subscription;

  constructor(
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private kunyekService: KunyekService,
    private dialogRef: MatDialogRef<LeaveCountReportDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.userdata = data.data;
    this.orgid = data.orgid;
    this.startdate = data.startdate;
    this.enddate = data.enddate;
  }

  ngOnInit(): void {
    if('detail' in this.userdata && this.userdata.detail.length > 0){

    }
    else{
      this.getLeaveDetails();
    }
  }

  sortData(sort: Sort) {}

  getLeaveDetails(){
    this.loadingDetails = true;
    const data = {
      "memberid": this.userdata.userid,
      "startdate": this.startdate,
      "enddate": this.enddate,
      "orgid": this.orgid,
    }
    this.getDetailsSubscription && this.getDetailsSubscription.unsubscribe();
    this.getDetailsSubscription = this.kunyekService.getLeaveCountReportDetails(data).subscribe((res: any)=> {
      if(res.returncode == '300'){
        this.userdata.detail = res.datalist;
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.loadingDetails = false;
    }, err => {
      this.loadingDetails = false;
    })
  }

}
