<div class="d-flex custom-web-breadcrumb">
  <div *ngFor="let item of breadcrumbs;" class="d-inline-flex align-items-center">
    <span *ngIf="item.isActive" class="active-breadcrumb" [routerLink]="['/'+item.url]">
      {{ item.name }}
    </span>
    <span *ngIf="!item.isActive">{{ item.name }}</span>
    <i *ngIf="item.isActive" class="fa fa-angle-right mx-1"></i> <!-- Adjust margin as needed -->
  </div>
</div>



<!-- <div class="d-flex custom-web-breadcrumb">
    <span class="active-breadcrumb">{{'Members'}}</span> <i class="fa fa-angle-right mx1"></i>
    <span>{{'gymlist'}}</span> <i class="fa fa-angle-right mx1"></i>
    <span>{{'new'}}</span> <i class="fa fa-angle-right mx1"></i>

 </div> -->