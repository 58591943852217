<div class="container-fluid">
    <div class = "content card">
        <div class = "titlep">
            <div>
                <img src="../../../assets/images/kunyek.png" width="80px">
            </div>
            <div class = "titlediv ms-1">
                <div class = "title">
                    END USER LICENSE AGREEMENT AND TERMS OF SERVICE
                </div>
                <div>
                    Effective Date: August 20, 2021
                </div>
            </div>
        </div>
        <div class="eula-body">
            <p>
                Welcome to Kunyek!
            </p>
            <p>
                Kunyek is a multipurpose and self-managed mobile application for time tracking, collaboration, productivity and e-learning platform for students, employees and individuals. 
            </p>
            <p>
                This End User License Agreement and Terms of Service (this “<span class = "text-decoration-underline">EULA</span>”)
                 is a binding contract between you, an individual user (“<span class = "text-decoration-underline">you</span>”) 
                 and Nirvasoft Pte Ltd (“Nirvasoft,” “<span class = "text-decoration-underline">we</span>,” “<span class = "text-decoration-underline">us</span>” or “<span class = "text-decoration-underline">our</span>”)
                  governing your use of the mobile software applications 
                  that Nirvasoft makes available for download (individually 
                  and collectively, the “<span class = "text-decoration-underline">App</span>”), the related website located
                   at http://www.nirvasoft.com/ and any other online properties 
                   owned or controlled by or on behalf of Nirvasoft 
                   (collectively with the App, the “<span class = "text-decoration-underline">Service</span>”).<b> BY INSTALLING 
                   OR OTHERWISE ACCESSING OR USING THE SERVICE, YOU AGREE 
                   THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND 
                   BY THIS EULA. IF YOU DO NOT AGREE TO THE TERMS OF THIS 
                   EULA, THEN YOU SHOULD NOT USE THE SERVICE.</b>
            </p>
            <p>
                <span class = "text-decoration-underline">Material Terms</span> : As provided in greater detail in this EULA (and without limiting the express language of this EULA), you acknowledge the following:
            </p>
            
            <ul>
                <li class="sublist">
                    the Service is licensed, not sold, to you, and you may use the Service only as set forth in this EULA;
                </li>
                <li class="sublist">
                    you use the Service at your sole risk
                </li>
                <li class="sublist">
                    the use of the Service may be subject to separate third-party terms of service and fees, including, without 
                    limitation, your mobile network operator’s (the “<span class = "text-decoration-underline">Carrier</span>”) terms of service and fees, including fees
                        charged for data usage and overage, which are your sole responsibility;
                </li>
                <li class="sublist">
                    you consent to the collection, use, and disclosure of your personally identifiable information in accordance
                        with Nirvasoft’s Privacy Policy available at <a routerLink = "/privacy" target="_blank">https://www.kunyek.com/privacy (“Privacy Policy”) </a>;
                </li>
                <li class="sublist">
                    we provide the App to you on an “as is” basis without warranties of any kind and Nirvasoft’s liability to you is limited;
                </li>
            </ul>
            <div>
                <ol type="1">
                    <li class="mainlist">
                        General Terms and Conditions.
                        <ol type = "a" class="mt-2">
                            <li class="sublist">
                                <span class = "text-decoration-underline">Changes to this EULA</span>. You understand and agree that we may change this EULA at any time without prior notice. The revised EULA will become effective at the time of posting unless specified otherwise. Any use of the Service <span class="text-decoration-underline">after the effective date</span> will constitute your acceptance of such revised EULA. If you find any change to this EULA or the Service unacceptable, then your sole remedy is to stop accessing, browsing, and otherwise using the Service.
                            </li>
                            <li class="sublist">
                                <span class = "text-decoration-underline">Privacy Policy</span>. Your access to and use of the Service is also subject to
                                 Nirvasoft’s <a href = "https://www.kunyek.com/privacy" target = "_blank">Privacy Policy</a>, the terms and conditions of which are incorporated herein 
                                 by reference.
                            </li>
                            <li class="sublist">
                                <span class = "text-decoration-underline">Eligibility</span>. The service is not for persons under the age of 13. IF YOU ARE
                                 UNDER 13 YEARS OF AGE, YOU MUST NOT USE OR ACCESS THE SERVICE AT ANY TIME OR
                                  IN ANY MANNER. By accessing or using the Service, you affirm that either you are 
                                  at least 13 years of age or you have been authorized to use the Service by your
                                   parent or legal guardian who is at least 18 years of age.
                            </li>
                            <li class="sublist">
                                <span class = "text-decoration-underline">Cancellation</span>. You may cancel your subscription to the Service at any time.
                            </li>
                        </ol>
                    </li>
                    <li class="mainlist">
                        User Content.
                        <ol type = "a" class="mt-2">
                            <li class="sublist">
                                <span class = "text-decoration-underline">Definition</span>. “<span class = "text-decoration-underline">User Content</span>” means any content that users upload, post or transmit 
                                (collectively, “<span class = "text-decoration-underline">Post</span>”) to or through 
                                the Service including, without limitation, any text, comments, messages and photos.  
                            </li>
                            <li class="sublist">
                                <span class = "text-decoration-underline">Screening User Content</span>. Kunyek offers end users the ability to 
                                submit User Content to or transmit User Content through the Service. 
                                Kunyek does not pre-screen any User Content, but reserves the right to 
                                remove, disallow, block, or delete any User Content in its sole 
                                discretion. Nirvasoft does not guarantee the accuracy, integrity, 
                                appropriateness, availability, or quality of any User Content, and 
                                under no circumstances will Nirvasoft be liable in any way for any 
                                User Content.
                            </li>
                            <li class="sublist">
                                <span class = "text-decoration-underline">Objectionable Content</span>. You agree not to Post any User Content
                                 to the Service that is or could be interpreted to be (i) abusive,
                                  bullying, defamatory, harassing, harmful, hateful, inaccurate, 
                                  infringing, libelous, objectionable, obscene, offensive, pornographic, 
                                  shocking, threatening, unlawful, violent, vulgar, or in violation of 
                                  any applicable laws (including laws related to speech); or (ii) 
                                  promoting any product, good, or service, or bigotry, discrimination, 
                                  hatred, intolerance, racism, or inciting violence (including suicide) 
                                  (collectively, “<span class = "text-decoration-underline">Objectionable Content</span>”). 
                                  Nirvasoft maintains a zero-tolerance policy regarding objectionable content. User accounts which have been confirmed responsible for posting objectionable content will be restricted from the Service and the content will be removed.
                                  The Posting of any 
                                  Objectionable Content may subject you to third party claims 
                                  and none of the rights granted to you in this EULA may be raised 
                                  as a defense against such third party claims. If you encounter any 
                                  Objectionable Content on the Service, then please immediately email
                                  developer@nirvasoft.com. Nirvasoft in its sole discretion may take 
                                  any actions it deems necessary and/or appropriate against any User 
                                  who Posts Objectionable Content on the Service.
                                  <p>
                                    Complaints must be communicated through email (developer@nirvasoft.com), however please note, that by agreeing to these Terms of Service you accept that for any complaint made, there is no guarantee of a resolution.
                                  </p>
                            </li>
                        </ol>
                    </li>
                    <li class="mainlist">
                        External Sites. The Service may contain links to, or the ability to share information with, 
                        third party websites (“<span class = "text-decoration-underline">External Sites</span>”). Nirvasoft does not endorse any 
                        External Sites or the content made available on such External Sites. Nirvasoft 
                        is not responsible for the content of any External Sites and does not make any 
                        representations regarding the content or accuracy of any materials on such External
                         Sites. You agree that Nirvasoft will have no liability to you arising from your use,
                          engagement, exposure to, or interaction with any External Sites.
                    </li>
                    <li class="mainlist">
                        Term and Termination of the EULA.
                        <ol type = "a" class="mt-2">
                            <li class="sublist" >
                                <span class = "text-decoration-underline">Term</span>. As between you and Nirvasoft, the Term of this EULA commences 
                                as of your first use of the Service and continues until the termination
                                 of this EULA by either you or Nirvasoft.
                            </li>
                            <li class="sublist">
                                <span class = "text-decoration-underline">Termination</span>. You may terminate this EULA by 
                                sending written notification to Nirvasoft at developer@nirvasoft.com, 
                                deleting the App from your mobile device, and terminating all other uses 
                                of the Service. Nirvasoft reserves the right, in its sole discretion, to 
                                restrict, suspend, or terminate this EULA and your access to all or any part 
                                of the Service at any time without prior notice or liability if you breach any 
                                provision of this EULA. Nirvasoft may further terminate this EULA for any other 
                                reason upon ten (10) days’ notice to you using the email address associated with 
                                your account credentials. Nirvasoft reserves the right to change, suspend, or discontinue 
                                all or any part of the Service at any time without prior notice or liability.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>
