import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
})
export class OrganizationsComponent implements OnInit {
  role: any;
  orgIdToEdit: any;

  orgAddorNot: boolean = false;
  orgListLoading: boolean = false;
  saveLoading: boolean = false;
  orgSubmitted: boolean = false;
  gettingDomainList: boolean = false;
  isEditing: boolean = false;
  isFocus: boolean = false;

  fileSelected: any = '';
  fileName: any = '';
  orgImgURL: any;
  imgURL: any;
  domains: any = [];
  orgListToShow: any = [];
  limit: any = '002';

  bannerFileName: any = '';
  bannerFileSelected: any = '';
  bannerImgURL: any = '';
  orgBannerImgURL: any = '';

  p: number = 1;
  checkedlayout: string = '001';
  appname: string = this.allinoneService.appname;
  defaultCount = '';

  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];

  searchText: string = '';

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Active',
      value: '001',
    },
    {
      name: 'Inactive',
      value: '002',
    },
  ];

  statusFilterName = 'All';
  statusFilterValue = '';

  publicMailDomain = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'outlook.com',
    'yandex.com',
    'protonmail.com',
    'zohomail.com',
    'icloud.com',
  ];

  orgForm: any = {
    domain: '',
    orgname: '',
    shortcode: '',
    description: '',
    membership: '002',
    emaildomain: '',
    layout: '001',
    eid: true,
    tasktype: '002',
    tasklength: '3',
    limit: '',
    singleposition: 'false',
    sameempid: 'false',
    timeinoutrequired: true,
    activityrecordrequired: true,
    personallocationapproval: true,
    unregisteredtimeinoutapproval: true,
    backdateallowed: true,
    backdateapproval: true,
    showtransportation: true,
    useqr360: false,
    rolerequired: false,
    userequisition: false,
    usepayroll: false,
    expirationdate: new Date(),
    neosync: false,
    hcmsync: false,
  };

  memberships = [
    {
      id: '001',
      name: 'Association',
    },
    {
      id: '002',
      name: 'Company',
    },
    {
      id: '003',
      name: 'Loyalty',
    },
  ];
  taskIdTypes = [
    {
      id: '001',
      name: 'Auto',
    },
    {
      id: '002',
      name: 'Manual',
    },
  ];
  limited = [
    { name: 'Limited', value: '001' },
    { name: 'Unlimited', value: '002' },
  ];
  layouts = [
    {
      id: '001',
      url: '../../../assets/images/layout1.png',
    },
  ];
  @ViewChild('fileInput') fileInput: any;
  @ViewChild('bannerFileInput') bannerFileInput: any;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    console.log(this.allinoneService.admins);
    if (!this.allinoneService.isAdminOf('domain')) {
      this.router.navigateByUrl('/home');
    }
  }

  async ngOnInit(): Promise<void> {
    console.log(this.allinoneService.getDomain().imageurl);
    this.role = this.allinoneService.getRole();
    var temp: any = this.allinoneService.getDomain();
    this.orgForm.domain = temp.domainid;
    this.orgListLoading = true;
    const awaitGettingOrgs = await this.getOrganization();

    //check comeback from other site [Members/locations/etc] - Edit Form
    const route_orgId = this.route.snapshot.queryParamMap.get('org') || '';
    if (route_orgId && awaitGettingOrgs == 'success') {
      const get_org = this.orgListToShow.find(
        (org: any) => org.orgid == route_orgId
      );
      console.log('org->', get_org);
      if (get_org) {
        this.editOrg(get_org);
      }
    }
    // if (this.role == "200") {
    //   var temp = this.allinoneService.getDomain()
    //   this.orgForm.domain = temp.domainid
    //   this.orgListLoading = true;
    //   this.getOrganization();
    // } else {
    //   console.log(this.allinoneService.getDomain())
    //   this.gettingDomainList = true;
    //   this.getDomainList()
    // }
  }

  statusChange(status: any) {
    if (this.statusFilterValue != status.value) {
      this.statusFilterValue = status.value;
      this.statusFilterName = status.name;
      this.refresh();
    }
  }

  uploadFile(event: any) {
    this.fileSelected = event[0];
    // console.log(this.fileSelected);

    var reader = new FileReader();
    // this.imagePath = fileSelected;
    reader.readAsDataURL(this.fileSelected);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };

    this.fileName = this.getFileName(this.fileSelected);
    console.log(this.fileName);
  }

  uploadFile1(event: any) {
    this.fileSelected = event.target.files[0];
    // console.log(fileSelected);

    var reader = new FileReader();
    // this.imagePath = fileSelected;
    reader.readAsDataURL(this.fileSelected);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };

    this.fileName = this.getFileName(this.fileSelected);
    console.log(this.fileName);
  }

  bannerUploadFile(event: any) {
    this.bannerFileSelected = event[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.bannerFileSelected);
    reader.onload = (_event) => {
      this.bannerImgURL = reader.result;
    };
    this.bannerFileName =
      'banner.' + this.bannerFileSelected.name.split('.').pop();
    console.log(this.bannerFileSelected);
  }

  bannerUploadFile1(event: any) {
    this.bannerFileSelected = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.bannerFileSelected);
    reader.onload = (_event) => {
      this.bannerImgURL = reader.result;
    };
    this.bannerFileName =
      'banner.' + this.bannerFileSelected.name.split('.').pop();
    console.log(this.bannerFileName);
  }

  checkLayout(event: any) {
    if (event.target.checked) {
      this.checkedlayout = event.target.value;
      this.orgForm.layout = this.checkedlayout;
    } else {
      this.checkedlayout = event.target.value;
      this.orgForm.layout = this.checkedlayout;
    }
  }
  getFileName(file: any) {
    var name = file.name.trim();
    console.log(name);
    var splitName = name.split('.');
    console.log(splitName);
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  removeImage() {
    this.fileName = '';
    this.fileSelected = '';
    this.imgURL = '';
    this.fileInput.nativeElement.value = '';
  }

  removeBannerImage() {
    this.bannerFileName = '';
    this.bannerFileSelected = '';
    this.bannerImgURL = '';
    this.bannerFileInput.nativeElement.value = '';
  }

  memberLimitChanged(event: any) {
    this.limit = event.target.value;
    if (this.limit == '001') {
      this.orgForm.limit = this.isEditing ? this.defaultCount : '5';
    } else {
      this.orgForm.limit = '';
    }
  }

  tasktypeChanged(event: any) {
    this.orgForm.tasktype = event.target.value;
  }

  taskLengthChanged(event: any) {
    this.orgForm.tasklength = event.target.value;
  }

  msChanged(event: any) {
    var value = event.target.value;
    this.orgForm.membership = value;
    if (this.orgForm.membership == '') {
      this.checkedlayout = '';
      this.orgForm.layout = '';
      this.orgForm.eid = false;
    } else {
      this.orgForm.eid = true;
      this.checkedlayout = '001';
      this.orgForm.layout = '001';
    }
    console.log(value);
  }

  eidChange() {
    if (this.orgForm.eid) {
      this.checkedlayout = '001';
      this.orgForm.layout = '001';
    } else {
      this.checkedlayout = '';
      this.orgForm.layout = '';
    }
  }

  sortData(sort: Sort) {
    const data = this.orgListToShow;
    if (!sort.active || sort.direction === '') {
      this.orgListToShow = data;
      return;
    }

    this.orgListToShow = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'orgname':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'des':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  getDomainList(): void {
    this.kunyekService.getDomains().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.domains.map((domain: any) => {
            this.domains.push({
              id: domain.id,
              appid: domain.appid,
              shortcode: domain.shortcode,
              domainid: domain.domainid,
              description: domain.description,
            });
          });
          this.gettingDomainList = false;
        } else {
          this.gettingDomainList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingDomainList = false;
      }
    );
  }

  getOrganization() {
    this.orgListLoading = true;
    return new Promise((resolve) => {
      this.orgListToShow = [];
      this.kunyekService.getOrgByDomainId(this.orgForm.domain).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.orgListToShow = res.datalist;
            // sort org list by name
            this.orgListToShow.sort((a: any, b: any) => {
              const isAsc = true;
              return this.allinoneService.compare(a.name, b.name, isAsc);
            });
            this.orgListLoading = false;

            resolve('success');
          } else {
            this.orgListLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
            resolve('error');
          }
        },
        (err) => {
          this.orgListLoading = false;
          resolve('error');
        }
      );
    });
  }

  addOrg() {
    this.orgAddorNot = true;
  }

  expdateclear() {
    this.orgForm.expirationdate = '';
  }

  cancel() {
    this.orgAddorNot = false;
    this.isEditing = false;
    this.orgSubmitted = false;
    this.orgForm = {
      domain: this.orgForm.domain.toString(),
      orgname: '',
      shortcode: '',
      description: '',
      membership: '002',
      emaildomain: '',
      layout: '001',
      eid: true,
      tasktype: '002',
      tasklength: '3',
      limit: '5',
      singleposition: 'false',
      sameempid: 'false',
      timeinoutrequired: true,
      activityrecordrequired: true,
      personallocationapproval: true,
      unregisteredtimeinoutapproval: true,
      backdateallowed: true,
      backdateapproval: true,
      showtransportation: true,
      useqr360: false,
      rolerequired: false,
      userequisition: false,
      usepayroll: false,
      expirationdate: new Date(),
    };
    this.limit = '002';
    this.imgURL = '';
    this.fileSelected = '';
    this.orgImgURL = '';
    this.fileName = '';

    this.orgBannerImgURL = '';
    this.bannerImgURL = '';
    this.bannerFileSelected = '';
    this.bannerFileName = '';

    this.defaultCount = '';
  }

  submitOrg() {
    this.orgSubmitted = true;

    if (
      this.orgForm.domain == '' ||
      this.orgForm.orgname == '' ||
      this.orgForm.shortcode == ''
    ) {
      return;
    } else if (
      this.orgForm.emaildomain &&
      this.publicMailDomain.indexOf(this.orgForm.emaildomain) > -1
    ) {
      this.messageService.openSnackBar(
        'Public email domains are not allowed.',
        'warn'
      );

      return;
    } else if (
      this.limit == '001' &&
      (isNaN(parseInt(this.orgForm.limit)) || parseInt(this.orgForm.limit) < 5)
    ) {
      this.messageService.openSnackBar(
        'Minimum limit for the member count is 5.',
        'warn'
      );
      return;
    } else {
      this.saveLoading = true;
      if (this.isEditing) {
        this.updateOrg();
      } else {
        this.saveOrg();
      }
    }
  }

  saveOrg() {
    const data = {
      orgname: this.orgForm.orgname,
      shortcode: this.orgForm.shortcode,
      orgdesc: this.orgForm.description,
      domainid: this.orgForm.domain,
      imagename: this.fileName,
      membership: this.orgForm.membership,
      layout: this.orgForm.layout,
      tagemail: this.orgForm.emaildomain,
      eid: this.orgForm.eid ? 'true' : 'false',
      tasktype: this.orgForm.tasktype,
      tasklength: this.orgForm.tasktype == '001' ? this.orgForm.tasklength : '',
      limit: this.limit == '002' ? '' : this.orgForm.limit.toString(),
      singleposition: this.orgForm.singleposition == 'true',
      sameempid: this.orgForm.sameempid == 'true',
      timeinoutrequired: this.orgForm.timeinoutrequired,
      activityrecordrequired: this.orgForm.activityrecordrequired,
      personallocationapproval: this.orgForm.personallocationapproval,
      unregisteredtimeinoutapproval: this.orgForm.unregisteredtimeinoutapproval,
      backdateallowed: this.orgForm.backdateallowed,
      backdateapproval: this.orgForm.backdateapproval,
      showtransportation: this.orgForm.showtransportation,
      useqr360: this.orgForm.useqr360,
      rolerequired: this.orgForm.rolerequired,
      userequisition: this.orgForm.userequisition,
      usepayroll: this.orgForm.usepayroll,
      orgbanner: this.bannerFileName,
      expirationdate: this.orgForm.expirationdate
        ? this.allinoneService.parseDate(this.orgForm.expirationdate)
        : '',
      neosync: this.orgForm.neosync,
      hcmsync: this.orgForm.hcmsync,
    };
    // console.log(data);
    // return
    this.kunyekService.addOrganization(data).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          if (this.bannerFileName != '') {
            if (this.bannerFileSelected.size >= 1048576) {
              this.bannerFileSelected =
                await this.allinoneService.compressImage(
                  this.bannerFileSelected
                );
            }
            const result = await this.allinoneService.newfileUploadToS3(
              this.bannerFileSelected,
              this.bannerFileName,
              res.orgid + '/'
            );
          }
          if (this.fileName != '' && this.orgImgURL != this.imgURL) {
            if (this.fileSelected.size >= 1048576) {
              this.fileSelected = await this.allinoneService.compressImage(
                this.fileSelected
              );
            }
            const result = await this.allinoneService.newfileUploadToS3(
              this.fileSelected,
              this.fileName,
              'organization/'
            );
            if (result) {
              this.saveLoading = false;
              this.orgAddorNot = false;
              this.orgListLoading = true;
              this.cancel();

              this.getOrganization();
              this.messageService.openSnackBar(
                'Saved successfully.',
                'success'
              );
            } else {
              this.messageService.openSnackBar(
                'Error while uploading attachment.',
                'warn'
              );
              this.saveLoading = false;
            }
          } else {
            this.saveLoading = false;
            this.orgAddorNot = false;
            this.cancel();
            this.orgListLoading = true;
            this.getOrganization();
            this.messageService.openSnackBar('Saved successfully.', 'success');
          }
        } else {
          this.saveLoading = false;
          this.orgSubmitted = false;
          // this.orgForm = {
          //   domain: this.orgForm.domain.toString(), orgname: "", description: ""
          // };
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.orgSubmitted = false;
      }
    );
  }

  updateOrg() {
    const data = {
      domainid: this.orgForm.domain,
      orgid: this.orgIdToEdit,
      name: this.orgForm.orgname,
      shortcode: this.orgForm.shortcode,
      description: this.orgForm.description,
      imagename: this.fileName,
      membership: this.orgForm.membership,
      layout: this.orgForm.layout,
      tagemail: this.orgForm.emaildomain,
      eid: this.orgForm.eid ? 'true' : 'false',
      tasktype: this.orgForm.tasktype,
      tasklength: this.orgForm.tasktype == '001' ? this.orgForm.tasklength : '',
      limit: this.limit == '002' ? '' : this.orgForm.limit.toString(),
      singleposition: this.orgForm.singleposition == 'true',
      sameempid: this.orgForm.sameempid == 'true',
      timeinoutrequired: this.orgForm.timeinoutrequired,
      activityrecordrequired: this.orgForm.activityrecordrequired,
      personallocationapproval: this.orgForm.personallocationapproval,
      unregisteredtimeinoutapproval: this.orgForm.unregisteredtimeinoutapproval,
      backdateallowed: this.orgForm.backdateallowed,
      backdateapproval: this.orgForm.backdateapproval,
      showtransportation: this.orgForm.showtransportation,
      useqr360: this.orgForm.useqr360,
      rolerequired: this.orgForm.rolerequired,
      userequisition: this.orgForm.userequisition,
      usepayroll: this.orgForm.usepayroll,
      orgbanner: this.bannerFileName,
      expirationdate: this.orgForm.expirationdate
        ? this.allinoneService.parseDate(this.orgForm.expirationdate)
        : '',
      neosync: this.orgForm.neosync,
      hcmsync: this.orgForm.hcmsync,
    };
    console.log(data);
    this.kunyekService.updateOrganization(data).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          if (this.allinoneService.orgs.length > 0) {
            const index = this.allinoneService.orgs.findIndex(
              (x: any) => x.orgid == this.orgIdToEdit
            );
            if (index > -1) {
              this.allinoneService.orgs[index].tagemail =
                this.orgForm.emaildomain;
              this.allinoneService.orgs[index].name = this.orgForm.orgname;
              this.allinoneService.orgs[index].membership =
                this.orgForm.membership;
              this.allinoneService.orgs[index].layout = this.orgForm.layout;

              this.allinoneService.orgs[index].showtransportation =
                this.orgForm.showtransportation;
              this.allinoneService.orgs[index].userequisition =
                this.orgForm.userequisition;
              this.allinoneService.orgs[index].rolerequired =
                this.orgForm.rolerequired;
            }
          }
          if (this.allinoneService.contributorOrgs.length > 0) {
            const index = this.allinoneService.contributorOrgs.findIndex(
              (x: any) => x.orgid == this.orgIdToEdit
            );
            if (index > -1) {
              this.allinoneService.contributorOrgs[index].tagemail =
                this.orgForm.emaildomain;
              this.allinoneService.contributorOrgs[index].name =
                this.orgForm.orgname;
              this.allinoneService.contributorOrgs[index].membership =
                this.orgForm.membership;
              this.allinoneService.contributorOrgs[index].layout =
                this.orgForm.layout;
              this.allinoneService.orgs[index].showtransportation =
                this.orgForm.showtransportation;
              this.allinoneService.orgs[index].userequisition =
                this.orgForm.userequisition;
              this.allinoneService.orgs[index].rolerequired =
                this.orgForm.rolerequired;
            }
          }
          if (
            this.bannerFileName != '' &&
            this.orgBannerImgURL != this.bannerImgURL
          ) {
            if (this.bannerFileSelected.size >= 307200) {
              this.bannerFileSelected =
                await this.allinoneService.compressImage(
                  this.bannerFileSelected
                );
            }
            const result = await this.allinoneService.newfileUploadToS3(
              this.bannerFileSelected,
              this.bannerFileName,
              this.orgIdToEdit + '/'
            );
          }
          if (this.fileName != '' && this.orgImgURL != this.imgURL) {
            if (this.fileSelected.size >= 307200) {
              this.fileSelected = await this.allinoneService.compressImage(
                this.fileSelected
              );
            }
            const result = await this.allinoneService.newfileUploadToS3(
              this.fileSelected,
              this.fileName,
              'organization/'
            );
            if (result) {
              this.saveLoading = false;
              this.orgAddorNot = false;
              this.orgSubmitted = false;
              this.isEditing = false;
              this.orgListLoading = true;
              this.cancel();
              this.getOrganization();
              this.messageService.openSnackBar(
                'Saved successfully.',
                'success'
              );
            } else {
              this.messageService.openSnackBar(
                'Error while uploading attachment.',
                'warn'
              );
              this.saveLoading = false;
            }
          } else {
            this.saveLoading = false;
            this.orgAddorNot = false;
            this.orgListLoading = true;
            this.cancel();
            this.getOrganization();
            this.messageService.openSnackBar('Saved successfully.', 'success');
          }
        } else {
          this.saveLoading = false;
          // this.orgSubmitted = false;
          // this.isEditing = false;
          // this.orgForm = {
          //   domain: this.orgForm.domain.toString(), orgname: "", description: ""
          // };
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  editOrg(org: any) {
    console.log(org);
    this.allinoneService.scrollTo('form');
    this.isEditing = true;
    this.addOrg();
    this.orgForm.orgname = org.name;
    this.orgForm.shortcode = org.shortcode;
    this.orgForm.description = org.description;
    this.orgIdToEdit = org.orgid;

    this.orgImgURL = org.imageurl;
    this.imgURL = org.imageurl;
    this.fileName = org.imagename;

    this.bannerFileName = org.orgbanner;
    this.bannerImgURL = org.orgbannerurl;
    this.orgBannerImgURL = org.orgbannerurl;

    this.checkedlayout = org.layout;
    this.orgForm.layout = org.layout;
    this.orgForm.membership = org.membership;
    this.orgForm.emaildomain = org.tagemail;
    this.orgForm.eid = org.eid == 'true' ? true : false;
    this.orgForm.tasktype = org.tasktype ? org.tasktype : '002';
    this.orgForm.tasklength = org.tasklength ? org.tasklength : '3';
    this.orgForm.limit = org.limit;
    this.orgForm.singleposition = org.singleposition ? 'true' : 'false';
    this.orgForm.sameempid = org.sameempid ? 'true' : 'false';
    this.orgForm.timeinoutrequired = org.timeinoutrequired;
    this.orgForm.activityrecordrequired = org.activityrecordrequired;
    this.orgForm.personallocationapproval = org.personallocationapproval;
    this.orgForm.unregisteredtimeinoutapproval =
      org.unregisteredtimeinoutapproval;
    this.orgForm.backdateallowed = org.backdateallowed;
    this.orgForm.backdateapproval = org.backdateapproval;
    this.orgForm.showtransportation = org.showtransportation;
    this.orgForm.useqr360 = org.useqr360;
    this.orgForm.rolerequired = org.rolerequired;
    this.orgForm.userequisition = org.userequisition;
    this.orgForm.usepayroll = org.usepayroll || false;
    this.limit = org.limit == '' ? '002' : '001';
    this.defaultCount = org.limit;
    this.orgForm.expirationdate = org.expirationdate
      ? this.allinoneService.parseDateToShowInEdit(org.expirationdate)
      : '';
    this.orgForm.neosync = org.neosync;
    this.orgForm.hcmsync = org.hcmsync;

    console.log(this.orgForm.limit);
  }

  viewMembers() {
    const org = this.orgListToShow.find(
      (org: any) => org.orgid == this.orgIdToEdit
    );
    if (org) {
      this.allinoneService.setSession(
        'organization',
        JSON.stringify(org) || 'undefined'
      );
      // this.allinoneService.setCookie(
      //   'organization',
      //   JSON.stringify(org) || 'undefined'
      // );
    }
    // this.router.navigateByUrl('teams/users?org=' + this.orgIdToEdit);
    this.router.navigateByUrl('references/users?org=' + this.orgIdToEdit);
  }
  viewLocations() {
    const org = this.orgListToShow.find(
      (org: any) => org.orgid == this.orgIdToEdit
    );
    if (org) {
      this.allinoneService.setCookie(
        'organization',
        JSON.stringify(org) || 'undefined'
      );
    }
    this.router.navigateByUrl('/locations?org=' + this.orgIdToEdit);
  }
  viewEvents() {
    const org = this.orgListToShow.find(
      (org: any) => org.orgid == this.orgIdToEdit
    );
    if (org) {
      this.allinoneService.setCookie(
        'organization',
        JSON.stringify(org) || 'undefined'
      );
    }
    this.router.navigateByUrl('/events?org=' + this.orgIdToEdit);
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  clear() {
    this.searchText = '';
  }

  refresh() {
    this.currentPage = 1;
    this.getOrganization();
  }
  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }
}
