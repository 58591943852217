import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import ListConfig from 'src/app/shared/interfaces/listconfig';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-tempswimmer-list',
  templateUrl: './temp-swimmer-list.component.html',
  styleUrls: ['./temp-swimmer-list.component.scss']
})
export class TempSwimmerListComponent {
  
  configs: ListConfig[] = [
    { title: 'Name', property: 'name' },
    { title: 'Nationality', property: 'nationality' },
    { title: 'Country', property: 'country' },
    { title: 'Birthdate', property: 'birthdate' },
    { title: 'Gender', property: 'gender' },
    { title: 'Occupation', property: 'occupation' },
    { title: 'Company', property: 'company' },
    { title: 'Arrival Date', property: 'arrivaldate' },
    { title: 'Phone', property: 'phone' },
    { title: 'Email', property: 'email' }
];

  url = ['/members/templist']
  searchName: string = '';
  searchText = "Search Temporary Swimmer"
  cb = this.kunyekService.tempMember().get.bind(this.kunyekService)
  breadcrumbs: any[] = [
    { name: 'Swim', url: 'swim', isActive: true },
    { name: 'Temporary Swimmer', url: '', isActive: false }
  ];

  constructor(
    private router: Router,
    private kunyekService: KunyekService,
  ) { }

  previewTempSwimmer(id: string) {
    this.router.navigate([...this.url,'preview',id])
  }
}
