import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Component, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { IMyDpOptions } from 'mydatepicker';

@Component({
  selector: 'app-batch-form',
  templateUrl: './batch-form.component.html',
  styleUrls: ['./batch-form.component.scss']
})
export class BatchFormComponent implements OnInit {
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };

  isSaving: boolean = false;
  saved: boolean = false;
  batchForm!: FormGroup;
  requiredMessage = 'This field must not be empty';
  breadcrumbs: any[] = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public allInOneservice: AllInOneService,
  ) { }

  ngOnInit(): void {
    this.batchForm = this.fb.group({
      batch_no: ['', Validators.required],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      time: ['', Validators.required],
      batch_type: ['', Validators.required],
      remark: ['']
    });
    this.setBreadcrumbs()
  }

  setBreadcrumbs() {
    this.breadcrumbs = [
      { name: 'Swim', url: 'swim', isActive: true },
      { name: 'Batch', url: 'members/batchlist', isActive: true },
      { name: 'New', url: '', isActive: false }
    ];
  }

  async onSaved() {
    this.isSaving = true;
    if (this.batchForm.valid) {
      const formattedStartDate = this.start_date?.value?.formatted;
      const formattedEndDate = this.start_date?.value?.formatted;
      const formData = {
        batch_no: this.batchForm.value.batch_no,
        start_date: this.allInOneservice.convertDate(formattedStartDate),
        end_date: this.allInOneservice.convertDate(formattedEndDate),
        time: this.batchForm.value.time,
        batch_type: this.batchForm.value.batch_type,
        remark: this.batchForm.value.remark
      };
      this.kunyekService.getBatch().create(formData).subscribe(
        (res: any) => {
          this.isSaving = false;
          if (res.returncode === '300') {
            this.messageService.openSnackBar(res.message, 'success');
            this.closeForm();
          } else if (res.returncode === '301') {
            this.messageService.openSnackBar("Batch number already exist!", "warn");
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.messageService.openSnackBar(err, 'warn');
          this.isSaving = false;
        }
      );
    } else {
      this.saved = true;
      this.messageService.openSnackBar('Invalid Form', 'warn');
      this.isSaving = false
      this.batchForm.markAllAsTouched();
    }
  }

  closeForm() {
    this.router.navigate(['members', 'batchlist']);
  }

  get batch_no() { return this.batchForm.get('batch_no'); }
  get start_date() { return this.batchForm.get('start_date'); }
  get end_date() { return this.batchForm.get('end_date'); }
  get time() { return this.batchForm.get('time'); }
  get batch_type() { return this.batchForm.get('batch_type'); }
  get remark() { return this.batchForm.get('remark'); }

}
