import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-membertypes',
  templateUrl: './membertypes.component.html',
  styleUrls: ['./membertypes.component.scss'],
})
export class MembertypesComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild('tbody') tbody!: ElementRef;

  ccFormSubmitted: boolean = false;
  editFormSubmitted: boolean = false;
  gettingCcList: boolean = false;
  isEditing: boolean = false;
  disable: boolean = true;
  saveLoading: boolean = false;
  fromOrg: boolean = false;
  classCodeAddOrNot: boolean = false;

  ccList: any[] = [];
  organizations: any[] = [];
  membershiptype: any = '';
  domainid: string = '';

  p: number = 1;

  type: string = '001'; // 001 - Employment Type , 002 - Transaction Type

  ccForm = {
    orgid: '',
    id: '',
    name: '',
    description: '',
    status: '',
  };

  statusList = [
    {
      id: 1,
      name: 'Inactive',
    },
    {
      id: 2,
      name: 'Rejoin',
    },
    {
      id: 3,
      name: 'Probation',
    },
    {
      id: 5,
      name: 'Promotion',
    },
    {
      id: 6,
      name: 'Transfer',
    },
    {
      id: 7,
      name: 'Dismiss',
    },
    {
      id: 8,
      name: 'Adjustment',
    },
    {
      id: 9,
      name: 'Default',
    },
    {
      id: 10,
      name: 'Demotion',
    },
    {
      id: 11,
      name: 'Extend',
    },
    {
      id: 13,
      name: 'Blacklist',
    },
  ];

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<MembertypesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fromOrg = data.fromOrg;
    this.type = data.type;
    if (this.fromOrg) {
      this.organizations.push(data.org);
      this.ccForm.orgid = data.org.orgid;
    }
    else{
      this.ccForm.orgid = data.orgid;
    }
  }

  ngOnInit(): void {
    // if (!this.fromOrg) {
    //   this.organizations = this.allinoneService.orgs;
    //   this.ccForm.orgid = this.allinoneService.selectedOrg
    //     ? this.allinoneService.selectedOrg
    //     : this.organizations[0].orgid;
    // }
    this.getMemberTypes();
  }

  /**
   * The function takes in a sort object and sorts the data based on the sort object's active and
   * direction properties
   * @param {Sort} sort - Sort - This is the object that contains the sort information.
   * @returns The data is being sorted by the column that is clicked on.
   */
  sortData(sort: Sort) {
    const data = this.ccList;
    if (!sort.active || sort.direction === '') {
      this.ccList = data;
      return;
    }

    this.ccList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'classcode':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  /**
   * This function is used to cancel the form and reset the form values
   */
  cancel() {
    this.isEditing = false;
    this.classCodeAddOrNot = false;
    this.ccFormSubmitted = false;
    this.ccForm = {
      id: '',
      name: '',
      description: '',
      status: '',
      orgid: this.ccForm.orgid,
    };
  }

  /**
   * This function is used to edit the class code
   * @param {any} item - any - This is the item that is being edited.
   */
  editClasscode(item: any) {
    this.isEditing = true;
    this.classCodeAddOrNot = true;
    this.ccForm = {
      orgid: this.ccForm.orgid,
      id: item.id,
      name: item.name,
      description: item.description,
      status: item.status,
    };
  }

  submitCc() {
    this.ccFormSubmitted = true;
    if (this.ccForm.name.trim() == '') {
      return;
    } else {
      if (this.type == '002' && this.ccForm.status == '') {
        return;
      }
      this.saveCc();
    }
  }

  /**
   * It saves the class code data to the database
   */
  saveCc() {
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var data = {
      orgid: this.ccForm.orgid,
      name: this.ccForm.name,
      description: this.ccForm.description,
      status: this.type == '001' ? '' : this.ccForm.status,
      type: this.type,
    };

    if (this.isEditing) {
      data = Object.assign(data, { id: this.ccForm.id });
    }

    const fun = this.isEditing
      ? this.kunyekService.updateMemberTypes(data)
      : this.kunyekService.createMemberType(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.getMemberTypes();
          this.cancel();
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar('Saved successfully.', 'success');
        } else {
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.ccFormSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
        this.ccFormSubmitted = false;
      }
    );
  }

  getMemberTypes() {
    this.gettingCcList = true;
    const data = {
      orgid: this.ccForm.orgid,
      type: this.type,
    };
    this.kunyekService.getMemberTypes(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.ccList = res.datalist;
          if (this.type == '002') {
            this.ccList = this.ccList.map((x: any) => ({
              ...x,
              statusname: this.statusList.find(
                (item: any) => item.id == x.status
              )?.name,
            }));
          }
          this.gettingCcList = false;
        } else {
          this.gettingCcList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingCcList = false;
      }
    );
  }

  checkOverflow(element: any) {
    return element.offsetHeight < element.scrollHeight;
  }
}
