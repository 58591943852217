import { CrmComponent } from './../crm/crm.component';
import { data } from 'jquery';
import { ProjectsComponent } from './../../admin/projects/projects.component';
import { CrmServiceFormComponent } from './../crm-service-form/crm-service-form.component';
import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { CrmStatusFormComponent } from '../status-form/status-form.component';
import { CrmPriorityFormComponent } from '../crm-priority-form/crm-priority-form.component';
import { CrmProductComponent } from '../crm-product/crm-product.component';
import { CrmPersonInChargeComponent } from '../crm-person-in-charge/crm-person-in-charge.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { CrmCustomerComponent } from '../crm-customer/crm-customer.component';

@Component({
  selector: 'app-crm-service-add',
  templateUrl: './crm-service-add.component.html',
  styleUrls: ['./crm-service-add.component.scss'],
})
export class CrmServiceAddComponent implements OnInit {
  @ViewChild('hiddenpdffileinput') hiddenpdffileinput: any;

  @ViewChild('recordfocus', { static: false })
  recordfocus!: ElementRef;

  @Input() orgid: any;
  @Input() checkOrg: any;
  @Input() customercare: any;
  @Input() customerid: any;

  @Input() businessType: any[] = [];
  @Input() currencyType: any = [];
  // @Input() statuslist: any = [];
  @Input() editData: any;
  @Input() customerStatus: any = [];
  @Input() statuslist: any = [];
  @Input() servielist: any = [];
  @Input() priority: any = [];
  @Input() projectList: any = [];
  @Input() customercaredata: any = [];

  recurringCheck: any = '';
  updateRecord: boolean = false;
  recordid: any = '';
  manhourFormSave: boolean = false;
  manhourForm = {
    assignedto: '',
    activity: '',
    manhour: '',
  };
  savemanmanhour: boolean = false;

  // statuslist :any = [];
  // servielist:any = [];
  // priority:any = [];
  // projectList: any[] = [];

  statuslistCustomercare: any = [
    { code: '0010', name: 'New' },
    { code: '00900', name: 'Closed' },
  ];
  manhourlist: any = [
    {
      assignedto: 'Nyi',
      activity: 'Test',
      manhour: 'mnahour',
    },
  ];
  recordAttachmentList: any = [];
  getttingManhour: boolean = true;

  text1: any = '';
  text2: any = '';
  // this.getServcieType();
  //   this.getStatusList();
  //   this.getAllProjects();
  //   this.getCustomers();
  //   this.getPriorityList();

  editStatus: boolean = false;

  @Output('save')
  save: EventEmitter<any> = new EventEmitter<any>();

  @Output('servicecancel')
  cancel: EventEmitter<any> = new EventEmitter<any>();

  @Output('serviceadd')
  serviceadd: EventEmitter<any> = new EventEmitter<any>();
  isMobileView: boolean = false;
  isView: boolean = false;

  mobileViewWidth: any = 426;
  viewWidth: any = 992;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (window.innerWidth < this.viewWidth) {
      this.isView = true;
    } else {
      this.isView = false;
    }
  }

  saveLoading: boolean = false;
  isEditing: boolean = false;
  serviceFormSubmitted: boolean = false;
  RecordFormSubmitted: boolean = false;
  attachmentList: any[] = [];
  deleteFileList: any[] = [];
  membersearch: string = '';
  picValue: string = '';
  gettingProjectList: boolean = false;

  gettingCustomers: boolean = false;
  gettingEmployee: boolean = false;
  isdomainAdmin: boolean = false;
  ppporouctjf: any = '';

  customers: any[] = [];
  branchlist: any = [];

  contactadmin: boolean = false;

  custoemrName: any = [
    {
      customername: 'nyie Ye Lwin',
      customeremail: 'email',
      customermobile: '92992',
    },
    {
      customername: 'nyie Ye Lwin',
      customeremail: 'email',
      customermobile: '92992',
    },
  ];
  members: any[] = [];
  callType: string = '';

  isMove: boolean = false;
  getServiceType: boolean = false;
  getSatausType: boolean = false;
  getProiority: boolean = false;

  updatename: any = '';
  updatetime: any = '';
  @Input() t_breadcrumb!: string;
  @Input() f_breadcrumb!: string;

  aweekAfter = new Date().setDate(new Date().getDate() + 10);
  formatTotime = 'HH:mm';
  mainformatotShow = 'yyyy-MM-ddTHH:mm';
  record = 'dd/MM/yyyy hh:mm aa';
  // formatToShow = 'yyyy-MM-dd';
  // yyyy-MM-ddTHH:mm
  formatToShow = 'yyyy-MM-ddTHH:mm';
  // 2022-05-28T14:16"
  startdate: any = '';
  // starttime: any = "";
  // enddate: any = "";
  endtime: any = '';
  closeType: boolean = false;
  closetyploading: boolean = false;
  checkrecurring: boolean = false;
  checkinternal: boolean = false;
  // 2022-05-19T17:03
  servicetype: any = '';
  statusnew: any = [];
  assignedtolist: any = [];
  manhourassignedlist: any = [];
  ngassignedtolist: any = [];
  customertypeint: any;
  serviceTypeShowList: any = [];
  servicetypelist: any = [];

  statusShowList: any = [];
  moduleidList: any = [];
  customerstatusList: any = [];
  branchname: any = '';

  serviceForm = {
    serviceid: '',
    autoid: '',
    tickeno: 'TBA',
    description: '',
    status: '0010',
    statusname: '',
    reportedby: '',
    assignedto: '',
    assingtouserid: '',
    startdatetime: '',
    enddatetime: '',
    customername: '',
    customerid: '',
    domainid: '',
    orgid: '',
    // "assignedtoid": "",
    // "assignedtoname": "",
    project: '',
    projectid: '',
    remark: '',
    priority: '0010',
    servciename: '',
    customerphone: '',
    customeremail: '',
    typename: '',
    detail: '',
    solution: '',
    companyname: '',
    product: '',
    productid: '',
    sku: '',
    pic: '',
    picid: '',
    refno: '',
    version: '',
    mer: '',
    // "moduleid": "",
    modulename: '',
    reopen: '',
    reticketno: '',
    // "completedatetime": "",
    targetdatetime: '',
    duedatetime: '',
    checkinternal: '',
    branchid: '',
  };

  ngbranchlist: any = [];

  contactid: any = '';
  contactname: any = '';
  contactlist: any = [];

  status = [
    { status: 'New', code: '001' },
    { status: 'Closed', code: '002' },
  ];

  picList: any = [];
  RecordList: any = [];
  recordDesc: any = '';
  saverecord: boolean = false;
  statuscheck: boolean = false;
  statuslabel: any = '';
  statuscustomercode: any = '';
  modulelist: any = [];
  recurringstatus: any = '';

  moveShow = {
    tickeno: '',
    project: '',
    priority: '',
    typename: '',
    status: '',
    product: '',
    employeename: '',
    startdatetime: '',
    enddatetime: '',
  };
  deleterecordFileList: any = [];
  goserviceTicket: boolean = false;
  permission: any = [];
  isProduct: boolean = false;
  isProject: boolean = false;
  isPic: boolean = false;
  isFocus: boolean = false;
  modulecheck: boolean = false;
  editsavecheck: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router
  ) {
    console.log(this.editData);

    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;
    }
    this.isdomainAdmin = this.allinoneService.crmOrgAdmin === 'true';
    if (this.isdomainAdmin == false) {
      console.log(this.permission);
      if (!this.isdomainAdmin) {
        if (this.permission.length > 0) {
          var type = this.permission.map((x: any) => x).indexOf('0010');
          if (type > -1) {
            this.isProduct = true;
          }
          var type1 = this.permission.map((x: any) => x).indexOf('009');
          if (type1 > -1) {
            this.isProject = true;
          }
          var type2 = this.permission.map((x: any) => x).indexOf('0011');
          if (type2 > -1) {
            this.isPic = true;
          }
        }
      }
    }

    const event = new Date();
    // expected output: Wed Oct 05 2011 16:48:00 GMT+0200 (CEST)
    // (note: your timezone may vary)
    this.http.get<any>('https://worldtimeapi.org/api/ip').subscribe(
      (data) => {
        const date = data.utc_datetime;
        this.serviceForm.startdatetime = formatDate(
          date,
          this.formatToShow,
          'en-US'
        ).toString();
      },
      (error) => {
        console.error('Error fetching current date:', error);
      }
    );

    if (this.serviceForm.startdatetime == '') {
      this.serviceForm.startdatetime = formatDate(
        Date.now(),
        this.formatToShow,
        'en-US'
      ).toString();
    }
    // this.serviceForm.targetdatetime = formatDate(
    //   Date.now(),
    //   this.formatToShow,
    //   'en-US'
    //   , ).toString();
    // this.serviceForm.completedatetime = formatDate(
    //   Date.now(),
    //   this.formatToShow,
    //   'en-US'
    //   , ).toString();

    console.log('Okstartdate');
    console.log(this.serviceForm.startdatetime);

    this.serviceForm.enddatetime = formatDate(
      Date.now(),
      this.formatToShow,
      'en-US'
    ).toString();

    this.serviceForm.startdatetime = formatDate(
      Date.now(),
      this.formatToShow,
      'en-US',
      'UTC'
    ).toString();

    const defaultStarttime = formatDate(
      Date(),
      this.formatTotime,
      'en-US'
    ).toString();

    const defaultEndTime = formatDate(
      Date.now(),
      this.formatTotime,
      'en-US'
    ).toString();

    // formatTotime
    console.log('ejfijeij');
    console.log(this.serviceForm.startdatetime);
    // // yyyy-MM-dd hh:mm:ss aa
    // this.startdate = defaultOpenDate;
    // this.enddate = defaultEndDate;
    // // this.starttime = defaultStarttime;
    // this.endtime = defaultEndTime;

    // console.log(defaultOpenDate + "T" + defaultStarttime)

    this.activatedRoute.data.subscribe((data) => {
      if (data.type == 'org') {
        this.callType = '300';
        // if (!this.allinoneService.isAdminOf('org')) {
        //   this.router.navigateByUrl('/home');
        // }s
      } else {
        // if (!this.allinoneService.isAdminOf('contributor')) {
        //   this.router.navigateByUrl('/home');
        // }
        this.callType = '400';
      }
    });
  }

  async ngOnInit() {
    if (this.editData) {
      this.isEditing = true;
      this.editsavecheck = true;

      if (this.editData.status == '0010') {
        this.editStatus = true;
      }
      if (this.editData.status == '00900') {
        this.statuscheck = true;
      }
    }

    this.serviceForm.domainid = this.allinoneService.getDomain().domainid;
    this.serviceForm.orgid = this.orgid;
    this.goserviceTicket = false;
    if (this.customercare == true) {
      this.statuscustomercode = this.editData.status;
    }
    this.getCustomers();
    this.getServcieType();
    this.getStatusList();
    this.getAllProjects();
    this.getPriorityList();
    this.getProduct();
    await this.getPIC();

    if (this.editData) {
      this.isEditing = true;
      console.log(this.editData);
      if (this.editData.status == '00900') {
        this.statuscheck = true;
      }
      this.statuslabel = this.editData.status == '0040' ? 'Completed' : 'WIP';
      if (this.serviceForm.domainid && this.serviceForm.orgid) {
      }

      this.updatetime = this.editData.lastupdatetime;
      this.updatename = this.editData.lastupdatename;

      var type = this.statuslist
        .map((x: any) => x.code)
        .indexOf(this.editData.status);
      this.moveShow = {
        tickeno: this.editData.ticketno,
        project: this.editData.project,
        priority: this.editData.priority,
        typename: this.editData.typename,
        status: this.statuslist[type].name,
        employeename: this.editData.assignedtoname,
        startdatetime: this.editData.startdatetime,
        enddatetime: this.editData.enddatetime,
        product: this.editData.product,
      };
      this.serviceForm = {
        serviceid: this.editData.serviceid,
        autoid: this.editData.autoid,
        tickeno: this.editData.ticketno,
        description: this.editData.editservciedesc,
        servciename: this.editData.typename,
        statusname: this.editData.statusname,
        // servicetype: this.editData.type,
        status: this.editData.status,
        reportedby: this.editData.reportedby,
        assignedto: '',
        startdatetime: this.editData.startdatetoshow,
        enddatetime: this.editData.enddatetoshow,
        customername: this.editData.name,
        customerid: this.editData.customerid,
        domainid: this.editData.domainid,
        orgid: this.editData.orgid,
        // assignedtoid: this.editData.assignedto,
        // assignedtoname: this.editData.assignedtoname,
        project: this.editData.projectid,
        projectid: this.editData.projectid,
        remark: this.editData.remark,
        priority: this.editData.priority,
        customeremail: this.editData.customeremail,
        customerphone: this.editData.customerphone,
        typename: this.editData.typename,
        detail: this.editData.details,
        solution: this.editData.solution,
        companyname: this.editData.companyname,
        product: this.editData.product,
        sku: '',
        pic: this.editData.assignedtoname,
        picid: '',
        refno: this.editData.refno,
        productid: this.editData.productid,
        // "assignedto": this.serviceForm.picid == "" ? this.picValue : this.serviceForm.picid,
        assingtouserid: this.editData.assignedtouserid,
        version: this.editData.version,
        mer: this.editData.mer,
        // moduleid: this.editData.moduleid,
        modulename: this.editData.modulename,
        reopen: this.editData.reticketno ? this.editData.reopen : '',
        reticketno: this.editData.reticketno,
        targetdatetime: this.editData.targetdatetime,
        duedatetime: this.editData.duedatetime,
        checkinternal: '',
        branchid: this.editData.branchid,
        // targetdatetime: formatDate(new Date(this.editData.targetdatetime), this.formatToShow, 'en-US',).toString(),
        // completedatetime: formatDate(new Date(this.editData.completedatetime), this.formatToShow, 'en-US', 'UTC').toString()
      };
      this.checkvoid = this.editData.voidticket;
      this.serviceForm.branchid = this.editData.branchid;

      console.log(this.editData.branchid);

      let selectedRole: any = [];
      for (var j = 0; j < this.picList.length; j++) {
        for (var i = 0; i < this.editData.assignedto.length; i++) {
          if (this.picList[j]['picid'] == this.editData.assignedto[i]) {
            selectedRole.push({
              picid: this.picList[j]['picid'],
              name: this.picList[j]['name'],
              userid: this.picList[j]['userid'],
            });
          }
        }
      }

      this.assignedtolist = this.editData.assignedto;
      this.ngassignedtolist = selectedRole;
      console.log('selectrole');
      console.log(this.ngassignedtolist);

      this.manhourassignedlist = this.ngassignedtolist;

      if (this.editData.isinternal == 'true') {
        this.checkinternal = true;
      } else {
        this.checkinternal = false;
      }

      this.servicetypelist = this.editData.servcietypelist;

      this.servicetype = this.editData.type;
      this.statusnew = this.editData.statuslist;

      if (this.editData.moduleid != null) {
        this.modulelist = this.editData.modulelist;
        if (this.modulelist.length > 0) {
          var type = this.modulelist
            .map((x: any) => x.moduleid)
            .indexOf(this.editData.moduleid);
          if (type > -1) {
            this.moduleidList = this.modulelist[type];
          }
        }

        this.modulecheck = true;
      }
      this.checkrecurring = this.editData.reopen == 'true' ? true : false;

      this.serviceForm.productid = this.editData.productid;
      this.projectValue = this.editData.project;
      this.serviceForm.picid = this.editData.assignedto;
      this.picValue = this.editData.assignedtoname;

      this.membersearch = this.editData.assignedtoname;
      this.attachmentList = this.editData.filelist;
      this.recurringCheck = this.editData.ticketno;
      this.recurringstatus = this.editData.status;
      // this.searchContactName
      this.contactid = this.editData.companyname;
      if (this.editData.contacts) {
        this.searchContactName = this.editData.contacts.name;
      } else if (this.editData.companyname) {
        this.searchContactName = this.editData.companyname;
      }

      // this.serviceForm.enddatetime = formatDate(
      //   Date.now(),
      //   this.formatToShow,
      //   'en-US'
      // ).toString();
      // this.serviceForm.enddatetime = this.editData.enddatetoshow
      this.serviceForm.startdatetime = this.editData.startdatetoshow;
      // this.serviceForm.enddatetime = formatDate(new Date(this.editData.stastartdatetoshowrtdatetime), this.formatToShow, 'en-US', 'UTC').toString()
      this.serviceForm.enddatetime = formatDate(
        new Date(this.editData.enddatetoshow),
        this.formatToShow,
        'en-US',
        'UTC'
      ).toString();
      //
      // this.serviceForm.startdatetime = formatDate(new Date(this.editData.startdatetoshow), this.mainformatotShow, 'en-US','UTC').toString()
      // this.starttime = formatDate(new Date(this.editData.startdatetoshow), this.formatTotime, 'en-US').toString()
      this.getRecordList();
      this.getManhour();

      this.editsavecheck = false;
    }

    if (this.customercare == true) {
      this.statuscustomercode = this.editData.status;
      if (this.customercaredata != '') {
        this.serviceForm.customerid = this.customercaredata.customerid;

        this.serviceForm.customername = this.customercaredata.customername;
        // this.branchname = this.customercaredata.branchname;

        if (this.customercaredata.contacts != '') {
          this.searchContactName =
            this.customercaredata.contacts[0].contactname;
          this.contactid = this.customercaredata.contacts[0].contactid;
          this.contactadmin =
            this.customercaredata.contacts[0].role == '300' ? true : false;
        }
      }
    }

    console.log(this.customercaredata);
    console.log('okwefko');

    // this.getEmployee();
  }

  cancelFunction() {
    console.log('cancel');

    if (this.goserviceTicket == true) {
      const data = {
        type: true,
      };
      console.log('11');
      this.cancel.emit(data);
    } else {
      this.cancel.emit();
      console.log('22');
    }
  }

  submitService() {
    this.serviceFormSubmitted = true;

    // if (this.serviceForm.customerphone != '') {
    //   if ((/([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g).test(this.allinoneService.checkUserId(this.serviceForm.customerphone.toString().trim())) == false) {
    //     //  if(this.leadForm.phone == '' || this.leadForm.phone.substring(0, 2) != '09' || this.leadForm.phone.substring(0, 4) != '+959' || this.leadForm.phone.toString().length > 12){
    //     this.messageService.openSnackBar("Please enter valid phone number.", 'warn');
    //     return;
    //   }
    if (this.recordAttachmentList.length > 0) {
      this.RecordFormSubmitted = true;
    }
    // }
    // console.log(this.)
    if (this.checkinternal == true) {
      if (
        this.servicetype &&
        this.serviceForm.description &&
        this.serviceForm.status
      ) {
        console.log('1');
        if (this.recordAttachmentList.length > 0 && this.recordDesc == '') {
          return;
        }
        this.saveLoading = true;
        this.saveService();
      }
      console.log('2');
    } else {
      if (
        this.servicetype &&
        this.serviceForm.description &&
        this.serviceForm.status &&
        this.serviceForm.customerid
      ) {
        console.log('1');
        if (this.recordAttachmentList.length > 0 && this.recordDesc == '') {
          return;
        }
        this.saveLoading = true;

        this.saveService();
      }
      console.log('2');
    }
  }

  async saveService() {
    var tempFileList = this.attachmentList;
    this.attachmentList = [];

    var recordTempfilelist = this.recordAttachmentList;
    this.recordAttachmentList = [];

    recordTempfilelist.map((item: any) => {
      if (!item.fileurl) {
        this.recordAttachmentList.push(item);
      }
    });

    tempFileList.map((item: any) => {
      if (!item.fileurl) {
        this.attachmentList.push(item);
      }
    });
    // (this.isEditing? this.serviceForm.id : tempFileName).toString()
    var filepath = 'crm/service';
    if (this.recordDesc) {
      if (this.recordAttachmentList.length > 0) {
        const upload_aws = await this.allinoneService.multipleUploadToAWS(
          this.recordAttachmentList,
          filepath
        );
        if (!upload_aws) {
          this.messageService.openSnackBar(
            'Unknown error occured while uploading your files.',
            'warn'
          );
          return;
        }
      }
    }

    if (this.attachmentList.length > 0) {
      const upload_aws = await this.allinoneService.multipleUploadToAWS(
        this.attachmentList,
        filepath
      );
      if (!upload_aws) {
        this.messageService.openSnackBar(
          'Unknown error occured while uploading your files.',
          'warn'
        );
        return;
      }
    }

    const filenamelist: any = [];
    tempFileList.map((item: any) => {
      filenamelist.push(item.filename);
    });

    const recordfilenamelist: any = [];
    recordTempfilelist.map((item: any) => {
      recordfilenamelist.push(item.filename);
    });
    const reprotedby = this.allinoneService.getKunyekUserId();
    var tempStartDate =
      this.serviceForm.startdatetime != ''
        ? formatDate(
            new Date(this.serviceForm.startdatetime),
            this.formatToShow,
            'en-US'
          ).toString()
        : '';
    var tempEndDate =
      this.serviceForm.enddatetime != ''
        ? formatDate(
            new Date(this.serviceForm.enddatetime),
            this.formatToShow,
            'en-US'
          ).toString()
        : '';

    var data = {
      desc: this.serviceForm.description,
      type: this.servicetype,
      status: this.serviceForm.status,
      reportedby: reprotedby,
      contactid: this.contactid == '' ? this.searchContactName : this.contactid,
      customerid:
        this.serviceForm.customerid == '' ? '' : this.serviceForm.customerid,
      projectid:
        this.serviceForm.projectid == ''
          ? this.projectValue
          : this.serviceForm.projectid,
      // "startdatetime": this.startdate ,
      startdatetime: this.serviceForm.startdatetime,
      enddatetime:
        this.serviceForm.status != '00900' && this.serviceForm.status != '0040'
          ? ''
          : this.serviceForm.enddatetime,
      // "enddatetime": this.serviceForm.status != '00900' ? '' : this.enddate + "T" + this.endtime,
      domainid: this.serviceForm.domainid,
      orgid: this.serviceForm.orgid,
      assignedto: this.assignedtolist,
      // "assignedto": this.serviceForm.picid == "" ? this.picValue : this.serviceForm.picid,
      // "assignedtouserid": this.serviceForm.assingtouserid == "" ? '' : this.serviceForm.assingtouserpiid,
      assignedtouserid: '',
      remark: this.serviceForm.remark,
      priority: this.serviceForm.priority,
      filename: filenamelist,
      customerphone: this.serviceForm.customerphone.toString().trim(),
      customeremail: this.serviceForm.customeremail,
      solution: this.serviceForm.solution,
      details: this.serviceForm.detail,
      productid: this.serviceForm.productid ? this.serviceForm.productid : '',
      refno: this.serviceForm.refno,
      recorddescription: this.recordDesc,
      recordfilename: recordfilenamelist,
      version: this.serviceForm.version,
      mer: this.serviceForm.mer,
      moduleid:
        this.moduleidList.moduleid == undefined
          ? ''
          : this.moduleidList.moduleid,
      reopen: this.serviceForm.reopen,
      reticketno:
        this.serviceForm.reopen == 'true' ? this.serviceForm.reticketno : '',
      customerselfservice: this.customercare == true ? true : false,
      targetdatetime: this.serviceForm.targetdatetime,
      duedatetime: this.serviceForm.duedatetime,
      isinternal: this.checkinternal == true ? true : false,
      voidticket: this.checkvoid == true ? true : false,
      branchid: this.serviceForm.branchid,
    };

    // this.serviceForm.pic = data.name;
    // this.serviceForm.picid = data.picid;
    // this.serviceForm.assingtouserid = data.userid;

    if (this.isEditing) {
      data = Object.assign(data, {
        autoid: this.serviceForm.autoid,
        deletefilename: this.deleteFileList,
        serviceid: this.serviceForm.serviceid,
        // saverecord: true,
        // recordid: this.recordid,
        // redcorddeletefilename: this.deleterecordFileList,
      });
    }

    console.log(data);
    console.log('>.....>>>>>>>');

    if (this.isEditing == true) {
      this.kunyekService.editService(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.saveLoading = false;

            this.save.emit();
          } else {
            this.saveLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.saveLoading = false;
        }
      );
    } else {
      this.kunyekService.createService(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.serviceForm.tickeno = res.ticketno;
            this.serviceForm.serviceid = res.serviceid;
            const format = 'MM/dd/yyyy hh:mm aa';
            this.updatetime = formatDate(
              new Date(),
              format,
              'en-US'
            ).toString();
            this.updatename = this.allinoneService.getKunyekUserName();
            this.serviceForm.serviceid = res.serviceid;
            this.saveLoading = false;

            this.isEditing = true;
            this.goserviceTicket = true;
            this.goserviceTicket = true;

            // this.save.emit();
            this.recordAttachmentList = [];
            this.getRecordList();
            this.messageService.openSnackBar('Ticket created', 'success');
          } else {
            this.saveLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.saveLoading = false;
        }
      );
    }

    // this.saveLoading = false;
  }

  customerSelect(customer: any) {
    console.log(customer);
    console.log(this.customertypeint);

    if (customer != undefined && !Array.isArray(customer)) {
      this.serviceForm.customername = customer.customername;
      this.serviceForm.customerphone = customer.customermobile;
      this.serviceForm.customeremail = customer.customeremail;
      this.contactlist = customer.contactlist;
      this.branchlist = customer.branchlist;
    } else {
      this.serviceForm.customerid = '';
      this.serviceForm.customername = '';
    }
  }
  branchSelect(branch: any) {
    console.log(branch);
    this.serviceForm.branchid = branch.branchid;
    let contactlist = [];

    for (let i = 0; i < this.contactlist.length; i++) {
      if (branch.branchid == this.contactlist[i]['branchid']) {
        contactlist.push({
          branchid: this.contactlist[i]['branchid'],
          contactemail: this.contactlist[i]['contactemail'],
          contactid: this.contactlist[i]['contactid'],
          contactmobile: this.contactlist[i]['contactmobile'],
          contactname: this.contactlist[i]['contactname'],
          emailaccess: this.contactlist[i]['emailaccess'],
          role: this.contactlist[i]['role'],
          selfservice: this.contactlist[i]['selfservice'],
        });
      }
    }
    this.contactlist = contactlist;

    this.branchname = branch.branchname;
  }

  customerChange() {
    this.serviceForm.customerid = '';
    this.serviceForm.customername = '';
  }

  searchContactName: any = '';
  contactSelect(contact: any) {
    this.contactid = contact.contactid;
    this.searchContactName = contact.contactname;
    this.contactname = contact.contactname;
    console.log('1' + this.searchContactName);
    console.log('2' + this.contactid);
  }

  contactChange() {
    this.contactname = '';
    this.contactid = '';
    console.log(this.searchContactName);
  }

  picListorg: any = [];

  picSelect(data: any) {
    console.log(data);
    this.picListorg = data;
    this.assignedtolist = [];
    for (let i = 0; i < this.picListorg.length; i++) {
      this.assignedtolist.push(this.picListorg[i]['picid']);
    }

    // console.log(this.picListorg);
    // console.log(this.assignedtolist);
    // console.log(this.ngassignedtolist);
    // console.log(this.manhourassignedlist);

    this.manhourassignedlist = this.ngassignedtolist;

    // {"userid": '', "name": 'Test User Long Name Test Description How to show in Service Tickets Test',
    //  "picid": '7eaa7443-a6c6-4a45-9776-044d96160137'}

    // for
    // if (data.name == "") {
    //   this.picValue = data.userid;
    // } else {
    //   this.picValue = data.name;
    // }
    this.serviceForm.pic = data.name;
    this.serviceForm.picid = data.picid;
    this.serviceForm.assingtouserid = data.userid;
  }

  picChange() {
    this.serviceForm.pic = '';
    this.serviceForm.picid = '';
    this.serviceForm.assingtouserid = '';
  }

  productSelect(data: any) {
    console.log(data);

    if (data != undefined && !Array.isArray(data)) {
      this.serviceForm.product = data.name;
      // this.serviceForm.productid = data.productid;
      if (data.piclist.length > 0) {
        if (
          data.piclist[0].picuser != undefined ||
          data.piclist[0].picuser != null
        ) {
          console.log('assignerto value>>>>>>>>');
          let selectedRole: any = [];
          this.assignedtolist = [];
          var picname = '';
          if (data.piclist[0].picuser['picname'] == '') {
            picname = data.piclist[0].picuser['userid'];
          } else {
            if (data.piclist[0].picuser['userid'] == '') {
              picname = data.piclist[0].picuser['picname'];
            } else {
              picname =
                data.piclist[0].picuser['picname'] +
                ' (' +
                data.piclist[0].picuser['userid'] +
                ')';
            }
          }
          selectedRole.push({
            picid: data.piclist[0].picuser['picid'],
            name: picname,
            userid: data.piclist[0].picuser['userid'],
          });
          console.log(selectedRole);
          if (this.assignedtoname == 'By Product') {
            this.getAssiProduct();
          }
          this.ngassignedtolist = selectedRole;
          console.log(this.ngassignedtolist);
          for (let i = 0; i < this.ngassignedtolist.length; i++) {
            this.assignedtolist.push(this.ngassignedtolist[i]['picid']);
          }
          // this.serviceForm.pic = data.piclist[0].picuser.picname;
          // this.serviceForm.picid = data.piclist[0].picuser.picid;
          // this.serviceForm.assingtouserid = data.piclist[0].picuser.userid;
          // this.picValue = data.piclist[0].picuser.picname;
        } else {
          if (this.customercare) {
            this.assignedtolist = [];
            this.ngassignedtolist = [];
          }
        }
      }
      if (data.modulelist.length > 0) {
        this.modulecheck = true;
        this.modulelist = data.modulelist;
        console.log(this.moduleidList);
      } else {
        this.modulecheck = false;
        this.moduleidList = [];
        this.modulelist = [];
      }
    } else {
      this.serviceForm.productid = '';
      this.serviceForm.product = '';

      this.assignedtoname = 'All';
      this.modulecheck = false;
      this.modulelist = [];
      this.moduleidList = [];
    }

    console.log(this.assignedtolist);
  }

  modulePicchange(data: any) {
    // data.target.value
    console.log(data);
    if (data != '' || data != undefined) {
      var type = this.modulelist
        .map((x: any) => x.moduleid)
        .indexOf(data.moduleid);
      console.log(type);
      if (this.modulelist.length > 0) {
        console.log('1');
        console.log(this.modulelist[type].piclist);
        if (this.modulelist[type].piclist.length > 0) {
          console.log('pi');
          console.log(this.modulelist[type].piclist[0].modulepicuser);
          if (this.modulelist[type].piclist[0].modulepicuser.length > 0) {
            let selectedRole: any = [];
            this.ngassignedtolist = [];

            // selectedRole = JSON.parse(JSON.stringify(this.modulelist[type].piclist[0].modulepicuser));

            console.log(selectedRole);
            console.log(this.ngassignedtolist);
            console.log('value>>>>>>>>');
            var name =
              this.modulelist[type].piclist[0].modulepicuser[0].picname;
            var userid =
              this.modulelist[type].piclist[0].modulepicuser[0].userid;

            var picname = '';
            if (name == '') {
              picname = userid;
            } else {
              if (userid == '') {
                picname = name;
              } else {
                picname = name + ' (' + userid + ')';
              }
            }
            selectedRole.push({
              picid: this.modulelist[type].piclist[0].modulepicuser[0].picid,
              name: picname,
              userid: this.modulelist[type].piclist[0].modulepicuser[0].userid,
            });
            console.log(selectedRole);
            this.ngassignedtolist = selectedRole;
            this.assignedtolist = [];
            for (let i = 0; i < this.ngassignedtolist.length; i++) {
              this.assignedtolist.push(this.ngassignedtolist[i]['picid']);
            }
            console.log(this.assignedtolist);
            // this.picValue = this.modulelist[type].piclist[0].modulepicuser[0].picname;
            // this.serviceForm.pic = this.modulelist[type].piclist[0].modulepicuser[0].picname;
            // this.serviceForm.picid = this.modulelist[type].piclist[0].modulepicuser[0].picid;
            // this.serviceForm.assingtouserid = this.modulelist[type].piclist[0].modulepicuser[0].userid;
          }
        } else if (this.modulelist[type].piclist.length == 0) {
          console.log('2');
          this.picValue = '';
          this.serviceForm.pic = '';
          this.serviceForm.picid = '';
          this.serviceForm.assingtouserid = '';
        }
      }
    } else {
      this.picValue = '';
      this.serviceForm.pic = '';
      this.serviceForm.picid = '';
      this.serviceForm.assingtouserid = '';
    }

    console.log(this.moduleidList);
    console.log('jaejae');
  }

  assignedtoname: any = 'All';

  async assignedChanges(data: any) {
    if (data == 'all') {
      this.assignedtoname = 'All';
      this.getAssignto = true;
      await this.getPIC();
      this.getAssignto = false;
    } else if (data == 'product') {
      this.assignedtoname = 'By Product';
      if (this.serviceForm.productid != '') {
        this.getAssiProduct();
      }
    } else if (data == 'project') {
      if (this.serviceForm.projectid != '') {
        this.assignedtoname = 'By Project';
        this.getAssiProject();
      }
    }
  }

  getAssignto: boolean = false;

  getAssiProduct() {
    this.getAssignto = true;
    const data = {
      productid: this.serviceForm.productid,
      orgid: this.orgid,
    };
    this.kunyekService.getAssinProduct(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.picList = [];
          res.list.map((pic: any) => {
            this.picList.push({
              userid: pic.userid,
              name: pic.picname == '' ? pic.userid : pic.picname,
              picid: pic.picid,
            });
          });
          this.getAssignto = false;
        } else {
          this.getAssignto = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getAssignto = false;
      }
    );
  }
  getAssiProject() {
    this.getAssignto = true;
    //
    const data = {
      projectid: this.serviceForm.projectid,
      orgid: this.orgid,
    };
    this.kunyekService.getAssinProject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.picList = [];
          res.list.map((pic: any) => {
            this.picList.push({
              userid: pic.userid,
              name: pic.picname == '' ? pic.userid : pic.picname,
              picid: pic.picid,
            });
          });
          this.getAssignto = false;
        } else {
          this.getAssignto = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getAssignto = false;
      }
    );
  }

  productChange() {
    this.serviceForm.product = '';
    this.serviceForm.productid = '';
    this.modulecheck = false;
    this.moduleidList = [];
    this.picValue = '';
    this.serviceForm.pic = '';
    this.serviceForm.picid = '';
    this.serviceForm.assingtouserid = '';
  }

  projectValue: any = '';
  projectSelect(data: any) {
    this.serviceForm.project = data.name;
    this.serviceForm.projectid = data.projectid;
    this.projectValue = data.name;
  }

  projectChange() {
    this.serviceForm.project = '';
    this.serviceForm.projectid = '';
  }

  getPIC() {
    return new Promise((resolve) => {
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.orgid,
      };
      this.kunyekService.getCrmPIC(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.picList = [];
            var picname = '';
            res.list.map((pic: any) => {
              if (pic.picname == '') {
                picname = pic.userid;
              } else {
                if (pic.userid == '') {
                  picname = pic.picname;
                } else {
                  picname = pic.picname + ' (' + pic.userid + ')';
                }
              }
              this.picList.push({
                userid: pic.userid,
                name: picname,
                picid: pic.picid,
              });
            });
            resolve('true');
          } else {
            resolve('false');
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve('false');
        }
      );
    });
  }

  checkvoid: boolean = false;
  voidcheck(data: any) {
    if (data.target.checked) {
      this.checkvoid = true;
    } else {
      this.checkvoid = false;
    }
  }

  projectPicList: any = [];
  selectProject(event?: any) {
    // this.joForm.project = event.target.value;

    //   var result =this.picList.filter(function (o1) {
    //     return this.picList.some(function (o2) {
    //         return o1.id === o2.id; // return the ones with equal id
    //    });
    // });

    var project = [];
    if (this.isMove) {
      this.membersearch = '';
    }
    if (this.serviceForm.project == '') {
      console.log('1');
    } else {
      console.log('2');
      for (let i = 0; i < this.projectList.length; i++) {
        if (event.target.value == this.projectList[i].projectid) {
          project.push(this.projectList[i]);
        }
      }

      console.log(project[0]['memberstatus']);
      if (project[0]['memberstatus'] == '1') {
        console.log(project[0]['memberlist'].length);
        this.projectPicList = project[0]['memberlist'];
        // this.picList = project[0]['memberlist']

        // for (let j = 0; j < project[0]['memberlist'].length; j++) {
        //   console.log(project[0]['memberlist'][j].userid);
        //   this.projectPicList =
        // }
      } else {
        this.projectPicList = project[0]['memberlist'];
        // this.picList = project[0]['memberlist']
        // for (let j = 0; j < project[0]['memberlist'].length; j++) {
        //   console.log(project[0]['memberlist'][j].userid);
        //   this.projectPicList.push(
        //     {
        //       "userid": project[0]['memberlist'][j].userid,
        //       "username": project[0]['memberlist'][j].username,
        //     }
        //   );
        // }
      }
      if (this.assignedtoname == 'By Project') {
        this.getAssiProject();
      }
      console.log('>>>>member');
      // console.log(this.projectPicList)

      // for(let p=0;p<this.projectPicList.length;p++){
      //       this.picList.push({
      //         "userid":this.projectPicList[p].userid,
      //         "username":this.projectPicList[p].username,
      //       })
      // }
      // console.log(this.picList);
    }
  }

  // selectedProject(){
  //   this.serviceForm.project = ""
  // }
  // onFileSelect(event: any) {
  //   let files = event.target.files;
  //   console.log('file')
  //   console.log(files)
  //   if (files) {
  //     for (let file of files) {
  //       // if (file.type == 'application/pdf') {
  //       var alreadySelect = false;
  //       this.attachmentList.map((item: any) => {
  //         if (item.filename == file.name) {
  //           alreadySelect = true;
  //         }
  //       });
  //       if (!alreadySelect) {
  //         var temp = {
  //           file: file,
  //           filename: file.name,
  //         };
  //         this.attachmentList.push(temp);
  //       }

  //     }
  //     this.hiddenpdffileinput.nativeElement.value = '';
  //   }
  // }
  onFileSelect(event: any) {
    let files = event.target.files;
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    console.log('file');
    console.log(files);
    if (files) {
      for (let file of files) {
        // if (file.type == 'application/pdf') {
        var alreadySelect = false;
        this.attachmentList.map((item: any) => {
          if (item.filename == file.name) {
            alreadySelect = true;
          }
        });
        if (!alreadySelect) {
          var temp = {
            file: file,
            filename: time + file.name,
          };
          this.attachmentList.push(temp);
        }
      }

      this.hiddenpdffileinput.nativeElement.value = '';
    }
    console.log(this.attachmentList);
  }
  // removeAttachment(index: number) {
  //   if (this.attachmentList[index].fileurl) {
  //     this.deleteFileList.push(this.attachmentList[index].filename)
  //   }
  //   this.attachmentList.splice(index, 1);
  // }
  removeAttachment(index: number) {
    console.log(this.attachmentList);
    if (this.attachmentList[index].fileurl) {
      this.deleteFileList.push(this.attachmentList[index].filename);
    }
    this.attachmentList.splice(index, 1);
  }

  downloadFile(fileurl: any, filename: any) {
    // window.open(fileurl, filename);
    var urlString = fileurl.substring(0, fileurl.indexOf('?'));
    return this.http
      .get(urlString, { responseType: 'blob' })
      .subscribe((response: any) => {
        saveAs(response, filename);
      });
  }

  getCustomers() {
    this.gettingCustomers = true;
    var data = {
      domainid: this.serviceForm.domainid,
      orgid: this.serviceForm.orgid,
    };

    if (this.customercare == true) {
      Object.assign(data, {
        customerid: this.customercaredata.customerid,
      });
    }

    this.kunyekService.getCrmCustomers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.customers = res.list;
          console.log('customer re get');
          this.customers = res.list;
          // if (res.list[i]["customerid"] == this.serviceForm.customerid) {
          //     this.contactlist = res.list[i]["contactlist"]
          //   }
          var currnetCustomer = res.list.filter(
            (ct: any) => ct.customerid == this.serviceForm.customerid
          );
          console.log(currnetCustomer.length);
          console.log(currnetCustomer.contactlist);
          if (currnetCustomer.length > 0) {
            this.contactlist = currnetCustomer[0].contactlist;
          }
          if (currnetCustomer.length > 0) {
            if (currnetCustomer[0].branchlist.length > 0) {
              this.branchlist = currnetCustomer[0].branchlist;
              console.log('branchlist');
              console.log(this.branchlist);
              console.log(this.serviceForm.branchid);
              console.log(
                this.branchlist.length > 0 && this.serviceForm.branchid != ''
              );
              let newbranchlist: any = [];
              if (
                this.branchlist.length > 0 &&
                this.serviceForm.branchid != ''
              ) {
                for (let i = 0; i < this.branchlist.length; i++) {
                  if (
                    this.serviceForm.branchid == this.branchlist[i]['branchid']
                  ) {
                    newbranchlist.push({
                      branchid: this.branchlist[i]['branchid'],
                      branchname: this.branchlist[i]['branchname'],
                      branchmobile: this.branchlist[i]['branchmobile'],
                      branchemail: this.branchlist[i]['branchemail'],
                      branchaddress1: this.branchlist[i]['branchaddress1'],
                      branchaddress2: this.branchlist[i]['branchaddress2'],
                      branchindustrytype:
                        this.branchlist[i]['branchindustrytype'] == null
                          ? ''
                          : this.branchlist[i]['branchindustrytype'],
                    });
                    this.branchname = this.branchlist[i]['branchname'];
                  }
                }
              }
              if (newbranchlist.length > 0) {
              }
              this.ngbranchlist = newbranchlist[0];

              console.log('...');
              console.log(typeof newbranchlist[0]);
              console.log(typeof this.ngassignedtolist);
              console.log(this.ngbranchlist);
              // resolve(true);
            }
          }
          this.gettingCustomers = false;
        } else {
          this.gettingCustomers = false;
          this.messageService.openSnackBar(res.message, 'warn');
          // resolve(false);
        }
      },
      (err) => {
        // resolve(false);
        this.gettingCustomers = false;
      }
    );
    // })
  }

  getEmployee() {
    return;
    this.gettingEmployee = true;
    const data = {
      domainid: this.serviceForm.domainid,
      orgid: this.serviceForm.orgid,
      domain: this.allinoneService.getDomain().shortcode,
    };

    this.kunyekService.getMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.members = res.datalist;
          // console.log(this.members)

          this.gettingEmployee = false;
          console.log('1');
          // resolve(true);
        } else {
          this.gettingEmployee = false;
          this.messageService.openSnackBar(res.message, 'warn');
          // resolve(false);
        }
      },
      (err) => {
        // resolve(false);
        this.gettingEmployee = false;
      }
    );
  }

  getAllProjects() {
    this.gettingProjectList = true;
    this.projectList = [];
    const data = {
      orgid: this.serviceForm.orgid,
      domainid: this.serviceForm.domainid,
      customerid: this.customercare ? this.customerid : '',
      customerview: this.customercare ? true : false,
      // role: this.callType
    };
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var project = [];
          console.log(this.projectList);
          var userid = this.allinoneService.getKunyekUserId();
          console.log(typeof userid);
          if (this.isdomainAdmin == false) {
            // for (let i = 0; i < res.data.length; i++) {
            //   if (res.data[i]['memberstatus'] == '') {
            //     this.projectList.push(res.data[i]);
            this.projectList = res.data;
            //   }
            //   if (res.data[i]['memberstatus'] == '1') {
            //     for (let j = 0; j < res.data[i]['memberlist'].length; j++) {
            //       console.log(res.data[i]['memberlist'][j]['userid'] == userid);
            //       if (userid == res.data[i]['memberlist'][j]['userid']) {
            //         console.log("ture1111")
            //         this.projectList.push(res.data[i])
            //       }
            //     }

            //   }

            // }
          } else {
            this.projectList = res.data;
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingProjectList = false;
      },
      (err) => {
        this.gettingProjectList = false;
      }
    );
  }

  move() {
    this.isMove = true;
    this.getServcieType();
    this.getStatusList();
    this.getAllProjects();
    this.getPriorityList();
    this.getProduct();
  }

  cancelMove() {
    this.isMove = false;
    this.membersearch = this.editData.assignedtoname;
  }

  minDate: string = '';
  // onStartDate() {
  //   var addDays = 35;
  //   var date = new Date(this.serviceForm.startdatetime);
  //   console.log(date)
  //   console.log(this.serviceForm.startdatetime)
  //   date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
  //   // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
  //   // if (
  //   //   this.serviceForm.enddatetime > this.minDate ||
  //   //   this.serviceForm.enddatetime < this.serviceForm.startdatetime
  //   // ) {
  //   //   this.serviceForm.enddatetime = this.serviceForm.startdatetime;
  //   // }
  // }

  // closeType
  closeTicket() {
    this.closeType = true;
    this.closetyploading = true;
    this.saveMoveServcie();
  }

  submitMoveService() {
    this.serviceFormSubmitted = true;
    if (this.servicetype) {
      this.saveLoading = true;
      this.saveMoveServcie();
    }
  }

  async saveMoveServcie() {
    const reprotedby = this.allinoneService.getKunyekUserId();
    var data = {
      type: this.servicetype,
      status: this.serviceForm.status,
      reportedby: reprotedby,
      projectid: this.serviceForm.project,
      enddatetime:
        this.closeType == true
          ? this.serviceForm.enddatetime
          : this.serviceForm.status != '00900'
          ? ''
          : this.serviceForm.enddatetime,
      domainid: this.serviceForm.domainid,
      orgid: this.serviceForm.orgid,
      assignedto:
        this.serviceForm.picid == '' ? this.picValue : this.serviceForm.picid,
      priority: this.serviceForm.priority,
      remark: this.serviceForm.remark,
      serviceid: this.serviceForm.serviceid,
      // 'product':this.serviceForm.product,
      productid: this.serviceForm.productid ? this.serviceForm.productid : '',
      closetype: this.closeType == true ? true : false,
    };

    console.log(data);

    var fun = this.kunyekService.updateMoveService(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.closetyploading = false;

          this.save.emit();
        } else {
          this.saveLoading = false;
          this.closetyploading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.closetyploading = false;
        this.saveLoading = false;
      }
    );
  }

  getServcieType() {
    this.getServiceType = true;
    console.log(this.servicetype);
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getServiceType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.servielist = res.list;
          this.getServiceType = false;
        } else {
          this.getServiceType = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getServiceType = false;
      }
    );
  }

  getStatusList() {
    this.getSatausType = true;
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getStatusType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(this.serviceForm.status);

          this.statuslist = [];
          this.statuslist = res.list;
          // this.statuslist.insert
          this.customerstatusList = [];

          for (let i = 0; i < this.statuslist.length; i++) {
            if (
              this.statuslist[i].code != '0030' &&
              this.statuslist[i].code != '0010' &&
              this.statuslist[i].code != '0020' &&
              this.statuslist[i].code != '0051' &&
              this.statuslist[i].code != '0050' &&
              this.statuslist[i].code != '0052'
            ) {
              this.customerstatusList.push({
                code: this.statuslist[i].code,
                name: this.statuslist[i].name,
                check: false,
              });
            }
          }

          if (!this.isEditing) {
            if (this.statuslist.length > 0) {
              this.statusnew = this.statuslist[0];
            }
          }
          console.log(this.serviceForm.status);
          if (this.serviceForm.status != '0010') {
            // var type = this.statuslist.map((x: any) => x.code).indexOf('00800');
            // console.log(type)
            // if (type > -1) {
            //   this.statuslist.splice(type, 1)
            // }
          }

          console.log(this.statuslist);

          this.serviceForm.status = this.serviceForm.status;
          console.log(this.serviceForm.status);
          this.getSatausType = false;
        } else {
          this.getSatausType = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getSatausType = false;
      }
    );
  }

  statuscodeChange(event: any) {
    this.serviceForm.status = event.target.value;
    console.log(this.serviceForm.status);
  }

  trackByStatusCode(index: number, item: any) {
    return item.code;
  }
  trackByServicetype(index: number, item: any) {
    return item.code;
  }
  trackByPriority(index: number, item: any) {
    return item.code;
  }
  projectForm() {
    const ser = 'service';
    const dialog = this.dialog.open(ProjectsComponent, {
      maxWidth: '700px',
      width: '700px',
      height: '600px',

      panelClass: [
        'mat-typography',
        'crm-service-status-modal',
        'dialog-container-advansearch',
        'event-modal',
        'event-detail',
      ],
      closeOnNavigation: true,
      data: {
        orgid: this.serviceForm.orgid,
        check: true,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getAllProjects();
    });
  }

  productFormAdd() {
    // app-crm-product
    const dialog = this.dialog.open(CrmProductComponent, {
      maxWidth: '750px',
      width: '750px',
      height: '500px',
      panelClass: [
        'mat-typography',
        'crm-service-status-modal',
        'event-modal',
        'event-detail',
      ],
      closeOnNavigation: true,
      data: {
        // picList: JSON.parse(JSON.stringify(this.picList)),
        orgid: this.orgid,
        check: true,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult != null && dialogResult.status == true) {
        console.log(dialogResult.data);
        console.log(this.serviceForm.orgid);
        this.getProduct();
      }
    });
  }

  // assignedForm
  assignedForm() {
    // app-crm-product
    const dialog = this.dialog.open(CrmPersonInChargeComponent, {
      maxWidth: '600px',
      width: '600px',
      height: '500px',
      panelClass: [
        'mat-typography',
        'crm-service-status-modal',
        'event-modal',
        'event-detail',
      ],
      closeOnNavigation: true,
      data: {
        // picList: JSON.parse(JSON.stringify(this.picList)),
        orgid: this.orgid,
        check: true,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult != null && dialogResult.status == true) {
        console.log(dialogResult.data);
        this.getPIC();
      }
    });
  }

  serviceFromAdd() {
    const dialog = this.dialog.open(CrmServiceFormComponent, {
      maxWidth: '550px',
      width: '550px',
      height: '450px',
      panelClass: ['mat-typography', 'crm-service-status-modal'],
      closeOnNavigation: true,
      data: {
        orgid: this.orgid,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult);
        this.getServcieType();
        console.log('ok');
      }
    });
  }
  statusFromAdd() {
    const dialog = this.dialog.open(CrmStatusFormComponent, {
      maxWidth: '550px',
      width: '550px',
      height: '450px',
      panelClass: ['mat-typography', 'crm-service-status-modal'],
      closeOnNavigation: true,
      data: {
        orgid: this.orgid,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      console.log(dialogResult);
      if (dialogResult) {
        this.getStatusList();
      }
    });
  }

  getPriorityList() {
    this.getProiority = true;
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getPriority(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.priority = res.list;
          this.getProiority = false;
        } else {
          this.getProiority = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getProiority = false;
      }
    );
  }

  Priority() {
    const dialog = this.dialog.open(CrmPriorityFormComponent, {
      maxWidth: '550px',
      width: '550px',
      height: '450px',
      panelClass: ['mat-typography', 'crm-service-status-modal'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      console.log(dialogResult);
      if (dialogResult) {
        this.getPriorityList();
      }
    });
  }
  backCustomer() {
    // this.serviceadd.emit();
    console.log(this.serviceForm.orgid);
    const dialog = this.dialog.open(CrmCustomerComponent, {
      maxWidth: '700px',
      width: '700px',
      height: '600px',
      panelClass: [
        'mat-typography',
        'crm-service-status-modal',
        'dialog-container-advansearch',
        'event-modal',
        'event-detail',
      ],
      closeOnNavigation: true,
      data: {
        orgid: this.orgid,
        check: true,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      console.log(dialogResult);
      if (dialogResult != null && dialogResult.status == true) {
        console.log(this.serviceForm.orgid);
        this.getCustomers();
        // this.serviceForm.sku = dialogResult.data.skucode;
        // this.serviceForm.productid = dialogResult.data.productid;
        // this.serviceForm.product =dialogResult.data.name;
        // this.picList= dialogResult.data.piclist;
      }
    });
  }
  productListdata: any = [];
  getProductLoading: boolean = false;
  getProduct() {
    this.getProductLoading = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      customerid: this.customerid ? this.customerid : '',
      customerview: this.customercare == true ? true : false,
    };
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.productListdata = res.list;
          var type = this.productListdata
            .map((x: any) => x.productid)
            .indexOf(this.serviceForm.productid);
          console.log('product');
          console.log(type);
          if (type > -1) {
            this.modulelist = this.productListdata[type].modulelist;
          }
          this.getProductLoading = false;
          if (!Array.isArray(this.moduleidList)) {
            this.modulecheck = true;
          }
        } else {
          this.getProductLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getProductLoading = false;
      }
    );
  }

  async RecordSave() {
    console.log('true');
    this.RecordFormSubmitted = true;
    if (!this.recordDesc) {
      console.log('1');
      return;
    }
    console.log('1');
    this.saverecord = true;
    var recordTempfilelist = this.recordAttachmentList;
    var uploadrecordAttachmentList: any = [];
    recordTempfilelist.map((item: any) => {
      if (!item.fileurl) {
        uploadrecordAttachmentList.push(item);
      }
    });
    // (this.isEditing? this.serviceForm.id : tempFileName).toString()
    var filepath = 'crm/service';
    console.log(uploadrecordAttachmentList);
    if (uploadrecordAttachmentList.length > 0) {
      const upload_aws = await this.allinoneService.multipleUploadToAWS(
        uploadrecordAttachmentList,
        filepath
      );
      if (!upload_aws) {
        this.messageService.openSnackBar(
          'Unknown error occured while uploading your files.',
          'warn'
        );
        return;
      }
    }
    const recordfilenamelist: any = [];
    recordTempfilelist.map((item: any) => {
      recordfilenamelist.push(item.filename);
    });
    var data = {
      saverecord: true,
      recordid: this.recordid ? this.recordid : '',
      recorddeletefilename: this.deleterecordFileList,
      recorddescription: this.recordDesc,
      serviceid: this.serviceForm.serviceid,
      domainid: this.serviceForm.domainid,
      orgid: this.serviceForm.orgid,
      recordfilename: recordfilenamelist,
    };
    console.log(data);
    this.kunyekService.editService(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          this.recordDesc = '';
          this.saverecord = false;
          this.RecordFormSubmitted = false;
          // this.RecordList = res.list;
          this.recordAttachmentList = [];
          this.updateRecord = false;
          this.recordid = '';
          this.recordDesc = '';
          this.getRecordList();
        } else {
          this.saverecord = false;
          this.RecordFormSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saverecord = false;
        this.RecordFormSubmitted = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  cancelmanhour() {
    this.manhourForm = {
      manhour: '',
      activity: '',
      assignedto: '',
    };
  }

  async SaveManhour() {
    console.log('true');
    this.manhourFormSave = true;
    console.log(this.manhourForm);
    if (
      this.manhourForm.assignedto == '' ||
      this.manhourForm.activity == '' ||
      this.manhourForm.manhour == ''
    ) {
      console.log('1');
      return;
    }
    console.log('1');
    this.savemanmanhour = true;

    var data = {
      savemanhour: true,
      serviceid: this.serviceForm.serviceid,
      recordid: this.recordid ? this.recordid : '',
      domainid: this.serviceForm.domainid,
      orgid: this.serviceForm.orgid,
      assignedto: this.manhourForm.assignedto,
      activity: this.manhourForm.activity,
      manhour: this.manhourForm.manhour,
    };
    console.log(data);
    this.kunyekService.editmanhour(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          this.savemanmanhour = false;
          this.manhourFormSave = false;
          // this.RecordList = res.list;
          this.getManhour();

          this.recordid = '';
        } else {
          this.savemanmanhour = false;
          this.manhourFormSave = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saverecord = false;
        this.manhourFormSave = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  getRecord: boolean = false;

  getRecordList() {
    this.RecordList = [];

    this.getRecord = true;
    var data = {
      serviceid: this.serviceForm.serviceid,
      domainid: this.serviceForm.domainid,
      orgid: this.serviceForm.orgid,
    };
    this.kunyekService.getServiceRecord(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res.list);
          res.list.map((rec: any) => {
            console.log(rec.filenamelist);
            var date = formatDate(
              new Date(rec.createdAt),
              this.record,
              'en-US'
            ).toString();
            this.RecordList.push({
              recordid: rec.recordid,
              createAt: date,
              description: rec.description,
              reportedby: rec.reportedby,
              reportedbyname: rec.reportedbyname,
              recordfilename: rec.filelist,
            });
          });
          this.getRecord = false;
          console.log(this.RecordList);
        } else {
          this.getRecord = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getRecord = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  getManhour() {
    this.manhourlist = [];

    this.getttingManhour = true;
    var data = {
      serviceid: this.serviceForm.serviceid,
      domainid: this.serviceForm.domainid,
      orgid: this.serviceForm.orgid,
      getmanhour: true,
    };
    this.kunyekService.getServiceManhour(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res.list);
          res.list.map((rec: any) => {
            this.manhourlist.push({
              recordid: rec.recordid,
              activity: rec.activity,
              manhour: rec.manhour,
              assignedto: rec.assignedto,
              assignedtoname: rec.assignedtoname,
            });
          });
          this.getttingManhour = false;
          console.log(this.RecordList);
        } else {
          this.getttingManhour = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getttingManhour = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  editmanhour(data: any) {
    this.recordid = data.recordid;
    this.manhourForm.activity = data.activity;
    this.manhourForm.assignedto = data.assignedto;
    this.manhourForm.manhour = data.manhour;
  }

  deletemanhour(value: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        const data = {
          deleteservicerecord: true,
          recordid: value.recordid,
        };
        this.getttingManhour = true;
        this.kunyekService.deleteService(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.getManhour();
            } else {
              this.getttingManhour = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.getttingManhour = false;
          }
        );
      }
    });
  }
  EditRecord(rec: any) {
    console.log('y');
    this.updateRecord = true;
    this.recordid = rec.recordid;
    this.recordAttachmentList = rec.recordfilename;
    this.recordDesc = rec.description;
    this.isEditing = true;
    setTimeout(() => this.recordfocus.nativeElement.focus());
  }

  cancelUpdate() {
    this.updateRecord = false;
    this.recordid = '';
    this.recordAttachmentList = [];
    this.recordDesc = '';
  }

  deleteRecord(id: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        const data = {
          deleteservicerecord: true,
          recordid: id,
        };
        this.getRecord = true;
        this.kunyekService.deleteService(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.getRecordList();
            } else {
              this.getRecord = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.getRecord = false;
          }
        );
      }
    });
  }
  onFileSelect1(event: any) {
    console.log(event);
    let files = event.target.files;
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    if (files) {
      for (let file of files) {
        // if (file.type == 'application/pdf') {
        var alreadySelect = false;
        this.recordAttachmentList.map((item: any) => {
          if (item.filename == file.name) {
            alreadySelect = true;
          }
        });
        if (!alreadySelect) {
          var temp = {
            file: file,
            filename: time + file.name,
          };
          this.recordAttachmentList.push(temp);
        }
      }
      this.hiddenpdffileinput.nativeElement.value = '';
    }
  }

  removeAttachmentRecord(index: number) {
    console.log(this.recordAttachmentList);
    console.log(this.RecordList);
    if (this.recordAttachmentList[index].fileurl) {
      this.deleterecordFileList.push(this.recordAttachmentList[index].filename);
    }
    this.recordAttachmentList.splice(index, 1);
  }
  goCrm() {
    this.router.navigateByUrl('service');
  }

  reOpen(data: any) {
    console.log(data.target.checked);

    if (data.target.checked) {
      this.checkrecurring = true;

      this.serviceForm.reopen = 'true';
    } else {
      this.checkrecurring = false;

      this.serviceForm.reopen = 'false';
    }
  }

  checkInternal(data: any) {
    if (data.target.checked) {
      this.checkinternal = true;
    } else {
      this.checkinternal = false;
    }
  }

  serviceTypechange(data: any) {
    console.log(data);
    if (data != undefined && !Array.isArray(data)) {
      this.serviceTypeShowList = data;
      this.servicetype = data.code;
    } else {
      this.servicetypelist = [];
      this.serviceTypeShowList = [];
      this.servicetype = '';
    }
  }

  statuschange(data: any) {
    console.log(data);
    if (data != undefined && !Array.isArray(data)) {
      if (data.code == '00900') {
        this.serviceForm.enddatetime = formatDate(
          Date.now(),
          this.formatToShow,
          'en-US'
        ).toString();
      }
      this.statusShowList = data;
      this.serviceForm.status = data.code;
    } else {
      // if (this.statuslist.length > 0) {
      //   this.statusnew = this.statuslist[0];
      this.statusShowList = [];
      this.statusnew = [];
      this.serviceForm.status = '';
      // }
      console.log(this.statusnew);
    }
  }

  clear() {
    this.serviceForm.reticketno = '';
  }

  recurringdata: any = [];
  checkconfirm: boolean = false;
  falseconfirm: boolean = false;
  searchDisable: boolean = false;
  recurringLoating: boolean = false;
  reticknoNo: any = '';
  onSearch() {
    if (this.serviceForm.reticketno == '') {
      return;
    } else {
      this.recurringLoating = true;

      this.recurringdata = [];
      const data = {
        orgid: this.serviceForm.orgid,
        domainid: this.serviceForm.domainid,
        productadmin: '',
        admin: this.allinoneService.crmOrgAdmin === 'true',
        pagesize: 1,
        sortby: '',
        status: '',
        servicetype: '',
        viewotherstatus: false,
        startdate: '',
        enddate: '',
        productid: '',
        picid: '',
        customerid:
          this.allinoneService.crmOrgAdmin === 'true' ? this.customerid : '',
        priority: '',
        ticketno: this.serviceForm.reticketno,
        assignedtome: false,
        projectid: '',
        reportedby: '',
        age: '',
        ageoperator: '',
        customerview: this.customercare ? true : false,
        contactid: '',
        contactadmin: this.customercare ? this.contactadmin : false,
        moduleid: '',
        refno: '',
        description: '',
        detail: '',
        recurring: '',
      };
      console.log(data);

      this.kunyekService.getService(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.recurringLoating = false;
            this.searchDisable = true;
            const format = 'dd/MM/yyyy hh:mm aa';
            const ageformat = 'dd/MM/yyyy';

            if (res.list.length > 0) {
              this.checkconfirm = true;
              this.falseconfirm = false;
            } else {
              this.falseconfirm = true;
              this.checkconfirm = false;
            }

            res.list.map((service: any) => {
              var tempStartDate =
                service.startdatetime != ''
                  ? formatDate(
                      new Date(service.startdatetime),
                      ageformat,
                      'en-US',
                      'UTC'
                    ).toString()
                  : '';
              var tempEndDate =
                service.enddatetime != '' && service.enddatetime != null
                  ? formatDate(
                      new Date(service.enddatetime),
                      ageformat,
                      'en-US',
                      'UTC'
                    ).toString()
                  : '';
              var targetDate =
                service.targetdatetime != '' && service.targetdatetime != null
                  ? formatDate(
                      new Date(service.targetdatetime),
                      ageformat,
                      'en-US'
                    ).toString()
                  : '';
              var lastdate = formatDate(
                new Date(service.updatedAt),
                format,
                'en-US'
              ).toString();
              var module = this.productListdata.filter(function (pro: any) {
                return pro.productid == service.productid;
              });
              var serviceType = this.servielist.filter(function (type: any) {
                return type.code == service.type;
              });
              var status = this.statuslist.filter(function (status: any) {
                return status.code == service.status;
              });
              this.recurringdata.push({
                // "ageday": service.status == "00900" ? this.endDays : this.Days,
                ageday: service.age,
                autoid: service.autoid,
                name: service.CRMCustomer.name,
                assignedto: service.assignedto,
                assignedtoname: service.assignedtoname,
                assignedtouserid: service.assignedtouserid,
                domainid: service.domainid,
                enddatetime: tempEndDate,
                startdatetime: tempStartDate,
                startdatetoshow: service.startdatetime,
                enddatetoshow: service.enddatetime,
                filelist: service.filelist,
                filename: service.filename,
                orgid: service.orgid,
                // "priority": service.priority,
                // "priorityname": service.CRMServicePriority.name,
                // "projectid": service.projectid,
                remark: service.remark,
                reportedby: service.reportedby,
                reportedbyname: service.reportedbyname,
                servicedesc: service.servicedesc.replace('\n', ''),
                editservciedesc: service.servicedesc,
                sortby: service.sortby,
                statusname:
                  service.CRMServiceStatus == null
                    ? ''
                    : service.CRMServiceStatus.name,
                statuslist: status.length > 0 ? status[0] : [],
                status: service.status,
                ticketno: service.ticketno,

                servcietypelist: serviceType.length > 0 ? serviceType[0] : [],

                typename: service.CRMServiceType.name
                  ? service.CRMServiceType.name
                  : '',
                longtypename: service.CRMServiceType.longname
                  ? service.CRMServiceType.longname
                  : '',
                type: service.type,
                customerphone: service.customerphone,
                customeremail: service.customeremail,
                serviceid: service.serviceid,
                project: service.projectname,
                projectid: service.projectid,
                details: service.details,
                solution: service.solution,
                // "lastupdatename":service.lastupdatedby,
                companyname: service.companyname,
                lastupdatename:
                  service.lastupdatedby == '' || service.lastupdatedby == null
                    ? service.reportedbyname
                    : service.lastupdatedbyname,
                lastupdatetime:
                  service.updatedAt == '' || service.updatedAt == null
                    ? ''
                    : lastdate,
                customerdefault: service.customerdefault,
                product:
                  service.product.name == null ? '' : service.product.name,
                productid: service.productid == null ? '' : service.productid,
                // "productid": this.filtergetdata.productid ? this.filtergetdata.productid : service.productid,
                // "customerid": this.filtergetdata.customerid ? this.filtergetdata.customerid : service.customerid,
                priority: service.priority,
                priorityname: service.CRMServicePriority.name
                  ? service.CRMServicePriority.name
                  : '',
                customerid: service.customerid,
                picid: service.picid != '' ? service.picid : '',
                // "picid": this.filtergetdata.picid ? this.filtergetdata.picid : "",
                pic: '',
                refno: service.refno,
                contacts: service.CRMContact,
                picdata:
                  service.picdata == null || service.picdata == ''
                    ? ''
                    : service.picdata,
                version: service.version,
                mer: service.mer,
                moduleid: service.moduleid,
                modulelist:
                  module.length > 0
                    ? module[0].modulelist.length > 0
                      ? module[0].modulelist
                      : ''
                    : '',
                modulename: service.modulename,
                reopen: service.reopen,
                reticketno: service.reticketno,
                targetdatetime: service.targetdatetime,
                targetdatetimeshow: targetDate,
                duedatetime: service.duedatetime,
                isinternal: service.isinternal,
                voidticket: service.voidticket == 'true' ? true : false,
                branchid: service.branchid == null ? '' : service.branchid,
                // "completedatetime":service.completedatetime,
              });
            });
            // productListdata
            console.log(this.recurringdata);
            this.getProduct();

            console.log('kjeiwjfiwje');
            // this.getRecordList();
          } else {
            this.recurringLoating = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.recurringLoating = false;
        }
      );
    }
  }

  onConfirm() {
    this.reticknoNo = this.serviceForm.reticketno;
    console.log(this.recurringdata.serviceid);
    var recurrD = this.recurringdata[0];
    console.log(recurrD);

    this.serviceForm = {
      serviceid: recurrD.serviceid,
      autoid: recurrD.autoid,
      tickeno: this.isEditing ? this.recurringCheck : 'TBA',
      description: recurrD.editservciedesc,
      servciename: recurrD.typename,
      statusname: recurrD.statusname,
      status:
        recurrD.status == '00900' || this.isEditing == false
          ? '0010'
          : this.recurringstatus,
      reportedby: recurrD.reportedby,
      assignedto: recurrD.assignedto,
      startdatetime: this.isEditing
        ? recurrD.startdatetime
        : formatDate(Date.now(), this.formatToShow, 'en-US').toString(),
      enddatetime: recurrD.enddatetoshow,
      customername: recurrD.name,
      customerid: recurrD.customerid,
      domainid: recurrD.domainid,
      orgid: recurrD.orgid,
      project: recurrD.projectid,
      projectid: recurrD.projectid,
      remark: recurrD.remark,
      priority: recurrD.priority,
      customeremail: recurrD.customeremail,
      customerphone: recurrD.customerphone,
      typename: recurrD.typename,
      detail: recurrD.details,
      solution: recurrD.solution,
      companyname: recurrD.companyname,
      product: recurrD.product,
      sku: '',
      pic: recurrD.assignedtoname,
      picid: '',
      refno: recurrD.refno,
      productid: recurrD.productid,
      assingtouserid: recurrD.assignedtouserid,
      version: recurrD.version,
      mer: recurrD.mer,
      modulename: recurrD.modulename,
      reopen: this.reticknoNo ? this.reticknoNo : '',
      reticketno: this.reticknoNo,
      targetdatetime: recurrD.targetdatetime,
      duedatetime: recurrD.duedatetime,
      checkinternal: '',
      branchid: '',
    };
    console.log('Okokk');
    console.log(recurrD.status);
    this.servicetypelist = recurrD.servcietypelist;
    this.servicetype = recurrD.type;
    var nstatus = {
      autoid: 1,
      code: '0010',
      name: 'New',
      CRMServiceStatusOrganization: { name: 'New' },
    };
    this.statusnew = recurrD.status == '00900' ? nstatus : recurrD.statuslist;
    this.moduleidList = recurrD.modulelist;

    this.checkrecurring = recurrD.reopen == 'true' ? true : false;

    this.projectValue = recurrD.project;
    this.assignedtolist = recurrD.assignedto;

    let selectedRole: any = [];
    for (var j = 0; j < this.picList.length; j++) {
      for (var i = 0; i < this.assignedtolist.length; i++) {
        if (this.picList[j]['picid'] == this.assignedtolist[i]) {
          selectedRole.push({
            picid: this.picList[j]['picid'],
            name: this.picList[j]['name'],
            userid: this.picList[j]['userid'],
          });
        }
      }
    }
    this.ngassignedtolist = selectedRole;
    this.serviceForm.picid = recurrD.assignedto;
    this.picValue = recurrD.assignedtoname;

    this.membersearch = recurrD.assignedtoname;
    this.attachmentList = recurrD.filelist;
    this.checkrecurring = true;
    this.serviceForm.reopen = 'true';

    console.log(this.assignedtolist);
    this.messageService.openSnackBar('Ticket restored', 'success');
  }
  cancelEdit() {
    this.searchDisable = false;
  }
}
