<div class="container-fluid">
  <div class="content">
    <app-breadcrumb s_breadcrumb="Campaign">
    </app-breadcrumb>


    <div class="row row-custom header">
      <div class="col-md-6 mb-3">
        <button *ngIf="!campaignAddOrNot" type="button" class="btn btn-custom"
          (click)="campaignAddOrNot = !campaignAddOrNot">
          <i class="fa fa-plus me-1"></i>New Campaign
        </button>

        <div *ngIf="campaignAddOrNot" class="close-save-button">
          <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
            Cancel
          </button>
          <app-button-loader (click)="submitCampaign()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="saveLoading"></app-button-loader>
        </div>

      </div>
    </div>
    <form>
      <div class="mb-3" *ngIf="campaignAddOrNot">
        <label for="desc" class="form-label">Description</label>
        <input type="text" class="form-control" [(ngModel)]="camForm.description" id="desc" name="desc"
          placeholder="Enter Campaign Description" required #desc="ngModel"
          [ngClass]="{ 'is-invalid': camFormSubmitted && desc.invalid }" (keyup.enter)="submitCampaign()">

        <div class="invalid-feedback" *ngIf="desc.invalid || (desc.dirty || desc.touched) && camFormSubmitted">
          <div *ngIf="desc.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
      <div *ngIf="campaignAddOrNot">
        <div class="row">
          <div class="mb-3 col-md-6">
            <label for="status" class="form-label">Campaign Status</label>
            <select class="form-select" aria-label="Select Status" id="status" name="status"
              [(ngModel)]="camForm.status" required #status="ngModel"
              [ngClass]="{ 'is-invalid': camFormSubmitted && status.invalid }">
              <option *ngFor="let status of statusList; let index =  index" [value]="status.id">
                {{status.value}}
              </option>
            </select>

            <div class="invalid-feedback"
              *ngIf="status.invalid || (status.dirty || status.touched) && camFormSubmitted">
              <div *ngIf="status.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <label for="sstatus" class="form-label">Public Summary</label>
            <select class="form-select" aria-label="Select Summary Status" id="sstatus" name="sstatus"
              [(ngModel)]="camForm.summarystatus" required #sstatus="ngModel"
              [ngClass]="{ 'is-invalid': camFormSubmitted && sstatus.invalid }">
              <option *ngFor="let status of statusList; let index =  index" [value]="status.id">
                {{status.name}}
              </option>
            </select>

            <div class="invalid-feedback"
              *ngIf="sstatus.invalid || (sstatus.dirty || sstatus.touched) && camFormSubmitted">
              <div *ngIf="sstatus.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>

        </div>
        <div>
          <label for="vaccess" class="form-label">Voting Access </label>
          <ng-select placeholder="Select voting Access" [multiple]="true" [(ngModel)]="camForm.votingaccess"
            name="vaccess" id="vaccess">
            <ng-option value="Public">Public</ng-option>
            <ng-option value="Mobile">Mobile</ng-option>
          </ng-select>
        </div>
      </div>
    </form>
    <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center" *ngIf="!campaignAddOrNot">

      <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search with Description/Status/Summary"
          aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
          [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
          [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="dropdown" [ngClass]="{'ms-2' : !isMobileView, 'ms-0': isMobileView}">
        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false" title="Page Size">
          <mat-icon>filter_list</mat-icon>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <div c style="text-align: center;">Page size</div>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li class="dropdown-item" *ngFor="let page of pg">
            <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
              id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
            <label for="pg{{page.name}}" class="col-10 p-1">
              {{page.name}}
            </label>
          </li>
        </ul>
      </div>
      <div class="ms-2">
        <button class="btn btn-custom" (click)="refresh()" title="Refresh">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="line"></div>
      <div class="d-flex align-items-center">
        <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{campaigns.length}}</span>
      </div>
    </div>
    <div class="input-group mb-2" *ngIf="isMobileView && !campaignAddOrNot">
      <input class="form-control" type="text" placeholder="&#xf002; Search with Description/Status/Summary"
        aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
        [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
      <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
        [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
        <mat-icon class="c-icon">close</mat-icon>
      </button>
    </div>
    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
      <table class="table table-responsive table-borderless" *ngIf="campaignAddOrNot == false">
        <thead class="table-header">
          <tr>
            <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="description">Description</td>
            <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="status">Campaign Status</td>
            <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="summarystatus">Public Summary</td>
            <td scope="col" class="col-lg-3 col-md-3">Voting Access</td>
            <td scope="col" class="d-flex justify-content-center">Action</td>
          </tr>
        </thead>
        <tbody *ngIf="gettingCampaign">
          <tr>
            <td colspan="4" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!gettingCampaign">
          <tr *ngIf="campaigns.length == 0">
            <td colspan="4" class="nodata">Empty</td>
          </tr>
          <tr
            *ngFor="let item of campaigns|filter : searchText:24 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index">
            <td class="td-data td-edit" (click)="editCampaign(item)">
              <span [title]="item.description">{{item.description}}</span>
            </td>
            <td class="td-data" [ngClass]="{'green' : item.status == '001', 'red' : item.status == '002'}">
              <span [title]="item.status == '001' ? 'Open' : 'Closed'">{{item.status == '001' ? 'Open' :
                'Closed'}}</span>
            </td>
            <td class="td-data"
              [ngClass]="{'green' : item.summarystatus == '001', 'red' : item.summarystatus == '002'}">
              <span [title]="item.summarystatus == '001' ? 'On' : 'Off'">{{item.summarystatus == '001' ?
                'On' : 'Off'}}</span>
            </td>
            <td class="td-data">
              <div class="d-flex">
                <span *ngIf="item.votestatus == '001'">Public</span>
                <span *ngIf="item.votestatus == '001' && item.mobilestatus == '001' "> &nbsp;&&nbsp;</span>
                <span *ngIf="item.mobilestatus == '001'">Mobile</span>
              </div>
            </td>
            <td class="d-flex justify-content-center align-items-center">
              <button class="btn btn-outline-primary me-2" (click)="openQR(item)" title="Open QR Page">
                <i class='fa fa-qrcode'></i>
              </button>
              <button class="btn btn-outline-primary me-2" (click)="viewRounds(item)" title="View Rounds">
                <i class='fas fa-clipboard-list'></i>
              </button>
              <button class="btn btn-outline-primary" (click)="editCampaign(item)" title="Edit Campaign">
                <i class='fa fa-edit'></i>
              </button>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
    <pagination-controls class="paginate" (pageChange)="p = $event"
      *ngIf="campaigns.length > itemPerPage && !campaignAddOrNot" previousLabel="Prev" nextLabel="Next">
    </pagination-controls>
  </div>
</div>