<h3>{{ isEditing ? "Edit" : "New" }}&nbsp;QR</h3>
<!-- <button (click)="test()">Test</button> -->
<form [formGroup]="form" (ngSubmit)="submitForm()">
  <ng-container>
    <div class="">
      <div class="card card-body my-card mb-3">
        <div class="row">
          <div class="col-md-12" id="form-container-anchor">
            <div class="form-group mb-3">
              <label class="form-label" for="type"> Type </label>
              <select formControlName="type" class="form-select" (change)="checkFile($event)" id="type" name="type">
                <option *ngFor="let item of typeList" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label" for="name"> Name </label>
              <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" id="name"
                name="name" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    formControl.name.invalid
                }" />
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label" for="orgname">
                Organization Name
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Organization Name"
                formControlName="orgname"
                id="orgname"
                name="orgname"
              />
            </div>
          </div> -->
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label" for="userid"> User ID </label>
              <input type="text" class="form-control" placeholder="Enter User ID" formControlName="userid" id="userid"
                name="userid" />
            </div>
          </div>
          <!-- <ng-container
            *ngIf="!isEditing && !formControl.userid.invalid && (formControl.type.value != '001' || (formControl.type.value == '001' && submitted && formControl.mobile.invalid))">
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="password"> Password <i class="fa fa-info-circle ms-1" matTooltip="Password must have the minimum length of 7 and must contain at least one upper case letter, one lower case letter and one special character."></i> </label>
                <input type="password" class="form-control" formControlName="password" id="password" name="password"
                  [ngClass]="{ 'is-invalid': submitted && formControl.password.invalid}" />
                  <div class="invalid-feedback" *ngIf="formControl.password.invalid">
                    <div>
                      Password must have the minimum length of 7 and must contain at least one upper case letter, one lower case letter and one special character.
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="confirmpassword"> Confirm Password </label>
                <input type="password" class="form-control" formControlName="confirmpassword" id="confirmpassword"
                  name="confirmpassword"
                  [ngClass]="{ 'is-invalid': submitted && formControl.confirmpassword.invalid}" />
                <div class="error" *ngIf="formControl.password.value != formControl.confirmpassword.value">
                  <div>
                    Passwords do not match.
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="isEditing && !formControl.userid.invalid && (olduserid != formControl.userid.value) && (formControl.type.value != '001' || (formControl.type.value == '001' && submitted && formControl.mobile.invalid))">
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="password"> Password <i class="fa fa-info-circle ms-1" matTooltip="Password must have the minimum length of 7 and must contain at least one upper case letter, one lower case letter and one special character."></i> </label>
                <input type="password" class="form-control" formControlName="password" id="password" name="password"
                  [ngClass]="{ 'is-invalid': submitted && formControl.password.invalid}" />
                  <div class="invalid-feedback" *ngIf="formControl.password.invalid">
                    <div>
                      Password must have the minimum length of 7 and must contain at least one upper case letter, one lower case letter and one special character.
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="confirmpassword"> Confirm Password </label>
                <input type="password" class="form-control" formControlName="confirmpassword" id="confirmpassword"
                  name="confirmpassword"
                  [ngClass]="{ 'is-invalid': submitted && formControl.confirmpassword.invalid}" />
                <div class="error" *ngIf="formControl.password.value != formControl.confirmpassword.value">
                  <div>
                    Passwords do not match.
                  </div>
                </div>
              </div>
            </div>
          </ng-container> -->

          <ng-container
            *ngIf="!isEditing && !formControl.userid.invalid">
            <div class="col-md-6">
              <div class="form-group mb-3">
                <!-- <label class="form-label" for="password"> Password <i class="fa fa-info-circle ms-1" matTooltip="Password must have the minimum length of 7 and must contain at least one upper case letter, one lower case letter and one special character."></i> </label> -->
                <label class="form-label" for="password"> Password <i class="fa fa-info-circle ms-1" matTooltip="Password must have the minimum length of 7."></i> </label>
                <input type="password" class="form-control" formControlName="password" id="password" name="password"
                  [ngClass]="{ 'is-invalid': submitted && formControl.password.invalid}" />
                  <div class="invalid-feedback" *ngIf="formControl.password.invalid">
                    <!-- <div>
                      Password must have the minimum length of 7 and must contain at least one upper case letter, one lower case letter and one special character.
                    </div> -->
                    <div>
                      Password must have the minimum length of 7.
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="confirmpassword"> Confirm Password </label>
                <input type="password" class="form-control" formControlName="confirmpassword" id="confirmpassword"
                  name="confirmpassword"
                  [ngClass]="{ 'is-invalid': submitted && formControl.confirmpassword.invalid}" />
                <div class="error" *ngIf="formControl.password.value != formControl.confirmpassword.value">
                  <div>
                    Passwords do not match.
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="isEditing && !formControl.userid.invalid && (olduserid != formControl.userid.value) && (formControl.type.value != '001' || (formControl.type.value == '001' && submitted))">
            <div class="col-md-6">
              <div class="form-group mb-3">
                <!-- <label class="form-label" for="password"> Password <i class="fa fa-info-circle ms-1" matTooltip="Password must have the minimum length of 7 and must contain at least one upper case letter, one lower case letter and one special character."></i> </label> -->
                <label class="form-label" for="password"> Password <i class="fa fa-info-circle ms-1" matTooltip="Password must have the minimum length of 7."></i> </label>
                <input type="password" class="form-control" formControlName="password" id="password" name="password"
                  [ngClass]="{ 'is-invalid': submitted && formControl.password.invalid}" />
                  <div class="invalid-feedback" *ngIf="formControl.password.invalid">
                    <!-- <div>
                      Password must have the minimum length of 7 and must contain at least one upper case letter, one lower case letter and one special character.
                    </div> -->
                    <div>
                      Password must have the minimum length of 7.
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="confirmpassword"> Confirm Password </label>
                <input type="password" class="form-control" formControlName="confirmpassword" id="confirmpassword"
                  name="confirmpassword"
                  [ngClass]="{ 'is-invalid': submitted && formControl.confirmpassword.invalid}" />
                <div class="error" *ngIf="formControl.password.value != formControl.confirmpassword.value">
                  <div>
                    Passwords do not match.
                  </div>
                </div>
              </div>
            </div>
          </ng-container>



          <div class="form-group mb-3" *ngIf="formControl.type.value != '001'">
            <label class="form-label" for="file">Attachment</label>
            <!-- <i class="ms-1 fa fa-question-circle" matTooltip="Updating to different file type will also update the QR code." *ngIf="isEditing"></i> -->
            <div class="dragdrop form-control p-0" name="file" id="file" [ngClass]="{
                'is-invalid':
                  submitted &&
                  ((!isEditing && !fileSelected) ||
                    (isEditing && !formControl.filename.value))
              }">
              <ng-container *ngIf="
                  fileSelected != '' ||
                    (isEditing && formControl.filename.value);
                  else uploadTemplate
                ">
                <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3 td-edit"
                  appDragDrop (onFileDropped)="uploadFile($event)" (click)="choosefile.click()">
                  <!-- <div class="ms-2"> -->
                  <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
                  <div>
                    <img [src]="
                        '../../../assets/images/' +
                        (formControl.filetype.value == 'pdf'
                          ? 'pdf'
                          : 'image') +
                        '.png'
                      " width="40px" />
                  </div>
                  <div class="ms-2">
                    <strong>{{ formControl.filename.value }}</strong>
                  </div>
                  <!-- </div> -->
                </div>
              </ng-container>
              <ng-template #uploadTemplate>
                <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
                  (onFileDropped)="uploadFile($event)">
                  <div class="me-3">Drag and drop file here</div>
                  <div class="me-3">or</div>
                  <div>
                    <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
                    <button type="button" class="btn btn-custom" (click)="choosefile.click()">
                      Choose File
                    </button>
                  </div>
                </div>

              </ng-template>
            </div>
            <small>Only .pdf, .png, .jpg, .jpeg are allowed.</small>
            <!-- <small *ngIf="isEditing"
              >Only .{{ allowedFileType }} is allowed.</small
            > -->
          </div>

          <div class="mb-3" *ngIf="formControl.type.value != '001'">
            <h3 class="mb-2">Theme</h3>
            <div class="form-check form-check-inline me-5">
              <input class="form-check-input" type="radio" name="template" id="light" formControlName="template"
                value="001">
              <label class="form-check-label" for="light">Light</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="template" id="dark" formControlName="template"
                value="002">
              <label class="form-check-label" for="dark">Dark</label>
            </div>
          </div>


        </div>
      </div>

      <ng-container *ngIf="formControl.type.value == '001'">
        <div class="card card-body my-card mb-3">
          <div class="row">
            <div>Profile</div>
            <div style="position: relative">
              <div class="uploadfilecontainer" (click)="pfFileInput.click()" appDragDrop
                (onFileDropped)="uploadFile($event)">
                <!-- <span>Choose Image</span> -->
                <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="
                    pfImgURL
                      ? pfImgURL
                      : '../../../assets/images/uploadphoto.png'
                  " />
                <!-- <mat-icon class="remove-image">remove_circle</mat-icon> -->

                <input hidden type="file" #pfFileInput accept="image/*" (change)="pfImgUpload($event)" />
              </div>
              <button mat-icon-button class="remove-drag-image" *ngIf="pfImgURL" (click)="removePfImage()">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </div>
            <!-- <img [src]="imgURL" height="200" *ngIf="imgURL"> -->
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="contactname"> Contact Name </label>
                <input type="text" class="form-control" placeholder="Enter Contact Name" formControlName="contactname"
                  id="contactname" name="contactname" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="post"> Rank </label>
                <input type="text" class="form-control" placeholder="Enter Rank" formControlName="post" id="post"
                  name="post" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="company"> Department </label>
                <input type="text" class="form-control" placeholder="Enter Department" formControlName="department"
                  id="department" name="department" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="company"> Organization </label>
                <input type="text" class="form-control" placeholder="Enter Organization" formControlName="company"
                  id="company" name="company" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="mobile"> Mobile </label>
                <input type="text" class="form-control" placeholder="Enter Mobile" formControlName="mobile" id="mobile"
                  name="mobile" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="workphone"> Work Phone </label>
                <input type="text" class="form-control" placeholder="Enter Work Phone" formControlName="workphone"
                  id="workphone" name="workphone" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="email"> Email </label>
                <input type="text" class="form-control" placeholder="Enter Email" formControlName="email" id="email"
                  name="email" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="workemail"> Work Email </label>
                <input type="text" class="form-control" placeholder="Enter Work Email" formControlName="workemail" id="workemail"
                  name="workemail" />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="website"> Website </label>
                <input type="text" class="form-control" placeholder="Enter Website" formControlName="website"
                  id="website" name="website" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="location"> Location </label>
                <input type="text" class="form-control" placeholder="Enter Location" formControlName="location"
                  id="location" name="location" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="googlemap"> Google Map Address </label>
                <input type="text" class="form-control" placeholder="https://goo.gl/maps/*****"
                  formControlName="googlemap" id="googlemap" name="googlemap" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="linkedin"> LinkedIn </label>
                <input type="text" class="form-control" placeholder="https://www.linkedin.com/in/******"
                  formControlName="linkedin" id="linkedin" name="linkedin" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="facebook"> Facebook </label>
                <input type="text" class="form-control" placeholder="https://www.facebook.com/*******"
                  formControlName="facebook" id="facebook" name="facebook" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="instagram"> Instagram </label>
                <input type="text" class="form-control" placeholder="https://www.instagram.com/*******"
                  formControlName="instagram" id="instagram" name="instagram" />
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="messenger"> Messenger </label>
                <input type="text" class="form-control" placeholder="https://www.messenger.com/*******"
                  formControlName="messenger" id="messenger" name="messenger" />
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="viber"> Viber </label>
                <input type="text" class="form-control" placeholder="+959*******" formControlName="viber" id="viber"
                  name="viber" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="whatsapp"> WhatsApp </label>
                <input type="text" class="form-control" placeholder="+959*******"
                  formControlName="whatsapp" id="whatsapp" name="whatsapp" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="telegram"> Telegram </label>
                <input type="text" class="form-control" placeholder="Username"
                  formControlName="telegram" id="telegram" name="telegram" />
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="form-label" for="file"> Attachment </label>
              <!-- <i class="ms-1 fa fa-question-circle" matTooltip="Updating to different file type will also update the QR code." *ngIf="isEditing"></i> -->
              <div class="dragdrop form-control p-0  position-relative" name="file" id="file" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    ((!isEditing && !fileSelected) ||
                      (isEditing && !formControl.filename.value))
                }">
                <ng-container *ngIf="
                    fileSelected != '' ||
                      (isEditing && formControl.filename.value);
                    else uploadTemplate
                  ">
                  <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3 td-edit"
                    appDragDrop (onFileDropped)="uploadFile($event)" (click)="choosefile.click()">
                    <!-- <div class="ms-2"> -->
                    <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
                    <div>
                      <img [src]="
                          '../../../assets/images/' +
                          (formControl.filetype.value == 'pdf'
                            ? 'pdf'
                            : 'image') +
                          '.png'
                        " width="40px" />
                    </div>
                    <div class="ms-2">
                      <strong>{{ formControl.filename.value }}</strong>
                    </div>
                    <!-- </div> -->

                  </div>
                  <button type="button" mat-icon-button class="remove-attachment" (click)="removeAttachment()">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </ng-container>
                <ng-template #uploadTemplate>
                  <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
                    (onFileDropped)="uploadFile($event)">
                    <div class="me-3">Drag and drop file here</div>
                    <div class="me-3">or</div>
                    <div>
                      <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
                      <button type="button" class="btn btn-custom" (click)="choosefile.click()">
                        Choose File
                      </button>
                    </div>
                  </div>

                </ng-template>
              </div>
              <small>Only .pdf, .png, .jpg, .jpeg are allowed.</small>
              <!-- <small *ngIf="isEditing"
                >Only .{{ allowedFileType }} is allowed.</small
              > -->
            </div>
            <div class="mb-3">
              <h3 class="mb-2">Theme</h3>
              <div class="form-check form-check-inline me-5">
                <input class="form-check-input" type="radio" name="template" id="light" formControlName="template"
                  value="001">
                <label class="form-check-label" for="light">Light</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="template" id="dark" formControlName="template"
                  value="002">
                <label class="form-check-label" for="dark">Dark</label>
              </div>
            </div>

            <!-- <div class="form-group mb-3"> <---Your contact file
              <label for="contactfile" class="form-label">Contact File</label>
              <div class="input-group" *ngIf="isContactFileSelected">
                <input
                  class="form-control"
                  type="text"
                  id="contactfile"
                  readonly
                  formControlName="contactfilename"
                />
                <button
                  class="btn btn-custom"
                  (click)="removeContact()"
                  id="removebtn"
                >
                  Remove
                </button>
              </div>
              <ng-container *ngIf="!isContactFileSelected">
                <input
                  class="form-control"
                  type="file"
                  id="contactfile"
                  (change)="contactFileSelect($event)"
                  accept=".vcf"
                  #contactfile
                />
              </ng-container>
              <small *ngIf="!isEditing">Only .vcf is allowed.</small>
            </div> -->
          </div>
        </div>
      </ng-container>

    </div>
  </ng-container>
  <div mat-dialog-actions>
    <div class="mb-3 w-100">
      <div class="d-flex align-items-center flex-row flex-wrap justify-content-end">
        <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
          Cancel
        </button>
        <app-button-loader [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
        </app-button-loader>
      </div>
    </div>
  </div>
</form>