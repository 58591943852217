import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDateToShow'
})
export class FormateDateToShowPipe implements PipeTransform {

  transform(date: any): unknown {
    if (date != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

}
