import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { throwError } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

type WarrantyForm = {
  warrantycode: string;
  month: string;
  remark: string;
};

@Component({
  selector: 'app-warranty-form',
  templateUrl: './warranty-form.component.html',
  styleUrls: ['./warranty-form.component.css'],
})
export class WarrantyFormComponent implements OnInit {
  submitted: boolean = false;
  generalDatePickerConfig: any = this.allInOneService.datePickerConfig;
  @Output('closeForm') clsoeForm = new EventEmitter<any>();
  @Input() editData: WarrantyForm | null = null;
  saveLoading: boolean = false;
  warrantyForm: WarrantyForm = {
    warrantycode: '',
    remark: '',
    month: '',
  };

  constructor(
    private kunyekService: KunyekService,
    private allInOneService: AllInOneService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    if (this.editData) {
      this.warrantyForm = { ...this.editData }; //copy editdata obj
    }
  }

  handleSave() {
    if (this.saveLoading) return;
    this.submitted = true;
    if (
      this.warrantyForm.month == '' ||
      this.warrantyForm.warrantycode == '' ||
      this.warrantyForm.month == null
    ) {
      return;
    }
    const data = {
      orgid: this.allInOneService.getSelectedOrg(),
      ...this.warrantyForm,
    };
    console.log(data);
    this.saveLoading = true;
    if (this.editData) {
      this.kunyekService.updateWarranty(data).subscribe({
        next: (res) => {
          if (res.returncode == '300') {
            this.saveLoading = false;
            this.messageService.openSnackBar('Update Scueessfully', 'success');
            this.closeEdit();
          } else {
            this.saveLoading = false;
            this.messageService.openSnackBar('Server Error', 'warn');
          }
        },
        error: (error) => {
          this.saveLoading = false;
          this.messageService.openSnackBar('Server Error', 'warn');
        },
      });
    } else {
      this.kunyekService.addWarranty(data).subscribe({
        next: (res) => {
          if (res.returncode == '300') {
            this.saveLoading = false;
            this.messageService.openSnackBar('Create Scueessfully', 'success');
            this.closeEdit();
          } else {
            this.saveLoading = false;
            this.messageService.openSnackBar('Server Error', 'warn');
          }
        },
        error: (error) => {
          this.saveLoading = false;
          this.messageService.openSnackBar('Server Error', 'warn');
          console.log(error);
        },
      });
    }
  }

  closeEdit() {
    console.log('hello');
    this.clsoeForm.emit();
  }
}
