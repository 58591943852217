<div class="container-fluid">
  <div class="content">
    <!-- <ng-container *ngIf="isAdmin">
      <app-hcm-breadcrumb *ngIf="!addWP" s_breadcrumb="Work Policy">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="addWP" s_breadcrumb="Work Policy"
        [t_breadcrumb]="isEditing ? 'Ref No.' + this.wpForm.refno : 'New Work Policy'" (emit)="cancel()">
      </app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb *ngIf="!addWP" [s_breadcrumb]="'Work Policy'">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="addWP" [s_breadcrumb]="'Work Policy'"
        [t_breadcrumb]="isEditing ? 'Ref No.' + this.wpForm.refno : 'New Work Policy'" (emit)="cancel()">
      </app-office-breadcrumb>
    </ng-container> -->

    <ng-container *ngIf="allinoneService.isAttendanceView">
      <app-hcm-breadcrumb *ngIf="!addWP" s_breadcrumb="Work Policy">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="addWP" s_breadcrumb="Work Policy"
        [t_breadcrumb]="isEditing ? 'Ref No.' + this.wpForm.refno : 'New Work Policy'" (emit)="cancel()">
      </app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!allinoneService.isAttendanceView">
      <app-team-breadcrumb *ngIf="!addWP" [s_breadcrumb]="'Work Policy'">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="addWP" [s_breadcrumb]="'Work Policy'"
        [t_breadcrumb]="isEditing ? 'Ref No.' + this.wpForm.refno : 'New Work Policy'" (emit)="cancel()">
      </app-team-breadcrumb>
    </ng-container>

    <ng-container *ngIf="addWP; else listView">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!saveLoading">
          Cancel
        </button>
        <app-button-loader class="mb-3" (click)="submitWP()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
      <div class="card card-body my-card">
        <div class="row">
          <div class="mb-3 col-md-6">
            <label for="refno" class="form-label">Ref No.</label>
            <input class="form-control" id="refno" [(ngModel)]="wpForm.refno" name="refno" #refno="ngModel"
              [disabled]="refNoDisabled" placeholder="TBA" />
          </div>
          <div class="mb-3 col-md-6">
            <label for="name" class="form-label">Name</label>
            <input class="form-control" id="name" [(ngModel)]="wpForm.name" name="name" #name="ngModel"
              [ngClass]="{'is-invalid' : wpFormSubmitted && name.invalid}" required />
            <div class="invalid-feedback" *ngIf="name.invalid || ((name.dirty || name.touched) && wpFormSubmitted)">
              <div *ngIf="name.errors?.required">
                {{ allinoneService.input_err_msg }}
              </div>
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <label for="startdate" class="form-label">Start Date</label>
            <input class="form-control" type="date" id="startdate" [(ngModel)]="wpForm.startdate" name="startdate"
              (ngModelChange)="compareDate()" #startdate="ngModel" max="9999-12-31" [disabled]="isEditing || wpForm.type == '002'" />
              <!-- <input type="date" class="form-control datepicker-readonly" [ngClass]="{'datepicker-readonly' : !isEditing && wpForm.type != '002' }" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly
            [(ngModel)]="wpForm.startdate" style="min-width: 200px;" required #startdate="ngModel" [disabled]="isEditing || wpForm.type == '002'"
            (ngModelChange)="compareDate()"> -->
          </div>
          <div class="mb-3 col-md-6">
            <label for="enddate" class="form-label">End Date</label>
            <input class="form-control" type="date" id="enddate" [(ngModel)]="wpForm.enddate" [min]="wpForm.startdate"
              name="enddate" #enddate="ngModel" max="9999-12-31" [disabled]="isEditing || wpForm.type == '002'" />
              <!-- <input type="text" class="form-control datepicker-readonly" [ngClass]="{'datepicker-readonly' : !isEditing && wpForm.type != '002' }" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly
            [(ngModel)]="wpForm.enddate" style="min-width: 200px;" required #enddate="ngModel" [disabled]="isEditing || wpForm.type == '002'"> -->
          </div>
          <div class="mb-3 col-md-12">
            <div class="form-check form-check-inline me-5">
              <input class="form-check-input" type="radio" name="roster" id="roster" value="001"
                [(ngModel)]="wpForm.type" (change)="typeChange('001')" [disabled]="isEditing">
              <label class="form-check-label" for="roster">Roster</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="calendar" id="calendar" value="002"
                [(ngModel)]="wpForm.type" (change)="typeChange('002')" [disabled]="isEditing">
              <label class="form-check-label" for="calendar">Calendar</label>
            </div>
          </div>
          <div *ngIf="wpForm.type == '001'" class="mb-3 col-md-6">
            <label for="roster" class="form-label">Roster</label>
            <select class="form-select" name="roster" id="roster" [(ngModel)]="wpForm.rosterid"
              [disabled]="rosterListLoading" #roster="ngModel"
              [ngClass]="{'is-invalid' : wpFormSubmitted &&  roster.invalid}" required>
              <option value="">-</option>
              <option *ngFor="let roster of rosterList" [value]="roster.rosterid">
                {{ roster.name }}
              </option>
            </select>
            <div class="invalid-feedback" *ngIf="
              roster.invalid ||
              ((roster.dirty || roster.touched) && wpFormSubmitted)
            ">
              <div *ngIf="roster.errors?.required">
                {{ allinoneService.input_err_msg }}
              </div>
            </div>
          </div>
          <div *ngIf="wpForm.type == '002'" class="mb-3 col-md-6">
            <label for="calendar" class="form-label">Calendar</label>
            <select class="form-select" name="calendar" id="calendar" [(ngModel)]="wpForm.calendarid"
              [disabled]="calendarListLoading" #calendar="ngModel"
              [ngClass]="{'is-invalid' : wpFormSubmitted &&  calendar.invalid}" required (ngModelChange)="calendarChange()"
              [disabled]="isEditing">
              <option value="">-</option>
              <option *ngFor="let calendar of calendarList" [value]="calendar.calendarid">
                {{ calendar.name }}
              </option>
            </select>
            <div class="invalid-feedback" *ngIf="
            calendar.invalid ||
              ((calendar.dirty || calendar.touched) && wpFormSubmitted)
            ">
              <div *ngIf="calendar.errors?.required">
                {{ allinoneService.input_err_msg }}
              </div>
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <label for="wpconfig" class="form-label">Work Policy</label>
            <select class="form-select" name="wpconfig" id="wpconfig" [(ngModel)]="wpForm.wpid"
              [disabled]="wpconfigLoading" #wpconfig="ngModel"
              [ngClass]="{'is-invalid' : wpFormSubmitted &&  wpconfig.invalid}" required>
              <option value="">-</option>
              <option *ngFor="let wp of wpconfigList" [value]="wp.wpid">
                {{ wp.name }}
              </option>
            </select>
            <div class="invalid-feedback" *ngIf=" wpconfig.invalid ||
              ((wpconfig.dirty || wpconfig.touched) && wpFormSubmitted)">
              <div *ngIf="wpconfig.errors?.required">
                {{ allinoneService.input_err_msg }}
              </div>
            </div>
          </div>
          <div class="mb-3 col-md-12">
            <label class="form-label" for="description"> Description </label>
            <textarea class="form-control" name="v" id="description" [(ngModel)]="wpForm.description" rows="4">
          </textarea>
          </div>
        </div>
      </div>
      <div class="card card-body my-card mt-3">
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <div>
            <h2 *ngIf="wpForm.type == '001' " class="info mb-0">Employee</h2>

            <div *ngIf="wpForm.type == '002'">
              <div class="form-check form-check-inline me-5">
                <input class="form-check-input" type="radio" name="etable" id="etable" value="001"
                  [(ngModel)]="wpForm.table">
                <label class="form-check-label" for="etable">Employee</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="ctable" id="ctable" value="002"
                  [(ngModel)]="wpForm.table">
                <label class="form-check-label" for="ctable">Calendar</label>
              </div>
            </div>

          </div>
          <ng-container *ngIf="!isEditing && wpForm.table == '001'">
            <div>
              <button class="btn btn-custom" (click)="employeePopup()"><i class="fa fa-plus me-1"></i> Add</button>
            </div>
          </ng-container>
        </div>
        <div *ngIf="wpForm.table == '001'" class="table-responsive" matSort (matSortChange)="sortEmployee($event)">
          <table class="table table-responsive table-borderless">
            <thead class="table-header">
              <tr>
                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="userid">User ID</td>
                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="username">User Name</td>
                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="employeeid">Employee ID</td>
                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="post">Post</td>
                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="department">Department</td>
                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="division">Division</td>
                <ng-container *ngIf="!isEditing">
                  <td scope="col" class="d-flex justify-content-center">Action</td>
                </ng-container>
              </tr>
            </thead>

            <tbody>
              <tr *ngIf="employeeList.length == 0">
                <td colspan="7" class="nodata">Empty</td>
              </tr>
              <tr *ngFor="let employee of employeeList; let index = index">
                <!-- <td class="td-data td-edit">
                  <span [title]="employee.userid">{{employee.userid}}</span>
                </td> -->
                <td class="td-data">
                  <div [title]="employee.userid" class="d-flex flex-column align-items-start justify-content-center">
                    <span class="data-overflow">{{ employee.userid }}</span>
                    <span class="data-overflow" *ngIf="employee.newuserid">{{ employee.newuserid }}</span>
                  </div>
                </td>
                <td class="td-data">
                  <span [title]="employee.username">{{employee.username}}</span>
                </td>
                <td class="td-data">
                  <span [title]="employee.employeeid">{{employee.employeeid}}</span>
                </td>
                <td class="td-data">
                  <span [title]="employee.type">{{employee.type}}</span>
                </td>
                <td class="td-data">
                  <span [title]="employee.department">{{employee.department}}</span>
                </td>
                <td class="td-data">
                  <span [title]="employee.division">{{employee.division}}</span>
                </td>

                <ng-container *ngIf="!isEditing">
                  <td class="d-flex justify-content-center align-items-center">
                    <button class="btn btn-outline-danger" (click)="deleteEmployee(index)">
                      <i class='fa fa-trash-o'></i>
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="wpForm.table == '002'">
          <app-office-calender-table #calendarTable
                    [startdate]="this.allinoneService.formatDBToShow(this.allinoneService.formatDate(this.wpForm.startdate))"
                    [enddate]="this.allinoneService.formatDBToShow(this.allinoneService.formatDate(this.wpForm.enddate))"
                    [weeks]="weeks" [shifts]="shiftList" [roster]="" (getData)="updateShiftData($event)"></app-office-calender-table>
        </div>
      </div>
    </ng-container>
    <ng-template #listView>
      <div class="d-flex align-items-center">
        <div class="mb-3 me-3">
          <button class="btn btn-custom me-auto" (click)="newWP()">
            <i class="fa fa-plus me-1"></i> New
          </button>
        </div>
        <div class="mb-3 me-3">
          <button type="button" class="btn btn-custom" (click)="export()" title="Export To Excel"
            [disabled]="wpList.length == 0">
            <img class="excel-icon" src="../../../assets/images/excel.png" />
            Export
          </button>
        </div>
        <div class="mb-3 me-3">
          <button type="button" class="btn btn-custom" title="Import from Excel" (click)="importSheet()"
            *ngIf="importStatus == '001' || importStatus == '005'">
            <img class="excel-icon" src="../../../assets/images/excel.png">
            Import
          </button>
          <div class="d-flex align-items-center" *ngIf="importStatus == '002' || importStatus == '003' ">
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-outline-black" disabled>
                <i class="fas fa-circle-notch fa-spin me-2" *ngIf="importStatus == '002' "></i>
                <img src="../../../assets/images/upload.gif" width="20px" class="me-2" *ngIf="importStatus == '003' "
                  alt="">
                {{ importStatus == '002' ? 'Checking' : 'Uploading' }}
              </button>
              <button type="button" class="btn btn-outline-black" (click)="getImportStatus()">
                <i class="fas fa-sync-alt" [ngClass]="{'fa-spin' : refreshingImportStatus}"></i>
              </button>
            </div>
          </div>
          <button type="button" class="btn btn-outline-danger" (click)="importSheet(true)" *ngIf="importStatus == '004'"
            style="width : auto;">
            <i class="fas fa-exclamation-circle me-2"></i>
            Import Error
          </button>
        </div>
      </div>
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by Name/Employee ID"
            aria-label="Search by All Fields" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            (ngModelChange)="searchTextChange()" [ngClass]="{ 'c-search-input-with-clear': searchText }"
            style="font-family: FontAwesome, Ubuntu" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{ 'ms-0': isMobileView, 'ms-2': !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{ page.count }}" (change)="changePageCount($event)"
                id="pg{{ page.name }}" class="p-1" [checked]="page.count == 100" />
              <label for="pg{{ page.name }}" class="col-10 p-1">
                {{ page.name }}
              </label>
            </li>
          </ul>
        </div>

        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div class="line"></div>
        <div class="filtter-btn align-items-center" [matMenuTriggerFor]="departmentmenu">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Type&nbsp;:&nbsp; {{filterType == '' ? 'All' : filterType == '001' ? 'Roster' : 'Calendar'}}
            <mat-menu #departmentmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let type of filterMenu" (click)="filterTypeChange(type.value)">
                {{ type.name }}
              </button>
            </mat-menu>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{
            (wpList | filter : searchText : "wpList").length
            }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by All Fields"
            aria-label="Search by All Fields" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="wpList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="refno">
                Ref No.
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="employeeid">
                Employee ID
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="name">
                Name
              </td>
              <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="post">
                Post
              </td>
              <!-- <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="department">
                Department
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="division">
                Division
              </td> -->
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="wpname">
                Work Policy
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="starttime">
                Start Date
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="endtime">
                End Date
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="roster" *ngIf="filterType != '002'">
                Roster
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="calendar" *ngIf="filterType != '001'">
                Calendar
              </td>

              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>

          <tbody *ngIf="wpListLoading">
            <tr>
              <td colspan="10" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!wpListLoading">
            <tr *ngIf="wpList.length == 0">
              <td colspan="10" class="nodata">Empty</td>
            </tr>

            <tr *ngFor="
                let wp of wpList
                  | filter : searchText : 'wpList'
                  | paginate
                    : { itemsPerPage: itemPerPage, currentPage: currentPage };
                let index = index
              ">
              <td class="td-data-no-min-width td-edit" (click)="editWP(wp)">
                <span [title]="wp.refno">{{ wp.refno }}</span>
              </td>
              
              <td class="td-data td-edit" (click)="editWP(wp)">
                <span [title]="wp.employeeid">{{ wp.employeeid }}</span>
              </td>
              <td class="td-data td-edit" (click)="editWP(wp)">
                <span [title]="wp.name">{{ wp.username == "" ? wp.userid : wp.username }}</span>
              </td>
              <td class="td-data td-edit" (click)="editWP(wp)">
                <span [title]="wp.classcodename">{{ wp.classcodename }}</span>
              </td>
              <!-- <td class="td-data">
                <span [title]="wp.department">{{ wp.department }}</span>
              </td>
              <td class="td-data">
                <span [title]="wp.division">{{ wp.division }}</span>
              </td> -->
              <td class="td-data td-edit" (click)="editWP(wp)">
                <span [title]="wp.wpname">{{ wp.wpname }}</span>
              </td>
              <td class="td-data td-edit" (click)="editWP(wp)">
                <span [title]="wp.startdate">{{ allinoneService.formatDBToShow(wp.startdate) }}</span>
              </td>
              <td class="td-data td-edit" (click)="editWP(wp)">
                <span [title]="wp.enddate">{{ allinoneService.formatDBToShow(wp.enddate) }}</span>
              </td>
              <td class="td-data td-edit" (click)="editWP(wp)" *ngIf="filterType != '002'">
                <span [title]="wp.type == '002' ? '-' : wp.rostername ">{{ wp.type == '002' ? '-' : wp.rostername }}</span>
              </td>
              <td class="td-data td-edit" (click)="editWP(wp)" *ngIf="filterType != '001'">
                <span [title]="wp.type == '001' ? '-' : wp.calendarname">{{ wp.type == '001' ? '-' : wp.calendarname }}</span>
              </td>

              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2" (click)="editWP(wp)">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-outline-danger" (click)="deletewp(wp.userwpid, index)">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="wpList.length > itemPerPage"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-template>
  </div>
</div>