import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss'],
})
export class ProjectStatusComponent implements OnInit {
  // public config: PerfectScrollbarConfigInterface = {};
  // @ViewChild('tbody') tbody!: ElementRef;

  statusFormSubmitted: boolean = false;
  editFormSubmitted: boolean = false;
  gettingStatusList: boolean = false;
  isEditing: boolean = false;
  disable: boolean = true;
  saveLoading: boolean = false;
  addNewStatus: boolean = false;
  changes: boolean = false;

  ccList: any[] = [];
  organizations: any[] = [];
  membershiptype: any = '';
  domainid: string = '';

  p: number = 1;

  statusForm = {
    id: '',
    orgid: '',
    name: '',
    desc: '',
  };

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ProjectStatusComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.statusForm.orgid = data.orgid;
  }

  ngOnInit(): void {
    this.getStatusList();
  }

  sortData(sort: Sort) {
    const data = this.ccList;
    if (!sort.active || sort.direction === '') {
      this.ccList = data;
      return;
    }

    this.ccList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  cancel() {
    this.isEditing = false;
    this.addNewStatus = false;
    this.statusFormSubmitted = false;
    this.statusForm = {
      name: '',
      desc: '',
      id: '',
      orgid: this.statusForm.orgid,
    };
  }

  editStatus(item: any) {
    this.isEditing = true;
    this.addNewStatus = true;
    this.statusForm = {
      name: item.name,
      desc: item.description,
      id: item.psid,
      orgid: this.statusForm.orgid,
    };
  }

  orgChanged(event: any) {
    this.statusForm.orgid = event.target.value;
    const index = this.organizations.findIndex(
      (x: any) => x.orgid == this.statusForm.orgid
    );
    this.membershiptype = this.organizations[index].membership;
    this.getStatusList();
  }

  submitStatus() {
    this.statusFormSubmitted = true;
    if (this.statusForm.name.trim() == '') {
      return;
    } else {
      this.saveStatus();
    }
  }

  saveStatus() {
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var data = {
      orgid: this.statusForm.orgid,
      name: this.statusForm.name,
      description: this.statusForm.desc,
    };

    if (this.isEditing) {
      data = Object.assign(data, { psid: this.statusForm.id });
    }

    const fun = this.isEditing
      ? this.kunyekService.updateProjectStatus(data)
      : this.kunyekService.addProjectStatus(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.changes = true;
          this.getStatusList();
          this.cancel();
          this.messageService.openSnackBar('Saved successfully.', 'success');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.statusFormSubmitted = false;
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
        this.statusFormSubmitted = false;
      }
    );
  }

  getStatusList() {
    this.gettingStatusList = true;
    const data = {
      orgid: this.statusForm.orgid,
      domainid: this.domainid,
    };
    this.kunyekService.getProjectStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.ccList = res.datalist;
          this.gettingStatusList = false;
        } else {
          this.gettingStatusList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingStatusList = false;
      }
    );
  }

  inputChange() {
    if (this.statusForm.name.trim() == '') {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  checkOverflow(element: any) {
    return element.offsetHeight < element.scrollHeight;
  }
}
