import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-requisition-request',
  templateUrl: './requisition-request.component.html',
  styleUrls: ['./requisition-request.component.scss']
})
export class RequisitionRequestComponent implements OnInit, OnDestroy {

  isAdmin: boolean = false;
  isMobileView: boolean = false;
  isAdding : boolean = false;
  isEditing : boolean = false;
  saveLoading: boolean = false;
  subscriptions = new SubSink();
  isFocus : boolean = false;

  searchText : string = '';
  orgid : string = '';
  formSubmitted : boolean = false;

  dataList : any = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];
  tableLoading : boolean = false;
  refNoDisabled: boolean = true;
  gettingMemberPosition : boolean = false;

  getRequestSub!: Subscription;
  getTemplateSubscription! : Subscription;

  templateList:any = [];
  getTemplateLoading : boolean = false;

  productListdata: any = [];
  projectList: any = [];
  getProductLoading: boolean = false;
  gettingProjectList: boolean = false;
  projectValue: string = '';
  productValue: string = '';
  text1: any = '';
  text2: any = ''
  hasPositionControl: boolean = false;
  departmentList: any = [];
  memberListLoading: boolean = false;
  members: any = [];
  divisionList: any = [];

  domainid = this.allinoneService.getDomain().domainid;

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  reasonList : any = [];
  gettingReasonList : boolean = false;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Submitted',
      value: '001',
    },
    {
      name: 'Approved',
      value: '002',
    },
    {
      name: 'Rejected',
      value: '003',
    },
    {
      name: 'Completed',
      value: '004',
    },
  ];

  submitForm = {
    requisitionid : '',
    refno: '',
    date : this.currentDate,
    remark : '',
    readonly : false,
    requeststatus: '001',
    requeststatusname: 'Submitted',
    priority : '0010',
    product : '',
    project : '',
    targetdate : this.currentDate,
    department: '',
    division: '',
    position : '',
    people : '1',
    requirements : '',
    descriptions : '',
    status : '',
    reason : '',
    hrremark : '',
    requestreason : '',
  }

  positionList = [
    {
      position : '',
      people : '',
      requirements : '',
      descriptions : '',
    }
  ]

  constructor(
    public allinoneService : AllInOneService,
    private kunyekService : KunyekService,
    private messageService : MessageService,
    private route : ActivatedRoute,
    private router : Router,
    private dialog : MatDialog,
  ) {
    // if (!this.allinoneService.isAdminOf('hr')) {
    //   this.router.navigateByUrl('/hxm');
    // }
    this.allinoneService.isOfficeView = true;
   }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgsCRm;
    console.log('Your org');
    console.log(this.organizations);
    
    this.orgid = this.allinoneService.selected_orgcrm
      ? this.allinoneService.selected_orgcrm.orgid
      : this.organizations[0].orgid;

    for(var i = 0; i < this.organizations.length; i++ ) {
      if(this.organizations[i].orgid == this.orgid) {
        console.log(this.organizations[i].department);
        
        this.submitForm.department = this.organizations[i].department ? this.organizations[i].department : '';
        this.submitForm.division = this.organizations[i].division ? this.organizations[i].division : '';
      }
    }
    console.log('ans');
    console.log(this.submitForm.department);
    console.log(this.submitForm.division);
    
    this.getTemplates();
    this.getRequest();
    // this.getProduct();
    this.getReasonList();
    this.getAllProjects();
    this.getMemberPosition();
  }

  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;
  }

  getReasonList() {
    this.gettingReasonList = true;
    var data = {
      "domainid": this.domainid,
      "orgid": this.orgid,
      "reasontype" : "001"
    }
    this.kunyekService.getRequisitionReason(data).subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          this.reasonList = res.datalist;
        }else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingReasonList = false;
      },
      (err : any) => {
        this.messageService.openSnackBar('Server Error',
          'warn'
        );
        this.gettingReasonList = false;
      }
    )
  }

  getProduct() {
    this.getProductLoading = true;
    const data = {
      "domainid": this.domainid,
      "orgid": this.orgid,
      "customerid": '',
      "customerview": false
    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.productListdata = res.list;
          var type = this.productListdata.map((x: any) => x.productid).indexOf(this.submitForm.product);
        } else {
          this.getProductLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getProductLoading = false;
      }
    );
  }

  getAllProjects() {
    this.gettingProjectList = true;
    this.projectList = [];
    const data = {
      orgid: this.orgid,
      domainid: this.domainid,
      customerid: "",
      customerview: false
      // role: this.callType
    };
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var project = [];
          console.log(this.projectList);
          var userid = this.allinoneService.getKunyekUserId();
          console.log(typeof (userid));
          this.projectList = res.data

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingProjectList = false;
      },
      (err) => {
        this.gettingProjectList = false;
      }
    );
  }

  productSelect(data: any) {
    console.log(data);
    
    this.submitForm.product = data.productid;
    console.log(this.submitForm.product);
    
    // if (data != undefined && !Array.isArray(data)) {


    //   this.serviceForm.product = data.name;
    //   // this.serviceForm.productid = data.productid;
    //   if (data.piclist.length > 0) {
    //     if (data.piclist[0].picuser != undefined) {
    //       console.log("assignerto value>>>>>>>>");
    //       let selectedRole: any = [];
    //       this.assignedtolist = [];
    //       selectedRole.push({
    //         picid: data.piclist[0].picuser['picid'],
    //         name: data.piclist[0].picuser['picname'],
    //         userid: data.piclist[0].picuser['userid']
    //       })
    //       console.log(selectedRole);
    //       if (this.assignedtoname == 'By Product') {
    //         this.getAssiProduct();

    //       }
    //       this.ngassignedtolist = selectedRole;
    //       for (let i = 0; i < this.ngassignedtolist.length; i++) {
    //         this.assignedtolist.push(this.ngassignedtolist[i]['picid']);
    //       }
    //       // this.serviceForm.pic = data.piclist[0].picuser.picname;
    //       // this.serviceForm.picid = data.piclist[0].picuser.picid;
    //       // this.serviceForm.assingtouserid = data.piclist[0].picuser.userid;
    //       // this.picValue = data.piclist[0].picuser.picname;
    //     }
    //   }
    //   if (data.modulelist.length > 0) {
    //     this.modulecheck = true;
    //     this.modulelist = data.modulelist;
    //   } else {
    //     this.modulecheck = false;
    //     this.moduleidList = []
    //   }
    // } else {

    //   this.serviceForm.productid = '';
    //   this.serviceForm.product = '';

    //   this.assignedtoname = "All";
    //   this.modulecheck = false;
    //   this.modulelist = [];
    // }

  }

  projectSelect(data: any) {
    this.submitForm.project = data.projectid;
    // this.serviceForm.project = data.name;
    // this.serviceForm.projectid = data.projectid;
    // this.projectValue = data.name
  }

  projectChange() {
    this.submitForm.project = '';
  }

  getRequest() {
    this.tableLoading = true;
    const data = {
      orgid : this.orgid,
      status : this.submitForm.requeststatus
    }
    this.getRequestSub && this.getRequestSub.unsubscribe();
    this.getRequestSub = this.kunyekService.getRequisitionRequest(data).subscribe(
      (res:any) => {
        console.log(res);
        if(res.returncode == 300) {
          this.dataList = [];
          res.datalist.map((x:any) => {
            var positions = '';
            x.positions.map((p:any, index :number) => {
              if(index == 0) {
                positions += p.name;
              } else {
                positions += ', ' + p.name;
              }
            });
            var temp = {
              ...x, positionlists : positions,

            };
            this.dataList.push(temp)
          })
          // this.dataList = res.datalist
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.tableLoading = false;
      },
      (err : any) => {
        this.tableLoading = false;
        this.messageService.openSnackBar('Server Error', 'warn');
      }
    )
  }

  positionChange(index : any) {    
    // console.log(index);
    // console.log(this.positionList[index]);
    // console.log(this.templateList);
    
    // this.templateList.map((x:any) => {
    //   if(x.reqtempid == this.positionList[index]['position']) {
    //     this.positionList[index]['requirements'] = x.requirements;
    //     this.positionList[index]['descriptions'] = x.descriptions;
    //   }
    // })

    this.templateList.map((x:any) => {
      if(x.reqtempid == this.submitForm.position) {
        this.submitForm.requirements = x.requirements;
        this.submitForm.descriptions = x.descriptions;
      }
    })
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.departmentList = res.departmentlist;
          this.divisionList = res.divisionlist;
          this.gettingMemberPosition = false;
        } else {
          this.gettingMemberPosition = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }

  statusChange(status: any) {
    if (this.submitForm.requeststatus != status.value) {
      this.submitForm.requeststatus = status.value;
      this.submitForm.requeststatusname = status.name;
      this.refresh();
    }
  }

  submit() {
    this.formSubmitted = true;
    // for(var i = 0; i < this.positionList.length; i++) {
    //   // if(this.positionList[i]['position'] == '' || this.positionList[i]['position'] == null
    //   //  || this.positionList[i]['people'] == '' || this.positionList[i]['people'] == null ||
    //   //  this.positionList[i]['requirements'] == '' || this.positionList[i]['requirements'] == null || 
    //   //  this.positionList[i]['descriptions'] == '' || this.positionList[i]['descriptions'] == null
    //   //  ) {
    //   //   this.messageService.openSnackBar('Position cannot be blank.', 'warn');
    //   //   return
    //   //  }
    //    if(this.positionList[i]['position'] == '' || this.positionList[i]['position'] == null) {
    //     this.messageService.openSnackBar('Position cannot be blank.', 'warn');
    //     return
    //    }
    //    if(this.positionList[i]['people'] == '' || this.positionList[i]['people'] == null) {
    //     this.messageService.openSnackBar('No. of People cannot be blank.', 'warn');
    //     return
    //    }
    //    if(this.positionList[i]['requirements'] == '' || this.positionList[i]['requirements'] == null) {
    //     this.messageService.openSnackBar('Job Requirements cannot be blank.', 'warn');
    //     return
    //    }
    //    if(this.positionList[i]['descriptions'] == '' || this.positionList[i]['descriptions'] == null) {
    //     this.messageService.openSnackBar('Job Descriptions cannot be blank.', 'warn');
    //     return
    //    }
    // }

    if(this.submitForm.requestreason == '' || this.submitForm.requestreason == null ) {
      this.messageService.openSnackBar('Reason cannot be blank.', 'warn');
      return
    }

    if(this.submitForm.remark == '' || this.submitForm.remark == null ) {
      this.messageService.openSnackBar('Remark cannot be blank.', 'warn');
      return
    }

    if(Number(this.submitForm.people) < 1) {
      this.messageService.openSnackBar('No. of People cannot less than 1.', 'warn');
      return
    }

    if(this.submitForm.people == '' || this.submitForm.people == null) {
      this.messageService.openSnackBar('No. of People cannot be blank.', 'warn');
      return
     }

     
    
    if(this.submitForm.position == '' || this.submitForm.position == null) {
      this.messageService.openSnackBar('Position cannot be blank.', 'warn');
      return
     }
     
     if(this.submitForm.requirements == '' || this.submitForm.requirements == null) {
      this.messageService.openSnackBar('Job Requirements cannot be blank.', 'warn');
      return
     }
     if(this.submitForm.descriptions == '' || this.submitForm.descriptions == null) {
      this.messageService.openSnackBar('Job Descriptions cannot be blank.', 'warn');
      return
     }
    this.saveLoading = true;

    var tempPositionList = [
      {
        position : this.submitForm.position,
        people : this.submitForm.people,
        requirements : this.submitForm.requirements,
        descriptions : this.submitForm.descriptions
      }
    ]
    var data = {
      requisitionid : this.submitForm.requisitionid,
      orgid : this.orgid,
      requisitiondate : this.allinoneService.formatDate(this.submitForm.date),
      positions :  tempPositionList,
      remark : this.submitForm.remark,
      priority : this.submitForm.priority,
      product : this.submitForm.product,
      project : this.submitForm.project,
      department : this.submitForm.department,
      division : this.submitForm.division,
      position : this.submitForm.position,
      people : this.submitForm.people.toString().trim(),
      targetdate : this.allinoneService.formatDate(this.submitForm.targetdate),
      requirements : this.submitForm.requirements,
      descriptions : this.submitForm.descriptions,
      requestreason : this.submitForm.requestreason
    }
    console.log(data);
    var fun = this.isEditing ?
    this.kunyekService.updateRequisitionRequest(data)
    : this.kunyekService.addRequisitionRequest(data);

    this.subscriptions.sink = fun.subscribe(
      (res:any) => {
        console.log(res);
        if(res.returncode == '300') {
          // if(this.isEditing) {
          //   // var index = this.dataList.findIndex(
          //   //   (x:any) => x.requisitionid == this.submitForm.requisitionid
          //   // );
          //   // if(index > 1) {
          //   //   this.dataList[index].name
          //   // }
          //   this.cancel();
          //   this.refresh();
          // } else {
          //   var positions = '';
          //   // for(var i = 0; i < this.templateList.length; i++) {
          //   //   for(var x = 0; i < this.positionList.length;i++) {
          //   //     if(this.templateList[i]['reqtempid'] == this.positionList[x]['position']) {
          //   //       positions += this.templateList[i]['name']
          //   //     }
          //   //   }
          //   // }
          //   this.templateList.map((x:any) => {
          //     this.positionList.map((p:any, index :number) => {
          //       if(x['reqtempid'] == p['position']) {
          //         if(positions.length == 0) {
          //           positions += x.name;
          //         } else {
          //           positions += ', ' + x.name;
          //         }
          //       }
          //     })
          //   })             
          //   console.log(positions);
            
          //   var tempList = { ...data, requisitionid : res.requisitionid, refno: res.refno, status : res.status, readonly : false, positionlists : positions};
          //   this.dataList.push(tempList);
          // }
          this.cancel();
          this.refresh();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err:any) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    )
    
  }

  editRequest(data : any){
    this.isEditing = true;
    this.isAdding = true;
    // this.submitForm = {
    //   requisitionid : data.requisitionid,
    //   refno : data.refno,
    //   date : this.allinoneService.formatDBToShowInEdit(data.requisitiondate),
    //   remark : data.remark,
    //   readonly : data.readonly,
    // }
    this.submitForm.requisitionid = data.requisitionid;
    this.submitForm.refno = data.refno;
    this.submitForm.date = this.allinoneService.formatDBToShowInEdit(data.requisitiondate);
    this.submitForm.remark = data.remark;
    this.submitForm.readonly = data.readonly;
    this.submitForm.priority = data.priority;
    this.submitForm.product = data.product;
    this.submitForm.project = data.project;
    // this.projectValue = data.project;
    // for(var i = 0; i < this.projectList.length; i++) {
    //   if(this.projectList[i]['projectid'] == data.project) {
    //     this.projectValue = this.projectList[i]['name']
    //   }
    // }
    this.submitForm.targetdate = this.allinoneService.formatDBToShowInEdit(data.targetdate);
    this.submitForm.department = data.department;
    this.submitForm.division = data.division;
    this.submitForm.position = data.position;
    this.submitForm.people = data.people;
    this.submitForm.requirements = data.requirements;
    this.submitForm.descriptions = data.descriptions;

    this.submitForm.reason = data.reason;
    this.submitForm.hrremark = data.hrremark;
    this.submitForm.status = data.status;

    this.submitForm.requestreason = data.requestreason;

    this.positionList = JSON.parse(JSON.stringify(data.positions));
    if(this.submitForm.readonly){
      var tempProduct = this.productListdata.find((x: any) => x.productid == this.submitForm.product)
      var tempProject = this.projectList.find((x: any) => x.projectid == this.submitForm.project)
      this.productValue = tempProduct ? tempProduct.name : '';
      this.projectValue = tempProject ? tempProject.name : '';
    }
  }

  deleteRequest(id: string, index:number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult : any) => {
      if(dialogResult) {
        this.tableLoading = true;
        const data = {
          orgid : this.orgid,
          requisitionid : id
        }
        this.kunyekService.deleteRequisitionRequest(data).subscribe(
          (res:any) => {
            if(res.returncode == '300') {
              var i = this.dataList.findIndex(
                (x:any) => x.requisitionid == id
              );
              this.dataList.splice(i, 1);
              if (
                Math.round(this.dataList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.tableLoading = false;
          },
          (err) => {
            this.messageService.openSnackBar('Server Error', 'warn');
            this.tableLoading = false;
          }
        )
      }
    })
  }

  getTemplates() {
    this.getTemplateLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getTemplateSubscription && this.getTemplateSubscription.unsubscribe();
    this.getTemplateSubscription = this.kunyekService
      .getRequisitionTemplate(data)
      .subscribe(
        (res: any) => {
          console.log(res);
          if (res.returncode == '300') {
            this.templateList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.getTemplateLoading = false;
        },
        (err) => {
          this.messageService.openSnackBar('Server Error',
            'warn'
          );
          this.getTemplateLoading = false;
        }
      );
  }

  new() {
    this.isAdding = true;
    this.isEditing = false;
    for(var i = 0; i < this.organizations.length; i++ ) {
      if(this.organizations[i].orgid == this.orgid) {
        console.log(this.organizations[i].department);
        
        this.submitForm.department = this.organizations[i].department ? this.organizations[i].department : '';
        this.submitForm.division = this.organizations[i].division ? this.organizations[i].division : '';
      }
    }
  }

  searchTextChange() {

  }

  removePositionList(index:number) {
    if(this.positionList.length > 1) {
      this.positionList.splice(index, 1);
    }
  }

  addPositionList() {
    this.positionList.push({
      position : '',
      people : '',
      requirements : '',
      descriptions : '',
    })
  }

  sortData(sort : Sort) {
    const data = this.dataList;
    if (!sort.active || sort.direction === '') {
      this.dataList = data;
      return;
    }
    this.dataList = data.sort((a : any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if(sort.active == 'refno') {
        return this.allinoneService.compare(a.refno, b.refno, isAsc);
      } else if(sort.active == 'positionlists') {
        return this.allinoneService.compare(a.positionlists, b.positionlists, isAsc);
      } else if(sort.active == 'people') {
        return this.allinoneService.compare(a.people, b.people, isAsc);
      } else if(sort.active == 'priority') {
        return this.allinoneService.compare(a.priority, b.priority, isAsc);
      } 
      else if(sort.active == 'requisitiondate') {
        return this.allinoneService.compare(a.requisitiondate, b.requisitiondate, isAsc);
      }
      else if(sort.active == 'targetdate') {
        return this.allinoneService.compare(a.targetdate, b.targetdate, isAsc);
      }
       else if(sort.active == 'status') {
        return this.allinoneService.compare(a.status, b.status, isAsc);
      } else if(sort.active == 'remark') {
        return this.allinoneService.compare(a.remark, b.remark, isAsc);
      }
      return 0;
    })
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event:any) {

  }

  refresh() {
    this.currentPage = 1;
    this.getRequest();
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.saveLoading = false;
    // this.submitForm = {
    //   requisitionid : '',
    //   refno : 'TBA',
    //   date : this.currentDate,
    //   remark : '',
    //   readonly : false,
    // }
    this.submitForm.requisitionid = '';
    this.submitForm.refno = 'TBA';
    this.submitForm.date = this.currentDate;
    this.submitForm.remark = '';
    this.submitForm.readonly = false;
    this.submitForm.priority = '0010',
    this.submitForm.product = '',
    this.submitForm.project = '',
    this.submitForm.targetdate = this.currentDate,
    // this.submitForm.department = '',
    // this.submitForm.division = '',
    this.submitForm.position = '',
    this.submitForm.people = '1',
    this.submitForm.requirements = '',
    this.submitForm.descriptions = '',
    this.submitForm.status = '',
    this.submitForm.hrremark = '',
    this.submitForm.reason = '',
    this.projectValue = '';
    this.productValue = '';
    this.submitForm.requestreason = '';
    this.positionList = [
      {
        position : '',
        people : '',
        requirements : '',
        descriptions : '',
      }
    ]
    // for(var i = 0; i < this.organizations.length; i++ ) {
    //   if(this.organizations[i].orgid == this.orgid) {
    //     console.log(this.organizations[i].department);
        
    //     this.submitForm.department = this.organizations[i].department ? this.organizations[i].department : '';
    //     this.submitForm.division = this.organizations[i].division ? this.organizations[i].division : '';
    //   }
    // }

  }

}
