import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-userid-mapping',
  templateUrl: './userid-mapping.component.html',
  styleUrls: ['./userid-mapping.component.scss']
})
export class UseridMappingComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isAdmin: boolean = false;
  isMobileView: boolean = false;
  isAdding : boolean = false;
  isEditing : boolean = false;
  saveLoading: boolean = false;
  subscriptions = new SubSink();
  isFocus : boolean = false;

  listLoading : boolean = false;
  searchText : string = '';
  orgid : string = '';
  formSubmitted : boolean = false;

  dataList : any = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];
  tableLoading : boolean = false;

  getListSubscription! : Subscription;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  submitForm = {
    id : '',
    userid : '',
    parentuserid : '',
  }

  constructor(
    public allinoneService : AllInOneService,
    private kunyekService : KunyekService,
    private messageService : MessageService,
    private route : ActivatedRoute,
    private router : Router,
    private dialog : MatDialog,
    private filterPipe : FilterPipe
  ) { 
    // this.onResize(event);
    // if (!this.allinoneService.isAdminOf("root")) {
    //   this.router.navigateByUrl('/home');
    // }
   }

  ngOnInit(): void {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
      this.getUseridMapping();
  }

  ngOnDestroy(): void {
    this.getListSubscription && this.getListSubscription.unsubscribe();
  }

  getUseridMapping() {
    this.tableLoading = true;
    const data = {
      orgid : this.orgid,
    };
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getListSubscription = this.kunyekService.getUserIDMapping(data).subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          this.dataList = res.datalist
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.tableLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error',
          'warn'
        );
        this.tableLoading = false;
      }
    )
  }

  submit() {
    this.formSubmitted = true;
    if(this.submitForm.userid == '' || this.submitForm.userid == null) {
      return
    }
    this.saveLoading = true;
    var data = {
      id : this.submitForm.id,
      orgid : this.orgid,
      adduserid : this.submitForm.userid,
      parentid : this.submitForm.parentuserid
    }

    console.log(data);
    

    var fun = this.isEditing ?
    this.kunyekService.updateUserIDMapping(data)
    : this.kunyekService.addUserIDMapping(data)

    this.subscriptions.sink = fun.subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          if(this.isEditing) {
            var index = this.dataList.findIndex(
              (x:any) => x.id == this.submitForm.id
            );
            if(index > -1) {
              this.dataList[index].userid = data.adduserid;
              this.dataList[index].parentid = data.parentid;
            } else {
              this.refresh();
            }
          }else {
            // var tempList = { ...data, id : res.id };
            var tempList = {
              userid : data.adduserid,
              parentid : data.parentid,
              id : res.id
            }
            this.dataList.push(tempList);
          }
          this.cancel();
        }
        else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    )
  }

  editData(data : any) {
    this.isEditing = true;
    this.isAdding = true;
    this.submitForm = {
      id : data.id,
      userid : data.userid,
      parentuserid : data.parentid
    }
  }

  deleteData(id: string, userid: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult:any) => {
      if(dialogResult) {
        this.tableLoading = true;
        const data = {
          orgid : this.orgid,
          id : id,
          adduserid : userid,
        }
        this.kunyekService.deleteUserIDMapping(data).subscribe(
          (res:any) => {
            if(res.returncode == '300') {
              var i = this.dataList.findIndex(
                (x:any) => x.id == id
              );
              this.dataList.splice(i, 1);
              if (
                Math.round(this.dataList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            }else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.tableLoading = false;
          },
          (err) => {
            this.tableLoading = false;
            this.messageService.openSnackBar('Server Error',
              'warn'
            );
          }
        )
      }
    })
  }

  new() {
    this.isAdding = true;
    this.isEditing = false;
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  sortData(sort : Sort) {
    const data = this.dataList;
    if (!sort.active || sort.direction === '') {
      this.dataList = data;
      return;
    }
    this.dataList = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      if(sort.active == 'userid') {
        return this.allinoneService.compare(a.userid, b.userid, isAsc);
      } else if(sort.active == 'parentid') {
        return this.allinoneService.compare(a.parentid, b.parentid, isAsc);
      }
      return 0;
    })
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event:any) {

  }

  refresh() {
    this.currentPage = 1;
    this.getUseridMapping();
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.saveLoading = false;
    this.submitForm = {
      id : '',
      userid : '',
      parentuserid : '',
    }

  }

}
