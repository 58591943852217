// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appname: 'Connect Portal',
  qrappname: 'QR 360',
  neoteamsappname : 'Neo Teams',
  mobileappname: 'Connect 365',
  // domain: "https://api1.iam.omnicloudapi.com/",
  // kunyekDomain: "https://api1.ky.omnicloudapi.com/",
  domain: 'https://api.iam.connectportal.cloud/',
  kunyekDomain: 'https://api.ky.connectportal.cloud/',
  crmsamdomain: 'https://api.ky.connectportal.cloud/',
  // memberDomain: 'https://jqd01f6aw5.execute-api.ap-southeast-1.amazonaws.com/member-stage/',
  memberDomain: 'http://localhost:7070/api/',
  bucketUrl: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/',
  bucketname: 'kunyekbucket',
  region: 'ap-southeast-1',
  socketurl:
    'wss://j7ksx5s71e.execute-api.ap-southeast-1.amazonaws.com/production',
  attendancesocketurl : 'wss://so119vhso3.execute-api.ap-southeast-1.amazonaws.com/production',
  accessKeyId: 'AKIA6JY5OJGA2I6MI7DU',
  secretAccessKey: '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'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
