<div class="fix">
    <div class="title-container mb-3">
        <div class="title">
            Pickup Points
        </div>
        <div class="close-btn">
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>

    <div class="d-flex flex-wrap mt-3" *ngIf="!addpickup">
        <div class="showbtn me-auto  mb-3">
            <button class="btn btn-custom" (click)="new()">
                <i class="fa fa-plus me-1"></i> New
            </button>
        </div>
    </div>
    <div class="loading-container" *ngIf="gettingpickup">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <div *ngIf="!addpickup  && !gettingpickup">
        <div class="" *ngIf="!gettingpickup ">
            <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()"
                [scrollWindow]="false" [perfectScrollbar]="config" class="task-container">
                <div *ngFor="let pickup of pickupList; let i = index">
                    <div class="card card-body mb-2 {{pickup.selected ? 'selected' : ''}}" (click)="selectPick(pickup,i)"
                        style="margin: 0px;">
                        <div *ngIf="pickup.selected" class="icon-container">
                            <mat-icon class="check-icon">check</mat-icon>
                        </div>
                        <div>
                            Name : {{pickup.name}}
                        </div>
                        <div>
                            Passenger : {{pickup.usercounts}}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>



    <div *ngIf="addpickup">

        <div>
            <div class="close-save-button mb-3 mt-3">
                <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader (click)="submitPickForm()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="saveLoading">
                </app-button-loader>
            </div>

        </div>
        <div class="row">

            <div class="mb-3 col-md-12">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name" name="name" [(ngModel)]="pickupname" #name="ngModel"
                    [ngClass]="{'is-invalid': pickupFormSave && name.invalid}" required>

                <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && pickupFormSave">
                    <div *ngIf="name.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div>
        </div>

    </div>
  
</div>
<div class="close-save-button justify-content-end mt-3" *ngIf="!gettingpickup && !addpickup">
    <button type="button" class="btn btn-custom me-2" (click)="close()" *ngIf="!changecheck">
        Cancel
    </button>
    <app-button-loader (click)="done()" [button_text]="'Save'" [load_button_text]="'Saving'"
    [isLoading]="changecheck"></app-button-loader>
</div>