import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'voterFilter'
})
export class VoterFilterPipe implements PipeTransform {

  transform(items : any[], searchText : string){
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    var returnItems: any[];

    returnItems = items.filter(it => {
        return (it.name.toString().toLocaleLowerCase().includes(searchText) 
        || it.userid.toString().toLocaleLowerCase().includes(searchText)
        ) && it.block != 'true';
    });
    return returnItems;

  }
}
