import { formatDate } from '@angular/common';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { LateEarlyoutRequestComponent } from '../late-earlyout-request/late-earlyout-request.component';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-late-earlyout-data-form',
  templateUrl: './late-earlyout-data-form.component.html',
  styleUrls: ['./late-earlyout-data-form.component.scss'],
})
export class LateEarlyoutDataFormComponent implements OnInit, OnDestroy {
  @ViewChild('lateEarlyOut') lateEarlyOut!: LateEarlyoutRequestComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isMobileView: boolean = false;
  dataList: any = [];
  gettingData: boolean = false;
  isLateRequest: boolean = true;
  isAdding: boolean = false;

  isFocus: boolean = false;
  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;
  saveLoading: boolean = false;

  searchText: string = '';
  orgid: string = '';

  searchLoading: boolean = false;
  organizations: any[] = [];
  getListSub!: Subscription;
  approverList: any[] = [];
  subscriptions = new SubSink();

  tablefilter = this.isLateRequest ? 'latedetails' : 'earlyoutdetails';

  // Date
  piedateFormat = 'dd/MM/yyyy';
  formatToShow = 'yyyy-MM-dd';
  d = new Date();
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);

  minDate = '';

  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();
  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  timeFormat = 'hh:mm';
  currentTime = formatDate(Date.now(), this.timeFormat, 'en-US').toString();
  currentEndTime = formatDate(
    new Date().setHours(new Date().getHours() + 1),
    this.timeFormat,
    'en-US'
  ).toString();

  currentData: any;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  submitForm = {
    startdate: this.currentStartDate,
    enddate: this.currentDate,
    starttime: this.currentTime,
    endtime: this.currentEndTime,
    requesttype: '',
    orgid: '',
    remark: '',
    duration: '1',
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public route: ActivatedRoute,
    private router: Router
  ) {
    this.route.data.subscribe((data: any) => {
      this.isLateRequest = data.isLateRequest;
      this.submitForm.requesttype = data.requesttypeid;
    });
    this.tablefilter = this.isLateRequest ? 'latedetails' : 'earlyoutdetails';
    this.allinoneService.isOfficeView = true;
  }
  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgsCRm;
    this.submitForm.orgid = this.allinoneService.selected_orgcrm
      ? this.allinoneService.selected_orgcrm.orgid
      : this.organizations[0].orgid;
    this.getDataList();
  }

  goToFPage() {
    if (this.isLateRequest) {
      this.router.navigateByUrl('office/late');
    } else {
      this.router.navigateByUrl('office/earlyout');
    }
  }

  search() {
    this.getDataList();
  }

  refresh() {
    this.getDataList();
    this.currentPage = 1;
  }

  getDataList() {
    this.gettingData = true;
    var data = {
      startdate: this.allinoneService.formatDate(this.submitForm.startdate),
      enddate: this.allinoneService.formatDate(this.submitForm.enddate),
      orgid: this.submitForm.orgid,
    };
    this.getListSub && this.getListSub.unsubscribe();
    var fun = this.isLateRequest
      ? this.kunyekService.getLateDetails(data)
      : this.kunyekService.getEarlyoutDetails(data);
    this.getListSub = fun.subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          this.dataList = res.datalist;
          // this.dataList.map((item: any) => {
          //   item.formattedtime = this.formatTime(this.isLateRequest ? item.late_time : item.earlyout_time)
          //   return item;
          // })
        } else {
          this.messageService.openSnackBar(
            res.error || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingData = false;
      },
      (err: any) => {
        this.gettingData = false;
      }
    );
  }

  formatTime(minutes: any) {
    minutes = Number(minutes);
    if (minutes) {
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      // add leading zero's if required
      minutes = minutes < 10 ? '0' + minutes : minutes;
      // if minutes is 0, display only hours
      if (minutes == '00') {
        return hours + ' hr';
      }
      return hours + ' hr ' + minutes + ' mins';
    } else {
      return '';
    }
  }

  editbackdate(item: any) {
    console.log(item);

    this.isAdding = true;
    this.currentData = item;
  }

  cancel() {
    this.isAdding = false;
    this.approverList = [];
  }

  save() {
    this.lateEarlyOut.submitRequest();
    console.log('ok');
  }

  submitLateEarlyOut(event: any) {
    const { requestData, approverList } = event;
    this.submitForm.starttime = requestData.starttime;
    this.submitForm.endtime = requestData.endtime;
    this.submitForm.remark = requestData.remark;
    this.submitForm.duration = requestData.duration;
    this.submitForm.startdate = requestData.date;
    this.approverList = approverList;
    this.addRequest();
  }

  addRequest() {
    this.saveLoading = true;
    var approvers: any = [];
    this.approverList.map((x: any) => {
      if (x.checked) {
        approvers.push(x.userid);
        // userfromto.push({
        //   userid: x.userid,
        //   fromplace: x.from ? x.from : '',
        //   toplace: x.to ? x.to : '',
        // });
      }
    });
    var data = {
      memberid: '',
      requesttype: this.submitForm.requesttype,
      requestsubtype: '',
      startdate: this.allinoneService.formatDate(this.submitForm.startdate),
      starttime: this.submitForm.starttime,
      enddate: '',
      endtime: this.submitForm.endtime,
      duration: this.submitForm.duration,
      remark: this.submitForm.remark,
      tmpimg: '',
      imagelist: [],
      deleteimagelist: [],
      requeststatus: '001',
      orgid: this.submitForm.orgid,
      approver: approvers,
    };
    var fun = this.kunyekService.addOfficeRequest(data);
    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          this.isAdding = false;
          this.getDataList();
        } else {
          this.messageService.openSnackBar(
            res.error || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err: any) => {
        this.saveLoading = false;
      }
    );
  }

  goToTableView() {
    this.isAdding = false;
  }

  sortData(sort: Sort) {
    const data = this.dataList;
    if (!sort.active || sort.direction === '') {
      this.dataList = data;
      return;
    }
    this.dataList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'date') {
        return this.allinoneService.compare(a.date, b.date, isAsc);
      } else if (sort.active == 'shift') {
        return this.allinoneService.compare(
          this.allinoneService.format12HourTimeTo24Hour(a.shift),
          this.allinoneService.format12HourTimeTo24Hour(b.shift),
          isAsc
        );
      } else if (sort.active == 'actual_timein') {
        return this.allinoneService.compare(
          this.allinoneService.f12to24WithSeconds(a.actual_timein),
          this.allinoneService.f12to24WithSeconds(b.actual_timein),
          isAsc
        );
      } else if (sort.active == 'late_time') {
        return this.allinoneService.compare(a.late_time, b.late_time, isAsc);
      } else if (sort.active == 'actual_timeout') {
        return this.allinoneService.compare(
          this.allinoneService.f12to24WithSeconds(a.actual_timeout),
          this.allinoneService.f12to24WithSeconds(b.actual_timeout),
          isAsc
        );
      } else if (sort.active == 'earlyout_time') {
        return this.allinoneService.compare(
          a.earlyout_time,
          b.earlyout_time,
          isAsc
        );
      }
      return 0;
    });
  }

  setLastDat() {
    // this.dateChange();
    var currentEndDate = formatDate(
      this.submitForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    var addDays = 35;
    var date = new Date(this.submitForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    if (
      this.submitForm.enddate > this.minDate ||
      this.submitForm.enddate < this.submitForm.startdate
    ) {
      this.submitForm.enddate = this.submitForm.startdate;
    }
    this.getDataList();
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  clear() {
    this.searchText = '';
  }

  searchTextChange() {
    this.currentPage = 1;
  }
}
