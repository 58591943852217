import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

// import { KunyekService } from '../../shared/services/kunyek.service';
// import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-request-pickup',
  templateUrl: './request-pickup.component.html',
  styleUrls: ['./request-pickup.component.scss']
})
export class RequestPickupComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};

  autoid: any = "";
  addpickup: boolean = false;
  pickupname: any = "";
  pickupFormSave: boolean = false;
  saveLoading: boolean = false;

  pickupList: any[] = [

  ]
  requestlist: any = []
  // typelist = ["New", "WIP", "TEST", "HI", "B"]

  currentCount = 20

  gettingpickup: boolean = false
  gettingMoreTaskList: boolean = false;
  orgid: any = "";
  gettingData: boolean = false;
  useridlist: any = [];
  passengerList: any = [];
  oldpickuppointid: any = "";
  type: any = "";
  newpickuppointid: any = "";

  constructor(
    private kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RequestPickupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.requestlist = data.list;
    this.orgid = data.orgid;
    this.oldpickuppointid = data.oldpickuppointid;
    this.type = data.type;
    console.log("request");
    console.log(this.requestlist);

  }

  async ngOnInit() {
    // this.getTaskList()
    await this.getPickupPoint();
    for (let i = 0; i < this.pickupList.length; i++) {
      if (this.oldpickuppointid == this.pickupList[i]['pickuppointid']) {
        this.pickupList[i].selected = true;

      }
    }
  }

  onScrollDown() {

  }


  getPickupPoint() {
    this.gettingpickup = true;
    this.pickupList = [];
    return new Promise((resolve) => {


      var data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.orgid,
      };
      this.kunyekService.getPickup(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.datalist.map((item: any) => {
              this.pickupList.push({
                "name": item.name,
                "user": "",
                "requestdata": item.requestdata,
                "usercounts": item.usercounts,
                "pickuppointid": item.pickuppointid,
                "useridlist": item.useridlist,
                "selected": false,
              })
            });
            resolve("true")
            this.gettingpickup = false;
          } else {

            this.gettingpickup = false;
            this.messageService.openSnackBar(res.message, 'warn');
            resolve("false")

          }
        },
        (err) => {
          this.messageService.openSnackBar(err, 'warn');
          this.gettingpickup = false;
          resolve("false")

        }
      );
    });

  }




  pickuppointid: any = "";
  selectPick(task: any, index: any) {
    console.log(task);

    this.pickuppointid = task.pickuppointid;
    // this.useridlist = [];

    // this.passengerList = [];

    // for (let i = 0; i < task.useridlist.length; i++) {
    //   this.useridlist.push(task.useridlist[i]);
    // }

    // this.useridlist.push(this.typelist['userid']);
    // for (let j = 0; j < task.requestdata.length; j++) {
    //   this.passengerList.push(task.requestdata[j])
    // }

    // this.passengerList.push({
    //   "enddate": this.typelist['enddate'],
    //   "requestid": this.typelist['requestid'],
    //   "startdate": this.typelist['startdate'],
    //   "userid": this.typelist['userid'],
    //   "weekday": this.typelist['weekday'],
    //   "check": false
    // })
    var pickList = [];

    for (let i = 0; i < this.pickupList.length; i++) {
      this.pickupList[i]['selected'] = false;
    }
    pickList.push(this.pickupList[index]);
    this.pickupList[index].selected = true;
    this.newpickuppointid = this.pickupList[index].pickuppointid;


  }

  close() {
    this.dialogRef.close({ data: '' });
  }
  done() {
    var finalList: any = [];
    // finalList = this.selectedUserList.filter((user: { checked: boolean }) => {
    //   return user.checked == true;
    // });
    this.changepickuppoint();

  }

  // this.update();


  new() {

    this.addpickup = true;
  }
  changecheck: boolean = false;
  changepickuppoint() {
    this.changecheck = true;
    var data = {
      "orgid": this.orgid,
      "domainid": this.allinoneService.getDomain().domainid,
      "oldpickuppointid": this.oldpickuppointid,
      "pickuppointid": this.pickuppointid,
      "requestdata": this.requestlist,
    }

    console.log(data);


    this.kunyekService.changePickupPoint(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.addpickup = false;
          var newpickupointid = this.pickuppointid;
          this.changecheck = false;
          var data = {
            "newpickupointid": newpickupointid
          }

          this.dialogRef.close({ data: data });

        } else {
          this.changecheck = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.changecheck = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );

  }

  submitPickForm() {
    this.saveLoading = true;
    this.pickupFormSave = true;
    if (this.pickupname == '') {
      return;
    }
    this.useridlist = [];
    console.log(this.requestlist['userid']);
    if (this.type == '001') {

      this.useridlist.push(this.requestlist['userid']);
      this.passengerList.push({
        "userid": this.requestlist['userid'],
        "username": this.requestlist['username'],
        "trantype": this.requestlist['trantype'],
        "requestid": this.requestlist['requestid'],
        "startdate": this.requestlist['startdate'],
        "enddate": this.requestlist['enddate'],
        "user":"",
        "weekday": this.requestlist['weekday'].toString(),
        "userlist":this.requestlist['userlist'],
        "check": false,
      })
      
    } else {
      if (this.requestlist['userlist'].length > 0) {
        for (let i = 0; i < this.requestlist['userlist'].length; i++) {
          this.useridlist.push(this.requestlist['userlist'][i]);
        }
      }
     
      this.passengerList.push({
        "userid": this.requestlist['userid'],
        "username": this.requestlist['username'],
        "trantype": this.requestlist['trantype'],
        "requestid": this.requestlist['requestid'],
        "startdate": this.requestlist['startdate'],
        "enddate": this.requestlist['enddate'],
        "user":this.requestlist['enddate'].toString(),
        "weekday": this.requestlist['weekday'].toString(),
        "userlist":this.requestlist['userlist'],
        "check": false,
      })
      this.useridlist.push(this.requestlist['userid']);

    }
    var data = {
      "orgid": this.orgid,
      "domainid": this.allinoneService.getDomain().domainid,
      "name": this.pickupname,
      "useridlist": this.useridlist,
      "requestdata": this.passengerList,
    }

    console.log(data);

    this.kunyekService.addPickup(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.addpickup = false;
          this.pickupFormSave = false;
          this.saveLoading = false;
          this.dialogRef.close({ data: '' });

        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
      }
    );

  }



  cancelFunction() {
    this.addpickup = false;

  }



}
