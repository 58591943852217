<div class="event-detail-wrapper">
    <div class="row" *ngIf="!fromEditForm">
        <div class="col-12 col-sm-12 col-md-12 col-lg-7 column p-2" >
            <div class = "label title mb-3 mt-3">
                {{event.name}}
            </div>
            <ul class="list-group">
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-sm-5">
                            Location
                        </div>
                        <div class="col-sm-7 mr-text">
                            {{event.locname}}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-sm-5">
                            Occurrence
                        </div>
                        <div class="col-sm-7 mr-text">
                            {{event.occurrence}}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-sm-5">
                            Start Date
                        </div>
                        <div class="col-sm-7 mr-text">
                            {{event.startdatetoshow}}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-sm-5">
                            End Date
                        </div>
                        <div class="col-sm-7 mr-text">
                            {{event.enddatetoshow}}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-sm-5">
                            Description
                        </div>
                        <div class="col-sm-7 mr-text">
                            {{event.description}}
                        </div>
                    </div>
                </li>
               
            </ul>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-5 column col-right">
            <div *ngIf = "qrLoading">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <ngx-qrcode *ngIf = "!qrLoading"  qrc-element-type="canvas" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="value"
                cssClass="bshadow"></ngx-qrcode>
            <button  *ngIf = "!qrLoading" type = "button" class = "btn btn-link" (click)="downloadQRCode()">Save QR</button>
        </div>
    </div>

    <div class="qronly" *ngIf="fromEditForm">
        <div *ngIf = "qrLoading" class="m-auto">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <ngx-qrcode *ngIf = "!qrLoading"  qrc-element-type="canvas" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="value"
            cssClass="bshadow"></ngx-qrcode>
        <button  *ngIf = "!qrLoading" type = "button" class = "btn btn-link" (click)="downloadQRCode()">Save QR</button>
    </div>

    <div class="close-btn">
        <button type="button" class="btn btn-custom " mat-dialog-close>Close</button>
    </div>
</div>