import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta,
  ) {}

  updateTitle(title: string) {
    this.title.setTitle(title);
      this.meta.updateTag({ property: 'og:title', content: title });
      this.meta.updateTag({
        property: 'twitter:title',
        content: title,
      });
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
    this.meta.updateTag({ property: 'twitter:url', content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ property: 'og:description', content: desc });
    this.meta.updateTag({ property: 'twitter:description', content: desc });
  }

  updateImg(imgUrl: string) {
    this.meta.updateTag({ property: 'og:image', content: imgUrl });
    this.meta.updateTag({ property: 'twitter:image', content: imgUrl });
    this.meta.updateTag({ property: 'og:image:width', content: '400' });
    this.meta.updateTag({ property: 'og:image:height', content: '400' });
  }
}
