import { ViewportScroller } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PanZoomConfig, PanZoomConfigOptions } from 'ngx-panzoom';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FileUploadComponent } from 'src/app/shared/components/file-upload/file-upload.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { HierarchyAddComponent } from '../hierarchy-add/hierarchy-add.component';
import { HierarchyDeleteComponent } from '../hierarchy-delete/hierarchy-delete.component';
import { HierarchyEditDetailsComponent } from '../hierarchy-edit-details/hierarchy-edit-details.component';
import { HierarchyEditComponent } from '../hierarchy-edit/hierarchy-edit.component';
import { HierarchyInfoComponent } from '../hierarchy-info/hierarchy-info.component';
import { PositionsComponent } from '../positions/positions.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.css'],
})
export class HierarchyComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.viewWidth) {
      this.isView = true;
    } else {
      this.isView = false;
    }
  }

  data: any[] = [];
  organizations: any[] = [];
  hierarchyTypes: any[] = [];
  parentList: any[] = [];
  positionsList: any[] = [];
  roParentList: any[] = [];

  // loading : boolean = false;

  displayMode: any = 'List';
  searchText: string = '';

  gettingOrgList: boolean = false;
  gettingHierarchyType: boolean = false;
  preserveSpace: boolean = false;
  gettingHierarchy: boolean = false;
  allExpand: boolean = false;
  isView: boolean = false;
  isFocus: boolean = false;
  refreshingImportStatus: boolean = false;
  syncLoading: boolean = false;
  members: any = [];

  viewWidth: number = 992;

  allsubs = new SubSink();

  hcForm = {
    orgid: '',
    orgname: '',
    type: '',
    order: '0',
  };

  domainid: string = this.allinoneService.getDomain().domainid;

  importStatus: string = '001'; //000- Server Error, 001-Success, 002-Progress, 003-Upload, 004-Error, 005-Cancel
  importData: any;
  statusInterval: any;

  private panZoomConfigOptions: PanZoomConfigOptions = {
    zoomLevels: 10,
    scalePerZoomLevel: 2.0,
    zoomStepDuration: 0.2,
    initialPanX: 50,
    initialPanY: 20,
    freeMouseWheelFactor: 0.01,
    zoomToFitZoomLevelFactor: 0.9,
    dragMouseButton: 'left',
    dynamicContentDimensions: true,
  };
  panzoomConfig: PanZoomConfig = new PanZoomConfig(this.panZoomConfigOptions);

  subscription!: Subscription;

  constructor(
    private scroll: ViewportScroller,
    private dialog: MatDialog,
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private filter: FilterPipe,
    private http: HttpClient,
  ) {
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
  }
  ngOnDestroy(): void {
    clearInterval(this.statusInterval);
  }

  async ngOnInit(): Promise<void> {
    this.gettingHierarchy = true;
    if (window.innerWidth < this.viewWidth) {
      this.isView = true;
    }
    this.organizations = this.allinoneService.orgs;
    this.hcForm.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    // this.organizations = this.allinoneService.orgs;
    // this.hcForm.orgid = this.allinoneService.selectedOrg
    //   ? this.allinoneService.selectedOrg
    //   : this.organizations[0].orgid;
    this.hcForm.orgname = this.organizations.filter(
      (x: any) => x.orgid == this.hcForm.orgid
    )[0].name;
    var index = this.organizations
      .map((x: any) => x.orgid)
      .indexOf(this.hcForm.orgid);
    this.hcForm.order = this.organizations[index].ordertype || '1';
    this.getImportStatus();
    this.statusInterval = setInterval(() => {
      if (
        this.importStatus != '001' &&
        this.importStatus != '005' &&
        this.importStatus != '000'
      ) {
        this.getImportStatus();
      }
    }, 3000);
    const await_type = await this.getHierarchyTypes();
    this.getHierarchy();
    this.getMemberList();
  }

  // orgChanged(event: any) {
  //   this.hcForm.orgid = event.target.value;
  //   this.hcForm.orgname =
  //     event.target.options[event.target.options.selectedIndex].text;
  //   var index = this.allinoneService.orgs
  //     .map((x: any) => x.orgid)
  //     .indexOf(this.hcForm.orgid);
  //   this.hcForm.order = this.allinoneService.orgs[index].ordertype || '1';
  //   this.allinoneService.selectedOrg = this.hcForm.orgid;
  //   this.allinoneService.setSelectedOrg(this.hcForm.orgid);
  //   this.getHierarchy();
  //   this.getMemberList();
  // }

  typeChange(event: any) {
    this.hcForm.type = event.target.value;
    if (this.hcForm.type == '50' || this.hcForm.type == 'all') {
      this.displayMode = 'List';
    }
    this.allExpand = false;
    this.getHierarchy();
  }

  downloadSheet() {
    var name = this.hcForm.orgname + '.xlsx';
    var exdata: any[] = [];
    var filtedData = this.filter.transform(
      this.parentList,
      this.searchText,
      'hierarchy'
    );
    if (this.hcForm.type == '1') {
      filtedData.map((item: any) => {
        var temp = {
          'Employee ID': item.employeeid,
          'Employee Name': item.username ? item.username : item.userid,
          Post: item.positionname,
          Department: item.department,
          Division: item.division,
          'Reporting Officer ID': item.peid,
          'Reporting Officer Name': item.parentusername
            ? item.parentusername
            : item.parentuserid,
          'Reporting Officer Post': item.parentpositionname,
          'Reporting Officer Department': item.parentdepartment,
          'Reporting Officer Division': item.parentdivision,
        };
        exdata.push(temp);
      });
    } else if (this.hcForm.type == '50') {
      filtedData.map((item: any) => {
        if (item.peid != '') {
          var temp = {
            'Employee ID': item.employeeid,
            'Employee Name': item.username ? item.username : item.userid,
            Post: item.positionname,
            Department: item.department,
            Division: item.division,
            'Supervisor ID': item.peid,
            'Supervisor Name': item.parentusername
              ? item.parentusername
              : item.parentuserid,
          };
          exdata.push(temp);
        }
      });
    } else {
      filtedData.map((item: any) => {
        var temp = {
          'Employee ID': item.employeeid,
          'Employee Name': item.username ? item.username : item.userid,
          Post: item.positionname,
          Department: item.department,
          Division: item.division,
          'Reporting Officer ID': item.peid,
          'Reporting Officer Name': item.parentusername
            ? item.parentusername
            : item.parentuserid,
          'Reporting Officer Post': item.parentpositionname,
          'Reporting Officer Department': item.parentdepartment,
          'Reporting Officer Division': item.parentdivision,
          Type:
            item.htype == '1'
              ? 'RO'
              : item.htype == '50'
              ? 'Supervision'
              : item.htype == '51'
              ? 'Guidance'
              : item.htype == '52'
              ? 'Mentoring'
              : 'Observation',
        };
        exdata.push(temp);
      });
    }
    this.allinoneService.exportEcecl(exdata, name);
  }

  getHierarchy() {
    this.gettingHierarchy = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.hcForm.orgid,
      domain: this.allinoneService.getDomain().shortcode,
      type: this.hcForm.type,
    };
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.kunyekService.getHierarchy(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.data = res.datalist;
          console.log(`Hierarchy Data: ${JSON.stringify(this.data)}`);
          this.parentList = res.parentlist;
          console.log('======>');
          console.log(this.parentList.length);
          console.log('======>');
          if (this.hcForm.type == '1') {
            this.roParentList = res.parentlist;
          }
          if (
            (this.hcForm.type == '1' || this.hcForm.type == 'all') &&
            this.data.length == 1
          ) {
            this.sortHierarchy(this.data[0]);
            this.sort_parent_list();
            this.data[0].expanded = true;
            this.parentList[0].expanded = true;
          }
          this.gettingHierarchy = false;
        } else {
          this.data = [];
          this.parentList = [];
          this.gettingHierarchy = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.data = [];
        this.parentList = [];
        this.gettingHierarchy = false;
      }
    );
  }
  getMemberList() {
    this.members = [];
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      domain: this.allinoneService.getDomain().shortcode,
      orgid: this.hcForm.orgid,
    };
    this.kunyekService.getMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (res.url != '') {
            this.readJsonFileList(res.url)
          }
          // this.members = res.datalist;
          this.members = this.members.filter((x: any) => x.employeeid != '');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
  }

  addHierarchy(node?: any, type?: any) {
    const dialog = this.dialog.open(HierarchyAddComponent, {
      maxHeight: '650px',
      // height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        orgid: this.hcForm.orgid,
        type: this.hierarchyTypes,
        parentList: this.parentList,
        node: node,
        memberlist: this.members,
        selectedType: this.hcForm.type,
        children: node?.children ? node.children : [],
        insertType: type ? type : 3,
        positions: this.positionsList,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult.isAdded) {
        this.parentList = dialogResult.parentList;
        this.roParentList = dialogResult.parentList;
        this.list_to_tree(this.parentList);
        if (this.hcForm.type == '1') {
          this.sort_parent_list();
        }
      }
      if (dialogResult.positions) {
        this.positionsList = dialogResult.positions;
      }
    });
  }

  refresh() {
    // this.scroll.scrollToAnchor('000012')
    this.getMemberList();
    this.getHierarchy();
  }

  viewInfo(node: any) {
    const dialog = this.dialog.open(HierarchyInfoComponent, {
      maxHeight: '500px',
      minWidth: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        user: node,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }

  openPositionForm() {
    const dialog = this.dialog.open(PositionsComponent, {
      width: '700px',
      maxHeight: '650px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        orgid: this.hcForm.orgid,
        fromOrg: false,
        org: this.allinoneService.getOrganization(),
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  getHierarchyTypes() {
    return new Promise((resolve) => {
      this.gettingHierarchyType = true;
      const data = {
        domainid: this.domainid,
        orgid: this.hcForm.orgid,
      };
      this.kunyekService.getHierarchyType(data).subscribe(
        (res: any) => {
          if (res.returncode == 300) {
            this.gettingHierarchyType = false;
            this.hierarchyTypes = res.datalist;
            this.hcForm.type = this.hierarchyTypes[0].id;
            resolve(true);
          } else {
            this.gettingHierarchyType = false;
            this.messageService.openSnackBar(res.message, 'warn');
            resolve(false);
          }
        },
        (err) => {
          this.gettingHierarchyType = false;
          resolve(false);
        }
      );
    });
  }

  deleteRO(node: any) {
    if (
      node.children == undefined ||
      node.children == null ||
      node.children.length == 0
    ) {
      this.deleteNode(node);
    } else {
      const dialog = this.dialog.open(HierarchyDeleteComponent, {
        maxWidth: '95vw',
        width: '500px',
        maxHeight: '95vh',
        panelClass: ['mat-typography'],
        closeOnNavigation: true,
        data: {
          orgid: this.hcForm.orgid,
          parentList: this.parentList,
          node: node,
        },
      });

      dialog.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.getHierarchy();
        }
      });
    }
  }

  deleteNode(node: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '350px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        cmessage:
          'Are you sure to delete this employee id (' +
          node.employeeid +
          ') and related children?',
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.gettingHierarchy = true;
        console.log(node);
        const data = {
          domainid: this.allinoneService.getDomain().domainid,
          orgid: this.hcForm.orgid,
          cid: node.positionid,
          ceid: node.employeeid,
          pid: node.pid,
          peid: node.peid,
          newpid: '',
          newpeid: '',
          type: this.hcForm.type,
        };
        this.kunyekService.deleteHierarchy(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              // var index = this.parentList.map((x)=>x.id).indexOf(node.id);
              // this.parentList.splice(index, 1)
              // this.messageService.openSnackBar("Deleted successfully." , 'success')
              // this.list_to_tree(this.parentList)
              this.getHierarchy();
            } else {
              this.gettingHierarchy = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.gettingHierarchy = false;
          }
        );
      }
    });
  }

  editHierarchy(node: any, changetype: boolean = false) {
    const dialog = this.dialog.open(HierarchyEditComponent, {
      maxHeight: '650px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        orgid: this.hcForm.orgid,
        type: this.hierarchyTypes,
        roParentList: this.roParentList,
        parentList: this.parentList,
        members: this.members,
        node: node,
        selectedType: node.htype,
        changetype: changetype,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.roParentList = dialogResult.roParentList;
      if (dialogResult.isEdited) {
        if (changetype || this.hcForm.type == 'all') {
          this.getHierarchy();
        } else {
          this.parentList = dialogResult.parentList;
          this.list_to_tree(this.parentList);
          this.sort_parent_list();
        }
      }
    });
  }

  editDetails(node: any, isalltype: boolean = false) {
    const oldpid = node.positionid;
    const eid = node.employeeid;
    const dialog = this.dialog.open(HierarchyEditDetailsComponent, {
      maxHeight: '650px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        orgid: this.hcForm.orgid,
        node: node,
      },
    });

    dialog.afterClosed().subscribe((dr: any) => {
      if (isalltype) {
        this.getHierarchy();
      } else {
        node.positionid = dr.positionid;
        node.positionname = dr.positionname;
        node.department = dr.department;
        node.division = dr.division;
        node.order = dr.order;
        this.parentList.map((x: any) => {
          if (x.id == node.id) {
            x.positionid = dr.positionid;
            x.positionname = dr.positionname;
            x.department = dr.department;
            x.division = dr.division;
            x.order = dr.order;
          }
          if (x.peid == eid && x.pid == oldpid) {
            x.pid = dr.positionid;
          }
        });

        // var temp =
        //   this.parentList[this.parentList.map((x) => x.id).indexOf(node.id)];
        // node.positionid = dr.positionid;
        // node.positionname = dr.positionname;
        // node.department = dr.department;
        // node.division = dr.division;
        // node.order = dr.order;

        // temp.positionid = dr.positionid;
        // temp.positionname = dr.positionname;
        // temp.department = dr.department;
        // temp.division = dr.division;
        // temp.order = dr.order;
        this.list_to_tree(this.parentList);
        this.sort_parent_list();
      }
    });
  }

  list_to_tree(list: any) {
    var map: any = {},
      node,
      roots = [],
      i;

    for (i = 0; i < list.length; i++) {
      map[list[i].employeeid + ',' + list[i].positionid] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i++) {
      node = list[i];
      if (node.peid !== '') {
        list[map[node.peid + ',' + node.pid]]?.children.push(node);
      } else {
        roots.push(node);
      }
    }
    console.log(roots);
    if (this.hcForm.type == '1') {
      this.sortHierarchy(roots[0]);
    }
    this.data = [];
    this.changeDetector.detectChanges();
    this.data = roots;
  }

  sortHierarchy(node: any) {
    if (node.children?.length > 0) {
      if (this.hcForm.order == '0') {
        node.children.sort(function (a: any, b: any) {
          return a.order - b.order;
        });
      } else if (this.hcForm.order == '1') {
        node.children.sort(function (a: any, b: any) {
          return b.order - a.order;
        });
      } else if (this.hcForm.order == '2') {
        node.children.sort(function (a: any, b: any) {
          return a.employeeid - b.employeeid;
        });
      } else {
        node.children.sort(function (a: any, b: any) {
          return b.primary - a.primary;
        });
      }
      node.children.map((item: any) => {
        this.sortHierarchy(item);
      });
    }
  }

  onNodeExpand(event: any) {
    var node = event.node;
    var index = this.parentList.map((x) => x.id).indexOf(node.id);
    if (index > -1) {
      this.parentList[index].expanded = true;
    }

    if (
      this.hcForm.type == 'all' &&
      node.children &&
      node.children[0].loading
    ) {
      const data = {
        positionid: node.positionid,
        employeeid: node.employeeid,
        orgid: this.hcForm.orgid,
      };
      this.allsubs.sink = this.kunyekService
        .getHierarchyByUser(data)
        .subscribe((res: any) => {
          if (res.returncode == '300') {
            event.node.children = res.datalist;
            this.sortHierarchy(event.node);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
            event.node.children = [];
          }
        });
    }
  }

  onNodeCollapse(event: any) {
    var node = event.node;
    var index = this.parentList.map((x) => x.id).indexOf(node.id);
    if (index > -1) {
      this.parentList[index].expanded = false;
    }
  }

  clickOnNode(node: any) {
    node.expanded = !node.expanded;
    this.parentList[
      this.parentList.map((x) => x.id).indexOf(node.id)
    ].expanded = node.expanded;
  }

  expandCollapse() {
    if (this.allExpand) {
      this.collapseAll();
    } else {
      this.expandAll();
    }
  }

  expandAll() {
    this.data.forEach((node) => {
      this.expandRecursive(node, true);
    });
    this.parentList.forEach((node) => {
      node.expanded = true;
    });

    var temp = this.data;
    this.data = [];
    this.changeDetector.detectChanges();
    this.data = temp;
    this.allExpand = true;
  }

  collapseAll() {
    this.data.forEach((node) => {
      this.expandRecursive(node, false);
    });
    this.parentList.forEach((node) => {
      node.expanded = false;
    });

    var temp = this.data;
    this.data = [];
    this.changeDetector.detectChanges();
    this.data = temp;
    this.allExpand = false;
  }

  private expandRecursive(node: any, isExpand: boolean) {
    if (
      (this.hcForm.type == 'all' &&
        node.htype != '50' &&
        node.htype != '51' &&
        node.htype != '52' &&
        node.htype != '53') ||
      this.hcForm.type != 'all'
    ) {
      node.expanded = isExpand;
      console.log(node);
      if (node.children) {
        node.children.forEach((childNode: any) => {
          this.expandRecursive(childNode, isExpand);
        });
      }
    }
  }

  // sort_parent_list(){
  //   console.log("sort funciton")
  //   var newList=this.parentList.filter((x:any)=>x.pid=="" && x.peid=="");
  //   var tempList=this.parentList.filter((x:any)=>x.pid=="" && x.peid=="");
  //   console.log(tempList)
  //   while (tempList.length>0){
  //     console.log('while loop')
  //     var res;
  //     for(var i=0;i<tempList.length;i++){
  //       console.log('for loop')
  //       res=this.parentList.filter((x:any)=>x.pid==newList[i].positionid && x.peid==newList[i].employeeid);
  //       res.sort((a:any,b:any)=>a.order-b.order);
  //       console.log('res')
  //       console.log(res)
  //       newList = newList.concat(res)
  //       console.log('new list')
  //       console.log(newList)
  //       tempList = res
  //     }
  //   }
  //   this.parentList=newList;
  // }

  sort_parent_list() {
    var newList = this.parentList.filter(
      (x: any) => x.pid == '' && x.peid == '' && x.htype == '1'
    );
    var tempList = this.parentList.filter(
      (x: any) =>
        x.pid == newList[0].positionid && x.peid == newList[0].employeeid
    );

    var supervisionList = this.parentList.filter(
      (x: any) =>
        x.pid == '' &&
        x.peid == newList[0].employeeid &&
        (x.htype == '50' ||
          x.htype == '51' ||
          x.htype == '52' ||
          x.htype == '53')
    );
    if (this.hcForm.order == '0') {
      tempList.sort((a: any, b: any) => a.order - b.order);
    } else {
      tempList.sort((a: any, b: any) => b.order - a.order);
    }
    var finalList = [...newList, ...supervisionList, ...tempList];
    console.log(finalList);
    while (tempList.length > 0) {
      var testList: any[] = [];
      for (var i = 0; i < tempList.length; i++) {
        var res = this.parentList.filter(
          (x: any) =>
            (x.pid == tempList[i].positionid &&
              x.peid == tempList[i].employeeid &&
              tempList[i].htype == '1') ||
            (x.pid == '' &&
              x.peid == tempList[i].employeeid &&
              (x.htype == '50' ||
                x.htype == '51' ||
                x.htype == '52' ||
                x.htype == '53') &&
              tempList[i].htype == '1')
        );
        if (this.hcForm.order == '0') {
          res.sort((a: any, b: any) => a.order - b.order);
        } else {
          res.sort((a: any, b: any) => b.order - a.order);
        }
        testList = testList.concat(res);
        finalList = finalList.concat(res);
      }
      tempList = testList;
    }
    this.parentList = finalList;
  }

  changeOrder(event: any) {
    this.hcForm.order = event.target.value;
    this.sortHierarchy(this.data[0]);
    this.sort_parent_list();
    var index = this.allinoneService.orgs
      .map((x: any) => x.orgid)
      .indexOf(this.hcForm.orgid);
    this.allinoneService.orgs[index].ordertype = this.hcForm.order;
    const data = {
      orgid: this.hcForm.orgid,
      ordertype: this.hcForm.order,
    };
    this.kunyekService.updateOrderType(data).subscribe((res: any) => {
      if (res.returncode == '300') {
      } else {
        this.messageService.openSnackBar(res.message, 'warn');
      }
    });
  }

  clear() {
    this.searchText = '';
  }

  importSheet(isError: boolean = false) {
    clearInterval(this.statusInterval);
    const dialog = this.dialog.open(FileUploadComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      // height: '400px',
      width: '600px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        isError: isError,
        orgid: this.hcForm.orgid,
        domainid: this.domainid,
        errorData: this.importData,
        type: '001',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getImportStatus();
      this.statusInterval = setInterval(() => {
        if (
          this.importStatus != '001' &&
          this.importStatus != '005' &&
          this.importStatus != '000'
        ) {
          this.getImportStatus();
        }
      }, 3000);
    });
  }

  getImportStatus() {
    this.refreshingImportStatus = true;
    var data = {
      type: '001',
      orgid: this.hcForm.orgid,
    };
    this.kunyekService.getHierarchyImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (
            (this.importStatus == '002' || this.importStatus == '003') &&
            res.status != this.importStatus
          ) {
            this.refresh();
          }
          this.importStatus = res.status;
          this.importData = res;
        } else {
          // this.messageService.openSnackBar(
          //   res.message || res.status || 'Server Error',
          //   'warn'
          // );
        }
        this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }
  syncHierarchy() {
    if (this.syncLoading) {
      return;
    }
    this.syncLoading = true;
    const data = {
      orgid: this.hcForm.orgid,
      domain: this.allinoneService.getDomain().shortcode,
    };
    this.kunyekService.syncHierarchy(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.messageService.openSnackBar(
            'It can take up to 15 minutes to update the upper hierarchy caching.',
            'info',
            5000
          );
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.syncLoading = false;
      },
      (err) => {
        this.syncLoading = false;
      }
    );
  }
  async readJsonFileList(url: string) {
    this.http.get(url).subscribe((res: any) => {
      this.members = res      
      
    })
    
  }
}
