import { MatDialog } from '@angular/material/dialog';
import { ChooseMemberComponent } from '../choose-member/choose-member.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/shared/services/message.service';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { DatePipe } from '@angular/common';
import { MemberApprovalDialogComponent } from '../member-approval-dialog/member-approval-dialog.component';
import { MemberApprovalHistoryComponent } from '../member-approval-history/member-approval-history.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-member-approval-form',
  templateUrl: './member-approval-form.component.html',
  styleUrls: ['./member-approval-form.component.scss']
})
export class MemberApprovalFormComponent implements OnInit {

  @Output('closeFormHandler') closeFormHandler = new EventEmitter();
  @Input() status: string = 'Add User'
  @Input() currentMember: any

  bmemberInfoToEdit: any

  saveLoading: boolean = false;
  relations: any[] = [];
  memberList: any[] = []
  memberForm!: FormGroup;
  requiredMessage = 'This field may not be blank';
  submitted = false;
  requestBodyData: any;
  memberTypeList: any[] = [];

  nrcRegionCode: any = [];
  nrcTownshipCode: any = [];
  nrcType: any = [];

  //images and file to upload
  selectedProfileImage?: File;
  tempProfileImage: any;
  profileUrl: any;
  selectedAttachments: any[] = [];
  tempfilename: any = "";
  deletedprofilename = "";
  filelist: Array<any> = [];
  deletedfilelist: Array<any> = [];
  isApproving: boolean = false;
  isNotApproved: boolean = true;
  isPaid: boolean = false;
  newUser: any;
  userCreated: boolean = false;

  reader = new FileReader();
  newUserSyskey: string = ''

  gettingNrcCode: boolean = false;


  subscription!: Subscription;
  isRejecting: boolean = false;

   previewurl: string = ''

  constructor(
    private kunyekService: KunyekService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private messageService: MessageService,
    public allInOneservice: AllInOneService,
    public datepipe: DatePipe,
    private http: HttpClient
  ) {
    this.memberForm = this.fb.group({
      name: ["", Validators.required],
      subname: [""],
      birthplace: [""],
      birthdate: [""],
      fathername: [""],
      mothername: [""],
      education: [""],
      currentjob: [""],
      department: [""],
      retiredjob: [""],
      address: [""],
      current_address: [""],
      religion: [""],
      nationality: [""],
      job: [""],
      membertypeid: "ASDLFKJ",
      phone: ["", Validators.required],
      nrcregioncode: ["", Validators.required],
      nrctownshipcode: ["", Validators.required],
      nrctype: ["", Validators.required],
      nrcnumber: ["", Validators.required],
    })
  }

  ngOnInit(): void {
    console.log(this.status)
    this.getMemberTypes();
    this.membertype.disable()
    this.memberForm.get('nrctype')?.disable();
    this.memberForm.get('nrctownshipcode')?.disable();
    this.memberForm.get('nrcregioncode')?.disable();
    this.membertype.setValue("ASDLFKJ")
    this.getNrcCode();
    this.checkCurrentStatus();
    this.getRelationList()
    this.checkApprover();
    console.log(this.relations, 'relations of current member')



    //  this.kunyekService.getMemberRelations({searchid: this.currentMember.userid}).subscribe({
    //   next: (res) => {
    //     if (res.returncode == "300") {

    //       if(res.datalist.length>0){
    //         console.log(res.datalist,'resdata.................')
    //         res.datalist.relations.forEach((relation: any)=>{
    //           let obj = {
    //             syskey: relation.relative.syskey,
    //             birthdate: relation.relative.birthdate,
    //             currentjob: relation.relative.currentjob,
    //             membertype: relation.relative.membertypeid
    //           }
    //           this.memberList.push(obj)
    //         })
    //       }
    //     }
    //   },
    //   error: (err) => {
    //     console.log(err)
    //   }
    // })
  }

  get name() {
    return this.memberForm.get("name")!
  }

  get phone() {
    return this.memberForm.get("phone")!
  }

  get birthdate() {
    return this.memberForm.get("birthdate")
  }

  get membertype() {
    return this.memberForm.get("membertypeid")!
  }

  get nrcregioncode() {
    return this.memberForm.get('nrcregioncode')!
  }

  get nrctownshipcode() {
    return this.memberForm.get('nrctownshipcode')!
  }

  get nrctype() {
    return this.memberForm.get('nrctype')!
  }

  get nrcnumber() {
    return this.memberForm.get('nrcnumber')!
  }

  getMemberTypes() {
    this.kunyekService.getKMemberTypesList({ searchName: '' }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.memberTypeList = res.datalist;
        }
      },
      error: (err) => {
        console.log(err)

      }
    })
  }


  getNrcCode() {
    this.gettingNrcCode = true;
    const data = {
    };
    this.kunyekService.getNrcCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.nrcCode = res.datalist;
          this.nrcRegionCode = res.datalist.regioncode;
          this.nrcTownshipCode = res.datalist.shortcode;
          this.nrcType = res.datalist.nrctype;
          this.gettingNrcCode = false;
        } else {
          this.gettingNrcCode = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingNrcCode = false;
      }
    );
  }

  // nrcChange() {
  //   if (
  //     this.memberForm.get("nrcCode") ||
  //     this.memberForm.get("nrcTownShipCode") ||
  //     this.memberForm.get("nrcType") ||
  //     this.memberForm.get("nrcNumber")
  //   ) {
  //     if (
  //       this.memberForm.get("nrcCode") == '' ||
  //       this.memberForm.get("nrcTownShipCode") == '' ||
  //       this.memberForm.get("nrcType") == '' ||
  //       this.memberForm.get("nrcNumber") == ''
  //     ) {
  //     } else {
  //       this.nrcRequired = false;
  //     }
  //   } else {
  //     this.nrcRequired = false;
  //   }
  // }


  // openModal() {
  //   const dialog = this.dialog.open(ChooseMemberComponent, {
  //     height: '606px',
  //     width: '500px',
  //     closeOnNavigation: true,
  //     panelClass: ['user-profile-modal', 'mat-typography'],
  //     data: {
  //       relativeList: this.relations,
  //       currentMemberId: this.currentMember.syskey
  //       // orgid: this.requestForm.orgid,
  //       // domainid: this.allinoneService.getDomain().domainid,
  //     },
  //   });

  //   dialog.afterClosed().subscribe((dialogResult: any) => {
  //     let tempRelations = this.relations
  //     if (dialogResult) {

  //       this.relations = dialogResult.data

  //     }
  //     // }
  //   });
  // }

  closeForm() {
    this.memberForm.reset();
    this.submitted = false;
    this.closeFormHandler.emit();
  }

  handleSaveMember() {
    // this.submitted = true
    this.saveLoading = true;
    this.requestBodyData = this.memberForm.value

    if (this.memberForm.valid) {
      let nrc: string;
      nrc = this.memberForm.get('nrcregioncode')?.value + '/' + this.memberForm.get('nrctownshipcode')?.value +
        '(' + this.memberForm.get('nrctype')?.value + ')' +
        this.memberForm.get('nrcnumber')?.value

      this.uploadFiles();

      this.requestBodyData = {
        ...this.requestBodyData,
        nrc: nrc,
        birthdate: this.allInOneservice.formatDate(this.birthdate?.value),
        tempfilename: this.tempfilename,
        profileimage: this.selectedProfileImage?.name,
        filelist: this.selectedAttachments
          .filter(file => !file.attachments_id)
          .map(file => file.filename),
        deletedfilelist: this.deletedfilelist
      }
      if (this.status == 'Edit') {
        this.requestBodyData = { ...this.requestBodyData, syskey: this.currentMember.syskey, parentid: this.currentMember.parentid ? this.currentMember.parentid : '' }
        this.requestBodyData['membertypeid'] = this.currentMember.memberType ? this.currentMember.memberType.membertypeid : ""
        console.log(this.requestBodyData, 'edit requ body')
        this.subscription = this.kunyekService.editKMember(this.requestBodyData).subscribe(
          (res: any) => {
            this.saveLoading = false;
            if (res.returncode == '300') {
              console.log(res)
              this.messageService.openSnackBar(res.message, 'success');
              this.userCreated = true;
              this.closeForm()
            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
            this.messageService.openSnackBar(err, 'warn');
          }
        )
      } else if (this.status == 'Add User') {
        this.newUser = this.memberForm.value
        this.requestBodyData.parentid = ''
        this.requestBodyData['membertypeid'] = "ASDLFKJ"
        this.subscription = this.kunyekService.addKMember(this.requestBodyData).subscribe(
          (res: any) => {
            this.saveLoading = false;
            this.currentMember = {}
            if (res.returncode == '300') {
              console.log(res)
              this.currentMember = { ...this.currentMember, syskey: res.syskey, name: this.name.value }
              console.log(this.currentMember, 'curmem')
              this.userCreated = true;
              this.messageService.openSnackBar(res.message, 'success');
            } else if (res.returncode == 301) {
              console.log(res)
              this.messageService.openSnackBar("User already exits with this phone number or email", "warn")
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.status = 'Edit'
            this.saveLoading = false;
          },
          (err) => {
            this.messageService.openSnackBar(err, 'warn');
            this.saveLoading = false;
          }
        )
      }
    } else {
      this.submitted = true;
      this.saveLoading = false;
      this.messageService.openSnackBar('Invalid Form', 'warn')
    }
  }

  checkCurrentStatus() {
    console.log(this.currentMember)
    if (this.status == 'Edit') {
      this.getRelationList();
      this.memberForm.setValue({
        name: this.currentMember.name,
        subname: this.currentMember.subname,
        birthplace: this.currentMember.birthplace,
        birthdate: this.fomratDate(this.currentMember.birthdate),
        nrcregioncode: this.currentMember.nrcregioncode,
        nrctownshipcode: this.currentMember.nrctownshipcode,
        nrctype: this.currentMember.nrctype,
        nrcnumber: this.currentMember.nrcnumber,
        fathername: this.currentMember.fathername,
        mothername: this.currentMember.mothername,
        education: this.currentMember.education,
        currentjob: this.currentMember.currentjob,
        department: this.currentMember.department,
        retiredjob: this.currentMember.retiredjob,
        address: this.currentMember.address,
        current_address: this.currentMember.current_address,
        religion: this.currentMember.religion,
        // userid: this.currentMember.userid? this.currentMember.userid : '',
        nationality: this.currentMember.nationality,
        job: this.currentMember.job,
        membertypeid: this.currentMember.memberType ? this.currentMember.memberType.membertypeid : "",
        phone: this.currentMember.phone,
      })

      let piamge = JSON.parse(this.currentMember.profileimage);
      this.profileUrl = piamge ? piamge.fileurl : ""
      this.selectedAttachments = this.currentMember.filelist;
    }
    console.log(this.memberForm.get('membertypeid'), 'membertypeid.......')
  }

  fomratDate(value: string): string {
    if (value === "") return "";
    const pattern = /(\d{4})(\d{2})(\d{2})/;
    return value.replace(pattern, '$1-$2-$3');
  }

  onFileSelected(event: Event) {
    if (this.profileUrl) {
      this.deletedprofilename = JSON.parse(this.currentMember.profileimage).filename;
    }

    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedProfileImage = input.files[0];
      this.reader.readAsDataURL(this.selectedProfileImage);
      this.reader.onload = (event) => {
        this.profileUrl = this.reader.result;
      }
    }
  }

  onAttachmentsSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const image = input.files[0];
      const timestamp = Date.now();
      this.reader.readAsDataURL(image);
      this.reader.onload = (event) => {
        this.selectedAttachments.push({
          fileurl: this.reader.result,
          filename: `${timestamp}_${image.name}`,
          file: image,
        });
      }
    }
  }

  async uploadFiles() {

    const defaultpath = 'kokaimember/members';

    if (this.tempfilename == '') {
      const date = new Date();
      const millisecond = date.getMilliseconds();
      this.tempfilename = this.datepipe.transform(
        date,
        'yyyyMMddhhmmss' + millisecond,
      );
    }

    //upload member profile iamge
    if (this.selectedProfileImage) {
      let profilepath = this.status != 'Edit' ?
        `${defaultpath}/${this.tempfilename}/${this.selectedProfileImage.name}`
        : `${defaultpath}/${this.currentMember.syskey}/${this.selectedProfileImage.name}`
      const upload_aws = await this.allInOneservice.uploadAnswerFile(
        this.selectedProfileImage,
        profilepath,
      );
      if (!upload_aws) {
        this.messageService.openSnackBar(
          'Unknown error occured while uploading your files.',
          'warn',
        );
        return;
      }
      this.tempProfileImage = profilepath;
    }

    //upload member attachements
    if (this.selectedAttachments.length > 0) {
      console.log(this.selectedAttachments)
      for (let i = 0; i < this.selectedAttachments.length; i++) {

        //upload only 
        if (!this.selectedAttachments[i].attachments_id) {
          let path = this.status != 'Edit' ?
            `kokaimember/attachments/${this.tempfilename}/${this.selectedAttachments[i].filename}`
            : `kokaimember/attachments/${this.currentMember.syskey}/${this.selectedAttachments[i].filename}`
          const upload_aws = await this.allInOneservice.uploadAnswerFile(
            this.selectedAttachments[i].file,
            path,
          );
          if (!upload_aws) {
            this.messageService.openSnackBar(
              'Unknown error occured while uploading your files.',
              'warn',
            );
            return;
          }
        }

      }
    }

  }

  removeAttachMent(index: number) {
    if (this.selectedAttachments[index].attachments_id) {
      this.deletedfilelist.push(this.selectedAttachments[index].filename);
    }
    this.selectedAttachments.splice(index, 1);
  }

  openApproveMember(status: string) {
    const dilog = this.dialog.open(MemberApprovalDialogComponent, {
      width: "540px",
      height: "320px",
      data: {
        usersyskey: this.currentMember.syskey,
        formstatus: status,
        approvestatus: status
      }
    });
    dilog.afterClosed().subscribe({
      next: (res) => {
        this.currentMember.formstatus = res.formstatus
        this.currentMember['approved'] = res.approved
        this.isNotApproved = false;
        this.closeFormHandler.emit();
      }
    })
  }

  approveMember(status: string) {
    this.isApproving = true;
    this.kunyekService.approveMember({
      usersyskey: this.currentMember.syskey,
      formstatus: status,
      fromdate: '',
      todate: '',
      remark: ''
    }).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.isApproving = false;
          this.messageService.openSnackBar(res.message, 'success');
          this.currentMember.formstatus = res.formstatus
          this.currentMember['approved'] = res.approved
          this.isNotApproved = false;
          this.closeFormHandler.emit();
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.isApproving = false;
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.isApproving = false;
      }
    )
  }

  addRelativeUser() {
    this.currentMember = this.currentMember
    this.status = 'Add Relative'
  }


  getRelationList() {
    if (this.currentMember) {
      this.kunyekService.getKMembers({ parentid: this.currentMember.syskey }).subscribe(
        (res: any) => {
          if (res.datalist.length > 0) {
            this.relations = res.datalist
            console.log(this.relations)
          } else {
            this.relations = []
          }
        }, (err: any) => {
          console.log(err)
          this.messageService.openSnackBar(err, 'warn')
        }
      )
    }
  }

  closeByRelativeForm() {
    this.status = 'Edit'
    this.getRelationList()
  }

  checkApprover() {
    const data = {
      membersyskey: this.currentMember.syskey
    };
    this.kunyekService.checkApprover(data).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.isNotApproved = res.approver;
        }
      }
    })
  }

  openHistory() {
    this.dialog.open(MemberApprovalHistoryComponent, {
      width: "530px",
      height: "500px",
      data: {
        member_id: this.currentMember.syskey
      }
    });
  }

  editBmember(index: number){
    this.status = 'Edit Relative'
    console.log(index)
    console.log(this.relations[index])
    this.bmemberInfoToEdit = this.relations[index]
  }

  viewImage(fileurl: string) {
    this.previewurl = fileurl;
  }

  downloadImage() {
    this.http.get(this.previewurl, { responseType: 'blob' }).subscribe((response: any) => {
      saveAs(response, this.selectedAttachments[0].filename);
    }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

}


