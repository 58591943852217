import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewQrComponent } from 'src/app/qr360/view-qr/view-qr.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { LateChoosePostComponent } from '../late-choose-post/late-choose-post.component';
import { Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-late-and-earlyout-policy',
  templateUrl: './late-and-earlyout-policy.component.html',
  styleUrls: ['./late-and-earlyout-policy.component.scss'],
})
export class LateAndEarlyoutPolicyComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  lateConfig = [
    {
      time: '',
      count: '',
    },
    {
      time: '',
      count: '',
    },
    {
      time: '',
      count: '',
    },
    {
      time: '',
      count: '',
    }
  ];

  earlyOutConfig = [
    {
      time: '',
      count: '',
    },
    {
      time: '',
      count: '',
    },
    {
      time: '',
      count: '',
    },
    {
      time: '',
      count: '',
    }
  ];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  isMobileView: boolean = false;
  isAdmin: boolean = false;
  isEditing: boolean = false;
  isAdding: boolean = false;
  isFocus: boolean = false;
  searchText: string = '';
  saveLoading: boolean = false;
  formSubmitted: boolean = false;
  orgid: string = '';
  organizations: any = [];
  getleoutSubscription!: Subscription;
  subscriptions = new SubSink();
  formatToShow = 'yyyy-MM-dd';

  // currentDate = this.allinoneService.formatDBToShowInEdit(
  //   this.allinoneService.getCurrentDateToDB()
  // );
  currentDate = new Date(Date.now());
  // endDate = formatDate(new Date(9999, 11, 31), this.formatToShow, 'en-US').toString();
  endDate = new Date(9999, 11, 31)

  leoutLoading: boolean = false;

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  leoutList: any = [];
  postList: any = [];
  posts: any = [];

  leoutForm : any = {
    configid: '',
    name: '',
    description: '',
    startdate: this.currentDate,
    // enddate: this.currentDate,
    enddate: this.endDate,
    latetime: false,
    earlyouttime: false,
    earlyouthalfdayabsent : false,
    latehalfdayabsent : false,
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getleout();
    this.getPostList();
  }

  newLateAndEarlyout() {
    console.log(this.lateConfig);
    console.log(this.earlyOutConfig);
    
    this.isAdding = true;
  }

  submitForm() {
    this.formSubmitted = true;
    
    if (this.leoutForm.name == '') {
      return;
    }
    if(!this.leoutForm.startdate) {
      this.messageService.openSnackBar(
         'Please enter Start Date',
        'warn'
      );
      return;
    }
    if(!this.leoutForm.enddate) {
      this.messageService.openSnackBar(
         'Please enter End Date',
        'warn'
      );
      return;
    }
    if(this.leoutForm.startdate == 'Invalid Date' || this.leoutForm.enddate == 'Invalid Date') {
      this.messageService.openSnackBar(
        'Invalid date',
       'warn'
     );
     return;
    }
    if(this.leoutForm.enddate < this.leoutForm.startdate) {
      this.messageService.openSnackBar(
        'Invalid date',
       'warn'
     );
     return;
    }
    for (var i = 0; i < this.lateConfig.length; i++) {
      this.lateConfig[i]['time'] =
        this.lateConfig[i]['time'] == null
          ? ''
          : this.lateConfig[i]['time'].toString();
      this.lateConfig[i]['count'] =
        this.lateConfig[i]['count'] == null
          ? ''
          : this.lateConfig[i]['count'].toString();
      if (
        (this.lateConfig[i]['time'] != '' &&
          this.lateConfig[i]['count'] == '') ||
        (this.lateConfig[i]['count'] != '' && this.lateConfig[i]['time'] == '')
      ) {
        this.messageService.openSnackBar('Invalid Late Time and Count', 'warn');
        return;
      }
    }
    for (var i = 0; i < this.earlyOutConfig.length; i++) {
      this.earlyOutConfig[i]['time'] =
        this.earlyOutConfig[i]['time'] == null
          ? ''
          : this.earlyOutConfig[i]['time'].toString();
      this.earlyOutConfig[i]['count'] =
        this.earlyOutConfig[i]['count'] == null
          ? ''
          : this.earlyOutConfig[i]['count'].toString();
      if (
        (this.earlyOutConfig[i]['time'] != '' &&
          this.earlyOutConfig[i]['count'] == '') ||
        (this.earlyOutConfig[i]['count'] != '' &&
          this.earlyOutConfig[i]['time'] == '')
      ) {
        this.messageService.openSnackBar(
          'Invalid Early Out Time and Count.',
          'warn'
        );
        return;
      }
    }
    this.addleout();
  }

  addleout() {
    this.saveLoading = true;
    var postdata: any = [];
    var latedata: any = this.lateConfig;
    var earlyoutdata: any = this.earlyOutConfig;

    // for (var i = 0; i < this.lateConfig.length; i++) {
    //   if (
    //     this.lateConfig[i]['time'] != '' &&
    //     this.lateConfig[i]['count'] != ''
    //   ) {
    //     latedata.push(this.lateConfig[i]);
    //   }
    // }

    // for (var i = 0; i < this.earlyOutConfig.length; i++) {
    //   if (
    //     this.earlyOutConfig[i]['time'] != '' &&
    //     this.earlyOutConfig[i]['count'] != ''
    //   ) {
    //     earlyoutdata.push(this.earlyOutConfig[i]);
    //   }
    // }

    for (var i = 0; i < this.postList.length; i++) {
      postdata.push(this.postList[i]['id']);
    }

    var data = {
      configid: this.leoutForm.configid,
      orgid: this.orgid,
      name: this.leoutForm.name.toString().trim(),
      description: this.leoutForm.description.toString().trim(),
      startdate: this.allinoneService.parseDate(this.leoutForm.startdate),
      enddate: this.allinoneService.parseDate(this.leoutForm.enddate),
      uselate: this.leoutForm.latetime,
      useearlyout: this.leoutForm.earlyouttime,
      earlyouthalfdayabsent : this.leoutForm.earlyouthalfdayabsent,
      latehalfdayabsent : this.leoutForm.latehalfdayabsent,
      latetime: latedata,
      earlyouttime: earlyoutdata,
      membertypes: postdata,
    };
    console.log(data);
    
    var fun = this.isEditing
      ? this.kunyekService.editLateAndEarlyout(data)
      : this.kunyekService.addLateAndEarlyout(data);

    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.saveLoading = false;
          this.cancel();
          this.refresh();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    );
  }

  getleout() {
    this.leoutLoading = true;
    const data = {
      orgid: this.orgid,
      configid: '',
    };
    this.getleoutSubscription && this.getleoutSubscription.unsubscribe();
    this.getleoutSubscription = this.kunyekService
      .getLateAndEarlyout(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.leoutList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.leoutLoading = false;
        },
        (err) => {
          this.leoutLoading = false;
        }
      );
  }

  editleout(data: any) {
    this.isEditing = true;
    this.isAdding = true;
    this.leoutForm = {
      configid: data.configid,
      name: data.name,
      description: data.description,
      startdate: this.allinoneService.parseDateToShowInEdit(data.startdate),
      enddate: this.allinoneService.parseDateToShowInEdit(data.enddate),
      latetime: data.uselate,
      earlyouttime: data.useearlyout,
      earlyouthalfdayabsent : data.earlyouthalfdayabsent,
      latehalfdayabsent : data.latehalfdayabsent,
    };
    console.log(data);

    // if (data.latetime.length == 0) {
    //   this.lateConfig = [
    //     {
    //       time: '',
    //       count: '',
    //     },
    //   ];
    // } else {
    //   this.lateConfig = JSON.parse(JSON.stringify(data.latetime));
    // }
    // if (data.earlyouttime.length == 0) {
    //   this.earlyOutConfig = [
    //     {
    //       time: '',
    //       count: '',
    //     },
    //   ];
    // } else {
    //   this.earlyOutConfig = JSON.parse(JSON.stringify(data.earlyouttime));
    // }
    // if (this.lateConfig.length < 4) {
    //   for (let i = this.lateConfig.length; i < 4; i++) {
    //     this.lateConfig.push({
    //       time: '',
    //       count: '',
    //     });
    //   }
    // }
    // if (this.earlyOutConfig.length < 4) {
    //   for (let i = this.earlyOutConfig.length; i < 4; i++) {
    //     this.earlyOutConfig.push({
    //       time: '',
    //       count: '',
    //     });
    //   }
    // }
    this.lateConfig = data.latetime
    this.earlyOutConfig = data.earlyouttime
    this.postList = [];
    for (var i = 0; i < this.posts.length; i++) {
      for (var x = 0; x < data.membertypes.length; x++) {
        if (this.posts[i]['id'] == data.membertypes[x]) {
          this.postList.push(this.posts[i]);
        }
      }
    }
  }

  deleteLeout(id: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.leoutLoading = true;
        const data = {
          orgid: this.orgid,
          configid: id,
        };

        this.kunyekService.deleteLateAndEarlyout(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              var i = this.leoutList.findIndex((x: any) => x.configid == id);
              this.leoutList.splice(i, 1);
              if (
                Math.round(this.leoutList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.leoutLoading = false;
          },
          (err) => {
            this.leoutLoading = false;
          }
        );
      }
    });
  }

  getPostList() {
    this.posts = [];
    const data = {
      orgid: this.orgid,
    };
    this.kunyekService.getClassCodeList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.posts = res.datalist;
          this.posts = this.posts.map((item: any) => ({
            ...item,
            isChecked: false,
          }));
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => { }
    );
  }

  deletePost(index: any) {
    for (var i = 0; i < this.posts.length; i++) {
      if (this.posts[i]['id'] == this.postList[index]['id']) {
        this.posts[i]['isChecked'] = false;
      }
    }
    this.postList.splice(index, 1);
  }

  openPopUp(item?: any) {
    const dialog = this.dialog.open(LateChoosePostComponent, {
      maxWidth: '95vw',
      width: '700px',
      height: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        posts: this.posts,
        selectedCount: this.postList.map((x: any) => {
          return x.id;
        }),
        orgid: this.orgid,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (dialogResult.length > 0) {
          this.postList = dialogResult;
          for (var i = 0; i < dialogResult.lenght; i++) {
            for (var x = 0; x < this.posts.length; x++) {
              if (dialogResult[i]['id'] == this.posts[x]['id']) {
                this.posts[x]['isChecked'] = dialogResult[i]['isChecked'];
              }
            }
          }
        } else {
          this.postList = [];
        }
      }
    });
  }

  sortPost(sort: Sort) {
    const data = this.postList;
    if (!sort.active || sort.direction === '') {
      this.postList = data;
      return;
    }
    this.postList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'name') {
        return this.allinoneService.compare(a.name, b.name, isAsc);
      } else if (sort.active == 'description') {
        return this.allinoneService.compare(
          a.description,
          b.description,
          isAsc
        );
      } else if (sort.active == 'no') {
        return this.allinoneService.compare(a.no, b.no, isAsc);
      }
      return 0;
    });
  }

  refresh() {
    this.currentPage = 1;
    this.getleout();
  }
  addSubmitConfig(type: string) {
    if (type == 'latetime') {
      this.lateConfig.push({
        time: '',
        count: '',
      });
    } else {
      this.earlyOutConfig.push({
        time: '',
        count: '',
      });
    }
  }

  removeSubmitConfig(index: number, type: string) {
    if (type == 'latetime') {
      if (this.lateConfig.length > 1) {
        this.lateConfig.splice(index, 1);
      }
    } else {
      if (this.earlyOutConfig.length > 1) {
        this.earlyOutConfig.splice(index, 1);
      }
    }
  }

  compareDate() {
    if (this.leoutForm.startdate > this.leoutForm.enddate) {
      this.leoutForm.enddate = this.leoutForm.startdate;
    }
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  sortData(sort: Sort) {
    const data = this.leoutList;
    if (!sort.active || sort.direction === '') {
      this.leoutList = data;
      return;
    }
    this.leoutList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'name') {
        return this.allinoneService.compare(a.name, b.name, isAsc);
      } else if (sort.active == 'description') {
        return this.allinoneService.compare(
          a.description,
          b.description,
          isAsc
        );
      } else if (sort.active == 'latetime') {
        return this.allinoneService.compare(a.latetime, b.latetime, isAsc);
      } else if (sort.active == 'earlyouttime') {
        return this.allinoneService.compare(
          a.earlyouttime,
          b.earlyouttime,
          isAsc
        );
      }
      return 0;
    });
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.saveLoading = false;
    this.leoutForm = {
      configid: '',
      name: '',
      description: '',
      startdate: this.currentDate,
      enddate: this.endDate,
      latetime: false,
      earlyouttime: false,
      earlyouthalfdayabsent : false,
      latehalfdayabsent : false,
    };
    this.lateConfig = [
      {
        time: '',
        count: '',
      },
      {
        time: '',
        count: '',
      },
      {
        time: '',
        count: '',
      },
      {
        time: '',
        count: '',
      }
    ];
    this.earlyOutConfig = [
      {
        time: '',
        count: '',
      },
      {
        time: '',
        count: '',
      },
      {
        time: '',
        count: '',
      },
      {
        time: '',
        count: '',
      }
    ];
    for (var i = 0; i < this.posts.length; i++) {
      this.posts[i]['isChecked'] = false;
    }
    this.postList = [];
  }
}
