import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-leave-opeing-history',
  templateUrl: './office-leave-opeing-history.component.html',
  styleUrls: ['./office-leave-opeing-history.component.scss'],
})
export class OfficeLeaveOpeingHistoryComponent implements OnInit {
  userid: string = '';
  username: string = '';
  orgid: string = '';
  templateid: string = '';
  openingid: string = '';

  gettingHistory: boolean = false;
  isAdmin: boolean = false;
  isFocus: boolean = false;
  calendarid: string = '';
  historyList: any = [];
  searchText: string = '';

  

  itemPerPage: number = 100;
  currentPage: number = 1;

  minDate = '';
  inoutList : any = [];
  d = new Date();
  formatToShow = 'yyyy-MM-dd';
  piedateFormat = 'dd/MM/yyyy';
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 30);
  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );
  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();

  subs = new SubSink();

  startdate: string = this.currentStartDate;
  enddate: string = this.currentDate;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private filterPipe: FilterPipe
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnInit(): void {
    try {
      var tempData = JSON.parse(
        this.allinoneService.decrypt(
          decodeURIComponent(this.route.snapshot.params['data'])
        )
      );
    } catch {
      this.goToLeaveOpening();
    }

    this.userid = tempData.userid;
    this.username = tempData.username;
    this.openingid = tempData.openingid;
    this.templateid = tempData.templateid;
    this.calendarid = tempData.calendarid;

    if (this.isAdmin) {
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.allinoneService.orgs.orgid;
    } else {
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.allinoneService.orgsCRm.orgid;
    }

    this.getHistory();
  }

  getHistory() {
    this.gettingHistory = true;
    const data = {
      openingid: this.openingid,
      orgid: this.orgid,
      memberid: this.userid,
      // stardate: this.allinoneService.formatDate(this.startdate),
      // enddate: this.allinoneService.formatDate(this.enddate),
    };

    this.subs.unsubscribe();
    this.subs.sink = this.kunyekService.getLeaveOpeningHistory(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.historyList = [];
          // sort res.requesttypelist by the field 'sort' in descending order
          res.requesttypelist.sort((a: any, b: any) => {
            return b.sort - a.sort;
          });

          res.requesttypelist.map((item: any) => {
            item.oldrequesttypelist.map((request: any, index: number) => {
              const temp = {
                syskey: index,
                modifiedby : item.username ? item.username : item.userid,
                modifieddate : this.allinoneService.formatSortIntoDateTime(item.sort),
                leavetype: request.requesttypename,
                oldbroughtforward: request.broughtforward == '' ? '-' : request.broughtforward,
                newbroughtforward: item.requesttypelist[index].broughtforward == '' ? '-' : item.requesttypelist[index].broughtforward,
                oldopening: request.opening == '' ? '-' : request.opening,
                newopening: item.requesttypelist[index].opening == '' ? '-' : item.requesttypelist[index].opening,
              }
              this.historyList.push(temp)
            })
            console.log(this.historyList);
          });
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingHistory = false;
      },
      (err) => {
        this.gettingHistory = false;
      }
    );
  }

  sortData(sort: Sort) {}

  goToLeaveOpening() {
    let url =
      (this.isAdmin
        ? this.allinoneService.isAttendanceView
          ? '/attendance/hxm'
          : '/requestapproval'
        : 'office/hxm') + '/leaveopening';

    console.log(url);
    this.router.navigateByUrl(url);
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = parseInt(event.target.value);
    this.currentPage = 1;
    this.currentPage = 1;
  }

  setLastDat() {
    // this.dateChange();
    var currentEndDate = formatDate(
      this.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    var addDays = 35;
    var date = new Date(this.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    if (
      this.enddate > this.minDate ||
      this.enddate < this.startdate
    ) {
      this.enddate = this.startdate;
    }
  }

  trackByKey(index: any, item: any) {
    return item.syskey;
  }

  downloadSheet(){
    var exdata: any = [];
    var name = 'Leave Opening History - ' + (this.username ? this.username + ' (' +this.userid+')' : this.userid)+'.xlsx';

    var filteredData = this.filterPipe.transform(
      this.historyList,
      this.searchText,
      'leaveopeninghistory'
    );

    filteredData.map((item: any) => {
      var temp = {
        'Leave Type': item.leavetype,
        'Brought Forward': item.oldbroughtforward,
        'New Brought Forward': item.newbroughtforward,
        'Opening': item.oldopening,
        'New Opening': item.newopening,
        'Modified By': item.modifiedby,
        'Modified Date' : item.modifieddate
      };
      exdata.push(temp);
    });

    this.allinoneService.exportEcecl(exdata, name);
  }
}
