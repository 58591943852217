import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-office-travel-detail',
  templateUrl: './office-travel-detail.component.html',
  styleUrls: ['./office-travel-detail.component.scss'],
})
export class OfficeTravelDetailComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService
  ) { }

  showVehicle = '';
  showTravelMode = '';
  showTypeName = "";
  requestTypeList: any = [];


  modeList = [
    {
      id: '001',
      value: 'By Air',
    },
    {
      id: '002',
      value: 'By Road',
    },
    {
      id: '003',
      value: 'By Rail',
    },
    {
      id: '004',
      value: 'By Ship',
    },
  ];

  vehicleuseList = [
    {
      id: '001',
      value: "Office's Vehicle",
    },
    {
      id: '002',
      value: 'Public Transportation',
    },
    {
      id: '003',
      value: 'By Own Vehicle(state reason)',
    },
  ];

  // data = {
  //   requestid: 'G6UQ9MS7FG0TJ3O',
  //   sort: '20240507145823352950',
  //   image: {
  //     imgurl: [
  //       'https://kunyekbucket.s3.amazonaws.com/office/request/G6UQ9MS7FG0TJ3O/202405071458015810.png?AWSAccessKeyId=AKIA6JY5OJGATYY73G53&Signature=RzPHjM2mFtBgV4FhR8Ae7IRAQp8%3D&Expires=1717698569',
  //       'https://kunyekbucket.s3.amazonaws.com/office/request/G6UQ9MS7FG0TJ3O/202405071458015811.png?AWSAccessKeyId=AKIA6JY5OJGATYY73G53&Signature=GNJTqoyPczha6aT3nczmyamT7MM%3D&Expires=1717698569',
  //     ],
  //     imgname: ['202405071458015810.png', '202405071458015811.png'],
  //     imgexp: '2024-05-07 14:59:28',
  //   },
  //   remark: 'qqqq',
  //   date: '20240507',
  //   startdate: '20240507',
  //   starttime: '14:56',
  //   enddate: '20240507',
  //   endtime: '15:56',
  //   approvetype: '',
  //   requestsubtypename: '',
  //   rolename: [],
  //   requestsubtype: '',
  //   duration: '1',
  //   userid: '+959785565578',
  //   vehicleuse: ['002'],
  //   modeoftravel: ['003'],
  //   approveremployeeid: '',
  //   approverusername: '',
  //   rejectperson: '',
  //   rejectrole: '',
  //   personname: '',
  //   requestedby: '',
  //   adminemployeeid: '',
  //   requestedbyname: 'Kyaw Soe Hein',
  //   requestedbyempid: '000011',
  //   username: 'Kyaw Soe Hein',
  //   employeeid: '000011',
  //   department: 'Customer Care Department',
  //   costcenter: 'None',
  //   division: 'None',
  //   rank: 'Sales Promoter',
  //   approvedtime: '',
  //   projectname: 'Service3651',
  //   projectid: '046118911',
  //   productname: 'Black Coffee',
  //   productid: '6710385083',
  //   accompanyperson: [
  //     {
  //       userid: 'con@mit.com.mm',
  //       username: 'Chit Oo Naung',
  //       userimage: '',
  //     },
  //     {
  //       userid: '+959259217226',
  //       username: 'Cypher',
  //       userimage: '',
  //     },
  //   ],
  //   approver: ['con@mit.com.mm'],
  //   approvernamelist: ['Chit Oo Naung'],
  //   appid: 'kunyek',
  //   domainid: '002',
  //   orgid: '30770116',
  //   requestrefno: '412',
  //   requeststatus: '001',
  //   requesttype: 'BCNDUWO85SCGQW',
  //   claimdate: '',
  //   amount: '50000',
  //   currencyid: 'SDJFIEJVEMFDSJ',
  //   currencyname: 'MMK',
  //   fromplace: '',
  //   toplace: '',
  //   readonly: false,
  //   paymentstatus: false,
  //   weekday: [],
  //   trantype: '',
  //   reservationtype: '',
  //   workstationid: '',
  //   roomid: '',
  //   deskid: '',
  //   roomname: '',
  //   maxpeople: '',
  //   floorname: '',
  //   going: false,
  //   return: false,
  //   arrivetime: '',
  //   returntime: '',
  //   userlist: [],
  //   userfrom: '',
  //   userto: '',
  //   userfromto: [],
  //   lat: '',
  //   long: '',
  //   location: '',
  //   locationid: '',
  //   travelrequestid: '',
  // };

  getRequestType() {
    var data = {
      orgid: this.allinoneService.getSelectedOrg(),
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.kunyekService.getRequestType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log("Requt tyep", res)
          this.requestTypeList = res.requesttypelist;
          this.getRequestTypeName();
        }
      },
      (err) => {
      }
    );
  }

  getModeOfTravel() {
    this.showTravelMode = this.data.modeoftravel
      .map((m: any) => {
        const mode = this.modeList.find((x) => x.id == m);
        console.log('mode: ', mode);
        return mode !== undefined ? mode.value : '';
      })
      .join(' , ');
  }

  getVehicle() {
    this.showVehicle = this.data.vehicleuse
      .map((v: any) => {
        const vehicle = this.vehicleuseList.find((x) => x.id == v);
        return vehicle !== undefined ? vehicle.value : '';
      })
      .join(' , ');
  }

  getRequestTypeName() {
    let type = this.requestTypeList.find((x: any) => x.requesttypeid == this.data.requesttype);
    this.showTypeName = type !== undefined ? type.requesttypename : ""
    console.log(type)
  }

  ngOnInit(): void {
    this.getRequestType();
    this.getVehicle();
    this.getModeOfTravel();
  }
}
