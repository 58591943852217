import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-office-calculate-leave',
  templateUrl: './office-calculate-leave.component.html',
  styleUrls: ['./office-calculate-leave.component.scss']
})
export class OfficeCalculateLeaveComponent implements OnInit, OnDestroy {
  @ViewChild('choosefile') choosefile: any;

  saveLoading: boolean = false;
  formSubmitted: boolean = false;
  isCompleted: boolean = false;
  refreshingImportStatus:boolean =false;
  importStatus:string="";
  statusInterval: any;
  importData: any;
  errorData: any;

  fileSelected: any = '';
  filename: string = '';
  filetype: string = '';
  orgid: string = '';
  domainid: string = '';

  calendarList: any = [];
  organizations: any = [];

  allowedFiletype = ['xlsx' , 'xls'];

  progress: number = 0;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private router: Router,
  ) { 
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
  }
  ngOnDestroy(): void {
    
    clearInterval(this.statusInterval);
  }

  ngOnInit(): void {
    // if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    // } else {
      // this.organizations = this.allinoneService.orgsCRm;
      // this.orgid = this.allinoneService.selected_orgcrm
      //   ? this.allinoneService.selected_orgcrm.orgid
      //   : this.organizations[0].orgid;
    // }
    this.getImportStatus();
    this.statusInterval = setInterval(() => {
      if (this.importStatus == '002' || this.importStatus == '003') {
        this.getImportStatus();
      }
    }, 3000);
  }
  uploadFile(event: any) {
    var filetype = event[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  onFileSelected(event: any) {
    var filetype = event.target.files[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event.target.files[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  uploadfile() {
    this.formSubmitted = true;
    
    if (!this.fileSelected) {
      return this.messageService.openSnackBar('Please upload a file.', 'warn');
    }
    clearInterval(this.statusInterval);
    this.saveLoading = true;
    var filename =
      this.allinoneService.getCurrentDateToDB() +
      this.allinoneService.getCurrentTimeToDB2() +
      '_' +
      this.filename;
    this.allinoneService
      .fileUploadToS3WithPathForImageAndProgress(
        this.fileSelected,
        filename,
        'importfiles/origin'
      )
      .on('httpUploadProgress', (progress) => {
        let progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        console.log(progressPercentage);
        this.progress = progressPercentage;
      })
      .send((err: any, data: any) => {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          //Fail
          this.progress = 0;
          this.saveLoading = false;

          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          return false;
        }
        this.submitImport(filename);
        return true;
      });
  }

  submitImport(filename: string) {
    
    var data = {
      orgid: this.orgid,
      filename: filename,
    };
    this.kunyekService.addOfficeLeaveImport(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.fileSelected = '';
          this.filename = '';
          this.filetype = '';
          this.getImportStatus();
          this.statusInterval = setInterval(() => {
            if (this.importStatus == '002' || this.importStatus == '003') {
              this.getImportStatus();
            }
          }, 3000);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  onDismiss() {
    if (this.saveLoading) {
      return;
    }
  }

  downloadSample() {
    window.open(
      this.allinoneService.bucketUrl +
        'templates/CalculateLeaveSampleTemplate.xlsx',
      '_blank'
    );
  }
  //1 - origin, 2- error
  downloadFile(type: number) {
    if (type == 1) {
      window.open(this.importData.originfile, '_blank');
    } else {
      window.open(this.importData.errorfile, '_blank');
    }
  }

  getImportStatus() {
    this.refreshingImportStatus = true;
    var data = {
      orgid: this.orgid,
    };
    this.kunyekService.getOfficeLeaveImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // if (
          //   (this.importStatus == '002' || this.importStatus == '003') &&
          //   res.importstatus != this.importStatus
          // ) {
            if(res.importstatus == ''){
              clearInterval(this.statusInterval);
              this.refreshingImportStatus= false;
            }
            else if(res.importstatus == '001' || res.importstatus=="004"){
              this.importStatus = res.importstatus;
              this.importData = res;
              this.isCompleted = true;
              clearInterval(this.statusInterval);
              this.refreshingImportStatus= false;
            }

          //   clearInterval(this.statusInterval);
          //   this.refreshingImportStatus= false;
          // }
          this.importStatus = res.importstatus;
          this.importData = res;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        // this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

}
