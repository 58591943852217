import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss'],
})
export class CandidatesComponent implements OnInit {
  customFields: any = [];
  customFieldsOrigin: any = [];
  customFieldsFinal: any = [];
  saveLoading: boolean = false;
  cusFieldCount: number = 99;
  maxOption: any = 1;
  maxOptionCount: any[] = [];
  checkEdit: boolean = false;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<CandidatesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.orgListForTag = data.orgList ? data.orgList : [];
    // this.orgListForTag.map((item: any) => {
    //   if (item.admin == 'true') {
    //     this.postAsList.push(item)
    //   }
    // })
    // this.post = data.post;
    // if (data.post) {
    //   this.editLoading = true;
    // }
    // if (this.checkEdit) {
      
    this.customFieldsOrigin = data.candidatesList;
    console.log('Pass Candi>>');
    console.log(this.customFieldsOrigin);
    this.cusFieldCount = 99;
    // this.customFieldsOrigin = this.candidatesList;
    this.maxOptionCount = [];
    this.customFields = JSON.parse(JSON.stringify(this.customFieldsOrigin));
    console.log('--AA--');
    console.log(this.customFields);
    if (this.customFieldsOrigin.length == 0) {
      this.addCusField();
    } else {
      for (let i = 0; i < this.customFields.length; i++) {
        this.maxOptionCount.push(i + 1);
      }
    }
    // this.maxOption = data.voteLimit;
    if (data.voteLimit == 0) {
      this.maxOption = 1;
    } else {
      this.maxOption = data.voteLimit;
      var lgh = this.customFieldsOrigin.length;
      if (lgh != 0) {
        var lastCount = this.customFieldsOrigin[lgh - 1].candidateid;
        this.cusFieldCount = parseInt(lastCount);
      }
    }
    // }
  }
  async ngOnInit(): Promise<void> {
    // this.openCustomAnswer();
  }
  //////////
  finishCustomAnswer() {
    console.log('custom fields');
    console.log(this.customFields);
    console.log('custom fields origin');
    console.log(this.customFieldsOrigin);
    if (
      this.maxOption == '' ||
      this.maxOption == undefined ||
      this.maxOption < 1
    ) {
      this.messageService.openSnackBar(
        'Max selection should be at least 1!',
        'warn'
      );
    } else {
      var lgh = this.customFieldsOrigin.length;
      if (lgh == 0) {
        this.cusFieldCount = 99;
      } else {
        // var lastCount = this.customFieldsOrigin[lgh - 1].candidateid;
        // this.cusFieldCount = parseInt(lastCount);
      }
      var tempList = [];
      for (let i = 0; i < this.customFields.length; i++) {
        if (this.customFields[i].name.trim() != '') {
          var c = 0;
          for (let j = 0; j < this.customFieldsOrigin.length; j++) {
            // if (this.customFields[i].name == this.customFieldsOrigin[j].name) {
            //   c = 1
            // }
            if (
              this.customFields[i].candidateid ==
              this.customFieldsOrigin[j].candidateid
            ) {
              c = 2;
              this.customFieldsOrigin[j].name = this.customFields[i].name;
              break;
            }
          }
          if (c == 2) {
          } else if (c == 0) {
            this.cusFieldCount = this.cusFieldCount + 1;
            tempList.push({
              candidateid: this.cusFieldCount.toString(),
              name: this.customFields[i].name,
            });
            // this.customFieldsOrigin.push({ "candidateid": this.cusFieldCount.toString(), "name": this.customFields[i].name })
          }
        }
      }

      this.customFieldsOrigin.push.apply(this.customFieldsOrigin, tempList);
      if (this.customFieldsOrigin.length < 2) {
        this.messageService.openSnackBar(
          'Please add at least two candidates!',
          'warn'
        );
      } else if (this.customFieldsOrigin.length < this.maxOption) {
        this.messageService.openSnackBar(
          'Vote limit is greater than total candidates!',
          'warn'
        );
      } else {
        // this.customAnswer = false;
        this.customFieldsFinal = JSON.parse(
          JSON.stringify(this.customFieldsOrigin)
        );
        // cusFieldCount
        // this.customAnswer = false;
        // this.customFieldsOrigin = JSON.parse(
        //   JSON.stringify(this.customFieldsFinal)
        // );
        // this.maxOption = this.maxOptionFinal;
        // // this.maxOptionFinal = this.maxOption;
        console.log('FINAL>>');
        console.log(this.customFieldsOrigin);
        console.log('--Removed--');
        // console.log(this.removedList);
        var ret = [
          {
            candidates: this.customFieldsOrigin,
            votelimit: this.maxOption,
          },
        ];
        this.dialogRef.close(ret);
      }
    }
  }
  close() {
    this.dialogRef.close(false);
  }
  removeCusField(idx: any) {
    if (this.customFields.length > 1) {
      var candidateid = this.customFields[idx].candidateid;
      this.customFields.splice(idx, 1);
      if (this.customFieldsOrigin.length != 0) {
        for (var i = 0; i < this.customFieldsOrigin.length; i++) {
          if (candidateid == this.customFieldsOrigin[i].candidateid) {
            this.customFieldsOrigin.splice(i, 1);
            console.log('--remove--');
            console.log(candidateid);
            // if(this.checkEdit){
            //   this.removedList.push(candidateid)
            // }
          }
        }
      }
      this.maxOptionCount.pop();
    }
  }
  addCusField() {
    this.customFields.push({ name: '' });
    this.maxOptionCount.push(this.customFields.length);
  }
  // openCustomAnswer() {
  //   // this.customAnswer = true;
  //   this.cusFieldCount = 99;
  //   this.customFields = [];
  //   this.maxOptionCount = [];
  //   this.customFields = JSON.parse(JSON.stringify(this.customFieldsOrigin));
  //   if (this.customFieldsOrigin.length == 0) {
  //     this.addCusField();
  //   } else {
  //     for (let i = 0; i < this.customFields.length; i++) {
  //       this.maxOptionCount.push(i + 1);
  //     }
  //   }
  // }
}
