import {
  Component,
  HostListener,
  Inject,
  Injector,
  OnInit,
  Optional,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
})
export class RoleComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  mobileViewWidth: any = 490;
  itemPerPage: any = 100;
  total: any = 0;
  currentPage: any = 1;
  searchText: any = '';
  orgID: any = '';
  permissionID: any = '';
  roleName: any = '';
  roleDesc: any = '';
  roelID: any = '';
  roleTypeID: any = '001';
  requestTypeList: any[] = [];

  isviewornew: boolean = false;
  isMobileView: boolean = false;
  roleListLoading: boolean = false;
  isFocus: boolean = false;
  isEditing: boolean = false;
  isdomainAdmin: boolean = false;
  orgListLoading: boolean = false;
  saveLoading: boolean = false;
  roleFormSubmitted: boolean = false;
  gettingPermission: boolean = false;
  isFromOrganization: boolean = false;
  isFromMemberCreate: boolean = false;

  roleList: any = [];
  orgList: any = [];
  permissionList: any = [];
  permissionIDList: any = [];
  requestIDList: any = [];
  checklist: any = [];
  permission: any = [];
  roleTypeList = [
    {
      name: 'Office',
      id: '001',
    },
    {
      name: 'CRM',
      id: '002',
    },
  ];

  list: any = [
    {
      title: 'Service',
      appid: '4',
      appname: 'Service Tickets',
      icon: 'assets/images/service.png',
      value: '',
      view: '',
      create: '',
      edit: '',
      delete: 'null',
    },
    {
      title: '',
      appid: '5',
      appname: 'Service Summary',
      icon: 'assets/images/servicesummary.png',
      value: '',
      view: '',
      create: 'null',
      edit: 'null',
      delete: 'null',
    },
    {
      title: '',
      appid: '10',
      appname: 'Service Type',
      icon: 'assets/images/servicetype.png',
      value: '',
      view: '',
      create: 'null',
      edit: '',
      delete: 'null',
    },
    {
      title: '',
      appid: '11',
      appname: 'Service Status',
      icon: 'assets/images/servicestatus.png',
      value: '',
      view: '',
      create: 'null',
      edit: '',
      delete: 'null',
    },
    {
      title: 'Sales',
      appid: '1',
      appname: 'Leads',
      icon: 'assets/images/leads.png',
      value: '',
      view: '',
      create: '',
      edit: '',
      delete: '',
    },
    {
      title: '',
      appid: '2',
      appname: 'Deals',
      icon: 'assets/images/deals.png',
      value: '',
      view: '',
      create: '',
      edit: '',
      delete: '',
    },
    {
      title: '',
      appid: '3',
      appname: 'Pipeline',
      icon: 'assets/images/pipeline.png',
      value: '',
      view: '',
      create: 'null',
      edit: 'null',
      delete: 'null',
    },
    {
      title: '',
      appid: '8',
      appname: 'Customers',
      icon: 'assets/images/customers.png',
      value: '',
      view: '',
      create: '',
      edit: '',
      delete: '',
    },
    {
      title: 'References',
      appid: '7',
      appname: 'Products',
      icon: 'assets/images/product.png',
      value: '',
      view: '',
      create: '',
      edit: '',
      delete: '',
    },
    {
      title: '',
      appid: '9',
      appname: 'Person in Charge',
      icon: this.allinoneService.bucketUrl + 'policyicons/members.png',
      value: '',
      view: '',
      create: '',
      edit: '',
      delete: '',
    },
  ];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  officePermission: any = [
    {
      id: '002',
      name: 'Request Type',
      checked: false,
    },
    {
      id: '003',
      name: 'Leave Calendar',
      checked: false,
    },
    {
      id: '004',
      name: 'Leave Template',
      checked: false,
    },
    {
      id: '005',
      name: 'Leave Entitlement',
      checked: false,
    },
    {
      id: '006',
      name: 'Leave Opening',
      checked: false,
    },
    {
      id: '007',
      name: 'Holiday',
      checked: false,
    },
    {
      id: '008',
      name: 'Working Hour',
      checked: false,
    },
    // {
    //   id: '009',
    //   name: 'Leave Summary Report',
    //   checked: false,
    // },
    {
      id: '010',
      name: 'Service Years',
      checked: false,
    },
    {
      id: '011',
      name: 'Claim',
      checked: false,
    },
    {
      id: '012',
      name: 'HR',
      checked: false,
    },
    {
      id: '013',
      name: 'Cash Advance',
      checked: false,
    },
    {
      id: '014',
      name: 'Overtime',
      checked: false,
    },
    {
      id: '015',
      name: 'Transportation',
      checked: false,
    },
    {
      id: '016',
      name: 'Leave',
      checked: false,
    },
    {
      id: '017',
      name: 'Travel',
      checked: false,
    },
    {
      id: '018',
      name: 'Off In Lieu',
      checked: false,
    },
    {
      id: '019',
      name: 'Early Out',
      checked: false,
    },
    {
      id: '020',
      name: 'Work From Home',
      checked: false,
    },
    {
      id: '021',
      name: 'Late',
      checked: false,
    },
    {
      id: '022',
      name: 'General',
      checked: false,
    },
    {
      id: '023',
      name: 'Reservation',
      checked: false,
    },
  ];

  private dialogRef: any;
  private dialogData: any;

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private injector: Injector
  ) {
    this.onResize(event);
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    if (this.dialogData) {
      this.isviewornew = false;
      this.isFromMemberCreate = false;
      this.orgID = this.dialogData.orgid;
    } else {
      this.isFromMemberCreate = false;
    }
  }

  ngOnInit(): void {
    this.isdomainAdmin = this.allinoneService.isAdminOf('domain');
    this.getOrgList();

    let routename = this.router.url;
    if (routename.includes('organizations')) {
      this.isFromOrganization = true;
    } else {
      this.isFromOrganization = false;
    }
  }

  filter() {}

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
  }

  refresh() {
    this.getRoleList();
    this.getRequestType();
  }

  sortData(sort: Sort) {
    const data = this.roleList;
    if (!sort.active || sort.direction === '') {
      this.roleList = data;
      return;
    }
    this.roleList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'desc':
          return this.allinoneService.compare(a.desc, b.desc, isAsc);
        case 'org':
          return this.allinoneService.compare(a.orgname, b.orgname, isAsc);
        default:
          return 0;
      }
    });
  }

  editRole(role: any) {
    this.isEditing = true;
    this.isviewornew = true;
    this.roleName = role.name;
    this.roleDesc = role.desc;
    this.orgID = role.orgid;
    this.roelID = role.roleid;
    this.roleTypeID = role.type;

    if (this.roleTypeID == '001') {
      console.log(role)
      this.officePermission.map((x: any) => {
        if (role.permission.includes(x.id)) {
          x.checked = true;
        }
      });
      this.requestTypeList.forEach((x: any) => {
        if (role.approvalpermission && role.approvalpermission.includes(x.requesttypeid)) {
          x.checked = true;
        }
      });
    } else {
      this.dialogRef.updateSize('900px');
      this.permission = JSON.parse(JSON.stringify(role.permission));
      for (let i = 0; i < this.list.length; i++) {
        if (!this.list[i].value) {
          var type = this.permission.map((x: any) => x).indexOf('001');

          if (type > -1) {
            this.list[0].value = '001';
          }
          var type1 = this.permission.map((x: any) => x).indexOf('002');

          if (type1 > -1) {
            this.list[1].value = '002';
          }
          var type2 = this.permission.map((x: any) => x).indexOf('003');

          if (type2 > -1) {
            this.list[2].value = '003';
          }
          var type3 = this.permission.map((x: any) => x).indexOf('004');

          if (type3 > -1) {
            this.list[3].value = '004';
          }
          var type4 = this.permission.map((x: any) => x).indexOf('005');

          if (type4 > -1) {
            this.list[4].value = '005';
          }

          var type5 = this.permission.map((x: any) => x).indexOf('006');

          if (type5 > -1) {
            this.list[5].value = '006';
          }
          var type6 = this.permission.map((x: any) => x).indexOf('007');

          if (type6 > -1) {
            this.list[6].value = '007';
          }

          var type7 = this.permission.map((x: any) => x).indexOf('008');

          if (type7 > -1) {
            this.list[7].value = '008';
          }

          // var type8 = this.permission.map((x: any) => x).indexOf('009');

          // if (type8 > -1) {
          //   this.list[8].value = '009';
          // }
          var type9 = this.permission.map((x: any) => x).indexOf('0010');

          if (type9 > -1) {
            this.list[8].value = '0010';
          }
          var type10 = this.permission.map((x: any) => x).indexOf('0011');

          if (type10 > -1) {
            this.list[9].value = '0011';
          }
        } // service ticket
        var s1 = this.permission.map((x: any) => x).indexOf('011');

        if (s1 > -1) {
          this.list[0].view = '011';
        }
        var s2 = this.permission.map((x: any) => x).indexOf('0012');

        if (s2 > -1) {
          this.list[0].create = '0012';
        }
        var s3 = this.permission.map((x: any) => x).indexOf('0013');

        if (s3 > -1) {
          this.list[0].edit = '0013';
        }
        var s4 = this.permission.map((x: any) => x).indexOf('0014');

        if (s4 > -1) {
          this.list[0].delete = '0014';
        }

        // service summary
        var sm1 = this.permission.map((x: any) => x).indexOf('0021');

        if (sm1 > -1) {
          this.list[1].view = '0021';
        }
        var sm2 = this.permission.map((x: any) => x).indexOf('0022');

        if (sm2 > -1) {
          this.list[1].create = '0022';
        }
        var sm3 = this.permission.map((x: any) => x).indexOf('0023');

        if (sm3 > -1) {
          this.list[1].edit = '0023';
        }
        var sm4 = this.permission.map((x: any) => x).indexOf('0024');

        if (sm4 > -1) {
          this.list[1].delete = '0024';
        }

        // service type
        var st1 = this.permission.map((x: any) => x).indexOf('0031');

        if (st1 > -1) {
          this.list[2].view = '0031';
        }
        var st2 = this.permission.map((x: any) => x).indexOf('0032');

        if (st2 > -1) {
          this.list[2].create = '0032';
        }
        var st3 = this.permission.map((x: any) => x).indexOf('0033');

        if (st3 > -1) {
          this.list[2].edit = '0033';
        }
        var st4 = this.permission.map((x: any) => x).indexOf('0034');

        if (st4 > -1) {
          this.list[2].delete = '0034';
        }

        // service status
        var ss1 = this.permission.map((x: any) => x).indexOf('0041');

        if (ss1 > -1) {
          this.list[3].view = '0041';
        }
        var ss2 = this.permission.map((x: any) => x).indexOf('0042');

        if (ss2 > -1) {
          this.list[3].create = '0042';
        }
        var ss3 = this.permission.map((x: any) => x).indexOf('0043');

        if (ss3 > -1) {
          this.list[3].edit = '0043';
        }
        var ss4 = this.permission.map((x: any) => x).indexOf('0044');

        if (ss4 > -1) {
          this.list[3].delete = '0044';
        }
        // leads
        var l1 = this.permission.map((x: any) => x).indexOf('0051');

        if (l1 > -1) {
          this.list[4].view = '0051';
        }
        var l2 = this.permission.map((x: any) => x).indexOf('0052');

        if (l2 > -1) {
          this.list[4].create = '0052';
        }
        var l3 = this.permission.map((x: any) => x).indexOf('0053');

        if (l3 > -1) {
          this.list[4].edit = '0053';
        }
        var l4 = this.permission.map((x: any) => x).indexOf('0054');

        if (l4 > -1) {
          this.list[4].delete = '0054';
        }

        // deals
        var d1 = this.permission.map((x: any) => x).indexOf('0061');

        if (d1 > -1) {
          this.list[5].view = '0061';
        }
        var d2 = this.permission.map((x: any) => x).indexOf('0062');

        if (d2 > -1) {
          this.list[5].create = '0062';
        }
        var d3 = this.permission.map((x: any) => x).indexOf('0063');

        if (d3 > -1) {
          this.list[5].edit = '0063';
        }
        var d4 = this.permission.map((x: any) => x).indexOf('0064');

        if (d4 > -1) {
          this.list[5].delete = '0064';
        }

        // pipeline
        var p1 = this.permission.map((x: any) => x).indexOf('0071');

        if (p1 > -1) {
          this.list[6].view = '0071';
        }
        var p2 = this.permission.map((x: any) => x).indexOf('0072');

        if (p2 > -1) {
          this.list[6].create = '0072';
        }
        var p3 = this.permission.map((x: any) => x).indexOf('0073');

        if (p3 > -1) {
          this.list[6].edit = '0073';
        }
        var p4 = this.permission.map((x: any) => x).indexOf('0074');

        if (p4 > -1) {
          this.list[6].delete = '0074';
        }

        // customers
        var c1 = this.permission.map((x: any) => x).indexOf('0081');

        if (c1 > -1) {
          this.list[7].view = '0081';
        }
        var c2 = this.permission.map((x: any) => x).indexOf('0082');

        if (c2 > -1) {
          this.list[7].create = '0082';
        }
        var c3 = this.permission.map((x: any) => x).indexOf('0083');

        if (c3 > -1) {
          this.list[7].edit = '0083';
        }
        var c4 = this.permission.map((x: any) => x).indexOf('0084');

        if (c4 > -1) {
          this.list[7].delete = '0084';
        }

        // product
        var pd1 = this.permission.map((x: any) => x).indexOf('00101');

        if (pd1 > -1) {
          this.list[8].view = '00101';
        }
        var pd2 = this.permission.map((x: any) => x).indexOf('00102');

        if (pd2 > -1) {
          this.list[8].create = '00102';
        }
        var pd3 = this.permission.map((x: any) => x).indexOf('00103');

        if (pd3 > -1) {
          this.list[8].edit = '00103';
        }
        var pd4 = this.permission.map((x: any) => x).indexOf('00104');

        if (pd4 > -1) {
          this.list[8].delete = '00104';
        }

        // PIC
        var pi1 = this.permission.map((x: any) => x).indexOf('00111');

        if (pi1 > -1) {
          this.list[9].view = '00111';
        }
        var pi2 = this.permission.map((x: any) => x).indexOf('00112');

        if (pi2 > -1) {
          this.list[9].create = '00112';
        }
        var pi3 = this.permission.map((x: any) => x).indexOf('00113');

        if (pi3 > -1) {
          this.list[9].edit = '00113';
        }
        var pi4 = this.permission.map((x: any) => x).indexOf('00114');
        if (pi4 > -1) {
          this.list[9].delete = '00114';
        }
      }
    }

    // for (let a = 0; a < role.permission.length; a++) {
    //   for (let b = 0; b < this.permissionList.length; b++) {
    //     for (let c = 0; c < this.permissionList[b].list.length; c++) {
    //       if (role.permission[a] == this.permissionList[b].list[c].id) {
    //         this.permissionList[b].checked = true;
    //         this.permissionList[b].list[c].checked = true;
    //       }
    //     }
    //   }
    // }
  }

  deleteRule(role: any) {}

  gotoOrg() {
    if (this.isFromOrganization) {
      this.router.navigateByUrl(`organizations`);
    } else {
      this.router.navigateByUrl(`members`);
    }
  }

  cancel() {
    if (this.isFromMemberCreate) {
      this.dialogRef.close(false);
    } else {
      this.dialogRef.updateSize('700px');
      this.isEditing = false;
      this.roelID = '';
      this.isviewornew = false;
      this.roleFormSubmitted = false;
      this.roleName = '';
      this.roleDesc = '';
      this.roleTypeID = '';
      this.permissionIDList = [];
      this.requestIDList = [];
      this.permission = [];
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].value = '';
        if (this.list[i].view != 'null') {
          this.list[i].view = '';
        }
        if (this.list[i].create != 'null') {
          this.list[i].create = '';
        }
  
        if (this.list[i].edit != 'null') {
          this.list[i].edit = '';
        }
        if (this.list[i].delete != 'null') {
          this.list[i].delete = '';
        }
      }
      this.officePermission.map((x: any) => {
        x.checked = false;
      });
      this.requestTypeList.map((x: any) => {
        x.checked = false;
      });

      // for (let a = 0; a < this.permissionList.length; a++) {
      //   for (let b = 0; b < this.permissionList[a].list.length; b++) {
      //     this.permissionList[a].checked = false;
      //     this.permissionList[a].list[b].checked = false;
      //   }
      // }
    }
  }

  submitRule() {
    this.roleFormSubmitted = true;
    if (this.roleName == '' || this.roleTypeID == '') {
    } else {
      if (this.isEditing) {
        this.updateRole();
      } else {
        this.addRole();
      }
    }
  }

  newRole() {
    this.roleTypeID = '001';
    this.isviewornew = true;
    this.officePermission[0].checked = true;
    this.requestTypeList[0].checked = true;
    if (this.orgID == '') {
      this.orgID = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.orgList[0].orgid;
    }

    // for (let b = 0; b < this.permissionList.length; b++) {
    //   this.permissionList[b].checked = false;
    //   for (let c = 0; c < this.permissionList[b].list.length; c++) {
    //     this.permissionList[b].list[c].checked = false;
    //   }
    // }
  }

  changeOrg(event: any) {
    this.getRoleList();
    if (this.orgID == '') {
      // this.roleList = [];
    } else {
      this.allinoneService.selectedOrg = this.orgID;
      this.allinoneService.setSelectedOrg(this.orgID);
    }
  }

  getOrgList() {
    this.orgList = this.allinoneService.orgs;

    // if (this.allinoneService.getLoyaltySession('orgid')) {
    //   this.orgID = this.allinoneService.getLoyaltySession('orgid');
    // } else {
    if (this.orgID == '') {
      this.orgID = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.orgList[0].orgid;
    }

    // }

    setTimeout(() => {
      // this.getpermissionList();
      this.getRoleList();
      this.getRequestType();
    }, 100);

    this.orgListLoading = false;
  }

  refreshPermission() {
    // this.checklist = [];
    // for(let a = 0 ;a < this.permissionList.length; a++) {
    //   if(this.permissionList[a].checked == true) {
    //     for(let b = 0; b < this.permissionList[a].list.length; b++) {
    //       if(this.permissionList[a].list[b].checked == true) {
    //         this.checklist.push(this.permissionList[a].list[b].id);
    //       }
    //     }
    //   }
    // }
    // this.getpermissionList();
  }

  // getpermissionList() {
  //   this.permissionList = [];
  //   this.gettingPermission = true;
  //   let data = {
  //     orgid: this.orgID,
  //     domainid: this.allinoneService.getDomain().domainid,
  //   };

  //   this.kunyekService.getResourcesList(data).subscribe(
  //     (res) => {
  //       if (res.returncode == '300') {
  //         this.gettingPermission = false;
  //         this.permissionList = res.datalist;
  //         // for(let a = 0 ; a < this.checklist.length; a++) {
  //         //   for(let b = 0; b < this.permissionList.length; b++) {
  //         //     for(let c = 0 ; c < this.permissionList[b].list.length; c++) {
  //         //       if(this.checklist[a] == this.permissionList[b].list[c].id) {
  //         //         this.permissionList[b].checked = true;
  //         //         this.permissionList[b].list[c].checked = true;
  //         //       }
  //         //     }
  //         //   }
  //         // }
  //       } else {
  //         this.gettingPermission = false;
  //         this.messageService.openSnackBar(res.message, 'warn');
  //       }
  //     },
  //     (err) => {
  //       this.gettingPermission = false;
  //       this.messageService.openSnackBar(err.message, 'warn');
  //     }
  //   );
  // }

  permissionChange() {
    this.permissionID = '';
  }

  checkAllCheckBox() {}

  panelChanged(index: any, event: any) {
    if (event.target.checked) {
      this.permissionList[index].checked = true;
      for (let a = 0; a < this.permissionList[index].list.length; a++) {
        this.permissionList[index].list[a].checked = true;
      }
    } else {
      this.permissionList[index].checked = false;
      for (let a = 0; a < this.permissionList[index].list.length; a++) {
        this.permissionList[index].list[a].checked = false;
      }
    }
    console.log(this.permissionList);
  }

  ischeck(index: any, listi: any, event: any) {
    if (event.target.checked) {
      // this.permissionIDList.push(this.permissionList[index].list[listi].id);
      this.permissionList[index].checked = true;
    } else {
      this.permissionList[index].list[listi].checked = false;
      let count = 0;
      for (let b = 0; b < this.permissionList[index].list.length; b++) {
        if (this.permissionList[index].list[b].checked == true) {
          count++;
        }
      }

      if (count == 0) {
        this.permissionList[index].checked = false;
      }

      // for (let a = 0; a < this.permissionIDList.length; a++) {
      //   if (this.permissionList[index].list[listi].id == this.permissionIDList[a]) {
      //     this.permissionIDList.splice(a, 1);
      //   }
      // }
    }
  }

  addRole() {
    this.saveLoading = true;
    this.permissionIDList = [];
    this.requestIDList = [];

    if (this.roleTypeID == '001') {
      this.officePermission.map((x: any) => {
        if (x.checked) {
          this.permissionIDList.push(x.id);
        }
      });
      this.requestTypeList.map((x: any) => {
        if (x.checked) {
          this.requestIDList.push(x.requesttypeid);
        }
      });
    } else {
      this.permissionIDList = this.permission;
    }
    // for (let a = 0; a < this.permissionList.length; a++) {
    //   if (this.permissionList[a].checked == true) {
    //     for (let b = 0; b < this.permissionList[a].list.length; b++) {
    //       if (this.permissionList[a].list[b].checked == true) {
    //         this.permissionIDList.push(this.permissionList[a].list[b].id);
    //       }
    //     }
    //   }
    // }
    let data = {
      name: this.roleName,
      permission: this.permissionIDList,
      approvalpermission: this.requestIDList,
      desc: this.roleDesc,
      type: this.roleTypeID,
      orgid: this.orgID,
      domainid: this.allinoneService.getDomain().domainid,
    };

    this.kunyekService.addRole(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.saveLoading = false;
          if (this.isFromMemberCreate) {
            this.dialogRef.close(true);
          } else {
            this.cancel();
            this.getRoleList();
          }

          this.messageService.openSnackBar('Saved successfully', 'success');
        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.message, 'wran');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.messageService.openSnackBar(err.message, 'warn');
      }
    );
  }

  getRoleList() {
    this.roleListLoading = true;
    let data = {
      orgid: this.orgID ? this.orgID : '',
      domainid: this.allinoneService.getDomain().domainid,
    };

    this.kunyekService.getRoleList(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.roleListLoading = false;
          this.roleList = res.datalist;
        } else {
          this.roleListLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.roleListLoading = false;
        this.messageService.openSnackBar(err.message, 'warn');
      }
    );
  }

  getRequestType() {
    let data = {
      orgid: this.orgID ? this.orgID : '',
      domainid: this.allinoneService.getDomain().domainid,
      type: '001'
    };

    this.kunyekService.getRequestType(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.requestTypeList = res.requesttypes.map((item: any) => ({
            ...item,
            checked: false
          }));  
          console.log(this.requestTypeList)
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.roleListLoading = false;
        this.messageService.openSnackBar(err.message, 'warn');
      }
    );
  }

  updateRole() {
    this.permissionIDList = [];
    this.requestIDList = [];
    if (this.roleTypeID == '001') {
      this.officePermission.map((x: any) => {
        if (x.checked) {
          this.permissionIDList.push(x.id);
        }
      });
      this.requestTypeList.map((x: any) => {
        if (x.checked) {
          this.requestIDList.push(x.requesttypeid);
        }
      });
    } else {
      this.permissionIDList = this.permission;
    }
    // for (let a = 0; a < this.permissionList.length; a++) {
    //   if (this.permissionList[a].checked == true) {
    //     for (let b = 0; b < this.permissionList[a].list.length; b++) {
    //       if (this.permissionList[a].list[b].checked == true) {
    //         this.permissionIDList.push(this.permissionList[a].list[b].id);
    //       }
    //     }
    //   }
    // }

    this.saveLoading = true;
    let data = {
      roleid: this.roelID,
      name: this.roleName,
      desc: this.roleDesc,
      type: this.roleTypeID,
      permission: this.permissionIDList,
      approvalpermission: this.requestIDList,
      orgid: this.orgID,
      domainid: this.allinoneService.getDomain().domainid,
    };

    this.kunyekService.updateRole(data).subscribe(
      (res) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.cancel();
          this.getRoleList();
          this.messageService.openSnackBar('Updated successfully', 'success');
        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.messageService.openSnackBar(err.message, 'warn');
      }
    );
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  typeChange(event: any) {
    this.roleTypeID = event.target.value;
    if (this.roleTypeID == '001') {
      this.dialogRef.updateSize('700px');
    } else {
      this.dialogRef.updateSize('900px');
    }
  }

  checkPermission(event: any, data: any, index: any) {
    var type = this.permission.map((x: any) => x).indexOf(data.value);
    if (event.target.checked == true) {
      if (type > -1) {
      } else {
        if (index == 0) {
          this.permission.push('001');
          this.list[index].value = '001';
        } else if (index == 1) {
          this.permission.push('002');
          this.list[index].value = '002';
        } else if (index == 2) {
          this.permission.push('003');
          this.list[index].value = '003';
        } else if (index == 3) {
          this.permission.push('004');
          this.list[index].value = '004';
        } else if (index == 4) {
          this.permission.push('005');
          this.list[index].value = '005';
        } else if (index == 5) {
          this.permission.push('006');
          this.list[index].value = '006';
        } else if (index == 6) {
          this.permission.push('007');
          this.list[index].value = '007';
        } else if (index == 7) {
          this.permission.push('008');
          this.list[index].value = '008';
        }
        // else if (index == 8) {
        //   this.permission.push('009')
        //   this.list[index].value = '009';
        // }
        else if (index == 8) {
          this.permission.push('0010');
          this.list[index].value = '0010';
        } else if (index == 9) {
          this.permission.push('0011');
          this.list[index].value = '0011';
        }
      }
    } else if (event.target.checked == false) {
      console.log(type);
      if (type > -1) {
        this.list[index].value = '';
        this.permission.splice(type, 1);
      }

      var type1 = this.permission.map((x: any) => x).indexOf(data.view);
      if (type1 > -1) {
        this.list[index].view = '';
        this.permission.splice(type1, 1);
      }

      var type2 = this.permission.map((x: any) => x).indexOf(data.create);
      if (type2 > -1) {
        this.list[index].create = '';
        this.permission.splice(type2, 1);
      }

      var type3 = this.permission.map((x: any) => x).indexOf(data.edit);
      if (type3 > -1) {
        this.list[index].edit = '';
        this.permission.splice(type, 1);
      }

      var type4 = this.permission.map((x: any) => x).indexOf(data.delete);
      if (type4 > -1) {
        this.list[index].delete = '';
        this.permission.splice(type, 1);
      }
    }
    console.log(this.permission);
  }

  checkNewpermission(event: any, data: any, index: any, show: any) {
    var view = this.permission.map((x: any) => x).indexOf(data.view);
    var create = this.permission.map((x: any) => x).indexOf(data.create);
    var edit = this.permission.map((x: any) => x).indexOf(data.edit);
    var delet = this.permission.map((x: any) => x).indexOf(data.delete);

    if (event.target.checked == true) {
      if (index == 0) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('011');
            this.list[index].view = '011';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0012');
            this.list[index].create = '0012';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0013');
            this.list[index].edit = '0013';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0014');
            this.list[index].delete = '0014';
          }
        }
      }
      if (index == 1) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0021');
            this.list[index].view = '0021';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0022');
            this.list[index].create = '0022';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0023');
            this.list[index].edit = '0023';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0024');
            this.list[index].delete = '0024';
          }
        }
      }
      if (index == 2) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0031');
            this.list[index].view = '0031';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0032');
            this.list[index].create = '0032';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0033');
            this.list[index].edit = '0033';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0034');
            this.list[index].delete = '0034';
          }
        }
      }
      if (index == 3) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0041');
            this.list[index].view = '0041';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0042');
            this.list[index].create = '0042';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0043');
            this.list[index].edit = '0043';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0044');
            this.list[index].delete = '0044';
          }
        }
      }
      if (index == 4) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0051');
            this.list[index].view = '0051';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0052');
            this.list[index].create = '0052';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0053');
            this.list[index].edit = '0053';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0054');
            this.list[index].delete = '0054';
          }
        }
      }
      if (index == 5) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0061');
            this.list[index].view = '0061';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0062');
            this.list[index].create = '0062';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0063');
            this.list[index].edit = '0063';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0064');
            this.list[index].delete = '0064';
          }
        }
      }
      if (index == 6) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0071');
            this.list[index].view = '0071';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0072');
            this.list[index].create = '0072';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0073');
            this.list[index].edit = '0073';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0074');
            this.list[index].delete = '0074';
          }
        }
      }
      if (index == 7) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0081');
            this.list[index].view = '0081';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0082');
            this.list[index].create = '0082';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0083');
            this.list[index].edit = '0083';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0084');
            this.list[index].delete = '0084';
          }
        }
      }
      if (index == 8) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('00101');
            this.list[index].view = '00101';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('00102');
            this.list[index].create = '00102';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('00103');
            this.list[index].edit = '00103';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('00104');
            this.list[index].delete = '00104';
          }
        }
      }
      if (index == 9) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('00111');
            this.list[index].view = '00111';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('00112');
            this.list[index].create = '00112';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('00113');
            this.list[index].edit = '00113';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('00114');
            this.list[index].delete = '00114';
          }
        }
      }
    } else if (event.target.checked == false) {
      if (show == 'view') {
        console.log('1');
        if (view > -1) {
          this.list[index].view = '';
          this.permission.splice(view, 1);
        }
      }
      if (show == 'create') {
        if (create > -1) {
          this.list[index].create = '';
          this.permission.splice(create, 1);
        }
      }
      if (show == 'edit') {
        if (edit > -1) {
          this.list[index].edit = '';
          this.permission.splice(edit, 1);
        }
      }
      if (show == 'delete') {
        if (delet > -1) {
          this.list[index].delete = '';
          this.permission.splice(delet, 1);
        }
      }
    }
  }
}
