<div class="container-fluid">
  <div class="content">
    <app-team-breadcrumb [s_breadcrumb]="'Device Info'"></app-team-breadcrumb>
    <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
      <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
        <input type="text" class="form-control" placeholder="&#xf002; Search by Name/ID" aria-label="Search by Name/ID"
          (focus)="isFocus = true" (blur)="isFocus = false" (ngModelChange)="searchTextChange()"
          [(ngModel)]="searchText" [ngClass]="{ 'c-search-input-with-clear': searchText }"
          style="font-family: FontAwesome, Ubuntu">
        <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
          [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false" title="Page Size">
          <mat-icon>filter_list</mat-icon>
        </button>
        <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
          <li>
            <div style="text-align: center;">Page size</div>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li class="dropdown-item" *ngFor="let page of pg">
            <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
              id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
            <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
          </li>
        </ul>
      </div>
      <div class="ms-2">
        <button class="btn btn-custom" (click)="refresh()" title="Refresh">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="ms-2">
        <button type="button" class="btn btn-custom" id="togglefilter" (click)="toggleFilter()">
          <mat-icon>{{openfilter ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </button>
      </div>
      <div class="line"></div>
      <div>
        <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>
      </div>
      <div class="line"></div>
      <div class="d-flex align-items-center">
        <span class="total-count-text">Total:&nbsp;</span>
        <span class="total-count-num">{{ (deviceInfoList | filter : searchText: 'deviceinfo' ).length }}</span>
      </div>

      <div class="input-group mt-2" *ngIf="isMobileView">
        <input type="text" class="form-control" placeholder="&#xf002; Search by Name/ID" aria-label="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
          [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
          (ngModelChange)="searchTextChange()">
        <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
          [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>

      <pagination-controls *ngIf="deviceInfoList.length > itemPerPage" class="mb-1 ms-auto paginate"
        (pageChange)="currentPage = $event" previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </div>
    <div *ngIf="openfilter" class="d-flex gap-3 flex-wrap" style="row-gap: 0 !important">
      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="departmentmenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Department&nbsp;:&nbsp;{{ searchForm.department.length > 20 ? (searchForm.department |
          slice:0:20)+'...' : searchForm.department}}
          <mat-menu #departmentmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let department of departmentList" (click)="departmentChange(department)">
              {{ department }}
            </button>
          </mat-menu>
          <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>
      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="divisionmenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Division&nbsp;:&nbsp;{{ searchForm.division.length > 22 ? (searchForm.division |
          slice:0:22)+'...' : searchForm.division}}
          <mat-menu #divisionmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let division of divisionList" (click)="divisionChange(division)">
              {{ division }}
            </button>
          </mat-menu>
          <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>
      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="teamidmenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Team ID&nbsp;:&nbsp;{{ searchForm.teamid.length > 22 ? (searchForm.teamid |
          slice:0:22)+'...' : searchForm.teamid}}
          <mat-menu #teamidmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let teamid of teamidList" (click)="teamidChange(teamid)">
              {{ teamid }}
            </button>
          </mat-menu>
          <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>

      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="costcenterMenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Cost Center&nbsp;:&nbsp;{{ searchForm.costcenter.length > 22 ? (searchForm.costcenter |
          slice:0:22)+'...' : searchForm.costcenter}}
          <mat-menu #costcenterMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let costcenter of costCenterList" (click)="costCenterChange(costcenter)">
              {{ costcenter }}
            </button>
          </mat-menu>
          <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>

      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="subdivisionMenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Sub Division&nbsp;:&nbsp;{{ searchForm.subdivision.length > 22 ? (searchForm.subdivision |
          slice:0:22)+'...' : searchForm.subdivision}}
          <mat-menu #subdivisionMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let subdivision of subDivisionList" (click)="subDivisionChange(subdivision)">
              {{ subdivision }}
            </button>
          </mat-menu>
          <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>

    </div>
    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
      <table class="table table-responsive table-borderless">
        <thead class="table-header">
          <tr>
            <td scope="col" mat-sort-header="employeeid">Employee ID</td>
            <td scope="col" class="col-md-1" mat-sort-header="username">Name</td>
            <td scope="col" class="col-md-1" mat-sort-header="userid">User ID</td>

            <td scope="col" class="col-md-2" mat-sort-header="mobile">Mobile</td>
            <td scope="col" class="col-md-2" mat-sort-header="devicename">Device Name</td>
            <td scope="col" class="col-md-1" mat-sort-header="date">Date</td>
            <td scope="col" class="col-md-3" mat-sort-header="uuid">UUID</td>
            <td scope="col" class="col-md-1" mat-sort-header="appversion">Connect Version </td>
            <!-- <td scope="col" mat-sort-header="appname">App</td>
            
            <td scope="col" class="col-md-2" mat-sort-header="uuid">UUID</td>
            
            <td scope="col" class="col-md-1" mat-sort-header="version">Device Version</td> -->

          </tr>
        </thead>
        <tbody *ngIf="listLoading">
          <tr>
            <td colspan="9" class="nodata">
              <div class="spinner-border" entitle="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!listLoading">
          <tr *ngIf="deviceInfoList.length == 0">
            <td colspan="9" class="nodata">Empty</td>
          </tr>
          <tr
            *ngFor="let data of deviceInfoList  | filter : searchText: 'deviceinfo' | paginate : { itemsPerPage: itemPerPage , currentPage : currentPage };let index = index">
            <td class="">
              <span [title]="data.employeeid">{{ data.employeeid }}</span>
            </td>
            <td class="td-data">
              <span [title]="data.username">{{ data.username }}</span>
            </td>
            <td class="td-data">
              <span [title]="data.userid">{{ data.userid }}</span>
            </td>
            <td class="td-data">
              <span [title]="data.mobile">{{ data.mobile }}</span>
            </td>
            <td class="td-data">
              <span [title]="data.devicename">{{ data.devicename }}</span>
            </td>
            <td class="td-data">
              <span [title]="data.createddate">{{ data.createddate | date : "dd/MM/yyyy"}}</span>
            </td>
            <td class="td-data">
              <span [title]="data.uuid">{{ data.uuid }}</span>
            </td>
            <td class="td-data">
              <span [title]="data.appversion">{{ data.appversion }}</span>
            </td>
            <!-- <td class="td-data">
              <span [title]="data.appname">{{ data.appname }}</span>
            </td>
            
            <td class="td-data">
              <span [title]="data.uuid">{{ data.uuid }}</span>
            </td>
            
            <td class="td-data">
              <span [title]="data.version">{{ data.version }}</span>
            </td> -->

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>