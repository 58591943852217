import { formatDate } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';

@Component({
  selector: 'app-synchistory',
  templateUrl: './synchistory.component.html',
  styleUrls: ['./synchistory.component.scss'],
})
export class SynchistoryComponent implements OnInit {
  @ViewChild('chatPS') chatPS!: ElementRef;
  synchisloading: boolean = false;
  searchText: string = '';
  isFocus: boolean = false;
  openfilter: boolean = false;
  historyList: any = []; // This will hold the filtered data
  currentDate = new Date(Date.now());
  syncDate: Date | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
  data: any[] = []; // Ensure that data is always an array, even when empty

  generalDatePickerConfig = this.allinoneService.datePickerConfig;

  constructor(
    public allinoneService: AllInOneService,
    private dialogRef: MatDialogRef<SynchistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any // `dialogData` passed from parent component
  ) {

    // Initialize data or set empty array
    this.data = dialogData?.historyList || []; // Make sure data is an array
  }

  ngOnInit(): void {
    this.historyList = this.data;
  }

  close(): void {
    this.dialogRef.close();
  }

  clear() {
    this.searchText = '';
    this.historyList = this.data; // Reset filtered data to original
  }

  search() {
    if (this.searchText || this.syncDate || this.startDate || this.endDate) {
      this.historyList = this.data.filter((it) => {
        const matchesSearchText = this.searchText
          ? it.userid.toString().toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
          : true;
  
        const matchesSyncDate = this.syncDate
          ? it.syncdate === formatDate(this.syncDate, 'yyyyMMdd', 'en-US')
          : true;
  
        const matchesStartDate = this.startDate
          ? it.startdate === formatDate(this.startDate, 'yyyyMMdd', 'en-US')
          : true;
  
        const matchesEndDate = this.endDate
          ? it.enddate === formatDate(this.endDate, 'yyyyMMdd', 'en-US')
          : true;
  
        return matchesSearchText && matchesSyncDate && matchesStartDate && matchesEndDate;
      });
    } else {
      // Reset filtered data to original if no filters are applied
      this.historyList = this.data;
    }
  }
  

  toggleFilter() {
    this.openfilter = !this.openfilter;
  }

  clearSyncDate(){
    this.syncDate = null;
  }

  clearStartDate(){
    this.startDate = null;
  }
  clearEndDate(){
    this.endDate = null;
  }
}
