<div class="title-container mb-3">
  <div class="title">
    Customer
  </div>
  <div class="close-btn">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<hr>

<div class="form-group mb-3">
  <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"
    [(ngModel)]="customerSearch" name="'contactsearch" id="contactsearch" (ngModelChange)="searchChange()">
</div>

<div style="height: 340px; overflow-y: auto;">
  <div *ngIf="gettingCustomerList" class="nodata">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div>
    <ul class="list-group" *ngIf="!gettingCustomerList && customerList.length > 0">
      <div class="profile-container" *ngFor="let user of customerList">
        <span (click)="close(user)">
          <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
            [lazyLoad]="user.photo || '../../../assets/images/profile.png'">
        </span>
        <div class="profile-item-container" (click)="close(user)">
          <div class="profile-item">
            <div class="username" style="cursor: pointer;">
              <b>{{user.name || user.phno}}</b>
            </div>
          </div>
        </div>

      </div>
    </ul>
    <div *ngIf="!gettingCustomerList && customerList.length == 0"
      class="d-flex align-items-center justify-content-center" style="height: 100px; width: 100%;">
      <div>
        No Contacts
      </div>
    </div>
  </div>

</div>