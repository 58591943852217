<div class="title-container mb-3">
  <div class="title">
    မိသားစုအသင်းဝင်ရွေးရန်
  </div>
  <div class="close-btn">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<hr>

<div class="input-group mb-2 me-2" style="width: 300px !important">
  <input class="form-control" type="text" placeholder="&#xf002; Search with Name" aria-label="Search"
    style="font-family: FontAwesome, Ubuntu" [(ngModel)]="memberSearch  ">
  <button class="btn btn-custom me-2" matTooltip="Refresh" (click)="searchByName()">
    <mat-icon>refresh</mat-icon>
  </button>
</div>

<div style="height: 400px; overflow-y: hidden;">
  <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
    (scrolled)="onScrolledDown()" class="chat-container" style="height: 390px !important;position: relative;" #chatPS>
    <ul class="list-group" *ngIf="!gettingMemberList && memberList.length > 0">
      <div class="profile-container" *ngFor="let member of memberList">
        <span>
          <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
            [lazyLoad]="member.profileUrl? member.profileUrl : '../../../assets/images/profile.png'">
        </span>
  
        <label class="d-flex align-items-center justify-content-center">
          <div class="profile-item-container">
            <div class="profile-item">
              <div class="membername" style="cursor: pointer;">
                <b>{{member.name}}</b>

              </div>
              <div style="color: grey;
                                  margin-top: 1px;
                                  font-size: 13.5px;" *ngIf="member.membername != ''">
                {{member.memberid}}{{member.newmemberid ? ', ' + member.newmemberid : ''}}</div>
            </div>
          </div>
          <input type="checkbox" class="form-check-input align-items-end" [id]="member.primarykey"
            [checked]="member.checked" (change)="selectMember($event,member)">
        </label>

      </div>
    </ul>



    <div *ngIf="!gettingMemberList && memberList.length == 0" class="d-flex align-items-center justify-content-center"
      style="height: 100px; width: 100%;">
      <div>
        No members
      </div>
    </div>

  </div>
</div>

<div class="close-save-button justify-content-end mt-3">
  <button type="button" class="btn btn-custom me-2" (click)="close('')">
    Cancel
  </button>
  <app-button-loader (click)="done()" [button_text]="'Done'" [load_button_text]="''"></app-button-loader>
</div>