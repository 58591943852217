<div class="fix" *ngIf="!isAudience && !isMeetingLink && !selectingClassCode">
  <div class="title-container mb-3">
    <div class="title">
      {{post ? isDuplicate? 'Create Post' : 'Edit Post' : 'Create Post'}}
    </div>
    <div class="close-btn">
      <button mat-icon-button mat-dialog-close [disabled]="saveLoading">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div *ngIf="editLoading" class="loader">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="mt-2" *ngIf="!editLoading">
    <div class="profile-container">
      <span *ngIf="postAsDomain">
        <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
          [lazyLoad]="domainImage ? domainImage : '../../../assets/images/post_admin.png'">
      </span>
      <span *ngIf="postAsOrg">
        <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
          [lazyLoad]="finalChooseOrgImage ? finalChooseOrgImage : '../../../assets/images/post_admin.png'">
      </span>
      <span *ngIf="!postAsDomain && !postAsOrg">
        <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
          [lazyLoad]="allinoneService.getProfileImage() ? allinoneService.getProfileImage() : '../../../assets/images/profile.png'">

      </span>
      <div class="profile-item-container">
        <div class="profile-item">
          <div class="username" *ngIf="postAsDomain">
            <b>{{domainName}}</b>
            <mat-icon class="close-domain" (click)="removeDomainPost()" style="margin-left: 10px;cursor: pointer;">close
            </mat-icon>

          </div>
          <div class="username" *ngIf="postAsOrg">
            <p class="username-text me-1">
              <span><b>{{getOrgName(finalChooseOrg)}}</b></span>
              <span [innerHTML]="tagPeople()"></span>
              <span *ngIf="finalChoosePeople.length > 2">
                <b class="viewOtherTag">
                  {{finalChoosePeople.length - 1}} others
                </b>
              </span>
            </p>
            <mat-icon class="close-domain" (click)="removeOrgPostAndTag()" style="margin-left: 10px;cursor: pointer;"
              title="Discard">close</mat-icon>
          </div>
          <div class="username" *ngIf="!postAsDomain && !postAsOrg">
            <p class="username-text me-1">
              <span><b>{{allinoneService.profile.username ? allinoneService.profile.username :
                  allinoneService.profile.userid}}</b></span>
              <span [innerHTML]="tagPeople()"></span>
              <span *ngIf="finalChoosePeople.length > 2">
                <b class="viewOtherTag" (click)="openAudience()">
                  {{finalChoosePeople.length - 1}} others
                </b>
              </span>
              <span *ngIf="finalAudienceForm.postfororg">
                <i class="fas fa-at mx-2"></i><b>{{getOrgName(finalChooseOrg)}}</b>
              </span>
              <span *ngIf="finalAudienceForm.channelid">
                <i class="fas fa-at mx-2"></i><b>{{groupname}}</b>
              </span>
            </p>
            <mat-icon *ngIf="finalChoosePeople.length > 0 || finalAudienceForm.postfororg || finalAudienceForm.channelid" class="close-domain"
              (click)="removeTagAndOrg()" style="margin-left: 0px !important;cursor: pointer;" title="Remove Tag">close
            </mat-icon>
          </div>
          <div class="d-flex align-items-center">
            <div class="audience-btn d-flex align-items center" (click)="openAudience()" *ngIf="!postAsDomain">
              <div *ngIf="finalAudienceForm.postas == '' && !finalAudienceForm.channelid">
                <i class="{{postSettingList[finalAudienceForm.postsettingindex].icon}}"></i>
              </div>
              <div *ngIf="finalAudienceForm.postas == '' && finalAudienceForm.channelid">
                <i class = "fas fa-comment-alt"></i>
              </div>
              <div *ngIf="finalAudienceForm.postas">
                <i class="{{postSettingList[finalAudienceForm.postsettingindexorg].orgicon}}"></i>
              </div>
              <div>
                {{ finalAudienceForm.postas ? postSettingList[finalAudienceForm.postsettingindexorg].orgname :
                finalAudienceForm.channelid? 'Group': postSettingList[finalAudienceForm.postsettingindex].name}}
              </div>
              <div>
                <i class="fa fa-sort-desc"></i>
              </div>
            </div>
            <div class="audience-btn d-flex align-items center" *ngIf="postAsDomain" (click)="openAudience()"
              style="background-color: var(--disabled-color);">
              <div>
                <i class="{{postSettingList[0].icon}}"></i>
              </div>
              <div>
                {{ postSettingList[0].name}}
              </div>
              <div>
                <i class="fa fa-sort-desc"></i>
              </div>
            </div>
            <i class="fas fa-cog" style="margin-left: 4px;
            margin-top: 4px;
            color: #778798;
            cursor: pointer;" (click)="openAudience()"></i>
          </div>
        </div>
        <div class="post-menu" style="margin-top: -10px;">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="menu" xPosition="before">
            <input style="display: none" type="file" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput
              multiple>
            <button mat-menu-item (click)="openAudience()">
              <i class="fas fa-cog" style="margin-left: 4px;"></i>
              <span>Settings</span>
            </button>
            <button mat-menu-item (click)="hiddenfileinput.click()">
              <i class="far fa-images"></i>
              <span>Images</span>
            </button>
            <input style="display: none" type="file" accept="application/pdf" (change)="onPdfSelect($event)"
              #hiddenpdffileinput multiple>
            <button mat-menu-item (click)="hiddenpdffileinput.click()">
              <i class="fa fa-file-pdf-o"></i>
              <span>Upload PDF</span>
            </button>
            <button mat-menu-item (click)="openMeetingLinkPage()">
              <!-- <i class="fas fa-cog" style="margin-left: 4px;"></i> -->
              <mat-icon style="font-size: 20px !important; height: 20px !important; width: 12px !important;">note_add
              </mat-icon>
              <span>Type</span>
            </button>
            <!-- <button *ngIf="isDomainAdmin" mat-menu-item (click)="clickPostDomain()">
              <span>
                <img class="menu-admin-image" [defaultImage]="'../../../assets/images/post_admin.png'"
                  [lazyLoad]="domainImage ? domainImage : '../../../assets/images/post_admin.png'">
              </span>
              <span>Post as {{domainName}}</span>
            </button> -->
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>



<perfect-scrollbar *ngIf="!isAudience && !editLoading && !isMeetingLink && !selectingClassCode"
  style=" max-height: calc(100% - 140px);" [config]="config" [scrollIndicators]="true" (click)="focus()">
  <!-- <div *ngIf = "!isAudience" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    [scrollWindow]="false" [perfectScrollbar]="config" style=" max-height: calc(100% - 140px);"> -->
  <textarea class="textarea" autosize [minRows]="1" [(ngModel)]="postForm.postnote" placeholder="Create post here ..."
    #postnote autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
    </textarea>
  <div *ngIf="meetingLinkAdded" class="meeting-container">
    <div style="display: flex; justify-content: space-between;">
      <div>
        <!-- <i class="fas fa-calendar-alt"></i> -->
        <p style="margin: 0 !important;">
          <span style="font-weight: bold; margin-top: 5px; margin-bottom: 5px;font-size: 15px;color: var(--main-color);"
            [innerHTML]="eventName()"></span>
          <span *ngIf="finalMeetingForm.eventtype == '4' && !finalMeetingForm.daterange">
            <span *ngIf="finalMeetingForm.voting == 'true'">
              &nbsp;(Open)
            </span>
            <span *ngIf="finalMeetingForm.voting == 'false'">
              &nbsp;(Close)
            </span>
          </span>
        </p>
      </div>
      <div style="display: flex;">
        <mat-icon class="close-domain" style="margin-left: 10px;cursor: pointer;" (click)="editMeetingLink()">
          edit</mat-icon>
        <mat-icon class="close-domain" style="margin-left: 10px;cursor: pointer;" (click)="removeMeetingLink()">
          close</mat-icon>
      </div>
    </div>
    <div>
      <div style="margin-top: 5px;">
        {{finalMeetingForm.venue}}
      </div>
      <div style="margin-top: 5px;" *ngIf="finalMeetingForm.eventtype != '3' && finalMeetingForm.eventtype != '4'">
        {{allinoneService.formatDBToShow(finalMeetingForm.startdate)}} -
        {{allinoneService.formatTimeToShow(finalMeetingForm.starttime)}} to
        {{allinoneService.formatTimeToShow(finalMeetingForm.endtime)}}
        <span *ngIf="finalMeetingForm.eventtype == '1'">on {{weeklyDaylong }} (Weekly)</span>
      </div>
      <div style="margin-top: 5px;"
        *ngIf="(finalMeetingForm.eventtype == '3') || (finalMeetingForm.eventtype == '4' && finalMeetingForm.daterange)">
        <div>
          {{allinoneService.formatDBToShow(finalMeetingForm.startdate)}} -
          {{allinoneService.formatDBToShow(finalMeetingForm.enddate)}}
        </div>
        <div>
          {{allinoneService.formatTimeToShow(finalMeetingForm.starttime)}} to
          {{allinoneService.formatTimeToShow(finalMeetingForm.endtime)}}
        </div>
      </div>
      <div style="margin-top: 5px; white-space: pre-line; word-break: break-all;">
        {{finalMeetingForm.description}}
      </div>
      <div style="margin-top: 5px; margin-bottom: 5px; white-space: pre-line; word-break: break-all;" *ngIf="finalMeetingForm.link">
        <span class="join-url" (click)="clickMetting(finalMeetingForm.link)">{{finalMeetingForm.link}}</span>
      </div>
      <div *ngIf="finalMeetingForm.ics && finalMeetingForm.eventtype == '1'">
        <div class="show-ics" (click)="allinoneService.addtoCalendar(finalMeetingForm, true)">
          <div style="padding: 10px 10px 10px 10px;display: flex;align-items: center;">
            <mat-icon>date_range</mat-icon>
            <span style="margin-left: 10px;">Add to Calendar</span>
          </div>
        </div>
      </div>
      <div style="margin-top: 5px;">
        <div *ngIf="finalMeetingForm.answertype == '1'">
          <span style="font-weight: bold;">Standard Answers </span><span>(Vote Limit - 1)</span>
          <div style="margin-top: 3px;">
            <mat-chip-list class="example-chip" *ngIf="confirmChooseDefaultAnswers">
              <mat-chip class="example-box" *ngFor="let ans of confirmChooseDefaultAnswers" style="color: #525050;">
                {{ans.name}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div *ngIf="finalMeetingForm.answertype == '2'">
          <span style="font-weight: bold;">Custom Answers </span> <span>(Vote Limit -
            {{this.maxOptionFinal}})</span>
          <div style="margin-top: 3px;">
            <mat-chip-list class="example-chip" *ngIf="confirmCustomAnswers.length <= 3">
              <mat-chip class="example-box" *ngFor="let ans of confirmCustomAnswers" style="color: #525050;">
                {{ans.name}}
              </mat-chip>
            </mat-chip-list>
            <mat-chip-list class="example-chip" cdkDropListOrientation="horizontal"
              *ngIf="confirmCustomAnswers.length > 3">
              <div *ngFor="let ans of confirmCustomAnswers; let i = index">
                <mat-chip class="example-box" style="color: #525050;" *ngIf="i < 3">
                  {{ans.name}}
                </mat-chip>
              </div>
              <mat-chip class="example-box" style="color: #525050;">
                +{{confirmCustomAnswers.length - 3}} Answers
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="finalMeetingForm.eventtype == '7'" class="meeting-container">
    <div class="d-flex justify-content-end mb-1">
      <mat-icon class="close-domain" style="margin-left: 10px;cursor: pointer;" (click)="editMeetingLink()">
        edit</mat-icon>
      <mat-icon class="close-domain" style="margin-left: 10px;cursor: pointer;" (click)="removeVideoLink()">
        close</mat-icon>
    </div>
    <vm-player #player>
      <vm-youtube cookies="true" [attr.video-id]="finalMeetingForm.videourl | getVideoId"></vm-youtube>
      <vm-default-ui>
      </vm-default-ui>
    </vm-player>
  </div>
  <div class="pdflist">
    <div *ngFor="let file of pdfList; let index = index">
      <div class="pdf-container">
        <div class="pdf-filename">
          <i class="fa fa-file-pdf-o"></i>{{file.filename}}
        </div>
        <div class="pdf-action">
          <button mat-icon-button class="icon-button" (click)="removePdf(index)">
            <mat-icon class="icon">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="row">
      <div class="img-container" *ngFor="let item of imageList; let index = index">
        <div class="img-border card">
          <img [src]="item.previewurl">
          <mat-icon class="close" (click)="removeImage(index)">close</mat-icon>
        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>
<!-- </div> -->
<div *ngIf="!isAudience && !editLoading && !isMeetingLink && !selectingClassCode">
  <button class="btn btn-custom"
    *ngIf="postForm.postnote == '' && imageList.length == 0 && pdfList.length == 0 && !meetingLinkAdded && finalMeetingForm.eventtype != '7'" disabled>
    {{post? isDuplicate ? 'Publish' : 'Update' : 'Publish'}}</button>
  <app-button-loader *ngIf="postForm.postnote || imageList.length > 0 || pdfList.length > 0 || meetingLinkAdded || finalMeetingForm.eventtype == '7'"
    (click)="submitPost()" [button_text]="post ? isDuplicate ? 'Publish' : 'Update' : 'Publish'"
    [load_button_text]="post ? isDuplicate ? 'Publish' : 'Update' : 'Publish'" [isLoading]="saveLoading"
    [width]="'100'">
  </app-button-loader>
</div>


<!-- Tag -->
<div class="fix" *ngIf="isAudience">
  <div class="title-container mb-3">
    <div class="back-btn">
      <button mat-icon-button (click)="closeAudience()">
        <!-- <i class="fa fa-arrow-left"></i> -->
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="title">
      Settings
    </div>
    <div class="close-btn">
      <button mat-icon-button (click)="finishAudience()">
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-tab-group>
    <mat-tab label="Post As">
      <div class="my-3">
        <select class="form-select" aria-label="Post As" id="postas" name="postas" (change)="postAsTypeChange($event)"
          [(ngModel)]="tempAudienceForm.index" required #eventtype="ngModel">
          <option *ngFor="let item of postAsList; let index = index" [value]="index">
            {{item.orgtag ? item.username + ' - ' + item.name :
            item.name}}
          </option>
        </select>
      </div>
      <div *ngIf="tempAudienceForm.postas">
        <div class="my-3">
          <select class="form-select" aria-label="Post Setting" id="postsetting" name="postsetting"
            *ngIf="tempAudienceForm.postfororg" [(ngModel)]="tempAudienceForm.postsettingindexorg" required>
            <option *ngFor="let item of postForOrgSettingList; let j = index" [value]="j"
              [selected]="j == tempAudienceForm.postsettingindexorg">
              {{item.orgname}}</option>
          </select>
          <select class="form-select" aria-label="Post Setting" id="postsetting" name="postsetting"
            *ngIf="!tempAudienceForm.postfororg" [(ngModel)]="tempAudienceForm.postsettingindexorg" required>
            <option *ngFor="let item of postSettingList; let j = index" [value]="j"
              [selected]="j == tempAudienceForm.postsettingindexorg">
              {{item.orgname}}</option>
          </select>
        </div>
        <div class="mb-3" *ngIf="tempAudienceForm.postsettingindexorg == 0 && !tempAudienceForm.domain">
          {{classCodeList.length}} member types
        </div>
        <div class="mb-3" *ngIf="tempAudienceForm.postsettingindexorg == 1 && !tempAudienceForm.domain">
          <perfect-scrollbar *ngIf="classCodeList.length > 0" class="classcodelist" fxFlex="auto" [config]="config"
            [scrollIndicators]="true">
            <div *ngIf="!gettingClassCode">
              <div class="class-code-container" (click)="checkAll()">
                <div>
                  <b>{{isCheckAll ? 'Unselect All' : 'Select All'}}</b>
                </div>
                <div>
                  <input class="form-check-input" type="checkbox" id="checkbox" [(ngModel)]="isCheckAll">
                </div>
              </div>
              <mat-divider></mat-divider>
              <div *ngFor="let classcode of classCodeList">
                <div class="class-code-container" (click)="addToClassCode(classcode)">
                  <div>
                    {{classcode.name}}
                  </div>
                  <div>
                    <input class="form-check-input" type="checkbox" id="checkbox" [(ngModel)]="classcode.checked">
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
      <div *ngIf="tempAudienceForm.postas == '' && !tempAudienceForm.domain">
        <div class="mb-3">
          <select class="form-select" aria-label="Post Setting" id="postsetting" name="postsetting"
            [(ngModel)]="tempAudienceForm.channelid" required [disabled]="gettingGroupList" (change)="postAsGroupChange($event)">
            <option value="">
              -
            </option>
            <option *ngFor="let item of groupList; let gi = index" [value]="item.channelid">
              {{item.channelname}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingGroupList"></mat-progress-bar>
        </div>
        <div *ngIf="!tempAudienceForm.channelid">
          <div class="mb-3" style="font-weight: bold;
          font-size: 16px;">
            Who can see your post?
          </div>
          <div *ngFor="let item of postSettingList; let i = index">
            <div class="post-setting-container">
              <label class="tag-label" [for]="item.id">
                <i class="{{item.icon}}"></i>{{item.name}}
              </label>
              <input class="form-check-input org-tag-input" type="radio" name="audiencesetting" [id]="item.id"
                [value]="i" [(ngModel)]="tempAudienceForm.postsettingindex">
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Tag" [disabled]="tempAudienceForm.postsettingindex == 3 || tempAudienceForm.domain">
      <div *ngIf="tempAudienceForm.tag == 'people'">
        <ul class="list-group" *ngIf="gettingContactList">
          <li class="list-group-item" *ngFor="let item of [0,1,2,3,4,5,6,7]">
            <div class="profile-item">
              <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                                'height.px': 40}"></ngx-skeleton-loader>
              <div style="margin-top: auto; width: 100%;">
                <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'height.px': 30}">
                </ngx-skeleton-loader>
              </div>
            </div>
          </li>
        </ul>


        <div class="mt-2" *ngIf="!gettingContactList">
          <div class="input-group">
            <input class="form-control" type="text" placeholder="Search" aria-label="Search" (focus)="isFocus = true"
              (blur)="isFocus = false" [(ngModel)]="searchData"
              [ngClass]="{'search-input' : !searchData, 'search-input-with-clear' : searchData }">
            <button class="btn btn-outline-secondary search-clean-btn " type="button" *ngIf="searchData"
              (click)="clear()" [ngClass]="{'clear' : isFocus, 'clear-not-focus': !isFocus}">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <perfect-scrollbar *ngIf="choosePeoples.length > 0" style="position: relative;" fxFlex="auto"
            [config]="config" [scrollIndicators]="true">

            <div class="list-group-item member-setup">
              <div class="member-wrapper" *ngFor="let c of choosePeoples;let i = index;"
                [ngClass]="'member-wrapper-' + i">
                <div class="image-container">
                  <img class="chat-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                    [lazyLoad]="c.imagename ? c.imagename : '../../../assets/images/profile.png' ">
                  <span class="member-close" (click)="removePeople(c)">
                    <mat-icon>close</mat-icon>
                  </span>
                </div>
                <div class="info-container">
                  <span *ngIf="c.username"> {{splitUserName(c.username)}}</span>
                  <span *ngIf="c.username == ''">{{c.userid}}{{c.newuserid ? ', ' + c.newuserid : ''}}</span>
                </div>
              </div>
            </div>
          </perfect-scrollbar>


          <!-- <perfect-scrollbar style="position: relative;max-height: calc(100% - 140px);" fxFlex="auto" [config]="config"
                        [scrollIndicators]="true"> -->
          <div>
            <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()"
              [scrollWindow]="false" [perfectScrollbar]="config"
              [attr.data-check]="choosePeoples.length > 0 ? '1' : '0'" class="contact-container">
              <div>
                <div *ngFor="let item of contactsListToShow | filter:searchData:type; let index = index"
                  [ngClass]="{'noborder': index == 0}">

                  <div class="list-group-header">
                    {{item.key}}
                  </div>


                  <li class="list-group-item" *ngFor="let c of item.contacts; let index = index"
                    (click)="addToPeople(c)">
                    <div class="profile-item">
                      <img class="chat-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="c.imagename ? c.imagename : '../../../assets/images/profile.png' ">

                      <div class="profile-container">
                        {{c.username}}
                        <span>{{c.userid}}{{c.newuserid ? ', ' + c.newuserid : ''}}</span>
                      </div>

                      <div class="checkbox-container">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="c.checked" id="flexCheckDefault">
                      </div>
                    </div>
                  </li>

                </div>
                <ul class="list-group" *ngIf="gettingMoreContact">
                  <li class="list-group-item" *ngFor="let item of [0,1]">
                    <div class="profile-item">
                      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                                            'height.px': 40}"></ngx-skeleton-loader>
                      <div style="margin-top: auto; width: 100%;">
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'height.px': 30}">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- </perfect-scrollbar> -->
          </div>
        </div>
      </div>
      <div *ngIf="tempAudienceForm.tag == 'org'">
        <div class="mt-2">
          <div class="input-group">
            <input class="form-control" type="text" placeholder="Search" aria-label="Search" (focus)="isFocus = true"
              (blur)="isFocus = false" [(ngModel)]="searchData"
              [ngClass]="{'search-input' : !searchData, 'search-input-with-clear' : searchData }">
            <button class="btn btn-outline-secondary search-clean-btn " type="button" *ngIf="searchData"
              (click)="clear()" [ngClass]="{'clear' : isFocus, 'clear-not-focus': !isFocus}">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div *ngFor="let org of orgListForTag | filter:searchData:type; let i = index">
            <div class="org-tag-container" *ngIf="tempAudienceForm.postas != org.orgid">
              <label class="org-tag-label" [for]="org.orgid">
                {{org.name}}
              </label>
              <input class="form-check-input org-tag-input" type="radio" name="orgTagRadio" [id]="org.orgid"
                [value]="org.orgid" [(ngModel)]="tagOrg" [checked]="tagOrg == org.orgid">
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="fix" *ngIf="isMeetingLink && !customAnswer && !defaultAnswer">
  <div class="title-container mb-3">
    <div class="back-btn">
      <button mat-icon-button (click)="closeMeetingLinkPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="title">
      <!-- Add Meeting Link -->
      Type
    </div>
    <div class="close-btn">
      <button mat-icon-button (click)="finishMeetingLink($event)">
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="mt-3">
    <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
      <div class="mb-3">
        <div>
          <!-- <label for="eventtype" class="form-label">Event Type</label> -->
          <select class="form-select" aria-label="Select Type" id="eventtype" name="eventtype"
            (change)="eventTypeChanged($event)" [(ngModel)]="meetingForm.eventtype" required #eventtype="ngModel"
            [ngClass]="{ 'is-invalid': meetingFormSubmitted && eventtype.invalid }">
            <option *ngFor="let type of eventTypeList" value="{{type.id}}">
              {{type.name}}</option>
          </select>
        </div>
        <div class="invalid-feedback"
          *ngIf="eventtype.invalid || (eventtype.dirty || eventtype.touched) && meetingFormSubmitted">
          <div *ngIf="eventtype.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
      <div *ngIf="meetingForm.eventtype == '0'">
        <div class="mb-3">
          <span>Comment</span>
          <mat-slide-toggle style="margin-left: 27px;" [(ngModel)]="meetingForm.comment" color="primary">
          </mat-slide-toggle>
        </div>
        <div class="mb-3" *ngIf="!finalAudienceForm.hidenoti">
          <span style="margin-right: 15px;">Notification</span>
          <mat-slide-toggle [(ngModel)]="meetingForm.noti" color="primary"></mat-slide-toggle>
        </div>
      </div>

      <div *ngIf="meetingForm.eventtype != '0' && meetingForm.eventtype != '7'">
        <div class="mb-3">
          <label for="desc" class="form-label">Description</label>

          <textarea autosize [minRows]="2" class="form-control " [(ngModel)]="meetingForm.description" id="desc"
            name="desc" placeholder="" required #desc="ngModel" autocomplete="off" autocorrect="off"
            autocapitalize="off" spellcheck="false" [ngClass]="{ 'is-invalid': meetingFormSubmitted && desc.invalid }">
                    </textarea>

          <div class="invalid-feedback" *ngIf="desc.invalid || (desc.dirty || desc.touched) && meetingFormSubmitted">
            <div *ngIf="desc.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
        <div class="mb-3"
          *ngIf="meetingForm.eventtype == '1' || meetingForm.eventtype == '2' || meetingForm.eventtype == '3' || meetingForm.eventtype == '6'">
          <label for="link" class="form-label">Online URL</label>

          <textarea autosize [minRows]="2" class="form-control " [(ngModel)]="meetingForm.link" id="link" name="link"
            required #link="ngModel" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
                    </textarea>
        </div>
        <div class="mb-3" *ngIf="meetingForm.eventtype != '4'">
          <label for="venue" class="form-label">Venue</label>

          <textarea autosize [minRows]="2" class="form-control " [(ngModel)]="meetingForm.venue" id="venue" name="venue"
            autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
                    </textarea>
        </div>
        <div *ngIf="meetingForm.eventtype != '4'">
          <div class="row">
            <div class="mb-3" [ngClass]="{'col-md-6' : meetingForm.eventtype == '3'}">
              <label for="startdate" class="form-label">Start Date</label>
              <input type="date" class="form-control" id="startdate" name="startdate"
                [(ngModel)]="meetingForm.startdate" max="9999-12-31" required #startdate="ngModel">
            </div>
            <div class="mb-3 col-md-6" *ngIf="meetingForm.eventtype == '3'">
              <label for="enddate" class="form-label">End Date</label>
              <input type="date" class="form-control" id="enddate" name="enddate" max="9999-12-31" [(ngModel)]="meetingForm.enddate"
                required #enddate="ngModel">
            </div>
          </div>
          <div class="form-check mb-3" *ngIf="meetingForm.eventtype == '1' || meetingForm.eventtype == '6'">
            <input (click)="ischeckWeekly($event)" class="form-check-input" type="checkbox" id="remember"
              [(ngModel)]="meetingForm.weekly" value="meetingForm.weekly" name="Weekly">
            <label class="form-check-label" for="ICS">
              Weekly
            </label>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="starttime" class="form-label">From</label>
              <input type="time" class="form-control" id="starttime" name="starttime" step="1"
                [(ngModel)]="meetingForm.starttime" required #starttime="ngModel">
            </div>
            <div class="col-md-6 mb-3">
              <label for="endtime" class="form-label">To</label>
              <input type="time" class="form-control" id="endtime" name="endtime" step="1"
                [(ngModel)]="meetingForm.endtime" required #endtime="ngModel">
            </div>
          </div>
        </div>
        <div class="form-check mb-3" *ngIf="meetingForm.eventtype == '1' || meetingForm.eventtype == '6'">
          <input class="form-check-input" type="checkbox" id="remember" [(ngModel)]="meetingForm.ics" name="ICS">
          <label class="form-check-label" for="ICS">
            ICS
          </label>
        </div>
        <div class="d-flex flex-wrap">
          <div class="form-check me-5">
            <label class="radio-inline">
              <input class="form-check-input" type="radio" name="answerType" id="answerType1"
                [(ngModel)]="meetingForm.answertype" value="1">Standard
            </label>
          </div>
          <div class="form-check" *ngIf="meetingForm.eventtype == '4'">
            <label class="radio-inline">
              <input class="form-check-input" type="radio" name="answerType" id="answerType2"
                [(ngModel)]="meetingForm.answertype" value="2">Custom
            </label>
          </div>
        </div>
        <div *ngIf="meetingForm.answertype == '1' || meetingForm.eventtype == '6'">
          <button class="btn btn-custom" (click)="openDefaultAnswer()" style="width: 50% !important;">
            Vote Options
          </button>
          <p>
            <span class="mt-2" style="color: gray;">
              {{finalChooseDefaultAnswers.length}} option<span *ngIf="finalChooseDefaultAnswers.length > 1">s</span>.
            </span>
          </p>
        </div>
        <div *ngIf="meetingForm.answertype == '2'">
          <button class="btn btn-custom" (click)="openCustomAnswer()" style="width: 50% !important;">
            Vote Options
          </button>
          <p>
            <span class="mt-2" style="color: gray;">
              <span *ngIf="customFieldsFinal.length > 1">{{maxOptionFinal}} of</span> {{customFieldsFinal.length}}
              option<span *ngIf="customFieldsFinal.length > 1">s</span>.
            </span>
          </p>
        </div>
        <div class="form-check setting-h">
          <input class="form-check-input" type="checkbox" id="showcount" [(ngModel)]="meetingForm.showcount">
          <label class="form-check-label" for="showcount">
            Show Count
          </label>
        </div>
        <div class="form-check setting-h">
          <input class="form-check-input" type="checkbox" id="showresult" [(ngModel)]="meetingForm.showresult">
          <label class="form-check-label" for="showresult">
            Show Result
          </label>
        </div>
        <div class="form-check setting-h">
          <input class="form-check-input" type="checkbox" id="shownamelist" [(ngModel)]="meetingForm.shownamelist">
          <label class="form-check-label" for="shownamelist">
            Show Name List
          </label>
        </div>
        <div class="form-check setting-h" *ngIf="!finalAudienceForm.hidenoti">
          <input class="form-check-input" type="checkbox" id="noti" [(ngModel)]="meetingForm.noti">
          <label class="form-check-label" for="noti">
            Notification
          </label>
        </div>
        <div class="form-check setting-h">
          <input class="form-check-input" type="checkbox" id="comment" [(ngModel)]="meetingForm.comment">
          <label class="form-check-label" for="comment">
            Comment
          </label>
        </div>
        <div *ngIf="meetingForm.eventtype == '4'">
          <div class="form-check setting-h">
            <input class="form-check-input" type="checkbox" id="mandatoryresp" [(ngModel)]="meetingForm.mandatoryresp">
            <label class="form-check-label" for="mandatoryresp">
              Mandatory Response
            </label>
          </div>
          <div class="form-check setting-h">
            <input class="form-check-input" type="checkbox" id="reminder" [(ngModel)]="meetingForm.reminder">
            <label class="form-check-label" for="reminder">
              Reminder
            </label>
          </div>
          <div class="form-check setting-h">
            <input class="form-check-input" type="checkbox" id="daterange" [(ngModel)]="meetingForm.daterange">
            <label class="form-check-label" for="daterange">
              Date Range
            </label>
          </div>
          <div *ngIf="!meetingForm.daterange" style="margin-left: 22px;">
            <div class="d-flex flex-wrap">
              <div class="form-check me-5">
                <label class="radio-inline">
                  <input class="form-check-input" type="radio" name="voting" id="openvoting"
                    [(ngModel)]="meetingForm.voting" value="true">Open Poll
                </label>
              </div>
              <div class="form-check" *ngIf="meetingForm.eventtype == '4'">
                <label class="radio-inline">
                  <input class="form-check-input" type="radio" name="voting" id="closevoting"
                    [(ngModel)]="meetingForm.voting" value="false">Closed Poll
                </label>
              </div>
            </div>
            <!-- <div style = "margin-left : 22px" class = "d-flex">
                            Voting&nbsp;&nbsp;<mat-slide-toggle [(ngModel)] = "meetingForm.voting"></mat-slide-toggle>
                        </div> -->
          </div>
          <div class="row" *ngIf="meetingForm.daterange">
            <div class="row">
              <div class="mb-3 col-md-6">
                <label for="startdate" class="form-label">Start Date</label>
                <input type="date" class="form-control" id="startdate" name="startdate"
                  [(ngModel)]="meetingForm.startdate" max="9999-12-31" required #startdate="ngModel">
              </div>
              <div class="mb-3 col-md-6">
                <label for="enddate" class="form-label">End Date</label>
                <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="meetingForm.enddate"
                  required max="9999-12-31" #enddate="ngModel">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="starttime" class="form-label">From</label>
                <input type="time" class="form-control" id="starttime" name="starttime" step="1"
                  [(ngModel)]="meetingForm.starttime" required #starttime="ngModel">
              </div>
              <div class="col-md-6 mb-3">
                <label for="endtime" class="form-label">To</label>
                <input type="time" class="form-control" id="endtime" name="endtime" step="1"
                  [(ngModel)]="meetingForm.endtime" required #endtime="ngModel">
              </div>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="meetingForm.eventtype == '7'">
        <div class="mb-3">
          <label for="link" class="form-label">Video URL</label>

          <textarea autosize [minRows]="2" class="form-control " [(ngModel)]="meetingForm.videourl" id="link" name="link"
            required #link="ngModel" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
                    </textarea>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</div>


<div class="fix" *ngIf="defaultAnswer">
  <div class="title-container mb-3">
    <div class="back-btn">
      <button mat-icon-button (click)="closeDefaultAnswer()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="title">
      Response Options
    </div>
    <div class="close-btn">
      <button mat-icon-button (click)="finishDefaultAnswer()">
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto">
    <div *ngFor="let ans of defaultAnswers ; let i = index">
      <div class="class-code-container" (click)="addToDefaultAns(ans)" style="padding-left: 10px;">
        <div>
          {{ans.name}}
        </div>
        <div>
          <input class="form-check-input" type="checkbox" id="checkboxfordefaultans" [(ngModel)]="ans.checked">
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>

<div class="fix" *ngIf="customAnswer">
  <div class="title-container mb-3">
    <div class="back-btn">
      <button mat-icon-button (click)="closeCustomAnswer()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="title">
      Custom Options
    </div>
    <div class="close-btn">
      <button mat-icon-button (click)="finishCustomAnswer()">
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>

  <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
    <div *ngFor="let field of customFields; let i = index">
      <div class="mb-3">
        <div class="cus-input-group">
          <input type="text" class="form-control" [(ngModel)]="field.name" #name="ngModel" name="field{{i}}"
            value="{{field}}" placeholder="***" (keydown.enter)="addCusField()"
            style="border-radius: 5px 0px 0px 5px !important;">
          <button type="button" class="btn btn-custom" (click)="removeCusField(i)"
            style="width: 10% !important;border-radius: 0px 5px 5px 0px; background-color: rgb(200, 21, 21) !important;">
            <mat-icon>remove_circle</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <button class="btn btn-custom btn-remove-ans" (click)="addCusField()">
      Add New <mat-icon style="margin-left: 10px;">add_circle</mat-icon>
    </button>
    <div class="row" style="margin-top: 10px;">
      <label for="max" class="col-md-5 col-form-label">Max Choice</label>
      <div class="col-md-7">
        <select class="form-select" aria-label="Select Max Choice" id="max" name="max" [(ngModel)]="maxOption">
          <option *ngFor="let max of maxOptionCount" value="{{max}}">
            {{max}}</option>
        </select>
      </div>
    </div>
  </perfect-scrollbar>
</div>