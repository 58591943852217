<div class="fix">
    <div class="title-container mb-3">
        <div class="title">
            Profile
        </div>
        <div class="close-btn">
            <!-- <button mat-icon-button mat-dialog-close [disabled]="saveLoading"> -->
            <button mat-icon-button mat-dialog-close [disabled]="isBlocking">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>

    <div *ngIf="loading && !error" class="loader">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <div *ngIf="!loading && !error">
        <div class="profile-img-container mt-3">
            <div style="position: relative;">
                <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                    (onFileDropped)="uploadFile($event)">
                    <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="userForm.imgURL ? userForm.imgURL : '../../../assets/images/uploadphoto.png'">

                    <input hidden type="file" #fileInput accept='image/*' (change)="uploadFile1($event)">
                </div>
                <!-- <button mat-icon-button class="remove-drag-image" *ngIf="userForm.imgURL" (click)="removeImage()">
                    <mat-icon>remove_circle</mat-icon>
                </button> -->
            </div>
            <div>
                <b style="font-size: 15px;">{{userForm.userID}}</b>
            </div>
        </div>


        <div class="mb-3">
            <label for="userName" class="form-label">Name</label>
            <input type="text" class="form-control" [(ngModel)]="userForm.username" id="username" name="username"
                placeholder="Enter Name" required #username="ngModel"
                [ngClass]="{ 'is-invalid': profileSubmitted && username.invalid }">

            <div class="invalid-feedback"
                *ngIf="username.invalid || (username.dirty || username.touched) && profileSubmitted">
                <div *ngIf="username.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
        <div class="mb-3">
          <label for="userName" class="form-label">User ID</label>
          <input type="text" class="form-control" [(ngModel)]="userForm.userID" id="userid" name="userid"
              placeholder="Enter User ID" disabled readonly>
        </div>

        <!-- <div class="mb-3">
            <label for="nrc" class="form-label">NRC</label>
            <input type="text" class="form-control" [(ngModel)]="userForm.nrc" id="nrc" name="nrc"
                placeholder="Enter NRC" #nrc="ngModel">
        </div>

        <div class="mb-3">
            <label for="state" class="form-label">State</label>
            <div>
                <select (change)="selectState($event)" class="form-select" aria-label="Select State" id="state"
                    name="state" [(ngModel)]="userForm.state" #state="ngModel" required>
                    <option *ngFor="let st of states" value="{{st.divId}}">
                        {{st.name}}</option>
                </select>
            </div>
        </div>

        <div class="mb-3">
            <label for="region" class="form-label">Region</label>
            <div>
                <select (change)="selectRegion($event)" class="form-select" aria-label="Select Region" id="region"
                    name="region" [(ngModel)]="userForm.region" #region="ngModel" required>
                    <option *ngFor="let rg of regions" value="{{rg.name}}">
                        {{rg.name}}</option>
                </select>
            </div>
        </div>

        <div class="mb-3">
            <label for="township" class="form-label">Township</label>
            <div>
                <select (change)="selectTownship($event)" class="form-select" aria-label="Select Township" id="township"
                    name="township" [(ngModel)]="userForm.township" #township="ngModel" required>
                    <option *ngFor="let ts of townships" value="{{ts.tsId}}">
                        {{ts.name}}</option>
                </select>
            </div>
        </div>

        <div class="mb-3">
            <label for="address" class="form-label">Address</label>
            <textarea class="form-control" id="address" name="address" [(ngModel)]="userForm.address"
                placeholder="Enter Address" rows="3"></textarea>
        </div> -->

        <div class="close-save-button justify-content-end">
            <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()"
                [disabled]="saveLoading">
                Cancel
            </button>

            <button class="btn btn-custom my-1" *ngIf="!saveLoading" (click)="submitProfile()">
                Save
            </button>
            <button class="btn btn-custom" *ngIf="saveLoading" disabled>
                <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
                &nbsp;
                Saving
            </button>

            <!-- <app-button-loader (click)="submitProfile()" style="width: 100px !important;" [button_text]="'Save'"
                class="my-1" [load_button_text]="'Saving'" [isLoading]="saveLoading">
            </app-button-loader> -->
        </div>

       
    </div>
</div>