import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  signinForm = this.formBuilder.group({
    userid: ['', Validators.required],
    password: ['', Validators.required],
  });

  signinFormSubmitted: boolean = false;
  isStillSigningIn: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private dialogRef: MatDialogRef<SigninComponent>,
  ) {}

  ngOnInit(): void {}

  get signinFormControl() {
    return this.signinForm.controls;
  }

  signin() {
    this.signinFormSubmitted = true;
    if (this.signinForm.invalid) {
      //this.messageservice.openSnackBar("Enter the required field", 'warn');
      return;
    } else {
      this.isStillSigningIn = true;

      const data = {
        username: this.signinForm.value.userid,
        password: this.signinForm.value.password,
      };

      this.kunyekService.voterAdminSignin(data).subscribe((res: any) => {
        if (res.returncode == '300') {
          this.allinoneService.setCookie(
            'voting_admin_atoken',
            res.token
          );
          this.isStillSigningIn = false;
          this.dialogRef.close(true)
        } else {
          this.isStillSigningIn = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      });
    }
  }
}
