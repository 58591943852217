import { Component, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent implements OnInit {

  constructor( private allinoneService : AllInOneService) { 
    this.allinoneService.navbar = false
  }

  ngOnDestroy(){
    this.allinoneService.navbar = true
  }

  ngOnInit(): void {
  }

}
