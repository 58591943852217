import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DatePipe, formatDate } from '@angular/common';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { encode } from 'querystring';
import { Subscription } from 'rxjs';
import { MapComponent } from 'src/app/admin/map/map.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { LogisticsCustomerComponent } from '../logistics-customer/logistics-customer.component';
import { LogisticsOrderqrComponent } from '../logistics-orderqr/logistics-orderqr.component';

@Component({
  selector: 'app-logistics-order',
  templateUrl: './logistics-order.component.html',
  styleUrls: ['./logistics-order.component.scss'],
})
export class LogisticsOrderComponent implements OnInit, OnDestroy {
  @ViewChild('hiddenfileinput') hiddenfileinput: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  gettingOrder: boolean = false;
  isLoading: boolean = false;
  isMobileView: boolean = false;
  isOrderNew: boolean = false;
  isFocus: boolean = false;
  nextDisabled: boolean = false;
  orderFormSubmitted: boolean = false;
  isEditing: boolean = false;

  orderSubscription!: Subscription;
  subs = new SubSink();
  orderTimeout: any;

  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'New',
      value: '001',
    },
    {
      name: 'Pending',
      value: '002',
    },
    {
      name: 'On the way',
      value: '003',
    },
    {
      name: 'Transit',
      value: '004',
    },
    {
      name: 'Delivered',
      value: '005',
    },
    {
      name: 'Approved',
      value: '006',
    },
  ];

  orders: any[] = [];
  organizations: any[] = [];
  imageList: any[] = [];

  status: string = '';
  statusname: any = 'All';
  end: string = '';
  searchText: string = '';
  lastPage: any = '';

  itemPerPage: number = 10;
  currentPage: number = 1;
  mobileViewWidth: number = 768;

  formatDateToShow = 'yyyy-MM-dd';
  formatTimeToShow = 'HH:mm:ss';

  now = Date.now();

  oneHourPlus = new Date(
    new Date(this.now).setHours(new Date(this.now).getHours() + 1)
  );

  currentDate = formatDate(
    Date.now(),
    this.formatDateToShow,
    'en-US'
  ).toString();
  currentStartTime = formatDate(
    Date.now(),
    this.formatTimeToShow,
    'en-US'
  ).toString();
  currentEndTime = formatDate(
    new Date(this.oneHourPlus),
    this.formatTimeToShow,
    'en-US'
  ).toString();

  orderForm = {
    id: '', // primary key for order
    domainid: '',
    orgid: '',
    orderid: '',
    desc: '',
    remark: '',
    customername: '',
    customermobile: '',
    recipentcustomersame: false,
    recipientname: '',
    recipientmobile: '',
    fromaddress: '',
    fromlat: '',
    fromlng: '',
    toaddress: '',
    tolat: '',
    tolng: '',
    startdate: this.currentDate,
    enddate: this.currentDate,
    starttime: this.currentStartTime,
    endtime: this.currentEndTime,
    status: '001',
    advance: '',
    collection: '',
    fees: '',
    settlement: '',
    sort: '',
    qrdata: '',
    assign: false,
  };

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private router: Router,
    private datepipe: DatePipe,
    private dialog: MatDialog
  ) {
    if (!this.allinoneService.isAdminOf('org')) {
      this.router.navigateByUrl('/');
    }
  }
  ngOnDestroy(): void {
    this.orderSubscription && this.orderSubscription.unsubscribe();
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    this.orderForm.domainid = this.allinoneService.getDomain().domainid;
    this.organizations = this.allinoneService.orgs;
    this.orderForm.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.getOrder();
  }

  getOrder() {
    this.gettingOrder = true;
    const data = {
      domainid: this.orderForm.domainid,
      orgid: this.orderForm.orgid,
      status: this.status,
      searchorder: this.searchText,
      end: this.end,
    };
    this.orderSubscription && this.orderSubscription.unsubscribe();
    this.orderSubscription = this.kunyekService
      .getLogisticsOrders(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (res.list.length > 0) {
              this.nextDisabled = false;
              this.orders.push.apply(this.orders, res.list);
              this.end = res.end;
              this.orders = this.orders.map((item) => ({
                ...item,
                statusName: this.statusList.find(
                  (x: any) => x.value == item.status
                )?.name,
              }));
              if (res.list.length < 10) {
                this.nextDisabled = true;
                this.lastPage = this.currentPage;
              }
            } else {
              this.currentPage = this.currentPage - 1;
              this.nextDisabled = true;
              this.lastPage = this.currentPage;
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingOrder = false;
        },
        (err) => {
          this.gettingOrder = false;
        }
      );
  }

  onFileSelect(event: any) {
    let files = event.target.files;
    var filetypelist = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files) {
      for (let file of files) {
        if (filetypelist.indexOf(file.type) > -1) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            var temp = {
              file: file,
              previewurl: e.target.result,
              filename: this.allinoneService.getFileName(file),
            };
            this.imageList.push(temp);
          };
          reader.readAsDataURL(file);
        } else {
          this.messageService.openSnackBar(
            "File type '" + file.type + "' is not supported!",
            'warn'
          );
        }
      }
      this.hiddenfileinput.nativeElement.value = '';
    }
  }

  sortData(event: any) {}

  goToLogistics() {
    this.router.navigateByUrl('/logistics');
  }

  newOrder() {
    window.scrollTo(0, 0);
    this.isOrderNew = true;
    this.orderForm.orderid = this.allinoneService.generateRandomID();
  }
  cancel() {
    this.isOrderNew = false;
    this.isEditing = false;
    this.imageList = [];
    this.orderForm = {
      id: '', // primary key for order
      domainid: this.orderForm.domainid,
      orgid: this.orderForm.orgid,
      orderid: '',
      desc: '',
      remark: '',
      customername: '',
      customermobile: '',
      recipentcustomersame: false,
      recipientname: '',
      recipientmobile: '',
      fromaddress: '',
      fromlat: '',
      fromlng: '',
      toaddress: '',
      tolat: '',
      tolng: '',
      startdate: this.currentDate,
      enddate: this.currentDate,
      starttime: this.currentStartTime,
      endtime: this.currentEndTime,
      status: '001',
      advance: '',
      collection: '',
      fees: '',
      settlement: '',
      sort: '',
      qrdata: '',
      assign: false,
    };
  }

  editOrder(order: any) {
    window.scrollTo(0, 0);
    this.isOrderNew = true;
    this.isEditing = true;
    this.orderForm = {
      id: order.orderid, // primary key for order
      domainid: this.orderForm.domainid,
      orgid: this.orderForm.orgid,
      orderid: order.orderno,
      desc: order.desc,
      remark: order.remark,
      customername: order.cuname,
      customermobile: order.cumobile,
      recipentcustomersame:
        order.cuname != '' &&
        order.cuname != '' &&
        order.cuname == order.rename &&
        order.cumobile == order.remobile,
      recipientname: order.rename,
      recipientmobile: order.remobile,
      fromaddress: order.fromaddress,
      fromlat: order.fromlat,
      fromlng: order.fromlong,
      toaddress: order.toaddress,
      tolat: order.tolat,
      tolng: order.tolong,
      startdate: this.allinoneService.formatDBToShowInEdit(order.startdate),
      enddate: this.allinoneService.formatDBToShowInEdit(order.enddate),
      starttime: order.starttime,
      endtime: order.endtime,
      status: order.status,
      advance: order.advance,
      collection: order.collection,
      fees: order.fees,
      settlement: order.settlement,
      sort: order.sort,
      qrdata: order.qrdata,
      assign: order.assign,
    };
    order.image.imgurl.map((item: any, index: number) => {
      var temp = {
        file: '',
        previewurl: item,
        filename: order.image.imgname[index],
      };
      this.imageList.push(temp);
    });
  }

  async submitOrder() {
    this.orderFormSubmitted = true;
    if (this.orderForm.desc == '') {
      return;
    }
    this.isLoading = true;
    var imagesToBeUploaded: any[] = [];
    var imagesNameList: any[] = [];
    var deleteImageList: any[] = [];
    const current_date = this.allinoneService.getCurrentDateToDB();
    const current_time = this.allinoneService.getCurrentTimeToDB();
    const current_time2 = this.allinoneService.getCurrentTimeToDB2();
    var tmpimg = current_date.toString() + current_time2.toString();

    this.imageList.map((item: any) => {
      if (item.file) {
        imagesToBeUploaded.push(item);
        imagesNameList.push(item.filename);
      } else {
        deleteImageList.push(item.filename);
      }
    });

    for (let i = 0; i < imagesToBeUploaded.length; i++) {
      if (imagesToBeUploaded[i].file.size >= 307200) {
        imagesToBeUploaded[i].file = await this.allinoneService.compressImage(
          imagesToBeUploaded[i].file
        );
        if (imagesToBeUploaded[i].file == false) {
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          this.isLoading = false;
          this.orderFormSubmitted = false;
          return;
        }
      }
    }

    if (imagesToBeUploaded.length > 0) {
      const result = await this.allinoneService.multipleUploadToAWS(
        imagesToBeUploaded,
        'logistics/order/' + (this.isEditing ? this.orderForm.id : tmpimg)
      );
      if (!result) {
        this.messageService.openSnackBar(
          'Error while uploading your file.',
          'warn'
        );
        this.isLoading = false;
        this.orderFormSubmitted = false;
        return;
      }
    }

    var data = {
      domainid: this.orderForm.domainid,
      orgid: this.orderForm.orgid,
      orderno: this.orderForm.orderid,
      desc: this.orderForm.desc,
      imagelist: imagesNameList,
      tmpimg: tmpimg,
      remark: this.orderForm.remark,
      cuname: this.orderForm.customername,
      cumobile: this.orderForm.customermobile,
      rename: this.orderForm.recipentcustomersame
        ? this.orderForm.customername
        : this.orderForm.recipientname,
      remobile: this.orderForm.recipentcustomersame
        ? this.orderForm.customermobile
        : this.orderForm.recipientmobile,
      fromaddress: this.orderForm.fromaddress,
      fromlat: this.orderForm.fromlat.toString(),
      fromlong: this.orderForm.fromlng.toString(),
      toaddress: this.orderForm.toaddress,
      tolat: this.orderForm.tolat.toString(),
      tolong: this.orderForm.tolng.toString(),
      startdate: this.allinoneService.formatDate(this.orderForm.startdate),
      starttime: this.orderForm.starttime,
      enddate: this.allinoneService.formatDate(this.orderForm.enddate),
      endtime: this.orderForm.endtime,
      advance: this.orderForm.advance,
      collection: this.orderForm.collection,
      fees: this.orderForm.fees,
      settlement: this.orderForm.settlement,
    };

    if (this.isEditing) {
      data = Object.assign(data, {
        orderid: this.orderForm.id,
        deleteimagelist: deleteImageList,
        sort: this.orderForm.sort,
        status: this.orderForm.status,
      });
    }
    var fun = this.isEditing
      ? this.kunyekService.editLogisticsOrder(data)
      : this.kunyekService.createLogisticsOrder(data);
    this.subs.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.cancel();
          this.refresh();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
        this.orderFormSubmitted = false;
      },
      (err) => {
        this.isLoading = false;
        this.orderFormSubmitted = false;
      }
    );
  }

  statusChange(status: any) {
    this.status = status.value;
    this.statusname = status.name;
    this.end = '';
    this.currentPage = 1;
    this.orders = [];
    this.getOrder();
  }

  searchTextChange() {
    clearTimeout(this.orderTimeout);
    this.orderTimeout = setTimeout(() => {
      this.end = '';
      this.currentPage = 1;
      this.orders = [];
      this.getOrder();
    }, 500);
  }

  clear() {
    this.searchText = '';
    this.end = '';
    this.currentPage = 1;
    this.orders = [];
    this.getOrder();
  }

  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      if (event == this.lastPage && this.lastPage != '') {
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        this.currentPage = event;

        if (event > this.orders.length / this.itemPerPage) {
          this.getOrder();
        }
      }
    }
  }

  removeImage(index: number) {
    this.imageList.splice(index, 1);
  }

  /**
   *
   * @param type from - fromlat,fromlong , to - tolat, tolng
   */
  pickLocation(type: string) {
    const dialog = this.dialog.open(MapComponent, {
      height: '400px',
      width: '65vw',
      closeOnNavigation: true,
      panelClass: ['map-modal', 'mat-typography'],
      data: {
        lat: type == 'from' ? this.orderForm.fromlat : this.orderForm.tolat,
        long: type == 'from' ? this.orderForm.fromlng : this.orderForm.tolng,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (type == 'from') {
          this.orderForm.fromlat = dialogResult.lat;
          this.orderForm.fromlng = dialogResult.long;
        } else {
          this.orderForm.tolat = dialogResult.lat;
          this.orderForm.tolng = dialogResult.long;
        }
      }
    });
  }

  /**
   *
   * @param type customer, recipient
   */
  chooseCustomer(type: string) {
    const dialog = this.dialog.open(LogisticsCustomerComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {},
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (type == 'customer') {
          this.orderForm.customername = dialogResult.data.name;
          this.orderForm.customermobile = dialogResult.data.phno;
        } else {
          this.orderForm.recipientname = dialogResult.data.name;
          this.orderForm.recipientmobile = dialogResult.data.phno;
        }
      }
    });
  }

  refresh() {
    this.end = '';
    this.currentPage = 1;
    this.lastPage = '';
    this.orders = [];
    this.getOrder();
  }

  deleteOrder(order: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.gettingOrder = true;
        const data = {
          orgid: this.orderForm.orgid,
          domainid: this.orderForm.domainid,
          orderid: order.orderid,
        };
        this.subs.sink = this.kunyekService
          .deleteLogisticsOrder(data)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.messageService.openSnackBar('Successful.', 'success');
                this.refresh();
              } else {
                this.gettingOrder = false;
                this.messageService.openSnackBar(res.message, 'warn');
              }
            },
            (err) => {
              this.gettingOrder = false;
            }
          );
      }
    });
  }

  viewQR(order?: any) {
    var qrjson: any;
    if (order) {
      if (order.assign || order.qrdata == '') {
        return;
      }
      qrjson = {
        qrdata: order.qrdata,
        type: 3,
      };
    } else {
      if (this.orderForm.assign || this.orderForm.qrdata == '') {
        return;
      }
      qrjson = {
        qrdata: this.orderForm.qrdata,
        type: 3,
      };
    }
    console.log(atob('RENFTDBZWkxPNkJFVDE5'));
    const dialog = this.dialog.open(LogisticsOrderqrComponent, {
      maxWidth: '400px',
      width: '400px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        value: JSON.stringify(qrjson),
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }
}
