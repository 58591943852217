<section>
    <ng-container *ngIf="!isError;else errorPage">
        <div class="container-fluid">
            <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="voterRoundLoading">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="content" *ngIf="!memberForm && !isaddingRound && !voterRoundLoading">
                <div class="custom-web-breadcrumb" *ngIf="isAdmin">
                    <span class="active-breadcrumb" (click)="gotohome()">Home</span>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    <span class="active-breadcrumb" (click)="gotocampaings()">Campaigns </span>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    <span>{{camdesc}}</span>
                </div>
                <div class="fix-header" [ngClass]="{'header-pad' : !isAdmin}">
                    <div class="campaign-name mb-3" *ngIf="!isAdmin">{{camdesc}}</div>
                    <div class="w-100 d-flex justify-content-between align-items-center flex-wrap">
                        <div class="mb-3">
                            <span class="title">Rounds</span>
                        </div>
                        <div class="d-flex" *ngIf="isAdmin">
                            <!-- <button type="button" class="btn btn-custom me-2 mb-3" (click)="logout()" title="Sign Out">
                                Sign Out
                            </button> -->
                            <button type="button" class="btn btn-custom me-2 mb-3" (click)="addVoter()"
                                title="Voter Setup">
                                Voters
                            </button>
                            <button type="button" class="btn btn-custom mb-3" (click)="addRound()"
                                title="Add New Round">
                                <i class="fa fa-plus me-1"></i>New Round
                            </button>
                        </div>
                        <!-- <div *ngIf = "!isAdmin">
                            <button type="button" class="btn btn-custom mb-3" (click)="signin()" title="Sign In">
                                Admin
                            </button>
                        </div> -->
                    </div>
                </div>

                <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                    <table class="table table-responsive table-borderless">
                        <thead class="table-header">
                            <tr>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="description">Description</td>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="remark">Remarks</td>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="status">Status</td>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="voter">Voters</td>
                                <td scope="col" class="d-flex justify-content-center">Actions</td>
                            </tr>
                        </thead>
                        <tbody *ngIf="voterRoundLoading">
                            <tr>
                                <td colspan="7" class="nodata">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="voterRoundLoading == false">
                            <tr *ngIf="votingRound.length == 0">
                                <td colspan="7" class="nodata">Empty</td>
                            </tr>
                            <tr *ngFor="let item of votingRound">
                                <td class="td-data td-edit" (click)="editRound(item)">
                                    <span [title]="item.description">{{item.description}} </span>
                                </td>
                                <td class="td-data">
                                    <span [title]="item.remark">{{item.remark}} </span>
                                </td>
                                <td class="td-data">
                                    <span [title]="item.status"
                                        [ngClass]="{'red' : item.status == '002', 'green' : item.status == '001'}">{{item.status
                                        == "001" ? "Open" : "Closed" }}</span>
                                </td>
                                <td class="td-data">
                                    <span style="color: var(--main-color);">{{item.voteresult}}/{{item.votertotal}} +
                                        {{item.othervoter}}</span>
                                </td>

                                <td class="d-flex justify-content-center">
                                    <button class="btn btn-outline-info" [ngClass]="{'me-2' : isAdmin}"
                                        (click)="openSummary(item)" title="Open Summary">
                                        <i class='fas fa-clipboard-list'></i>
                                    </button>
                                    <button class="btn btn-outline-primary" (click)="editRound(item)" *ngIf="isAdmin"
                                        title="Edit Voting Round">
                                        <i class='fa fa-edit'></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="content" *ngIf="isaddingRound && !voterRoundLoading">

                <div class="custom-web-breadcrumb">
                    <span class="active-breadcrumb" (click)="gotohome()">Home</span>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    <span class="active-breadcrumb" (click)="gotocampaings()">Campaigns </span>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    <span class="active-breadcrumb" (click)="cancelAddRound()">{{camdesc}}</span>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    <span>{{isEditing? 'Edit Round' : 'New Round'}}</span>
                </div>

                <form action="" #f="ngForm" (ngSubmit)="f.form.valid && addVotingRound()" name="form" novalidate>
                    <div class="d-flex mb-3">
                        <button *ngIf="!saveLoading" class="btn me-2 btn-custom" (click)="cancelAddRound()">
                            Cancel
                        </button>
                        <app-button-loader class="btn-custom" style="border-radius: 5px;" [button_text]="'Save'"
                            [load_button_text]="'Saving'" [isLoading]="saveLoading">
                        </app-button-loader>
                    </div>
                    <!-- description -->
                    <div class="form-group  mb-3">
                        <label for="Description" class="mb-2">Description</label>
                        <textarea type="text" name="desc" class="form-control" id="desc" [(ngModel)]="description"
                            required #desc="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && desc.invalid }"></textarea>
                        <div role="alert" *ngIf="desc.errors?.required && f.submitted" style="color: red;">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                    <!-- remark -->
                    <div class="form-group  mb-3">
                        <label for="Remark" class="mb-2">Remarks</label>
                        <textarea type="text" [(ngModel)]="remark" name="remark" class="form-control" id="remark"
                            #remark_id="ngModel" autocomplete="off"></textarea>
                    </div>
                    <!-- type -- status  -->
                    <div class="row">
                        <div class=" form-group mb-3 col-md-4">
                            <label for="memberstatus" class="form-label">Type</label>
                            <select (change)="Member($event)" class="form-select">
                                <option *ngFor="let i of mem_list" [value]="i.id" [selected]="i.id == subList">{{i.mem}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group mb-3 col-md-4">
                            <label for="status" class="form-label">Status</label>
                            <select (change)="onStatus($event)" class="form-select">
                                <option *ngFor="let item of status_list" [value]="item.id"
                                    [selected]="item.id == status">{{item.stat}}</option>
                            </select>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label for="order" class="form-label">Order</label>
                            <input type="number" class="form-control" id="order" name="order" placeholder="Enter order"
                                [(ngModel)]="roundorder" required #order="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && order.invalid }"
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)">

                            <div class="invalid-feedback"
                                *ngIf="order.invalid || (order.dirty || order.touched) && f.submitted">
                                <div *ngIf="order.errors?.required">
                                    {{allinoneService.input_err_msg}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="subList == '2'" (click)="addMember()" class="mt-2 d-flex select-mem"
                        style="width: fit-content;">
                        <span>
                            Selected Voter - {{finalSelectedMember.length}}
                        </span>
                        <span>
                            <mat-icon class="ms-2 member-edit d-flex">edit</mat-icon>
                        </span>
                    </div>
                </form>
                <div class="form-group mb-3 d-flex">
                    <button class="btn mt-2 btn-custom" (click)="voterSetup()">
                        {{cadnDintaes.length}} Candidates
                    </button>
                    <button mat-flat-button class="button" class="mt-2" (click)="voterSetup()">
                        Vote Limit - {{voteLimit}}
                    </button>
                </div>
            </div>

            <div class="content" *ngIf="memberForm && !voterRoundLoading">
                <div class="custom-web-breadcrumb">
                    <span class="active-breadcrumb" (click)="gotohome()">Home</span>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    <span class="active-breadcrumb" (click)="gotocampaings()">Campaigns </span>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    <span class="active-breadcrumb" (click)="cancelAddRound()">{{camdesc}}</span>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    <span class="active-breadcrumb" (click)="cancelMember()">{{isEditing? 'Edit Round' : 'New Round'}}
                    </span>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    <span> Voters </span>
                </div>
                <div class="close-save-button mb-3">
                    <button type="button" class="btn btn-custom me-2" (click)="cancelMember()"
                        *ngIf="!saveLoadingMember">
                        Cancel
                    </button>
                    <app-button-loader (click)="saveMember()" [button_text]="'Save'" [load_button_text]="'Saving'"
                        [isLoading]="saveLoadingMember">
                    </app-button-loader>
                </div>
                <div>
                    <div class="mb-3">
                        <input #selectmember type="text" placeholder="Select Member" aria-label="Number" matInput
                            class="form-control" [matAutocomplete]="auto1" [(ngModel)]="memberSearchText"
                            name="memberselect" id="memberselect" [disabled]="gettingMembers"
                            #trigger="matAutocompleteTrigger">
                        <mat-progress-bar mode="indeterminate" *ngIf="gettingMembers"></mat-progress-bar>
                        <mat-autocomplete #auto1="matAutocomplete">
                            <mat-option *ngFor="let member of members| filter : memberSearchText:16 "
                                (click)="selectMember(member, selectmember)">
                                {{member.userid}}{{member.name ? ' - '+member.name : ''}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="mb-2 d-flex flex-wrap justify-content-end align-items-center">
                        <div class="d-flex align-items-center">
                            <span class="total-count-text">Total:&nbsp;</span> <span
                                class="total-count-num">{{selectedMember.length}}</span>
                        </div>
                        <div class="line"></div>
                        <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
                            <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                                (focus)="isFocus1 = true" (blur)="isFocus1 = false" [(ngModel)]="searchText1"
                                [ngClass]="{'c-search-input-with-clear' : searchText1 }"
                                style="font-family: FontAwesome,Ubuntu;">
                            <button class="btn btn-outline-secondary " type="button" *ngIf="searchText1"
                                (click)="clear()" [ngClass]="{'c-clear' : isFocus1, 'c-clear-not-focus': !isFocus1}">
                                <mat-icon class="c-icon">close</mat-icon>
                            </button>
                        </div>
                        <div class="dropdown ms-2">
                            <button type="button" class="btn btn-custom" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false" title="Page Size">
                                <mat-icon>filter_list</mat-icon>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <div c style="text-align: center;">Page size</div>
                                </li>
                                <li>
                                    <hr class="dropdown-divider">
                                </li>
                                <li class="dropdown-item" *ngFor="let page of pgMember">
                                    <input type="radio" name="pg-count" value="{{page.count}}"
                                        (change)="changePageCountMember($event)" id="pg{{page.name}}" class="p-1"
                                        [checked]="page.count==100">
                                    <label for="pg{{page.name}}" class="col-10 p-1">
                                        {{page.name}}
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="input-group mb-2" *ngIf="isMobileView">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                            (focus)="isFocus1 = true" (blur)="isFocus1 = false" [(ngModel)]="searchText1"
                            [ngClass]="{'c-search-input-with-clear' : searchText1 }"
                            style="font-family: FontAwesome,Ubuntu;">
                        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText1" (click)="clear()"
                            [ngClass]="{'c-clear' : isFocus1, 'c-clear-not-focus': !isFocus1}">
                            <mat-icon class="c-icon">close</mat-icon>
                        </button>
                    </div>
                    <div>
                        <div class="table-responsive" matSort (matSortChange)="sortMemberData($event)">
                            <table class="table table-responsive table-borderless">
                                <thead class="table-header">
                                    <tr>
                                        <td scope="col" class="col-lg-5 col-md-5" mat-sort-header="userid">User ID</td>
                                        <td scope="col" class="col-lg-5 col-md-5" mat-sort-header="username">Name
                                        </td>
                                        <td scope="col" class="d-flex justify-content-center">Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="selectedMember.length == 0">
                                        <td colspan="3" class="nodata">Empty</td>
                                    </tr>
                                    <tr
                                        *ngFor="let member of selectedMember| filter : searchText1:16 | paginate: { itemsPerPage: itemPerPageMember, currentPage: q };let index = index">
                                        <td>
                                            {{member.userid}}
                                        </td>
                                        <td>
                                            {{member.name}}
                                        </td>
                                        <td class="d-flex justify-content-center">
                                            <button class="btn btn-outline-danger" (click)="removeMember(index)">
                                                <i class='fa fa-trash-o'></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #errorPage>
        <div class="c-container m-5 error" *ngIf="!noCampaignError">
            <span class="mb-3">Something went wrong!</span>
            <button class="btn btn-secondary" (click)="reload()">Reload</button>
        </div>
        <div class="c-container m-5 error" *ngIf="noCampaignError">
            <span class="noround">This campaign does not exist.</span>
        </div>
    </ng-template>
</section>