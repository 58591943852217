import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { MessageService } from '../../services/message.service';
import ListConfig, { Filter, Status } from '../../interfaces/listconfig';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @Input() url!: string[];
  @Input() searchText!: string;
  @Input() configs!: ListConfig[];
  list: any[] = [];
  @Input() breadcrumbs!: any[];
  gettingList: boolean = true;
  @Input() customTemplate!: TemplateRef<any>;
  @Input() filter?: Filter;
  totalPages!: number;
  totalcount!: number;
  page = 1;
  filterState?: string | number | undefined;

  @Input() cb!: (data: any) => Observable<any>;

  searchName: string = '';
  selectedFilter?: string;

  constructor(
    private router: Router,
    public allInOneService: AllInOneService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.addAction();
    this.route.queryParams.subscribe(() => {
      this.getCurrentPage();
      this.getList();
    });
    this.setFilterDefault();
  }

  setFilterDefault() {
    if (this.filter && this.filterState === undefined) {
      this.selectedFilter = this.filter.choices.find(c => c.value === (this.filter?.default || -1))?.name;
      this.filterState = -1;
    }
  }

  handleFilterSelected(filter: { name: string, value?: string | number }) {
    this.selectedFilter = filter.name;
    this.filterState = filter.value;
    this.router.navigate(this.url, { queryParams: { page: 1, q: this.searchName, filterState: this.filterState } });
    this.getList();
  }

  getFormattedProperty(value: any, type: 'date' | 'time') {
    if (type === 'date') {
      return this.allInOneService.formatDBToShow(value);
    } else {
      return this.allInOneService.format24HourTimeTo12Hour(value);
    }
  }

  addAction() {
    if (this.customTemplate) {
      this.configs.push({ title: '', property: '' });
    }
  }

  getCurrentPage() {
    this.route.queryParams.subscribe((params) => {
      const pageParam = params['page'];
      if (pageParam && !isNaN(+pageParam) && +pageParam > 0) {
        this.page = +pageParam;
      } else {
        this.router.navigate(this.url, { queryParams: { page: 1 } });
      }
      const q = params['q'];
      if (q) {
        this.searchName = q;
      }
      const filterParam = params['filterState'];


      if (filterParam !== undefined) {
        this.filterState = filterParam;
        this.selectedFilter = this.filter?.choices.find(c => c.value == this.filterState)?.name;
      } else {
        this.setFilterDefault();
      }
    });
  }

  getStatusText(status: string | number, value: Status[]) {
    return value.find(v => v.value === status)?.text || '';
  }

  getPropertyValue(obj: any, path: string): any {
    return path.split('.').reduce((o, p) => (o ? o[p] : undefined), obj);
  }

  getStatusClass(status: number | string, id: string) {
    let statusClass = '';
    let statusType: 'danger' | 'ok' | 'default';
    let sts: Status[] = [];
    this.configs.forEach(c => {
      if (c.status && id === c.status.id) {
        sts = c.status.list;
      }
    });
    if (sts.length > 0) {
      statusType = sts.find(s => s.value === status)?.type || 'default';
      switch (statusType) {
        case 'default':
          statusClass = 'status-gray';
          break;
        case 'ok':
          statusClass = 'status-green';
          break;
        case 'danger':
          statusClass = 'status-red';
          break;
      }
    }
    return statusClass;
  }

  newMember() {
    this.router.navigate([...this.url, 'new']);
  }

  toDetail(id: string) {
    this.router.navigate([...this.url, id, 'detail']);
  }

  goPageOne() {
    this.router.navigate(this.url, { queryParams: { page: 1, q: this.searchName, filterState: this.filterState } });
  }

  getList() {
    this.gettingList = true;
    const data = { searchName: this.searchName, page: this.page, filter: this.filterState == '-1' ? undefined : this.filterState };
    this.cb(data).subscribe(
      (res: any) => {
        if (res.returncode === '300') {
          this.totalPages = res.totalpages;
          this.totalcount = res.totalcount;
          if (this.page > this.totalPages) {
            this.goPageOne();
          } else {
            this.list = res.datalist;
            this.gettingList = false;
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.gettingList = false;
        }
      },
      (err) => {
        this.messageService.openSnackBar('Error fetching data', 'error');
        this.gettingList = false;
      }
    );
  }

  searchByName() {
    this.router.navigate(this.url, { queryParams: { page: 1, q: this.searchName, filterState: this.filterState } });
  }
}
