<app-list 
  [url]="url" 
  [breadcrumbs]="breadcrumbs" 
  [configs]="configs" 
  [searchText]="searchText"
  [cb]="cb"
  [filter]="filter"
  >


</app-list>

