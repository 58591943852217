<div class="container-fluid">
  <div class="content">
    <app-team-breadcrumb [s_breadcrumb]="'User Mapping'"
      [t_breadcrumb]="mappingOrNot ? isEditing ? userForm.uuid:  'New User': ''" (emit)="cancel()">
    </app-team-breadcrumb>

    <div class="d-flex flex-wrap">
      <div class="mb-3">
        <button *ngIf="!mappingOrNot" type="button" class="btn btn-custom" (click)="userAdd()">
          <i class="fa fa-plus me-1"></i>Add
        </button>
        <div *ngIf="mappingOrNot" class="close-save-button">
          <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
            Cancel
          </button>
          <app-button-loader (click)="submitUser()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="saveLoading">
          </app-button-loader>
        </div>
      </div>
    </div>

    <ng-container *ngIf="mappingOrNot">
      <div class="row">
        <div class="mb-3 col-md-6">

          <label for="userid" class="form-label">User ID
          </label>
          <div *ngIf="isEditing">
            <input type="text" class="form-control" id="userid" name="userid" placeholder="12345"
              [(ngModel)]="userForm.uuid" #userid="ngModel" required disabled>
          </div>
          <div class="input-group" *ngIf="!isEditing">
            <span class="input-group-text" id="prefix">{{prefix}}</span>
            <input type="text" class="form-control" id="userid" name="userid" placeholder="11111"
              [(ngModel)]="userForm.uuid" #userid="ngModel" required
              [ngClass]="{ 'is-invalid': userFormSubmitted && (userid.invalid  || invalidUserId) }"
              [disabled]="isEditing">
            <div class="invalid-feedback"
              *ngIf="userid.invalid || (userid.dirty || userid.touched) && userFormSubmitted">
              <div *ngIf="userid.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>

        </div>
        <div class="mb-3 col-md-6">
          <label for="otp" class="form-label">
            Default OTP
          </label>
          <select class="form-select" [(ngModel)]="userForm.defaultotp">
            <option value="0">Mobile</option>
            <option value="1">Email</option>
          </select>
        </div>
        <div class="mb-3 col-md-6">
          <label for="mobile" class="form-label">
            Mobile
          </label>
          <input type="text" class="form-control" id="mobile" name="mobile" placeholder="+959*********"
            [(ngModel)]="userForm.mobile" #mobile="ngModel" required
            [ngClass]="{ 'is-invalid': userFormSubmitted && mobile.invalid && userForm.defaultotp == '0'}">
          <div class="invalid-feedback"
            *ngIf="mobile.invalid || (mobile.dirty || mobile.touched) && userFormSubmitted && userForm.defaultotp == '0'">
            <div *ngIf="mobile.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="email" class="form-label">
            Email
          </label>
          <input type="text" class="form-control" id="email" name="email" placeholder="john@example.com"
            #email="ngModel" [(ngModel)]="userForm.email" required
            [ngClass]="{ 'is-invalid': userFormSubmitted && email.invalid && userForm.defaultotp == '1'}">
          <div class="invalid-feedback"
            *ngIf="email.invalid || (email.dirty || email.touched) && userFormSubmitted && userForm.defaultotp == '1'">
            <div *ngIf="email.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!mappingOrNot">
      <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center">

        <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Mobile/Email"
            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-2' : !isMobileView, 'ms-0': isMobileView}">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">
                {{page.name}}
              </label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{userList.length}}</span>
        </div>
      </div>
      <div class="input-group mb-2" *ngIf="isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Mobile/Email"
          aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
          [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
          [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="uuid">User ID</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="mobile">Mobile</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="email">Email</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="otp">Default OTP</td>
              <td scope="col" class="d-flex justify-content-center">Actions</td>
            </tr>
          </thead>
          <tbody *ngIf="userListLoading">
            <tr>
              <td colspan="5" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="userListLoading == false">
            <tr *ngIf="userList.length == 0">
              <td colspan="5" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of (userList | filter:searchText:'usermapping') | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index">
              <td class="td-data td-edit" (click)="editUser(item)" style="max-width: fit-content;">
                <span data-bs-placement="right" [title]="item.uuid">
                  {{item.uuid}}
                </span>
              </td>
              <td class="td-data">
                <span [title]="item.mobile">{{item.mobile}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.email">{{item.email}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.defaultotp == '0' ? 'Mobile' : 'Email'">{{item.defaultotp == '0' ? 'Mobile' :
                  'Email'}}</span>
              </td>
              <td class="d-flex justify-content-center">
                <button class="btn btn-outline-primary" (click)="editUser(item)">
                  <i class='fa fa-edit'></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="p = $event"
        *ngIf="(userList | filter:searchText:'usermapping').length > itemPerPage" previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-container>

  </div>
</div>