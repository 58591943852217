<div class="row">
    <div class="mb-3 col-md-6">
        <label for="startdate" class="form-label">Start Date</label>
        <input class="form-control" type="date" id="startdate" [(ngModel)]="submitForm.startdate" name="startdate"
            [disabled]="requestData.readonly" max="9999-12-31" [min]="currentDate"
            (ngModelChange)="calculateDuration()" />

    </div>
    <div class="mb-3 col-md-6">
        <label for="enddate" class="form-label">End Date</label>
        <input class="form-control" type="date" id="enddate" [(ngModel)]="submitForm.enddate" name="enddate"
            [disabled]="requestData.readonly" max="9999-12-31" [min]="submitForm.startdate"
            (ngModelChange)="calculateDuration()" />

    </div>

    <div class="mb-3 col-md-12">
        <label for="duration" class="form-label">Duration</label>
        <input class="form-control" type="text" id="duration" [(ngModel)]="submitForm.duration" name="duration"
            disabled />
    </div>

    <div class="mb-3 col-md-12">

        <label for="dlloc" class="form-label ">Designated Location</label>
        <!-- <select name="dloc" id="dloc" [(ngModel)]="submitForm.locationid">
            <option *ngFor="let item of locList" [value]="item.locid">
                {{item.name}}
            </option>
        </select>  -->
        <input type="text" placeholder="" aria-label="Number" matInput class="form-control" [matAutocomplete]="auto1"
            [(ngModel)]="submitForm.location" name="location" id="location" [disabled]="locListLoading"
            #trigger="matAutocompleteTrigger" (ngModelChange)="removeLocation()">
        <div *ngIf="submitForm.lat != '' && submitForm.long != ''" class="link-primary mt-2"
            style="cursor: pointer; text-decoration: underline;" (click)="goToMap(submitForm.lat, submitForm.long)">{{
            submitForm.lat }} , {{ submitForm.long }} </div>
        <mat-autocomplete #auto1="matAutocomplete">
            <mat-option *ngFor="let loc of locList | filter:submitForm.location:7" (click)="selectLocation(loc)">
                {{loc.name}}
            </mat-option>
        </mat-autocomplete>
        <mat-progress-bar mode="indeterminate" *ngIf="locListLoading"></mat-progress-bar>
        <div class="map-wrapper mt-1" *ngIf="submitForm.locationid">
            <a class="d-flex align-items-center" style="text-decoration: none !important;">
                {{this.submitForm.lat}} - {{this.submitForm.long}}
                <mat-icon style="font-size: 1rem !important;" class="d-flex align-items-center">location_on</mat-icon>
            </a>
        </div>
    </div>



    <div class="mb-3 col-md-12">
        <label for="remark" class="form-label">Reason For Work From Home</label>
        <textarea class="form-control" [(ngModel)]="submitForm.remark" rows="3"
            [disabled]="submitForm.readonly"></textarea>
    </div>
    <div class="mb-3 col-md-12 d-flex">
        <ng-container>
            <button class="btn btn-custom me-2" (click)="chooseUser()" [disabled]="submitForm.readonly">
                Select Approvers
            </button>
        </ng-container>
    </div>
    <ng-container *ngIf="approverList.length > 0">
        <div class="col-md-12">
            <h3> Selected Approvers ({{ approverList.length }})</h3>
            <div class="list-group">
                <div class="profile-container d-flex col-md-4" *ngFor="let user of approverList">
                    <span>
                        <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
                            [lazyLoad]=" user.photo || user.image || '../../../assets/images/profile.png'" />
                    </span>

                    <label class="d-flex align-items-center align-items-center-custom justify-content-center w-100">
                        <div class="profile-item-container">
                            <div class="profile-item">
                                <div class="username" style="cursor: pointer">
                                    <b>{{ user.username || user.userid }}</b>
                                </div>
                                <div style="color: grey; margin-top: 1px; font-size: 13.5px"
                                    *ngIf="user.username != ''">
                                    {{ user.userid }}
                                </div>
                            </div>
                        </div>
                        <input type="checkbox" class="form-check-input" [id]="user.primarykey"
                            [(ngModel)]="user.checked" [checked]="user.checked" [disabled]="submitForm.readonly" />
                    </label>
                </div>
            </div>
        </div>
    </ng-container>
</div>