<div class='container-fluid'>
  <div class="content">

    <app-team-breadcrumb s_breadcrumb="Calculate Leave">
    </app-team-breadcrumb>

    <div *ngIf="refreshingImportStatus" class="my-card check-status">
      <i class="fas fa-spinner fa-spin me-2"></i> Checking Import Status
    </div>
    <div *ngIf="!refreshingImportStatus">
      <div class="d-flex align-items-center mb-3">
        <app-button-loader (click)="uploadfile()" [button_text]="'Upload'" [load_button_text]="'Uploading'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
      <div class="my-card p-4">
        <div>
          <label class="form-label me-1">File Upload</label>
          (<span class="filename" (click)="downloadSample()">Sample Template</span>)
        </div>
        <div class="dragdrop form-control p-0 mb-3" name="file" id="file">
          <ng-container *ngIf="fileSelected != ''; else uploadTemplate">
            <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3" appDragDrop
              (onFileDropped)="uploadFile($event)">
              <div>
                <img src="../../../../assets/images/excel.png" width="40px" />
              </div>
              <div class="ms-2">
                <strong>{{ fileSelected.name }}</strong>
              </div>
            </div>
          </ng-container>
          <ng-template #uploadTemplate>
            <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
              (onFileDropped)="uploadFile($event)">
              <div class="me-3">Drag and drop file here</div>
              <div class="me-3">or</div>
              <div>
                <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
                <button type="button" class="btn btn-custom" (click)="choosefile.click()">
                  Choose File
                </button>
              </div>
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="saveLoading">
          <mat-progress-bar class="example-margin" color="primary" mode="determinate" [value]="progress">
          </mat-progress-bar>
          <div class="d-flex align-items-center justify-content-center">
            {{progress}}%
          </div>
        </ng-container>
      </div>

      <div *ngIf="isCompleted" class="my-card p-3 mt-3">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div>
            <h3 class="mb-0 me-2">Result</h3>
          </div>
        </div>
        <ul class="list-group">
          <li class="list-group-item">
            <div class="row">
              <div>
                Imported File
              </div>
              <div>
                <span class="filename">
                  <span (click)="downloadFile(1)">Download</span>
                  <i class="fa fa-download ms-2" (click)="downloadFile(1)"></i>
                </span>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div>
                {{importStatus == '000' || importStatus == '004' ? 'Error File' : 'Result File'}}
              </div>
              <div>
                <!-- *ngIf="errorData.errorfile" -->
                <span class="filename">
                  <span (click)="downloadFile(2)">Download</span>
                  <i class="fa fa-download ms-2" (click)="downloadFile(2)"></i>
                </span>
                <!-- <span *ngIf="!errorData.errorfile">
                  {{errorData.errorreturn}}
                </span> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</div>