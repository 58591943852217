<div class='container-fluid'>
    <div class="content">
        <!-- <ng-container *ngIf="isAdmin">
            <app-team-breadcrumb *ngIf="!addShift" s_breadcrumb="Shift">
            </app-team-breadcrumb>
            <app-team-breadcrumb *ngIf="addShift" s_breadcrumb="Shift"
                [t_breadcrumb]="isEditing ? 'Edit Shift' : 'New Shift'" (emit)="cancel()">
            </app-team-breadcrumb>
        </ng-container>

        <ng-container *ngIf="!isAdmin">
            <app-office-breadcrumb *ngIf="!addShift" [s_breadcrumb]="'Shift'">
            </app-office-breadcrumb>
            <app-office-breadcrumb *ngIf="addShift" [s_breadcrumb]="'Shift'"
                [t_breadcrumb]="isEditing ? 'Edit Shift' : 'New Shift'" (emit)="cancel()">
            </app-office-breadcrumb>
        </ng-container> -->

        <ng-container *ngIf="allinoneService.isAttendanceView">
            <app-hcm-breadcrumb *ngIf="!addShift" s_breadcrumb="Shift">
            </app-hcm-breadcrumb>
            <app-hcm-breadcrumb *ngIf="addShift" s_breadcrumb="Shift"
                [t_breadcrumb]="isEditing ? 'Edit Shift' : 'New Shift'" (emit)="cancel()">
            </app-hcm-breadcrumb>
        </ng-container>

        <ng-container *ngIf="!allinoneService.isAttendanceView">
            <app-team-breadcrumb *ngIf="!addShift" s_breadcrumb="Shift">
            </app-team-breadcrumb>
            <app-team-breadcrumb *ngIf="addShift" s_breadcrumb="Shift"
                [t_breadcrumb]="isEditing ? 'Edit Shift' : 'New Shift'" (emit)="cancel()">
            </app-team-breadcrumb>
        </ng-container>

        <ng-container *ngIf="addShift;else listView">
            <div class="d-flex align-items-center">
                <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader class="mb-3" (click)="submitShift()" [button_text]="'Save'"
                    [load_button_text]="'Saving'" [isLoading]="saveLoading">
                </app-button-loader>
            </div>
            <div class="row">
                <div class="mb-3 col-md-6">
                    <label for="name" class="form-label">Name</label>
                    <input class="form-control" id="name" [(ngModel)]="shiftForm.name" name="name" #name="ngModel"
                        [ngClass]="{ 'is-invalid': shiftFormSubmitted && name.invalid }" required />
                    <div class="invalid-feedback"
                        *ngIf=" name.invalid || ((name.dirty || name.touched) && shiftFormSubmitted)">
                        <div *ngIf="name.errors?.required">
                            {{ allinoneService.input_err_msg }}
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="description" class="form-label">Description</label>
                    <input class="form-control" id="description" [(ngModel)]="shiftForm.description"
                        name="description" />
                </div>

                <div class="mb-3 col-md-4 col-8 col-sm-8">
                    <label for="starthrmin" class="form-label">Start Time</label>
                    <select class="form-select" [(ngModel)]="shiftForm.starthrmin" name="starthrmin" id="starthrmin"
                        (ngModelChange)="timeChange('start')">
                        <option *ngFor="let time of timeList" [value]="time">
                            {{time}}
                        </option>
                    </select>
                </div>
                <div class="mb-3 col-md-2 col-4 col-sm-4">
                    <label for="starttime" class="form-label hidden-label"></label>
                    <select class="form-select" [(ngModel)]="shiftForm.starttime" name="starttime" id="starttime"
                        (ngModelChange)="timeChange('start')">
                        <option *ngFor="let ampm of ampm" [value]="ampm">
                            {{ampm}}
                        </option>
                    </select>
                </div>

                <div class="mb-3 col-md-4 col-8 col-sm-8">
                    <label for="endhrmin" class="form-label">End Time</label>
                    <select class="form-select" [(ngModel)]="shiftForm.endhrmin" name="endhrmin" id="endhrmin"
                        (ngModelChange)="timeChange('end')">
                        <option *ngFor="let time of timeList" [value]="time">
                            {{time}}
                        </option>
                    </select>
                </div>
                <div class="mb-3 col-md-2 col-4 col-sm-4">
                    <label for="endtime" class="form-label hidden-label"></label>
                    <select class="form-select" [(ngModel)]="shiftForm.endtime" name="endtime" id="endtime"
                        (ngModelChange)="timeChange('end')">
                        <option *ngFor="let ampm of ampm" [value]="ampm">
                            {{ampm}}
                        </option>
                    </select>
                </div>
                <div class="mb-3 col-md-3 col-8 col-sm-8">
                    <label for="workinghours" class="form-label">Working Hours</label>
                    <select class="form-select" [(ngModel)]="shiftForm.workinghours" name="workinghours"
                        (ngModelChange)="timeChange('working')" id="workinghours">
                        <option *ngFor="let hr of workinghours" [value]="hr">
                            {{hr}}
                        </option>
                    </select>
                </div>
                <div class="mb-3 col-md-3 col-4 col-sm-4">
                    <label for="workingmins" class="form-label hidden-label"></label>
                    <select class="form-select" [(ngModel)]="shiftForm.workinghoursmin" name="workingmins"
                        (ngModelChange)="timeChange('working')" id="workingmins">
                        <option *ngFor="let mins of workingminutes" [value]="mins">
                            {{mins}}
                        </option>
                    </select>
                </div>
                <div class="mb-3 col-md-3 col-8 col-sm-8">
                    <label for="breakhours" class="form-label">Break Hours</label>
                    <select class="form-select" [(ngModel)]="shiftForm.breakhours" name="breakhours" id="breakhours">
                        <option *ngFor="let hr of workinghours" [value]="hr">
                            {{hr}}
                        </option>
                    </select>
                </div>
                <div class="mb-3 col-md-3 col-4 col-sm-4">
                    <label for="breakmins" class="form-label hidden-label"></label>
                    <select class="form-select" [(ngModel)]="shiftForm.breakhoursmin" name="breakmins" id="breakmins">
                        <option *ngFor="let mins of workingminutes" [value]="mins">
                            {{mins}}
                        </option>
                    </select>
                </div>
            </div>
        </ng-container>
        <ng-template #listView>
            <div class="d-flex align-items-center">
                <div class="mb-3 me-3">
                    <button class="btn btn-custom me-auto" (click)="newShift()">
                        <i class="fa fa-plus me-1"></i> New
                    </button>
                </div>
                <div class="mb-3">
                    <button type="button" class="btn btn-custom" (click)="export()" title="Export To Excel"
                        [disabled]="shiftList.length == 0">
                        <img class="excel-icon" src="../../../assets/images/excel.png" />
                        Export
                    </button>
                </div>
            </div>
            <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
                <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
                    <input class="form-control" type="text" placeholder="&#xf002; Search by Name/Description"
                        aria-label="Search by Name/Description" (focus)="isFocus = true" (blur)="isFocus = false"
                        [(ngModel)]="searchText" (ngModelChange)="searchTextChange()"
                        [ngClass]="{ 'c-search-input-with-clear': searchText }"
                        style="font-family: FontAwesome, Ubuntu" />
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>
                <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false" title="Page Size">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <div c style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}"
                                (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                [checked]="page.count==100">
                            <label for="pg{{page.name}}" class="col-10 p-1">
                                {{page.name}}
                            </label>
                        </li>
                    </ul>
                </div>

                <div class="ms-2">
                    <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>

                <div class="line"></div>
                <div class="d-flex align-items-center">
                    <span class="total-count-text">Total:&nbsp;</span>
                    <span class="total-count-num">{{ (shiftList | filter : searchText : 'shiftList').length }}</span>
                </div>

                <div class="input-group mt-2" *ngIf="isMobileView">
                    <input class="form-control" type="text" placeholder="&#xf002; Search by Name/Description"
                        aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                        [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
                        (ngModelChange)="searchTextChange()" />
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>

                <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
                    *ngIf="shiftList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
                </pagination-controls>
            </div>
            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="name">Name</td>
                            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="description">Description</td>
                            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="starttime">Start Time</td>
                            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="endtime">End Time</td>
                            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="breakhours">Break Hours</td>
                            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="workinghours">Working Hours</td>
                            <td scope="col" class="d-flex justify-content-center">Action</td>
                        </tr>
                    </thead>

                    <tbody *ngIf="shiftListLoading">
                        <tr>
                            <td colspan="7" class="nodata">
                                <div class="spinner-border" entitle="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="!shiftListLoading">
                        <tr *ngIf="shiftList.length == 0">
                            <td colspan="7" class="nodata">Empty</td>
                        </tr>

                        <tr
                            *ngFor="let shift of shiftList | filter : searchText:'shiftList' | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage}; let index = index">
                            <td class="td-data td-edit" (click)="editShift(shift)">
                                <span [title]="shift.name">{{shift.name}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="shift.description">{{shift.description}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="shift.starttime">{{shift.starttime}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="shift.endtime">{{shift.endtime}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="shift.breakhours">{{shift.breakhours}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="shift.workinghours">{{shift.workinghours}}</span>
                            </td>

                            <td class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary me-2" (click)="editShift(shift)">
                                    <i class='fa fa-edit'></i>
                                </button>
                                <button class="btn btn-outline-danger" (click)="deleteShift(shift.shiftid, index)">
                                    <i class='fa fa-trash-o'></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination-controls class="paginate" (pageChange)="currentPage = $event"
                *ngIf="shiftList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
            </pagination-controls>
        </ng-template>
    </div>
</div>