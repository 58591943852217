
import { DatePipe, formatDate, } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,

} from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestPickupComponent } from '../request-pickup/request-pickup.component';
import { Sort } from '@angular/material/sort';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-daily-request',
  templateUrl: './daily-request.component.html',
  styleUrls: ['./daily-request.component.scss']
})
export class DailyRequestComponent implements OnInit {

  gettingData: boolean = false;
  dailyRequestList: any = [];
  addrequest: boolean = false;
  saveLoading: boolean = false;
  gettingVehicle: boolean = false;
  carFormSave: boolean = false;
  isEditing: boolean = false;
  orgid: any = "";
  organizations: any = [];
  searchText: any = "";
  searchTextAll: any = "";

  isRejecting: boolean = false;
  oldpickuppointid: any = "";

  isApproving: boolean = false;
  itemPerPage: any = "";
  currentPage: any = 1;
  text1: any = "";
  text2: any = "";

  alldailyRequestList: any = [];
  allgetting: boolean = false;
  allDaily: boolean = false;
  startdateall: any = "";
  weekDay = [

    {
      name: 'Monday',
      value: 'Monday',
    },
    {
      name: 'Tuesday',
      value: 'Tuesday',
    },
    {
      name: 'Wednesday',
      value: 'Wednesday',
    },
    {
      name: 'Thursday',
      value: 'Thursday',
    },
    {
      name: 'Friday',
      value: 'Friday',
    },
    {
      name: 'Saturday',
      value: 'Saturday',
    },
    {
      name: 'Sunday',
      value: 'Sunday',
    },
  ];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];
  formatToShow = 'yyyy-MM-dd';
  format: any = "hh:mm aa";
  dailyRequest: any = []

  requeststatus: any = "";
  statusList = [
    { "name": "All", "code": "" },
    { "name": "Submitted", "code": "001" },
    { "name": "Approved", "code": "002" },
    { "name": "Rejected", "code": "003" },
    { "name": "Completed", "code": "004" }
  ];

  requestData: any = [];
  istransoprationAdmin: boolean = false;
  startdate: any = "";
  memberList: any = [];
  username: any = "";
  requestid: any = "";
  currentPageAllSelected: boolean = false;


  dailyRequests = 0;
  approveCount = 0;
  rejectCount = 0;
  checkCount = 0;


  approveLoading: boolean = false;
  rejectLoading: boolean = false;

  tranparentid: string = '';




  // formatToShow = 'yyyy-MM-dd';
  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private filter: FilterPipe
  ) {
    this.istransoprationAdmin = this.allinoneService.isAdminOf("transportation");
    if (this.istransoprationAdmin == false) {
      this.router.navigateByUrl(`transportation`);
    }
    this.organizations = this.allinoneService.tranOrgs;
    this.orgid = this.allinoneService.selectedTranOrg
      ? this.allinoneService.selectedTranOrg
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {
    this.startdate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.startdateall = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.getDailyRequestList();
  }



  cancelFunction() {
    this.addrequest = false;
    this.isEditing = false;
    this.allDaily = false;
  }

  cancelFunction1() {
    this.addrequest = false;
    this.isEditing = false;
    this.allDaily = true;
  }



  getDailyRequestList() {
    this.gettingData = true;
    this.dailyRequestList = [];

    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "requesttype": "002",
      "trantype": "002",
      "requeststatus": this.requeststatus,
      "date": formatDate(
        new Date(this.startdate),
        'yyyyMMdd',
        'en-US'
      ).toString()


      // "date":this.allinoneService.formatDBToShow(this.startdate)
    };
    this.kunyekService.getRequest(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.dailyRequestList = res.datalist;



          this.gettingData = false;
        } else {

          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingData = false;
      }
    );
  }

  getDailyRequestListAll() {
    this.allgetting = true;
    this.alldailyRequestList = [];

    console.log("requestid>>");
    console.log(this.requestid);

    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "requesttype": "",
      "trantype": "",
      "requeststatus": this.requeststatus,
      "startdate": "",
      "enddate": "",
      "requestid": this.requestid,
      "byadmin": true,
      "end": ""
      // "date":this.allinoneService.formatDBToShow(this.startdate)
    };
    this.kunyekService.getRequestAll(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          // this.alldailyRequestList = res.datalist;

          console.log(res);

          if (res.approvallist.length > 0) {
            var requestchild = res.approvallist;
            this.tranparentid = res.approvallist[0].olduserid ? res.approvallist[0].olduserid : res.approvallist[0].userid;
            for (var i = 0; i < requestchild.length; i++) {
              console.log("i")
              console.log(requestchild[i])
              for (var ii = 0; ii < requestchild[i]["child"].length; ii++) {
                console.log("ii")
                console.log(requestchild[i]["child"][ii])
                requestchild[i]["child"][ii]["isChecked"] = false;
                requestchild[i]["child"][ii]["requestsubtype"] = requestchild[i]["requestsubtype"];

                this.alldailyRequestList.push(requestchild[i]["child"][ii])
              }
            }
          }


          this.allgetting = false;
        } else {

          this.allgetting = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.allgetting = false;
      }
    );
  }

  goAll(data: any, index: any) {
    // this.messageService.openSnackBar("Not available", 'warn');
    this.isEditing = false;
    this.allDaily = true;
    this.username = data.username;
    this.requestid = data.requestid;
    this.requeststatus = "";

    this.dailyRequest.weekday = data.weekday;

    this.dailyRequest.startdate = data.startdate;
    this.dailyRequest.enddate = data.enddate;

    this.dailyRequest.remark = data.remark;
    this.dailyRequest.refno = data.requestrefno;
    this.oldpickuppointid = data.pickuppointid;
    this.dailyRequest.index = index;
    this.requestData = data;

    this.dailyRequest.going = data.going;
    this.dailyRequest.return = data.return;

    if (data.arrivetime != "") {
      // var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
      // var stD = new Date(atFormDate + ' ' +  data.arrivetime);
      // var start = formatDate(stD, this.format, 'en-US').toString();

      var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
      var stD = new Date(atFormDate + 'T' + data.arrivetime);

      var start = formatDate(stD, this.format, 'en-US').toString();
      this.dailyRequest.arrivetime = start;
    } else {
      this.dailyRequest.arrivetime = "";
    }


    if (data.returntime != "") {

      // var stD = new Date(atFormDate + 'T' + data.arrivetime);
      var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
      var retD = new Date(atFormDate + 'T' + data.returntime);
      var returntime = formatDate(retD, this.format, 'en-US').toString();

      this.dailyRequest.returntime = returntime;

    } else {
      this.dailyRequest.returntime = "";
    }

    this.dailyRequest.toplace = data.toplace;


    if (data.userfromto.length > 0) {
      this.memberList = data.userfromto;
    }

    this.getDailyRequestListAll();

  }
  async EmployeeEdit(data: any, index: any) {
    console.log(data);
    this.memberList = [];
    this.isEditing = true;
    this.allDaily = false;
    var refno = this.dailyRequest.refno;
    this.dailyRequest = JSON.parse(JSON.stringify(data));
    this.dailyRequest.refno = refno;

    this.dailyRequest.status = data.requeststatus;
    this.dailyRequest.statusname = data.requeststatus == '001' ? 'Submitted' : data.requeststatus == '002' ? 'Approved' : data.requeststatus == '003' ? 'Rejected' : data.requeststatus == '004' ? 'Completed' : '';

    this.dailyRequest.date = this.allinoneService.formatDBToShow(this.allinoneService.formatDate(data.date))
    this.dailyRequest.name = data.username;
    this.dailyRequest.employeeid = data.employeeid;
    this.dailyRequest.userid = data.userid;
    this.dailyRequest.requestid = data.requestid;

    this.dailyRequest.userfrom = data.userfrom;
    this.dailyRequest.userto = data.userto;

  }

  refreshgetData() {
    if (this.allDaily) {
      this.isEditing = false;
      this.currentPageAllSelected = false;
      this.dailyRequests = 0;
      this.approveCount = 0;
      this.rejectCount = 0;
      this.checkCount = 0;
      this.getDailyRequestListAll();
    } else {
      this.getDailyRequestList();
    }

  }


  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    this.refreshgetData();
  }

  showpickup() {
    const dialog = this.dialog.open(RequestPickupComponent, {
      height: '470px',
      width: '470px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        orgid: this.orgid,
        list: this.requestData,
        type: "002",
        oldpickuppointid: this.oldpickuppointid,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        var data = dialogResult;
        console.log("data>>>>");
        console.log(data);
        console.log(this.dailyRequest.index);
        this.oldpickuppointid = dialogResult.data['newpickupointid'];

        this.dailyRequestList[this.dailyRequest.index].pickuppointid = dialogResult.data['newpickupointid'];

      }
    });
  }





  submitApprove() {
    if (!this.rejectLoading) {
      this.isApproving = true;
      var message =
        'Are you sure to approve selected ' + this.approveCount + ' records?';
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        // width: '3256x',
        panelClass: ['mat-typography'],
        closeOnNavigation: true,
        data: {
          cmessage: message,
        },
      });
      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.approveLoading = true;
          var tempApproveList = [];
          var datalist = this.alldailyRequestList
          for (var i = 0; i < datalist.length; i++) {
            if (
              datalist[i].isChecked &&
              (datalist[i].requeststatus == '001' ||
                datalist[i].requeststatus == '003')
            ) {
              console.log(datalist[i]);
              // tempApproveList.push(this.approvalList[i]);
              tempApproveList.push({
                parentid: this.tranparentid,
                date: datalist[i].date,
                memberid: datalist[i].olduserid,
                requestid: datalist[i].transportationid,
                requestsubtype: datalist[i].requestsubtype,
                approver: datalist[i].approver ? datalist[i].approver : [],
              });
            }
          }
          // console.log(tempApproveList.length);
          var start = 0;
          var end = 100;
          if (end > tempApproveList.length) {
            end = tempApproveList.length;
          }

          tempApproveList = tempApproveList.sort((a: any, b: any) => {
            return this.allinoneService.compare(a.date, b.date, false);
          });

          this.updatePayment(tempApproveList, start, end, '002');
        }
      });
    }
  }


  submitRejected() {
    if (!this.approveLoading) {
      var message =
        'Are you sure to reject selected ' + this.rejectCount + ' records?';
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        // width: '285px',
        panelClass: ['mat-typography'],
        closeOnNavigation: true,
        data: {
          cmessage: message,
        },
      });
      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.rejectLoading = true;
          var tempRejectList = [];
          var datalist = this.alldailyRequestList;
          for (var i = 0; i < datalist.length; i++) {
            if (
              datalist[i].isChecked &&
              (datalist[i].requeststatus == '001' ||
                datalist[i].requeststatus == '002')
            ) {
              // tempRejectList.push(this.approvalList[i]);
              tempRejectList.push({
                parentid: this.tranparentid,
                date: datalist[i].date,
                memberid: datalist[i].olduserid,
                requestid: datalist[i].transportationid,
                requestsubtype: datalist[i].requestsubtype,
                approver: datalist[i].approver ? datalist[i].approver : [],
              });
            }
          }
          // console.log(tempRejectList.length);
          var start = 0;
          var end = 100;
          if (end > tempRejectList.length) {
            end = tempRejectList.length;
          }

          tempRejectList = tempRejectList.sort((a: any, b: any) => {
            return this.allinoneService.compare(a.date, b.date, false);
          });

          this.updatePayment(tempRejectList, start, end, '003');
        }
      });
    }
  }





  updatePayment(list: any, start: number, end: number, type: string) {
    var flist: any = [];
    for (var i = start; i < end; i++) {
      flist.push(list[i]);
    }

    var startdate = flist[flist.length - 1].date;
    var enddate = flist[0].date;
    var data: any;
    var fun;
    data = {
      orgid: this.orgid,
      requestlist: flist,
      transportation: true,
      byadmin: true,
      startdate: startdate,
      enddate: enddate,
      requeststatus: type,
    };


    console.log(data);

    fun = this.kunyekService.updateMultipleRequest(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (end == list.length) {
            this.refreshgetData();

            // this.multipleLoading = false;
            if (type == '002') {
              this.approveLoading = false;
            } else if (type == '003') {
              this.rejectLoading = false;
            }
          } else {
            start = end;
            end = end + 100;
            if (end > list.length) {
              end = list.length;
            }
            this.updatePayment(list, start, end, type);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
          // this.multipleLoading = false;
          if (type == '002') {
            this.approveLoading = false;
          } else if (type == '003') {
            this.rejectLoading = false;
          }
        }
      },
      (err) => {
        // this.multipleLoading = false;
        if (type == '002') {
          this.approveLoading = false;
        } else if (type == '003') {
          this.rejectLoading = false;
        }
      }
    );
  }

  submitApproval(
    type: string,
  ) {
    if (type == '002') {
      this.isApproving = true;
    } else {
      this.isRejecting = true;
    }
    var data;
    data = {
      orgid: this.orgid,
      requeststatus: type,
      roleid: this.allinoneService.getSelectedRole(),
      byadmin: true,
      transportation: true,
      startdate: this.allinoneService.formatImportDate(this.dailyRequest.date),
      enddate: this.allinoneService.formatImportDate(this.dailyRequest.date),
      requestlist: [
        {
          parentid: this.tranparentid,
          date: this.allinoneService.formatImportDate(this.dailyRequest.date),
          memberid: this.dailyRequest.olduserid ? this.dailyRequest.olduserid : this.dailyRequest.userid,
          requestid: this.dailyRequest.transportationid,
          requestsubtype: this.dailyRequest.requestsubtype,
          approver: [],
        },
      ],
    };
    this.kunyekService.updateMultipleRequest(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.dailyRequest.requeststatus = type;
          this.dailyRequest.requeststatusname = this.statusList.find(
            (x: any) => x.value == type
          )?.name;
          this.dailyRequest.rejectperson =
            this.allinoneService.getKunyekUserId();
          this.dailyRequest.personname =
            this.allinoneService.getKunyekUserName();
          this.allDaily = true;
          this.refreshgetData();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isApproving = false;
        this.isRejecting = false;
      },
      (err) => {
        this.isApproving = false;
        this.isRejecting = false;
      }
    );

  }



  statusname: any = "All";
  statusChange(status: any) {
    if (status.code) {
      this.requeststatus = status.code;
      this.statusname = status.name;
      this.getDailyRequestListAll();
    } else {
      this.statusname = "All";
      this.requeststatus = "";
      this.getDailyRequestListAll();
    }
  }



  sortData(sort: Sort) {
    const data = this.dailyRequestList;
    if (!sort.active || sort.direction === '') {
      this.dailyRequestList = data;
    }

    this.dailyRequestList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.allinoneService.compare(
            a.username.toLocaleLowerCase(),
            b.username.toLocaleLowerCase(),
            isAsc
          );
        case 'employeeid':
          return this.allinoneService.compare(
            parseInt(a.employeeid),
            parseInt(b.employeeid),
            isAsc
          );
        case 'requestrefno':
          return this.allinoneService.compare(
            parseInt(a.requestrefno),
            parseInt(b.requestrefno),
            isAsc
          );
        case 'startdate':
          return this.allinoneService.compare(
            a.startdate.toLocaleLowerCase(),
            b.startdate.toLocaleLowerCase(),
            isAsc
          );

        case 'status':
          return this.allinoneService.compare(
            parseInt(a.status),
            parseInt(b.status),
            isAsc
          );
        case 'remark':
          return this.allinoneService.compare(
            a.remark.toLocaleLowerCase(),
            b.remark.toLocaleLowerCase(),
            isAsc
          );
        default:
          return 0;
      }

    });

  }


  sortDataAll(sort: Sort) {
    const data = this.alldailyRequestList;
    if (!sort.active || sort.direction === '') {
      this.alldailyRequestList = data;
    }

    this.alldailyRequestList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.allinoneService.compare(
            a.username.toLocaleLowerCase(),
            b.username.toLocaleLowerCase(),
            isAsc
          );
        case 'employeeid':
          return this.allinoneService.compare(
            parseInt(a.employeeid),
            parseInt(b.employeeid),
            isAsc
          );
        case 'requestrefno':
          return this.allinoneService.compare(
            parseInt(a.requestrefno),
            parseInt(b.requestrefno),
            isAsc
          );
        case 'startdate':
          return this.allinoneService.compare(
            a.startdate.toLocaleLowerCase(),
            b.startdate.toLocaleLowerCase(),
            isAsc
          );

        case 'status':
          return this.allinoneService.compare(
            parseInt(a.status),
            parseInt(b.status),
            isAsc
          );
        case 'remark':
          return this.allinoneService.compare(
            a.remark.toLocaleLowerCase(),
            b.remark.toLocaleLowerCase(),
            isAsc
          );
        default:
          return 0;
      }

    });

  }
  downloadSheet() {
    var data: any = [];

    if (this.allDaily) {
      var dailyExport = this.filter.transform(
        this.alldailyRequestList,
        this.searchText,
        "alldailyRequest"
      );
      dailyExport.map((dail: any) => {
        data.push({
          "User ID": dail.userid,
          "Name": dail.username,
          "Date": this.allinoneService.formatDBToShow(this.allinoneService.formatDate(dail.date)),
          "Status": dail.requeststatus == '001' ? 'Submitted' :
            dail.requeststatus == '002' ? 'Approved' : dail.requeststatus == '003' ?
              'Rejected' : dail.requeststatus == '004' ? 'Completed' : '',
          "Remark": dail.remark,
        });
      });
    } else {
      var dailyExport = this.filter.transform(
        this.dailyRequestList,
        this.searchText,
        "dailyRequest"
      );
      dailyExport.map((dail: any) => {
        console.log(dail);
        data.push({
          "Ref No.": dail.requestrefno,
          "Employee ID": dail.employeeid,
          "Name": dail.username,
          "Date": this.allinoneService.formatDBToShow(this.allinoneService.formatDate(dail.startdate)) + " - " + this.allinoneService.formatDBToShow(this.allinoneService.formatDate(dail.enddate)),
          "Remark": dail.remark,
          "Week Day": dail.weekday.join(','),
        });
      });
    }

    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = "";
    if (this.allDaily) {
      name = 'Daily_Requests_' + this.dailyRequest.refno +'_' + time + '.xlsx';
    } else {
      name = 'Daily_Requests_' + this.dailyRequest.refno +'_'+ time + '.xlsx';

    }
    this.allinoneService.exportEcecl(data, name);

  }
  searchdateApi() {

    this.refreshgetData();
  }



  selectRequest(request: any) {
    if (request.isChecked) {
      if (request.requeststatus == '001' || request.requeststatus == '003') {
        this.approveCount += 1;
      }
      if (request.requeststatus == '001' || request.requeststatus == '002') {
        this.rejectCount += 1;
      }

    } else {
      if (request.requeststatus == '001' || request.requeststatus == '003') {
        this.approveCount -= 1;
      }
      if (request.requeststatus == '001' || request.requeststatus == '002') {
        this.rejectCount -= 1;
      }
    }
  }

  currentPageSelectAll() {
    if (this.currentPageAllSelected) {
      this.approveCount = 0;
      this.rejectCount = 0;
      for (var i = 0; i < this.alldailyRequestList.length; i++) {
        this.alldailyRequestList[i].isChecked = true;
        if (
          this.alldailyRequestList[i].requeststatus == '001' ||
          this.alldailyRequestList[i].requeststatus == '003'
        ) {
          this.approveCount += 1;
        }
        if (
          this.alldailyRequestList[i].requeststatus == '001' ||
          this.alldailyRequestList[i].requeststatus == '002'
        ) {
          this.rejectCount += 1;
        }
      }
    } else {
      for (var i = 0; i < this.alldailyRequestList.length; i++) {
        if (
          this.alldailyRequestList[i].isChecked &&
          (this.alldailyRequestList[i].requeststatus == '001' ||
            this.alldailyRequestList[i].requeststatus == '003')
        ) {
          this.approveCount -= 1;
        }
        if (
          this.alldailyRequestList[i].isChecked &&
          (this.alldailyRequestList[i].requeststatus == '001' ||
            this.alldailyRequestList[i].requeststatus == '002')
        ) {
          this.rejectCount -= 1;
        }

        this.alldailyRequestList[i].isChecked = false;
      }
    }
  }


}
