import { Component } from '@angular/core';
import { Router } from '@angular/router';
import ListConfig from 'src/app/shared/interfaces/listconfig';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-summer-swimmer-list',
  templateUrl: './summer-swimmer-list.component.html',
  styleUrls: ['./summer-swimmer-list.component.scss']
})
export class SummerSwimmerListComponent{

  configs: ListConfig[] = [
    { title: 'အမည် (မြန်မာ)', property: 'nameinburmese' },
    { title: 'အမည် (အင်္ဂလိပ်)', property: 'nameinenglish' },
    { title: 'မှတ်ပုံတင်အမှတ်', property: 'nrc' },
    { title: 'ဖုန်းနံပါတ်', property: 'phone' },
    { title: 'မွေးသက္ကရာဇ်', property: 'birthdate',type:"date" },
    { title: 'ပညာအရည်အချင်း', property: 'education' },
    { title: 'အလုပ်အကိုင်', property: 'occupation' }
];

  url = ['/members/summerswimmer']
  searchName: string = '';
  searchText = "Search Summer Swimmer"
  cb = this.kunyekService.getSummerSwimmerList.bind(this.kunyekService)
  breadcrumbs: any[] = [
    { name: 'Swim', url: 'swim', isActive: true },
    { name: 'Summer Swimmer', url: '', isActive: false }
  ];

  constructor(
    private router: Router,
    private kunyekService: KunyekService,
  ) { }
 

  previewSummerSwimmer(id: string) {
    this.router.navigate([...this.url,'preview',id])
  }

  

  // getSummerSwimmerList() {
  //   const data = { searchName: this.searchName,page:this.page };
  //   this.kunyekService.getSummerSwimmerList(data).subscribe(
  //     (res: any) => {
  //       if (res.returncode === '300') {
  //         this.totalPages = res.totalpages
  //         this.summerSwimmerList = res.datalist;
  //       } else {
  //         this.messageService.openSnackBar(res.message, 'warn');
  //       }
  //       this.gettingSummerSwimmerList = false;
  //     },
  //     (err) => {
  //       this.gettingSummerSwimmerList = false;
  //       this.messageService.openSnackBar('Error fetching data', 'error');
  //     }
  //   );
  // }


}
