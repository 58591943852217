import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Workbook } from 'exceljs';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-time-in-out-report',
  templateUrl: './time-in-out-report.component.html',
  styleUrls: ['./time-in-out-report.component.scss'],
})
export class TimeInOutReportComponent implements OnInit, OnDestroy {
  mobileViewWidth: any = 426;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  itemPerPage: number = 100;
  currentPage: number = 1;
  searchText: string = '';
  searchLoading: boolean = false;
  orgid: string = '';
  getList!: Subscription;
  getMemberSub!: Subscription;

  minDate = new Date();
  inoutList: any = [];
  d = new Date();
  formatToShow = 'yyyy-MM-dd';
  piedateFormat = 'dd/MM/yyyy';
  tempweekbefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBefore = new Date(this.tempweekbefore);
  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );
  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();

  organizations: any = [];
  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];
  gettingMemberPosition: boolean = false;
  subscriptions = new SubSink();

  lastPage: any = '';
  // filename : string = '';
  // searchfilename : string = '';
  nextDisabled: boolean = false;
  total: number = 0;
  gettingData = false;
  // key: any = '';
  // start : number = 0;
  // end : number = Number(this.itemPerPage);
  inoutfileurl: string = '';

  dateFormat = 'yyyyMMdd';
  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  // websockect
  connectSocketLoading: boolean = true;
  private socket: WebSocket | undefined;
  public socketConnectionStatus: string = 'disconnected';
  public socketReceivedMessage: string = '';
  connectionId: string = '';
  drsocketfileurl: any = '';
  updateCId: boolean = false;
  socketConnectTime: any;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  openfilter: boolean = false;

  userStatusFilterList = this.allinoneService.userStatusFilterList;

  submitForm = {
    startdate: this.aweekBefore,
    enddate: new Date(Date.now()),
    department: 'All',
    division: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
    status: '001'
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe,
    private http: HttpClient,
    private filter: FilterPipe
  ) {
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.setLastDat();
  }

  ngOnDestroy(): void {
    this.getList && this.getList.unsubscribe();
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.socket?.close(3001);
    clearTimeout(this.socketConnectTime);
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  search() {
    this.connectWebSocket();
    this.closeSocketWithTime();
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '007',
    };
    this.kunyekService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      this.updateCId = false;
      console.log(res);
    });
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.searchLoading = true;
    this.connectSocketLoading = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      // this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionId = tempData.connectionId;
        if (this.updateCId) {
          this.updateConnectionID();
        }
        this.getInOutReport();
      } else {
        this.drsocketfileurl = tempData.fileurl;
        if (this.drsocketfileurl) {
          this.http.get(this.drsocketfileurl).subscribe((res: any) => {
            console.log('Your get data res');
            console.log(res);
            this.inoutList = res;
            this.searchLoading = false;
            this.gettingData = false;
            this.connectionId = '';
            this.socket?.close(3001);
          });
        }
      }
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });
  }

  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(() => {
      if (this.socketConnectionStatus != 'disconnected') {
        this.socket?.close(3001);
        this.connectionId = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoading = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  getInOutReport(filename: string = '') {
    this.searchLoading = true;
    this.inoutList = [];
    this.total = 0;
    // this.filename = '';
    const data = {
      startdate: formatDate(
        new Date(this.submitForm.startdate),
        this.dateFormat,
        'en-US'
      ).toString(),
      enddate: formatDate(
        new Date(this.submitForm.enddate),
        this.dateFormat,
        'en-US'
      ).toString(),
      orgid: this.orgid,
      department:
        !this.openfilter || this.submitForm.department == 'All'
          ? ''
          : this.submitForm.department,
      division:
        !this.openfilter || this.submitForm.division == 'All'
          ? ''
          : this.submitForm.division,
      teamid:
        !this.openfilter || this.submitForm.teamid == 'All'
          ? ''
          : this.submitForm.teamid,
      costcenter:
        !this.openfilter || this.submitForm.costcenter == 'All'
          ? ''
          : this.submitForm.costcenter,
      subdivision:
        !this.openfilter || this.submitForm.subdivision == 'All'
          ? ''
          : this.submitForm.subdivision,
      activestatus: this.openfilter ? this.submitForm.status : '001',
      // start : 0,
      // end : Number(this.itemPerPage),
      // filename : filename,
      // searchtext : '',
      connectionid: this.connectionId,
    };
    console.log(data);
    this.kunyekService.getInOutReport(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.inoutList = res.datalist;
          // this.key = res.key;
          // this.filename = res.filename;
          // this.total = res.totalcount;
          // this.inoutfileurl = res.fileurl;
          // if(res.datalist.length < this.itemPerPage) {
          //   this.nextDisabled = true;
          //   this.lastPage = this.itemPerPage;
          // }
        } else {
          this.inoutList = [];
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.inoutList = [];
        this.gettingData = false;
        this.searchLoading = false;
        this.messageService.openSnackBar('Server Error', 'warn');
      }
    );
  }

  toggleFilter() {
    if (
      this.departmentList.length < 2
      // ||
      // this.divisionList.length < 2 ||
      // this.teamidList.length < 2 ||
      // this.costCenterList.length < 2 ||
      // this.subDivisionList.length < 2
    ) {
      this.getMemberPosition();
    }
    this.openfilter = !this.openfilter;
  }

  departmentChange(dep: any) {
    if (this.submitForm.department != dep) {
      this.submitForm.department = dep;
      this.submitForm.division = 'All';
      this.submitForm.teamid = 'All';
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  divisionChange(divi: any) {
    if (this.submitForm.division != divi) {
      this.submitForm.division = divi;
      this.submitForm.teamid = 'All';
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  teamidChange(teamid: any) {
    if (this.submitForm.teamid != teamid) {
      this.submitForm.teamid = teamid;
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  costCenterChange(costcenter: any) {
    if (this.submitForm.costcenter != costcenter) {
      this.submitForm.costcenter = costcenter;
      this.submitForm.subdivision = 'All';
    }
  }

  subDivisionChange(subdivision: any) {
    if (this.submitForm.subdivision != subdivision) {
      this.submitForm.subdivision = subdivision;
    }
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.getMemberSub = this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.departmentList = this.departmentList.concat(res.departmentlist);
          this.divisionList = this.divisionList.concat(res.divisionlist);
          this.teamidList = this.teamidList.concat(res.teamidlist);
          this.costCenterList = this.costCenterList.concat(res.costcenterlist);
          this.subDivisionList = this.subDivisionList.concat(res.subdivision);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingMemberPosition = false;
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }

  downloadSheet() {
    console.log('call');
    // console.log(this.locfileurl);
    // return this.http.get(this.locfileurl, { responseType: 'blob' });
    var startd = formatDate(
      new Date(this.submitForm.startdate),
      this.formatToShow,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.submitForm.enddate),
      this.formatToShow,
      'en-US'
    ).toString();
    var name = 'Time In Out Report' + '(' + startd + '-' + endd + ').xlsx';
    var exdata: any = [];
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet('Sheet 1');
    var filteredLocData = this.filter.transform(
      this.inoutList,
      this.searchText,
      15
    );
    filteredLocData.map((item: any) => {
      var temp = {
        EmployeeID: item.employeeid,
        UserID: item.userid,
        Name: item.username,
        Department: item.department,
        Division: item.division,
        TeamID: item.teamid,
        CostCenter: item.costcenter,
        Date: item.showdate,
        TimeIn: item.showintime,
        TimeInLocation: item.location,
        TimeInLatLong: item.latlong,
        TimeOut: item.showouttime,
        TimeOutLocation: item.outlocation,
        TimeOutLatLong: item.outlatlong,
        InLocationType: item.locationtype,
        OutLocationType: item.outlocationtype,
        Activity: item.activitydescription,
      };
      exdata.push(temp);
    });
    workSheet.addRow([
      'Employee ID',
      'User ID',
      'Name',
      'Department',
      'Division',
      'TeamID',
      'Cost Center',
      'Date',
      'Time In',
      'Time In Location',
      'Time In LatLong',
      'Time Out',
      'Time Out Location',
      'Time Out LatLong',
      'Activity',
    ]);
    exdata.forEach((item: any) => {
      const row = workSheet.addRow([
        item.EmployeeID,
        item.UserID,
        item.Name,
        item.Department,
        item.Division,
        item.TeamID,
        item.CostCenter,
        item.Date,
        item.TimeIn,
        item.TimeInLocation, // 9
        item.TimeInLatLong,
        item.TimeOut,
        item.TimeOutLocation, // 12
        item.TimeOutLatLong,
        item.Activity,
      ]);
      // for (let i = 1; i < 10; i++) {
      //   const col = row.getCell(i);
      //   col.font = {
      //     color: {
      //       argb: item.LocationType == '3' ? '000000' : 'FF0000',
      //     },
      //   };
      // }
      const col = row.getCell(10);
      col.font = {
        color: {
          argb: item.InLocationType == '3' ? '000000' : 'FF0000',
        },
      };
      const col2 = row.getCell(13);
      col2.font = {
        color: {
          argb: item.OutLocationType == '3' ? '000000' : 'FF0000',
        },
      };
    });
    workBook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, name);
    });
  }

  // dateChange() {
  //   this.filename = '';
  //   this.inoutfileurl = '';
  //   this.searchfilename = '';
  // }

  setLastDat() {
    // this.dateChange();
    var currentEndDate = formatDate(
      this.submitForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    var addDays = 35;
    var date = new Date(this.submitForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;
    if (
      this.submitForm.enddate > this.minDate ||
      this.submitForm.enddate < this.submitForm.startdate
    ) {
      this.submitForm.enddate = this.submitForm.startdate;
    }
  }

  sortData(sort: Sort) {
    const data = this.inoutList;
    if (!sort.active || sort.direction === '') {
      this.inoutList = data;
      return;
    }
    this.inoutList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'employeeid') {
        return this.allinoneService.compare(a.employeeid, b.employeeid, isAsc);
      } else if (sort.active == 'userid') {
        return this.allinoneService.compare(a.userid, b.userid, isAsc);
      } else if (sort.active == 'username') {
        return this.allinoneService.compare(a.username, b.username, isAsc);
      } else if (sort.active == 'department') {
        return this.allinoneService.compare(a.department, b.department, isAsc);
      } else if (sort.active == 'division') {
        return this.allinoneService.compare(a.division, b.division, isAsc);
      } else if (sort.active == 'showtype') {
        return this.allinoneService.compare(a.showtype, b.showtype, isAsc);
      } else if (sort.active == 'showdate') {
        var date1 = this.allinoneService.formatDateForInOutReportDate(
          a.showdate
        );
        var date2 = this.allinoneService.formatDateForInOutReportDate(
          b.showdate
        );
        // console.log('test');
        // console.log(a.showdate);
        // console.log(date1);

        var datetime1 = new Date(date1);
        var datetime2 = new Date(date2);
        // console.log('@@@');
        // console.log(date1+' '+a.showtime);

        // console.log(datetime1);
        // console.log(datetime2);

        return this.allinoneService.compare(datetime1, datetime2, isAsc);
      }
      // else if(sort.active == 'showintime') {
      //   var inTime1 = new Date(a.showintime);
      //   var inTime2 = new Date(b.showintime);
      //   return this.allinoneService.compare(inTime1, inTime2, isAsc);
      // }
      // else if(sort.active == 'showouttime') {
      //   var outTime1 = new Date(a.showouttime);
      //   var outTime2 = new Date(b.showouttime);
      //   return this.allinoneService.compare(outTime1, outTime2, isAsc);
      // }
      // else if(sort.active == 'showintime') {
      //   return this.allinoneService.compare(a.showintime, b.showintime, isAsc);
      // }
      // else if(sort.active == 'showouttime') {
      //   return this.allinoneService.compare(a.showouttime, b.showouttime, isAsc);
      // }
      // else if (sort.active == 'location') {
      //   return this.allinoneService.compare(a.location, b.location, isAsc);
      // }
      // else if (sort.active == 'outlocation') {
      //   return this.allinoneService.compare(a.outlocation, b.outlocation, isAsc);
      // }
      return 0;
    });
  }

  // resetPagination() {
  //   this.currentPage = 1;
  //   this.lastPage = '';
  //   this.nextDisabled = false;
  // }

  // pageChange(event: any) {
  //   // console.log(event);
  //   if (this.nextDisabled == true) {
  //     if (event < this.lastPage) {
  //       this.currentPage = event;
  //       if (event < 1) {
  //       } else {
  //         this.nextDisabled = false;
  //       }
  //     }
  //   } else {
  //     console.log('else con');

  //     if (event == this.lastPage && this.lastPage != '') {
  //       this.currentPage = event;
  //       this.nextDisabled = true;
  //     } else {
  //       this.currentPage = event;
  //       if (event > this.inoutList.length / this.itemPerPage) {
  //         this.searchWithPagination();
  //       }
  //       else{
  //         this.nextDisabled = false;
  //       }
  //     }
  //   }
  // }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
  }

  filterReport() { }

  blank() { }

  clear() {
    this.searchText = '';
  }
}
