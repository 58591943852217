<div class ="main" mat-dialog-content>
    <div class = "profile-image mb-3 me-0">
        <span *ngIf="post.type == '1'">
            <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="post.domainurl ? post.domainurl : '../../../assets/images/post-admin.png'">
        </span>
        <span *ngIf="post.type == '2' || post.type == '4'">
            <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="post.adminimage ? post.adminimage : '../../../assets/images/profile.png'">
        </span>
        <span *ngIf="post.type == '3'">
            <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="post.orgimageurl ? post.orgimageurl :'../../../assets/images/post-admin.png'">
        </span>
    </div>
    <p>
        <b>
            Block 
            <span *ngIf = "post.type == '1'">{{allinoneService.getDomainDesc(post.domain)}}</span>
            <span *ngIf = "post.type == '2'">{{post.admin ? post.admin: post.adminid}}</span>
            <span *ngIf = "post.type == '3'">{{post.orgname}}</span>?
        </b>
    </p>
</div>

<div mat-dialog-actions style="justify-content: space-evenly;">
    <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary" [disabled] = "saveLoading">
        Cancel
    </button>
    <button type="button" (click)="onConfirm()" class="btn btn-custom" cdkFocusInitial [disabled] = "saveLoading">
        <span *ngIf = "!saveLoading">Block</span>
        <span *ngIf = "saveLoading" class = "justify-content-center">
            <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
            <span class="mx-1">Blocking</span>
        </span>
    </button>
</div>