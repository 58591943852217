<div *ngIf="!isError || (isError && reupload)">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div>
      <h3 class="mb-0 me-2">Excel File Upload</h3>
      <span class="filename" (click)="downloadSample()">Sample Template</span>
    </div>
    <div>
      <mat-icon class="td-edit" (click)="onDismiss()">close</mat-icon>
    </div>
  </div>
  <div class="dragdrop form-control p-0 mb-3" name="file" id="file">
    <ng-container *ngIf="fileSelected != ''; else uploadTemplate">
      <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3" appDragDrop
        (onFileDropped)="uploadFile($event)">
        <div>
          <img src="../../../../assets/images/excel.png" width="40px" />
        </div>
        <div class="ms-2">
          <strong>{{ fileSelected.name }}</strong>
        </div>
      </div>
    </ng-container>
    <ng-template #uploadTemplate>
      <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
        (onFileDropped)="uploadFile($event)">
        <div class="me-3">Drag and drop file here</div>
        <div class="me-3">or</div>
        <div>
          <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
          <button type="button" class="btn btn-custom" (click)="choosefile.click()">
            Choose File
          </button>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-container *ngIf="saveLoading">
    <mat-progress-bar class="example-margin" color="primary" mode="determinate" [value]="progress">
    </mat-progress-bar>
    <div class="d-flex align-items-center justify-content-center">
      {{progress}}%
    </div>
  </ng-container>
  <div class="d-flex align-items-center justify-content-end">
    <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary mx-2" [disabled]="saveLoading">
      Cancel
    </button>
    <app-button-loader (click)="uploadfile()" [button_text]="'Upload'" [load_button_text]="'Uploading'"
      [isLoading]="saveLoading">
    </app-button-loader>
  </div>
</div>

<div *ngIf="isError && !reupload">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div>
      <h3 class="mb-0 me-2">Import Error</h3>
    </div>
    <div>
      <mat-icon class="td-edit" (click)="onDismiss()">close</mat-icon>
    </div>
  </div>
  <ul class="list-group">
    <li class="list-group-item">
      <div class="row">
        <div>
          Imported File
        </div>
        <div>
          <span class="filename">
            <span (click)="downloadFile(1)">{{errorData.originfile}}</span>
            <i class="fa fa-download ms-2" (click)="downloadFile(1)"></i>
          </span>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div>
          {{errorData.errorfile ? 'Error File' : 'Error Message'}}
        </div>
        <div>
          <span class="filename" *ngIf="errorData.errorfile">
            <span (click)="downloadFile(2)">{{errorData.errorfile}}</span>
            <i class="fa fa-download ms-2" (click)="downloadFile(2)"></i>
          </span> 
          <span *ngIf="!errorData.errorfile">
            {{errorData.errorreturn}}
          </span>
        </div>
      </div>
    </li>
  </ul>
  <div class="d-flex align-items-center justify-content-end mt-3">
    <button type="button" (click)="cancelImport()" class="btn btn-outline-secondary mx-2" [disabled]="saveLoading">
      <span *ngIf="!saveLoading">
        Cancel Upload
      </span>
      <span *ngIf="saveLoading">
        <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
      </span>
    </button>
    <button type="button" (click)="reuploadFile()" class="btn btn-custom" [disabled]="saveLoading">
      Reupload
    </button>
  </div>
</div>