<div class="title-container mb-1">
    <div class="title">
        Change Group Photo
    </div>
    <!-- <div class="close-btn">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div> -->
</div>
<div *ngIf="!showCropTool" class="w-100 d-flex justify-content-center align-items-center">
    <div class="w-100" style="position: relative;">
        <div class="uploadfilecontainer w-100 d-flex justify-content-center"  appDragDrop
            (onFileDropped)="uploadFile($event)">
            <img *ngIf="submitForm.imgURL" class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="submitForm.imgURL ? submitForm.imgURL : '../../../assets/images/uploadphoto.png'">
            <div *ngIf="!submitForm.imgURL" class="d-flex justify-content-center align-content-center" >
                <span class="me-2 d-flex align-items-center">Drag and drop or</span>
                <button class="btn btn-custom" (click)="fileInput.click()">Upload</button>
            </div>

            <input hidden type="file" #fileInput accept='image/*' (change)="uploadFile1($event)">
        </div>
        <button mat-icon-button class="remove-drag-image" *ngIf="submitForm.imgURL" (click)="removeImage()">
            <mat-icon>remove_circle</mat-icon>
        </button>
    </div>

</div>
<!-- <div *ngIf="imageChangedEvent != '' && !croppedImage" class="img-cropper"> -->
<div *ngIf="imageChangedEvent != '' && showCropTool" class="img-cropper">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [roundCropper]="true" [maintainAspectRatio]="true"
        [aspectRatio]="1/1" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" [imageBase64]="submitForm.imgURL"
        (loadImageFailed)="loadImageFailed()">
    </image-cropper>
    <!-- [imageBase64]="submitForm.imgURL" -->

    <!-- <button mat-flat-button color="primary" class="text-end" (click)="copyImage()">Crop
      Image</button> -->
</div>
<div *ngIf="imageChangedEvent != '' && showCropTool" class="w-100 d-flex justify-content-end">
    <button class="mt-2 btn btn-custom" (click)="copyImage()">Crop Image</button>
</div>

<!-- <button mat-flat-button color="btn btn-custom" class="mt-2 me-auto" (click)="copyImage()"
    *ngIf="imageChangedEvent != '' && showCropTool">Crop
    Image</button> -->
<!-- <div class="profile-img-container mt-3">
    <div style="position: relative;">
        <div class="uploadfilecontainer w-100 d-flex justify-content-center align-content-center"
            (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
            <img *ngIf="submitForm.imgURL" class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="submitForm.imgURL ? submitForm.imgURL : '../../../assets/images/uploadphoto.png'">

            <input hidden type="file" #fileInput accept='image/*' (change)="uploadFile1($event)">
        </div>
        <button mat-icon-button class="remove-drag-image" *ngIf="submitForm.imgURL" (click)="removeImage()">
            <mat-icon>remove_circle</mat-icon>
        </button>
    </div>
</div> -->
<div *ngIf="!showCropTool" class="close-save-button justify-content-end">
    <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saveLoading">
        Cancel
    </button>

    <button class="btn btn-custom my-1" *ngIf="!saveLoading" (click)="submit()">
        Save
    </button>
    <button class="btn btn-custom" *ngIf="saveLoading" disabled>
        <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
        &nbsp;
        Saving
    </button>

    <!-- <app-button-loader (click)="submitProfile()" style="width: 100px !important;" [button_text]="'Save'"
        class="my-1" [load_button_text]="'Saving'" [isLoading]="saveLoading">
    </app-button-loader> -->
</div>