<!-- <div class="d-flex align-items-center flex-row" class="back">
    <div>
        <img src="assets/images/kunyek1.png" alt="" class="navbar-brand-logo1" style="height: 50px;">

    </div>
    
        <h2 style="font-family: Ubuntu;
        font-size: 21px;
        margin-top: 3px;
        color: var(--main-color);
        text-shadow: 0px 2px 2px rgba(85, 85, 85, 0.4);">Kunyek</h2>
    
</div> -->
<section class="text-sm-start">
    <div class="signup-wrapper">
        <div class="mb-3">
            <img [src]="allinoneService.isQrModule ? 'assets/images/qr360.png' :'assets/images/kunyek1.png'" alt="" class="navbar-brand-logo">
            <h3 style="font-weight: 600;" class="mb-1">Enter User ID</h3>
            <!-- <span>to receive a verification code.</span> -->
        </div>

        <form [formGroup]="signupForm" (ngSubmit)="signUp()">

            <div class="form-group my-2">
                <input type="text" class="form-control" autofocus placeholder="Email or Phone Number"
                    formControlName="userid" autocomplete="username"
                    [ngClass]="{ 'is-invalid': signupFormSubmitted && signupFormControl.userid.errors }">
                <div *ngIf="signupFormSubmitted && signupFormControl.userid.errors" class="invalid-feedback">
                    <div *ngIf="signupFormControl.userid.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div>

            <div class="col-sm-12 offset-0 mt-3 mb-2">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="remember" name="_remember"
                        formControlName="isrememberme" (change)="onRememberMeChange($event)">
                    <label class="form-check-label mb-1 ms-1" for="remember">
                        Remember me
                    </label>
                </div>
            </div>

            <app-button-loader [isLoading]="isStillSigningIn" [button_text]="'Sign In'"
                [load_button_text]="'Signing in'" [width]="'100'">
            </app-button-loader>
        </form>
        <div class="center mt-3" *ngIf="!allinoneService.isQrModule">
            <button type="button" class="btn btn-light logintype" (click)="qrLogin()">SIGN IN BY QR CODE</button>
        </div>
    </div>
</section>