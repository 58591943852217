import { Component, HostListener, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { data } from 'jquery';
import { MapComponent } from '../map/map.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (window.innerWidth < this.viewWidth) {
      this.isView = true;
    } else {
      this.isView = false;
    }
  }

  role: any;
  userid: any;

  saveLoading: boolean = false;
  locformSubmitted: boolean = false;
  gettingDomainList: boolean = false;
  gettingOrgList: boolean = false;
  isEdition: boolean = false;
  isFromOrg: boolean = false;

  locAddorNot: boolean = false;
  locListLoading: boolean = false;
  isMobileView: boolean = false;
  isView: boolean = false;

  autoHide: boolean = true;
  responsive: boolean = true;
  isFocus: boolean = false;

  domains: any = [];
  organizations: any = [];
  locListToShow: any = [];
  locTypeList: any = [];

  mobileViewWidth: any = 426;
  viewWidth: any = 992;
  itemPerPage: any = 100;
  p: number = 1;
  type: number = 7;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  org_name: any = '';
  searchText: any = '';

  locForm = {
    locid: '',
    loctype: '3',
    domain: '',
    org: '',
    locname: '',
    lat: '',
    long: '',
    range: '100',
    description: '',
  };

  rootLocTypeList = [
    {
      name: 'Global',
      value: '1',
    },
    {
      name: 'Domain',
      value: '2',
    },
    {
      name: 'Organization',
      value: '3',
    },
    {
      name: 'Personal',
      value: '4',
    },
  ];

  domainLocTypeList = [
    {
      name: 'Domain',
      value: '2',
    },
    {
      name: 'Organization',
      value: '3',
    },
    {
      name: 'Personal',
      value: '4',
    },
  ];

  orgLocTypeList = [
    {
      name: 'Organization',
      value: '3',
    },
    {
      name: 'Personal',
      value: '4',
    },
  ];

  constructor(
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    public kunyekService: KunyekService,
    public allInOneService: AllInOneService,
    public messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (!this.allinoneService.isAdminOf('org')) {
      this.router.navigateByUrl('/home');
    }
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    if (window.innerWidth < this.viewWidth) {
      this.isView = true;
    }

    // this.role = this.allinoneService.getRole()
    this.userid = this.allInOneService.getKunyekUserId();
    this.locForm.domain = this.allinoneService.getDomain().domainid;
    // if(this.role == "100" || this.role == "200"){
    //   if (this.role == "100") {
    //     this.locTypeList = this.rootLocTypeList
    //     var tempDomain: any = this.allinoneService.getDomain()
    //     this.locForm.domain = tempDomain.domainid
    //     // this.getOrganization()
    //   }
    //   else if (this.role == "200") {
    //     var tempDomain: any = this.allinoneService.getDomain()
    //     this.locForm.domain = tempDomain.domainid
    //     this.locTypeList = this.domainLocTypeList
    //     // this.getOrganization()
    //   }
    //       //check route from org
    //   this.locForm.org = this.route.snapshot.queryParamMap.get('org') || "";
    //   if (this.locForm.org != "") {
    //   this.isFromOrg = true;
    //   this.locForm.loctype = '3';
    //   var tempOrg: any = this.allinoneService.getOrganization();
    //   this.org_name = tempOrg.name;

    //   const data = {
    //     "userid": "",
    //     "domainid": "",
    //     "orgid": [this.locForm.org],
    //     "global": "",
    //   }
    //   this.getLocation(data)
    //   }
    //   else{
    //     this.getOrganization()
    //   }
    // }
    // else if (this.role == "300") {
    this.organizations = this.allinoneService.orgs;
    this.locForm.org = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.locTypeList = this.orgLocTypeList;
    const data = {
      personal: '',
      domainid: '',
      orgid: [this.locForm.org],
      global: '',
    };
    this.getLocation(data);
    // }
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  clear() {
    this.searchText = '';
  }

  sortData(sort: Sort) {
    const data = this.locListToShow;
    if (!sort.active || sort.direction === '') {
      this.locListToShow = data;
      return;
    }

    this.locListToShow = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
          case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'location':
          return this.allinoneService.compare(
            a.lat + ', ' + a.lng,
            b.lat + ', ' + b.lng,
            isAsc
          );
        case 'range':
          return this.allinoneService.compare(
            Number(a.range),
            Number(b.range),
            isAsc
          );
        case 'desc':
          return this.allinoneService.compare(a.desc, b.desc, isAsc);
        default:
          return 0;
      }
    });
  }

  refresh() {
    if (this.locForm.loctype == '1') {
      const data = {
        personal: '',
        domainid: '',
        orgid: [],
        global: '1',
      };
      this.getLocation(data);
    } else if (this.locForm.loctype == '2') {
      const data = {
        personal: '',
        domainid: this.locForm.domain,
        orgid: [],
        global: '',
      };
      this.getLocation(data);
    } else if (this.locForm.loctype == '3') {
      const data = {
        personal: '',
        domainid: '',
        orgid: [this.locForm.org],
        global: '',
      };
      this.getLocation(data);
    } else if (this.locForm.loctype == '4') {
      const data = {
        personal: this.userid,
        domainid: '',
        // orgid: [],
        orgid : this.locForm.org,
        global: '',
      };
      this.getLocation(data);
    }
  }

  getDomainList(): void {
    this.gettingDomainList = true;
    this.domains = [];
    this.kunyekService.getDomains().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.domains.map((domain: any) => {
            this.domains.push({
              id: domain.id,
              appid: domain.appid,
              shortcode: domain.shortcode,
              domainid: domain.domainid,
              description: domain.description,
            });
          });
          this.gettingDomainList = false;
        } else {
          this.gettingDomainList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingDomainList = false;
      }
    );
  }

  getOrganization() {
    this.gettingOrgList = true;
    this.organizations = [];
    this.kunyekService.getOrgByDomainId(this.locForm.domain).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.datalist.map((org: any) => {
            this.organizations.push({
              orgid: org.orgid,
              appid: org.appid,
              createdby: org.createdby,
              createddate: org.createddate,
              domainid: org.domainid,
              name: org.name,
              description: org.description,
            });
          });
          this.locForm.org = this.organizations[0].orgid;
          const data = {
            personal: '',
            domainid: '',
            orgid: [this.locForm.org],
            global: '',
          };
          this.getLocation(data);
          this.gettingOrgList = false;
        } else {
          this.gettingOrgList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingOrgList = false;
      }
    );
  }

  getLocation(data: any) {
    this.locListToShow = [];
    this.locListLoading = true;
    console.log(data);
    var fun = this.locForm.loctype == '4' ? this.kunyekService.getPersonalLocations(data) : this.kunyekService.getLocations(data);
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.datalist.map((loc: any) => {
            this.locListToShow.push({
              userid : loc.userid,
              username : loc.username,
              locid: loc.locid,
              lat: loc.lat,
              lng: loc.lng,
              name: loc.name,
              range: loc.range,
              type: loc.type,
              typeid: loc.typeid,
              description: loc.description,
            });
          });
          this.pg[3].count = this.locListToShow.length;
          this.locListLoading = false;
        } else {
          this.locListLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.locListLoading = false;
      }
    );
  }

  map() {
    const dialog = this.dialog.open(MapComponent, {
      height: '400px',
      width: '65vw',
      closeOnNavigation: true,
      panelClass: ['map-modal', 'mat-typography'],
      data: {
        lat: this.locForm.lat,
        long: this.locForm.long,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.locForm.lat = dialogResult.lat;
        this.locForm.long = dialogResult.long;
      }
    });
  }

  cancel() {
    this.locAddorNot = !this.locAddorNot;
    this.isEdition = false;
    this.locformSubmitted = false;
    this.locForm = {
      locid: '',
      loctype: this.locForm.loctype,
      domain: this.locForm.domain,
      org: this.locForm.org,
      locname: '',
      lat: '',
      long: '',
      range: '100',
      description: '',
    };
  }

  onKeypress(e: any) {
    if (e.key == '.' && e.target.value.length == 0) {
      return false;
    }

    if (e.key === 'e') return e.preventDefault();
  }

  submitLocation() {
    this.locformSubmitted = true;
    if (this.locForm.loctype != '') {
      if (this.locForm.loctype == '2' && this.locForm.domain == '') {
        return;
      } else if (
        this.locForm.loctype == '3' &&
        this.locForm.domain == '' &&
        this.locForm.org == ''
      ) {
        return;
      } else if (
        this.locForm.loctype == '3' &&
        this.locForm.domain != '' &&
        this.locForm.org == ''
      ) {
        return;
      } else if (
        this.locForm.locname != '' &&
        this.locForm.lat != '' &&
        this.locForm.long != '' &&
        this.locForm.range != ''
      ) {
        var reg = new RegExp('^[0-9]+(.[0-9]+)*$');
        var templat = this.locForm.lat;
        var templong = this.locForm.long;
        if (templat.toString().includes('-')) {
          templat = templat.toString().substring(1);
        }

        if (templong.toString().includes('-')) {
          templong = templong.toString().substring(1);
        }

        console.log(templat + ',' + templong);

        if (
          reg.test(templat) &&
          reg.test(templong) &&
          reg.test(this.locForm.range)
        ) {
          this.saveLoading = true;
          this.saveLocation();
        }
      }
    }
  }

  saveLocation() {
    var data = {};

    if (this.locForm.loctype == '1') {
      data = {
        orgid: this.locForm.org,
        domainid: this.locForm.domain,
        name: this.locForm.locname,
        lat: this.locForm.lat.toString(),
        lng: this.locForm.long.toString(),
        range: this.locForm.range.toString(),
        description: this.locForm.description,
        typeid: 'global',
        type: this.locForm.loctype.toString(),
      };
    } else if (this.locForm.loctype == '2') {
      data = {
        orgid: this.locForm.org,
        domainid: this.locForm.domain,
        name: this.locForm.locname,
        lat: this.locForm.lat.toString(),
        lng: this.locForm.long.toString(),
        range: this.locForm.range.toString(),
        description: this.locForm.description,
        typeid: this.locForm.domain.toString(),
        type: this.locForm.loctype.toString(),
      };
    } else if (this.locForm.loctype == '3') {
      data = {
        orgid: this.locForm.org,
        domainid: this.locForm.domain,
        name: this.locForm.locname,
        lat: this.locForm.lat.toString(),
        lng: this.locForm.long.toString(),
        range: this.locForm.range.toString(),
        description: this.locForm.description,
        typeid: this.locForm.org.toString(),
        type: this.locForm.loctype.toString(),
      };
    } else if (this.locForm.loctype == '4') {
      data = {
        orgid: this.locForm.org,
        domainid: this.locForm.domain,
        name: this.locForm.locname,
        lat: this.locForm.lat.toString(),
        lng: this.locForm.long.toString(),
        range: this.locForm.range.toString(),
        description: this.locForm.description,
        typeid: this.userid.toString(),
        type: this.locForm.loctype.toString(),
      };
    }

    if (this.isEdition) {
      data = Object.assign(data, { locid: this.locForm.locid });
    }

    var fun = this.isEdition
      ? this.kunyekService.updateLocation(data)
      : this.kunyekService.addLocation(data);
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.locAddorNot = !this.locAddorNot;
          this.saveLoading = false;
          this.locformSubmitted = false;
          this.isEdition = false;

          this.locForm = {
            locid: '',
            loctype: this.locForm.loctype,
            domain: this.locForm.domain,
            org: this.locForm.org,
            locname: '',
            lat: '',
            long: '',
            range: '100',
            description: '',
          };
          this.messageService.openSnackBar(res.message, 'success');
          if (this.locForm.loctype == '1') {
            const data = {
              personal: '',
              domainid: '',
              orgid: [],
              global: '1',
            };
            this.getLocation(data);
          } else if (this.locForm.loctype == '2') {
            const data = {
              personal: '',
              domainid: this.locForm.domain,
              orgid: [],
              global: '',
            };
            this.getLocation(data);
          } else if (this.locForm.loctype == '3') {
            const data = {
              personal: '',
              domainid: '',
              orgid: [this.locForm.org],
              global: '',
            };
            this.getLocation(data);
          } else if (this.locForm.loctype == '4') {
            const data = {
              personal: this.userid,
              domainid: '',
              // orgid: [],
              orgid: this.locForm.org,
              global: '',
            };
            this.getLocation(data);
          }
        } else {
          // this.locAddorNot = !this.locAddorNot;
          this.saveLoading = false;
          this.locformSubmitted = false;
          // this.locForm = {
          //   "locid": this.locForm.locid,
          //   "loctype": this.locForm.loctype,
          //   "domain": this.locForm.domain,
          //   "org": this.locForm.org,
          //   "locname": '',
          //   "lat": '',
          //   "long": '',
          //   "range": '100',
          //   "description": ''
          // }
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.locformSubmitted = false;
        // this.isEdition = false;
      }
    );
  }

  openEditForm(item: any) {
    console.log(item);
    this.locAddorNot = !this.locAddorNot;
    this.isEdition = true;
    this.locForm = {
      locid: item.locid,
      loctype: this.locForm.loctype,
      domain: this.locForm.domain,
      org: this.locForm.org,
      locname: item.name,
      lat: item.lat,
      long: item.lng,
      range: item.range,
      description: item.description,
    };
  }

  locTypeChanged(event?: any, fromOrgVal?: any) {
    if (event) {
      this.locForm.loctype = event.target.value;
    }
    if (fromOrgVal) {
      this.locForm.loctype = fromOrgVal;
    }

    console.log('your type');
    console.log(this.locForm.loctype);
    
    if (this.locForm.loctype == '1' || this.locForm.loctype == '4') {
      // if (this.role == "100" || this.role == "200") {
      //   this.locForm.org = ""
      // }

      // if (this.locForm.loctype == "1") {
      //   const data = {
      //     "userid": "",
      //     "domainid": "",
      //     "orgid": [],
      //     "global": "1",
      //   }
      //   this.getLocation(data)
      // }
      if (this.locForm.loctype == '4') {
        const data = {
          personal: this.userid,
          domainid: '',
          // orgid: [],
          orgid: this.locForm.org,
          // global: '',
        };
        this.getLocation(data);
      }
    }
    // else if (this.locForm.loctype == "2") {
    //   this.locForm.org = ""
    //   this.locListToShow = []
    //   const data = {
    //     "userid": "",
    //     "domainid": this.locForm.domain,
    //     "orgid": [],
    //     "global": "",
    //   }
    //   this.getLocation(data)
    // }
    else if (this.locForm.loctype == '3') {
      this.locListToShow = [];
      // if (this.role == "300") {
      const data = {
        personal: '',
        domainid: '',
        orgid: [this.locForm.org],
        global: '',
      };

      this.getLocation(data);
      // } else {
      //   this.getOrganization()
      // }
    } else {
      this.locListToShow = [];
    }
  }

  // domainChanged(event: any) {
  //   this.locForm.domain = event.target.value;
  //   this.locForm.org = ""
  //   if (this.locForm.loctype == "2") {
  //     const data = {
  //       "userid": "",
  //       "domainid": this.locForm.domain,
  //       "orgid": "",
  //       "global": "",
  //     }
  //     this.getLocation(data)
  //   }
  //   else if (this.locForm.loctype == "3" && this.locForm.domain != "") {
  //     this.getOrganization()
  //   }
  // }

  orgChanged(event?: any) {
    if (event) {
      this.locForm.org = event.target.value;
      this.allinoneService.selectedOrg = this.locForm.org;
      this.allinoneService.setSelectedOrg(this.locForm.org);
    }

    const data = {
      personal: '',
      domainid: '',
      orgid: [this.locForm.org],
      global: '',
    };
    this.getLocation(data);
  }

  deleteLocation(locid: any, typeid: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.locListLoading = true;
        const data = {
          orgid: this.locForm.org,
          domainid: this.locForm.domain,
          locid: locid,
          typeid: typeid,
        };
        this.kunyekService.deleteLocation(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              if (this.locForm.loctype == '1') {
                const data = {
                  personal: '',
                  domainid: '',
                  orgid: [],
                  global: '1',
                };
                this.getLocation(data);
              } else if (this.locForm.loctype == '2') {
                const data = {
                  personal: '',
                  domainid: this.locForm.domain,
                  orgid: [],
                  global: '',
                };
                this.getLocation(data);
              } else if (this.locForm.loctype == '3') {
                const data = {
                  personal: '',
                  domainid: '',
                  orgid: [this.locForm.org],
                  global: '',
                };
                this.getLocation(data);
              } else if (this.locForm.loctype == '4') {
                const data = {
                  personal: this.userid,
                  domainid: '',
                  // orgid: [],
                  orgid : this.locForm.org,
                  global: '',
                };
                this.getLocation(data);
              }
            } else {
              this.locListLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.locListLoading = false;
          }
        );
      }
    });
  }

  goToOrg() {
    this.router.navigateByUrl('organizations');
  }
  goToOrgEdit() {
    this.router.navigateByUrl(`organizations?org=${this.locForm.org}`);
  }

  openQR(item: any) {
    if (item.qrdata) {
      const qrdata =encodeURIComponent(item.qrdata);
      window.open(`checkin-qr/${qrdata}`, '_blank')
    } else {
      item.loading = true;
      const data = {
        orgid: this.locForm.org,
        lat: item.lat,
        long: item.lng,
        name: item.name,
        locationid: item.locid,
      };
      this.kunyekService.getLocationQR(data).subscribe((res: any) => {
        if(res.returncode == '300'){
          item.qrdata = res.qrdata;
          const qrdata =encodeURIComponent(item.qrdata);
          window.open(`checkin-qr/${qrdata}`, '_blank')
        }
        else{
          this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
        }
        item.loading = false;
      }, err=> {
        item.loading = false;
      });
    }
  }
}
