import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { LeaveSelectMemberComponent } from 'src/app/shared/components/leave-select-member/leave-select-member.component';
import { IMyDpOptions } from 'mydatepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-leave-form',
  templateUrl: './leave-form.component.html',
  styleUrls: ['./leave-form.component.scss']
})
export class LeaveFormComponent implements OnInit {
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };
  profileImageSrc: string = '../../../assets/images/uploadphoto.png';
  isSaving: boolean = false;
  leaveForm!: FormGroup;
  saved = false;
  requiredMessage = 'This field is required';
  breadcrumbs: any[] = [];
  tempfilename: any = "";
  leaveAttachment?: any;
  tempProfileImage: any;
  reader = new FileReader()
  fileIsSelected: boolean = false;

  


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private messageService: MessageService,
    private kunyek: KunyekService,
    private allInOneService: AllInOneService,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    public allInOneservice: AllInOneService,


  ) { }

  ngOnInit(): void {

    this.leaveForm = this.fb.group({
      type: ['', Validators.required],
      user: [{ value: '', disabled: true }, Validators.required],
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      reason: ['', Validators.required],
      member_syskey: ['', Validators.required],
      id: [''],

    });
    this.setBreadcrumbs();
  }

  setBreadcrumbs() {
    if (this.router.url === '/members/leavelist/new') {
      this.breadcrumbs = [
        { name: 'Leave', url: 'leave', isActive: true },
        { name: 'List', url: 'members/leavelist', isActive: true },
        { name: 'New', url: '', isActive: false }
      ];
    } else {
      this.breadcrumbs = [
        { name: 'Members', url: '', isActive: true },
        { name: 'Leave', url: '', isActive: false }
      ];
    }
  }
  onFileChanged(event: any) {
    const file = event.target?.files[0];
    if (file) {
      this.leaveAttachment = file;
      // const reader = new FileReader();
      // reader.onload = e => this.profileImageSrc = reader.result as string;
      // reader.readAsDataURL(file);
    }
  }
  // assignAttachmentsForRequest(index: any, filename: string) {
  //   this.filelistreq[index] = {
  //     name: `Attachment${index + 1}`,
  //     filename: filename
  //   }
  // }

  // onProfileClick() {
  //   const input = document.createElement('input');
  //   input.hidden = true;
  //   input.type = 'file';
  //   input.accept = 'image/*';
  //   input.addEventListener('change', (event: Event) => {
  //     this.onImageChange(event);
  //   });
  //   input.click();
  // }

  // private onImageChange(event: any): void {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = e => this.profileImageSrc = reader.result as string;
  //     reader.readAsDataURL(file);
  //   }
  // }
  async uploadFiles() {
    const defaultpath = 'kokaimember/members';

    if (!this.tempfilename) {
      const date = new Date();
      const millisecond = date.getMilliseconds();
      this.tempfilename = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    }

    if (this.leaveAttachment) {
      const profilepath = `${defaultpath}/${this.tempfilename}/${this.leaveAttachment.name}`;
      const upload_aws = await this.allInOneservice.uploadAnswerFile(this.leaveAttachment, profilepath);
      if (!upload_aws) {
        this.messageService.openSnackBar('Unknown error occurred while uploading your files.', 'warn');
        return;
      }
      this.tempProfileImage = profilepath;
    }
  }

 async onSaved() {
    this.saved = true;
    if (this.leaveForm.valid) {
      this.isSaving = true;
      await this.uploadFiles()
      const formattedFromDate = this.from_date?.value?.formatted;
      const formattedToDate = this.to_date?.value?.formatted;
      const formData = {
        member_syskey: this.member_syskey?.value,
        member_id: this.member_id?.value,
        type: this.type?.value,
        from_date: this.allInOneService.convertDate(formattedFromDate),
        to_date: this.allInOneService.convertDate(formattedToDate),
        duration: this.calculateDuration(),
        reason: this.reason?.value,
        tempfilename: this.tempfilename,
        attachment: this.leaveAttachment?.name,
      };
      this.kunyek.leave().create(formData).subscribe({
        next: (res) => {
          switch (res.returncode) {
            case "301":
              this.isSaving = false;
              this.messageService.openSnackBar(res.message, 'warn');
              break;
            default:
              this.isSaving = false;
              this.messageService.openSnackBar('Success!', 'success');
              this.closeForm()
          }
        },
        error: (err) => {
          this.messageService.openSnackBar('Failed to save leave', 'warn');
          this.isSaving = false;

        }
      });
    } else {
      this.messageService.openSnackBar('Invalid Form', 'warn');
      setTimeout(() => {
        this.isSaving = false;
      }, 1000);
    }
  }

  closeForm() {
    this.router.navigate(['/members', 'leavelist']);
  }

  calculateDuration(): number {
    // console.log(this.from_date?.value);
    const fromDate = this.leaveForm.get('from_date')?.value.jsdate;
    const toDate = this.leaveForm.get('to_date')?.value.jsdate;
    if (fromDate && toDate) {
      const from = moment(fromDate);
      const to = moment(toDate);
      return to.diff(from, 'days') + 1;
    }
    return 0;
  }
  checkAndOpenMemberModal() {

    this.openMemberModal();
  }
  openMemberModal() {
    const dialog = this.dialog.open(LeaveSelectMemberComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      // data: {
      //   : 
      // },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.leaveForm.patchValue({ user: dialogResult.data[0].name, member_syskey: dialogResult.data[0].syskey, id: dialogResult.data[0].userid });
        this.leaveForm.get('user')?.enable();
      }
    });
  }


  // Getter methods for easy access in template
  get type() { return this.leaveForm.get('type'); }
  get from_date() { return this.leaveForm.get('from_date'); }
  get to_date() { return this.leaveForm.get('to_date'); }
  get reason() { return this.leaveForm.get('reason'); }
  get user() { return this.leaveForm.get('user'); }
  get member_id() { return this.leaveForm.get('id'); }
  get member_syskey() { return this.leaveForm.get('member_syskey'); }
}
