<div class="container-fluid">
    <div class="content">
        <!-- <app-breadcrumb [s_breadcrumb]="'Organizations'" [t_breadcrumb]="orgAddorNot ? isEditing ? orgForm.orgname:  'New Organization': ''" (emit)="cancel()">
        </app-breadcrumb> -->

        <app-team-breadcrumb [s_breadcrumb]="'Organizations'" [t_breadcrumb]="orgAddorNot ? isEditing ? orgForm.orgname:  'New Organization': ''" (emit)="cancel()">
        </app-team-breadcrumb>


        <div class="mb-3 d-flex justify-content-between header">
            <div>
                <button *ngIf="!orgAddorNot" type="button" class="btn btn-custom" (click)="addOrg()">
          <i class="fa fa-plus me-1"></i>New Organization
        </button>
                <div *ngIf="orgAddorNot" class="close-save-button">
                    <button type="button" class="btn btn-custom me-2 my-1" (click)="cancel()" *ngIf="!saveLoading">
            Cancel
          </button>
                    <app-button-loader (click)="submitOrg()" [button_text]="'Save'" class="my-1" [load_button_text]="'Saving'" [isLoading]="saveLoading"></app-button-loader>

                    <div class="line" *ngIf="isEditing"></div>

                    <button *ngIf="isEditing" type="button" class="btn btn-custom me-2 my-1" (click)="viewMembers()" title="View Members">
            Members
          </button>
                    <!--<button *ngIf="isEditing" type="button" class="btn btn-custom me-2 my-1" (click)="viewLocations()"
                        title="View Locations">
                        Locations
                    </button>
                    <button *ngIf="isEditing" type="button" class="btn btn-custom me-2 my-1" (click)="viewEvents()"
                        title="View Members">
                        Events
                    </button> -->
                </div>
            </div>
        </div>

        <ng-container *ngIf="!orgAddorNot">
            <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
                <div class="input-group" style="width: 300px !important">
                    <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description" aria-label="Search by Name/Description" (focus)="isFocus = true" (blur)="isFocus = false" (ngModelChange)="searchTextChange()" [(ngModel)]="searchText" [ngClass]="{ 'c-search-input-with-clear': searchText }"
                        style="font-family: FontAwesome, Ubuntu">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
                </div>
                <div class="dropdown" class="ms-2">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
                    <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
                        <li>
                            <div style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                            <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
                        </li>
                    </ul>
                </div>
                <div class="ms-2">
                    <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
                </div>
                <div class="align-items-center ms-2" [matMenuTriggerFor]="statusmenu">
                    <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" return="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Status&nbsp;:&nbsp;{{ statusFilterName }}
            <mat-menu #statusmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let status of statusList" (click)="statusChange(status)">
                {{ status.name }}
              </button>
                    </mat-menu>
                    </button>
                </div>
                <div class="line"></div>
                <div class="d-flex align-items-center">
                    <span class="total-count-text">Total:&nbsp;</span>
                    <span class="total-count-num">{{ ( orgListToShow | filter : searchText: 'org' ).length }}</span>
                </div>


            </div>
        </ng-container>

        <form id="form" class="mb-4" *ngIf="orgAddorNot">
            <!-- <div class="mb-3" *ngIf="role == '100'">
                <label for="domain" class="form-label">Domain</label>
                <select class="form-select" aria-label="Select Domain" id="domain" name="domain"
                    (change)="domainChanged($event)" [(ngModel)]="orgForm.domain" required #domain="ngModel"
                    [ngClass]="{ 'is-invalid': orgSubmitted && domain.invalid }"
                    [disabled]="gettingDomainList || isEditing">
                    <option selected value="">Select Domain</option>
                    <option *ngFor="let domain of domains" [value]="domain.domainid">{{domain.description}}</option>
                </select>
                <mat-progress-bar mode="indeterminate" *ngIf="gettingDomainList"></mat-progress-bar>

                <div class="invalid-feedback"
                    *ngIf="domain.invalid || (domain.dirty || domain.touched) && orgSubmitted">
                    <div *ngIf="domain.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div> -->
            <div>
                <div>
                    <div>
                        Organization Image
                    </div>
                    <div style="position: relative;">
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
                            <!-- <span>Choose Image</span> -->
                            <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="imgURL ? imgURL : '../../../assets/images/uploadphoto.png'">
                            <!-- <mat-icon class="remove-image">remove_circle</mat-icon> -->

                            <input hidden type="file" #fileInput accept='image/*' (change)="uploadFile1($event)">
                        </div>
                        <button mat-icon-button class="remove-drag-image" *ngIf="imgURL" (click)="removeImage()">
              <mat-icon>remove_circle</mat-icon>
            </button>
                    </div>
                    <!-- <img [src]="imgURL" height="200" *ngIf="imgURL"> -->
                </div>
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="orgName" class="form-label">Organization Name</label>
                        <input type="text" class="form-control" [(ngModel)]="orgForm.orgname" id="orgname" name="orgname" placeholder="Organization-123" required #orgname="ngModel" [ngClass]="{ 'is-invalid': orgSubmitted && orgname.invalid }" (keyup.enter)="submitOrg()">

                        <div class="invalid-feedback" *ngIf="orgname.invalid || (orgname.dirty || orgname.touched) && orgSubmitted">
                            <div *ngIf="orgname.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="orgName" class="form-label">Short Code</label>
                        <input type="text" class="form-control" [(ngModel)]="orgForm.shortcode" id="shortcode" name="shortcode" placeholder="ORG123" required #shortcode="ngModel" [ngClass]="{ 'is-invalid': orgSubmitted && shortcode.invalid }" (keyup.enter)="submitOrg()">

                        <div class="invalid-feedback" *ngIf="shortcode.invalid || (shortcode.dirty || shortcode.touched) && orgSubmitted">
                            <div *ngIf="shortcode.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="orgDesc" class="form-label">Description</label>
                    <textarea class="form-control" id="description" [(ngModel)]="orgForm.description" name="description" rows="5" placeholder="Enter Description for Your Organization"></textarea>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="membership" class="form-label">Membership Types</label>
                        <select class="form-select" aria-label="Select Membership" id="membership" name="membership" (change)="msChanged($event)" [(ngModel)]="orgForm.membership" required #ms="ngModel">
              <option selected value="">Select Membership</option>
              <option *ngFor="let ms of memberships" [value]="ms.id" [selected]="orgForm.membership==ms.id">
                {{ms.name}}</option>
            </select>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="emaildomain" class="form-label">Organization Email</label>
                        <input type="text" class="form-control" [(ngModel)]="orgForm.emaildomain" id="emaildomain" name="emaildomain" placeholder="eg. mit.com.mm" required #emaildomain="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="ttype" class="form-label">Member Limit</label>
                        <select class="form-select" id="limit" name="limit" [(ngModel)]="limit" required #lim="ngModel" (change)="memberLimitChanged($event)">
              <option *ngFor="let li of limited" [value]="li.value" [selected]="limit==li.value">
                {{li.name}}</option>
            </select>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="tlength" class="form-label">Maximum Count</label>
                        <input [disabled]="limit == '002'" type="number" class="form-control" [(ngModel)]="orgForm.limit" id="count" [min]="5" name="count" required #cou="ngModel" (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="ttype" class="form-label">Task ID Type</label>
                        <select class="form-select" aria-label="Select Task ID Type" id="ttype" name="ttype" (change)="tasktypeChanged($event)" [(ngModel)]="orgForm.tasktype" required #ttype="ngModel">
              <option *ngFor="let tt of taskIdTypes" [value]="tt.id" [selected]="orgForm.tasktype==tt.id">
                {{tt.name}}</option>
            </select>
                    </div>
                    <div class="mb-3 col-md-6" *ngIf="orgForm.tasktype == '001'">
                        <label for="tlength" class="form-label">Task ID Length</label>
                        <select class="form-select" aria-label="Select Task ID Type" id="tlength" name="tlength" (change)="taskLengthChanged($event)" [(ngModel)]="orgForm.tasklength" required #tlength="ngModel">
              <option *ngFor="let tl of ['3','4','5','6','7','8']" [value]="tl" [selected]="orgForm.tasklength==tl">
                {{tl}}</option>
            </select>
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="singleposition" class="form-label">Single Position</label>
                        <select class="form-select" aria-label="Single Position" id="singleposition" name="singleposition" [(ngModel)]="orgForm.singleposition" required>
              <option [value]="'true'">
                Yes
              </option>
              <option [value]="'false'">
                No
              </option>
            </select>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="sameempid" class="form-label">Use Attendance ID</label>
                        <select class="form-select" aria-label="Use Attendance ID" id="sameempid" name="sameempid" [(ngModel)]="orgForm.sameempid" required>
              <option [value]="'true'">
                Yes
              </option>
              <option [value]="'false'">
                No
              </option>
            </select>
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="expirationdate" class="form-label">Expiration Date</label>
                    <div class="input-group">
                        <!-- <input type="date" class="form-control" name="expirationdate" id="expirationdate" autofocus placeholder=""
              [(ngModel)]="orgForm.expirationdate" autocomplete="off"> -->
                        <input type="text" class="form-control datepicker-readonly" name="expirationdate" id="expirationdate" placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="orgForm.expirationdate" style="min-width: 200px;" [bsConfig]="{showClearButton: true}">
                        <!-- <button class="btn" type="button" (click)="expdateclear()"
              style="background-color: var(--main-color);color: white;">
              <i class="fa fa-times"></i>
            </button> -->
                    </div>
                </div>
                <!-- <div class="mb-3 col-md-12">
          <label for="orgbannerimg">Organization Banner Image</label>
          <div class="banneruploadfilecontainer">
            <div class = "bannerdragcontainer"(click)="bannerFileInput.click()" appDragDrop (onFileDropped)="bannerUploadFile($event)">
              
              <img class="banner-drag-image" [ngClass]="{'banner-selected' : bannerImgURL}"
                [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="bannerImgURL ? bannerImgURL : '../../../assets/images/uploadphoto.png'">
              

              <input hidden type="file" #bannerFileInput accept='image/*' (change)="bannerUploadFile1($event)">

            </div>
            <button mat-icon-button class="banner-remove-drag-image" *ngIf="bannerImgURL" (click)="removeBannerImage()">
              <mat-icon>remove_circle</mat-icon>
            </button>
          </div>
        </div> -->
                <div class="mb-3 col-md-12">
                    <label for="remark" class="form-label">Configuration</label>
                    <div class="card card-body pb-0">
                        <div class="row g-0">
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="timeinoutrequired" name="timeinoutrequired" [(ngModel)]="orgForm.timeinoutrequired">
                                <label class="form-check-label" for="timeinoutrequired">
                  Time In/Out Required
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="activityrecordrequired" name="activityrecordrequired" [(ngModel)]="orgForm.activityrecordrequired">
                                <label class="form-check-label" for="activityrecordrequired">
                  Activity Record Required
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="personallocationapproval" name="personallocationapproval" [(ngModel)]="orgForm.personallocationapproval">
                                <label class="form-check-label" for="personallocationapproval">
                  Personal Location Approval
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="unregisteredtimeinoutapproval" name="unregisteredtimeinoutapproval" [(ngModel)]="orgForm.unregisteredtimeinoutapproval">
                                <label class="form-check-label" for="unregisteredtimeinoutapproval">
                  Unregistered Time In/Out Approval
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="backdateallowed" name="backdateallowed" [(ngModel)]="orgForm.backdateallowed">
                                <label class="form-check-label" for="backdateallowed">
                  Backdate Allowed
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="backdateapproval" name="backdateapproval" [(ngModel)]="orgForm.backdateapproval">
                                <label class="form-check-label" for="backdateapproval">
                  Backdate Approval
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="rolerequired" name="rolerequired" [(ngModel)]="orgForm.rolerequired">
                                <label class="form-check-label" for="rolerequired">
                  Role Required
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="userequisition" name="userequisition" [(ngModel)]="orgForm.userequisition">
                                <label class="form-check-label" for="userequisition">
                  Use Requisition
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="usetransportation" name="usetransportation" [(ngModel)]="orgForm.showtransportation">
                                <label class="form-check-label" for="usetransportation">
                  Use Transportation
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="useqr360" name="useqr360" [(ngModel)]="orgForm.useqr360">
                                <label class="form-check-label" for="useqr360">
                  Use for QR360
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="usepayroll" name="usepayroll" [(ngModel)]="orgForm.usepayroll">
                                <label class="form-check-label" for="usepayroll">
                  Use Payroll
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="neosync" name="neosync" [(ngModel)]="orgForm.neosync">
                                <label class="form-check-label" for="neosync">
                  Neo Payroll Sync
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3">
                                <input class="form-check-input" type="checkbox" id="hcmsync" name="hcmsync" [(ngModel)]="orgForm.hcmsync">
                                <label class="form-check-label" for="hcmsync">
                  HCMEE Sync
                </label>
                            </div>
                            <div class="form-check col-md-4 col-sm-6 col-12 mb-3" *ngIf="orgForm.membership!=''">
                                <input class="form-check-input" type="checkbox" id="eid" [(ngModel)]="orgForm.eid" name="eid" (ngModelChange)="eidChange()">
                                <label class="form-check-label mb-1 ms-1" for="eid">
                  eID
                </label>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="mb-3" *ngIf="orgForm.membership!='' && this.orgForm.eid == true">
                    <label for="layout" class="form-label">Layouts</label>
                    <div class="d-flex flex-wrap">
                        <div class="layout-container me-3" *ngFor="let layout of layouts;">
                            <div class="container">
                                <div class="round">
                                    <input type="checkbox" id="{{layout.id}}" value="{{layout.id}}" [checked]="checkedlayout==layout.id" [disabled]="checkedlayout==layout.id" (change)="checkLayout($event)" />
                                    <label for="{{layout.id}}"></label>
                                </div>
                            </div>
                            <div class="layout-item ">
                                <label for="{{layout.id}}">
                  <img [alt]="appname" [defaultImage]="'../../../assets/images/bg.jpg'"
                    [lazyLoad]="layout.url ? layout.url : '../../../assets/images/bg.jpg'">
                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
            <table class="table table-responsive table-borderless" *ngIf="orgAddorNot == false">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-1 col-md-1">Image</td>

                        <td scope="col" class="col-md-3" mat-sort-header="orgname">Organization Name</td>
                        <td scope="col" class="col-md-5" mat-sort-header="des">Description</td>
                        <td scope="col" class="col-md-2" mat-sort-header="expirationdate">Expiration Date</td>
                        <td scope="col" class="col-md-2" mat-sort-header="status">Status</td>
                        <td scope="col" class="d-flex justify-content-center">Action</td>
                    </tr>
                </thead>
                <tbody *ngIf="orgListLoading">
                    <tr>
                        <td colspan="4" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="orgListLoading == false">
                    <tr *ngIf="orgListToShow.length == 0">
                        <td colspan="4" class="nodata">Empty</td>
                    </tr>
                    <tr *ngFor="let item of orgListToShow | filter : searchText: 'org' | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage } ; let index = index">
                        <td class="td-edit" (click)="editOrg(item)">

                            <div>
                                <img class="preivew-image" [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="item.imageurl ? item.imageurl : '../../../assets/images/post_admin.png'">
                            </div>
                        </td>
                        <td class="td-data td-edit" (click)="editOrg(item)">
                            <span [title]="item.name">{{item.name}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.description">{{item.description}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.expirationdate">{{item.expirationdate ?
                allinoneService.formatDBToShow(item.expirationdate) : '-' }}</span>
                        </td>
                        <!-- <td class="td-data">
              <span [title]="item.status">{{item.status}}</span>
            </td> -->
                        <td class="td-data td-edit">
                            <span [title]="item.activestatus" class="status" [ngClass]="{
                  'status-green': item.activestatus == '001',
                  'status-red': item.activestatus == '002'
                }">{{ item.activestatus == '001' ? 'Active' : 'Inactive' }}</span>
                        </td>
                        <td class="d-flex justify-content-center">

                            <button class="btn btn-outline-primary" title="Edit" (click)="editOrg(item)">
                <i class='fa fa-edit'></i>
              </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="orgListToShow.length > itemPerPage && !orgAddorNot" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
    </div>
</div>