<div class="container-fluid">
  <div class="content">
    <div class="m-4">
      <div class="me-auto">
        <!-- <app-kmember-breadcrumb [s_breadcrumb]="'Gym'"></app-kmember-breadcrumb> -->
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
      </div>
      <div class="d-flex gap-2 mb-2">
        <button type="button" class="btn btn-custom" (click)="closeForm()">Cancel</button>
        <app-button-loader [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="isSaving"
          (click)="onSave()"></app-button-loader>
        <!-- <button type="button" class="btn btn-custom">Submit</button> -->
      </div>
      <div class="card">
        <div class="card-body m-2">
          <form [formGroup]="gymForm" (ngSubmit)="onSave()">
            <div class="row">
              <div class="col-md-10">
                <div class="row">

                  <div class="col-md-6 mb-3">
                    <label for="member" class="form-label">Member<font color="red">*</font></label>
                    <div class="input-group">
                      <input type="text" id="member" [readonly]="true" class="form-control" formControlName="member"
                        [ngClass]="{ 'is-invalid': member?.invalid && saved }">
                      <div class="btn btn-custom me-2" style="height: 36px" (click)="checkAndOpenMemberModal()">
                        <mat-icon>add</mat-icon>
                      </div>
                    </div>
                    <div class="invalid-feedback"
                      *ngIf="member?.invalid || ((member?.dirty || member?.touched) && saved)">
                      <div *ngIf="member?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <!-- Name -->
                  <div class="col-md-6 mb-3">
                    <label for="name" class="form-label">Name<font color="red">*</font></label>
                    <input type="text" id="name" class="form-control" formControlName="name"
                      [ngClass]="{'is-invalid': name?.invalid && saved}">
                    <div class="invalid-feedback" *ngIf="name?.invalid && saved">
                      <div *ngIf="name?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <!-- Parent Name -->
                  <div class="col-md-6 mb-3">
                    <label for="fatherName" class="form-label">Father Name</label>
                    <input type="text" id="fatherName" class="form-control" formControlName="fatherName">
                  </div>

                  <!-- NRC NO -->
                  <div class="col-md-6 mb-3">
                    <label for="nrcNo" class="form-label">NRC No.<font color="red">*</font></label>
                    <div class="g-2">
                      <div class="row g-2">
                        <div class="col-2">
                          <select class="form-select" id="nrcregioncode" name="nrcregioncode"
                            formControlName="nrcregioncode" required
                            [ngClass]="{ 'is-invalid': saved && nrcregioncode?.invalid }">
                            <option value="">-</option>
                            <option *ngFor="let item of nrcRegionCode" [value]="item">{{item}}</option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select class="form-select" id="nrctownshipcode" name="nrctownshipcode"
                            formControlName="nrctownshipcode" required
                            [ngClass]="{ 'is-invalid': saved && nrctownshipcode?.invalid }">
                            <option *ngFor="let item of nrcTownshipCode" [value]="item == '-' ? '' : item">{{item}}
                            </option>
                          </select>
                        </div>
                        <div class="col-2">
                          <select class="form-select" id="nrctype" name="nrctype" formControlName="nrctype" required
                            [ngClass]="{ 'is-invalid': saved && nrctype?.invalid }">
                            <option *ngFor="let item of nrcType" [value]="item == '-' ? '' : item">{{item}}</option>
                          </select>
                        </div>
                        <div class="col-4">
                          <input type="text" class="form-control" id="nrcnumber" name="nrcnumber"
                            formControlName="nrcnumber" minlength="6" maxlength="6" required
                            (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                            [ngClass]="{ 'is-invalid': saved && nrcnumber?.invalid }" />
                        </div>
                      </div>

                      <div class="invalid"
                        *ngIf="nrcregioncode?.invalid || nrctownshipcode?.invalid || nrctype?.invalid || nrcnumber?.invalid">
                        <small class="text-danger" *ngIf="(nrcregioncode?.errors?.required || nrctownshipcode?.errors?.required ||
                        nrctype?.errors?.required || nrcnumber?.errors?.required) && saved">{{ requiredMessage
                          }}</small>
                      </div>
                    </div>
                  </div>

                  <!-- Race/Religion -->
                  <div class="col-md-6 mb-3">
                    <label for="race" class="form-label">Race</label>
                    <input type="text" id="race" class="form-control" formControlName="race">
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="religion" class="form-label">Religion</label>
                    <input type="text" id="religion" class="form-control" formControlName="religion">
                  </div>

                  <!-- Length -->
                  <div class="col-md-6 mb-3">
                    <label for="length" class="form-label">Length(cm)</label>
                    <input type="text" id="length" class="form-control" formControlName="length">
                  </div>

                  <!-- Weight -->
                  <div class="col-md-6 mb-3">
                    <label for="weight" class="form-label">Weight(kg)</label>
                    <input type="text" id="weight" class="form-control" formControlName="weight">
                  </div>

                  <!-- Contact NO -->
                  <div class="col-md-6 mb-3">
                    <label for="contactNo" class="form-label">Contact No<font color="red">*</font></label>
                    <input type="tel" id="contactNo" class="form-control" formControlName="contactNo"
                      [ngClass]="{'is-invalid': contactNo?.invalid && saved}">
                    <div class="invalid-feedback" *ngIf="contactNo?.invalid && saved">
                      <div *ngIf="contactNo?.errors?.required">{{requiredMessage}}</div>
                      <div *ngIf="contactNo?.errors?.numericString">This field must be a number</div>
                    </div>
                  </div>

                  <!-- Occupation -->
                  <div class="col-md-6 mb-3">
                    <label for="occupation" class="form-label">Occupation</label>
                    <input type="text" id="occupation" class="form-control" formControlName="occupation">
                  </div>

                  <!-- Account No -->
                  <div class="col-md-6 mb-3">
                    <label for="accountNo" class="form-label">Account No</label>
                    <input type="text" id="accountNo" class="form-control" formControlName="accountNo">
                  </div>

                  <!-- Account No: FC -->
                  <div class="col-md-6 mb-3">
                    <label for="accountNoFC" class="form-label">Account No</label>
                    <div class="d-flex align-items-center">
                      <span class="px-2 fc">FC</span>
                      <input type="text" id="accountNoFC" class="form-control fc-input" formControlName="accountNoFC">
                    </div>
                  </div>


                  <!-- CardCode -->
                  <div class="col-md-6 mb-3">
                    <label for="cardCode" class="form-label">Card Code</label>
                    <input type="text" id="cardCode" class="form-control" formControlName="cardCode">
                  </div>

                  <!-- Voucher No -->
                  <div class="col-md-6 mb-3">
                    <label for="voucherNo" class="form-label">Voucher No</label>
                    <input type="text" id="voucherNo" class="form-control" formControlName="voucherNo">
                  </div>

                  <!-- Join Date -->
                  <div class="col-md-6 mb-3">
                    <label for="joinDate" class="form-label">Join Date</label>
                    <my-date-picker [options]="datePickerOptions" formControlName="joinDate"
                      placeholder="DD/MM/YYYY"></my-date-picker>
                  </div>

                  <!-- Monthly Fees Last Pay Date -->
                  <div class="col-md-6 mb-3">
                    <label for="lastPayDate" class="form-label">Monthly Fees Last Pay Date</label>
                    <my-date-picker [options]="datePickerOptions" formControlName="lastPayDate"
                      placeholder="DD/MM/YYYY">
                    </my-date-picker>
                  </div>

                  <!-- Member Status Date -->
                  <div class="col-md-6 mb-3">
                    <label for="statusDate" class="form-label">Member Status Date</label>
                    <my-date-picker [options]="datePickerOptions" formControlName="statusDate"
                      placeholder="DD/MM/YYYY"></my-date-picker>
                  </div>

                  <!-- Member Status -->
                  <div class="col-md-6 mb-3">
                    <label for="memberStatus" class="form-label">Member Status<font color="red">*</font></label>
                    <select id="memberStatus" class="form-select" formControlName="memberStatus"
                      [ngClass]="{'is-invalid': memberStatus?.invalid && saved}">
                      <option value="" disabled selected>Status</option>
                      <option value="normal">Normal</option>
                      <option value="pro">Pro</option>
                      <option value="ultimate">Ultimate</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="memberStatus?.invalid && saved">
                      <div *ngIf="memberStatus?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="region" class="form-label">State/Division</label>
                    <div class="g-2">
                      <div class="row g-2">
                        <div class="col-4">
                          <select class="form-select" id="region" name="region" formControlName="region"
                            (change)="onRegionChange($event)">
                            <option value="" selected disabled>Select State/Division</option>
                            <option *ngFor="let regionlist of regionList" [value]="regionlist.region">
                              {{regionlist.region}}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select class="form-select" id="town" name="town" formControlName="town"
                            (change)="onTownChange($event)">
                            <option value="" selected disabled>Select Town</option>
                            <option *ngFor="let townlist of filteredTowns" [value]="townlist.town">{{townlist.town}}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select class="form-select" id="ward" name="ward" formControlName="ward">
                            <option value="" selected disabled>Select Ward/Village</option>
                            <option *ngFor="let wardlist of filteredWards" [value]="wardlist.postcode">{{wardlist.ward}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Address -->
                  <div class="col-md-12 mb-3">
                    <label for="address" class="form-label">Address</label>
                    <textarea id="address" class="form-control" formControlName="address" rows="4"
                      [ngClass]="{'is-invalid': address?.invalid && saved}"></textarea>
                    <div class="invalid-feedback" *ngIf="address?.invalid && saved">
                      <div *ngIf="address?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="remark" class="form-label">Remark</label>
                    <textarea id="remark" class="form-control" formControlName="remark" rows="4"></textarea>

                  </div>
                  <!-- <div class="col-md-12 mb-3">
                    <label for="address" class="form-label">Remark</label>
                    <textarea id="address" class="form-control" formControlName="address" rows="4"
                      [ngClass]="{'is-invalid': address?.invalid && saved}"></textarea>
                    <div class="invalid-feedback" *ngIf="address?.invalid && saved">
                      <div *ngIf="address?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div> -->
                </div>
              </div>
              <!-- Profile Image -->
              <div class="col-lg-2 col-md-4 text-center mt-2">
                <div class="profile-img-container cursor-pointer">
                  <div (click)="onProfileClick()" class="uploadfilecontainer">
                    <img [src]="profileImageSrc" alt="Profile Image" class="drag-image">
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>