import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-contact-tracing-details',
  templateUrl: './contact-tracing-details.component.html',
  styleUrls: ['./contact-tracing-details.component.scss']
})
export class ContactTracingDetailsComponent implements OnInit {

  item : any;

  constructor(public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.item = data.item;
    console.log(this.item);

  }

  ngOnInit(): void {
  }

}
