import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { IMyDpOptions } from 'mydatepicker';

function numericStringValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isValid = typeof value === 'string' && /^[0-9]*$/.test(value);
    return isValid ? null : { numericString: { value: control.value } };
  };
}


@Component({
  selector: 'app-summer-swim-form',
  templateUrl: './summer-swim-form.component.html',
  styleUrls: ['./summer-swim-form.component.scss']
})
export class SummerSwimFormComponent implements OnInit {

  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };

  profileImageSrc: string = '../../../assets/images/uploadphoto.png';
  isSaving: boolean = false;
  swimForm!: FormGroup;
  saved = false
  requiredMessage = 'This field must not be empty'
  gettingNrcCode = false

  nrcRegionCode: any = [];
  nrcTownshipCode: any = [];
  nrcType: any = [];
  breadcrumbs: any[] = [];
  batchList: any = [];
  regionList: any = [];
  filteredTowns: any[] = [];
  filteredWards: any[] = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private kunyek: KunyekService,
    private messageService: MessageService,
    public allInOneService: AllInOneService,
  ) { }

  ngOnInit(): void {
    this.swimForm = this.fb.group({
      batch: ['', Validators.required],
      myanName: ['', Validators.required],
      engName: ['',],
      kscNo: [''],
      dateOfBirth: ['', Validators.required],
      height: ['', [Validators.required, numericStringValidator()]],
      nrcregioncode: ["", Validators.required],
      nrctownshipcode: ["", Validators.required],
      nrctype: ["", Validators.required],
      nrcnumber: ["", Validators.required],
      race: ['',],
      religion: ['',],
      education: ['',],
      occupation: ['',],
      fatherName: ['', Validators.required],
      motherName: ['', Validators.required],
      address: ['',],
      region: ['',],
      town: ['',],
      ward: ['',],
      contactNo: ['', [Validators.required, numericStringValidator()]]
    });
    this.getNrcCode()
    this.setBreadcrumbs()
    this.getBatch()
    this.getRegion()
  }
  setBreadcrumbs() {
    if (this.router.url === '/members/summerswimmer/new') {
      this.breadcrumbs[0] = { name: 'Swim', url: 'swim', isActive: true }
      this.breadcrumbs[1] = { name: 'Summer Swimmer', url: 'members/summerswimmer', isActive: true }
      this.breadcrumbs[2] = { name: 'New', url: '', isActive: false }
    } else {
      this.breadcrumbs[0] = { name: 'Members', url: '', isActive: true }
      this.breadcrumbs[1] = { name: 'Summer Swimmer', url: '', isActive: false }
    }
  }


  getNrcCode() {
    this.gettingNrcCode = true;
    const data = {
    };
    this.kunyek.getNrcCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.nrcRegionCode = res.datalist.regioncode;
          this.nrcTownshipCode = res.datalist.shortcode;
          this.nrcType = res.datalist.nrctype;
          this.gettingNrcCode = false;
        } else {
          this.gettingNrcCode = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingNrcCode = false;
      }
    );
  }

  getBatch() {
    const data = {
      "searchName": "Summer Swim"
    };
    this.kunyek.getBatch().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.batchList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  getRegion() {
    const data = {};
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.regionList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onRegionChange(region: any) {
    const data = {
      region: region.target.value
    };
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredTowns = res.datalist;
          this.filteredWards = [];
          this.resetDropdowns('town');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  resetDropdowns(type: string) {
    if (type === 'town') {
      this.filteredWards = [];
    }
  }

  onTownChange(town: any) {
    const data = {
      town: town.target.value
    };
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredWards = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onProfileClick() {
    const input = document.createElement('input');
    input.hidden = true;
    input.type = 'file';
    input.accept = 'image/*';
    input.addEventListener('change', (event: Event) => {
      this.onImageChange(event);
    });
    input.click();
  }

  private onImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = e => this.profileImageSrc = reader.result as string;
      reader.readAsDataURL(file);
    }
  }

  onSaved() {
    this.isSaving = true;
    this.saved = true;
    const formattedBirthDate = this.dateOfBirth?.value?.formatted;
    const formData = {
      batchsyskey: this.batch?.value,
      nameinburmese: this.myanName?.value,
      nameinenglish: this.engName?.value,
      kscno: this.kscNo?.value,
      birthdate: this.allInOneService.convertDate(formattedBirthDate),
      height: this.height?.value + 'cm',
      nrcregioncode: this.nrcregioncode?.value,
      nrctownshipcode: this.nrctownshipcode?.value,
      nrctype: this.nrctype?.value,
      nrcnumber: this.nrcnumber?.value,
      race: this.race?.value,
      religion: this.religion?.value,
      education: this.education?.value,
      occupation: this.occupation?.value,
      fathername: this.fatherName?.value,
      mothername: this.motherName?.value,
      address: this.address?.value,
      phone: this.contactNo?.value,
      nrc: this.nrcregioncode?.value + '/' + this.nrctownshipcode?.value +
        '(' + this.nrctype?.value + ')' +
        this.nrcnumber?.value,
      postcode: this.ward?.value
    };

    if (this.swimForm.valid) {
      this.kunyek.summerSwimMember().register(formData).subscribe({
        next: (res) => {
          switch (res.returncode) {
            case "301":
              this.isSaving = false
              this.messageService.openSnackBar(res.message, 'warn')
              break;
            default:
              this.isSaving = false;
              this.messageService.openSnackBar('Success!', 'success')
              this.closeForm();
          }
        },
        error: (err) => {
          console.error('Error saving member:', err);
          this.messageService.openSnackBar('Failed to save member', 'warn');
          this.isSaving = false;
        }
      });
    } else {
      this.messageService.openSnackBar('Invalid Form', 'warn');
      this.isSaving = false;
    }
  }

  closeForm() {
    if (this.router.url === '/members/summer-swim-form') {
      this.router.navigate(['members'])
    } else {

      this.router.navigate(['members', 'summerswimmer']);
    }
  }

  goBackToList() {
    this.router.navigateByUrl('members/summerswimmer');
  }

  get batch() { return this.swimForm.get('batch'); }
  get myanName() { return this.swimForm.get('myanName'); }
  get engName() { return this.swimForm.get('engName'); }
  get kscNo() { return this.swimForm.get('kscNo'); }
  get dateOfBirth() { return this.swimForm.get('dateOfBirth'); }
  get height() { return this.swimForm.get('height'); }
  get race() { return this.swimForm.get('race'); }
  get religion() { return this.swimForm.get('religion'); }
  get education() { return this.swimForm.get('education'); }
  get occupation() { return this.swimForm.get('occupation'); }
  get fatherName() { return this.swimForm.get('fatherName'); }
  get motherName() { return this.swimForm.get('motherName'); }
  get address() { return this.swimForm.get('address'); }
  get contactNo() { return this.swimForm.get('contactNo'); }
  get nrcregioncode() { return this.swimForm.get('nrcregioncode'); }
  get nrctownshipcode() { return this.swimForm.get('nrctownshipcode'); }
  get nrctype() { return this.swimForm.get('nrctype'); }
  get nrcnumber() { return this.swimForm.get('nrcnumber'); }
  get region() { return this.swimForm.get('region'); }
  get town() { return this.swimForm.get('town'); }
  get ward() { return this.swimForm.get('ward'); }

}
