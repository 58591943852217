<div class="mx-3">
    <div>
        <div class="title-container mb-3">
         
            
            <div class="title">
                Ticket Details
            </div>
            <div class="close-btn">
                <button mat-icon-button mat-dialog-close>
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <hr>
          <div class="spinner-border loading" role="status" *ngIf="gettingTicketDetails">
            <span class="visually-hidden">Loading...</span>
        </div>
         <div class="row" *ngIf="!gettingTicketDetails">
            <div class="mb-3 col-md-6">
                <label for="userid" class="form-label">Ticket No. </label>
                <div class="input-group">
                    <input type="text" class="form-control" id="userid" name="userid" placeholder="Ref No"
                     [value]="ticketdetails.ticketno"   disabled="true" />
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Ref No. </label>
                <input type="text" class="form-control" id="name" name="name"   [value]="ticketdetails.refno" disabled="true" />
            </div>
            <div class="mb-3 col-md-12">
                <label for="userid" class="form-label"> Description </label>
            
                <textarea disabled="true" class="form-control" id="userid" name="userid"  [value]="ticketdetails.servicedesc" placeholder="User ID">

                    </textarea>
            </div>
            <div class="mb-3 col-md-6">
                <label for="employeeid" class="form-label"> Service Type </label>
                <input type="text" class="form-control" id="employeeid" name="employeeid" placeholder="Employee ID"
                [value]="ticketdetails.servicetypename"   disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Status </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.statusname" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Priority </label>
                <input type="text" class="form-control" id="name" name="name"   [value]="ticketdetails.priorityname" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Customer </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.customername" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Product </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.productname" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Project </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.projectname" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Version </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.version" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Age </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.age" disabled="true" />
            </div>

            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Start Date </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.startdatetime=='' ? ticketdetails.startdatetime  : ticketdetails.startdatetime | date:'yyyy-MM-dd'" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Time </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.startdatetime=='' ? ticketdetails.startdatetime  : ticketdetails.startdatetime | date:'hh:mm aa'" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Target Date </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.targetdatetime=='' ?ticketdetails.targetdatetime :ticketdetails.targetdatetime | date:'yyyy-MM-dd' " disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Time </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.targetdatetime=='' ?ticketdetails.targetdatetime:ticketdetails.targetdatetime | date:'hh:mm aa'" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Due Date </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.duedatetime=='' ? ticketdetails.duedatetime  : ticketdetails.duedatetime | date:'yyyy-MM-dd'" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label"> Time </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.duedatetime !='' ?ticketdetails.duedatetime :ticketdetails.duedatetime | date:'hh:mm aa'" disabled="true" />
            </div>
            
            <div class="mb-3 col-md-12">
                <label for="name" class="form-label"> Details </label>
                <textarea class="form-control" id="remark" name="remark" rows="3" placeholder="Remark"
                [value]="ticketdetails.details"  disabled="true"></textarea>
            </div>
            <div class="mb-3 col-md-12">
                <label for="name" class="form-label"> Solution </label>
                <textarea class="form-control" id="remark" name="remark" rows="3" placeholder="Remark"
                [value]="ticketdetails.solution"  disabled="true"></textarea>
            </div>
            <div class="mb-3 col-md-12">
                <label for="name" class="form-label"> Remark </label>
                <textarea class="form-control" id="remark" name="remark" rows="3" placeholder="Remark"
                [value]="ticketdetails.remark"   disabled="true"></textarea>
            </div>
            <div class="mb-3 col-md-12">
                <label for="name" class="form-label"> Reported By </label>
                <input type="text" class="form-control" id="name" name="name"  [value]="ticketdetails.reportedby" disabled="true" />
            </div>

        </div> 
    
    </div>
</div>