import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { ClasscodeComponent } from '../classcode/classcode.component';
import { MemberPositionControlSyncComponent } from '../member-position-control-sync/member-position-control-sync.component';
import { MembertypesComponent } from '../membertypes/membertypes.component';
import { PayLevelComponent } from '../pay-level/pay-level.component';
import { SynchistoryComponent } from '../synchistory/synchistory.component';

@Component({
  selector: 'app-member-position-control',
  templateUrl: './member-position-control.component.html',
  styleUrls: ['./member-position-control.component.scss'],
})
export class MemberPositionControlComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  pcAddorNot: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  pcListLoading: boolean = false;
  memberformSubmitted: boolean = false;
  gettingMemberTypes: boolean = false;
  gettingCcList: boolean = false;
  gettingMemberPosition: boolean = false;
  gettingMemberList: boolean = false;
  memberSelected: boolean = false;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  showAysnc: boolean = false;
  showAysncFromNeo: boolean = false;
  gettingPaylevel: boolean = false;

  synchistoryList: any = [];
  pcList: any = [];
  ccList: any = [];
  positionList: any = [];
  departmentList: any = [];
  postList: any = [];
  branchList: any = [];
  divisionList: any = [];
  sectionList: any = [];
  costcenterList: any = [];
  subdivisionList: any = [];
  employmentTypes: any = [];
  transactionTypes: any = [];
  organizations: any = [];
  memberList: any = [];
  historyList: any = [];
  paylevelList: any = [];
  editTransactionType: string = "";

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  p: number = 1;

  memberSearch: string = '';
  selectedMemberName: string = '';
  newUserID: string = '';
  selectedMemberEID: string = '';
  searchText: string = '';

  subscription!: Subscription;
  allsub = new SubSink();

  mytimeout: any;

  memberForm: any = {
    id: '',
    orgid: '',
    userid: '',
    newuserid: '',
    post: '',
    effectivedate: '',
    mineffectivedate: '',
    employmenttype: '',
    transactiontype: '',
    branch: '',
    department: '',
    division: '',
    remark: '',
    status: '',
    section: '',
    costcenter: '',
    subdivision: '',
    paylevel: '',
  };

  constructor(
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private filter: FilterPipe,
    private router: Router
  ) {
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
  }

  ngOnDestroy(): void {
    this.allsub.unsubscribe();
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    // this.organizations = this.allinoneService.orgs;
    // this.memberForm.orgid = this.allinoneService.selectedOrg
    //   ? this.allinoneService.selectedOrg
    //   : this.organizations[0].orgid;
    this.organizations = this.allinoneService.orgs;
    this.memberForm.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    const index = this.organizations.findIndex(
      (x: any) => x.orgid == this.memberForm.orgid
    );
    this.showAysnc = this.organizations[index].hcmsync;
    this.showAysncFromNeo = this.organizations[index].neosync;

    // // ILBC and MIT only
    // if (
    //   this.memberForm.orgid == '31147485' ||
    //   this.memberForm.orgid == '11346359' ||
    //   this.memberForm.orgid == '30770116'
    // ) {
    //   this.showAysnc = true;
    // }

    // // MIT and Test 2 Org
    // if (
    //   this.memberForm.orgid == '11346359' ||
    //   this.memberForm.orgid == '15790596' || // c365demo
    //   this.memberForm.orgid == '30770116'
    // ) {
    //   this.showAysncFromNeo = true;
    // }

    this.getMemberList();
    // ***
    this.getMemberPosition();
    // this.getCcList();
    this.getMemberTypes();
    this.getPcList();
    this.getPositionList();
    this.getPayLevelList();
  }

  // getCcList() {
  //   this.gettingCcList = true;
  //   const data = {
  //     orgid: this.memberForm.orgid,
  //     domainid: this.allinoneService.getDomain().domainid,
  //   };
  //   this.kunyekService.getPositionList(data).subscribe(
  //     (res: any) => {
  //       if (res.returncode == '300') {
  //         this.ccList = res.datalist;
  //         this.gettingCcList = false;
  //       } else {
  //         this.gettingCcList = false;
  //         this.messageService.openSnackBar(res.message, 'warn');
  //       }
  //     },
  //     (err) => {
  //       this.gettingCcList = false;
  //     }
  //   );
  // }

  getPcList() {
    this.pcListLoading = true;
    const data = {
      orgid: this.memberForm.orgid,
      searchuser: '',
    };
    this.allsub.sink = this.kunyekService.getMemberHistory(data).subscribe(
      (res: any) => {
        console.log(`Res Data: ${JSON.stringify(res)}`);
        if (res.returncode == '300') {
          this.synchistoryList = res.historylist
          this.pcList = res.datalist;
          this.pg[3].count = this.pcList.length;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.pcListLoading = false;
      },
      (err) => {
        this.pcListLoading = false;
      }
    );
  }

  syncData(neoPayroll: boolean = false) {
    const dialog = this.dialog.open(MemberPositionControlSyncComponent, {
      maxWidth: '95vw',
      width: '450px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        orgid: this.memberForm.orgid,
        neoPayroll: neoPayroll,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.memberForm.orgid,
    };
    this.allsub.sink = this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.departmentList = res.departmentlist;
          // this.divisionList = res.divisionlist;
          this.branchList = res.branchlist;
          this.sectionList = res.sectionlist;
          this.costcenterList = res.costcenterlist;
          this.subdivisionList = res.subdivision;
          this.gettingMemberPosition = false;
        } else {
          this.gettingMemberPosition = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }

  getPositionList() {
    this.gettingCcList = true;
    const data = {
      orgid: this.memberForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.kunyekService.getPositionList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.positionList = res.datalist;
          this.departmentList = [];
          this.divisionList = [];

          for (var i = 0; i < this.positionList.length; i++) {
            this.departmentList.push(this.positionList[i].department);
            this.divisionList.push(this.positionList[i].division);
            this.postList.push(this.positionList[i].name);
          }

          this.postList = [...new Set(this.postList)];
          this.divisionList = [...new Set(this.divisionList)];
          this.departmentList = [...new Set(this.departmentList)];

          this.gettingCcList = false;
        } else {
          this.gettingCcList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingCcList = false;
      }
    );
  }

  getCcList() {
    this.gettingCcList = true;
    const data = {
      orgid: this.memberForm.orgid,
    };
    this.allsub.sink = this.kunyekService.getClassCodeList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.ccList = res.datalist;

          this.ccList.sort((a: any, b: any) => {
            let fa = a.name.toLowerCase(),
              fb = b.name.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          this.gettingCcList = false;
        } else {
          this.gettingCcList = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.gettingCcList = false;
      }
    );
  }

  getMemberTypes() {
    this.gettingMemberTypes = true;
    const data = {
      orgid: this.memberForm.orgid,
      type: '',
    };
    this.allsub.sink = this.kunyekService.getMemberTypes(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.transactionTypes = res.transaction;
          console.log(`Transaction : ${JSON.stringify(this.transactionTypes)}`);
          this.employmentTypes = res.employment;
          this.gettingMemberTypes = false;
        } else {
          this.gettingMemberTypes = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.gettingMemberTypes = false;
      }
    );
  }

  pcAdd() {
    this.pcAddorNot = true;
  }

  submitPc() {
    let resignType = "";
    let rejoinType = "";
    let blacklistType = "";
    this.memberformSubmitted = true;
    if (
      this.memberForm.userid.toString().trim() == '' ||
      this.memberForm.post == '' ||
      this.memberForm.branch.toString().trim() == '' ||
      this.memberForm.department.toString().trim() == '' ||
      this.memberForm.effectivedate == '' ||
      this.memberForm.employmenttype == '' ||
      this.memberForm.transactiontype == ''
    ) {
      return;
    }

    if(this.isEditing){
      for(var i = 0; i < this.transactionTypes.length; i++){
        if(this.transactionTypes[i].name === "Resign"){
          resignType = this.transactionTypes[i].id;
        }
        if(this.transactionTypes[i].name === "Rejoin"){
          rejoinType = this.transactionTypes[i].id;
        }
        if(this.transactionTypes[i].name === "Blacklist"){
          blacklistType = this.transactionTypes[i].id;
        }
        if (this.transactionTypes[i].id === this.editTransactionType && this.transactionTypes[i].name === "Resign") {
          if(resignType === this.memberForm.transactiontype || rejoinType === this.memberForm.transactiontype){
              // return;
              console.log(`${this.transactionTypes[i].name}`);
          }else {
            this.messageService.openSnackBar('Your transaction type should be rejoin!', 'warn');
            return;
          }
        }
        if (blacklistType === this.editTransactionType){
          this.messageService.openSnackBar("You can't change other type!", 'warn');
          return;
        }
      }
    }

    this.saveLoading = true;

    // var positionid = '';

    // for(var i = 0; i < this.positionList.length; i++) {
    //   if(this.memberForm.post == this.positionList[i].name &&
    //      this.memberForm.department == this.positionList[i].department
    //      && this.memberForm.division == this.positionList[i].division ) {
    //       positionid = this.positionList[i].positionid;
    //      }
    // }

    var positionid = '';
    var temp = this.positionList.find(
      (x: any) =>
        x.name == this.memberForm.post &&
        x.department == this.memberForm.department &&
        x.division == this.memberForm.division
    );
    if (temp) {
      positionid = temp.positionid;
    }

    // console.log('position');
    // console.log(positionid);
    // console.log(this.memberForm.post);
    // console.log(this.memberForm.department);
    // console.log(this.memberForm.division);

    // return;
    const data = {
      positionid: positionid,
      id: this.memberForm.id,
      orgid: this.memberForm.orgid,
      adduser: this.allinoneService.checkUserId(
        this.memberForm.userid.toString().trim()
      ),
      post: this.memberForm.post.toString().trim(),
      branch: this.memberForm.branch.toString().trim(),
      department: this.memberForm.department.toString().trim(),
      division: this.memberForm.division.toString().trim(),
      section: this.memberForm.section.toString().trim(),
      costcenter: this.memberForm.costcenter.toString().trim(),
      subdivision: this.memberForm.subdivision.toString().trim(),
      // effectivedate: this.allinoneService.formatDate(
      //   this.memberForm.effectivedate
      // ),
      effectivedate: this.allinoneService.parseDate(
        this.memberForm.effectivedate
      ),
      employmenttype: this.memberForm.employmenttype,
      transactiontype: this.memberForm.transactiontype,
      remark: this.memberForm.remark,
      paylevel: this.memberForm.paylevel,
    };
    console.log(JSON.stringify(data));
    // return;
    var fun = this.isEditing
      ? this.kunyekService.updateMemberHistory(data)
      : this.kunyekService.addMemberHistory(data);
    this.allsub.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.cancel();
          this.getPcList();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  cancel() {
    this.pcAddorNot = false;
    this.isEditing = false;
    this.memberSelected = false;
    this.memberformSubmitted = false;
    this.selectedMemberName = '';
    this.newUserID = '';
    this.selectedMemberEID = '';
    this.historyList = [];
    this.memberForm = {
      id: '',
      orgid: this.memberForm.orgid,
      userid: '',
      post: '',
      effectivedate: '',
      mineffectivedate: '',
      employmenttype: '',
      transactiontype: '',
      branch: '',
      department: '',
      division: '',
      remark: '',
      status: '',
      newuserid: '',
      section: '',
      costcenter: '',
      subdivision: '',
      paylevel: '',
    };
  }

  downloadSheet() {
    const membersToExport = this.filter.transform(
      this.pcList,
      this.searchText,
      'positioncontrol'
    );
    var data: any = [];
    var name = 'Position Control.xlsx';
    membersToExport.map((member: any) => {
      data.push({
        'User ID': member.userid,
        Name: member.username,
        'Employee ID': member.employeeid,
        'Employment Type': member.employmenttypename,
        'Transaction Type': member.transactiontypename,
        'Effective Date': this.allinoneService.formatDBToShow(
          member.effectivedate
        ),
        Post: member.post,
        Branch: member.branch,
        Department: member.department,
        Division: member.division,
        Section: member.section,
        'Cost Center': member.costcenter,
        'Sub Division': member.subdivision,
      });
    });
    this.allinoneService.exportEcecl(data, name);
  }

  editPc(pc: any) {
    this.isEditing = true;
    this.pcAddorNot = true;
    this.memberSelected = true;
    this.selectedMemberName = pc.username;
    this.newUserID = pc.newuserid;
    this.selectedMemberEID = pc.employeeid;
    this.editTransactionType = pc.transactiontype
    this.memberForm = {
      id: pc.id,
      orgid: this.memberForm.orgid,
      userid: pc.userid,
      post: pc.post,
      // effectivedate: this.allinoneService.formatDBToShowInEdit(
      //   pc.effectivedate
      // ),
      effectivedate: this.allinoneService.parseDateToShowInEdit(
        pc.effectivedate
      ),
      mineffectivedate: '',
      employmenttype: pc.employmenttype,
      transactiontype: pc.transactiontype,
      branch: pc.branch,
      department: pc.department,
      division: pc.division,
      remark: pc.remark,
      status: pc.status,
      newuserid: pc.newuserid,
      section: pc.section,
      costcenter: pc.costcenter,
      subdivision: pc.subdivision,
      paylevel: pc.paylevel,
    };
    console.log(pc);
    if (pc.status == '1') {
      this.historyList = this.pcList.filter(
        (x: any) => x.userid == this.memberForm.userid && x.status == '0'
      );
      this.historyList.sort(function (a: any, b: any) {
        var x = parseInt(a.order);
        var y = parseInt(b.order);
        return y - x;
      });
      console.log(this.historyList);
    }
  }

  deletePc(id: string, index: number) {
    if (this.pcList[index].status == '1') {
      return;
    }
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.pcListLoading = true;
        const data = {
          id: id,
          orgid: this.memberForm.orgid,
        };
        this.kunyekService.deleteMemberHistory(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.pcList.splice(index, 1);
              this.pg[3].count -= 1;
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.pcListLoading = false;
          },
          (err) => {
            this.pcListLoading = false;
          }
        );
      }
    });
  }
  openMemberTypeForm(type: string) {
    const dialog = this.dialog.open(MembertypesComponent, {
      width: '700px',
      height: '550px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        fromOrg: false,
        org: this.allinoneService.getOrganization(),
        type: type,
        orgid: this.memberForm.orgid,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getMemberTypes();
    });
  }

  openClassCodeForm() {
    const dialog = this.dialog.open(ClasscodeComponent, {
      width: '700px',
      height: '550px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        fromOrg: false,
        org: this.allinoneService.getOrganization(),
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getCcList();
      console.log(this.memberForm);
    });
  }

  openPayLevelForm() {
    const dialog = this.dialog.open(PayLevelComponent, {
      width: '700px',
      height: '550px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        orgid: this.memberForm.orgid,
        fromOrg: false,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getPayLevelList();
      console.log(this.memberForm);
    });
  }

  sortData(sort: Sort) {
    const data = this.pcList;
    if (!sort.active || sort.direction === '') {
      this.pcList = data;
      return;
    }
    this.pcList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'postname':
          return this.allinoneService.compare(a.postname, b.postname, isAsc);
        case 'employmenttypename':
          return this.allinoneService.compare(
            a.employmenttypename,
            b.employmenttypename,
            isAsc
          );
        case 'transactiontypename':
          return this.allinoneService.compare(
            a.transactiontypename,
            b.transactiontypename,
            isAsc
          );
        case 'effectivedate':
          return this.allinoneService.compare(
            a.effectivedate,
            b.effectivedate,
            isAsc
          );
        case 'branch':
          return this.allinoneService.compare(a.branch, b.branch, isAsc);
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'remark':
          return this.allinoneService.compare(a.remark, b.remark, isAsc);
        default:
          return 0;
      }
    });
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  changeUserid() {
    this.selectedMemberName = '';
    this.selectedMemberEID = '';
    this.newUserID = '';
    this.memberSelected = false;
    this.historyList = [];
    clearTimeout(this.mytimeout);
    this.mytimeout = setTimeout(() => {
      this.memberList = [];
      this.getMemberList();
    }, 500);
  }

  selectMember(member: any) {
    this.selectedMemberName = member.username;
    this.newUserID = member.newuserid;
    this.selectedMemberEID = member.employeeid;
    this.memberSelected = true;
    var tempMember = this.pcList.find(
      (x: any) => x.userid == member.userid && x.status == '1'
    );

    if (tempMember) {
      this.memberForm = {
        id: '',
        orgid: this.memberForm.orgid,
        userid: tempMember.userid,
        post: tempMember.post,
        effectivedate: '',
        // mineffectivedate: this.allinoneService.formatDBToShowInEdit(
        //   tempMember.effectivedate
        // ),
        mineffectivedate: this.allinoneService.parseDateToShowInEdit(
          tempMember.effectivedate
        ),
        employmenttype: tempMember.employmenttype,
        transactiontype: '',
        branch: tempMember.branch,
        department: tempMember.department,
        division: tempMember.division,
        remark: tempMember.remark,
        status: '',
        newuserid: tempMember.newuserid,
        section: tempMember.section,
        costcenter: tempMember.costcenter,
        subdivision: tempMember.subdivision,
        paylevel: tempMember.paylevel,
      };

      this.historyList = this.pcList.filter(
        (x: any) => x.userid == this.memberForm.userid
      );
      this.historyList.sort(function (a: any, b: any) {
        var x = parseInt(a.order);
        var y = parseInt(b.order);
        return y - x;
      });
      // console.log(this.historyList);
    }
  }

  getMemberList() {
    this.gettingMemberList = true;
    const data = {
      startcount: 0,
      endcount: 10,
      search: this.memberForm.userid,
      orgid: this.memberForm.orgid,
    };
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.kunyekService.getOfficeMember(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.memberList = res.list;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingMemberList = false;
      },
      (err) => {
        this.gettingMemberList = false;
      }
    );
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  clear() {
    this.searchText = '';
  }

  getPayLevelList() {
    this.gettingPaylevel = true;
    const data = {
      orgid: this.memberForm.orgid,
    };
    this.allsub.sink = this.kunyekService.getPaylevel(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.paylevelList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingPaylevel = false;
      },
      (err) => {
        this.gettingPaylevel = false;
      }
    );
  }

  getSyncHistory() {
    const dialog = this.dialog.open(SynchistoryComponent, {
      width: '800px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {historyList: this.synchistoryList}
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.synchistoryList;
        // console.log(`Member List: ${JSON.stringify(this.synchistoryList)}`);
      }
    });
  }
}


