import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { QrSetupComponent } from '../qr-setup/qr-setup.component';
import { ViewQrComponent } from '../view-qr/view-qr.component';

@Component({
  selector: 'app-user-qr-list-view',
  templateUrl: './user-qr-list-view.component.html',
  styleUrls: ['./user-qr-list-view.component.scss']
})
export class UserQrListViewComponent implements OnInit {
  @ViewChild('choosefile') choosefile: any;
  @ViewChild('contactfile') contactfile: any;
  @ViewChild('pfFileInput') pfFileInput: any;

  isMobileView: boolean = false;
  isFocus: boolean = false;
  qrListLoading: boolean = false;
  firsttime: boolean = true;

  subscription = new SubSink();

  qrList: any[] = [];
  organizations: any[] = [];

  orgid: string = '';
  searchText: string = '';
  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  p: number = 1;

  addQr: boolean = false;

  cardListType : any = '001'

  // Add qr
  isEditing: boolean = false;
  pfImgURL: any;
  saveLoading = false;

  idToEdit: string = '';
  submitted: boolean = false;

  typeList = [
    {
      name: 'Card',
      id: '001',
      folder: 'card',
    },
    {
      name: 'Menu',
      id: '002',
      folder: 'menu',
    },
    {
      name: 'Documents',
      id: '003',
      folder: 'doc',
    },
    {
      name: 'Data',
      id: '004',
      folder: 'data',
    },
    {
      name: 'Digital Assets',
      id: '005',
      folder: 'digital',
    },
    {
      name: 'Digital ID',
      id: '006',
      folder: 'did',
    },
  ];

  filetypeList: any = {
    pdf: '90',
    jpg: '80',
    jpeg: '81',
    png: '82',
  };

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  addTypeList = [
    {
      name: 'Card',
      id: '001',
    },
    {
      name: 'Menu',
      id: '002',
    },
    {
      name: 'Documents',
      id: '003',
    },
    {
      name: 'Data',
      id: '004',
    },
    {
      name: 'Digital Assets',
      id: '005',
    },
    {
      name: 'Digital ID',
      id: '006',
    },
  ];

  fileSelected: any = '';
  contactFileSelected: any = '';
  pfFileSelected: any = '';
  hashedOrgid : string = '';
  isContactFileSelected: boolean = false;
  access: boolean = true;

  form = this.formBuider.group({
    name: ['', Validators.required],
    orgname: [''],
    userid: [''],
    filename: [''],
    uploadfilename: [''],
    contactname: ['', Validators.required],
    post: [''],
    mobile: ['', Validators.required],
    workphone: [''],
    email: [''],
    company: [''],
    department: [''],
    website: [''],
    location: [''],
    googlemap:[''],

    linkedin: [''],
    facebook: [''],
    messenger : [''],
    instagram : [''],
    viber : [''],
    template : ['002'],
    filepath: ['', Validators.required],
    filetype: [''],
    type: ['001', Validators.required],
    contactfilename: [''],
    pffilename: [''],
  });

  get formControl() {
    return this.form.controls;
  }

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private formBuider: FormBuilder,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    // if (this.allinoneService.isQrModule) {
    //   this.organizations = this.allinoneService.orgsCRm;
    //   this.orgid = this.allinoneService.selectedOrgCRM
    //     ? this.allinoneService.selectedOrgCRM
    //     : this.organizations[0].orgid;
    // } else {

    //   this.organizations = this.allinoneService.orgs;
    //   this.orgid = this.allinoneService.selectedOrg
    //     ? this.allinoneService.selectedOrg
    //     : this.organizations[0].orgid;
    // }


    if (this.isEditing) {
      this.formControl.type.disable();
      this.formControl.name.disable();
      this.formControl.userid.disable();
      // if (!this.access) {
      //   this.formControl.userid.disable();
      // }
    }
    this.getQrList();
  }

  getQrList() {
    this.qrListLoading = true;
    this.subscription.sink = this.kunyekService
      .getPersonalQR(this.orgid, this.allinoneService.isQrModule)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.qrList = res.datalist.map((item: any) => ({
              ...item,
              // typetodisplay: item.type == '001' ? 'Card' : 'Menu',
              typetodisplay: this.typeList.find((i) => i.id == item.type)?.name,
              filetype: item.filename.split('.').pop(),
            }));
            if(this.qrList.length == 1 && this.firsttime){
              this.cardListType = this.qrList[0].type;
              this.editQr(this.qrList[0]);
            }
            this.pg[3].count = this.qrList.length;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.qrListLoading = false;
          this.firsttime = false;
        },
        (err) => {
          this.qrListLoading = false;
        }
      );
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  sortData(sort: Sort) {
    const data = this.qrList;
    if (!sort.active || sort.direction === '') {
      this.qrList = data;
      return;
    }

    this.qrList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'type':
          return this.allinoneService.compare(
            a.typetodisplay,
            b.typetodisplay,
            isAsc
          );
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'organization':
          return this.allinoneService.compare(
            a.organization,
            b.organization,
            isAsc
          );
        case 'contact':
          return this.allinoneService.compare(a.contact, b.contact, isAsc);
        default:
          return 0;
      }
    });
  }

  openQR(qrurl: string) {
    const dialog = this.dialog.open(ViewQrComponent, {
      width: '350px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        qrurl: qrurl,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => { });
  }

  deleteQr(id: string, index: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.qrListLoading = true;
        const data = {
          id: id,
          orgid: this.orgid,
        };
        this.kunyekService.deletePersonalQR(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.qrList.splice(index, 1);
              this.pg[3].count -= 1;
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.qrListLoading = false;
          },
          (err) => {
            this.qrListLoading = false;
          }
        );
      }
    });
  }

  newQr() {
    this.addQr = true;
    this.formControl.type.enable();
    this.formControl.name.enable();
    this.formControl.userid.enable();
    this.form.patchValue({
      filename: "",
      filepath:this.getUploadFileName(),
      filetype: "",
    });
  }

  editQr(data: any) {
    console.log("data - ");
    console.log(data);
    this.isEditing = true;
    this.formControl.type.disable();
    this.formControl.name.disable();
    this.formControl.userid.disable();
    this.addQr = true;
    var temp = data;
    this.idToEdit = temp.id
    this.orgid = temp.orgid;
    this.hashedOrgid = this.allinoneService.hash(this.orgid);
    this.form.setValue({
      name: temp.description,
      filename: temp.filename,
      uploadfilename: temp.uploadfilename,
      filepath: temp.path,
      // filetype: this.allowedFileType,
      filetype: temp.filename.split('.').pop(),
      type: temp.type,
      orgname: temp.orgname,
      userid: temp.accessuserid,
      contactfilename: temp.contactfile,
      pffilename: temp.profileimage,
      contactname: temp.name,
      post: temp.post,
      mobile: temp.mobile,
      workphone: temp.workphone,
      email: temp.email,
      company: temp.company,
      department: temp.department,
      website: temp.website,
      location: temp.location,
      linkedin: temp.linkedin,
      facebook: temp.facebook,
      messenger : temp.messenger,
      instagram : temp.instagram,
      viber : temp.viber,
      template : temp.template == "" ? '002' : temp.template,
      googlemap: temp.googlemap ? temp.googlemap : "",

    });
    this.pfImgURL = temp.profileimageurl;
    this.access = temp.access;
    if (temp.contactfile) {
      this.isContactFileSelected = true;
    }
  }

  async submitQr() {
    this.submitted = true;

    if (this.formControl.filepath.invalid) {
      return this.messageService.openSnackBar('Please choose a file.', 'warn');
    }
    if (this.formControl.name.invalid) {
      return this.messageService.openSnackBar('Please enter Name.', 'warn');
    }

    if ((this.formControl.post.value != "" && this.formControl.contactname.value == "") ||
      (this.formControl.company.value != "" && this.formControl.contactname.value == "") ||
      (this.formControl.department.value != "" && this.formControl.contactname.value == "") ||
      (this.formControl.mobile.value != "" && this.formControl.contactname.value == "") ||
      (this.formControl.workphone.value != "" && this.formControl.contactname.value == "")
    ) {
      return this.messageService.openSnackBar('Please enter Contact Name.', 'warn');
    }

    this.saveLoading = true;
    const type = this.formControl.type.value.toString().trim();
    const desc = this.formControl.name.value.toString().trim();
    const path = this.formControl.filepath.value.toString().trim();
    const filename = this.formControl.filename.value.toString().trim();
    const filetype = this.formControl.filetype.value.toString().trim();
    const typename = this.typeList.find((i) => i.id == type)?.folder;
    const orgname = this.formControl.orgname.value.toString().trim();
    const userid = this.allinoneService.checkUserId(
      this.formControl.userid.value.toString().trim()
    );
    const contactfilename = this.formControl.contactfilename.value
      .toString()
      .trim();

    const profilename = this.formControl.pffilename.value.toString().trim();

    const name = this.formControl.contactname.value.toString().trim();
    const post = this.formControl.post.value.toString().trim();
    const mobile = this.allinoneService.checkUserId(this.formControl.mobile.value.toString().trim());
    const workphone = this.allinoneService.checkUserId(this.formControl.workphone.value.toString().trim());
    const email = this.formControl.email.value.toString().trim();
    const company = this.formControl.company.value.toString().trim();
    const department = this.formControl.department.value.toString().trim();
    const website = this.formControl.website.value.toString().trim();
    const location = this.formControl.location.value.toString().trim();
    const linkedin = this.formControl.linkedin.value.toString().trim();
    const facebook = this.formControl.facebook.value.toString().trim();
    const messenger = this.formControl.messenger.value.toString().trim();
    const instagram = this.formControl.instagram.value.toString().trim();
    const viber = this.allinoneService.checkUserId(this.formControl.viber.value.toString().trim());
    const template = this.formControl.template.value.toString().trim();
    const googlemap = this.formControl.googlemap.value.toString().trim();
    const uploadfilename = this.formControl.uploadfilename.value.toString().trim();


    console.log(typename);

    if (this.fileSelected) {
      const result = await this.allinoneService.newfileUploadToS3(
        this.fileSelected,
        typename + '.' + filetype,
        this.hashedOrgid +
        '/' +
        type +
        '/' +
        path +
        '/'
      );
      if (!result) {
        this.saveLoading = false;
        return this.messageService.openSnackBar(
          'There was an error uploading your file.',
          'warn'
        );
      }
    }

    if (this.contactFileSelected) {
      const result = await this.allinoneService.newfileUploadToS3(
        this.contactFileSelected,
        contactfilename,
        this.hashedOrgid +
        '/' +
        type +
        '/' +
        path +
        '/'
      );
      if (!result) {
        this.saveLoading = false;
        return this.messageService.openSnackBar(
          'There was an error uploading your file.',
          'warn'
        );
      }
    }

    if (this.pfFileSelected) {
      const result = await this.allinoneService.newfileUploadToS3(
        this.pfFileSelected,
        profilename,
        this.hashedOrgid +
        '/' +
        type +
        '/' +
        path +
        '/'
      );
      if (!result) {
        this.saveLoading = false;
        return this.messageService.openSnackBar(
          'There was an error uploading your file.',
          'warn'
        );
      }
    }

    const data = type == '001' ?
      {
        path: path,
        filename: filename,
        id: this.idToEdit,
        type: type,
        description: desc,
        orgname: orgname,
        accessuserid: userid,
        name: name,
        post: post,
        mobile: mobile,
        workphone: workphone,
        email: email,
        company: company,
        department: department,
        website: website,
        location: location,
        googlemap:googlemap,
        linkedin: linkedin,
        facebook: facebook,
        messenger : messenger,
        instagram : instagram,
        viber : viber,
        template : template,
        contactfile: contactfilename,
        profileimage: profilename,
        profileimageurl: this.pfImgURL,
        orgid: this.orgid,
        uploadfilename: this.isEditing ? (this.fileSelected ? typename + '.' + filetype : uploadfilename): (this.fileSelected ? typename + '.' + filetype : ''),
      }
      : {
        id: this.idToEdit,
        type: type,
        description: desc,
        orgname: orgname,
        accessuserid: userid,
        path: path,
        filename: filename,
        contactfile: contactfilename,
        orgid: this.orgid,
        uploadfilename: typename + '.' + filetype,
      };
    console.log(data);

    var fun = this.isEditing
      ? this.kunyekService.updatePersonalQR(data)
      : this.kunyekService.addPersonalQR(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if(this.isEditing) {
            var index = this.qrList.findIndex(
              (x: any) => x.id == this.idToEdit
            );
            if(index > -1) {
              this.idToEdit = res.id;
              this.qrList[index].id = this.idToEdit;
              this.qrList[index].type = type;
              this.qrList[index].description = desc;
              this.qrList[index].path = path;
              this.qrList[index].filename = filename;
              this.qrList[index].contactfile = contactfilename;
              this.qrList[index].accessuserid = userid;
              this.qrList[index].orgname = orgname;
              this.qrList[index].name = name;
              this.qrList[index].post = post;
              this.qrList[index].mobile = mobile;
              this.qrList[index].workphone = workphone;
              this.qrList[index].email = email,
              this.qrList[index].company = company,
              this.qrList[index].department = department;
              this.qrList[index].website = website;
              this.qrList[index].location = location;
              this.qrList[index].googlemap = googlemap;
              this.qrList[index].linkedin = linkedin;
              this.qrList[index].facebook = facebook;
              this.qrList[index].messenger = messenger;
              this.qrList[index].instagram = instagram;
              this.qrList[index].viber = viber;
              this.qrList[index].template = template;
              this.qrList[index].uploadfilename = type == '001' ? (this.isEditing ? (this.fileSelected ? typename + '.' + filetype : uploadfilename): (this.fileSelected ? typename + '.' + filetype : '')) : typename + '.' + filetype;
              this.qrList[index].access = false;
              this.qrList[index].profileimage = res.profileimage;
              this.qrList[index].profileimageurl = res.profileimageurl;
              this.qrList[index].qrurl = res.qrurl
            }
          }
          this.saveLoading = false;
          // console.log(temp);
          // this.getQrList();
          // this.addQr = false;
          this.cancel()

        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.saveLoading = false;
      }
    );

  }

  cancel() {
    this.addQr = false;
    this.submitted = false;
    this.isEditing = false;
    this.orgid = '';
    this.hashedOrgid = '';
    this.form.setValue({
      name : '',
      orgname : '',
      userid : '',
      filename : '',
      uploadfilename : '',
      contactname : '',
      post : '',
      mobile : '',
      workphone : '',
      email : '',
      company : '',
      department : '',
      website : '',
      location : '',
      googlemap : '',

      linkedin : '',
      facebook: '',
      messenger : '',
      instagram : '',
      viber : '',
      template : '',
      filepath : '',
      filetype : '',
      type : '001',
      contactfilename : '',
      pffilename : '',
    })
    this.idToEdit = '';
  }

  checkFile(event: any) {
    if (!this.isEditing) {
      this.fileSelected = ''
      this.form.patchValue({
        filename: '',
        filepath: '',
        filetype: '',
      })
    }
  }

  uploadFile(event: any) {

    var filesize = event[0].size / 1000000;
    if (filesize > 200) {
      this.messageService.openSnackBar('Maximum file size is 200 mb.', 'warn');
      return;
    }
    var filetype = event[0].name.split('.').pop();


    if (this.filetypeList[filetype] == undefined) {
      this.messageService.openSnackBar('Not supported file type.', 'warn');
      return;
    }

    // if (this.isEditing && filetype != this.allowedFileType) {
    //   this.messageService.openSnackBar(
    //     'Updating to different file type is not allowed.',
    //     'warn'
    //   );
    //   this.choosefile.nativeElement.value = '';
    //   return;
    // }
    const isFiletypesame = this.formControl.filetype.value == filetype;
    this.fileSelected = event[0];
    this.form.patchValue({
      filename: this.fileSelected.name,
      filepath:
        this.isEditing
          ? this.formControl.filepath.value
          : this.getUploadFileName(),
      filetype: filetype,
    });
  }

  onFileSelected(event: any) {

    var filesize = event.target.files[0].size / 1000000;
    if (filesize > 200) {
      this.messageService.openSnackBar('Maximum file size is 200 mb.', 'warn');
      return;
    }
    var filetype = event.target.files[0].name.split('.').pop();
    if (this.filetypeList[filetype] == undefined) {
      this.messageService.openSnackBar('Not supported file type.', 'warn');
      return;
    }
    // if (this.isEditing && filetype != this.allowedFileType) {
    //   this.messageService.openSnackBar(
    //     'Updating to different file type is not allowed.',
    //     'warn'
    //   );
    //   this.choosefile.nativeElement.value = '';
    //   return;
    // }
    const isFiletypesame = this.formControl.filetype.value == filetype;
    this.fileSelected = event.target.files[0];
    this.form.patchValue({
      filename: this.fileSelected.name,
      filepath:
        this.isEditing
          ? this.formControl.filepath.value
          : this.getUploadFileName(),
      filetype: filetype,
    });
    this.choosefile.nativeElement.value = '';
  }

  removeContact() {
    this.contactFileSelected = '';
    // this.contactfile.nativeElement.value = '';
    this.isContactFileSelected = false;
    this.form.patchValue({
      contactfilename: '',
    });
  }
  contactFileSelect(event: any) {
    console.log(event.target.files);
    if (event.target.files.length != 0) {
      var filetype = event.target.files[0].name.split('.').pop();
      if (filetype != 'vcf') {
        this.messageService.openSnackBar('Not supported file type.', 'warn');
        return;
      }
      this.contactFileSelected = event.target.files[0];
      this.form.patchValue({
        contactfilename: this.contactFileSelected.name,
      });
      this.isContactFileSelected = true;
      console.log(this.form);
    } else if (event.target.files.length == 0) {
      this.contactFileSelected = '';
    }
  }

  getFileName(file: any) {
    var name = file.name.trim();
    console.log(name);
    var splitName = name.split('.');
    console.log(splitName);
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  pfImgUpload(event: any) {
    this.pfFileSelected = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.pfFileSelected);
    reader.onload = (_event) => {
      this.pfImgURL = reader.result;
    };
    this.form.patchValue({
      pffilename: this.getFileName(this.pfFileSelected)
    });
  }

  removePfImage() {
    this.form.patchValue({
      pffilename: ''
    });
    this.pfFileSelected = '';
    this.pfImgURL = '';
    this.pfFileInput.nativeElement.value = '';
    console.log(this.pfImgURL);
  }

  getUploadFileName() {
    // var filetype = file.name.split('.').pop();
    var currentdatetime = this.datepipe.transform(new Date(), 'yyyyMMddhhmmss');
    var shuffled = currentdatetime!
      .split('')
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join('');
    return shuffled;
  }

  removeAttachment(){
    this.form.patchValue({
      filename: '',
      filetype: '',
      uploadfilename : ''
    });
    this.fileSelected = '';   
  }

}
