<div class="container-fluid">
    <div class="content">
        <app-breadcrumb [s_breadcrumb]="'Contact Tracing'"></app-breadcrumb>

        <div class="mb-3 d-flex ">
            <div class="close-save-button">
                <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'" [isLoading]="searchLoading"
                    (click)="search()">
                </app-button-loader>
                <button type="button" class="btn btn-custom ms-2" (click)="clear()" *ngIf="!searchLoading">
                    Clear
                </button>
            </div>

        </div>


        <form>
            <div class="d-flex header">
                <div class="form-check mb-3 me-5">
                    <label class="radio-inline">
                        <input class="form-check-input" type="radio" name="searchtype" id="searchtype1"
                            [(ngModel)]="ctForm.searchtype" value="event" (ngModelChange)="searchTypeChange()"
                            [disabled]="searchLoading">By Event
                    </label>
                </div>
                <div class="form-check mb-3 me-5">
                    <label class="radio-inline">
                        <input class="form-check-input" type="radio" name="searchtype" id="searchtype3"
                            [(ngModel)]="ctForm.searchtype" value="location" (ngModelChange)="searchTypeChange()"
                            [disabled]="searchLoading">By Location
                    </label>
                </div>
                <div class="form-check mb-3">
                    <label class="radio-inline">
                        <input class="form-check-input" type="radio" name="searchtype" id="searchtype2"
                            [(ngModel)]="ctForm.searchtype" value="person" (ngModelChange)="searchTypeChange()"
                            [disabled]="searchLoading">By Person
                    </label>
                </div>
            </div>

            <div *ngIf="ctForm.searchtype=='event'" class="row">
                <div class="mb-3 col-md-6">
                    <label for="group" class="form-label">Groups</label>
                    <select class="form-select" aria-label="Select Group" id="group" name="group"
                        (change)="gpChanged($event)" [(ngModel)]="evForm.channelid" [disabled]="gettingChannelList"
                        required #org="ngModel">
                        <option selected value="">Select Group</option>
                        <option *ngFor="let channel of channels" [value]="channel.channelid">{{channel.channelname}}
                        </option>
                    </select>
                    <mat-progress-bar mode="indeterminate" *ngIf="gettingChannelList"></mat-progress-bar>
                </div>

                <div class="mb-3 col-md-6">
                    <label for="evemt" class="form-label">Events</label>
                    <select class="form-select" aria-label="Select Event" id="event" name="event"
                        (change)="eventChanged($event)" [(ngModel)]="event.eventid" [disabled]="gettingChannelList"
                        required #eve="ngModel" [ngClass]="{ 'is-invalid': ctFormSubmitted && org.invalid }">
                        <option selected value="">Select Event</option>
                        <option *ngFor="let e of events" [value]="e.eventid">{{e.name}}</option>
                    </select>
                    <mat-progress-bar mode="indeterminate" *ngIf="gettingChannelList"></mat-progress-bar>
                    <div class="invalid-feedback" *ngIf="eve.invalid || (eve.dirty || eve.touched) && ctFormSubmitted">
                        <div *ngIf="eve.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>

                </div>
                    <div class="col-md-6 mb-3" *ngIf="showDT">
                        <label for="startdate" class="form-label">Start Date</label>
                        <input type="text" class="form-control" id="startdate" name="startdate"
                            [(ngModel)]="event.startdate" required #startdate="ngModel" disabled>
                    </div>
                    <div class=" col-md-6 mb-3" *ngIf="showDT">
                        <label for="enddate" class="form-label">End Date</label>
                        <input type="text" class="form-control" id="enddate" name="enddate"
                            [(ngModel)]="event.enddate" required #enddate="ngModel" disabled>
                    </div>


            </div>
            <div>
                <div class="row" *ngIf="ctForm.searchtype == 'person'">
                    <div class="mb-3 col-md-6">
                        <label for="userid" class="form-label">User ID</label>
                        <input type="text" class="form-control" id="userid" name="userid"
                            placeholder="john@domain.com, +959********" [(ngModel)]="ctForm.searchdata" required
                            #userid="ngModel" [ngClass]="{ 'is-invalid': ctFormSubmitted && userid.invalid }"
                            (keyup.enter)="search()">

                        <div class="invalid-feedback"
                            *ngIf="userid.invalid || (userid.dirty || userid.touched) && ctFormSubmitted">
                            <div *ngIf="userid.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="range" class="form-label">Time Range (Mins)</label>
                        <input type="number"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            maxlength="3" class="form-control" id="range" name="range" placeholder="Enter Range"
                            [(ngModel)]="ctForm.range" required #range="ngModel"
                            [ngClass]="{ 'is-invalid': ctFormSubmitted && range.invalid }" (keyup.enter)="search()">

                        <div class="invalid-feedback"
                            *ngIf="range.invalid || (range.dirty || range.touched) && ctFormSubmitted">
                            <div *ngIf="range.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mb-3" *ngIf="ctForm.searchtype == 'location'">
                    <div class="mb-3">
                        <label for="location" class="form-label">Location </label>
                        <!-- <div class=" d-flex">
                            <label for="location" class="form-label">Location </label>
                            <span style="margin-left: 0.2rem;"> ( </span>
                            <a (click)="newLocation()" class="btn-link mx-1">New Location</a> )
                        </div> -->
                        <!-- <select class="form-select" aria-label="Select Location" id="location" name="location"
                            [(ngModel)]="ctFormLocation.searchdata" required #loc="ngModel" [disabled]="gettingLocList"
                            [ngClass]="{ 'is-invalid': ctFormSubmitted && loc.invalid }">
                            <option selected value="">Select Location</option>
                            <option *ngFor="let loc of locations" [value]="loc.locid">{{loc.name}}</option>
                        </select> -->
                        <ng-select placeholder="Select locations" [multiple]="true" [(ngModel)]="selectedLocation"
                            name="location" id="location">
                            <ng-option *ngFor="let loc of locations" [value]="loc.locid">{{loc.name}}</ng-option>
                        </ng-select>
                        <!-- <ng-select placeholder="select"
                           
                         
                          [items]="locations"
                            bindLabel="name"
                            bindValue="locid"
                            [multiple]=true
                            [(ngModel)]="ctFormLocation.searchdata">
                        </ng-select> -->
                        <mat-progress-bar mode="indeterminate" *ngIf="gettingLocList"></mat-progress-bar>

                        <!-- <div class="invalid-feedback"
                            *ngIf="loc.invalid || (loc.dirty || loc.touched) && ctFormSubmitted">
                            <div *ngIf="loc.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="row g-3 mb-3 " *ngIf="ctForm.searchtype != 'event'">
                    <div class="col-md-6">
                        <label for="startdate" class="form-label">Start Date Time</label>
                        <input type="datetime-local" class="form-control" id="startdate" name="startdate"
                            [(ngModel)]="ctForm.startdate" required #startdate="ngModel"
                            [ngClass]="{ 'is-invalid': ctFormSubmitted && startdate.invalid }" step="1">

                        <div class="invalid-feedback"
                            *ngIf="startdate.invalid || (startdate.dirty || startdate.touched) && ctFormSubmitted">
                            <div *ngIf="startdate.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="enddate" class="form-label">End Date Time</label>
                        <input type="datetime-local" class="form-control" id="enddate" name="enddate"
                            [(ngModel)]="ctForm.enddate" required #enddate="ngModel"
                            [ngClass]="{ 'is-invalid': ctFormSubmitted && enddate.invalid }" [min]="ctForm.startdate"
                            step="1">

                        <div class="invalid-feedback"
                            *ngIf="enddate.invalid || (enddate.dirty || enddate.touched) && ctFormSubmitted">
                            <div *ngIf="enddate.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>


        <div class="table-responsive" *ngIf="ctForm.searchtype == 'location'" matSort
            (matSortChange)="sortByLocationData($event)">
            <table class="table table-borderless">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-1 col-md-1">No.</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="userid">User ID</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="username">Name</td>
                        <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="date">Date</td>
                    </tr>
                </thead>
                <tbody *ngIf="searchLoading">
                    <tr>
                        <td colspan="6" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!searchLoading">
                    <!-- contactsList should be eventListToShow -->
                    <tr *ngIf="contactsListByLocation.length == 0">
                        <td colspan="6" class="nodata">Empty</td>
                    </tr>
                    <tr *ngFor="let item of contactsListByLocation; let index = index">
                        <td class="td-data">{{index+1}}</td>
                        <td class="td-data">{{item.userid}}</td>
                        <td class="td-data">{{item.username}}</td>
                        <td class="td-data">{{allinoneService.formatDBToShow(item.startdate)}} {{item.starttime}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive" *ngIf="ctForm.searchtype == 'person'" matSort
            (matSortChange)="sortByPersonData($event)">
            <table class="table table-borderless">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-1 col-md-1">No.</td>
                        <td scope="col" class="col-lg-5 col-md-5" mat-sort-header="userid">User ID</td>
                        <td scope="col" class="col-lg-5 col-md-5" mat-sort-header="username">Name</td>
                        <td scope="col" class="d-flex justify-content-center">Action</td>
                    </tr>
                </thead>
                <tbody *ngIf="searchLoading">
                    <tr>
                        <td colspan="6" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!searchLoading">
                    <!-- contactsList should be eventListToShow -->
                    <tr *ngIf="contactsListByPerson.length == 0">
                        <td colspan="6" class="nodata">Empty</td>
                    </tr>
                    <tr *ngFor="let item of contactsListByPerson; let index = index">
                        <td class="td-edit td-data" (click)="viewDetails(item)">{{index+1}}</td>
                        <td class="td-edit td-data" (click)="viewDetails(item)">{{item.userid}}</td>
                        <td class="td-edit td-data" (click)="viewDetails(item)">{{item.username}}</td>
                        <td class="d-flex justify-content-center">
                            <button class="btn btn-link" (click)="viewDetails(item)" title="View Details">
                                More
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive" *ngIf="ctForm.searchtype == 'event'" matSort
            (matSortChange)="sortByEventData($event)">
            <table class="table table-borderless">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-1 col-md-1">No.</td>
                        <td scope="col" class="col-lg-5 col-md-5" mat-sort-header="userid">User ID</td>
                        <td scope="col" class="col-lg-5 col-md-5" mat-sort-header="username">Name</td>
                    </tr>
                </thead>
                <tbody *ngIf="searchLoading">
                    <tr>
                        <td colspan="6" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!searchLoading">
                    <!-- contactsList should be eventListToShow -->
                    <tr *ngIf="attendanceList.length == 0">
                        <td colspan="6" class="nodata">Empty</td>
                    </tr>
                    <tr *ngFor="let item of attendanceList; let index = index">
                        <td class="td-edit td-data">{{index+1}}</td>
                        <td class="td-edit td-data">{{item.userid}}</td>
                        <td class="td-edit td-data">{{item.username}}</td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>