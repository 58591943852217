import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-view-qr',
  templateUrl: './view-qr.component.html',
  styleUrls: ['./view-qr.component.scss'],
})
export class ViewQrComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  element!: HTMLImageElement;

  qrurl: string = '';

  constructor(
    public dialogRef: MatDialogRef<ViewQrComponent>,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.qrurl = data.qrurl;
  }

  ngOnInit(): void {}

  downloadQRCode() {
    const fileNameToDownload = 'qr360';
    const htmlstring =
      document.getElementsByClassName('bshadow')[0].children[0];

    const base64Img = this.getImgSrc(htmlstring);

    fetch(base64Img)
      .then((res) => res.blob())
      .then((blob) => {
        // IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileNameToDownload);
        } else {
          // Chrome
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileNameToDownload;
          link.click();
        }
      });
  }

  getImgSrc(htmlString: any) {
    this.element = <HTMLImageElement>htmlString;
    return this.element.src;
  }
}
