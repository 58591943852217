<div class="location-new-wrapper">
    <form >
        <div class="label title mb-3">New Location</div>
        <div>
            <div class="mb-3">
                <label for="locName" class="form-label">Location Name</label>
                <input type="text" class="form-control" id="locName" name="locName" placeholder="Enter Location Name"
                    [(ngModel)]="locForm.locname" required #locname="ngModel"
                    [ngClass]="{ 'is-invalid': locformSubmitted && locname.invalid }" (keyup.enter)="submitLocation()">

                <div class="invalid-feedback"
                    *ngIf="locname.invalid || (locname.dirty || locname.touched) && locformSubmitted">
                    <div *ngIf="locname.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>


            </div>
            <div class="row g-3 mb-3">
                <div class="col-md-4">
                    <label for="latitude" class="form-label">Latitude</label>
                    <input type="number" class="form-control" id="latitude" name="latitude" placeholder="Enter Latitude"
                        [(ngModel)]="locForm.lat" required #lat="ngModel"
                        [ngClass]="{ 'is-invalid': locformSubmitted && lat.invalid }" (keyup.enter)="submitLocation()">

                    <div class="invalid-feedback" *ngIf="lat.invalid || (lat.dirty || lat.touched) && locformSubmitted">
                        <div *ngIf="lat.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                    <div class="map-wrapper">
                        <a (click)="map()" class="btn-link">Pick Location</a>
                    </div>
                </div>
                <div class="col-md-4">
                    <label for="longitude" class="form-label">Longitude</label>
                    <input type="number" class="form-control" id="longitude" name="longitude"
                        placeholder="Enter Longitude" [(ngModel)]="locForm.long" required #long="ngModel"
                        [ngClass]="{ 'is-invalid': locformSubmitted && long.invalid }" (keyup.enter)="submitLocation()">

                    <div class="invalid-feedback"
                        *ngIf="long.invalid || (long.dirty || long.touched) && locformSubmitted">
                        <div *ngIf="long.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>

                </div>

                <div class="col-md-4">
                    <label for="range" class="form-label">Range</label>
                    <input type="number" class="form-control" id="range" name="range" placeholder="Enter Range"
                        [(ngModel)]="locForm.range" required #range="ngModel"
                        [ngClass]="{ 'is-invalid': locformSubmitted && range.invalid }"
                        (keyup.enter)="submitLocation()">

                    <div class="invalid-feedback"
                        *ngIf="range.invalid || (range.dirty || range.touched) && locformSubmitted">
                        <div *ngIf="range.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="locDesc" class="form-label">Description</label>
                <textarea class="form-control" id="locDesc" name="locDesc" rows="5" [(ngModel)]="locForm.description"
                    placeholder="Enter Description for Your Location"></textarea>
            </div>
        </div>
    </form>

    <div class="close-btn">
        <button type="button" class="btn btn-custom " mat-dialog-close>Cancel</button>
        <app-button-loader class="mx-2" (click)="submitLocation()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="saveLoading"></app-button-loader>
    </div>
</div>