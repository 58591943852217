import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crm-breadcrumb',
  templateUrl: './crm-breadcrumb.component.html',
  styleUrls: ['./crm-breadcrumb.component.css']
})
export class CrmBreadcrumbComponent implements OnInit {


  @Input() s_breadcrumb!: string;
  @Input() t_breadcrumb!: string;
  @Input() f_breadcrumb!: string;
  @Input() fi_breadcrumb!: string;


  @Output('emit')
  change: EventEmitter<any> = new EventEmitter<any>();

  @Output('emit1')
  change1: EventEmitter<any> = new EventEmitter<any>();


  @Output('emit2')
  change2: EventEmitter<any> = new EventEmitter<any>();

  routeName: any = "";
  routeLink: any = "";
  constructor(private router: Router) {
    if (this.router.url.startsWith('/sales') || this.router.url.startsWith('/org/projects?q=crm')) { this.routeName = "Sales"; this.routeLink = "/sales"; } else if (this.router.url.startsWith('/service')) { this.routeName = "Service"; this.routeLink = "/service" }
  }

  ngOnInit(): void {
  }
  goToSecond() {
    if (this.t_breadcrumb) {
      this.change.emit(false);
    }
  }
  goToThird() {
    if (this.f_breadcrumb) {
      this.change1.emit(false);
    }
  }
  goToFourth() {
    if (this.fi_breadcrumb) {
      this.change2.emit(false);
    }
  }

  goRoute() {

  }

}
