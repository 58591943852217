<div class="cc-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="label title">Select Member</div>
        <div class="close-btn">
            <button mat-icon-button (click)="close(false)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>
<div class = "mb-2">
    <div class="input-group">
        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
        </button>
    </div>
</div>
<div class = "my-3 mx-3 d-flex justify-content-between align-items-center" style="cursor: pointer;" (click) = "selectAll()">
    <div style="font-weight: bold; font-size: 15px;">
        {{isSelectedAll ? 'Unselect All' : 'Select All'}}
    </div>
    <div>
        <input class="form-check-input" type="checkbox" [(ngModel)]="isSelectedAll" id="flexCheckDefault">
    </div>
</div>
<mat-divider></mat-divider>
<perfect-scrollbar style="position: relative;max-height: 350px; min-height: 350px; padding-bottom: 10px;" fxFlex="auto"
    [config]="config" [scrollIndicators]="true">
    <div *ngFor="let item of list| filter :searchText:13; let index = index">
        <div class="card card-body my-2 d-flex flex-row justify-content-between align-items-center" (click) = "check(index)">
            <div>
                <div>
                    {{item.username ? item.username : item.userid}} - {{item.employeeid}}
                </div>
                <div *ngIf = "item.department && item.division">
                    {{item.positionname}}, {{item.department}}, {{item.division}}
                </div>
                <div *ngIf = "!item.department && item.division">
                    {{item.positionname}}, - , {{item.division}
                </div>
                <div *ngIf = "item.department && !item.division">
                    {{item.positionname}}, {{item.department}}
                </div>
                <div *ngIf = "!item.department && !item.division">
                    {{item.positionname}}
                </div>
            </div>
            <div>
                <input class="form-check-input" type="checkbox" [(ngModel)]="item.checked" id="flexCheckDefault">
            </div>
        </div>
    </div>
</perfect-scrollbar>

<div class="btn-footer">
    <button class="btn btn-primary my-btn" type="button" (click)="close(true)">
        <mat-icon>check</mat-icon>
    </button>
</div>