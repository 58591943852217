<div class="me-auto">
    <!-- <app-kmember-breadcrumb [s_breadcrumb]="'Payment'" [t_breadcrumb]="'History'" (emit)="handleClose()">
    </app-kmember-breadcrumb> -->
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
</div>

<div class="fluid-container" *ngIf="!isOpenDetail">
    <div class="content">
        <table class="table  table-responsive table-borderless">
            <thead class="table-header">
                <tr>
                    <th>Vr No.</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th class="text-end">Total Amount</th>
                </tr>
            </thead>
            <tbody *ngIf="getting">
                <tr>
                    <td colspan="9" class="nodata">
                        <div class="spinner-border" entitle="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!getting && history.length == 0">
                <tr>
                    <td colspan="6" class="nodata">
                        Empty
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr *ngFor="let obj of history;" (click)="handleSelect(obj)" style="cursor: pointer;">
                    <td class="td">{{ obj.autoid }}</td>
                    <td class="td">{{ obj.member?.name }}</td>
                    <td class="td">{{ formateDate(obj.datetime) }}</td>
                    <td class="td text-end">{{ obj.total_amount | number }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="d-flex flex-column align-items-center gap-2" *ngIf="isOpenDetail">
    <div class="d-flex ms-auto btn-action">
        <button class="btn btn-custom me-2" (click)="printPage()">Print</button>
        <button class="btn btn-custom" (click)="exportPDF()">Export PDF</button>
    </div>
    <div class="card px-5 py-3" id="printableCard" style="width: 780px;">
        <div class="d-flex flex-column gap-1 align-items-center mt-3">
            <h2>KOKINE SWIMMING CLUB</h2>
            <h3>Phone: 01-542749, 401467, 09-73224059</h3>
        </div>
        <div class="d-flex flex-column align-items-end p-2 gap-3">
            <div class="d-flex gap-3">
                <span class="text-end">Date:</span>
                <span style="width: 130px;" class="dashed-line text-end">{{ invoicedate }}</span>
            </div>
            <div class="d-flex gap-2">
                <span class="text-end">Vr No:</span>
                <span style="width: 130px;" class="dashed-line text-end">{{ invoiceid }}</span>
            </div>
        </div>
        <div class="p-3 mb-3 mt-3" style=" background-color: white; border: solid 1px #ddd; border-radius: 6px;">
            <div>
                <table style="width: 100%; border-collapse: collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 160px;">Name: </td>
                            <td class="dashed-line">{{ amember?.member.name }}</td>
                        </tr>
                        <tr>
                            <td style="width: 160px;">Member Type: </td>
                            <td class="dashed-line">{{ amember?.member.memberType.name }}</td>
                        </tr>
                        <tr>
                            <td style="width: 160px;">Phone: </td>
                            <td class="dashed-line">{{ amember?.member.phone }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table style="width: 100%; border-collapse: collapse;">
                <tbody>
                    <tr *ngFor="let detail of amember.fees">
                        <td style="width: 160px;">{{ detail.feetype_name }} : </td>
                        <td class="text-end dashed-line">{{ detail.amount | number }} &nbsp;{{ currency }}</td>
                    </tr>
                </tbody>
            </table>




            <hr class="mt-5">
            <table style="width: 100%; border-collapse: collapse;">
                <tbody>
                    <tr>
                        <td>Total Fees</td>
                        <td class="text-end">{{ amember?.total_amount | number }} &nbsp;{{ currency }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <ng-container *ngFor="let bmember of bmembers">
            <div class="p-3 mb-3" style="background-color: white; border: solid 1px #ddd; border-radius: 6px;">

                <div>
                    <table style="width: 100%; border-collapse: collapse;">
                        <tbody>
                            <tr>
                                <td style="width: 160px;">Name: </td>
                                <td class="dashed-line">{{ bmember?.member.name }}</td>
                            </tr>
                            <tr>
                                <td style="width: 160px;">Member Type: </td>
                                <td class="dashed-line">{{ bmember?.member.memberType.name }}</td>
                            </tr>
                            <tr>
                                <td style="width: 160px;">Phone: </td>
                                <td class="dashed-line">{{ bmember?.member.phone }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <table style="width: 100%; border-collapse: collapse;">
                    <tbody>
                        <tr *ngFor="let detail of bmember.fees">
                            <td style="width: 160px;">{{ detail.feetype_name }} : </td>
                            <td class="text-end dashed-line">{{ detail.amount | number }} &nbsp;{{ currency }}</td>
                        </tr>
                    </tbody>
                </table>




                <hr class="mt-5">
                <table style="width: 100%; border-collapse: collapse;">
                    <tbody>
                        <tr>
                            <td>Total Fees</td>
                            <td class="text-end">{{ bmember?.total_amount | number }} &nbsp;{{ currency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>

        <div class="d-flex justify-content-between">
            <div>
                <span>Total: &nbsp;&nbsp;{{ totalAmount | number }} &nbsp;{{ currency }}</span>
            </div>
            <div>
                <span>Cash By: &nbsp;&nbsp;{{ cashby }}</span>
            </div>
        </div>
    </div>

</div>