import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { RequestUserListComponent } from '../request-user-list/request-user-list.component';
import { Sort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dropoff-point',
  templateUrl: './dropoff-point.component.html',
  styleUrls: ['./dropoff-point.component.scss']
})
export class DropoffPointComponent implements OnInit {

  gettingData: boolean = false;
  dropoffList : any = [];
  adddropoff : boolean = false;
  saveLoading: boolean = false;
  gettingVehicle: boolean = false;
  dropoffFormSave : boolean = false;
  isEditing: boolean = false;
  orgid: any = "";
  organizations: any = [];
  searchText: any = "";

  itemPerPage: any = "";
  currentPage: any = 1;
  text1: any = "";
  text2: any = "";

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  dropoffForm = {
    "name": "",
    "description": "",
    "user": [],
    "pickupid": ""
  }

  passengerList: any = [];
  orgPassengerList: any = [];
  requsetidList: any = [];
  delerequest: any = [];
  useridlist: any = [];
  istransoprationAdmin: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private filter:FilterPipe
  ) { 
    this.istransoprationAdmin = this.allinoneService.isAdminOf("transportation");
    if (this.istransoprationAdmin == false) {
      this.router.navigateByUrl(`transportation`);
    }
    this.organizations = this.allinoneService.tranOrgs;
    this.orgid = this.allinoneService.selectedTranOrg
      ? this.allinoneService.selectedTranOrg
      : this.organizations[0].orgid;
   }

  ngOnInit(): void {
    this.getDropoff();
  }

  new() {
    this.adddropoff = true;
    this.dropoffForm.name = "";
    this.dropoffForm.description = "";
    this.dropoffForm.user = [];
    this.dropoffFormSave = false;
    this.dropoffForm.pickupid = "";
    this.useridlist = [];
    this.passengerList = [];
  }

  cancelFunction() {
    this.adddropoff = false;
    this.isEditing = false;
  }

  submitDropoffForm() {
    this.dropoffFormSave = true;
    if(this.dropoffForm.name == "") {
      return;
    } else {
      this.saveLoading = true;
      this.useridlist = [];
      this.requsetidList = [];

      if (this.passengerList.length > 0) {
        for (let i = 0; i < this.passengerList.length; i++) {
          this.requsetidList.push(this.passengerList[i]['requestid']);
          if (this.passengerList[i]['trantype'] == '002') {
            for (let j = 0; j < this.passengerList[i]['userlist'].length; j++) {
              this.useridlist.push(this.passengerList[i]['userlist'][j]);
            }
            this.useridlist.push(this.passengerList[i]['userid']);
          } else {
            this.useridlist.push(this.passengerList[i]['userid']);
          }
        }
      }

      var uniqueArray = Array.from(new Set(this.useridlist));

      var data = {
        "orgid": this.orgid,
        "domainid": this.allinoneService.getDomain().domainid,
        "name": this.dropoffForm.name,
        "useridlist": uniqueArray,
        "requestdata": this.passengerList,
        "requestidlist": this.requsetidList,
        "removerequestidlsit": this.delerequest,
        "type" : "002"
      }

      if (this.isEditing) {
        data = Object.assign(data,
          {
            "pickuppointid": this.dropoffForm.pickupid,
          }
        );
      }

      console.log(data);
      if (this.isEditing) {
        this.kunyekService.updatePickup(data).subscribe(
          (res: any) => {

            if (res.returncode == '300') {
              this.adddropoff = false;
              this.dropoffFormSave = false;
              this.saveLoading = false;
              this.isEditing = false;
              this.getDropoff();
            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
          }
        );
      } else {
        this.kunyekService.addPickup(data).subscribe(
          (res: any) => {

            if (res.returncode == '300') {
              this.adddropoff = false;
              this.isEditing = false;
              this.dropoffFormSave = false;
              this.saveLoading = false;
              this.getDropoff();
            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
          }
        );
      }

    }
  }

  getDropoff() {
    this.gettingData = true;
    this.dropoffList = [];
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      "type" : '002',
    }
    this.kunyekService.getPickup(data).subscribe(
      (res :any) => {
        if (res.returncode == '300') {
          res.datalist.map((item: any) => {
            this.dropoffList.push({
              "name": item.name,
              "user": "",
              "userid": item.userid,
              "requestdata": item.requestdata,
              "usercounts": item.usercounts,
              "pickuppointid": item.pickuppointid,
            })
          });

          this.gettingData = false;
        } else {

          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingData = false;
      }
    )
  }

  async dropoffedit(data: any) {
    this.isEditing = true;
    this.dropoffForm.name = data.name;
    this.dropoffForm.description = data.description;
    this.dropoffForm.user = [];
    this.delerequest = [];
    this.dropoffForm.pickupid = data.pickuppointid;
    this.passengerList = JSON.parse(JSON.stringify(data.requestdata));
    this.orgPassengerList = JSON.parse(JSON.stringify(data.requestdata));
  }

  refreshgetData() {
    this.getDropoff();
  }

  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    this.refreshgetData();
  }

  choosePassenger() {
    const dialog = this.dialog.open(RequestUserListComponent, {
      height: '550px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        orgid: this.orgid,
        domainid: this.allinoneService.getDomain().domainid,
        passengerList: this.passengerList,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult);
        if (dialogResult) {
          this.passengerList = dialogResult.data;
          console.log(this.passengerList);
        }
      }
    });
  }

  delete(index: any) {
    for (let i = 0; i < this.orgPassengerList.length; i++) {
      if (this.orgPassengerList[i]['requestid'] == this.passengerList[index]['requestid']) {
        this.delerequest.push(this.orgPassengerList[i]['requestid'])
      }

    }

    console.log(this.delerequest);

    this.passengerList.splice(index, 1);

  }

  sortData(sort: Sort) {
    const data = this.dropoffList;
    if (!sort.active || sort.direction === '') {
      this.dropoffList = data;
    }

    this.dropoffList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(
            a.name.toLocaleLowerCase(),
            b.name.toLocaleLowerCase(),
            isAsc
          );
        case 'usercounts':
          return this.allinoneService.compare(
            parseInt(a.usercounts),
            parseInt(b.usercounts),
            isAsc
          );
        default:
          return 0;
      }

    });

  }

  downloadSheet() {
    var dropoffExport = this.filter.transform(
      this.dropoffList,
      this.searchText,
      "pickup"
    );


    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Dropoff_Point' + time + '.xlsx';
    dropoffExport.map((pick: any) => {
      data.push({
        "Name": pick.name,
        // "Passenger": pick.usercounts,
      });
    });

    this.allinoneService.exportEcecl(data, name);

  }



}
