import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { HierarchyChildrenComponent } from '../hierarchy-children/hierarchy-children.component';

@Component({
  selector: 'app-hierarchy-add',
  templateUrl: './hierarchy-add.component.html',
  styleUrls: ['./hierarchy-add.component.css'],
})
export class HierarchyAddComponent implements OnInit {
  isEditing: boolean = false;
  saveLoading: boolean = false;
  hcFormSubmitted: boolean = false;
  checkingCeid: boolean = false;
  isUserValid: boolean = false;
  isParentValid: boolean = false;
  gettingPositionList: boolean = false;
  isAdded: boolean = false;
  alreadyNewPos: boolean = false;
  disableParent: boolean = false;
  disabledChild: boolean = false;
  firstTime: boolean = false;
  gettingHierarchy: boolean = false;

  username: any = '';
  selectedUserid: any = '';
  selectedUserName: any = '';
  selectedEmployeeid: any = '';
  selectedPUserid: any = '';
  selectedPUserName: any = '';
  selectedPEmployeeid: any = '';
  headDepartment: any = '';
  headDivision: any = '';
  node: any = '';
  searchText: any = '';
  searchText1: any = '';
  // insert type 1 - above, 2 - between , 3 - down
  insertType: number = 3;
  children: any[] = [];

  hcForm = {
    pid: '',
    peid: '',
    pindex: '',
    cindex: '',
    ceid: '',
    type: '',
    orgid: '',
    order: '0',
    primary: false,
  };

  parentList: any[] = [];
  positions: any[] = [];
  parentPositions: any[] = [];
  hierarchyTypes: any[] = [];
  members: any = [];
  childrenList: any = [];
  roParent: any = [];
  svParent: any = [];

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<HierarchyAddComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.hcForm.orgid = data.orgid;
    this.hcForm.type = data.selectedType;
    this.parentList = data.parentList;
    this.hierarchyTypes = data.type;
    this.members = data.memberlist;
    this.insertType = data.insertType;
    this.positions = data.positions;
    if (data.node) {
      if (this.insertType == 1) {
        const index = this.members.findIndex(
          (x: any) => x.employeeid == data.node.employeeid
        );
        if (index > -1) {
          this.isUserValid = true;
          this.selectedUserName = this.members[index].username;
          this.selectedUserid = this.members[index].userid;
          this.hcForm.ceid = this.members[index].employeeid;
          this.selectedEmployeeid = this.members[index].employeeid;
          this.disabledChild = true;
          this.searchText = this.selectedEmployeeid;
        }
      } else {
        const index = this.parentList.findIndex(
          (x: any) =>
            x.employeeid == data.node.employeeid &&
            x.positionid == data.node.positionid
        );
        if (index > -1) {
          this.parentChange(this.parentList[index]);
          this.disableParent = true;
          this.childrenList = data.children;
        }
      }
    }
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    if (this.data.selectedType != '1') {
      this.getHierarchy();
    } else {
      this.roParent = this.parentList;
    }
    if(this.positions.length == 0) {
      this.getPositions();
    }
    if (this.parentList.length == 0) {
      this.firstTime = true;
    }
    // console.log(this.parentList);
  }

  useridChange() {
    this.username = '';
    this.selectedEmployeeid = '';
    this.selectedUserName = '';
    this.selectedUserid = '';
    this.isUserValid = false;
  }

  peidChange() {
    this.username = '';
    this.selectedPEmployeeid = '';
    this.selectedPUserName = '';
    this.selectedPUserid = '';
    this.hcForm.pid = '';
    this.hcForm.peid = '';
    this.isParentValid = false;
  }

  typeChange(event: any) {
    this.hcForm.type = event.target.value;
    this.parentList = this.hcForm.type == '1' ? this.roParent : this.svParent;
    this.firstTime = this.parentList.length == 0;
    this.peidChange();
    this.searchText1 = '';
    this.hcForm.pindex = '';
    console.log(this.hcForm);
  }

  parentChange(data: any) {
    this.isParentValid = true;
    this.selectedPUserName = data.username;
    this.selectedPUserid = data.userid;
    this.selectedPEmployeeid = data.employeeid;
    if (this.hcForm.type == '1') {
      if (this.firstTime || this.insertType == 1) {
        if (data.type.toString().trim() != '') {
          const index = this.positions.findIndex(
            (x: any) =>
              x.name.toString().trim() == data.type.toString().trim() &&
              x.department.toString().trim() ==
                data.department.toString().trim() &&
              x.division.toString().trim() == data.division.toString().trim()
          );
          if (index > -1) {
            this.hcForm.pindex = index.toString();
          } else {
            var temp = {
              appid: '',
              createdby: '',
              department: data.department.toString().trim(),
              description: '',
              division: data.division.toString().trim(),
              domainid: '',
              id: '',
              name: data.type.toString().trim(),
              orgid: '',
              positionid: '',
              pctype: '1',
            };
            this.hcForm.pindex = '';
            const tindex = this.positions.findIndex(
              (x: any) => x.pctype == '1'
            );
            if (tindex == -1) {
              this.positions.push(temp);
              this.hcForm.pindex = (this.positions.length - 1).toString();
            } else {
              this.positions[tindex] = temp;
              this.hcForm.pindex = tindex.toString();
            }
          }
        }
      } else {
        this.parentPositions = [];
        var temp1 = {
          appid: '',
          createdby: '',
          department: data.department.toString().trim(),
          description: '',
          division: data.division.toString().trim(),
          domainid: '',
          id: '',
          name: data.positionname.toString().trim(),
          orgid: '',
          positionid: data.positionid,
        };
        this.parentPositions.push(temp1);
      }
      this.hcForm.pid = data.positionid;
    }
    this.hcForm.peid = data.employeeid;
    this.searchText1 = this.hcForm.peid;
  }

  getHierarchy() {
    this.gettingHierarchy = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.hcForm.orgid,
      domain: this.allinoneService.getDomain().shortcode,
      type: '1',
    };

    this.kunyekService.getHierarchy(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.gettingHierarchy = false;
          this.roParent = res.parentlist;
        } else {
          this.gettingHierarchy = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingHierarchy = false;
      }
    );
  }

  userChange(data: any) {
    this.isUserValid = true;
    this.selectedUserName = data.username;
    this.selectedUserid = data.userid;
    this.hcForm.ceid = data.employeeid;

    this.selectedEmployeeid = data.employeeid;

    if (data.type.toString().trim() != '') {
      const index = this.positions.findIndex(
        (x: any) =>
          x.name.toString().trim() == data.type.toString().trim() &&
          x.department.toString().trim() == data.department.toString().trim() &&
          x.division.toString().trim() == data.division.toString().trim()
      );
      console.log(index);
      if (index > -1) {
        this.hcForm.cindex = index.toString();
      } else {
        var temp = {
          appid: '',
          createdby: '',
          department: data.department.toString().trim(),
          description: '',
          division: data.division.toString().trim(),
          domainid: '',
          id: '',
          name: data.type.toString().trim(),
          orgid: '',
          positionid: '',
          pctype: '2',
        };
        this.hcForm.cindex = '';
        const tindex = this.positions.findIndex((x: any) => x.pctype == '2');
        if (tindex == -1) {
          this.positions.push(temp);
          this.hcForm.cindex = (this.positions.length - 1).toString();
        } else {
          this.positions[tindex] = temp;
          this.hcForm.cindex = tindex.toString();
        }
      }
    }
    this.isUserValid = true;

    console.log(data);
  }

  submitHC() {
    this.searchText = this.hcForm.ceid;
    this.hcFormSubmitted = true;
    if (this.hcForm.type == '1') {
      if (this.insertType == 2 && this.children.length == 0) {
        this.messageService.openSnackBar(
          'Please select at least one child.',
          'warn'
        );
      } else {
        if (
          !this.firstTime &&
          this.hcForm.cindex &&
          this.hcForm.ceid &&
          this.hcForm.peid &&
          this.hcForm.pid &&
          parseInt(this.hcForm.order) >= 0
        ) {
          this.saveHC();
        } else if (
          (this.firstTime || this.insertType == 1) &&
          this.hcForm.cindex &&
          this.hcForm.ceid &&
          this.hcForm.peid &&
          this.hcForm.pindex &&
          parseInt(this.hcForm.order) >= 0
        ) {
          this.saveHC();
        }
      }
    } else if (this.hcForm.type != '1') {
      if (this.hcForm.cindex && this.hcForm.ceid && this.hcForm.peid) {
        this.saveHC();
      }
    }
  }

  async saveHC() {
    this.saveLoading = true;
    if (this.hcForm.type == '1') {
      if (this.firstTime || this.insertType == 1) {
        var pPos = this.positions[parseInt(this.hcForm.pindex)];
        var cPos = this.positions[parseInt(this.hcForm.cindex)];
        var tempPid = pPos.positionid;
        var tempCid = cPos.positionid;
        if (tempPid == '') {
          const data = {
            domainid: this.allinoneService.getDomain().domainid,
            orgid: this.hcForm.orgid,
            name: pPos.name,
            department: pPos.department,
            division: pPos.division,
            description: pPos.description,
          };
          tempPid = await this.addPosition(data);
          if (tempPid == '') {
            return;
          }
          this.positions[parseInt(this.hcForm.pindex)].positionid = tempPid;
        }
        if (tempCid == '' && this.hcForm.pindex != this.hcForm.cindex) {
          const data = {
            domainid: this.allinoneService.getDomain().domainid,
            orgid: this.hcForm.orgid,
            name: cPos.name,
            department: cPos.department,
            division: cPos.division,
            description: cPos.description,
          };
          tempCid = await this.addPosition(data);
          if (tempCid == '') {
            return;
          }
          this.positions[parseInt(this.hcForm.cindex)].positionid = tempCid;
        }
        if (this.hcForm.pindex != this.hcForm.cindex) {
          this.addHierarchy(tempPid, tempCid);
        } else {
          this.addHierarchy(tempPid, tempPid);
        }
      } else {
        var cPos = this.positions[parseInt(this.hcForm.cindex)];
        if (cPos.positionid == '') {
          const data = {
            domainid: this.allinoneService.getDomain().domainid,
            orgid: this.hcForm.orgid,
            name: cPos.name,
            department: cPos.department,
            division: cPos.division,
            description: cPos.description,
          };
          var tempCid2 = await this.addPosition(data);
          if (tempCid2 == '') {
            return;
          }
          this.positions[parseInt(this.hcForm.cindex)].positionid = tempCid2;
          this.addHierarchy(this.hcForm.pid, tempCid2);
        } else {
          this.addHierarchy(this.hcForm.pid, cPos.positionid);
        }
      }
    } else if (this.hcForm.type != '1') {
      var cPos = this.positions[parseInt(this.hcForm.cindex)];
      if (cPos.positionid == '') {
        const data = {
          domainid: this.allinoneService.getDomain().domainid,
          orgid: this.hcForm.orgid,
          name: cPos.name,
          department: cPos.department,
          division: cPos.division,
          description: cPos.description,
        };
        var tempCid2 = await this.addPosition(data);
        if (tempCid2 == '') {
          return;
        }
        this.positions[parseInt(this.hcForm.cindex)].positionid = tempCid2;
        this.addHierarchy(this.hcForm.pid, tempCid2);
      } else {
        this.addHierarchy(this.hcForm.pid, cPos.positionid);
      }
    }
  }

  addPosition(data: any) {
    return new Promise((resolve: any) => {
      this.kunyekService.createPosition(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            resolve(res.positionid);
          } else {
            resolve('');
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve('');
        }
      );
    });
  }

  addHierarchy(pid: any, cid: any) {
    var data: any;
    var order =
      parseInt(this.hcForm.order) >= 0 ? parseInt(this.hcForm.order) : '';
    if (this.insertType == 1) {
      data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.hcForm.orgid,
        cid: pid,
        ceid: this.hcForm.peid,
        pid: cid,
        peid: this.hcForm.ceid,
        type: this.hcForm.type,
        inserttype: 'up',
        children: [],
        order: order,
        primary : this.hcForm.primary ? '1' :'0',
      };
    } else if (this.insertType == 2) {
      var finalChildrenList: any = [];
      this.children.map((item: any) => {
        const temp = {
          id: item.id,
          userid: item.userid,
          positionid: item.positionid,
          peid: item.peid,
          pid: item.pid,
          employeeid: item.employeeid,
          parentuserid: item.parentuserid,
          primary : this.hcForm.primary ? '1' :'0',
        };
        finalChildrenList.push(temp);
      });
      data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.hcForm.orgid,
        cid: cid,
        ceid: this.hcForm.ceid,
        pid: pid,
        peid: this.hcForm.peid,
        type: this.hcForm.type,
        inserttype: 'down',
        children: finalChildrenList,
        order: order,
        primary : this.hcForm.primary ? '1' :'0',
      };
    } else {
      data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.hcForm.orgid,
        cid: cid,
        ceid: this.hcForm.ceid,
        pid: pid,
        peid: this.hcForm.peid,
        type: this.hcForm.type,
        inserttype: 'down',
        children: [],
        order: order,
        primary : this.hcForm.primary ? '1' :'0',
      };
    }
    // return;
    this.kunyekService.addHierarchy(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.isAdded = true;

          if (this.hcForm.type == this.data.selectedType) {
            if (this.hcForm.type == '1') {
              if (this.firstTime) {
                this.parentList.push({
                  positionid: pid,
                  employeeid: this.hcForm.peid,
                  positionname:
                    this.positions[parseInt(this.hcForm.pindex)].name,
                  department:
                    this.positions[parseInt(this.hcForm.pindex)].department,
                  division:
                    this.positions[parseInt(this.hcForm.pindex)].division,
                  username: this.selectedPUserName
                    ? this.selectedPUserName
                    : this.selectedPUserid,
                  userid: this.selectedPUserid,
                  parentpositionname: '',
                  parentdepartment: '',
                  parentdivision: '',
                  pid: '',
                  peid: '',
                  id: res.parentid,
                  expanded: true,
                  parentusername: '',
                  parentuserid: '',
                  order: 0,
                  primary : this.hcForm.primary ? '1' :'0',
                  htype: this.hcForm.type,
                });
              }

              //up
              if (this.insertType == 1) {
                var index = this.parentList
                  .map((x) => x.positionid + ',' + x.employeeid)
                  .indexOf(data.pid + ',' + data.peid);
                var parentRes: any = this.parentList.filter(
                  (x: any) =>
                    x.positionid == this.parentList[index].pid &&
                    x.employeeid == this.parentList[index].peid
                );
                if (parentRes.length > 0) {
                  var parentpositionname = parentRes[0].positionname;
                  var parentdepartment = parentRes[0].department;
                  var parentdivision = parentRes[0].division;
                  var pusername = parentRes[0].username;
                  var puserid = parentRes[0].userid;
                }

                this.parentList.push({
                  positionid: data.cid,
                  employeeid: data.ceid,
                  positionname:
                    this.positions[parseInt(this.hcForm.pindex)].name,
                  department:
                    this.positions[parseInt(this.hcForm.pindex)].department,
                  division:
                    this.positions[parseInt(this.hcForm.pindex)].division,
                  username: this.selectedPUserName
                    ? this.selectedPUserName
                    : this.selectedPUserid,
                  userid: this.selectedPUserid,
                  parentpositionname: parentpositionname,
                  parentdepartment: parentdepartment,
                  parentdivision: parentdivision,
                  pid: this.parentList[index].pid,
                  peid: this.parentList[index].peid,
                  id: res.childid,
                  expanded: true,
                  parentusername: pusername,
                  parentuserid: puserid,
                  order: data.order,
                  primary : this.hcForm.primary ? '1' :'0',
                  htype: this.hcForm.type,
                });
                this.parentList[index].pid = data.cid;
                this.parentList[index].peid = data.ceid;
              }
              //between
              else if (this.insertType == 2) {
                console.log('between');
                var parentRes: any = this.parentList.filter(
                  (x: any) =>
                    x.positionid == pid && x.employeeid == this.hcForm.peid
                );
                if (parentRes.length > 0) {
                  var parentpositionname = parentRes[0].positionname;
                  var parentdepartment = parentRes[0].department;
                  var parentdivision = parentRes[0].division;
                  var pusername = parentRes[0].username;
                  var puserid = parentRes[0].userid;
                }

                this.parentList.push({
                  positionid: cid,
                  employeeid: this.hcForm.ceid,
                  positionname:
                    this.positions[parseInt(this.hcForm.cindex)].name,
                  department:
                    this.positions[parseInt(this.hcForm.cindex)].department,
                  division:
                    this.positions[parseInt(this.hcForm.cindex)].division,
                  username: this.selectedUserName
                    ? this.selectedUserName
                    : this.selectedUserid,
                  userid: this.selectedUserid,
                  parentpositionname: parentpositionname,
                  parentdepartment: parentdepartment,
                  parentdivision: parentdivision,
                  pid: pid,
                  peid: this.hcForm.peid,
                  id: res.childid,
                  expanded: true,
                  parentusername: pusername,
                  parentuserid: puserid,
                  order: data.order,
                  primary : this.hcForm.primary ? '1' :'0',
                  htype: this.hcForm.type,
                });
                console.log(this.children);
                for (var i = 0; i < this.children.length; i++) {
                  var index = this.parentList
                    .map((x) => x.id)
                    .indexOf(finalChildrenList[i].id);
                  console.log(index);
                  console.log(this.parentList[index]);
                  this.parentList[index].pid = data.cid;
                  this.parentList[index].peid = data.ceid;
                  this.parentList[index].parentpositionname =
                    this.positions[parseInt(this.hcForm.cindex)].name;
                  this.parentList[index].parentdepartment =
                    this.positions[parseInt(this.hcForm.cindex)].department;
                  this.parentList[index].parentdivision =
                    this.positions[parseInt(this.hcForm.cindex)].division;
                  this.parentList[index].parentusername = this.selectedUserName
                    ? this.selectedUserName
                    : this.selectedUserid;
                  this.parentList[index].parentuserid = this.selectedUserid;
                  this.parentList[index].primary = this.hcForm.primary ? '1' :'0';
                  this.parentList[index].htype = this.hcForm.type;
                }
              }
              //down
              else if (this.insertType == 3) {
                var parentRes: any = this.parentList.filter(
                  (x: any) =>
                    x.positionid == pid && x.employeeid == this.hcForm.peid
                );
                if (parentRes.length > 0) {
                  var parentpositionname = parentRes[0].positionname;
                  var parentdepartment = parentRes[0].department;
                  var parentdivision = parentRes[0].division;
                  var pusername = parentRes[0].username;
                  var puserid = parentRes[0].userid;
                }
                this.parentList.push({
                  positionid: cid,
                  employeeid: this.hcForm.ceid,
                  positionname:
                    this.positions[parseInt(this.hcForm.cindex)].name,
                  department:
                    this.positions[parseInt(this.hcForm.cindex)].department,
                  division:
                    this.positions[parseInt(this.hcForm.cindex)].division,
                  username: this.selectedUserName
                    ? this.selectedUserName
                    : this.selectedUserid,
                  userid: this.selectedUserid,
                  parentpositionname: parentpositionname,
                  parentdepartment: parentdepartment,
                  parentdivision: parentdivision,
                  pid: pid,
                  peid: this.hcForm.peid,
                  id: res.childid,
                  expanded: true,
                  parentusername: pusername,
                  parentuserid: puserid,
                  order: data.order,
                  primary : this.hcForm.primary ? '1' :'0',
                  htype: this.hcForm.type,
                });
              }
            } else {
              if (
                this.firstTime ||
                this.parentList.filter(
                  (x: any) =>
                    x.positionid == '' && x.employeeid == this.hcForm.peid
                ).length == 0
              ) {
                this.parentList.push({
                  positionid: '',
                  employeeid: this.hcForm.peid,
                  positionname: '',
                  department: '',
                  division: '',
                  username: this.selectedPUserName
                    ? this.selectedPUserName
                    : this.selectedPUserid,
                  userid: this.selectedPUserid,
                  parentpositionname: '',
                  parentdepartment: '',
                  parentdivision: '',
                  pid: '',
                  peid: '',
                  id: res.parentid,
                  expanded: true,
                  parentusername: '',
                  parentuserid: '',
                });
                this.searchText1 = '';
                console.log(this.hcForm.peid);
              }
              var parentRes: any = this.parentList.filter(
                (x: any) =>
                  x.positionid == pid && x.employeeid == this.hcForm.peid
              );
              if (parentRes.length > 0) {
                var pusername = parentRes[0].username;
                var puserid = parentRes[0].userid;
              }
              this.parentList.push({
                positionid: cid,
                employeeid: this.hcForm.ceid,
                positionname: this.positions[parseInt(this.hcForm.cindex)].name,
                department:
                  this.positions[parseInt(this.hcForm.cindex)].department,
                division: this.positions[parseInt(this.hcForm.cindex)].division,
                username: this.selectedUserName
                  ? this.selectedUserName
                  : this.selectedUserid,
                userid: this.selectedUserid,
                parentpositionname: '',
                parentdepartment: '',
                parentdivision: '',
                pid: pid,
                peid: this.hcForm.peid,
                id: res.childid,
                expanded: true,
                parentusername: pusername,
                parentuserid: puserid,
                htype: this.hcForm.type
              });
            }
          }
          this.children = [];
          var tempPositionList = [];
          for(var i = 0; i < this.positions.length; i++) {
            if(this.positions[i].positionid != '') {
              tempPositionList.push(this.positions[i])
            }
          }
          this.dialogRef.close({
            isAdded: this.isAdded,
            parentList: this.parentList,
            positions: tempPositionList
          });
          this.messageService.openSnackBar('Saved successfully.', 'success');
        } else {
          this.saveLoading = false;
          this.hcFormSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.hcFormSubmitted = false;
        this.saveLoading = false;
      }
    );
  }

  getPositions() {
    this.alreadyNewPos = false;
    this.gettingPositionList = true;
    const data = {
      orgid: this.hcForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.kunyekService.getPositionList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.positions = res.datalist;
          this.positions.sort((a, b) => {
            let fa = a.name.toLowerCase(),
              fb = b.name.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          this.gettingPositionList = false;
          if (this.insertType == 1) {
            this.hcForm.cindex = this.positions
              .findIndex((x: any) => x.positionid == this.data.node.positionid)
              .toString();
          }
        } else {
          this.gettingPositionList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingPositionList = false;
      }
    );
  }

  close() {
    // this.dialogRef.close(this.isAdded, this.positions);
    var tempPositionList = [];
    for(var i = 0; i < this.positions.length; i++) {
      if(this.positions[i].positionid != '') {
        tempPositionList.push(this.positions[i])
      }
    }
    this.dialogRef.close({
      isAdded: this.isAdded,
      positions : tempPositionList,
    });
    
  }

  selectChildren() {
    const dialog = this.dialog.open(HierarchyChildrenComponent, {
      // maxHeight: '650px',
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        list: this.childrenList,
        selectedList: this.children,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult.confirm == true) {
        this.children = dialogResult.list;
        console.log(this.children);
      }
    });
  }
}
