<div class="d-flex align-items-center justify-content-between mb-3">
    <div>
      <h2 class="mb-0">Employee</h2>
    </div>
    <div>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
    <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
      <input class="form-control" type="text" placeholder="&#xf002; Search by ID/Name"
        aria-label="Search by Name/Description" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
        (ngModelChange)="searchTextChange()" [ngClass]="{ 'c-search-input-with-clear': searchText }"
        style="font-family: FontAwesome, Ubuntu" />
      <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
        <mat-icon class="c-icon">close</mat-icon>
      </button>
    </div>
    <!-- <div class="line"></div> -->
    <!-- <div class="d-flex align-items-center">
      <span class="total-count-text">Total:&nbsp;</span>
      <span class="total-count-num">{{
        (postList | filter : searchText : "postList").length
        }}</span>
    </div> -->
  
    <div class="input-group mt-2" *ngIf="isMobileView">
      <input class="form-control" type="text" placeholder="&#xf002; Search by Name/Description" aria-label="Search"
        (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
        [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
        (ngModelChange)="searchTextChange()" />
      <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
        <mat-icon class="c-icon">close</mat-icon>
      </button>
    </div>
  
    <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
      *ngIf="postList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
    </pagination-controls>
  </div>
  <div class="table-responsive" matSort (matSortChange)="sortData($event)" style="height: 401px;">
    <table class="table table-responsive table-borderless">
      <thead class="table-header">
        <tr>
          <td scope="col">
            <div class="form-check">
              <input class="form-check-input td-edit" type="checkbox" id="primary" [(ngModel)]="currentPageAllSelected"
                (ngModelChange)="currentPageSelectAll()" />
            </div>
          </td>
          <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="userid">
            Name
          </td>
          <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="username">
            Description
          </td>
        </tr>
      </thead>
  
      <tbody *ngIf="postListLoading">
        <tr>
          <td colspan="7" class="nodata">
            <div class="spinner-border" entitle="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
      </tbody>
  
      <tbody *ngIf="!postListLoading">
        <tr *ngIf="postList.length == 0">
          <td colspan="7" class="nodata">Empty</td>
        </tr>
  
        <tr *ngFor="
            let post of postList
              | filter : searchText : 'postList'
              | paginate
                : { itemsPerPage: itemPerPage, currentPage: currentPage };
            let index = index
          ">
          <td style="vertical-align: top;
        padding-top: 10px !important;">
            <div class="form-check">
              <input class="form-check-input td-edit" type="checkbox" id="primary" [(ngModel)]="post.isChecked"
                (ngModelChange)="selectPost(post)" />
            </div>
          </td>
          <td class="td-data">
            <span [title]="post.name">{{ post.name }}</span>
          </td>
          <td class="td-data">
            <span [title]="post.description">{{ post.description }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex align-items-center justify-content-end">
    <!-- <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!saveLoading"> -->
    <button type="button" class="btn btn-custom me-2 mb-3" mat-dialog-close>
      Cancel
    </button>
    <app-button-loader class="mb-3" (click)="addPost()" [button_text]="'Save'" [load_button_text]="'Saving'"
      [isLoading]="saveLoading">
    </app-button-loader>
  </div>