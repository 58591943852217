import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-classcode',
  templateUrl: './classcode.component.html',
  styleUrls: ['./classcode.component.scss'],
})
export class ClasscodeComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild('tbody') tbody!: ElementRef;

  ccFormSubmitted: boolean = false;
  editFormSubmitted: boolean = false;
  gettingCcList: boolean = false;
  isEditing: boolean = false;
  disable: boolean = true;
  saveLoading: boolean = false;
  fromOrg: boolean = false;
  fromHCM: boolean = false;
  classCodeAddOrNot: boolean = false;
  isFocus: boolean = false;

  ccList: any[] = [];
  organizations: any[] = [];
  membershiptype: any = '';
  domainid: string = '';

  searchText: string = '';

  p: number = 1;

  ccForm = {
    orgid: '',
    classcode: '',
    desc: '',
    id: '',
  };

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ClasscodeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fromOrg = data.fromOrg;
    this.fromHCM = data.fromHCM ? data.fromHCM : false;
    if (this.fromOrg) {
      console.log(data.org);
      this.organizations.push(data.org);
      this.ccForm.orgid = data.org.orgid;
    } else {
      this.ccForm.orgid = data.orgid;
    }
  }

  ngOnInit(): void {
    // if (!this.fromOrg) {
    //   this.organizations = this.allinoneService.orgs;
    //   // this.ccForm.orgid = this.allinoneService.selectedOrg ? this.allinoneService.selectedOrg : this.organizations[0].orgid
    // }
    // if(this.fromHCM){
    //   this.organizations = this.allinoneService.hrOrgs;
    //   console.log(this.organizations);
    // }
      this.organizations = this.allinoneService.orgs;
    const index = this.organizations.findIndex(
      (x: any) => x.orgid == this.ccForm.orgid
    );
    this.membershiptype = this.organizations[index].membership;
    this.domainid = this.allinoneService.getDomain().domainid;
    this.getCcList();
  }

  sortData(sort: Sort) {
    const data = this.ccList;
    if (!sort.active || sort.direction === '') {
      this.ccList = data;
      return;
    }

    this.ccList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'classcode':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  cancel() {
    this.isEditing = false;
    this.classCodeAddOrNot = false;
    this.ccFormSubmitted = false;
    this.ccForm = {
      classcode: '',
      desc: '',
      id: '',
      orgid: this.ccForm.orgid,
    };
  }

  editClasscode(item: any) {
    this.isEditing = true;
    this.classCodeAddOrNot = true;
    this.ccForm = {
      classcode: item.name,
      desc: item.description,
      id: item.id,
      orgid: this.ccForm.orgid,
    };
  }

  orgChanged(event: any) {
    this.ccForm.orgid = event.target.value;
    const index = this.organizations.findIndex(
      (x: any) => x.orgid == this.ccForm.orgid
    );
    this.membershiptype = this.organizations[index].membership;
    this.getCcList();
  }

  submitCc() {
    this.ccFormSubmitted = true;
    if (this.ccForm.classcode.trim() == '') {
      return;
    } else {
      this.saveCc();
    }
  }

  saveCc() {
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var data = {
      domainid: this.domainid,
      orgid: this.ccForm.orgid,
      name: this.ccForm.classcode,
      description: this.ccForm.desc,
    };

    if (this.isEditing) {
      data = Object.assign(data, { id: this.ccForm.id });
    }

    const fun = this.isEditing
      ? this.kunyekService.updateClasscode(data)
      : this.kunyekService.createClasscode(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.getCcList();
          this.cancel();
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar('Saved successfully.', 'success');
        } else {
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.ccFormSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
        this.ccFormSubmitted = false;
      }
    );
  }

  getCcList() {
    this.gettingCcList = true;
    const data = {
      orgid: this.ccForm.orgid,
      domainid: this.domainid,
    };
    this.kunyekService.getClassCodeList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.ccList = res.datalist;
          this.gettingCcList = false;
        } else {
          this.gettingCcList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingCcList = false;
      }
    );
  }

  inputChange() {
    if (this.ccForm.classcode.trim() == '') {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  checkOverflow(element: any) {
    return element.offsetHeight < element.scrollHeight;
  }

  clear(){
    this.searchText = '';
  }
}
