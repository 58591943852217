import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';


@Component({
  selector: 'app-select-referrer',
  templateUrl: './select-referrer.component.html',
  styleUrls: ['./select-referrer.component.scss']
})
export class SelectReferrerComponent implements OnInit {

  @ViewChild('chatPS') chatPS!: ElementRef;

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  searchingMember: boolean = false;
  searchCustomerCancel: boolean = false;
  isLeaveOpening: boolean = false;
  memberSearch = '';
  memberList: any = [];
  _memberList: any = [];
  selectedMemberList: any = [{
    memberType: {},
    name: "",
    phone: '',
    profileurl: "",
    syskey: ""
  },
  {
    memberType: {},
    name: "",
    phone: '',
    profileurl: "",
    syskey: ""
  }
  ];
  isAllMembers: boolean = false;
  _isEnd: boolean = false;
  isAdmin: boolean = false;
  gettingMemberList: boolean = true;
  currentMemberId: string = '';

  domainid: string = '';
  orgid: string = '';
  templateid: string = '';
  calendarid: string = '';

  startCount: any = 0;
  endCount: any = 30;
  referrer_number: number = 0

  subscription!: Subscription;
  mytimeout: any;
  private dialogData: any;
  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<SelectReferrerComponent>,
    private injector: Injector,
    // private dialog: MatDialog,
    private router: Router,
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    console.log(this.dialogData, 'dia')
    this.domainid = this.dialogData.domainid;
    this.orgid = this.dialogData.orgid;
    this.currentMemberId = this.dialogData.currentMemberId
    this.selectedMemberList = this.dialogData.selectedReferrersList
    this.referrer_number = this.dialogData.referrer_number
    console.log(this.selectedMemberList, 'dial selected memberslist')
  }

  ngOnInit(): void {
    this.homesearchMember()
  }

  // searchChange() {
  //   if (this.memberSearch.trim() == '') {
  //     this.isAllMembers = false;
  //   }
  //   clearTimeout(this.mytimeout);
  //   this.mytimeout = setTimeout(() => {
  //     // if (this.memberSearch.trim() != '') {
  //     this.memberList = [];
  //     this.chatPS.nativeElement.scrollTop = 0;
  //     this.startCount = 0;
  //     this.endCount = 30;
  //     // }
  //     this.homesearchMember();
  //   }, 500);
  // }

  searchChange() {
    this.memberList.filter = this.memberSearch.trim().toLowerCase();
  }


  homesearchMember() {
    this.memberList = [];
    const data = {
      membertypeid: 'ASDLFKJ',
      formstatus: '002',
      searchName: this.memberSearch
    }
    this.subscription = this.kunyekService.getKMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.memberList = res.datalist
          var index = this.memberList.findIndex(
            (member: any) => member.syskey == this.currentMemberId
          );
          if (index > -1) {
            this.memberList.splice(index, 1);
          }

          // this.memberList.map((member: any) => {
          //   let piamge = JSON.parse(member.profileimage);
          // member.profileUrl = piamge ? piamge.fileurl : ""
          //   this.selectedMemberList.map((sel: any) => {
          //     if (sel.syskey == member.syskey) {
          //       member.selected = sel.selected
          //     }
          //   })
          // })
          this.memberList.forEach((i: any) => {
            this.selectedMemberList.forEach((j: any) => {
              if (i.syskey == j.syskey) {
                i.selected = true;
              }
            })
          })
          console.log(this.memberList, '....................checked or not after opening')
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingMemberList = false;
      },
      (err) => {
        this.gettingMemberList = false;
      }
    );

    this.gettingMemberList = false
    if (this.selectedMemberList.length > 0) {

    }
  }

  onScrolledDown() {
    if (!this.isAllMembers) {
      this.startCount = this.startCount + 30;
      this.endCount = this.endCount + 30;
      // this.homesearchMember();
    }
  }

  selectMember(user: any) {
    console.log(user, 'event and user')
    // var index = this.memberList.findIndex(
    //   (cus: any) => cus.syskey == user.member_id
    // );
    // console.log(index);
    // if (index != -1) {
    //   this.memberList[index].checked = !this.memberList[index].checked;
    // }
    // if (event.target.checked) {
    //   var index = this.selectedMemberList.findIndex(
    //     (seluser: any) => seluser.relative_id == user.syskey
    //   );
    //   if (index == -1) {
    //     user.checked = true;
    this.memberList.map((i: any) => {
      if (i.syskey == user.syskey) {
        i.selected = true;
      }
    })
    // if (this.selectedMemberList.length > 0) {
    //   this.selectedMemberList.forEach((i: any) => {
    //     if (i.syskey != user.syskey) {
    //       this.selectedMemberList.push(
    //         {
    //           syskey: user.syskey,
    //           name: user.name,
    //           profileurl: user.profileurl,
    //           memberType: user.memberType,
    //           phone: user.phone
    //         }
    //       );
    //     }
    //   })
    // } else {
    //   this.selectedMemberList.push(
    //     {
    //       syskey: user.syskey,
    //       name: user.name,
    //       profileurl: user.profileurl,
    //       memberType: user.memberType,
    //       phone: user.phone
    //     }
    //   );
    // }
    if (this.referrer_number == 1) {
      this.selectedMemberList[0].syskey = user.syskey,
        this.selectedMemberList[0].name = user.name,
        this.selectedMemberList[0].profileurl = user.profileurl,
        this.selectedMemberList[0].memberType = user.memberType,
        this.selectedMemberList[0].phone = user.phone
    }

    if (this.referrer_number == 2) {
      this.selectedMemberList[1].syskey = user.syskey,
        this.selectedMemberList[1].name = user.name,
        this.selectedMemberList[1].profileurl = user.profileurl,
        this.selectedMemberList[1].memberType = user.memberType,
        this.selectedMemberList[1].phone = user.phone
    }

    console.log(this.selectedMemberList)
    if (this.selectedMemberList.length > 2) {
      this.selectedMemberList = this.selectedMemberList[this.selectedMemberList.length - 1]
      console.log(this.selectedMemberList)
      // this.selectedMemberList[0].selected = true;
      // this.selectedMemberList[1].selected = true;
    }


    // } else {

    //   user.checked = false;
    //   this.selectedMemberList[index].checked = !this.selectedMemberList[index].checked;
    // }
    // } else {
    //   var index ;
    //   this.selectedMemberList.forEach((i:any)=>{
    //     if(i.relative_id==user.syskey){
    //       index = this.selectedMemberList.indexOf(i, 0);
    //     }
    //   })
    //   if (index > -1) {
    //     this.selectedMemberList.splice(index, 1);
    //   }
    // }
    console.log(this.selectedMemberList, 'dialog selected user;');

    this.dialogRef.close({ data: this.selectedMemberList });

  }

  close(res: any) {
    this.dialogRef.close({ data: res });
  }
  done() {
    // let finalList = [];
    // finalList = this.selectedMemberList.filter((user: any) => user.checked === true
    // );
    // console.log('fl');
    // console.log(finalList);

    this.dialogRef.close({ data: this.selectedMemberList });
  }


  searchByName() {
    this.gettingMemberList = true
    this.homesearchMember();
  }


}


