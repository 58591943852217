<div class="container-fluid">
    <div class="content">
        <div>
            <app-transportation-breadcrumb *ngIf="!isEditing" s_breadcrumb="Outstanding Requests">
            </app-transportation-breadcrumb>
            <app-transportation-breadcrumb *ngIf=" isEditing" s_breadcrumb="Outstanding Requests"
                [t_breadcrumb]="outstandingRequests.name  " (emit)="cancelFunction()">
            </app-transportation-breadcrumb>

            <!--  (emit1)="cancels(2)"  -->
            <div *ngIf="!isEditing">
                <div class=" w-100 d-flex flex-wrap me-auto ">
                    <div class="mb-2 col-md-2 me-2">
                        <input type="date" class="form-control" id="startdatetime" [(ngModel)]="startdate"
                            name="startdate" (change)="searchdateApi()">
                    </div>

                    <!-- <div style="width: 250px !important;" class="me-2">
                        <select class="form-select" id="type" name="type" [(ngModel)]="typerequest"
                            (change)="selecttrantype($event)">
                            <option *ngFor="let type of typelist" [value]="type.type">
                                {{ type.name }}
                            </option>
                        </select>
                    </div> -->

                    <div class="m2-4 me-2" [matMenuTriggerFor]="typemenu">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                            mdbDropdownToggle
                            style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;">
                            {{typename}}
                        </button>
                    </div>
                    <mat-menu #typemenu="matMenu" xPosition="before" class="menuproject">
                        <button *ngFor="let type of typelist" class="mat-item"
                            (click)="selecttrantype(type)">{{type.name}}</button>
                    </mat-menu>

                    <div class="input-group me-2 mb-2" style="width: 250px !important;">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                            [(ngModel)]="searchText" style="font-family: FontAwesome,Ubuntu;">
                    </div>
                    <div class="dropdown mb-2 me-2">
                        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false" matTooltip="Page Size">
                            <mat-icon>filter_list</mat-icon>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div c style="text-align: center;">Page size</div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li class="dropdown-item" *ngFor="let page of pg">
                                <input type="radio" name="pg-count" value="{{page.count}}"
                                    (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                    [checked]="page.count==itemPerPage">
                                <label for="pg{{page.name}}" class="col-10 p-1">
                                    {{page.name}}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <!-- me-2 -->
                    <div class="line mb-2 "></div>
                    <div class="mb-2 me-2">
                        <button type="button" class="btn btn-custom" (click)="downloadSheet()"
                            matTooltip="Export Reauest">
                            <img class="excel-icon" src="../../../assets/images/excel.png">Export
                        </button>
                    </div>
                    <div class="me-2 mb-2 ">
                        <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Search">
                            Search
                            <!-- <mat-icon>refresh</mat-icon> -->
                        </button>
                    </div>
                    <div class="line mb-2 "></div>
                    <div class="d-flex align-items-center me-auto mb-2 ">
                        <span class="total-count-text ">Total:&nbsp;</span> <span
                            class="total-count-num ">{{outstandingrequestList.length}}</span>
                    </div>
                    <div>
                    </div>
                </div>

                <div>
                    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <!-- <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="requestrefno">Ref No.
                                    </td> -->
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="employeeid">Employee ID
                                    </td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="username">Name</td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="startdate">Date</td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="status">Status</td>
                                    <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="remark">Remark</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <tr>
                                    <td colspan="6" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="gettingData== false">
                                <tr *ngIf="outstandingrequestList.length == 0">
                                    <td colspan="6" class="nodata">Empty</td>
                                </tr>
                                <tr
                                    *ngFor="let item of outstandingrequestList | filter : searchText:'requests';let i = index;">
                                    <!-- <td class="td-data" (click)="EmployeeEdit(item,i)" style="cursor: pointer;">
                                        <span [title]="item.requestrefno">{{item.requestrefno}}</span>
                                    </td> -->
                                    <td class="td-data" (click)="EmployeeEdit(item,i)" style="cursor: pointer;">
                                        <span [title]="item.employeeid">{{item.employeeid}}</span>
                                    </td>
                                    <td class="td-data" (click)="EmployeeEdit(item,i)" style="cursor: pointer;">
                                        <span [title]="item.username">{{item.username}}</span>
                                    </td>
                                    <td class="td-data" style="cursor: pointer;" (click)="EmployeeEdit(item,i)">
                                        <span>{{allinoneService.formatDBToShow(allinoneService.formatDate(item.date))}}
                                        </span>
                                    </td>
                                    <td class="td-data" (click)="EmployeeEdit(item,i)" style="cursor: pointer;">
                                        <span class="status" [ngClass]="{
                                            'status-green': item.requeststatus == '002',
                                            'status-red': item.requeststatus == '003',
                                            'status-gray': item.requeststatus == '001',
                                            'status-main': item.requeststatus == '004'
                                          }">{{item.requeststatus == '001' ? 'Submitted' :
                                            item.requeststatus == '002' ? 'Approved' : item.requeststatus == '003' ?
                                            'Rejected' : item.requeststatus == '004' ? 'Completed' : ''}}</span>
                                    </td>
                                    <td class="td-data" style="cursor: pointer;" (click)="EmployeeEdit(item,i)">
                                        <span [title]="item.remark">{{item.remark}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="addrequest || isEditing">
                <div class="d-flex flex-wrap">
                    <div class="close-save-button mb-3 ">
                        <button type="button" class=" btn btn-custom me-2" (click)="cancelFunction()">
                            Cancel
                        </button>

                    </div>
                
                  
                    <div class="d-flex align-items-center ms-auto "
                            *ngIf=" outstandingRequests.status != '001' || (outstandingRequests.status == '001')">
                            <span [title]="outstandingRequests.status" class="status" [ngClass]="{
                        'status-green': outstandingRequests.status == '002',
                        'status-red': outstandingRequests.status == '003',
                        'status-gray': outstandingRequests.status == '001',
                        'status-main': outstandingRequests.status == '004'}">
                                <span
                                    *ngIf="outstandingRequests.status == '001' || outstandingRequests.status == '002' || outstandingRequests.status == '003' || outstandingRequests.status == '004'">
                                    {{ outstandingRequests.status == '001' ? 'Submitted' : outstandingRequests.status == '002' ?
                                    'Approved' : outstandingRequests.status
                                    == '003' ? 'Rejected' : 'Completed' }}
                                </span>
                            </span>
                        </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">Name</label>
                        <input type="text" class="form-control" id="name" name="name"
                            [(ngModel)]="outstandingRequests.name" disabled>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="employee" class="form-label">Employee ID</label>
                        <input type="text" class="form-control" id="emplyoee" name="employee"
                            [(ngModel)]="outstandingRequests.employeeid" disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <label for="date" class="form-label">Date</label>
                        <input type="text" class="form-control" id="date" name="date"
                            [(ngModel)]="outstandingRequests.date" disabled>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="d-flex flex-wrap align-items-center">
                        <ng-container *ngFor="let day of weekDay;let i = index;">
                            <div class="form-check mb-3 me-5">
                                <input class="form-check-input" type="checkbox" [attr.name]="day.name"
                                    [attr.id]="day.name" [checked]="outstandingRequests.weekday.includes(day.value)"
                                    disabled="true">
                                <label class="form-check-label" [attr.for]="day.name"> {{day.name}}</label>
                            </div>
                        </ng-container>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="mb-3 col-md-12">
                        <label for="remark" class="form-label">Remark</label>
                        <textarea type="text" style="height: 100px;" class="form-control" id="remark" name="remark"
                            [(ngModel)]="outstandingRequests.remark" disabled></textarea>
                    </div>
                </div> -->

                <div class="row" *ngIf="typerequest == '001'">
                    <div class="mb-3">
                        <label for="pickuppoint" class="form-label">Pick Up Point</label>
                        <input type="text" class="form-control" id="pickuppoint" name="pickuppoint"
                            [(ngModel)]="outstandingRequests.pickuppointname" disabled>
                    </div>
                </div>


                <div *ngIf="typerequest == '002'">


                    <div class="row">
                        <div class="mb-3 col-md-12">
                            <label for="date" class="form-label">To Place</label>
                            <input type="text" class="form-control" id="date" name="date"
                                [(ngModel)]="outstandingRequests.toplace" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <div class="flex-wrap d-flex">
                                <div class="form-check cus me-5" *ngIf="outstandingRequests.going">
                                    <input class="form-check-input cus" type="checkbox" id="void" name="void"
                                        checked="checked" disabled>
                                    <label class="form-check-label cus mb-1 ms-1" for="void">
                                        Going
                                    </label>
                                </div>
                                <div class="form-check cus me-5" *ngIf="!outstandingRequests.going">
                                    <input class="form-check-input cus" type="checkbox" id="void" name="void" disabled>
                                    <label class="form-check-label cus mb-1 ms-1" for="void">
                                        Going
                                    </label>
                                </div>
                                <div class="form-check cus" *ngIf="outstandingRequests.return">
                                    <input class="form-check-input cus" type="checkbox" id="return" name="return"
                                        checked="checked" disabled>
                                    <label class="form-check-label cus mb-1 ms-1" for="return">
                                        Return
                                    </label>
                                </div>
                                <div class="form-check cus" *ngIf="!outstandingRequests.return">
                                    <input class="form-check-input cus" type="checkbox" id="return" name="return"
                                        disabled>
                                    <label class="form-check-label cus mb-1 ms-1" for="return">
                                        Return
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="outstandingRequests.going && outstandingRequests.return">
                        <div class="mb-3 col-md-6">
                            <label for="date" class="form-label">Arrive Time</label>
                            <input type="text" class="form-control" id="arrivetime" name="arrivetime"
                                [(ngModel)]="outstandingRequests.arrivetime" disabled>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="date" class="form-label">Return Time</label>
                            <input type="text" class="form-control" id="returntime" name="returntime"
                                [(ngModel)]="outstandingRequests.returntime" disabled>
                        </div>
                    </div>
                    <div class="row" *ngIf="!outstandingRequests.going || !outstandingRequests.return">
                        <div class="mb-3 col-md-12" *ngIf="outstandingRequests.going">
                            <label for="date" class="form-label">Arrive Time</label>
                            <input type="text" class="form-control" id="arrivetime" name="arrivetime"
                                [(ngModel)]="outstandingRequests.arrivetime" disabled>
                        </div>
                        <div class="mb-3 col-md-12" *ngIf="outstandingRequests.return">
                            <label for="date" class="form-label">Return Time</label>
                            <input type="text" class="form-control" id="returntime" name="returntime"
                                [(ngModel)]="outstandingRequests.returntime" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label for="date" class="form-label">Pick Up Place</label>
                            <input type="text" class="form-control" id="date" name="date"
                                [(ngModel)]="outstandingRequests.userfrom" disabled>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="status" class="form-label">Drop Off Place</label>
                            <input type="text" class="form-control" id="status" name="status"
                                [(ngModel)]="outstandingRequests.userto" disabled>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="mb-3 col-md-12">
                        <label for="remark" class="form-label">Remark</label>
                        <textarea type="text" style="height: 100px;" class="form-control" id="remark" name="remark"
                            [(ngModel)]="outstandingRequests.remark" disabled></textarea>
                    </div>
                </div>
                <!-- <div class="col-md-12">
                  <h3> Members  ({{ memberList.length }})</h3>
                  <div class="row">

                    <ng-container *ngFor="let user of memberList">
                      <div class="col-md-4">
                        <div class="card p-3 my-card">
                          <div class="d-flex align-items-center">
                            <span>
                              <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
                                [lazyLoad]="
                                  user.photo || user.image || '../../../assets/images/profile.png'
                                " />
                            </span>

                            <label class="d-flex align-items-center justify-content-between w-100">
                              <div class="profile-item-container">
                                <div class="profile-item">
                                  <div class="username" style="cursor: pointer">
                                    <b>{{ user.username || user.userid}}</b>
                                  </div>
                                  <div style="color: grey; margin-top: 1px; font-size: 13.5px" *ngIf="user.username != ''">
                                    {{ user.userid }}
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6">
                              <input class="form-control" type="text" [id]="'from-'+user.userid"
                                [name]="'from-'+user.userid" [(ngModel)]="user.fromplace" placeholder="Pick Up Place" disabled/>
                            </div>
                            <div class="col-md-6">
                              <input class="form-control" type="text" [id]="'to-'+user.userid" [name]="'to-'+user.userid"
                                [(ngModel)]="user.toplace" placeholder="Drop Off Place" disabled/>
                            </div>
                          </div>
                        </div>
                      </div>


                    </ng-container>

                  </div>
                </div> -->
            </div>
        </div>
    </div>
</div>