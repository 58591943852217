<div *ngIf="!showTickets" class="fluikd-container">
  <div class="content">
    <!-- bread-crumb -->
    <div class="me-auto">
      <app-cs365-breadcrumb [s_breadcrumb]="'Service Warranty'"></app-cs365-breadcrumb>
    </div>
    <!-- bread-crumb -->

    <!-- Header-->
    <div class="d-flex gap-2 my-4">
      <app-dropdown-button [(ngModel)]="selectedCustomer" name="Customer" [items]="customers" bindLabel="customername"
        bindValue="customerid" (ngModelChange)="getServiceWarranty()"></app-dropdown-button>
      <app-dropdown-button [(ngModel)]="selectedProduct" name="Product" [items]="products" bindLabel="name"
        bindValue="productid" (ngModelChange)="getServiceWarranty()"></app-dropdown-button>
      <button class="btn btnGray" *ngIf="showdate" (click)="openAdvacedSearch()">
        <span>{{ showdate }}</span>
        <i class="fa fa-close ms-4" (click)="clearDate()"></i>
      </button>
      <button class="btn btn-custom" (click)="openAdvacedSearch()">
        <i class="fa fa-filter"></i>
      </button>
      <button class="btn btn-custom" (click)="getServiceWarranty()">
        Search
      </button>
    </div>

    <!--Table-->
    <div class="card card-body my-card">
      <div class="table-responsive">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td>Product</td>
              <td>Customer</td>
              <td>Start Date</td>
              <td>End Date</td>
              <td>Warranty Month</td>
              <td>Remark</td>
            </tr>
          </thead>
          <tbody *ngIf="isloading">
            <tr>
              <td colspan="7" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isloading">
            <tr *ngIf="serviceWarranties.length == 0">
              <td colspan="7" class="nodata">Empty</td>
            </tr>
            <tr *ngFor="let serviceWarranty of serviceWarranties" (click)="handleShowTickets(serviceWarranty)"
              style="cursor: pointer">
              <td>{{ serviceWarranty.product.name }}</td>
              <td>{{ serviceWarranty.CRMCustomer.name }}</td>
              <td>{{ serviceWarranty.startdatetime | date : "dd/MM/YYYY" }}</td>
              <td>
                {{ serviceWarranty.warrantyenddate }}
              </td>
              <td>{{ serviceWarranty.product.CRMWarranty.month }}</td>
              <td>{{ serviceWarranty.remark }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--Table-->
  </div>
</div>

<ng-container *ngIf="showTickets">
  <app-warranty-tickets [warranty]="showData" (closeService)="closeShowTickets()"></app-warranty-tickets>
</ng-container>