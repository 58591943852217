<div class='container-fluid'>
  <div class="content">

    <!-- <ng-container *ngIf="isAdmin">
      <app-team-breadcrumb *ngIf="!addHoliday" s_breadcrumb="Holiday">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="addHoliday" s_breadcrumb="Holiday"
        [t_breadcrumb]="isEditing ? 'Edit Holiday' : 'New Holiday'" (emit)="cancel()">
      </app-team-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb *ngIf="!addHoliday" [s_breadcrumb]="'Holiday'">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="addHoliday" [s_breadcrumb]="'Holiday'"
        [t_breadcrumb]="isEditing ? 'Edit Holiday' : 'New Holiday'" (emit)="cancel()">
      </app-office-breadcrumb>
    </ng-container> -->

    <ng-container *ngIf="allinoneService.isAttendanceView">
      <app-hcm-breadcrumb *ngIf="!addHoliday" s_breadcrumb="Holiday">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="addHoliday" s_breadcrumb="Holiday"
        [t_breadcrumb]="isEditing ? 'Edit Holiday' : 'New Holiday'" (emit)="cancel()">
      </app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!allinoneService.isAttendanceView">
    <app-team-breadcrumb *ngIf="!addHoliday" s_breadcrumb="Holiday">
    </app-team-breadcrumb>
    <app-team-breadcrumb *ngIf="addHoliday" s_breadcrumb="Holiday"
      [t_breadcrumb]="isEditing ? 'Edit Holiday' : 'New Holiday'" (emit)="cancel()">
    </app-team-breadcrumb>
  </ng-container>

    <ng-container *ngIf="!addHoliday">
      <div class="d-flex justify-content-between">
        <div class="mb-3">
          <button class="btn btn-custom me-auto" (click)="newHoliday()">
            <i class="fa fa-plus me-1"></i> New
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="addHoliday">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!isLoading">
          Cancel
        </button>
        <app-button-loader class="mb-3" (click)="submitHoliday()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="isLoading">
        </app-button-loader>

      </div>
      <div class="row">
        <div class="mb-3 col-md-6">
          <label for="holiday" class="form-label">Holiday</label>
          <input class="form-control" id="holiday" [(ngModel)]="holidayForm.holiday" name="holiday" #holiday="ngModel"
            [ngClass]="{ 'is-invalid': formSubmitted && holiday.invalid }" required />
          <div class="invalid-feedback" *ngIf="
          holiday.invalid ||
              ((holiday.dirty || holiday.touched) && formSubmitted)
            ">
            <div *ngIf="holiday.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <label for="oildate" class="form-label">Oil Date</label>
          <div class="input-group">
            <!-- <input type="date" class="form-control" id="oildate" name="oildate" [(ngModel)]="holidayForm.oildate" max="9999-12-31"> -->
            <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
              [bsConfig]="generalDatePickerConfig" readonly name="oildate" [(ngModel)]="holidayForm.oildate"
              style="min-width: 200px;" required #oildate="ngModel">
            <button class="btn btn-custom" type="button" id="button-addon2" *ngIf="holidayForm.oildate"
              (click)="removeOildate()"><i class="fa fa-times" aria-hidden="true"></i></button>
          </div>
        </div>


        <div class="mb-3 col-md-6">
          <label for="startdate" class="form-label">Start Date</label>
          <!-- <input class="form-control" type="date" id="startdate" [(ngModel)]="holidayForm.startdate" name="startdate" max="9999-12-31" /> -->
          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="holidayForm.startdate" style="min-width: 200px;"
            required #startdate="ngModel">
        </div>
        <div class="mb-3 col-md-6">
          <label for="enddate" class="form-label">End Date</label>
          <!-- <input class="form-control" type="date" id="enddate" [(ngModel)]="holidayForm.enddate" name="enddate" max="9999-12-31" /> -->
          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="holidayForm.enddate" style="min-width: 200px;"
            required #enddate="ngModel">
        </div>
        <!-- <div class="mb-3 col-md-12 d-flex align-items-center flex-wrap">
          <div class="form-check me-5">
            <input class="form-check-input" type="radio" name="status" id="active" [checked]="holidayForm.active"
              (change)="activeStatusChange()">
            <label class="form-check-label" for="active">
              Active
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="status" id="inactive" [checked]="!holidayForm.active"
              (change)="activeStatusChange()">
            <label class="form-check-label" for="inactive">
              Inactive
            </label>
          </div>
        </div> -->
      </div>

    </ng-container>

    <div *ngIf="!addHoliday">
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center ">
        <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by year" aria-label="Search by year"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div class="line "></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{holidayList.length}}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by year" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>


        <pagination-controls id="rule" class="pagination upper-paginate ms-auto" (pageChange)="currentPage = $event"
          *ngIf="holidayList.length > 0 " previousLabel="Prev" nextLabel="Next">
        </pagination-controls>

        <!-- <pagination-holiday class="ms-auto" *ngIf="holidayList.length > 0" #p="paginationApi"
          (pageChange)="pageChange($event)">
          <div class="d-flex align-items-center justify-content-center">
            <div [class]="
                    p.isFirstPage()
                      ? 'custom-pagination-disabled'
                      : 'custom-pagination'
                  " (click)="p.previous()">
              <span class="me-1">&laquo;</span>&nbsp;Prev
            </div>
            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </span>
              <div class="active-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div [class]="
                    nextDisabled
                      ? 'custom-pagination-disabled'
                      : 'custom-pagination'
                  " (click)="p.next()">
              Next&nbsp;<span class="ms-1">&raquo;</span>
            </div>
          </div>
        </pagination-holiday> -->

      </div>

      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="holiday">Holiday</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="startdate">Start Date</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="enddate">End Date</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="oildate">Oil Date</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>

          <tbody *ngIf="holidayListLoading">
            <tr>
              <td colspan="5" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!holidayListLoading">
            <tr *ngIf="holidayList.length == 0">
              <td colspan="5" class="nodata">Empty</td>
            </tr>

            <tr
              *ngFor="let holiday of holidayList | filter : searchText:'holidayList' | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, id : 'rule' }; let index = index">
              <td class="td-data td-edit" (click)="editHoliday(holiday)">
                <span [title]="holiday.year">{{holiday.name}}</span>
              </td>

              <td class="td-data">
                <span
                  [title]='allinoneService.formatDBToShow(holiday.startdate)'>{{allinoneService.formatDBToShow(holiday.startdate)}}</span>
              </td>
              <td class="td-data">
                <span
                  [title]='allinoneService.formatDBToShow(holiday.enddate)'>{{allinoneService.formatDBToShow(holiday.enddate)}}</span>
              </td>

              <td class="td-data">
                <span
                  [title]='holiday.oildate ? allinoneService.formatDBToShow(holiday.oildate) : ""'>{{holiday.oildate?
                  allinoneService.formatDBToShow(holiday.oildate) : '-'}}</span>
              </td>



              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2" (click)="editHoliday(holiday)">
                  <i class='fa fa-edit'></i>
                </button>
                <button class="btn btn-outline-danger" (click)="deleteHoliday(holiday.holidayid)">
                  <i class='fa fa-trash-o'></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>