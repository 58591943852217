<div class="container-fluid">
  <div class="content">
    <!-- <ng-container *ngIf="!isHCM">
      <app-breadcrumb *ngIf="isFromOrg" [s_breadcrumb]="'Organizations'" [t_breadcrumb]="org_name"
        [f_breadcrumb]="'Members'" [fi_breadcrumb]="memberAddorNot ? isEditing ? editBreadcrumbName :  'Add Member': ''"
        (emit)="goToOrg()" (emit1)="goToOrgEdit()" (emit2)="cancel()">
      </app-breadcrumb>
      <app-breadcrumb *ngIf="!isFromOrg" [s_breadcrumb]="breadcrumbName +'s'"
        [t_breadcrumb]="memberAddorNot ? isEditing? editBreadcrumbName :  'Add ' + breadcrumbName: ''"
        (emit)="cancel()">
      </app-breadcrumb>
    </ng-container>

    <ng-container *ngIf="isHCM">
      <app-hcm-breadcrumb *ngIf="!fromRequisition" [s_breadcrumb]="breadcrumbName +'s'"
        [t_breadcrumb]="memberAddorNot ? isEditing? editBreadcrumbName:  'Add ' + breadcrumbName: ''" (emit)="cancel()">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="fromRequisition && memberAddorNot" s_breadcrumb="Recruitment"
        t_breadcrumb="Requisitions" [f_breadcrumb]="breadcrumbName+'s'" [fi_breadcrumb]="searchUserID"
        (emit)="gotoRequisition(true)" (emit1)="gotoRequisition(false)" (emit2)="cancel()">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="fromRequisition && !memberAddorNot" s_breadcrumb="Recruitment"
        t_breadcrumb="Requisitions" [f_breadcrumb]="breadcrumbName+'s'" (emit)="gotoRequisition(true)"
        (emit1)="gotoRequisition(false)">
      </app-hcm-breadcrumb>
    </ng-container> -->

    <ng-container *ngIf="allinoneService.isAttendanceView && !isFromOrg">
      <app-hcm-breadcrumb
        *ngIf="!fromRequisition"
        [s_breadcrumb]="breadcrumbName + 's'"
        [t_breadcrumb]="
          memberAddorNot
            ? isEditing
              ? editBreadcrumbName
              : 'Add ' + breadcrumbName
            : ''
        "
        (emit)="cancel()"
      >
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb
        *ngIf="fromRequisition && memberAddorNot"
        s_breadcrumb="Recruitment"
        t_breadcrumb="Requisitions"
        [f_breadcrumb]="breadcrumbName + 's'"
        [fi_breadcrumb]="searchUserID"
        (emit)="gotoRequisition(true)"
        (emit1)="gotoRequisition(false)"
        (emit2)="cancel()"
      >
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb
        *ngIf="fromRequisition && !memberAddorNot"
        s_breadcrumb="Recruitment"
        t_breadcrumb="Requisitions"
        [f_breadcrumb]="breadcrumbName + 's'"
        (emit)="gotoRequisition(true)"
        (emit1)="gotoRequisition(false)"
      >
      </app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!allinoneService.isAttendanceView && !isFromOrg">
      <app-team-breadcrumb
        *ngIf="!fromRequisition"
        [s_breadcrumb]="breadcrumbName + 's'"
        [t_breadcrumb]="
          memberAddorNot
            ? isEditing
              ? editBreadcrumbName
              : 'Add ' + breadcrumbName
            : ''
        "
        (emit)="cancel()"
      >
      </app-team-breadcrumb>
      <app-team-breadcrumb
        *ngIf="fromRequisition && memberAddorNot"
        s_breadcrumb="Recruitment"
        t_breadcrumb="Requisitions"
        [f_breadcrumb]="breadcrumbName + 's'"
        [fi_breadcrumb]="searchUserID"
        (emit)="gotoRequisition(true)"
        (emit1)="gotoRequisition(false)"
        (emit2)="cancel()"
      >
      </app-team-breadcrumb>
      <app-team-breadcrumb
        *ngIf="fromRequisition && !memberAddorNot"
        s_breadcrumb="Recruitment"
        t_breadcrumb="Requisitions"
        [f_breadcrumb]="breadcrumbName + 's'"
        (emit)="gotoRequisition(true)"
        (emit1)="gotoRequisition(false)"
      >
      </app-team-breadcrumb>
    </ng-container>

    <ng-container *ngIf="isFromOrg">
      <app-team-breadcrumb
        *ngIf="isFromOrg"
        [s_breadcrumb]="'Organizations'"
        [t_breadcrumb]="org_name"
        [f_breadcrumb]="'Members'"
        [fi_breadcrumb]="
          memberAddorNot ? (isEditing ? editBreadcrumbName : 'Add Member') : ''
        "
        (emit)="goToOrg()"
        (emit1)="goToOrgEdit()"
        (emit2)="cancel()"
      >
      </app-team-breadcrumb>
      <app-team-breadcrumb
        *ngIf="!isFromOrg"
        [s_breadcrumb]="breadcrumbName + 's'"
        [t_breadcrumb]="
          memberAddorNot
            ? isEditing
              ? editBreadcrumbName
              : 'Add ' + breadcrumbName
            : ''
        "
        (emit)="cancel()"
      >
      </app-team-breadcrumb>
    </ng-container>

    <div class="row">
      <div class="col-md-6">
        <div class="d-flex header flex-wrap">
          <div class="mb-3">
            <button
              *ngIf="!memberAddorNot"
              type="button"
              class="btn btn-custom me-2"
              (click)="memberAdd()"
            >
              <i class="fa fa-plus me-1"></i>Add
            </button>
            <div
              *ngIf="memberAddorNot && !memberListLoading"
              class="close-save-button"
            >
              <button
                type="button"
                class="btn btn-custom me-2"
                (click)="cancel()"
                *ngIf="!saveLoading"
              >
                Cancel
              </button>
              <ng-container *ngIf="!memberForm.inactive">
                <app-button-loader
                  class="me-2"
                  *ngIf="allok || isUserValid"
                  (click)="submitMember()"
                  [button_text]="'Save'"
                  [load_button_text]="'Saving'"
                  [isLoading]="saveLoading"
                >
                </app-button-loader>
                <button
                  *ngIf="!allok && !isUserValid"
                  class="btn btn-custom me-2"
                  type="button"
                  disabled
                >
                  Save
                </button>
              </ng-container>
            </div>
          </div>
          <div class="mb-3">
            <button
              type="button"
              class="btn btn-custom me-2"
              (click)="openClassCodeForm()"
              title="{{ membershiptype == '003' ? 'Member Type' : 'Post' }}"
            >
              {{ membershiptype == "003" ? "Member Type" : "Post" }}
            </button>
          </div>
          <div class="mb-3 me-2" *ngIf="!isAttendanceView">
            <button
              type="button"
              class="btn btn-custom mb-2"
              (click)="addRole()"
            >
              Role Access
            </button>
          </div>
          <div class="mb-3">
            <button
              type="button"
              class="btn btn-custom me-2"
              (click)="openPayLevelForm()"
              title="Pay Level"
            >
              Pay Level
            </button>
          </div>
          <div class="mb-3" *ngIf="isEditing && memberAddorNot">
            <button
              type="button"
              class="btn btn-custom mb-2"
              (click)="openPositionHistoryForm()"
            >
              Position Control
            </button>
          </div>
          <div *ngIf="!memberAddorNot && showAysncFromNeo" class="mb-3">
            <app-button-loader
              class=""
              (click)="syncFromNeoPayroll()"
              [button_text]="'Sync from NeoPayroll'"
              [load_button_text]="'Checking'"
              [isLoading]="syncMemberInfoLoading"
            >
            </app-button-loader>
          </div>
        </div>
      </div>

      <div class="mb-3 col-md-6">
        <div
          class="row g-0 col-md-12 col-sm-12"
          *ngIf="!memberAddorNot && !isBulkUpload"
        >
          <label
            for="orgemail"
            class="col-form-label ps-0"
            [ngClass]="{ 'custom-label': !isMobileView }"
          >
            Organization Email</label
          >
          <div class="input-group {{ isMobileView ? 'w-100' : 'w-75' }}">
            <input
              type="text"
              class="form-control"
              id="orgemail"
              name="orgemail"
              [(ngModel)]="tagEmail"
              placeholder="Email domain"
              [disabled]="!tagmailediting"
            />
            <button
              class="btn btn-custom input-group-text"
              *ngIf="!tagmailediting && !syncLoading"
              (click)="tagMailEdit()"
            >
              <i class="fa fa-edit"></i>
            </button>
            <button
              class="btn btn-custom input-group-text"
              style="border-left: 1px solid white"
              (click)="syncTagEmail()"
              *ngIf="!syncLoading"
            >
              <i class="fas fa-sync-alt"></i>
            </button>
            <button
              class="btn btn-custom input-group-text"
              style="border-left: 1px solid white"
              (click)="cancelEdit()"
              *ngIf="
                tagmailediting && tagEmail != '' && !firstTime && !syncLoading
              "
            >
              <i class="fa fa-close"></i>
            </button>
            <button
              class="btn btn-custom input-group-text"
              style="border-left: 1px solid white"
              disabled
              *ngIf="syncLoading"
            >
              <span
                class="spinner-border spinner-border-sm button-spinner"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="memberAddorNot && !isBulkUpload">
      <ng-container *ngIf="memberListLoading; else formTemplate">
        <div class="empty requisition-empty">
          <div class="spinner-border" entitle="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </ng-container>
      <ng-template #formTemplate>
        <div class="card card-body my-card">
          <div class="info">{{ breadcrumbName }} Info</div>
          <div class="servcie">
            <div class="row">
              <div class="col-lg-10 col-md-8">
                <div class="row">
                  <div
                    class="{{
                      isEditing && !isFromOrg && memberForm.newuserid != ''
                        ? 'mb-3 col-md-4'
                        : 'mb-3 col-md-6'
                    }}"
                  >
                    <label for="userid" class="form-label">User ID </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="userid"
                        name="userid"
                        placeholder="john@domain.com, +959********"
                        [(ngModel)]="memberForm.userid"
                        required
                        #userid="ngModel"
                        [ngClass]="{
                          'is-invalid': memberformSubmitted && userid.invalid
                        }"
                        [disabled]="
                          (isEditing && !isFromOrg) || memberForm.inactive
                        "
                      />
                    </div>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        userid.invalid ||
                        ((userid.dirty || userid.touched) &&
                          memberformSubmitted)
                      "
                    >
                      <div *ngIf="userid.errors?.required">
                        {{ allinoneService.input_err_msg }}
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="
                      isEditing && !isFromOrg && memberForm.newuserid != ''
                    "
                    class="{{
                      isEditing && !isFromOrg && memberForm.newuserid != ''
                        ? 'mb-3 col-md-4'
                        : 'mb-3 col-md-6'
                    }}"
                  >
                    <label for="newuserid" class="form-label"
                      >New User ID
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="newuserid"
                        name="newuserid"
                        [(ngModel)]="memberForm.newuserid"
                        disabled
                      />
                    </div>
                  </div>

                  <div
                    class="{{
                      isEditing && !isFromOrg && memberForm.newuserid != ''
                        ? 'mb-3 col-md-4'
                        : 'mb-3 col-md-6'
                    }}"
                  >
                    <label for="name" class="form-label"> Name </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter Name"
                      [(ngModel)]="memberForm.name"
                      [disabled]="memberForm.inactive"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="d-flex flex-wrap mb-2 me-5">
                    <div class="form-check me-5" *ngIf="isUserValid">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="remember"
                        [(ngModel)]="isAdmin"
                        (change)="adminContriChanged()"
                        name="_remember"
                        [disabled]="
                          isAdmin &&
                          memberForm.userid ==
                            allinoneService.getKunyekUserId() &&
                          isEditing
                        "
                      />
                      <label class="form-check-label mb-1 ms-1" for="remember">
                        Admin
                      </label>
                    </div>
                    <div class="form-check me-5">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="hr​admin"
                        name="hr​admin"
                        [(ngModel)]="memberForm.hradmin"
                        [disabled]="memberForm.inactive"
                      />
                      <!-- [disabled]="(isAdmin && memberForm.userid == allinoneService.getKunyekUserId() && isEditing)" -->
                      <label class="form-check-label mb-1 ms-1" for="hr​admin">
                        HR Admin
                      </label>
                    </div>
                    <ng-container *ngIf="!isAttendanceView">
                      <div *ngIf="showtransportation" class="form-check me-5">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="transportationadmin"
                          name="transportationadmin"
                          [(ngModel)]="memberForm.transportationadmin"
                          [disabled]="memberForm.inactive"
                        />
                        <!-- [disabled]="(isAdmin && memberForm.userid == allinoneService.getKunyekUserId() && isEditing)" -->
                        <label
                          class="form-check-label mb-1 ms-1"
                          for="transportationadmin"
                        >
                          Transportation Admin
                        </label>
                      </div>
                      <div class="form-check me-5">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="contributor"
                          (change)="adminContriChanged()"
                          [(ngModel)]="memberForm.contributor"
                          name="contributor"
                          [disabled]="memberForm.inactive"
                        />
                        <label
                          class="form-check-label mb-1 ms-1"
                          for="contributor"
                        >
                          Contributor
                        </label>
                      </div>

                      <div class="form-check me-5">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="postadmin"
                          (change)="adminContriChanged()"
                          [(ngModel)]="memberForm.postadmin"
                          name="postadmin"
                          [disabled]="memberForm.inactive"
                        />
                        <label
                          class="form-check-label mb-1 ms-1"
                          for="postadmin"
                        >
                          Post Admin
                        </label>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="role"
                          (change)="roleChange($event)"
                          [(ngModel)]="isRoleSelected"
                          name="role"
                          [disabled]="memberForm.inactive"
                        />
                        <label class="form-check-label mb-1 ms-1" for="role">
                          Role Access
                        </label>
                      </div>
                    </ng-container>
                  </div>

                  <div *ngIf="isRoleSelected && !isAttendanceView" class="mb-3">
                    <label class="form-label cc-label" (click)="addRole()">
                      Role Access
                    </label>

                    <ng-select
                      [ngClass]="{
                        'role-select-invalid':
                          memberForm.roleList.length == 0 &&
                          isRoleSelected &&
                          memberformSubmitted
                      }"
                      class="role-select"
                      [items]="roleList"
                      bindLabel="name"
                      placeholder="Select Role"
                      appendTo="body"
                      [multiple]="true"
                      [(ngModel)]="memberForm.roleList"
                      [disabled]="memberForm.inactive"
                    >
                    </ng-select>

                    <div
                      class="invalid-ngselect"
                      *ngIf="
                        memberForm.roleList.length == 0 &&
                        isRoleSelected &&
                        memberformSubmitted
                      "
                    >
                      <div>
                        {{ allinoneService.input_err_msg }}
                      </div>
                    </div>

                    <mat-progress-bar
                      mode="indeterminate"
                      *ngIf="roleListLoading"
                    ></mat-progress-bar>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-md-6">
                    <label for="birth" class="form-label">
                      Date of Birth
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control datepicker-readonly"
                        placeholder="dd/mm/yyyy"
                        bsDatepicker
                        [bsConfig]="generalDatePickerConfig"
                        [bsValue]="memberForm.dateofbirth"
                        [(ngModel)]="memberForm.dateofbirth"
                        [bsConfig]="{ showClearButton: true }"
                        [disabled]="memberForm.inactive"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="mb-3 col-md-6">
                    <label for="gender" class="form-label"> Gender </label>
                    <select
                      class="form-select"
                      id="gender"
                      name="gender"
                      [(ngModel)]="memberForm.gender"
                      [disabled]="memberForm.inactive"
                    >
                      <option value="">-</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-md-6">
                    <label for="birth" class="form-label"> NRC </label>
                    <div class="row g-2">
                      <div class="col-2">
                        <select
                          class="form-select"
                          id="nrcregioncode"
                          name="nrcregioncode"
                          [(ngModel)]="memberForm.nrcregioncode"
                          #nrcregioncode="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              memberformSubmitted &&
                              nrcRequired &&
                              nrcregioncode.invalid
                          }"
                          required
                          (ngModelChange)="nrcChange()"
                          [disabled]="memberForm.inactive"
                        >
                          <option value="">-</option>
                          <option
                            *ngFor="let item of nrcRegionCode"
                            [value]="item"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </div>
                      <div class="col-4">
                        <select
                          class="form-select"
                          id="nrctownshipcode"
                          name="nrctownshipcode"
                          [(ngModel)]="memberForm.nrctownshipcode"
                          #nrctownshipcode="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              memberformSubmitted &&
                              nrcRequired &&
                              nrctownshipcode.invalid
                          }"
                          required
                          (ngModelChange)="nrcChange()"
                          [disabled]="memberForm.inactive"
                        >
                          <option
                            *ngFor="let item of nrcTownshipCode"
                            [value]="item == '-' ? '' : item"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </div>
                      <div class="col-2">
                        <select
                          class="form-select"
                          id="nrctype"
                          name="nrctype"
                          [(ngModel)]="memberForm.nrctype"
                          #nrctype="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              memberformSubmitted &&
                              nrcRequired &&
                              nrctype.invalid
                          }"
                          required
                          (ngModelChange)="nrcChange()"
                          [disabled]="memberForm.inactive"
                        >
                          <option
                            *ngFor="let item of nrcType"
                            [value]="item == '-' ? '' : item"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </div>
                      <div class="col-4">
                        <input
                          class="form-control"
                          id="nrcnumber"
                          name="nrcnumber"
                          [(ngModel)]="memberForm.nrcnumber"
                          minlength="6"
                          #nrcnumber="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              memberformSubmitted &&
                              nrcRequired &&
                              nrcnumber.invalid
                          }"
                          required
                          (ngModelChange)="nrcChange()"
                          (keypress)="
                            ($event.charCode >= 48 && $event.charCode < 58)
                          "
                          [disabled]="memberForm.inactive"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="mb-3 col-md-6">
                    <label for="maritalstatus" class="form-label">
                      Marital Status
                    </label>
                    <select
                      class="form-select"
                      id="maritalstatus"
                      name="maritalstatus"
                      [(ngModel)]="memberForm.martialstatus"
                      [disabled]="memberForm.inactive"
                    >
                      <option value="">-</option>
                      <option
                        *ngFor="let item of martialStatus"
                        [value]="item.code"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="mobile" class="form-label"> Mobile </label>
                    <input
                      class="form-control"
                      type="text"
                      id="mobile"
                      name="mobile"
                      [(ngModel)]="memberForm.mobile"
                      [disabled]="memberForm.inactive"
                      (keypress)="
                        ($event.charCode >= 48 && $event.charCode < 58)
                      "
                      maxlength="15"
                      placeholder="+959*********"
                    />
                  </div>
                </div>
              </div>

              <div
                class="col-lg-2 col-md-4 d-flex justify-content-md-center justify-content-lg-center"
              >
                <div class="profile-img-container">
                  <div style="position: relative">
                    <div
                      class="uploadfilecontainer"
                      (click)="fileInput.click()"
                      appDragDrop
                      (onFileDropped)="uploadFile($event)"
                    >
                      <img
                        class="drag-image me-3"
                        [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="
                          gettingProfileImage
                            ? '../../../assets/images/bg.jpg'
                            : memberForm.imgURL
                            ? memberForm.imgURL
                            : '../../../assets/images/uploadphoto.png'
                        "
                      />

                      <input
                        hidden
                        type="file"
                        #fileInput
                        accept="image/*"
                        (change)="uploadFile1($event)"
                      />
                    </div>
                    <!-- <button mat-icon-button class="remove-drag-image" *ngIf="memberForm.imgURL" (click)="removeImage()">
                      <mat-icon>remove_circle</mat-icon>
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="profile-img-container">
              <div style="position: relative;">
                <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                (onFileDropped)="uploadFile($event)">
                <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                    [lazyLoad]="memberForm.imgURL ? memberForm.imgURL : '../../../assets/images/uploadphoto.png'">
  
                <input hidden type="file" #fileInput accept='image/*' (change)="uploadFile1($event)">
              </div>
                  <button mat-icon-button class="remove-drag-image" *ngIf="memberForm.imgURL" (click)="removeImage()">
                      <mat-icon>remove_circle</mat-icon>
                  </button>
              </div>
              
            </div> -->
          </div>
        </div>

        <br />

        <div class="card card-body my-card">
          <!-- <div class="info">Employment</div> -->
          <div class="servcie">
            <div class="row">
              <div
                class="mb-3 col-md-6"
                *ngIf="memberAddorNot && !isBulkUpload"
              >
                <label
                  for="type"
                  class="form-label cc-label"
                  (click)="openClassCodeForm()"
                >
                  {{ membershiptype == "003" ? "Member Type" : "Post" }}
                </label>
                <select
                  class="form-select"
                  id="type"
                  name="type"
                  (change)="classCodeChange($event)"
                  [(ngModel)]="memberForm.typeid"
                  required
                  #type="ngModel"
                  [ngClass]="{
                    'is-invalid': memberformSubmitted && type.invalid
                  }"
                  [disabled]="
                    gettingCcList || hasPositionControl || memberForm.inactive
                  "
                >
                  <option value="" [selected]="memberForm.typeid == ''">
                    Select
                    {{ membershiptype == "003" ? "Member Type" : "Post" }}
                  </option>
                  <option
                    *ngFor="let cc of ccList; trackBy: trackById"
                    value="{{ cc.id }}"
                    [selected]="cc.id == memberForm.typeid"
                  >
                    {{ cc.name }}
                  </option>
                </select>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingCcList"
                ></mat-progress-bar>

                <div
                  class="invalid-feedback"
                  *ngIf="
                    type.invalid ||
                    ((type.dirty || type.touched) && memberformSubmitted)
                  "
                >
                  <div *ngIf="type.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3 col-md-6">
                <label for="eid" class="form-label">
                  <!-- <span *ngIf="memberForm.profiletype == 'Employment' || memberForm.profiletype == ''">Employee
                    ID</span>
                  <span *ngIf="memberForm.profiletype == 'Membership'">Member ID</span>
                  <span *ngIf="memberForm.profiletype == 'Loyalty'">Customer ID</span> -->
                  Employee ID
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="eid"
                  name="eid"
                  placeholder="Enter ID"
                  [(ngModel)]="memberForm.eid"
                  #eid="ngModel"
                  [required]="isMemberID"
                  [disabled]="isEditing"
                  [ngClass]="{
                    'is-invalid': memberformSubmitted && eid.invalid
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    eid.invalid ||
                    ((eid.dirty || eid.touched) && memberformSubmitted)
                  "
                >
                  <div *ngIf="eid.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
              </div>

              <!-- <div class="mb-3 col-md-4">
                <label for="post" class="form-label">
                  Branch
                </label>
                <input type="text" class="form-control" id="post" name="post" placeholder="Enter Branch"
                  [(ngModel)]="memberForm.post" [disabled]="hasPositionControl" required #branch="ngModel"
                  [ngClass]="{ 'is-invalid': memberformSubmitted && branch.invalid && needToFillPositionControl }">
  
                <div class="invalid-feedback"
                  *ngIf="branch.invalid || (branch.dirty || branch.touched) && memberformSubmitted && needToFillPositionControl">
                  <div *ngIf="branch.errors?.required">
                    {{allinoneService.input_err_msg}}
                  </div>
                </div>
              </div> -->

              <div class="mb-3 col-md-6">
                <label for="branch" class="form-label"> Branch </label>
                <input
                  type="text"
                  class="form-control"
                  id="branch"
                  name="branch"
                  placeholder="Enter Branch"
                  [(ngModel)]="memberForm.post"
                  [matAutocomplete]="branchAutoComplete"
                  #branch="ngModel"
                  [disabled]="
                    hasPositionControl ||
                    gettingMemberPosition ||
                    memberForm.inactive
                  "
                  [ngClass]="{
                    'is-invalid':
                      memberformSubmitted &&
                      branch.invalid &&
                      needToFillPositionControl
                  }"
                  required
                />
                <mat-autocomplete #branchAutoComplete="matAutocomplete">
                  <ng-container
                    *ngFor="
                      let branch of branchList
                        | filter
                          : memberForm.post
                          : 'memberDepartmentAutocomplete'
                    "
                  >
                    <mat-option [value]="branch">
                      {{ branch }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingMemberPosition"
                ></mat-progress-bar>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    branch.invalid ||
                    ((branch.dirty || branch.touched) &&
                      memberformSubmitted &&
                      needToFillPositionControl)
                  "
                >
                  <div *ngIf="branch.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
              </div>

              <div class="mb-3 col-md-6">
                <label for="department" class="form-label"> Department </label>
                <input
                  type="text"
                  class="form-control"
                  id="department"
                  name="department"
                  placeholder="Enter Department"
                  [(ngModel)]="memberForm.department"
                  [matAutocomplete]="departmentAutocomplele"
                  [disabled]="
                    hasPositionControl ||
                    gettingMemberPosition ||
                    memberForm.inactive
                  "
                  required
                  #department="ngModel"
                  [ngClass]="{
                    'is-invalid':
                      memberformSubmitted &&
                      department.invalid &&
                      needToFillPositionControl
                  }"
                />
                <mat-autocomplete #departmentAutocomplele="matAutocomplete">
                  <ng-container
                    *ngFor="
                      let dep of departmentList
                        | filter
                          : memberForm.department
                          : 'memberDepartmentAutocomplete'
                    "
                  >
                    <mat-option [value]="dep" *ngIf="dep != 'All'">
                      {{ dep }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingMemberPosition"
                ></mat-progress-bar>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    department.invalid ||
                    ((department.dirty || department.touched) &&
                      memberformSubmitted &&
                      needToFillPositionControl)
                  "
                >
                  <div *ngIf="department.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
              </div>

              <div class="mb-3 col-md-6">
                <label for="division" class="form-label"> Division </label>
                <input
                  type="text"
                  class="form-control"
                  id="division"
                  name="division"
                  placeholder="Enter Division"
                  [(ngModel)]="memberForm.division"
                  [matAutocomplete]="divisionAutoComplete"
                  [disabled]="
                    hasPositionControl ||
                    gettingMemberPosition ||
                    memberForm.inactive
                  "
                />
                <mat-autocomplete #divisionAutoComplete="matAutocomplete">
                  <ng-container
                    *ngFor="
                      let division of divisionList
                        | filter
                          : memberForm.division
                          : 'memberDepartmentAutocomplete'
                    "
                  >
                    <mat-option [value]="division" *ngIf="division != 'All'">
                      {{ division }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingMemberPosition"
                ></mat-progress-bar>
              </div>

              <div class="mb-3 col-md-6">
                <label for="teamid" class="form-label"> Team ID </label>
                <input
                  type="text"
                  class="form-control"
                  id="teamid"
                  name="teamid"
                  placeholder="Enter Team ID"
                  [(ngModel)]="memberForm.teamid"
                  [matAutocomplete]="teamidAutoComplete"
                  [disabled]="gettingMemberPosition || memberForm.inactive"
                />
                <mat-autocomplete #teamidAutoComplete="matAutocomplete">
                  <ng-container
                    *ngFor="
                      let teamid of teamidList
                        | filter
                          : memberForm.teamid
                          : 'memberDepartmentAutocomplete'
                    "
                  >
                    <mat-option [value]="teamid">
                      {{ teamid }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingMemberPosition"
                ></mat-progress-bar>
              </div>

              <div class="mb-3 col-md-6">
                <label for="section" class="form-label"> Section </label>
                <input
                  type="text"
                  class="form-control"
                  id="section"
                  name="section"
                  placeholder="Enter Section"
                  [disabled]="
                    hasPositionControl ||
                    gettingMemberPosition ||
                    memberForm.inactive
                  "
                  [(ngModel)]="memberForm.section"
                  [matAutocomplete]="sectionAutoComplete"
                />
                <mat-autocomplete #sectionAutoComplete="matAutocomplete">
                  <ng-container
                    *ngFor="
                      let section of sectionList
                        | filter
                          : memberForm.section
                          : 'memberDepartmentAutocomplete'
                    "
                  >
                    <mat-option [value]="section">
                      {{ section }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingMemberPosition"
                ></mat-progress-bar>
              </div>
              <div class="mb-3 col-md-6">
                <label for="costcenter" class="form-label"> Cost Center </label>
                <input
                  type="text"
                  class="form-control"
                  id="costcenter"
                  name="costcenter"
                  placeholder="Enter Cost Center"
                  [disabled]="
                    hasPositionControl ||
                    gettingMemberPosition ||
                    memberForm.inactive
                  "
                  [(ngModel)]="memberForm.costcenter"
                  [matAutocomplete]="costcenterAutoComplete"
                />
                <mat-autocomplete #costcenterAutoComplete="matAutocomplete">
                  <ng-container
                    *ngFor="
                      let costcenter of costcenterList
                        | filter
                          : memberForm.costcenter
                          : 'memberDepartmentAutocomplete'
                    "
                  >
                    <mat-option
                      [value]="costcenter"
                      *ngIf="costcenter != 'All'"
                    >
                      {{ costcenter }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingMemberPosition"
                ></mat-progress-bar>
              </div>
              <div class="mb-3 col-md-6">
                <label for="subdivision" class="form-label">
                  Sub Division
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="subdivision"
                  name="subdivision"
                  placeholder="Enter Sub Division"
                  [(ngModel)]="memberForm.subdivision"
                  [disabled]="
                    hasPositionControl ||
                    gettingMemberPosition ||
                    memberForm.inactive
                  "
                  [matAutocomplete]="subdivisionAutoComplete"
                />
                <mat-autocomplete #subdivisionAutoComplete="matAutocomplete">
                  <ng-container
                    *ngFor="
                      let subdivision of subdivisionList
                        | filter
                          : memberForm.subdivision
                          : 'memberDepartmentAutocomplete'
                    "
                  >
                    <mat-option [value]="subdivision">
                      {{ subdivision }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingMemberPosition"
                ></mat-progress-bar>
              </div>

              <div class="mb-3 col-md-6">
                <label
                  for="paylevel"
                  class="form-label cc-label"
                  (click)="openPayLevelForm()"
                >
                  Pay Level
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="paylevel"
                  name="paylevel"
                  placeholder="Enter Sub Division"
                  [(ngModel)]="memberForm.paylevel"
                  [disabled]="true"
                />
                <!-- <select
                  class="form-select"
                  id="paylevel"
                  name="paylevel"
                  [(ngModel)]="memberForm.paylevel"
                  [disabled]="
                    gettingPaylevel || hasPositionControl || memberForm.inactive
                  "
                >
                  <option value="" [selected]="memberForm.paylevel == ''">
                    Select Pay Level
                  </option>
                  <option
                    *ngFor="
                      let paylevel of paylevelList;
                      trackBy: trackByPaylevelid
                    "
                    value="{{ paylevel.paylevelid }}"
                    [selected]="paylevel.paylevelid == memberForm.paylevel"
                  >
                    {{ paylevel.name }}
                  </option>
                </select>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingPaylevel"
                ></mat-progress-bar> -->
              </div>

              <div class="mb-3 col-md-6">
                <label
                  for="roletype"
                  class="form-label cc-label"
                  (click)="openRoleForm()"
                >
                  Role
                </label>
                <select
                  class="form-select"
                  aria-label="Select Role"
                  id="roletype"
                  name="roletype"
                  #roletype="ngModel"
                  [(ngModel)]="memberForm.roletype"
                  [required]="rolerequired"
                  [ngClass]="{
                    'is-invalid': memberformSubmitted && roletype.invalid
                  }"
                  [disabled]="memberForm.inactive"
                >
                  <option value="">-</option>
                  <option
                    *ngFor="let i of academicList"
                    value="{{ i.roleid }}"
                    [selected]="i.roleid == memberForm.roletype"
                  >
                    {{ i.name }}
                  </option>
                </select>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingAcademicList"
                ></mat-progress-bar>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    roletype.invalid ||
                    ((roletype.dirty || roletype.touched) &&
                      memberformSubmitted)
                  "
                >
                  <div *ngIf="roletype.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
              </div>

              <div *ngIf="userequisition" class="mb-3 col-md-6">
                <label for="requisition" class="form-label">
                  Requisition
                </label>
                <select
                  class="form-select"
                  aria-label="Select Requisition"
                  id="requisition"
                  name="requisition"
                  [(ngModel)]="memberForm.requisitionid"
                  [disabled]="gettingRequisition || memberForm.inactive"
                >
                  <option value="">-</option>
                  <option
                    *ngFor="let i of requisitionList"
                    value="{{ i.requisitionid }}"
                    [selected]="i.requisitionid == memberForm.requisitionid"
                  >
                    Ref No. {{ i.refno }} - {{ i.positionname }}
                  </option>
                </select>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingRequisition"
                ></mat-progress-bar>
              </div>
            </div>

            <div class="row">
              <div class="mb-3 col-md-6">
                <label for="joineddate" class="form-label"> Joined Date </label>
                <div class="input-group">
                  <!-- <input class="form-control" type="date" id='joineddate' name='joineddate'
                    [(ngModel)]="memberForm.joineddate" required #joineddate="ngModel" max="9999-12-31"
                    [disabled]="memberForm.inactive"> -->
                  <input
                    class="form-control datepicker-readonly"
                    type="text"
                    id="joineddate"
                    name="joineddate"
                    placeholder="dd/mm/yyyy"
                    bsDatepicker
                    [bsConfig]="generalDatePickerConfig"
                    [bsValue]="memberForm.joineddate"
                    [(ngModel)]="memberForm.joineddate"
                    [bsConfig]="{ showClearButton: true }"
                    [disabled]="memberForm.inactive"
                    #joineddate="ngModel"
                    [ngClass]="{
                      'is-invalid': memberformSubmitted && joineddate.invalid
                    }"
                    required
                    readonly
                  />
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    joineddate.invalid ||
                    ((joineddate.dirty || joineddate.touched) &&
                      memberformSubmitted)
                  "
                >
                  <div *ngIf="joineddate.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
                <!-- <div class="invalid-feedback"
                    *ngIf="joineddate.invalid || (joineddate.dirty || joineddate.touched) && memberformSubmitted && needToFillPositionControl">
                    <div *ngIf="joineddate.errors?.required">
                      {{allinoneService.input_err_msg}}
                    </div>
                  </div> -->
              </div>

              <div class="mb-3 col-md-6">
                <label for="effectivedate" class="form-label">
                  Effective Date
                </label>
                <div class="input-group">
                  <!-- <input class="form-control" type="date" id='effectivedate' name='effectivedate'
                    [(ngModel)]="memberForm.effectivedate" required #effectivedate="ngModel"
                    (ngModelChange)="positionControlFieldsChange()"
                    [ngClass]="{ 'is-invalid': memberformSubmitted && effectivedate.invalid && needToFillPositionControl }"
                    [disabled]="hasPositionControl || memberForm.inactive" [min]="memberForm.joineddate"
                    max="9999-12-31"> -->
                  <!-- [ngClass]="{ 'is-invalid': memberformSubmitted && effectivedate.invalid && needToFillPositionControl }" -->
                  <input
                    type="text"
                    id="effectivedate"
                    name="effectivedate"
                    class="form-control datepicker-readonly"
                    placeholder="dd/mm/yyyy"
                    bsDatepicker
                    [bsConfig]="generalDatePickerConfig"
                    #effectivedate="ngModel"
                    [bsValue]="memberForm.effectivedate"
                    [(ngModel)]="memberForm.effectivedate"
                    required
                    [bsConfig]="{ showClearButton: true }"
                    [ngClass]="{
                      'is-invalid': memberformSubmitted && effectivedate.invalid
                    }"
                  />
                  <!-- <button class="btn btn-custom" type="button" id="button-addon2" (click)="removeDate('effective')"
                    [disabled]="hasPositionControl"><i class="fa fa-times" aria-hidden="true"></i></button> -->
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    effectivedate.invalid ||
                    ((effectivedate.dirty || effectivedate.touched) &&
                      memberformSubmitted)
                  "
                >
                  <div *ngIf="effectivedate.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
                <!-- <div class="invalid-feedback"
                  *ngIf="effectivedate.invalid || (effectivedate.dirty || effectivedate.touched) && memberformSubmitted && needToFillPositionControl">
                  <div *ngIf="effectivedate.errors?.required">
                    {{allinoneService.input_err_msg}}
                  </div>
                </div> -->
              </div>

              <div class="mb-3 col-md-6">
                <label
                  for="employmenttype"
                  class="form-label cc-label"
                  (click)="openMemberTypeForm('001')"
                >
                  Employment Type
                </label>
                <select
                  class="form-select"
                  aria-label="Select Employment Type"
                  id="employmenttype"
                  name="employmenttype"
                  [(ngModel)]="memberForm.employmenttype"
                  #employmenttype="ngModel"
                  (ngModelChange)="positionControlFieldsChange()"
                  required
                  [ngClass]="{
                    'is-invalid':
                      memberformSubmitted &&
                      employmenttype.invalid &&
                      needToFillPositionControl
                  }"
                >
                  <option value="">-</option>
                  <option
                    *ngFor="let etype of employmentTypes; trackBy: trackById"
                    value="{{ etype.id }}"
                    [selected]="etype.id == memberForm.employmenttype"
                  >
                    {{ etype.name }}
                  </option>
                </select>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    employmenttype.invalid ||
                    ((employmenttype.dirty || employmenttype.touched) &&
                      memberformSubmitted &&
                      needToFillPositionControl)
                  "
                >
                  <div *ngIf="employmenttype.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingMemberTypes"
                ></mat-progress-bar>
              </div>

              <div class="mb-3 col-md-6">
                <label
                  for="transactiontype"
                  class="form-label cc-label"
                  (click)="openMemberTypeForm('002')"
                >
                  Transaction Type
                </label>
                <select
                  class="form-select"
                  aria-label="Select Transaction Type"
                  id="transactiontype"
                  name="transactiontype"
                  [(ngModel)]="memberForm.transactiontype"
                  #transactiontype="ngModel"
                  required
                  (ngModelChange)="positionControlFieldsChange()"
                  [ngClass]="{
                    'is-invalid':
                      memberformSubmitted &&
                      transactiontype.invalid &&
                      needToFillPositionControl
                  }"
                >
                  <option value="">-</option>
                  <option
                    *ngFor="let ttype of transactionTypes; trackBy: trackById"
                    value="{{ ttype.id }}"
                    [selected]="ttype.id == memberForm.transactiontype"
                  >
                    {{ ttype.name }}
                  </option>
                </select>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    transactiontype.invalid ||
                    ((transactiontype.dirty || transactiontype.touched) &&
                      memberformSubmitted &&
                      needToFillPositionControl)
                  "
                >
                  <div *ngIf="transactiontype.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="gettingMemberTypes"
                ></mat-progress-bar>
              </div>

              <div class="mb-3 col-md-6">
                <label for="leavecalendar" class="form-label">
                  Leave Year
                </label>
                <select
                  class="form-select"
                  aria-label="Select Year"
                  id="leavecalendar"
                  name="leavecalendar"
                  [(ngModel)]="memberForm.leavecalendarid"
                  [disabled]="
                    calenderListLoading ||
                    gettingLeaveEntitlement ||
                    memberForm.inactive
                  "
                  #leaveyear="ngModel"
                  [required]="memberForm.leavetemplateid != ''"
                  [ngClass]="{
                    'is-invalid': memberformSubmitted && leaveyear.invalid
                  }"
                >
                  <option value="">-</option>
                  <option
                    *ngFor="let cal of activeCalendarList"
                    value="{{ cal.calendarid }}"
                  >
                    {{ cal.year }}
                  </option>
                </select>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    leaveyear.invalid ||
                    ((leaveyear.dirty || leaveyear.touched) &&
                      memberformSubmitted)
                  "
                >
                  <div *ngIf="leaveyear.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="calenderListLoading || gettingLeaveEntitlement"
                >
                </mat-progress-bar>
              </div>
              <div class="mb-3 col-md-6">
                <label for="leavetemplate" class="form-label">
                  Leave Template
                </label>
                <select
                  class="form-select"
                  aria-label="Select Year"
                  id="leavetemplate"
                  name="leavetemplate"
                  [(ngModel)]="memberForm.leavetemplateid"
                  [disabled]="
                    templateListLoading ||
                    gettingLeaveEntitlement ||
                    memberForm.inactive
                  "
                  #leavetemplate="ngModel"
                  [required]="memberForm.leavecalendarid != ''"
                  [ngClass]="{
                    'is-invalid': memberformSubmitted && leavetemplate.invalid
                  }"
                >
                  <option value="">-</option>
                  <option
                    *ngFor="let template of templeteList"
                    value="{{ template.templateid }}"
                  >
                    {{ template.name }}
                  </option>
                </select>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    leavetemplate.invalid ||
                    ((leavetemplate.dirty || leavetemplate.touched) &&
                      memberformSubmitted)
                  "
                >
                  <div *ngIf="leavetemplate.errors?.required">
                    {{ allinoneService.input_err_msg }}
                  </div>
                </div>
                <mat-progress-bar
                  mode="indeterminate"
                  *ngIf="calenderListLoading || gettingLeaveEntitlement"
                >
                </mat-progress-bar>
              </div>
            </div>
            <div class="mb-3 col-md-12">
              <!-- <label for="config" class="form-label">
                  Config
                </label> -->
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="attendance"
                    [(ngModel)]="memberForm.attendancerequired"
                    name="attendance"
                    [disabled]="memberForm.inactive"
                  />
                  <label class="form-check-label mb-1 ms-1" for="attendance">
                    Attendance Required
                  </label>
                </div>
                <div class="me-5">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="activity"
                    [(ngModel)]="memberForm.activityrequired"
                    name="activity"
                    [disabled]="memberForm.inactive"
                  />
                  <label class="form-check-label mb-1 ms-1" for="activity">
                    Activity Required
                  </label>
                </div>
                <div class="me-5">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="Resign"
                    [(ngModel)]="memberForm.resign"
                    name="activity"
                  />
                  <label class="form-check-label mb-1 ms-1" for="Resign">
                    Resign
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div class="card card-body my-card">
          <div class="info">Other</div>
          <div class="servcie">
            <div class="row">
              <div *ngIf="memberForm.profiletype == 'Membership'">
                <div class="row g-3 mb-3">
                  <div class="col-md-6">
                    <label for="type" class="form-label"> Rank </label>
                    <input
                      type="text"
                      class="form-control"
                      id="rank"
                      name="rank"
                      placeholder="Enter Rank"
                      [(ngModel)]="memberForm.rank"
                      required
                      #rank="ngModel"
                      (keyup.enter)="submitMember()"
                      [disabled]="memberForm.inactive"
                      maxlength="30"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="entity" class="form-label"> Entity </label>
                    <input
                      type="text"
                      class="form-control"
                      id="entity"
                      name="entity"
                      placeholder="Enter Entity"
                      [(ngModel)]="memberForm.entity"
                      required
                      #entity="ngModel"
                      (keyup.enter)="submitMember()"
                      maxlength="30"
                      [disabled]="memberForm.inactive"
                    />
                  </div>
                </div>
                <div class="mb-4">
                  <label for="formFile" class="form-label">Attachment</label>
                  <div class="input-group">
                    <input
                      *ngIf="isFileChange"
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event)"
                      accept="image/*,.docx,application/pdf,application/vnd.ms-powerpoint"
                      id="formFile"
                      #fileInput
                      [disabled]="memberForm.inactive"
                    />
                    <button
                      class="input-group-text btn btn-custom"
                      id="btnGroupAddon2"
                      *ngIf="!isEditing && fileSelected"
                      (click)="removefileSelected()"
                    >
                      Remove
                    </button>
                    <button
                      class="input-group-text btn btn-custom"
                      id="btnGroupAddon2"
                      *ngIf="isFileChange && isEditing && !noFile"
                      (click)="isFileChange = false"
                    >
                      Cancel
                    </button>
                  </div>

                  <!-- file preview -->

                  <div class="input-group" *ngIf="!isFileChange && isEditing">
                    <input
                      class="form-control"
                      readonly
                      aria-describedby="btnGroupAddon2"
                      [(ngModel)]="memberForm.filename"
                      [disabled]="memberForm.inactive"
                    />
                    <button
                      class="input-group-text btn btn-custom"
                      id="btnGroupAddon2"
                      (click)="isFileChange = true"
                      [disabled]="memberForm.inactive"
                    >
                      Change
                    </button>
                    <button
                      class="input-group-text btn btn-custom"
                      id="btnGroupAddon2"
                      style="border-left: 1px solid"
                      (click)="downloadAttachment()"
                      [disabled]="memberForm.inactive"
                    >
                      <span *ngIf="!downloading">Download</span>
                      <span *ngIf="downloading">
                        <div class="spinner-border download" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </span>
                    </button>
                    <button
                      class="input-group-text btn btn-custom"
                      id="btnGroupAddon2"
                      style="border-left: 1px solid"
                      (click)="isFileChange = true"
                      [disabled]="memberForm.inactive"
                    >
                      Remove
                    </button>
                  </div>
                </div>

                <div class="row g-3 mb-3">
                  <div class="col-md-6">
                    <label for="exttext1" class="form-label">
                      Extra Text Field 1
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exttext1"
                      name="exttext1"
                      placeholder=""
                      [(ngModel)]="memberForm.exttext1"
                      required
                      #exttext1="ngModel"
                      (keyup.enter)="submitMember()"
                      maxlength="30"
                      [disabled]="memberForm.inactive"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="exttext2" class="form-label">
                      Extra Text Field 2
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exttext2"
                      name="exttext2"
                      placeholder=""
                      [(ngModel)]="memberForm.exttext2"
                      required
                      #exttext2="ngModel"
                      (keyup.enter)="submitMember()"
                      maxlength="30"
                      [disabled]="memberForm.inactive"
                    />
                  </div>
                </div>

                <div class="row g-3 mb-3">
                  <div class="col-md-6">
                    <label for="exttext3" class="form-label">
                      Extra Text Field 3
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exttext3"
                      name="exttext3"
                      placeholder=""
                      [(ngModel)]="memberForm.exttext3"
                      required
                      #exttext3="ngModel"
                      (keyup.enter)="submitMember()"
                      maxlength="30"
                      [disabled]="memberForm.inactive"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="exttext4" class="form-label">
                      Extra Text Field 4
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exttext4"
                      name="exttext4"
                      placeholder=""
                      [(ngModel)]="memberForm.exttext4"
                      required
                      #exttext4="ngModel"
                      (keyup.enter)="submitMember()"
                      maxlength="30"
                      [disabled]="memberForm.inactive"
                    />
                  </div>
                </div>

                <div class="mb-4">
                  <label for="exttext5" class="form-label">
                    Extra Text Field 5
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="exttext5"
                    name="exttext5"
                    placeholder=""
                    [(ngModel)]="memberForm.exttext5"
                    required
                    #exttext5="ngModel"
                    (keyup.enter)="submitMember()"
                    maxlength="30"
                    [disabled]="memberForm.inactive"
                  />
                </div>
              </div>

              <div class="mb-3">
                <label for="remark" class="form-label">Remark</label>
                <textarea
                  class="form-control"
                  id="remark"
                  [(ngModel)]="memberForm.remark"
                  name="remark"
                  rows="3"
                  placeholder="Enter Remark"
                  [disabled]="memberForm.inactive"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <div>
      <!-- <div class="row" [ngClass]="{'row-custom' : !memberAddorNot && !isBulkUpload}">
        <div class="mb-3 col-md-12 col-sm-12 col-lg-4" [ngClass]="{'row row-custom' : !isMobileView}"
          *ngIf="!memberAddorNot && !isBulkUpload">
          <label for="post" class="col-form-label" [ngClass]="{'custom-label' : !isMobileView, 'ps-0' : isTabletView}">
            {{membershiptype ==
            '003'
            ? 'Member Type' : 'Post'}}</label>

          <div [ngClass]="{'w-75': !isMobileView , 'w-100' : isMobileView}">
            <select class="form-select" aria-label="Select Post" id="post" name="post" (change)="postChanged($event)"
              [(ngModel)]="searchPost" required [disabled]="gettingOrgList || isEditing">
              <option *ngFor="let post of postList" value="{{post}}">
                {{post}}</option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>
          </div>
        </div>
        <div class="mb-3 col-md-12 col-sm-12 col-lg-4" [ngClass]="{'row row-custom' : !isMobileView}"
          *ngIf="!memberAddorNot && !isBulkUpload">
          <label for="department" class="col-form-label"
            [ngClass]="{'custom-label' : !isMobileView, 'ps-0' : isTabletView}">
            Department</label>

          <div [ngClass]="{'w-75': !isMobileView , 'w-100' : isMobileView}">
            <select class="form-select" aria-label="Select Department" id="department" name="department"
              (change)="departmentChanged($event)" [(ngModel)]="searchDepartment"
              [disabled]="gettingOrgList || isEditing">
              <option *ngFor="let department of departmentList" value="{{department}}">
                {{department}}</option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>
          </div>
        </div>
        <div class="mb-3 col-md-12 col-sm-12 col-lg-4" [ngClass]="{'row row-custom' : !isMobileView}"
          *ngIf="!memberAddorNot && !isBulkUpload">
          <label for="status" class="col-form-label"
            [ngClass]="{'custom-label' : !isMobileView, 'ps-0' : isTabletView}">
            Status</label>

          <div [ngClass]="{'w-75': !isMobileView , 'w-100' : isMobileView}">
            <select class="form-select" aria-label="Select Status" id="status" name="status"
              (change)="statusChange($event)" [(ngModel)]="searchStatus" [disabled]="gettingOrgList || isEditing">
              <option *ngFor="let status of statusList" value="{{status.id}}">
                {{status.name}}</option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>
          </div>
        </div>
      </div> -->
      <div *ngIf="isBulkUpload">
        <div class="mb-3">
          <label for="file" class="form-label"
            >Excel Template (<span
              class="cc-label"
              (click)="downloadSampleFile()"
              >Download Sample Template</span
            >)</label
          >
          <div class="input-group">
            <input
              class="form-control"
              type="file"
              (change)="onExcelFileSelect($event)"
              accept=".xlsx"
              id="formFile"
              #excelFile
              [disabled]="excel"
            />
            <button
              class="input-group-text btn btn-custom"
              id="btnGroupAddon2"
              *ngIf="excel"
              (click)="removeExcel()"
            >
              Remove
            </button>
            <button
              class="input-group-text btn btn-custom"
              style="border-left: 1px solid white"
              id="btnGroupAddon2"
              [disabled]="!excel || checkingExcelFile"
              (click)="checkExcelFile()"
            >
              <span *ngIf="!checkingExcelFile"> Check </span>
              <span *ngIf="checkingExcelFile">
                <span
                  class="spinner-border spinner-border-sm button-spinner"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="mx-1">Checking</span>
              </span>
            </button>
          </div>
        </div>
        <div style="color: green" *ngIf="allok">
          <i class="fa fa-check me-2"></i> Template is correct.
        </div>
        <div class="d-flex flex-wrap mt-3" *ngIf="!checkingExcelFile">
          <div class="card custom-box" *ngIf="newcclist.length > 0">
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <div style="align-items: center; display: inherit">
                  New {{ membershiptype == "003" ? "Member Type" : "Post" }}
                </div>
                <div class="ps-1">
                  <app-button-loader
                    class="float-end"
                    (click)="saveCc()"
                    [button_text]="'Save'"
                    [load_button_text]="'Saving'"
                    [isLoading]="ccSaveLoading"
                  >
                  </app-button-loader>
                </div>
              </div>
            </div>
            <div class="p-2">
              <ul style="padding-left: 1.6rem">
                <li *ngFor="let x of newcclist">{{ x.name }}</li>
              </ul>
            </div>
          </div>

          <div class="card custom-box" *ngIf="newrolelist.length > 0">
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <div style="align-items: center; display: inherit">
                  New Role
                </div>
                <div class="ps-1">
                  <app-button-loader
                    class="float-end"
                    (click)="saveNewRole()"
                    [button_text]="'Save'"
                    [load_button_text]="'Saving'"
                    [isLoading]="roleSaveLoading"
                  >
                  </app-button-loader>
                </div>
              </div>
            </div>
            <div class="p-2">
              <ul style="padding-left: 1.6rem">
                <li *ngFor="let x of newrolelist">{{ x.name }}</li>
              </ul>
            </div>
          </div>

          <div class="card custom-box" *ngIf="newEmploymentTypeList.length > 0">
            <div class="card-header">
              <div
                class="d-flex align-items-center h-100 cc-label"
                (click)="openMemberTypeForm('001')"
              >
                New Employment Types
                <!-- <div class="ps-1">
                  <app-button-loader class="float-end" (click)="saveCc()" [button_text]="'Save'"
                    [load_button_text]="'Saving'" [isLoading]="ccSaveLoading">
                  </app-button-loader>
                </div> -->
              </div>
            </div>
            <div class="p-2">
              <ul style="padding-left: 1.6rem">
                <li *ngFor="let x of newEmploymentTypeList">{{ x }}</li>
              </ul>
            </div>
          </div>
          <div
            class="card custom-box"
            *ngIf="newTransactionTypeList.length > 0"
          >
            <div class="card-header">
              <div
                class="d-flex align-items-center h-100 cc-label"
                (click)="openMemberTypeForm('002')"
              >
                New Transaction Types
                <!-- <div class="ps-1">
                  <app-button-loader class="float-end" (click)="saveCc()" [button_text]="'Save'"
                    [load_button_text]="'Saving'" [isLoading]="ccSaveLoading">
                  </app-button-loader>
                </div> -->
              </div>
            </div>
            <div class="p-2">
              <ul style="padding-left: 1.6rem">
                <li *ngFor="let x of newTransactionTypeList">{{ x }}</li>
              </ul>
            </div>
          </div>
          <div
            class="alert alert-danger custom-box"
            role="alert"
            *ngIf="errorList.length > 0"
          >
            <div class="d-flex align-items-center">
              <mat-icon class="me-2"> error </mat-icon>
              <div>Error</div>
            </div>
            <hr />
            <ul style="padding-left: 1rem">
              <li *ngFor="let e of errorList">
                <span *ngIf="e.line">In line no. {{ e.line }},&nbsp;</span
                >{{ e.error }}
              </li>
            </ul>
          </div>

          <div
            class="alert alert-warning custom-box"
            *ngIf="duplicateList.length > 0 || warningList.length > 0"
          >
            <div class="d-flex align-items-center">
              <mat-icon class="me-2"> warning </mat-icon>
              <div>Warning</div>
            </div>
            <hr />

            <ul style="padding-left: 1rem">
              <li *ngFor="let du of duplicateList">
                "{{ du }}" is duplicated.
              </li>
              <li *ngFor="let e of warningList">
                In line no. {{ e.line }}, {{ e.error }}
              </li>
            </ul>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      *ngIf="!memberAddorNot && !isBulkUpload"
    >
      <div
        class="mb-3 d-flex flex-wrap justify-content-start align-items-center"
      >
        <div
          class="input-group"
          style="width: 300px !important"
          *ngIf="!isMobileView"
        >
          <input
            class="form-control"
            type="text"
            placeholder="&#xf002; Search with Name/ID/User ID"
            (ngModelChange)="searchTextChange($event)"
            aria-label="Search"
            (focus)="isFocus = true"
            (blur)="isFocus = false"
            [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }"
            style="font-family: FontAwesome, Ubuntu"
          />
          <button
            class="btn btn-outline-secondary"
            type="button"
            *ngIf="searchText"
            (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
          >
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div
          class="dropdown"
          [ngClass]="{ 'ms-0': isMobileView, 'ms-2': !isMobileView }"
        >
          <button
            type="button"
            class="btn btn-custom"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            title="Page Size"
          >
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input
                type="radio"
                name="pg-count"
                value="{{ page.count }}"
                (change)="changePageCount($event)"
                id="pg{{ page.name }}"
                class="p-1"
                [checked]="page.count == 100"
              />
              <label for="pg{{ page.name }}" class="col-10 p-1">
                {{ page.name }}
              </label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <button
          type="button"
          class="btn btn-custom ms-2"
          id="togglefilter"
          (click)="toggleFilter()"
        >
          <mat-icon>{{
            openfilter ? "keyboard_arrow_up" : "keyboard_arrow_down"
          }}</mat-icon>
        </button>
        <div class="line"></div>
        <!-- <div class="mb-3 me-2" *ngIf="!memberAddorNot"> -->
        <button
          type="button"
          class="btn btn-custom ms-2"
          (click)="openBulkUpload()"
          title="Bulk Upload"
        >
          <img class="excel-icon" src="../../../assets/images/excel.png" />
          Import
        </button>
        <!-- </div> -->
        <!-- <div class="mb-3 me-2" *ngIf="!memberAddorNot"> -->
        <button
          type="button"
          class="btn btn-custom ms-2"
          (click)="downloadSheet()"
          title="Export Members To Excel"
          [disabled]="downloading"
        >
          <img class="excel-icon" src="../../../assets/images/excel.png" />

          <!-- Show spinner when downloading is true, otherwise show 'Export' -->
          <span *ngIf="!downloading">Export</span>
          <div
            *ngIf="downloading"
            class="spinner-border spinner-border-sm excel-spinner"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <!-- </div> -->
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{
            (
              members
              | memberFilter
                : searchText
                : searchPost
                : searchDepartment
                : searchDivision
                : searchCostCenter
                : searchStatus
                : searchBranch
                : searchPaylevel
                : searchEmploymentType
                : searchTransactionType
                : searchTeamid
                : searchSection
                : searchSubDivision
                : searchForm.firstJoineddate
                : searchForm.secondJoineddate
                : joineddateopt
                : searchForm.firstEffectivedate
                : searchForm.secondEffectivedate
                : effectivedateopt
            ).length
          }}</span>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-custom"
          (click)="getSyncHistory()"
          [disabled]="members.length == 0"
        >
          Sync History
        </button>
      </div>
    </div>

    <div
      *ngIf="openfilter && !isEditing && !memberAddorNot"
      class="d-flex gap-3 flex-wrap"
      style="row-gap: 0 !important"
    >
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="postmenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          {{ membershiptype == "003" ? "Member Type" : "Post" }}&nbsp;:&nbsp;{{
            searchPost.length > 22
              ? (searchPost | slice : 0 : 22) + "..."
              : searchPost
          }}
          <mat-menu #postmenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let post of postList"
              (click)="postChange(post)"
            >
              {{ post }}
            </button>
          </mat-menu>
          <span
            *ngIf="memberListLoading"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="branchmenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          Branch&nbsp;:&nbsp;{{
            searchBranch.length > 20
              ? (searchBranch | slice : 0 : 20) + "..."
              : searchBranch
          }}
          <mat-menu #branchmenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let branch of tempBranchList"
              (click)="branchChange(branch)"
            >
              {{ branch }}
            </button>
          </mat-menu>
          <span
            *ngIf="gettingMemberPosition"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="departmentmenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          Department&nbsp;:&nbsp;{{
            searchDepartment.length > 20
              ? (searchDepartment | slice : 0 : 20) + "..."
              : searchDepartment
          }}
          <mat-menu #departmentmenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let department of departmentList"
              (click)="departmentChange(department)"
            >
              {{ department }}
            </button>
          </mat-menu>
          <span
            *ngIf="gettingMemberPosition"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="teamidmenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          Team ID&nbsp;:&nbsp;{{
            searchTeamid.length > 20
              ? (searchTeamid | slice : 0 : 20) + "..."
              : searchTeamid
          }}
          <mat-menu #teamidmenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let teamid of tempTeamidList"
              (click)="teamidChange(teamid)"
            >
              {{ teamid }}
            </button>
          </mat-menu>
          <span
            *ngIf="gettingMemberPosition"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="sectionmenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          Section&nbsp;:&nbsp;{{
            searchSection.length > 22
              ? (searchSection | slice : 0 : 22) + "..."
              : searchSection
          }}
          <mat-menu #sectionmenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let section of tempSectionList"
              (click)="sectionChange(section)"
            >
              {{ section }}
            </button>
          </mat-menu>
          <span
            *ngIf="gettingMemberPosition"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="divisionmenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          Division&nbsp;:&nbsp;{{
            searchDivision.length > 22
              ? (searchDivision | slice : 0 : 22) + "..."
              : searchDivision
          }}
          <mat-menu #divisionmenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let division of divisionList"
              (click)="divisionChange(division)"
            >
              {{ division }}
            </button>
          </mat-menu>
          <span
            *ngIf="gettingMemberPosition"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="subdivisionmenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          Sub Division&nbsp;:&nbsp;{{
            searchSubDivision.length > 22
              ? (searchSubDivision | slice : 0 : 22) + "..."
              : searchSubDivision
          }}
          <mat-menu #subdivisionmenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let subdivision of tempSubDivisionList"
              (click)="subDivisionChange(subdivision)"
            >
              {{ subdivision }}
            </button>
          </mat-menu>
          <span
            *ngIf="gettingMemberPosition"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="costcentermenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          Cost Center&nbsp;:&nbsp;{{
            searchCostCenter.length > 22
              ? (searchCostCenter | slice : 0 : 22) + "..."
              : searchCostCenter
          }}
          <mat-menu #costcentermenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let cc of costcenterList"
              (click)="costCenterChange(cc)"
            >
              {{ cc }}
            </button>
          </mat-menu>
          <span
            *ngIf="gettingMemberPosition"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="transactiontypemenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          Transaction Type&nbsp;:&nbsp;{{
            searchTransactionType.length > 22
              ? (searchTransactionType | slice : 0 : 22) + "..."
              : searchTransactionType
          }}
          <mat-menu #transactiontypemenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let transaction of transactionTypeList"
              (click)="transactionTypeChange(transaction)"
            >
              {{ transaction }}
            </button>
          </mat-menu>
          <span
            *ngIf="gettingMemberPosition"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="employmenttypemenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          Employment Type&nbsp;:&nbsp;{{
            searchEmploymentType.length > 22
              ? (searchEmploymentType | slice : 0 : 22) + "..."
              : searchEmploymentType
          }}
          <mat-menu #employmenttypemenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let employment of employmentTypeList"
              (click)="employmentTypeChange(employment)"
            >
              {{ employment }}
            </button>
          </mat-menu>
          <span
            *ngIf="gettingMemberPosition"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="paylevelmenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          PayLevel&nbsp;:&nbsp;{{
            searchPaylevel.length > 22
              ? (searchPaylevel | slice : 0 : 22) + "..."
              : searchPaylevel
          }}
          <mat-menu #paylevelmenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let paylevel of tempPaylevelList"
              (click)="paylevelChange(paylevel)"
            >
              {{ paylevel }}
            </button>
          </mat-menu>
          <span
            *ngIf="gettingMemberPosition"
            class="spinner-border spinner-border-sm button-spinner mx-1"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>

      <div
        class="filtter-btn align-items-center mb-3"
        [matMenuTriggerFor]="statusmenu"
      >
        <button
          class="btn btn-secondary dropdown-toggle advanced-filter-btn"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          style="color: white; min-width: 100px"
        >
          Status&nbsp;:&nbsp;{{
            searchStatusName.length > 22
              ? (searchStatusName | slice : 0 : 22) + "..."
              : searchStatusName
          }}
          <mat-menu #statusmenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              *ngFor="let status of statusList"
              (click)="statusChange(status)"
            >
              {{ status.name }}
            </button>
          </mat-menu>
        </button>
      </div>
    </div>

    <div *ngIf="openfilter && !isEditing && !memberAddorNot">
      <div class="d-flex gap-3 flex-wrap" style="row-gap: 0 !important">
        <div class="filtter-btn align-items-center mb-3 position-relative">
          <input
            type="text"
            class="form-control datepicker-readonly"
            placeholder="Join date"
            bsDatepicker
            [bsConfig]="generalDatePickerConfig"
            [bsValue]="searchForm.firstJoineddate"
            [bsConfig]="{ adaptivePosition: true }"
            placement="right"
            [(ngModel)]="searchForm.firstJoineddate"
            readonly
          />
          <button
            type="button"
            class="btn btn-outline-danger position-absolute end-0 top-50 translate-middle-y me-2 p-1"
            (click)="clearDate('firstjoin')"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </div>
        <div class="filtter-btn align-items-center mb-3">
          <select
            class="form-select"
            aria-label="Select status"
            style="font-size: 14px"
            [(ngModel)]="joineddateopt"
          >
            <option *ngFor="let i of operatorList" value="{{ i.code }}">
              {{ i.name }}
            </option>
          </select>
        </div>
        <div
          *ngIf="joineddateopt == '6'"
          class="filtter-btn align-items-center mb-3 position-relative"
        >
          <input
            type="text"
            class="form-control datepicker-readonly"
            placeholder="Join date"
            bsDatepicker
            [bsConfig]="generalDatePickerConfig"
            [bsValue]="searchForm.secondJoineddate"
            [bsConfig]="{ adaptivePosition: true }"
            placement="right"
            [(ngModel)]="searchForm.secondJoineddate"
            readonly
          />
          <button
            type="button"
            class="btn btn-outline-danger position-absolute end-0 top-50 translate-middle-y me-2 p-1"
            (click)="clearDate('secondjoin')"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </div>
      </div>
      <div class="d-flex gap-3 flex-wrap" style="row-gap: 0 !important">
        <div class="filtter-btn align-items-center mb-3 position-relative">
          <input
            type="text"
            class="form-control datepicker-readonly"
            placeholder="Effective date"
            bsDatepicker
            [bsConfig]="generalDatePickerConfig"
            [bsValue]="searchForm.firstEffectivedate"
            [bsConfig]="{ adaptivePosition: true }"
            placement="right"
            [(ngModel)]="searchForm.firstEffectivedate"
            readonly
          />
          <button
            type="button"
            class="btn btn-outline-danger position-absolute end-0 top-50 translate-middle-y me-2 p-1"
            (click)="clearDate('firsteffective')"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </div>
        <div class="filtter-btn align-items-center mb-3">
          <select
            class="form-select"
            aria-label="Select status"
            style="font-size: 14px"
            [(ngModel)]="effectivedateopt"
          >
            <option *ngFor="let i of operatorList" value="{{ i.code }}">
              {{ i.name }}
            </option>
          </select>
        </div>
        <div
          *ngIf="effectivedateopt == '6'"
          class="filtter-btn align-items-center mb-3 position-relative"
        >
          <input
            type="text"
            class="form-control datepicker-readonly"
            placeholder="Effective date"
            bsDatepicker
            [bsConfig]="generalDatePickerConfig"
            [bsValue]="searchForm.secondEffectivedate"
            [bsConfig]="{ adaptivePosition: true }"
            placement="right"
            [(ngModel)]="searchForm.secondEffectivedate"
            readonly
          />
          <button
            type="button"
            class="btn btn-outline-danger position-absolute end-0 top-50 translate-middle-y me-2 p-1"
            (click)="clearDate('secondeffective')"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- <div
      *ngIf="openfilter && !isEditing && !memberAddorNot"
      class="d-flex flex-wrap gap-3"
      style="row-gap: 0 !important; align-items: center"
    >
      <div class="d-flex align-items-center mb-3 me-2">
        <select class="form-select me-1" aria-label="Select status" [(ngModel)]="searchForm.joineddateopt">
          <option *ngFor="let i of operatorList" value="{{ i.code }}">
            {{ i.name }}
          </option>
        </select>

        <div class="input-group">
          <input
            type="text"
            class="form-control datepicker-readonly"
            id="searchjoineddate"
            name="searchjoineddate"
            placeholder="dd/mm/yyyy"
            bsDatepicker
            [bsConfig]="{
              containerClass: 'custom-datepicker',
              dateInputFormat: 'DD/MM/YYYY',
              showWeekNumbers: false,
              showClearButton: false,
              adaptivePosition: true
            }"
            [bsValue]="searchForm.searchjoineddate"
            [(ngModel)]="searchForm.searchjoineddate"
            placement="left"
            readonly
          />
        </div>
      </div>
      <div class="d-flex align-items-center mb-3 me-2">
        <select class="form-select me-1" aria-label="Select status" [(ngModel)]="searchForm.effectivedateopt">
          <option *ngFor="let i of operatorList" value="{{ i.code }}">
            {{ i.name }}
          </option>
        </select>

        <div class="input-group">
          <input
            type="text"
            class="form-control datepicker-readonly"
            id="searcheffectivedate"
            name="searcheffectivedate"
            placeholder="dd/mm/yyyy"
            bsDatepicker
            bsDatepicker
            [bsConfig]="{
              containerClass: 'custom-datepicker',
              dateInputFormat: 'DD/MM/YYYY',
              showWeekNumbers: false,
              showClearButton: false,
              adaptivePosition: true
            }"
            [bsValue]="searchForm.searcheffectivedate"
            [(ngModel)]="searchForm.searcheffectivedate"
            placement="right"
            readonly
          />
        </div>
      </div>
    </div> -->

    <div class="input-group mb-2" *ngIf="isMobileView && !memberAddorNot">
      <input
        class="form-control"
        type="text"
        placeholder="&#xf002; Search"
        aria-label="Search"
        (focus)="isFocus = true"
        (blur)="isFocus = false"
        [(ngModel)]="searchText"
        (ngModelChange)="searchTextChange($event)"
        [ngClass]="{ 'c-search-input-with-clear': searchText }"
        style="font-family: FontAwesome, Ubuntu"
      />
      <button
        class="btn btn-outline-secondary"
        type="button"
        *ngIf="searchText"
        (click)="clear()"
        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
      >
        <mat-icon class="c-icon">close</mat-icon>
      </button>
    </div>
    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
      <table
        class="table table-responsive table-borderless"
        *ngIf="!memberAddorNot && !isBulkUpload"
      >
        <thead class="table-header">
          <tr>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="userid">
              <div class="resizable-header">User ID</div>
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="username"
            >
              Name
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="employeeid"
            >
              Employee ID
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="classcode"
            >
              {{ membershiptype == "003" ? "Member Type" : "Post" }}
            </td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="branch">
              Branch
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="department"
            >
              Department
            </td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="teamid">
              Team ID
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="division"
            >
              Division
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="paylevel"
            >
              Pay Level
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="employmenttype"
            >
              Employment Type
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="transactiontype"
            >
              Transaction Type
            </td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="remark">
              Remark
            </td>
            <td scope="col" class="d-flex justify-content-center">Actions</td>
          </tr>
        </thead>
        <tbody *ngIf="memberListLoading">
          <tr>
            <td colspan="13" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="memberListLoading == false">
          <tr *ngIf="members.length == 0">
            <td colspan="13" class="nodata">Empty</td>
          </tr>
          <tr
            *ngFor="
              let item of members
                | memberFilter
                  : searchText
                  : searchPost
                  : searchDepartment
                  : searchDivision
                  : searchCostCenter
                  : searchStatus
                  : searchBranch
                  : searchPaylevel
                  : searchEmploymentType
                  : searchTransactionType
                  : searchTeamid
                  : searchSection
                  : searchSubDivision
                  : searchForm.firstJoineddate
                  : searchForm.secondJoineddate
                  : joineddateopt
                  : searchForm.firstEffectivedate
                  : searchForm.secondEffectivedate
                  : effectivedateopt
                | paginate : { itemsPerPage: itemPerPage, currentPage: p };
              let index = index
            "
          >
            <td
              class="td-data td-edit"
              (click)="editMember(item)"
              style="max-width: fit-content"
            >
              <div
                class="d-flex justify-content-center align-items-start flex-column"
              >
                <span
                  class="data-overflow"
                  data-bs-placement="right"
                  style="min-width: 210px; width: auto"
                  [title]="item.userid"
                >
                  {{ item.userid }}
                  <span *ngIf="item.role == '300'">
                    <img
                      class="bluemark"
                      src="../../../assets/images/bluemark.png"
                      matTooltip="Admin"
                    />
                  </span>
                  <span *ngIf="item.hradmin == '700'">
                    <img
                      class="bluemark"
                      src="../../../assets/images/bluemark_hr.png"
                      matTooltip="HR Admin"
                    />
                  </span>
                  <span *ngIf="item.contributor == '400'">
                    <img
                      class="bluemark"
                      src="../../../assets/images/bluemark_contributor.png"
                      style="margin-left: 3px"
                      matTooltip="Contributor"
                    />
                  </span>
                  <span *ngIf="item.rolelist.length > 0">
                    <img
                      class="bluemark"
                      src="../../../assets/images/bluemark_role.png"
                      style="margin-left: 3px"
                      matTooltip="Roles"
                    />
                  </span>
                </span>
                <span class="data-overflow" *ngIf="item.newuserid">{{
                  item.newuserid
                }}</span>
              </div>
            </td>
            <td class="td-data">
              <span [title]="item.username">{{ item.username }} </span>
            </td>
            <td class="td-data">
              <span [title]="item.employeeid">{{ item.employeeid }} </span>
            </td>
            <td class="td-data">
              <span [title]="item.type">{{ item.type }}</span>
            </td>
            <td class="td-data">
              <span [title]="item.department">{{ item.post }}</span>
            </td>
            <td class="td-data">
              <span [title]="item.department">{{ item.department }}</span>
            </td>
            <td class="td-data">
              <span [title]="item.department">{{ item.teamid }}</span>
            </td>
            <td class="td-data">
              <span [title]="item.division">{{ item.division }}</span>
            </td>
            <td class="td-data">
              <span [title]="item.division">{{ item.paylevel }}</span>
            </td>
            <td class="td-data">
              <span [title]="item.division">{{ item.employmenttypename }}</span>
            </td>
            <td class="td-data">
              <span [title]="item.division">{{
                item.transactiontypename
              }}</span>
            </td>
            <td class="td-data">
              <span [title]="item.remark">{{ item.remark }}</span>
            </td>
            <td class="d-flex justify-content-center align-items-center">
              <button
                class="btn btn-outline-primary me-2"
                (click)="editMember(item)"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button
                class="btn btn-outline-danger"
                (click)="deleteMember(item.id, item.userid)"
                [disabled]="
                  item.role == '300' &&
                  item.userid == allinoneService.getKunyekUserId()
                "
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination-controls
      class="paginate"
      (pageChange)="p = $event"
      *ngIf="
        (
          members
          | memberFilter
            : searchText
            : searchPost
            : searchDepartment
            : searchDivision
            : searchCostCenter
            : searchStatus
            : searchBranch
            : searchPaylevel
            : searchEmploymentType
            : searchTransactionType
            : searchTeamid
            : searchSection
            : searchSubDivision
            : searchForm.firstJoineddate
            : searchForm.secondJoineddate
            : joineddateopt
            : searchForm.firstEffectivedate
            : searchForm.secondEffectivedate
            : effectivedateopt
        ).length > itemPerPage &&
        !memberAddorNot &&
        !isBulkUpload
      "
      previousLabel="Prev"
      nextLabel="Next"
    >
    </pagination-controls>
  </div>
</div>
