
import { DatePipe, } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,

} from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { FilterPipe } from 'src/app/shared/filter.pipe';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {
  gettingData: boolean = true;
  vehicleList: any = [
    // {
    //   "carid": "393939",
    //   "carno": "8D:2938",
    //   "drivername": "U Aung",
    //   "driverphone": "09767064706",
    //   "maxpassenger": 4
    // }
  ];

  cartypelist: any = [
    { "value": "001", "name": "Car" },
    { "value": "002", "name": "Taxi" },

  ];
  cartype: any = "001";

  addVehicle: boolean = false;
  saveLoading: boolean = false;
  gettingVehicle: boolean = false;
  carFormSave: boolean = false;
  isEditing: boolean = false;
  orgid: any = "";
  organizations: any = [];
  searchText: any = "";

  itemPerPage: any = "";
  currentPage: any = 1;
  text1: any = "";
  text2: any = "";

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  carForm = {
    "carid": "",
    "carno": "",
    "maxpassenger": "",
    "carname": "",
  }
  istransoprationAdmin: boolean = false;
  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private filter: FilterPipe
  ) {
    this.istransoprationAdmin = this.allinoneService.isAdminOf("transportation");
    if (this.istransoprationAdmin == false) {
      this.router.navigateByUrl(`transportation`);
    }
    this.organizations = this.allinoneService.tranOrgs;
    this.orgid = this.allinoneService.selectedTranOrg
      ? this.allinoneService.selectedTranOrg
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {

    this.getVehicle();
  }


  new() {
    this.addVehicle = true;
    this.carForm.carno = "";
    this.carForm.carid = "";
    this.carForm.carname = "";
    this.carForm.maxpassenger = "";
    this.cartype = "001";
    this.carFormSave = false;

  }

  cancelFunction() {
    this.addVehicle = false;
    this.isEditing = false;
  }
  submitVehicle() {
    this.carFormSave = true;

  
    if (this.carForm.carname == "") {
      console.log("111")

      return;
    } else {
      if (this.cartype == '001') {
        console.log("ijwiefjf");

        if (this.carForm.carname == "" || this.carForm.carno == "" || this.carForm.maxpassenger == "" || this.carForm.maxpassenger == null) {
          
          if (parseInt(this.carForm.maxpassenger) == 0 || this.carForm.maxpassenger == null) {
            console.log("44444444444")
  
            this.messageService.openSnackBar("Invalid Max Passenger", 'warn');
            return;
          }
          console.log("3333333333")
          return;
        }
      }
      console.log("555555555")
      




      this.saveLoading = true;

      var data = {
        "orgid": this.orgid,
        "domainid": this.allinoneService.getDomain().domainid,
        "vehicleno": this.carForm.carno,
        "maxpassenger": this.carForm.maxpassenger.toString(),
        "vehicletype": this.cartype,
        "name": this.carForm.carname,

        // "taxi":this.checkTaxi
      }
      console.log(data);


      if (this.isEditing) {
        data = Object.assign(data,
          {
            "vehicleid": this.carForm.carid,
          }
        );
      }

      if (this.isEditing == true) {
        this.kunyekService.updateVehicle(data).subscribe(
          (res: any) => {

            if (res.returncode == '300') {
              this.addVehicle = false;
              this.saveLoading = false;
              this.isEditing = false;
              this.getVehicle();
            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
          }
        );
      } else {
        this.kunyekService.addVehicle(data).subscribe(
          (res: any) => {

            if (res.returncode == '300') {
              this.addVehicle = false;
              this.isEditing = false;
              this.saveLoading = false;
              this.getVehicle();
            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
          }
        );
      }

    }

  }


  getVehicle() {
    this.gettingData = true;
    this.vehicleList = [];
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getVehicle(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          res.datalist.map((item: any) => {
            this.vehicleList.push({
              "carid": item.vehicleid,
              "carno": item.vehicleno,
              "maxpassenger": item.maxpassenger,
              "carname": item.name,
              "type": item.vehicletype,
              "typename":item.vehicletype == "001" ? "Car" : "Taxi",
            })
          });
          this.gettingData = false;
        } else {

          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingData = false;
      }
    );
  }


  customerSelect(data: any) {
    console.log(data);


  }

  async vehicleEdit(data: any) {
    this.isEditing = true;
    this.carForm.carid = data.carid;
    this.carForm.carno = data.carno;
    this.carForm.maxpassenger = data.maxpassenger;
    this.carForm.carname = data.carname;
    this.cartype = data.type;


  }

  refreshgetData() {
    this.getVehicle();
  }


  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    this.refreshgetData();
  }


  sortData(sort: Sort) {
    const data = this.vehicleList;
    if (!sort.active || sort.direction === '') {
      this.vehicleList = data;
    }

    this.vehicleList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'carno':
          return this.allinoneService.compare(
            a.carno.toLocaleLowerCase(),
            b.carno.toLocaleLowerCase(),
            isAsc
          );
        case 'type':
          return this.allinoneService.compare(
            a.type.toLocaleLowerCase(),
            b.type.toLocaleLowerCase(),
            isAsc
          );
        case 'carname':
          return this.allinoneService.compare(
            a.carname.toLocaleLowerCase(),
            b.carname.toLocaleLowerCase(),
            isAsc
          );
        case 'maxpassenger':
          return this.allinoneService.compare(
            parseInt(a.maxpassenger),
            parseInt(b.maxpassenger),
            isAsc
          );
        default:
          return 0;
      }

    });

  }

  downloadSheet() {
    var vehicleExport = this.filter.transform(
      this.vehicleList,
      this.searchText,
      "pickup"
    );

    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Vehicle' + time + '.xlsx';
    vehicleExport.map((veh: any) => {
      data.push({
        "Car No.": veh.carno,
        "Name": veh.carname,
        "Type": veh.type == '001' ? "Car" : "Taxi",
        "Max Passenger": veh.maxpassenger,
      });
    });

    this.allinoneService.exportEcecl(data, name);

  }



  selectcartype(data: any) {
    console.log(data.target.value);
    this.cartype = data.target.value;
    if (data.target.value == "002") {
      this.carForm.carno = "";
      this.carForm.maxpassenger = "";

    }

    this.carFormSave = false;

  }
}
