import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent implements OnInit {

  isOpenForm: boolean = false;
  isEditing: boolean = false;

  memberList: any[] = [];
  searchName: string = '';

  currentMember: any

  action: string = '';

  subscription!: Subscription;
  gettingMemberList: boolean = true;

  constructor(
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public allInOneService: AllInOneService
  ) { }

  ngOnInit(): void {
    this.getMemberList();
  }

  newMember() {
    this.isOpenForm = true;
    this.action = 'Add User'
  }

  closeMemberForm() {
    this.isOpenForm = false;
    this.searchByName()
  }

  getMemberList() {
    const data = {
      searchName: this.searchName,
      paymentstatus: "true",
      formstatus: "002"
    }
    this.subscription = this.kunyekService.getRegisteredMember(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.memberList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingMemberList = false;
      },
      (err) => {
        this.gettingMemberList = false;
      }
    );
  }

  searchByName() {
    this.gettingMemberList = true
    this.getMemberList();
  }

  editMember(member: any) {
    this.currentMember = member
    this.action = 'View'
    this.isOpenForm = true
  }

  viewMember(member: any) {
    this.currentMember = member
    this.action = 'View'
    this.isOpenForm = true
  }

  deleteMember(id: any) {
    this.kunyekService.deleteKMember({ userid: id }).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res)
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingMemberList = false;
      },
      (err) => {
        this.gettingMemberList = false;
      }
    );
  }

}
