import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-dashboard',
  templateUrl: './office-dashboard.component.html',
  styleUrls: ['./office-dashboard.component.scss'],
})
export class OfficeDashboardComponent implements OnInit, OnDestroy {
  isAttendanceView: boolean = false;
  gettingApprovalCount: boolean = false;
  gettingRequisitionCount: boolean = false;
  gettingHrRequestApprovalCount: boolean = false;
  isAdmin: boolean = false;
  approvalCount: number = 0;
  requestCount: number = 0;

  lateCount: number = 0;
  earlyOutCount: number = 0;

  workFromHomeCount: number = 0;
  offInLieuCount: number = 0;

  claimCount: number = 0;

  requisitionRequestCount: number = 0;
  requisitionApprovalCount: number = 0;

  hrRequestCount: number = 0;
  hrApprovalCount: number = 0;

  orgid: string = '';
  domainid: string = '';

  roleList: any = [];
  officePermissionList: any = ['001', '000', '012', '013', '014', '015', '016', '017', '018', '019', '020', '021'];
  currentRole = '';

  subscriptions = new SubSink();

  // menu = [
  //   // 014 requisition request, 015 requisition approval
  //   {
  //     id: '012',
  //     name: 'Leave',
  //     path: '/office/leave',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/leave.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '013',
  //     name: 'Claim',
  //     path: '/office/claim',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/claim.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '014',
  //     name: 'Late',
  //     path: '/office/late',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/late-request.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '015',
  //     name: 'Early Out',
  //     path: '/office/earlyout',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/earlyout-request.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '000',
  //     name: 'Requests',
  //     path: '/office/request',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/requests.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '001',
  //     name: 'Approval',
  //     path: '/office/approval',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/approval.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '002',
  //     name: 'Request Type',
  //     path: '/office/requesttype',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/request_type.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '003',
  //     name: 'Leave Calendar',
  //     path: '/office/leavecalendar',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/leave.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '004',
  //     name: 'Leave Template',
  //     path: '/office/leavetemplate',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/template.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '005',
  //     name: 'Leave Entitlement',
  //     path: '/office/leaveentitlement',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/entitlement.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '006',
  //     name: 'Leave Opening',
  //     path: '/office/leaveopening',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/leave_opening.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '007',
  //     name: 'Holiday',
  //     path: '/office/holiday',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/holiday.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '008',
  //     name: 'Working Hour',
  //     path: '/office/workinghour',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/working_hours.png',
  //     enabled: true,
  //   },
  //   // {
  //   //   id: '009',
  //   //   name: 'Leave Summary',
  //   //   path: '/office/leave-summary-report',
  //   //   icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/leave-summary-report.png',
  //   //   enabled: true,
  //   // },
  //   {
  //     id: '010',
  //     name: 'Service Years',
  //     path: '/office/serviceyears',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/anniversary.png',
  //     enabled: true,
  //   },
  //   {
  //     id: '011',
  //     name: 'Calculate Leave',
  //     path: '/office/calculateleave',
  //     icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/calculateleave.png',
  //     enabled: true,
  //   },
  // ];

  menu: any = [];

  constructor(
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.isAttendanceView = this.allinoneService.isAttendanceView;
    this.menu = JSON.parse(JSON.stringify(this.allinoneService.officePolicies));
    console.log('menu', this.menu);
    if (this.isAttendanceView) {
      this.isAdmin = false;
      this.allinoneService.isOfficeView = true;
      this.officePermissionList = ['012', '001'];
    } else {
      this.activatedRoute.data.subscribe((data) => {
        if (data.role == 'admin') {
          if (!this.allinoneService.isAdminOf('org')) {
            this.router.navigateByUrl('/home');
          }
          this.isAdmin = true;
        } else {
          this.allinoneService.isOfficeView = true;
          this.roleList = this.allinoneService.officePermissionList;
          if (this.roleList.length > 0) {
            var selectedRole = this.allinoneService.getSelectedRole();
            this.currentRole = selectedRole
              ? selectedRole
              : this.roleList[0].roleid;
            this.officePermissionList.push.apply(
              this.officePermissionList,
              this.roleList.find((x: any) => x.roleid == this.currentRole)
                .permission
            );
          }
          if (this.allinoneService.orgsCRm.length == 0) {
            this.officePermissionList = [];
          }
        }
      });
      var tempList = this.allinoneService.requisitionList;
      console.log('Your temp list');
      console.log(tempList);

      if (tempList.length != 0) {
        for (var i = 0; i < tempList.length; i++) {
          this.menu.push(tempList[i]);
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.domainid = this.allinoneService.getDomain().domainid;
    if (this.isAdmin) {
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.allinoneService.orgs[0].orgid;
      this.getApprovalCount();
      // ***
      this.getRequisitionCount();
    } else {
      if (this.allinoneService.orgsCRm.length > 0) {
        this.orgid = this.allinoneService.selected_orgcrm
          ? this.allinoneService.selected_orgcrm.orgid
          : this.allinoneService.orgsCRm[0].orgid;
        this.getApprovalCount();
        if (!this.isAttendanceView) {
          this.getRequisitionCount();
        }
      }
    }
    this.getHrRequestApprovalCount();
    console.log('menu', this.menu)
  }

  getApprovalCount() {
    this.gettingApprovalCount = true;
    var data = {
      domainid: this.domainid,
      orgid: this.orgid,
      roleid: this.allinoneService.getSelectedRole(),
      byadmin: this.isAdmin,
    };
    this.subscriptions.sink = this.kunyekService
      .getApprovalCount(data)
      .subscribe((res: any) => {
        if (res.returncode == '300') {
          this.approvalCount = res.approval;
          this.requestCount = res.request;
          this.lateCount = res.late;
          this.earlyOutCount = res.earlyout;
          this.workFromHomeCount = res.workfromhome;
          this.offInLieuCount = res.offinlieu || 0;
          this.claimCount = res.claim || 0;
          this.gettingApprovalCount = false;
        }
      });
  }

  t() {
    console.log(this.hrApprovalCount);
    console.log(this.hrRequestCount);
    
  }

  getHrRequestApprovalCount() {
    this.gettingHrRequestApprovalCount = true;

    var data = {
      orgid: this.orgid,
      hradmin : false,
      orgadmin : false,
    };
    this.subscriptions.sink = this.kunyekService
      .getHrCount(data).subscribe((res: any) => {
        if (res.returncode == '300') {
          this.hrApprovalCount = res.approvalcount;
          this.hrRequestCount = res.requestcount;
          this.gettingHrRequestApprovalCount = false;
        }
      });
  }

  getRequisitionCount() {
    this.gettingRequisitionCount = true;
    var data = {
      orgid: this.orgid,
      role: ''
    };
    this.subscriptions.sink = this.kunyekService
      .getRequisitionCount(data)
      .subscribe((res: any) => {
        if (res.returncode == '300') {
          this.requisitionApprovalCount = res.approvalcounts;
          this.requisitionRequestCount = res.requestcounts;
          this.gettingRequisitionCount = false;
        }
      });
  }

  goTo(data: any) {
    if (data.enabled) {
      this.router.navigateByUrl('/adminconsole' + data.path);
    } else {
      this.messageService.openSnackBar(
        'This feature is not available yet.',
        'info'
      );
    }
  }

  changeRole() {
    this.allinoneService.setSelectedRole(this.currentRole);
    if (this.isAttendanceView) {
      this.officePermissionList = ['012', '001'];
    } else {
      this.officePermissionList = ['001', '000', '012', '013', '014', '015', '016', '017'];
    }
    this.officePermissionList.push.apply(
      this.officePermissionList,
      this.roleList.find((x: any) => x.roleid == this.currentRole).permission
    );
  }

  checkPermission(data: any) {
    if (data.enabled) {
      if (this.allinoneService.orgsCRm.length > 0) {
        if (
          data.id == '001' ||
          data.id == '000' ||
          data.id == '012' ||
          data.id == '013' ||
          data.id == '014' ||
          data.id == '015' ||
          data.id == '016' ||
          data.id == '017' ||
          data.id == '018' ||
          data.id == '019' ||
          data.id == '020' ||
          data.id == '021'
        ) {
          if (this.isAttendanceView) {
            this.router.navigateByUrl('/attendance' + data.path);
          } else {
            this.router.navigateByUrl(data.path);
          }
        } else {
          var check = this.roleList
            .find((x: any) => x.roleid == this.currentRole)
            ?.permission.includes(data.id);
          if (this.roleList.length > 0 && check) {
            this.router.navigateByUrl(data.path);
          } else {
            this.messageService.openSnackBar(
              this.allinoneService.unauthorizedAccessMessage,
              'warn'
            );
          }
        }
      } else {
        this.messageService.openSnackBar(
          this.allinoneService.unauthorizedAccessMessage,
          'warn'
        );
      }
    } else {
      this.messageService.openSnackBar(
        'This feature is not available yet.',
        'info'
      );
    }
  }
}
