<div class="title-container mb-3">
    <div class="title">
        Posts
    </div>
    <div class="close-btn">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<hr>

<div class="form-group mb-3">
    <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search" (focus)="isFocus = true"
        (blur)="isFocus = false" [(ngModel)]="typeSearch" [ngClass]="{'c-search-input-with-clear' : typeSearch }"
        style="font-family: FontAwesome,Ubuntu;" (ngModelChange)="searchChange()">
</div>


<div style="height: 400px; overflow-y: hidden;">
    <div *ngIf="gettingTypeList" class="nodata">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div>
        <div class="selectall" *ngIf="!gettingTypeList">
            <div (click)="selectAll()">
                {{!isSelectAll ? 'Select All' : 'Unselect All'}}
            </div>
        </div>
        <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
            [perfectScrollbar]="config" class="chat-container" style="height: 390px !important;position: relative;" #chatPS>
            <ul class="list-group" *ngIf="!gettingTypeList && typeList.length > 0">
                <div class="profile-container" *ngFor="let typ of typeList | filter : typeSearch:'postList' ">
                
                    <label class="d-flex align-items-center justify-content-center">
                        <div class="profile-item-container">
                            <div class="profile-item">
                                <div class="username" style="cursor: pointer;">
                                    <b>{{typ.name}}</b>
                                </div>
                            </div>
                        </div>
                        <input type="checkbox" class="form-check-input" [id]="typ.classname" [checked]="typ.checked"
                            (change)="selectType($event,typ)">
                    </label>

                </div>
            </ul>

            <div *ngIf="!gettingTypeList && typeList.length == 0"
                class="d-flex align-items-center justify-content-center" style="height: 100px; width: 100%;">
                <div>
                    Empty
                </div>
            </div>

        </div>
    </div>
</div>

<div class="close-save-button justify-content-end mt-3">
    <button type="button" class="btn btn-custom me-2" (click)="close('')">
        Cancel
    </button>
    <app-button-loader (click)="done()" [button_text]="'Done'" [load_button_text]="''"></app-button-loader>
</div>