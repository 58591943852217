import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {

  transform(items: any[], searchVal: string, propertyName: string) {
    return items.filter((item) => item[propertyName].toLowerCase().includes(searchVal.toLowerCase()));
  }

}
