<div class="d-flex align-items-center mb-3">
  <button type="button" class="btn btn-custom me-2" (click)="cancelRequest()" *ngIf="!saveLoading">
    Cancel
  </button>
  <app-button-loader (click)="submitRequest()" [button_text]="'Save'" [load_button_text]="'Saving'"
    [isLoading]="saveLoading" *ngIf="memberSelected">
  </app-button-loader>
  <!-- <button (click)="t()" >T</button> -->

  <button class="btn btn-custom ms-auto" type="button" (click)="openLeaveSummaryForm()"
    *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE'">
    <i class="fas fa-clipboard-list me-2"></i>Leave Summary
  </button>
</div>
<div class="row">
  <div class="mb-3 col-md-6">

    <label for="userid" class="form-label">User ID {{memberSelected ? '('+selectedMemberName+' - '+
      selectedMemberEID+')' : ''}}
    </label>
    <input type="text" class="form-control" id="userid" name="userid" placeholder="john@domain.com, +959********"
      [(ngModel)]="requestForm.userid" required #userid="ngModel"
      [ngClass]="{ 'is-invalid': formSubmitted && userid.invalid }" [matAutocomplete]="memberAutoComplete"
      (ngModelChange)="changeUserid()">
    <mat-autocomplete #memberAutoComplete="matAutocomplete">
      <ng-container *ngFor="let member of memberList">
        <mat-option [value]="member.userid" (click)="selectMember(member)">
          <!-- {{member.userid}}{{member.username ? ' - ' + member.username : ''}} -->
          {{member.userid}}{{ member.newuserid ? ', ' + member.newuserid : '' }}{{member.username ? ' - ' +
          member.username : ''}}
        </mat-option>
      </ng-container>

    </mat-autocomplete>

    <div class="invalid-feedback" *ngIf="userid.invalid || (userid.dirty || userid.touched) && formSubmitted">
      <div *ngIf="userid.errors?.required">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>
  <div class="mb-3 col-md-6">
    <label class="form-label" for='requesttype'> Type </label>
    <select class="form-select" id='requesttype' name='requesttype' [(ngModel)]="requestForm.requesttype"
      [disabled]="!memberSelected || gettingTemplate || requestTypeList.length == 0"
      (ngModelChange)="requestTypeChange()">
      <option *ngFor="let type of requestTypeList" [value]="type.requesttypeid">{{type.requesttypename}}</option>
    </select>
    <mat-progress-bar mode="indeterminate" *ngIf="gettingTemplate"></mat-progress-bar>
  </div>

  <!-- <div class="mb-3 col-md-6" *ngIf="claimRoleView">
    <label class="form-label" for='requesttype'> Type </label>
    <input type="text" class="form-control" id="requesttype" name="requesttype" placeholder="Employee" value="Claim"
      disabled="true" />
  </div> -->

  <ng-container
    *ngIf="requestForm.requesttype == lateid || requestForm.requesttype == earlyoutid || requestForm.requesttype == workfromhomeid || requestForm.requesttype == offinlieuid; else otherTemplate">
    <ng-container *ngIf="requestForm.requesttype == lateid || requestForm.requesttype == earlyoutid">
      <app-late-earlyout-request #lateEarlyOut [requestData]="requestForm" [isEditing]="false"
        [orgid]="requestForm.orgid" [isEarlyout]="requestForm.requesttype == earlyoutid" [approverList]="approverList"
        (save)="submitLateEarlyOut($event)"></app-late-earlyout-request>
    </ng-container>
    <ng-container *ngIf="requestForm.requesttype == workfromhomeid">
      <app-work-from-home-request #workFromHome [requestData]="requestForm" [isEditing]="false"
        [userid]="requestForm.userid" [orgid]="requestForm.orgid" [approverList]="approverList"
        (save)="submitWorkFromHome($event)"></app-work-from-home-request>
    </ng-container>
    <ng-container *ngIf="requestForm.requesttype == offinlieuid">
      <app-off-in-lieu-request #offInLieu [requestData]="requestForm" [isEditing]="false" [userid]="requestForm.userid"
        [orgid]="requestForm.orgid" [approverList]="approverList"
        (save)="submitOffInLieu($event)"></app-off-in-lieu-request>
    </ng-container>
  </ng-container>
  <ng-template #otherTemplate>
    <div class="mb-3 col-md-12"
      *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE' || requestForm.requesttype == this.claimid || requestForm.requesttype == this.reservationid || requestForm.requesttype == this.transportationid || requestForm.requesttype == this.hrid">
      <label class="form-label" for="subtype">
        {{ requestForm.requesttype == "NCMLEIWHRNVIE" ? "Leave" : requestForm.requesttype == this.claimid ?
        "Claim" : requestForm.requesttype == this.transportationid ? "Transportation" : requestForm.requesttype ==
        this.hrid ? 'HR' : "Reservation"}}
        Types
      </label>
      <select class="form-select" id='subtype' name='subtype' [(ngModel)]="requestForm.subtype"
        [disabled]="requestSubTypeList.length == 0" (ngModelChange)="getTranType(requestForm.subtype)">
        <option *ngFor="let type of requestSubTypeList;" [value]="type.requesttypeid">{{type.requesttypename}}</option>
      </select>
    </div>
    <div class="mb-3 col-md-12" *ngIf="requestForm.requesttype == transportationid && requestForm.trantype== '001'">
      <label class="form-label" for="pickuppoint">Pick Up Points
      </label>
      <select class="form-select" id="pickuppoint" name="pickuppoint" [(ngModel)]="requestForm.pickuppoint"
        [disabled]="gettingPickUp">
        <option value="" selected>-</option>
        <option *ngFor="let type of pickupList" [value]="type.pickuppointid">
          {{ type.name }}
        </option>
      </select>
      <mat-progress-bar mode="indeterminate" *ngIf="gettingPickUp"></mat-progress-bar>
    </div>
    <ng-container *ngIf="requestForm.requesttype == 'VMAOWEFAMSVNAOWI'">
      <div class="mb-3 col-md-6">
        <label for="startdate" class="form-label">Date</label>
        <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate" name="startdate"
          [disabled]="requestForm.readonly" max="9999-12-31" />
      </div>
      <div class="mb-3 col-md-6">
        <label for="starttime" class="form-label">Time</label>
        <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime" name="starttime"
          [disabled]="requestForm.readonly" />
      </div>
    </ng-container>
    <ng-container *ngIf="(requestForm.requesttype == this.reservationid && requestForm.reservationtype == '001')">
      <div class="mb-3 col-md-6">
        <label for="startdate" class="form-label">Date</label>
        <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate" name="startdate"
          [disabled]="requestForm.readonly" max="9999-12-31" (ngModelChange)="getAvailableRoomAndDesk()" />
      </div>
      <div class="mb-3 col-md-3">
        <label for="starttime" class="form-label">Start Time</label>
        <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime" name="starttime"
          [disabled]="requestForm.readonly" (ngModelChange)="getAvailableRoomAndDesk()" />
      </div>
      <div class="mb-3 col-md-3">
        <label for="endtime" class="form-label">End Time</label>
        <input class="form-control" type="time" id="endtime" [(ngModel)]="requestForm.endtime" name="endtime"
          [disabled]="requestForm.readonly" (ngModelChange)="getAvailableRoomAndDesk()" />
      </div>
      <div class="mb-3 col-md-6" *ngIf="!requestForm.readonly">
        <label for="room" class="form-label">Room</label>
        <select [(ngModel)]="requestForm.roomid" class="form-select" name="room" id="room"
          [disabled]="requestForm.readonly || gettingAvailableRoomAndDesk" (ngModelChange)="roomChange()">
          <option value="">-</option>
          <option *ngFor="let item of availableRoom" [value]="item.roomid">
            {{ item.name }}
          </option>
        </select>
        <mat-progress-bar mode="indeterminate" color="primary" *ngIf="gettingAvailableRoomAndDesk"></mat-progress-bar>
      </div>
      <div class="mb-3 col-md-6" *ngIf="requestForm.readonly">
        <label for="room" class="form-label">Room</label>
        <input class="form-control" type="text" id="room" [(ngModel)]="requestForm.roomname" name="room" disabled />
      </div>
      <div class="mb-3 col-md-6">
        <label for="maxpeople" class="form-label">Max No. of People</label>
        <input class="form-control" type="text" id="maxpeople" [(ngModel)]="requestForm.maxpeople" name="maxpeople"
          disabled />
      </div>
    </ng-container>
    <ng-container *ngIf="(requestForm.requesttype == this.reservationid && requestForm.reservationtype == '002')">
      <div class="mb-3 col-md-3">
        <label for="startdate" class="form-label">Start Date</label>
        <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate" name="startdate"
          [disabled]="requestForm.readonly" max="9999-12-31" (ngModelChange)="getAvailableRoomAndDesk()" />
      </div>
      <div class="mb-3 col-md-3">
        <label for="starttime" class="form-label">Start Time</label>
        <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime" name="starttime"
          [disabled]="requestForm.readonly" (ngModelChange)="getAvailableRoomAndDesk()" />
      </div>
      <div class="mb-3 col-md-3">
        <label for="enddate" class="form-label">End Date</label>
        <input class="form-control" type="date" id="enddate" [(ngModel)]="requestForm.enddate" name="enddate"
          [disabled]="requestForm.readonly" max="9999-12-31" (ngModelChange)="getAvailableRoomAndDesk()" />
      </div>
      <div class="mb-3 col-md-3">
        <label for="endtime" class="form-label">End Time</label>
        <input class="form-control" type="time" id="endtime" [(ngModel)]="requestForm.endtime" name="endtime"
          [disabled]="requestForm.readonly" (ngModelChange)="getAvailableRoomAndDesk()" />
      </div>
      <div class="mb-3 col-md-6">
        <label for="room" class="form-label">Floor</label>
        <select [(ngModel)]="requestForm.workstationid" class="form-select" name="workstation" id="workstation"
          [disabled]="requestForm.readonly || gettingWorkstation" (ngModelChange)="workstationChange()">
        <option value="">-</option>
          <option *ngFor="let item of workstationList" [value]="item.workstationid">
            {{ item.floorname }}
          </option>
        </select>
        <mat-progress-bar mode="indeterminate" color="primary" *ngIf="gettingWorkstation"></mat-progress-bar>
      </div>
      <div class="mb-3 col-md-6" *ngIf="!requestForm.readonly">
        <label for="desk" class="form-label">Desk</label>
        <select [(ngModel)]="requestForm.deskid" class="form-select" name="desk" id="desk"
          [disabled]="requestForm.readonly || gettingAvailableRoomAndDesk">
          <option value="">-</option>
          <option *ngFor="let item of availableDesk" [value]="item">
            {{ item }}
          </option>
        </select>
        <mat-progress-bar mode="indeterminate" color="primary" *ngIf="gettingAvailableRoomAndDesk"></mat-progress-bar>
      </div>
      <div class="mb-3 col-md-6" *ngIf="requestForm.readonly">
        <label for="desk" class="form-label">Desk</label>
        <input class="form-control" type="text" id="desk" [(ngModel)]="requestForm.deskid" name="desk" disabled />
      </div>
    </ng-container>
    <ng-container
      *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE' || requestForm.requesttype == 'UEYVNDLOAPQWXQW' || requestForm.requesttype == this.transportationid ">
      <div class="mb-3"
        [ngClass]="{'col-md-4' : requestForm.requesttype == 'NCMLEIWHRNVIE', 'col-md-3': requestForm.requesttype == 'UEYVNDLOAPQWXQW', 'col-md-6' : requestForm.requesttype == this.transportationid }">
        <label for="startdate" class="form-label">Start Date</label>
        <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate" name="startdate"
          (ngModelChange)="calculateDuration()" max="9999-12-31" />
      </div>
      <div class="mb-3 col-md-2" *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE'">
        <label for="starttime" class="form-label">Start Time</label>
        <select [(ngModel)]="requestForm.starttimeampm" class="form-select" name="starttime" id="starttime"
          (ngModelChange)="calculateDuration()">
          <option *ngFor="let item of timeList" [value]="item">{{item}}</option>
        </select>
      </div>
      <div class="mb-3 col-md-3" *ngIf="requestForm.requesttype == 'UEYVNDLOAPQWXQW'">
        <label for="starttime" class="form-label">Start Time</label>
        <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime" name="starttime"
          (ngModelChange)="calculateDuration()" />
      </div>
      <div class="mb-3"
        [ngClass]="{'col-md-4' : requestForm.requesttype == 'NCMLEIWHRNVIE', 'col-md-3': requestForm.requesttype == 'UEYVNDLOAPQWXQW', 'col-md-6' : requestForm.requesttype == this.transportationid}">
        <label for="enddate" class="form-label">End Date</label>
        <input class="form-control" type="date" id="enddate" [min]="requestForm.startdate"
          [(ngModel)]="requestForm.enddate" name="enddate" max="9999-12-31" (ngModelChange)="calculateDuration()" />
      </div>
      <div class="mb-3 col-md-2" *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE'">
        <label for="startdate" class="form-label">End Time</label>
        <select [(ngModel)]="requestForm.endtimeampm" class="form-select" (ngModelChange)="calculateDuration(true)">
          <option *ngFor="let item of timeList" [value]="item">{{item}}</option>
        </select>
      </div>
      <div class="mb-3 col-md-3" *ngIf="requestForm.requesttype == 'UEYVNDLOAPQWXQW' ">
        <label for="endtime" class="form-label">End Time</label>
        <input class="form-control" type="time" id="endtime" [(ngModel)]="requestForm.endtime" name="endtime"
          (ngModelChange)="calculateDuration(true)" />
      </div>
      <div class="mb-3 col-md-12" *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE'">
        <label for="duration" class="form-label">Duration</label>
        <input class="form-control" type="text" id="duration" [(ngModel)]="requestForm.duration" name="duration"
          disabled />
        <mat-progress-bar mode="indeterminate" color="primary" *ngIf="gettingDuration"></mat-progress-bar>
      </div>
      <div class="mb-3 col-md-12" *ngIf="requestForm.requesttype == 'UEYVNDLOAPQWXQW'">
        <label for="overtimehour" class="form-label">Hour</label>
        <input class="form-control" type="text" id="overtimehour" [(ngModel)]="requestForm.overtimehour"
          name="overtimehour" disabled />
      </div>
    </ng-container>
    <ng-container *ngIf="requestForm.requesttype == this.claimid">
      <div class="mb-3 col-md-6">
        <label for="startdate" class="form-label">Date</label>
        <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate" max="9999-12-31"
          name="startdate" />
      </div>
      <div class="mb-3 col-md-4">
        <label for="amount" class="form-label">Amount</label>
        <input class="form-control" name="amount" id="amount" type="number" [(ngModel)]="requestForm.amount" [min]="0"
          (keypress)="$event.charCode != 45">
      </div>
      <div class="mb-3 col-md-2">
        <label for="currency" class="form-label">Currency</label>
        <select [(ngModel)]="requestForm.currency" class="form-select" name="currency" id="currency">
          <option *ngFor="let item of currencyList" [value]="item.currencyid">{{item.currencyname}}</option>
        </select>
      </div>
      <ng-container *ngIf="requestForm.taxifare">
        <div class="mb-3 col-md-6">
          <label for="claimfrom" class="form-label">From Place</label>
          <input class="form-control" type="text" id="claimfrom" name="claimfrom"
            [(ngModel)]="requestForm.claimfromplace" [disabled]="requestForm.readonly" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="claimto" class="form-label">To Place</label>
          <input class="form-control" type="text" id="claimto" name="claimto" [(ngModel)]="requestForm.claimtoplace"
            [disabled]="requestForm.readonly" />
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="requestForm.requesttype == travelrequestid">
      <div class="mb-3 col-md-3">
        <label for="startdate" class="form-label">Departure Date</label>
        <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate" name="startdate"
          (ngModelChange)="calculateDuration()" max="9999-12-31" />
      </div>
      <div class="mb-3 col-md-3">
        <label for="starttime" class="form-label">Departure Time</label>
        <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime" name="starttime" />
      </div>
      <div class="mb-3 col-md-3">
        <label for="enddate" class="form-label">Arrival Date</label>
        <input class="form-control" type="date" id="enddate" [min]="requestForm.startdate"
          [(ngModel)]="requestForm.enddate" name="enddate" max="9999-12-31" (ngModelChange)="calculateDuration()" />
      </div>
      <div class="mb-3 col-md-3">
        <label for="endtime" class="form-label">Planned Return</label>
        <input class="form-control" type="time" id="endtime" [(ngModel)]="requestForm.endtime" name="endtime" />
      </div>
      <div class="mb-3 col-md-12">
        <label for="travelduration" class="form-label">Days</label>
        <input class="form-control" type="text" id="duration" [(ngModel)]="requestForm.travelduration"
          name="travelduration" disabled />
      </div>
      <div class="mb-3 col-md-6">
        <ng-container *ngIf="!requestForm.readonly">
          <label for="product" class="form-label">Product</label>
          <ng-select class="role-select" [items]="productListdata" bindLabel="name" bindValue="productid"
            placeholder="-" appendTo="body" [multiple]="false" [(ngModel)]="requestForm.product"
            (ngModelChange)="productChange()">
          </ng-select>
        </ng-container>
        <ng-container *ngIf="requestForm.readonly">
          <label for="product" class="form-label">Product</label>
          <input class="form-control" type="text" id="product" [(ngModel)]='requestForm.productname' name="product"
            disabled />
        </ng-container>
      </div>

      <div class="mb-3 col-md-6">
        <ng-container *ngIf="!requestForm.readonly">
          <label for="Project" class="form-label">Project</label>
          <ng-select class="role-select" [items]="projectList" bindLabel="name" bindValue="projectid" placeholder="-"
            appendTo="body" [multiple]="false" [(ngModel)]="requestForm.project" (ngModelChange)="projectChange()">
          </ng-select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingProjectList"></mat-progress-bar>
        </ng-container>
        <ng-container *ngIf="requestForm.readonly">
          <label for="project" class="form-label">Project</label>
          <input class="form-control" type="text" id="project" [(ngModel)]='requestForm.projectname' name="project"
            disabled />
        </ng-container>
      </div>

      <div class="mb-3 col-md-6">
        <label for="amount" class="form-label">Estimated Budget</label>
        <input class="form-control" name="amount" id="amount" type="number" [(ngModel)]="requestForm.amount" [min]="0"
          (keypress)="($event.charCode != 45)" [disabled]="requestForm.readonly" />
      </div>
      <div class="mb-3 col-md-6">
        <label for="currency" class="form-label">Currency</label>
        <select [(ngModel)]="requestForm.currency" class="form-select" name="currency" id="currency"
          [disabled]="requestForm.readonly">
          <option *ngFor="let item of currencyList" [value]="item.currencyid">
            {{ item.currencyname }}
          </option>
        </select>
      </div>

      <div class="mb-3 col-md-6">
        <label for="modeoftravel" class="form-label">Mode of Travel</label>
        <ng-select class="role-select" [items]="modeoftravel" bindLabel="value" bindValue="key" appendTo="body"
          [multiple]="true" [(ngModel)]="requestForm.modeoftravel">
        </ng-select>
      </div>
      <div class="mb-3 col-md-6">
        <label for="vehicleuse" class="form-label">Vehicle Use</label>
        <ng-select class="role-select" [items]="usevehicle" bindLabel="value" bindValue="key" appendTo="body"
          [multiple]="true" [(ngModel)]="requestForm.vehicleuse">
        </ng-select>
      </div>
    </ng-container>

    <!-- Star Cash Advanced -->
    <ng-container *ngIf="requestForm.requesttype == cashadvancedid">
      <div class="mb-3 col-md-6">
        <label for="startdate" class="form-label">Date</label>
        <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate" max="9999-12-31"
          name="startdate" />
      </div>
      <div class="mb-3 col-md-4">
        <label for="amount" class="form-label">Amount</label>
        <input class="form-control" name="amount" id="amount" type="number" [(ngModel)]="requestForm.amount" [min]="0"
          (keypress)="$event.charCode != 45">
      </div>
      <div class="mb-3 col-md-2">
        <label for="currency" class="form-label">Currency</label>
        <select [(ngModel)]="requestForm.currency" class="form-select" name="currency" id="currency">
          <option *ngFor="let item of currencyList" [value]="item.currencyid">{{item.currencyname}}</option>
        </select>
      </div>
    </ng-container>
    <!-- End Advanced -->

    <!-- Start Transportation -->
    <ng-container *ngIf="requestForm.requesttype == this.transportationid">
      <div class="row">
        <div class="d-flex flex-wrap align-items-center">
          <ng-container *ngFor="let day of weekDay;let i = index;">
            <div class="form-check mb-3 me-5"
              [ngClass]="{'red-checkbox': day.name == 'Sunday' || day.name == 'Saturday'}">
              <input class="form-check-input" type="checkbox" [attr.name]="day.name" [attr.id]="day.name"
                [disabled]="requestForm.readonly" (change)="addWeekDay($event, day.value)"
                [checked]="requestForm.weekday?.includes(day.value)">
              <label class="form-check-label" [attr.for]="day.name"> {{day.name}}</label>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="requestForm.trantype== '002'">
        <div class="mb-3 col-md-12">
          <label for="toplace" class="form-label">To Place</label>
          <input class="form-control" type="text" id="toplace" name="toplace" [(ngModel)]="requestForm.toplace" />
        </div>
        <div class="d-flex align-items-center align-items-center-custom justify-content-start w-100">
          <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="going" name="going"
              [(ngModel)]="requestForm.isgoing"> <label class="form-check-label ms-1" for="going"> Going
            </label> </div>
          <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="return" name="return"
              [(ngModel)]="requestForm.isreturn"> <label class="form-check-label ms-1" for="return"> Return
            </label> </div>
        </div>
        <ng-container *ngIf="requestForm.isgoing">
          <div class="col-md-6 mb-3" [ngClass]="{
          'col-md-12' : !requestForm.isgoing || !requestForm.isreturn
        }">
            <label for="arrivetime" class="form-label">Arrive Time</label>
            <input class="form-control" type="time" id="arrivetime" name="arrivetime"
              [(ngModel)]="requestForm.arrivetime" />
          </div>
        </ng-container>
        <ng-container *ngIf="requestForm.isreturn">
          <div class="col-md-6 mb-3" [ngClass]="{
          'col-md-12' : !requestForm.isgoing || !requestForm.isreturn
        }">
            <label for="returntime" class="form-label">Return Time</label>
            <input class="form-control" type="time" id="returntime" name="returntime"
              [(ngModel)]="requestForm.returntime" />
          </div>
        </ng-container>
        <div class="mb-3 col-md-6">
          <label for="dailyfrom" class="form-label">Pick Up Place</label>
          <input class="form-control" type="text" id="dailyfrom" name="dailyfrom" [(ngModel)]="requestForm.dailyfrom" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="dailyto" class="form-label">Drop Off Place</label>
          <input class="form-control" type="text" id="dailyto" name="dailyto" [(ngModel)]="requestForm.dailyto" />
        </div>
      </ng-container>

    </ng-container>
    <!-- End Transportation -->

    <!-- Start HR -->
    <ng-container *ngIf="requestForm.requesttype == this.hrid">
      <div class="mb-3 col-md-6">
        <label for="hrrequesttype" class="form-label">Request Type</label>
        <select class="form-select" name="hrrequesttype" id="hrrequesttype" [(ngModel)]="requestForm.hrrequesttype">
          <option *ngFor="let d of HRRequestTypeList" [value]="d.id">
            {{ d.name }}
          </option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingHRRequestType"></mat-progress-bar>
      </div>

      <div class="mb-3 col-md-6">
        <label for="hrrequestdate" class="form-label">
          Request Date
        </label>
        <input class="form-control" type="date" id="hrrequestdate" [(ngModel)]="requestForm.hrrequestdate"
          name="hrrequestdate" max="9999-12-31" />
      </div>
    </ng-container>
    <!-- End HR -->

    <ng-container *ngIf="requestForm.requesttype">
      <div class="mb-3 col-md-12">
        <label for="remark" class="form-label">{{requestForm.requesttype == travelrequestid ? 'Travel Purpose' :
          'Remark'}}</label>
        <textarea class="form-control" [(ngModel)]="requestForm.remark" rows="3"></textarea>
      </div>
      <div class="mb-3 col-md-12 d-flex" *ngIf="requestForm.requesttype != hrid">
        <button *ngIf="requestForm.requesttype == travelrequestid" class="btn btn-custom me-2"
          (click)="chooseAccompanyPerson()" [disabled]="requestForm.readonly">
          Accompany Person
        </button>
        <ng-container
          *ngIf="requestForm.requesttype != 'UEYVNDLOAPQWXQW'  && requestForm.requesttype != 'VMAOWEFAMSVNAOWI' && requestForm.requesttype != this.reservationid && requestForm.requesttype != this.transportationid ">
          <input hidden type="file" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput multiple>
          <button class="btn btn-custom me-2" (click)="hiddenfileinput.click()">
            <i class="fas fa-paperclip me-2"></i> Attachments
          </button>
        </ng-container>
        <button class="btn btn-custom me-2" (click)="chooseUser()"
          *ngIf="(requestForm.requesttype == this.transportationid && requestForm.trantype == '002') || (requestForm.requesttype != this.transportationid && requestForm.requesttype != this.reservationid)">
          {{ requestForm.requesttype != this.transportationid ? 'Select Approvers' : 'Select Members' }}
        </button>
      </div>
      <div class="col-md-12 row" *ngIf="requestForm.requesttype != 'UEYVNDLOAPQWXQW'">
        <div class="img-container col-lg-3 col-md-4 col-sm-4 col-6" *ngFor="let item of imageList; let index = index">
          <div class="img-border card">
            <img [src]="item.previewurl" (click)="allinoneService.imageViewer(imageList, index)">
            <mat-icon class="close" (click)="removeImage(index)">close</mat-icon>
          </div>
        </div>
      </div>

      <ng-container *ngIf="accompanyPersonList.length > 0 && requestForm.requesttype == this.travelrequestid">
        <!-- <div class="col-md-12 mb-3"> -->
        <div class="col-md-6 mb-3">
          <h3> Selected Accompany Person ({{ accompanyPersonList.length }})</h3>
          <div class="list-group">
            <!-- <div class="profile-container d-flex col-md-4" *ngFor="let user of accompanyPersonList"> -->
            <div class="profile-container d-flex" *ngFor="let user of accompanyPersonList">
              <span>
                <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="
                  user.photo || user.image || '../../../assets/images/profile.png'
                " />
              </span>

              <label class="d-flex align-items-center align-items-center-custom justify-content-center w-100">
                <div class="profile-item-container">
                  <div class="profile-item">
                    <div class="username" style="cursor: pointer">
                      <b>{{ user.username || user.userid }}</b>
                    </div>
                    <div style="color: grey; margin-top: 1px; font-size: 13.5px" *ngIf="user.username != ''">
                      {{ user.userid }}
                    </div>
                  </div>
                </div>
                <input type="checkbox" class="form-check-input" [id]="user.primarykey" [(ngModel)]="user.checked"
                  [checked]="user.checked" [disabled]="requestForm.readonly" />
              </label>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="approverList.length > 0 && requestForm.requesttype != this.transportationid">
        <div class="col-md-6">
          <h3> Selected Approvers ({{ approverList.length }})</h3>
          <div class="list-group">
            <div class="profile-container d-flex" *ngFor="let user of approverList">
              <span>
                <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="
                  user.photo || user.image || '../../../assets/images/profile.png'
                " />
              </span>

              <label class="d-flex align-items-center align-items-center-custom justify-content-center w-100">
                <div class="profile-item-container">
                  <div class="profile-item">
                    <div class="username" style="cursor: pointer">
                      <b>{{ user.username || user.userid }}</b>
                    </div>
                    <div style="color: grey; margin-top: 1px; font-size: 13.5px" *ngIf="user.username != ''">
                      {{ user.userid }}
                    </div>
                  </div>
                </div>
                <input type="checkbox" class="form-check-input" [id]="user.primarykey" [(ngModel)]="user.checked"
                  [checked]="user.checked" [disabled]="requestForm.readonly" />
              </label>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="approverList.length > 0 && requestForm.requesttype == this.transportationid && requestForm.trantype == '002'">
        <div class="col-md-12">
          <h3> Selected Members ({{ approverList.length }})</h3>
          <div class="row">
            <!-- <div class="list-group"> -->
            <ng-container *ngFor="let user of approverList">
              <div class="col-md-4">
                <div class="card p-3 my-card">
                  <div class="d-flex align-items-center">
                    <span>
                      <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="
                              user.photo || user.image || '../../../assets/images/profile.png'
                            " />
                    </span>

                    <label class="d-flex align-items-center justify-content-between w-100">
                      <div class="profile-item-container">
                        <div class="profile-item">
                          <div class="username" style="cursor: pointer">
                            <b>{{ user.username || user.userid }}</b>
                          </div>
                          <div style="color: grey; margin-top: 1px; font-size: 13.5px" *ngIf="user.username != ''">
                            {{ user.userid }}
                          </div>
                        </div>
                      </div>
                      <div>
                        <input type="checkbox" class="form-check-input" [id]="user.primarykey"
                          [(ngModel)]="user.checked" [checked]="user.checked" [disabled]="requestForm.readonly" />
                      </div>
                    </label>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <input class="form-control" type="text" [id]="'from-'+user.userid" [name]="'from-'+user.userid"
                        [(ngModel)]="user.from" placeholder="Pick Up Place" />
                    </div>
                    <div class="col-md-6">
                      <input class="form-control" type="text" [id]="'to-'+user.userid" [name]="'to-'+user.userid"
                        [(ngModel)]="user.to" placeholder="Drop Off Place" />
                    </div>
                  </div>
                </div>
              </div>


            </ng-container>

          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>

</div>