import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckInProjectDaterangeComponent } from 'src/app/admin/check-in-project-daterange/check-in-project-daterange.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-project-report',
  templateUrl: './project-report.component.html',
  styleUrls: ['./project-report.component.css'],
})
export class ProjectReportComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  gettingProjects: boolean = false;
  gettingJobs: boolean = false;
  formSubmitted: boolean = false;
  isMobileView: boolean = false;
  isFocusPJ: boolean = false;
  gettingProjData: boolean = false;
  searchLoading: boolean = false;

  projects: any[] = [];
  jobs: any[] = [];
  projData: any[] = [];
  organizations: any[] = [];

  searchTextPJ: any = '';
  projStartDate: any = '';
  projEndDate: any = '';

  mobileViewWidth: number = 426;
  itemPerPagePJ: number = 100;
  q: number = 1;
  formatToShow = 'yyyy-MM-dd';
  tasktype = 'project';

  projectPG = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  d = new Date();
  month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  day = this.d.getDate().toString().padStart(2, '0');
  todayDate = '' + this.d.getFullYear() + this.month + this.day;
  //for filter date
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBeforeDate = new Date(this.aweekBefore);
  checkInStartDate =
    this.aweekBeforeDate.getFullYear() +
    (this.aweekBeforeDate.getMonth() + 1).toString().padStart(2, '0') +
    this.aweekBeforeDate.getDate().toString().padStart(2, '0') +
    '';
  checkInEndDate = this.d.getFullYear() + this.month + this.day + '';

  currentEndDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();
  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();
  tdyDate = formatDate(new Date(), this.formatToShow, 'en-US').toString();

  getcheckinForm = {
    startdate: this.currentStartDate,
    enddate: this.currentEndDate,
    domain: this.allinoneService.getDomain().shortcode,
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    orgname: '',
    projindex: '',
    jobindex: '',
    subtype: 'Employee',
    userid: '',
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private router: Router,
    private dialog: MatDialog,
    private filter: FilterPipe
  ) {
    if (!this.allinoneService.isAdminOf('contributor')) {
      this.router.navigateByUrl('/home');
    }
  }

  async ngOnInit(): Promise<void> {
    this.organizations = this.allinoneService.contributorOrgs;
    this.getcheckinForm.orgid = this.allinoneService.selectedConOrg
      ? this.allinoneService.selectedConOrg
      : this.organizations[0].orgid;
    const await_proj = await this.getProjects();
  }

  orgChanged(event: any) {
    this.getcheckinForm.orgid = event.target.value;
    this.allinoneService.selectedConOrg = this.getcheckinForm.orgid;
    this.allinoneService.setSelectedConOrg(this.getcheckinForm.orgid);
    this.getProjects();
  }

  getProjects() {
    return new Promise((resolve: any) => {
      this.gettingProjects = true;
      this.projects = [];
      this.jobs = [];
      const data = {
        orgid: this.getcheckinForm.orgid,
        domainid: this.allinoneService.getDomain().domainid,
        role: '400'
      };
      this.kunyekService.getContributorProjects(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.projects = res.data;

            this.getcheckinForm.projindex = this.projects.length > 0 ? '0' : '';
            if (this.getcheckinForm.projindex != '') {
              this.getJobs();
            }
            resolve(true);
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
            resolve(false);
          }
          this.gettingProjects = false;
        },
        (err) => {
          resolve(false);
          this.gettingProjects = false;
        }
      );
    });
  }

  projChanged(event: any) {
    this.getJobs();
  }

  getJobs() {
    this.gettingJobs = true;
    this.jobs = [];
    const data = {
      projectid:
        this.projects[parseInt(this.getcheckinForm.projindex)].projectid,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      role: '400'
    };
    this.kunyekService.getAllJobs(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.jobs = res.data;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingJobs = false;
      },
      (err) => {
        this.gettingJobs = false;
      }
    );
  }

  clear() {
    this.searchTextPJ = '';
  }

  changePjPageCount(event: any) {
    this.itemPerPagePJ = event.target.value;
    this.q = 1;
  }

  filterByDate() {
    const dialog = this.dialog.open(CheckInProjectDaterangeComponent, {
      height: 'auto',
      maxWidth: '400px',
      minWidth: '350px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        startdate: this.projStartDate,
        enddate: this.projEndDate,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.projStartDate = dialogResult.startdate;
        this.projEndDate = dialogResult.enddate;
        this.searchByProj();
      }
    });
  }

  searchByProj() {
    this.formSubmitted = true;
    this.searchLoading = true;
    const data = {
      projectid:
        this.projects[parseInt(this.getcheckinForm.projindex)].projectid,
      jobid:
        this.getcheckinForm.jobindex == ''
          ? ''
          : this.jobs[parseInt(this.getcheckinForm.jobindex)].jobid,
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      startdate: this.allinoneService.formatDate(this.projStartDate),
      enddate: this.allinoneService.formatDate(this.projEndDate),
      role: '400'
    };
    this.kunyekService.getProjectAndJobSummary(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
          this.projData = res.datalist;
          this.searchLoading = false;
          this.formSubmitted = false;
          this.tasktype =
            this.getcheckinForm.jobindex == '' ? 'project' : 'job';
          this.projectPG[3].count = this.projData.length;
        } else {
          this.searchLoading = false;
          this.formSubmitted = false;
          this.projData = [];
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.projData = [];
        this.searchLoading = false;
        this.formSubmitted = false;
      }
    );
  }

  removeDate() {
    this.projStartDate = '';
    this.projEndDate = '';
    this.projData = [];
  }

  sortProjData(sort: Sort) {
    const data = this.projData;
    if (!sort.active || sort.direction === '') {
      this.projData = data;
      return;
    }

    this.projData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'hours':
          return this.allinoneService.compare(a.hours, b.hours, isAsc);
        default:
          return 0;
      }
    });
  }

  viewTaskDetails(item: any) {
    var type = this.tasktype;
    var tempUser = {
      userid: item.userid,
      username: item.username,
      hours: item.hours,
      projectname: this.projects[parseInt(this.getcheckinForm.projindex)].name,
    };
    var user = this.allinoneService.replaceAll(
      this.allinoneService.encrypt(JSON.stringify(tempUser)),
      '/',
      '###'
    );
    sessionStorage.setItem('projdetails', JSON.stringify(item.datalist));
    var temp = '/contributor/project-report/task/' +  this.getcheckinForm.orgid +'/' +type +'/' +user;

    this.router.navigate([
      '/contributor/project-report/task/' +
        this.getcheckinForm.orgid +
        '/' +
        type +
        '/' +
        user,
    ]);
  }

  downloadSheet() {
    var exdata: any = [];
    var startd = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.formatToShow,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.formatToShow,
      'en-US'
    ).toString();
    var name =
      this.getcheckinForm.orgname + '(' + startd + '-' + endd + ').xlsx';

    var filteredProjData = this.filter.transform(this.projData, this.searchTextPJ, 14)
    filteredProjData.map((item: any) => {
      var temp = {
        'User ID': item.userid,
        'Name': item.username,
        'Employee ID': item.employeeid,
        'Total Hours': item.hours,
      };
      exdata.push(temp);
    });

    this.allinoneService.exportEcecl(exdata, name);
  }
}
