<div class="container-fluid">
  <div class="content">
    <!-- <ng-container *ngIf="isAdmin">
      <app-hcm-breadcrumb *ngIf="!addCalendar" s_breadcrumb="Leave Calendar">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb
        *ngIf="addCalendar"
        s_breadcrumb="Leave Calendar"
        [t_breadcrumb]="isEditing ? 'Edit Calendar' : 'New Calendar'"
        (emit)="cancel()"
      >
      </app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb
        *ngIf="!addCalendar"
        [s_breadcrumb]="'Leave Calendar'"
      >
      </app-office-breadcrumb>
      <app-office-breadcrumb
        *ngIf="addCalendar"
        [s_breadcrumb]="'Leave Calendar'"
        [t_breadcrumb]="isEditing ? 'Edit Calendar' : 'New Calendar'"
        (emit)="cancel()"
      >
      </app-office-breadcrumb>
    </ng-container> -->

    <!-- <ng-container *ngIf="allinoneService.appView == '002' && isAdmin">
      <app-hcm-breadcrumb *ngIf="!addCalendar" [s_breadcrumb]="'Leave Calendar'">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="addCalendar" [s_breadcrumb]="'Leave Calendar'"
        [t_breadcrumb]="isEditing ? 'Edit Calendar' : 'New Calendar'" (emit)="cancel()">
      </app-hcm-breadcrumb>
    </ng-container> -->

    <ng-container *ngIf="allinoneService.appView == '002' && !isAdmin ">
      <app-office-breadcrumb *ngIf="!addCalendar" [s_breadcrumb]="'Leave Calendar'">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="addCalendar" [s_breadcrumb]="'Leave Calendar'"
        [t_breadcrumb]="isEditing ? 'Edit Calendar' : 'New Calendar'" (emit)="cancel()">
      </app-office-breadcrumb>
    </ng-container>


    <ng-container *ngIf="allinoneService.appView == '002' && isAdmin">
      <app-team-breadcrumb *ngIf="!addCalendar" [s_breadcrumb]="'Leave Calendar'">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="addCalendar" [s_breadcrumb]="'Leave Calendar'"
        [t_breadcrumb]="isEditing ? 'Edit Calendar' : 'New Calendar'" (emit)="cancel()">
      </app-team-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!addCalendar">
      <div class="d-flex justify-content-between">
        <div class="mb-3">
          <button class="btn btn-custom me-auto" (click)="newCalendar()">
            <i class="fa fa-plus me-1"></i> New
          </button>
        </div>
        <!-- <div class="mb-3">
          <div class="align-items-center" [matMenuTriggerFor]="statusmenu">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              aria-expanded="false"
              mdbDropdownToggle
              style="
                background-color: #87929d;
                border-color: #87929d;
                color: white;
                min-width: 100px;
              "
            >
              Year&nbsp;:&nbsp;{{ selectedStatus }}
            </button>
          </div>
          <mat-menu
            #statusmenu="matMenu"
            id="service-menu"
            xPosition="before"
            class="calendar-menu"
          >
            <button
              mat-menu-item
              *ngFor="let status of statusList"
              (click)="statusChange(status)"
            >
              {{ status }}
            </button>
          </mat-menu>
        </div> -->
      </div>
    </ng-container>
    <ng-container *ngIf="addCalendar">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!isLoading">
          Cancel
        </button>
        <app-button-loader class="mb-3" (click)="submitCalendar()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="isLoading">
        </app-button-loader>
      </div>
      <div class="row">
        <div class="mb-3 col-md-12">
          <label for="year" class="form-label">Year</label>
          <input class="form-control" id="year" [(ngModel)]="calendarForm.year" name="year" #year="ngModel"
            [ngClass]="{ 'is-invalid': formSubmitted && year.invalid }" required />
          <div class="invalid-feedback" *ngIf="
              year.invalid || ((year.dirty || year.touched) && formSubmitted)
            ">
            <div *ngIf="year.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div>

        <div class="mb-3 col-md-6">
          <label for="startdate" class="form-label">Start Date</label>
          <!-- <input
            class="form-control"
            type="date"
            id="startdate"
            [(ngModel)]="calendarForm.startdate"
            name="startdate"
            max="9999-12-31"
          /> -->
          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="calendarForm.startdate" style="min-width: 200px;"
            required #startdate="ngModel">
        </div>
        <div class="mb-3 col-md-6">
          <label for="enddate" class="form-label">End Date</label>
          <!-- <input
            class="form-control"
            type="date"
            id="enddate"
            [(ngModel)]="calendarForm.enddate"
            name="enddate"
            max="9999-12-31"
          /> -->
          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="calendarForm.enddate" style="min-width: 200px;"
            required #enddate="ngModel">
        </div>
        <div class="mb-3 col-md-12 d-flex align-items-center flex-wrap">
          <div class="form-check me-5">
            <input class="form-check-input" type="radio" name="status" id="active" [checked]="calendarForm.active"
              (change)="activeStatusChange()" />
            <label class="form-check-label" for="active"> Active </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="status" id="inactive" [checked]="!calendarForm.active"
              (change)="activeStatusChange()" />
            <label class="form-check-label" for="inactive"> Inactive </label>
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngIf="!addCalendar">
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by year" aria-label="Search by year"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div class="line"></div>
        <div class="">
          <div class="align-items-center" [matMenuTriggerFor]="statusmenu">
            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
              mdbDropdownToggle style="
                background-color: #87929d;
                border-color: #87929d;
                color: white;
                min-width: 100px;
              ">
              Year&nbsp;:&nbsp;{{ selectedStatus }}
            </button>
          </div>
          <mat-menu #statusmenu="matMenu" id="service-menu" xPosition="before" class="calendar-menu">
            <button mat-menu-item *ngFor="let status of statusList" (click)="statusChange(status)">
              {{ status }}
            </button>
          </mat-menu>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ calendarList.length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by year" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <pagination-controls id="rule" class="pagination upper-paginate ms-auto" (pageChange)="currentPage = $event"
          *ngIf="calendarList.length > 0" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>

        <!-- <pagination-template class="ms-auto" *ngIf="calendarList.length > 0" #p="paginationApi"
          (pageChange)="pageChange($event)">
          <div class="d-flex align-items-center justify-content-center">
            <div [class]="
                    p.isFirstPage()
                      ? 'custom-pagination-disabled'
                      : 'custom-pagination'
                  " (click)="p.previous()">
              <span class="me-1">&laquo;</span>&nbsp;Prev
            </div>
            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </span>
              <div class="active-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div [class]="
                    nextDisabled
                      ? 'custom-pagination-disabled'
                      : 'custom-pagination'
                  " (click)="p.next()">
              Next&nbsp;<span class="ms-1">&raquo;</span>
            </div>
          </div>
        </pagination-template> -->
      </div>

      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="year">
                Year
              </td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="startdate">
                Start Date
              </td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="enddate">
                End Date
              </td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="status">
                Status
              </td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>

          <tbody *ngIf="calendarListLoading">
            <tr>
              <td colspan="5" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!calendarListLoading">
            <tr *ngIf="calendarList.length == 0">
              <td colspan="5" class="nodata">Empty</td>
            </tr>

            <tr *ngFor="
                let template of calendarList
                  | filter : searchText : 'calendarList'
                  | paginate
                    : {
                        itemsPerPage: itemPerPage,
                        currentPage: currentPage,
                        id: 'rule'
                      };
                let index = index
              ">
              <td class="td-data td-edit" (click)="editCalendar(template)">
                <span [title]="template.year" [ngClass]="{ 'text-danger': !template.valid }">{{ template.year }}</span>
              </td>

              <td class="td-data">
                <span [title]="allinoneService.formatDBToShow(template.startdate)">{{
                  allinoneService.formatDBToShow(template.startdate)
                  }}</span>
              </td>
              <td class="td-data">
                <span [title]="allinoneService.formatDBToShow(template.enddate)">{{
                  allinoneService.formatDBToShow(template.enddate) }}</span>
              </td>

              <td class="td-data">
                <span [title]="template.active ? 'Active' : 'Inactive'" class="status" [ngClass]="{
                    'status-active': template.active,
                    'status-inactive': !template.active
                  }">{{ template.active ? "Active" : "Inactive" }}</span>
              </td>

              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-primary" (click)="editCalendar(template)">
                  <i class="fa fa-edit"></i>
                </button>
                <!-- <button class="btn btn-outline-danger" (click)="deleteLeaveTemplate(template.templateid)">
                  <i class='fa fa-trash-o'></i>
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>