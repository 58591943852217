<div class="d-flex align-items-center justify-content-between mb-3">
    <div>
        <h3 class="mb-0">Filter</h3>
    </div>
    <div>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div class="row">
    <div class="mb-3 col-md-6" [ngClass]="{'col-md-12' : hideRequest}">
        <label for="requeststatus" class="form-label">Status</label>
        <select class="form-select" [(ngModel)]="searchForm.requeststatus" id="requeststatus" name="requeststatus">
            <option *ngFor="let item of statusList" [value]="item.value">
                {{item.name}}
            </option>
        </select>
    </div>
    <div class="mb-3 col-md-6" *ngIf="!hideRequest">
        <label for="requesttype" class="form-label">Request Type</label>
        <select class="form-select" [(ngModel)]="searchForm.requesttype" id="requesttype" name="requesttype">
            <option value="">All</option>
            <ng-container *ngFor="let req of requestTypeList">
                <option [value]="req.requesttypeid">{{req.requesttypename}}
                </option>
            </ng-container>
        </select>
    </div>
    <div class="mb-3 col-md-6">
        <label for="startdate" class="form-label">Start Date</label>
        <!-- <input class="form-control" type="date" id="startdate" max="9999-12-31" [(ngModel)]="searchForm.startdate" name="startdate" /> -->
        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
              [bsConfig]="generalDatePickerConfig" readonly name="startdate" id="startdate"
              [(ngModel)]="searchForm.startdate" placement="top" style="min-width: 200px;" required #startdate="ngModel"
              (ngModelChange)="startdateChange()">
    </div>
    <div class="mb-3 col-md-6">
        <label for="enddate" class="form-label">End Date</label>
        <!-- <input class="form-control" type="date" id="enddate" max="9999-12-31" [(ngModel)]="searchForm.enddate" name="enddate" /> -->
        <input type="text" id="enddate" name="enddate" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
              [bsConfig]="generalDatePickerConfig" readonly
              [(ngModel)]="searchForm.enddate" placement="top" [minDate]="searchForm.startdate" style="min-width: 200px;" required #enddate="ngModel"
              
              >
    </div>

</div>

<div mat-dialog-actions align="end">
    <button type="button" class="btn btn-outline-secondary me-2" mat-dialog-close>
        Cancel
    </button>
    <app-button-loader (click)="search()" [button_text]=" 'Search'" [load_button_text]=" ''"></app-button-loader>
</div>