import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-general-payment-invoice-list',
  templateUrl: './general-payment-invoice-list.component.html',
  styleUrls: ['./general-payment-invoice-list.component.scss']
})
export class GeneralPaymentInvoiceListComponent implements OnInit {
  url = ['members/paymentinvoice']
  page = 1
  totalPages!:number
  paymentInvoiceList: any[] = [];
  searchName: string = '';

  gettingPaymentInvoiceList: boolean = true;

  breadcrumbs: any[] = [
    { name: 'Payment', url: 'payment', isActive: true },
    { name: 'General Payment', url: '', isActive: false }
  ];

  constructor(
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public allInOneService: AllInOneService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getCurrentPage()
    this.getPaymentInvoiceList();
  }
  getCurrentPage() {
    this.route.queryParams.subscribe((params) => {
      const pageParam = params['page'];
      if (pageParam && !isNaN(+pageParam) && +pageParam > 0) {
        this.page = +pageParam;
      } else {
        this.router.navigate(this.url, { queryParams: { page: 1 } });
      }
      const q = params['q'];
      if (q) {
        this.searchName = q;
      }
    });
  }
  goPageOne() {
    const searchNameIsExitAndNotPreviousOne = this.searchName && this.searchName!==this.searchName
        if (this.page !== 1 || searchNameIsExitAndNotPreviousOne) {
      this.router.navigate(this.url, { queryParams: { page: 1, q: this.searchName } });
    }
  }


  getBadgeClass(paymentType: string): string {
    switch (paymentType) {
      case 'Cash':
        return 'badge bg-secondary';
      case 'Kpay':
        return 'badge bg-warning text-dark';
      case 'Bank':
        return 'badge bg-danger';
      default:
        return 'badge bg-success';
    }
  }

  getPaymentInvoiceList() {
    const data =  {searchName: this.searchName,page:this.page}
    this.kunyekService.getPaymentInvoice().get(data || {}).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.totalPages = res.totalpages;
          if (this.page > this.totalPages) {
            this.goPageOne();
          } else {
            this.paymentInvoiceList = res.datalist;
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingPaymentInvoiceList = false;
      },
      (err) => {
        this.gettingPaymentInvoiceList = false;
      }
    );
  }

  newMember() {
    this.router.navigateByUrl('members/paymentinvoice/new')
  }

  searchByName() {
    this.router.navigate(this.url, { queryParams: { page: 1, q: this.searchName } });
  }

  viewDetailPayment(id: string) {
    this.router.navigateByUrl('members/paymentinvoice/' + id);
  }

  editPayment(id: string) {
    this.router.navigateByUrl('members/paymentinvoice/edit/' + id);
  }
}
