import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';

import { Sort } from '@angular/material/sort';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { iif } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Color } from 'ng2-charts/public_api';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-service-weekly-report',
  templateUrl: './service-weekly-report.component.html',
  styleUrls: ['./service-weekly-report.component.css']
})
export class ServiceWeeklyReportComponent implements OnInit {

  rowlist: any = [

  ];
  columnlist: any = [

  ];
  gettingData: boolean = false;
  enddate: any = "";
  startdate: any = "";
  formatToShow = 'yyyy-MM-dd';
  orgid: any = "";
  organizations: any = [];
  productid: any = "";
  formatToShowServcie = "MM/dd/yyyy";

  productListdata: any = [];
  productname: any = 'product'
  productsearch: any = '';
  myChart: any;

  


  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private filter: FilterPipe
  ) {
    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;
  }
  todayDate: Date = new Date(); // ယနေ့နေ့စွဲ
  sevenDaysAgo :any  = "";
  ngOnInit(): void {
      this.sevenDaysAgo = new Date(this.todayDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      console.log(this.sevenDaysAgo);

    // datenew.
    this.startdate = formatDate(this.sevenDaysAgo, this.formatToShow, 'en-US').toString();
    // threemonthdate.(threemonthdate.getDay() - 7);
    // this.startdate = formatDate(threemonthdate.getDate() - 7, this.formatToShow, 'en-US').toString();
    

    this.enddate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.getServiceWeeklyreport();
    // this.getServiceWeeklyreport();
  }

  refreshgetData() {
    this.getServiceWeeklyreport();

  }

  removeProduct() {

    this.productsearch = '';
  }

  // changeproduct(data: any) {
  //   if (data == 'product') {
  //     this.productid = '';
  //     this.productname = 'product';
      
  //     this.getServiceWeeklyreport();
  //   } else {
  //     this.productid = data.productid
  //     this.productsmid = data.productid

  //     this.router.navigate([], {
  //       relativeTo: this.route,
  //       queryParams: {
  //         'productid': this.productid,
  //       },
  //       queryParamsHandling: 'merge',
  //     });
  //     this.productname = data.name;
  //     this.getSummary();
  //   }
  // }


  getServiceWeeklyreport() {
    this.gettingData = true;
    this.columnlist = [];
    this.rowlist = [];

    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      "startdate":this.startdate,
      "enddate":this.enddate
    };
    console.log(data)
    this.kunyekService.getServiceWeeklyreport(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.columnlist = res.list;
    this.getChart();


          // this.rowlist = res.rowlist;
          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  
  downloadSheet() {



    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Service_Overage_Report' + time + '.xlsx';

    console.log(this.rowlist); 
    for (let j = 0; j < this.rowlist.length; j++) {
      var tmpdata: any = {
        "Product Name": this.rowlist[j]['productname']
      };
      for (let k = 0; k < this.rowlist[j]['countlist'].length; k++) {
          var columnname = this.rowlist[j]['countlist'][k]['columnname'];
          tmpdata[columnname] = this.rowlist[j]['countlist'][k]['count'];
      }
      data.push(tmpdata)
    }

    console.log(data);
    // this.serviceMonthlyList.map((car: any) => {
    //   data.push({
    //     "Product Name": car.productname,
    //     "Product": car.count,
    //   });
    // });

    this.allinoneService.exportEcecl(data, name);

  }
  
  public barChartData: any = [
    
  ];
  public barChartLabel: any = [
    
  ];
  public barChartColors: Color[] = []
  public barChartColorsFirst: Color[] = [
    { backgroundColor: 'red' },
    { backgroundColor: '#2e86c1' },
    { backgroundColor: 'grey' },
    { backgroundColor: 'teal' },
    { backgroundColor: '#69F0AE' },
    { backgroundColor: 'lime' },
    { backgroundColor: 'yellow' },
    { backgroundColor: 'indigo' },
    { backgroundColor: 'amber' },
    { backgroundColor: 'purple' },
    { backgroundColor: '#FF8D85' },
    { backgroundColor: 'orange' },
    { backgroundColor: '#B8255F' },
    { backgroundColor: '#4DD091' },
    { backgroundColor: '#95A5A6' },
    { backgroundColor: '#800000' },
    { backgroundColor: '#4DD0E1' },
    { backgroundColor: '#607D8B' },
    { backgroundColor: '#FF6E40' },
    { backgroundColor: '#AEEA00' },
    { backgroundColor: '#00695C' },
    { backgroundColor: '#F57F17' }
  ];
  dynamicColors() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + ")";
    // var letters = '0123456789ABCDEF'.split('');
    // var color = '#';
    // for (var i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    //   console.log(color);
    // }
    // return color;
  }
  poolColors(a: any) {
    var pool = [];

    // for (let i = 0; i < a; i++) {
      pool.push("rgba(255,0,0)");
      pool.push("rgba(245,223,33)");
      pool.push("rgba(201,200,197)");

    // }
    // console.log(this.dynamicColors());
    return pool;
  }

  getChart(){
    console.log("label")
    this.barChartData = [];
    this.barChartLabel = [];

    for(var i = 0; i < this.columnlist.length;i++){
      this.barChartData.push(this.columnlist[i]['open']);
      this.barChartLabel.push(this.columnlist[i]['priorityname']);
    }
    const datass = {
      labels: this.barChartLabel,
      datasets: [{
        label: '',
        data: this.barChartData,
        backgroundColor: this.poolColors(this.barChartData.length),
        // backgroundColor: [
        //   // this.barChartColors
        //   // 'red',
        //   // '#2e86c1',
        //   // 'grey',
        //   // 'teal',
        //   // '#69F0AE',
        //   // 'lime',
        //   // 'yellow',
        //   // 'indigo',
        //   // 'amber',
        //   // 'purple',
        //   // '#FF8D85',
        //   // 'orange',
        //   // '#B8255F',
        //   // '#4DD091',
        //   // '#95A5A6',
        //   // '#800000',
        //   // '#4DD0E1',
        //   // '#607D8B',
        //   // '#FF6E40',
        //   // '#AEEA00',
        //   // '#00695C',
        //   // '#F57F17',


        // ],
        borderWidth: 1
      }]
    };

    if (this.myChart) {
      console.log(this.myChart.clear());
      this.myChart.destroy();
      console.log(this.myChart);

    }
    console.log("destroy1111");

    this.myChart = new Chart(
      'myChart',
      {
        type: 'bar',
        data: datass,
        options: {
          // maintainAspectRatio
          maintainAspectRatio: false,
          legend: { display: false },
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                fontSize: 16
                
              }
              ,
            },]
          },
          plugins: [
            // topLabels
          ]
        }
      }


    );

  }
}
