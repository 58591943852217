import { Component, HostListener, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { EventsAttendanceComponent } from '../events-attendance/events-attendance.component';
import { MatDialog } from '@angular/material/dialog';
import { EventsDetailComponent } from '../events-detail/events-detail.component';
import { formatDate } from "@angular/common";
import { LocationsNewComponent } from '../locations-new/locations-new.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { Location } from '@angular/common';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  // isSticky: boolean = false;

  // @HostListener('window:scroll', ['$event'])
  // checkScroll(): void {
  //   this.isSticky = window.pageYOffset >= 50;
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true
    }
    else {
      this.isMobileView = false
    }
    if (window.innerWidth < this.viewWidth) {
      this.isView = true
    }
    else {
      this.isView = false
    }
  }

  eventAddorNot: boolean = false;
  eventFormSubmitted: boolean = false;
  gettingDomainList: boolean = false;
  gettingOrgList: boolean = false;
  gettingLocList: boolean = false;
  gettingChannelList: boolean = false;
  saveLoading: boolean = false;
  eventListLoading: boolean = false;
  isEditing: boolean = false;
  isFromOrg: boolean = false;
  isMobileView: boolean = false;
  disableDate: boolean = true;
  backFromAttLoading: boolean = false;
  isFocus : boolean = false;
  isView: boolean = false;


  eventIdToEdit: any = ""
  role: any;
  org_name: any = "";
  searchText: any = "";

  callType: any = '';

  organizations: any = []
  domains: any = []
  channels: any = []
  locations: any = []
  events: any = []

  mobileViewWidth: any = 426
  viewWidth: any = 992
  type : any;

  itemPerPage:any=100
  p: number = 1;
  searchType: number = 8;

  pg=[

    {
      "name":"100 items",
      "count":100
    },
    {
      "name":"300 items",
      "count":300
    },
    {
      "name":"500 items",
      "count":500
    },
    {
      "name":"All items",
      "count":0
    }
  ]

  occurrenceList = [
    "Once",
    "Daily",
    "Weekdays",
    "Weekends"
  ]

  eventForm = {
    "domain": "",
    "org": "",
    "channel": "",
    "eventname": "",
    "description": "",
    "startdatetime": "",
    "enddatetime": "",
    "locid": "",
    "occurrence": "Once",
  }

  constructor(
    public dialog: MatDialog,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    public kunyekService: KunyekService,
    private route: ActivatedRoute,
    private router: Router,
    public location: Location,
  ) {
    // if(!this.allinoneService.isAdminOf("org") && !this.allinoneService.isAdminOf('contributor')){
    //   this.router.navigateByUrl('/home');
    // }
   }

  async ngOnInit(): Promise<void> {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true
    }
    if (window.innerWidth < this.viewWidth) {
      this.isView = true
    }
    // this.role = this.allinoneService.getRole()

    this.callType = sessionStorage.getItem('callType')
    // if(this.callType != '300' && this.callType != '400'){
    //   this.router.navigateByUrl('/home')
    // }

    const route_eventid = this.route.snapshot.queryParamMap.get('event') || "";
    this.eventForm.org = this.route.snapshot.queryParamMap.get('org') || "";
    this.eventForm.channel = this.route.snapshot.queryParamMap.get('channel') || "";
    this.type = this.route.snapshot.queryParamMap.get('type') || "";

    this.eventForm.domain = this.allinoneService.getDomain().domainid;
    if (this.callType == '300') {
      this.organizations = this.allinoneService.orgs;
    } else {
      this.organizations = this.allinoneService.contributorOrgs;
    }
    if(route_eventid == ""){
      if (this.callType == '300') {
        this.eventForm.org = this.allinoneService.selectedOrg
          ? this.allinoneService.selectedOrg
          : this.organizations[0].orgid;
      } else {
        this.eventForm.org = this.allinoneService.selectedOrg
          ? this.allinoneService.selectedOrg
          : this.organizations[0].orgid;
      }
      this.getChannelList();
      this.getEventList()
      this.getLocation()
    }
    else{
      this.backFromAttLoading = true;
      this.router.navigateByUrl('/events', {skipLocationChange: true});
      this.getChannelList();
      const getEList = await this.getEventList()
      const get_event = this.events.find((event: any) => event.eventid == route_eventid);
      this.getLocation()
      if(this.type == 1 && getEList){
        this.openEditForm(get_event) 
        this.backFromAttLoading = false
      }
      else if(this.type == 2 && getEList){
        this.viewAttendanceForm(get_event)
        this.backFromAttLoading = false
      }
    }
  }

  changePageCount(event:any){
    this.itemPerPage=event.target.value;
    this.p=1
  }

  clear(){
    this.searchText = ""
  }

  sortData(sort: Sort) {
    const data = this.events
    if (!sort.active || sort.direction === '') {
      this.events = data
      return;
    }

    this.events = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'startdate': return this.allinoneService.compare(a.startdatetoshow, b.startdatetoshow, isAsc);
        case 'enddate': return this.allinoneService.compare(a.enddatetoshow, b.enddatetoshow, isAsc);
        default: return 0;
      }
    });
  }



  getDomainList(): void {
    this.gettingDomainList = true;
    this.domains = []
    this.kunyekService.getDomains().subscribe((res: any) => {
      if (res.returncode == "300") {
        res.domains.map((domain: any) => {
          this.domains.push({
            id: domain.id,
            appid: domain.appid,
            shortcode: domain.shortcode,
            domainid: domain.domainid,
            description: domain.description
          })
        });
        this.gettingDomainList = false;
      }
      else {
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }
    )
  }

  getOrganization() {
    this.gettingOrgList = true;
    this.organizations = []
    this.kunyekService.getOrgByDomainId(this.eventForm.domain).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.datalist.map((org: any) => {
          this.organizations.push({
            orgid: org.orgid,
            appid: org.appid,
            createdby: org.createdby,
            createddate: org.createddate,
            domainid: org.domainid,
            name: org.name,
            description: org.description
          })
        }
        )
        this.gettingOrgList = false;
        this.eventForm.org = this.organizations[0].orgid
        this.getEventList()
        this.getChannelList()
      }
      else {
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }
    )
  }

  getLocation() {
    this.locations = []
    this.gettingLocList = true
    var data;
    // data = {
    //   "global": "",
    //   "domainid": this.eventForm.domain,
    //   "orgid": [this.eventForm.org],
    //   "userid": ""
    // }
    // if (this.role == "300" || this.eventForm.org != "") {
      data = {
        "global": "",
        "domainid": "",
        "orgid": [this.eventForm.org],
        "personal": ""
      }
    // }
    this.kunyekService.getLocations(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.datalist.map((loc: any) => {
          this.locations.push({
            locid: loc.locid,
            lat: loc.lat,
            lng: loc.lng,
            name: loc.name,
            range: loc.range,
            type: loc.type,
            typeid: loc.typeid,
            description: loc.description
          })
        }
        )
        this.gettingLocList = false;
      }
      else {
        this.gettingLocList = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    })
  }

  getChannelList() {
    this.channels = []
    this.gettingChannelList = true
    const domain = this.allinoneService.getDomain();

    const data = {
      "domain": domain.shortcode,
      "domainid" : domain.domainid,
      "orgid": this.eventForm.org
    }

    this.kunyekService.getChannels(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.list.map((channel: any) => {
          this.channels.push({
            "channelid": channel.channelid,
            "channelname": channel.channelname
          })
        }
        )
        this.gettingChannelList = false;
      }
      else {
        this.gettingChannelList = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    })
  }

  async getEventList() {
    return new Promise(resolve => {
        this.events = []
        this.eventListLoading = true;
        const format = 'dd/MM/yyyy hh:mm aa';
        // this.eventForm.startdatetime = formatDate(new Date(this.eventForm.startdatetime), format,'en-US').toString()
        const data = {
          "domainid": this.eventForm.domain,
          "orgid": this.eventForm.org,
          "channelid": this.eventForm.channel
        }
        this.kunyekService.getEvents(data).subscribe((res: any) => {
          if (res.returncode == "300") {
            res.datalist.map((event: any) => {
              var tempStartDate =event.startdate!=""? formatDate(new Date(event.startdate), format, 'en-US').toString():"";
              var tempEndDate = event.enddate!=""?formatDate(new Date(event.enddate), format, 'en-US').toString():"";
              this.events.push({
                eventid: event.eventid,
                appid: event.appid,
                channelid:event.channelid,
                createdby: event.createdby,
                createddate: event.createddate,
                description: event.description,
                domainid: event.domainid,
                enddatetime: event.enddate,
                locid: event.locid,
                locname: event.locname,
                name: event.name,
                orgid: event.orgid,
                startdatetime: event.startdate,
                occurrence: event.occurrence,
                startdatetoshow: tempStartDate,
                enddatetoshow: tempEndDate,
              })
            }
            )
            this.pg[3].count = this.events.length
            this.eventListLoading = false;
            resolve(true)
          }
          else {
            this.eventListLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
            resolve(false)
          }
        },
         err => {
           this.eventListLoading = false;
           resolve(false)
         }
        )
      })
  }

  // domainChanged(event: any) {
  //   this.eventForm.domain = event.target.value;
  //   if (this.role == "100") {
  //     this.eventForm.org = ""
  //     this.eventForm.locid = ""
  //   }
  //   if (this.eventForm.domain == "") {
  //     this.events = []
  //   }
  //   else {
  //     this.getEventList()
  //     this.getOrganization()
  //     this.getLocation()
  //   }
  // }

  orgChanged(event: any) {
    
    this.eventForm.org = event.target.value;
    if (this.eventForm.org == '') {
      this.events = [];
    } else {
      if (this.callType == '300') {
        this.allinoneService.selectedOrg = this.eventForm.org;
        this.allinoneService.setSelectedOrg(this.eventForm.org);
      } else {
        this.allinoneService.selectedConOrg = this.eventForm.org;
        this.allinoneService.setSelectedConOrg(this.eventForm.org);
      }
      this.getEventList();
      this.getLocation();
    }
    
    // this.eventForm.org = event.target.value
    // this.allinoneService.selectedOrg = this.eventForm.org
    // this.allinoneService.setSelectedOrg(this.eventForm.org)
    // this.eventForm.channel = ""
    // this.eventForm.locid = ""
    // if (this.eventForm.org != "") {
    //   this.getChannelList()
    // }
    // this.getEventList()
    // this.getLocation()
  }

  channelChanged(event: any) {
    this.eventForm.channel = event.target.value
    this.getEventList()
  }

  locChanged(event: any) {
    this.eventForm.locid = event.target.value
  }

  openEditForm(event: any) {
    this.isEditing = true;
    this.eventAddorNot = true;
    this.eventIdToEdit = event.eventid
    this.eventForm = {
      "domain": this.eventForm.domain,
      "org": this.eventForm.org,
      "channel": this.eventForm.channel,
      "eventname": event.name,
      "description": event.description,
      "startdatetime": event.startdatetime,
      "enddatetime": event.enddatetime,
      "locid": event.locid,
      "occurrence": event.occurrence,
    }
  }

  viewEventData(event: any,fromEditForm:boolean) {
    const dialog = this.dialog.open(EventsDetailComponent, {
      height: '400px',
      width: '65vw',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail','mat-typography'],
      data: {
        event: event,
        fromEditForm:fromEditForm
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }
  viewQR() {
    const dialog = this.dialog.open(EventsDetailComponent, {
      height: '400px',
      width: '400px',
      closeOnNavigation: true,
      panelClass: ['qr-modal','mat-typography'],
      data: {
        event: this.events.filter((x:any)=>x.eventid==this.eventIdToEdit)[0],
        fromEditForm:true
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }

  deleteEvent(eventid: any) { }

 
  EnableDate(startDate:any,endDate:any){
    if(startDate){
      this.eventForm.startdatetime="";
    }
    if(endDate){
      this.eventForm.enddatetime="";
    }
    // this.disableDate=this.disableDate?false:true;
    

  }
  viewAttendance(event: any) {
    this.router.navigateByUrl("event/attendance?event=" + event+"&org="+this.eventForm.org+"&channel="+this.eventForm.channel + "&type=1");
     // make sure it only closes if the upper async fn succesfully ran!
    
}

  submitEvent() {
    this.eventFormSubmitted = true;
    console.log(this.eventForm.enddatetime)

    if (this.eventForm.domain != "" && this.eventForm.eventname != "" && this.eventForm.startdatetime != "" &&
      this.eventForm.enddatetime != "" && this.eventForm.locid != "" && this.eventForm.occurrence != "") {
      if (this.eventForm.enddatetime <= this.eventForm.startdatetime) {
        this.messageService.openSnackBar("Please select the valid end date!", 'warn');
      }
      else {
        this.saveLoading = true
        this.saveEvent()
      }

    }else if(this.eventForm.domain != "" && this.eventForm.eventname != "" && this.eventForm.startdatetime == "" &&
    this.eventForm.enddatetime == "" && this.eventForm.locid != "" && this.eventForm.occurrence != ""){
      this.saveLoading = true
      this.saveEvent()
    }
  }

  saveEvent() {
    // const format = 'dd/MM/yyyy hh:mm aa';
    // this.eventForm.startdatetime = formatDate(new Date(this.eventForm.startdatetime), format,'en-US').toString()
    console.log(this.eventForm)
    var data = {
      "name": this.eventForm.eventname,
      "description": this.eventForm.description,
      "startdate": this.eventForm.startdatetime,
      "enddate": this.eventForm.enddatetime,
      "locid": this.eventForm.locid,
      "occurrence": this.eventForm.occurrence,
      "domainid": this.eventForm.domain,
      "orgid": this.eventForm.org,
      "channelid": this.eventForm.channel
    }

    if (this.isEditing) {
      data = Object.assign(data, { 'eventid': this.eventIdToEdit });
    }

    var fun = this.isEditing ? this.kunyekService.updateEvent(data) : this.kunyekService.createEvent(data);
    fun.subscribe((res: any) => {
      if (res.returncode == "300") {
        this.saveLoading = false;
        this.eventAddorNot = false;
        this.eventFormSubmitted = false;
        this.isEditing = false;
        this.eventForm = {
          "domain": this.eventForm.domain,
          "org": this.eventForm.org,
          "channel": this.eventForm.channel,
          "eventname": "",
          "description": "",
          "startdatetime": "",
          "enddatetime": "",
          "locid": "",
          "occurrence": "",
        }
        this.eventListLoading = true
        this.getEventList()
        this.messageService.openSnackBar(res.message, 'success');
      }
      else {
        this.saveLoading = false;
        this.eventFormSubmitted = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err => {
      this.isEditing = false;
    }
    )
  }

  cancel() {
    this.location.replaceState("/events");
    this.eventAddorNot = false
    this.disableDate=true;
    this.isEditing = false;
    this.eventFormSubmitted = false;
    this.eventIdToEdit = ""
    this.eventForm = {
      "domain": this.eventForm.domain,
      "org": this.eventForm.org,
      "channel": this.eventForm.channel,
      "eventname": "",
      "description": "",
      "startdatetime": "",
      "enddatetime": "",
      "locid": "",
      "occurrence": "",
    }
  }

  viewAttendanceForm(event: any) {

    const dialog = this.dialog.open(EventsAttendanceComponent, {
      width: '50vw',
      closeOnNavigation: true,
      panelClass: ['event-modal','mat-typography'],
      data: {
        event: event
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }


  newLocation() {
    const dialog = this.dialog.open(LocationsNewComponent, {
      width: '50vw',
      maxHeight: "90vh",
      closeOnNavigation: true,
      panelClass: ['event-modal','mat-typography'],
      data: {
        'org': this.eventForm.org
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.getLocation();
      }
    });
  }

  goToOrg() {
    this.router.navigateByUrl("organizations");
  }
  goToOrgEdit() {
    this.router.navigateByUrl(`organizations?org=${this.eventForm.org}`);
  }

}
