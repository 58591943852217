import { DatePipe, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SelectReferrerComponent } from '../select-referrer/select-referrer.component';
import { IMyDpOptions } from 'mydatepicker';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };
  @Input() status: string = 'Register'
  @Input() currentMember: any

  saveLoading: boolean = false;
  relations: any[] = [];
  memberList: any[] = []
  memberForm!: FormGroup;
  requiredMessage = 'This field may not be blank';
  submitted = false;
  requestBodyData: any;
  memberTypeList: any[] = [];
  selectedReferrerList: any[] = [
    { syskey: '', name: '' },
    { syskey: '', name: '' }
  ];

  nrcRegionCode: any = [];
  nrcTownshipCode: any = [];
  nrcType: any = [];
  submitLoading: boolean = false;

  previewurl: string = ''

  //images and file to upload
  selectedProfileImage?: File;
  tempProfileImage: any;
  profileUrl: any;
  requiredAttchments: any[] = [
    {
      name: "Attachment1",
      fileurl: '',
      filename: '',
      file: File || null,
      attachments_id: '',
    },
    {
      name: "Attachment2",
      fileurl: '',
      filename: '',
      file: File || null,
      attachments_id: '',

    },
    {
      name: "Attachment3",
      fileurl: '',
      filename: '',
      file: File || null,
      attachments_id: '',

    },
    {
      name: "Attachment4",
      fileurl: '',
      filename: '',
      file: File || null,
      attachments_id: '',

    },
    {
      name: "Attachment5",
      fileurl: '',
      filename: '',
      file: File || null,
      attachments_id: '',

    },
  ];
  selectedAttachments: any[] = []
  tempfilename: any = "";
  deletedprofilename = "";
  filelist: Array<any> = [];
  deletedfilelist: Array<any> = [];
  isApproving: boolean = false;
  isNotApproved: boolean = true;
  isPaid: boolean = false;
  newUser: any;
  userCreated: boolean = false;
  userId: string = ''

  reader = new FileReader();
  newUserSyskey: string = ''

  gettingNrcCode: boolean = false;


  referrer1_id: string = ''
  referrer2_id: string = ''
  referrer1_name: string = ''
  referrer2_name: string = ''


  @ViewChild('fileInput1') fileInput1: any;
  @ViewChild('fileInput2') fileInput2: any;
  @ViewChild('fileInput3') fileInput3: any;
  @ViewChild('fileInput4') fileInput4: any;
  @ViewChild('fileInput5') fileInput5: any;

  attachment1Selected: boolean = false;
  attachment2Selected: boolean = false;
  attachment3Selected: boolean = false;
  attachment4Selected: boolean = false;
  attachment5Selected: boolean = false;

  isAttachment1Editing: boolean = false;
  isAttachment2Editing: boolean = false;
  isAttachment3Editing: boolean = false;
  isAttachment4Editing: boolean = false;
  isAttachment5Editing: boolean = false;

  att1downloading: boolean = false;
  att2downloading: boolean = false;
  att3downloading: boolean = false;
  att4downloading: boolean = false;
  att5downloading: boolean = false;

  attachement1filename: string = ''
  attachement2filename: string = ''
  attachement3filename: string = ''
  attachement4filename: string = ''
  attachement5filename: string = ''

  attachement1fileid: string = ''
  attachement2fileid: string = ''
  attachement3fileid: string = ''
  attachement4fileid: string = ''
  attachement5fileid: string = ''

  isAttachment1Required: boolean = false;
  isAttachment2Required: boolean = false;
  isAttachment3Required: boolean = false;
  isAttachment4Required: boolean = false;
  isAttachment5Required: boolean = false;

  bmemberInfoToEdit: any;
  filelistreq: any[] = []

  subscription!: Subscription;

  disableRegister: boolean = false;

  registerSubmitted: boolean = false;

  regionList: any = [];
  filteredTowns: any[] = [];
  filteredWards: any[] = [];

  constructor(
    private kunyekService: KunyekService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private messageService: MessageService,
    public allInOneservice: AllInOneService,
    public datepipe: DatePipe,
    private http: HttpClient,
    private location: Location
  ) {
    this.memberForm = this.fb.group({
      name: ["", Validators.required],
      subname: [""],
      birthplace: [""],
      birthdate: [""],
      fathername: [""],
      mothername: [""],
      education: [""],
      currentjob: [""],
      department: [""],
      retiredjob: [""],
      address: [""],
      current_address: [""],
      religion: [""],
      nationality: [""],
      job: [""],
      membertypeid: "ASDLFKJ",
      phone: ["", Validators.required],
      nrcregioncode: ["", Validators.required],
      nrctownshipcode: ["", Validators.required],
      nrctype: ["", Validators.required],
      nrcnumber: ["", Validators.required],
      referrer1_id: [""],
      referrer2_id: [""],
      region: ['',],
      town: ['',],
      ward: ['',]
    })
  }

  ngOnInit(): void {
    this.getMemberTypes();
    this.membertype.disable()
    this.membertype.setValue("ASDLFKJ")
    this.getNrcCode();
    this.loadMemberInformations();
    this.getRegion()

    this.selectedAttachments = this.requiredAttchments
    if (this.currentMember && this.currentMember.formstatus == '002') {
      this.isNotApproved = false
    }
    this.selectedAttachments.forEach((i: any) => {
      this.filelistreq.push({
        name: i.name,
        filename: i.filename
      })
    })
  }

  get name() {
    return this.memberForm.get("name")!
  }

  get phone() {
    return this.memberForm.get("phone")!
  }

  get birthdate() {
    return this.memberForm.get("birthdate")!
  }

  get membertype() {
    return this.memberForm.get("membertypeid")!
  }

  get nrcregioncode() {
    return this.memberForm.get('nrcregioncode')!
  }

  get nrctownshipcode() {
    return this.memberForm.get('nrctownshipcode')!
  }

  get nrctype() {
    return this.memberForm.get('nrctype')!
  }

  get nrcnumber() {
    return this.memberForm.get('nrcnumber')!
  }

  get referrer1() {
    return this.memberForm.get('referrer1_id')!
  }

  get referrer2() {
    return this.memberForm.get('referrer2_id')!
  }

  get region() {
    return this.memberForm.get('region');
  }
  get town() {
    return this.memberForm.get('town');
  }
  get ward() {
    return this.memberForm.get('ward');
  }


  getMemberTypes() {
    this.kunyekService.getKMemberTypesList({ searchName: '' }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.memberTypeList = res.datalist;
        }
      },
      error: (err) => {
        console.log(err)

      }
    })
  }


  getNrcCode() {
    this.gettingNrcCode = true;
    const data = {
    };
    this.kunyekService.getNrcCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.nrcRegionCode = res.datalist.regioncode;
          this.nrcTownshipCode = res.datalist.shortcode;
          this.nrcType = res.datalist.nrctype;
          this.gettingNrcCode = false;
        } else {
          this.gettingNrcCode = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingNrcCode = false;
      }
    );
  }

  getRegion() {
    const data = {};
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.regionList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onRegionChange(region: any) {
    const data = {
      region: region.target.value
    };
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredTowns = res.datalist;
          this.filteredWards = [];
          this.resetDropdowns('town');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  resetDropdowns(type: string) {
    if (type === 'town') {
      this.filteredWards = [];
    }
  }

  onTownChange(town: any) {
    const data = {
      town: town.target.value
    };
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredWards = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  closeForm() {
    this.memberForm.reset();
    this.submitted = false;
    this.location.back()
  }

  handleSaveMember() {
    this.saveLoading = true;
    this.requestBodyData = this.memberForm.value

    if (this.memberForm.valid) {
      let nrc: string;
      nrc = this.memberForm.get('nrcregioncode')?.value + '/' + this.memberForm.get('nrctownshipcode')?.value +
        '(' + this.memberForm.get('nrctype')?.value + ')' +
        this.memberForm.get('nrcnumber')?.value

      this.uploadFiles();
      const formattedBirthDate = this.birthdate?.value?.formatted || this.birthdate?.value;
      this.requestBodyData = {
        ...this.requestBodyData,
        postcode: this.requestBodyData?.ward,
        nrc: nrc,
        birthdate: this.allInOneservice.convertDate(formattedBirthDate),
        tempfilename: this.tempfilename,
        profileimage: this.selectedProfileImage?.name,
        filelist: this.filelistreq,
        deletedfilelist: this.deletedfilelist,
        usersyskey: this.allInOneservice.getKunyekUserId(),
        formstatus: '004'
      }
      if (this.status == 'Edit') {
        this.requestBodyData['formstatus'] = this.registerSubmitted == true ? '001' : '004'

        this.requestBodyData['membertypeid'] = this.currentMember.memberType ? this.currentMember.memberType.membertypeid : ""
        for (let i = 0; i >= this.selectedAttachments.length; i++) {
          this.filelistreq[i].name = this.selectedAttachments[i].name;
          this.filelistreq[i].filename = this.selectedAttachments[i].filename
        }
        this.requestBodyData.referrersList = this.selectedReferrerList
        this.requestBodyData = { ...this.requestBodyData, syskey: this.currentMember.syskey, parentid: this.currentMember.parentid ? this.currentMember.parentid : '', filelist: this.filelistreq }

        this.subscription = this.kunyekService.editKMember(this.requestBodyData).subscribe(
          (res: any) => {
            this.saveLoading = false;
            if (res.returncode == '300') {
              this.messageService.openSnackBar(res.message, 'success');
              this.userCreated = true;
              this.loadMemberInformations()
            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
            this.messageService.openSnackBar(err, 'warn');
          }
        )
      } else if (this.status == 'Register') {
        this.newUser = this.memberForm.value
        this.requestBodyData.parentid = ''
        this.requestBodyData['membertypeid'] = "ASDLFKJ"
        this.requestBodyData.referrersList = this.selectedReferrerList
        this.kunyekService.addKMember(this.requestBodyData).subscribe(
          (res: any) => {
            this.saveLoading = false;
            this.currentMember = {}
            if (res.returncode == '300') {
              this.currentMember = { ...this.currentMember, syskey: res.syskey, name: this.name.value, memberType: { membertypeid: 'ASDLFKJ' } }
              this.userCreated = true;
              this.loadMemberInformations()
              this.messageService.openSnackBar(res.message, 'success');
            } else if (res.returncode == 301) {
              this.messageService.openSnackBar("User already exits with this phone number or email", "warn")
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.status = 'Edit'
            this.saveLoading = false;
          },
          (err) => {
            this.messageService.openSnackBar(err, 'warn');
            this.saveLoading = false;
          }
        )
      }
    } else {
      this.submitted = true;
      this.saveLoading = false;
      this.messageService.openSnackBar('Invalid Form', 'warn')
    }
  }

  checkCurrentStatus() {
    if (this.status == 'Edit') {
      if (this.currentMember.profileimage) {
        let piamge = JSON.parse(this.currentMember.profileimage);
        this.profileUrl = piamge ? piamge.fileurl : ""
      }
      this.getRelationList();
      this.memberForm.setValue({
        name: this.currentMember.name,
        subname: this.currentMember.subname,
        birthplace: this.currentMember.birthplace,
        birthdate: this.allInOneservice.changeStringToDateobject(this.currentMember.birthdate),
        nrcregioncode: this.currentMember.nrcregioncode,
        nrctownshipcode: this.currentMember.nrctownshipcode,
        nrctype: this.currentMember.nrctype,
        nrcnumber: this.currentMember.nrcnumber,
        fathername: this.currentMember.fathername,
        mothername: this.currentMember.mothername,
        education: this.currentMember.education,
        currentjob: this.currentMember.currentjob,
        department: this.currentMember.department,
        retiredjob: this.currentMember.retiredjob,
        address: this.currentMember.address,
        current_address: this.currentMember.current_address,
        religion: this.currentMember.religion,
        nationality: this.currentMember.nationality,
        job: this.currentMember.job,
        membertypeid: this.currentMember.memberType ? this.currentMember.memberType.membertypeid : "",
        phone: this.currentMember.phone,
        referrer1_id: this.currentMember.referrer1,
        referrer2_id: this.currentMember.referrer2,
        region: this.currentMember.directory?.region || '',
        town: this.currentMember.directory?.town || '',
        ward: this.currentMember.directory?.postcode || ''
      })
      if (this.currentMember && this.currentMember.directory) {
        this.onRegionChange({ target: { value: this.currentMember?.directory?.region } });
        this.onTownChange({ target: { value: this.currentMember?.directory?.town } });
      }
      this.selectedAttachments = this.requiredAttchments
      if (this.currentMember.filelist.length > 0) {
        this.currentMember.filelist.forEach((i: any) => {
          this.selectedAttachments.forEach((j: any) => {
            if (i.name === j.name) {
              j.filename = i.filename
              j.fileurl = i.fileurl
              j.attachments_id = i.attachments_id
            }
            console.log(i)
            if (i.name == "Attachment1" && i.filename !== '') {
              this.isAttachment1Editing = true;
              this.attachement1filename = this.selectedAttachments[0].filename
              this.attachement1fileid = this.selectedAttachments[0].attachment_id
              this.attachment1Selected = true;
            }
            if (i.name == "Attachment2" && i.filename !== '') {
              this.isAttachment2Editing = true;
              this.attachement2filename = this.selectedAttachments[1].filename
              this.attachement2fileid = this.selectedAttachments[1].attachment_id
              this.attachment2Selected = true;
            }
            if (i.name == "Attachment3" && i.filename !== '') {
              this.isAttachment3Editing = true;
              this.attachement3filename = this.selectedAttachments[2].filename
              this.attachement3fileid = this.selectedAttachments[2].attachment_id
              this.attachment3Selected = true;
            }
            if (i.name == "Attachment4" && i.filename !== '') {
              this.isAttachment4Editing = true;
              this.attachement4filename = this.selectedAttachments[3].filename
              this.attachement4fileid = this.selectedAttachments[3].attachment_id
              this.attachment4Selected = true;
            }
            if (i.name == "Attachment5" && i.filename !== '') {
              this.isAttachment5Editing = true;
              this.attachement5filename = this.selectedAttachments[4].filename
              this.attachement5fileid = this.selectedAttachments[4].attachment_id
              this.attachment5Selected = true;
            }

          })
        })
      }
      if (this.currentMember.referrer1_person) {
        this.referrer1_name = this.currentMember.referrer1_person.name,
          this.selectedReferrerList[0] = {
            syskey: this.currentMember.referrer1_person.syskey,
            name: this.currentMember.referrer1_person.name
          }
      }

      if (this.currentMember.referrer2_person) {
        this.referrer2_name = this.currentMember.referrer2_person.name
        this.selectedReferrerList[1] = {
          syskey: this.currentMember.referrer2_person.syskey,
          name: this.currentMember.referrer2_person.name
        }
      }
      if (this.currentMember.formstatus == '001' || this.currentMember.formstatus == '002') {
        this.registerSubmitted = true;
        this.memberForm.disable()
      }
    }
  }

  fomratDate(value: string): string {
    if (value === "") return "";
    const pattern = /(\d{4})(\d{2})(\d{2})/;
    return value.replace(pattern, '$1-$2-$3');
  }

  onFileSelected(event: Event) {
    if (this.profileUrl) {
      this.deletedprofilename = JSON.parse(this.currentMember.profileimage).filename;
    }

    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedProfileImage = input.files[0];
      this.reader.readAsDataURL(this.selectedProfileImage);
      this.reader.onload = (event) => {
        this.profileUrl = this.reader.result;
      }
    }
  }



  async uploadFiles() {

    const defaultpath = 'kokaimember/members';

    if (this.tempfilename == '') {
      const date = new Date();
      const millisecond = date.getMilliseconds();
      this.tempfilename = this.datepipe.transform(
        date,
        'yyyyMMddhhmmss' + millisecond,
      );
    }

    //upload member profile iamge
    if (this.selectedProfileImage) {
      let profilepath = this.status != 'Edit' ?
        `${defaultpath}/${this.tempfilename}/${this.selectedProfileImage.name}`
        : `${defaultpath}/${this.currentMember.syskey}/${this.selectedProfileImage.name}`
      const upload_aws = await this.allInOneservice.uploadAnswerFile(
        this.selectedProfileImage,
        profilepath,
      );
      if (!upload_aws) {
        this.messageService.openSnackBar(
          'Unknown error occured while uploading your files.',
          'warn',
        );
        return;
      }
      this.tempProfileImage = profilepath;
    }

    //upload member attachements
    if (this.selectedAttachments.length > 0) {
      console.log(this.selectedAttachments)
      for (let i = 0; i < this.selectedAttachments.length; i++) {

        //upload only 
        if (!this.selectedAttachments[i].attachments_id && this.selectedAttachments[i].file && this.selectedAttachments[i].filename !== '') {
          let path = this.status != 'Edit' ?
            `kokaimember/attachments/${this.tempfilename}/${this.selectedAttachments[i].filename}`
            : `kokaimember/attachments/${this.currentMember.syskey}/${this.selectedAttachments[i].filename}`

          const filetype = this.selectedAttachments[i].filename.split('.').pop();
          const upload_aws = await this.allInOneservice.uploadAnswerFile(
            this.selectedAttachments[i].file,
            path,
          );
          if (!upload_aws) {
            this.messageService.openSnackBar(
              'Unknown error occured while uploading your files.',
              'warn',
            );
            return;
          }
        }

      }
    }

  }

  removeAttachMent(index: number) {
    if (this.selectedAttachments[index].attachments_id) {
      this.deletedfilelist.push(this.selectedAttachments[index].filename);
    }
    this.selectedAttachments.splice(index, 1);
  }

  approveMember() {
    this.isApproving = true;
    this.kunyekService.approveMember({
      usersyskey: this.currentMember.syskey,
      formstatus: '002',
      fromdate: '',
      todate: '',
      remark: ''
    }).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.isApproving = false;
          this.messageService.openSnackBar(res.message, 'success');
          this.currentMember.formstatus = res.formstatus
          this.currentMember['approved'] = res.approved
          this.isNotApproved = false;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.isApproving = false;
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.isApproving = false;
      }
    )
  }

  addRelativeUser() {
    this.currentMember = this.currentMember
    this.status = 'Add Relative'
  }


  getRelationList() {
    if (this.currentMember) {
      this.kunyekService.getKMembers({ parentid: this.currentMember.syskey }).subscribe(
        (res: any) => {
          if (res.datalist.length > 0) {
            this.relations = res.datalist
            console.log(this.relations)
          } else {
            this.relations = []
          }
        }, (err: any) => {
          console.log(err)
          this.messageService.openSnackBar(err, 'warn')
        }
      )
    }
  }

  closeByRelativeForm() {
    this.status = 'Edit'
    this.getRelationList()
  }

  onAttachment1Selected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.attachment1Selected = true
      const attachemnt = input.files[0];
      const timestamp = Date.now();
      this.reader.readAsDataURL(attachemnt);
      this.reader.onload = (event) => {
        this.selectedAttachments[0] = {
          name: "Attachment1",
          fileurl: this.reader.result,
          filename: `${timestamp}_${attachemnt.name}`,
          file: attachemnt,
        };
        this.assignAttachmentsForRequest(0, `${timestamp}_${attachemnt.name}`)
      }
    }
  }
  onAttachment2Selected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.attachment2Selected = true
      const attachemnt = input.files[0];
      const timestamp = Date.now();
      this.reader.readAsDataURL(attachemnt);
      this.reader.onload = (event) => {
        this.selectedAttachments[1] = {
          name: "Attachment2",
          fileurl: this.reader.result,
          filename: `${timestamp}_${attachemnt.name}`,
          file: attachemnt,
        }
        this.assignAttachmentsForRequest(1, `${timestamp}_${attachemnt.name}`)
      }
    }
  }
  onAttachment3Selected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.attachment3Selected = true
      const attachemnt = input.files[0];
      const timestamp = Date.now();
      this.reader.readAsDataURL(attachemnt);
      this.reader.onload = (event) => {
        this.selectedAttachments[2] = {
          name: "Attachment3",
          fileurl: this.reader.result,
          filename: `${timestamp}_${attachemnt.name}`,
          file: attachemnt,
        }
        this.assignAttachmentsForRequest(2, `${timestamp}_${attachemnt.name}`)
      }
    }
  }
  onAttachment4Selected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.attachment4Selected = true
      const attachemnt = input.files[0];
      const timestamp = Date.now();
      this.reader.readAsDataURL(attachemnt);
      this.reader.onload = (event) => {
        this.selectedAttachments[3] = {
          name: "Attachment4",
          fileurl: this.reader.result,
          filename: `${timestamp}_${attachemnt.name}`,
          file: attachemnt,
        }
      }
      this.assignAttachmentsForRequest(3, `${timestamp}_${attachemnt.name}`)
    }
  }
  onAttachment5Selected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.attachment5Selected = true
      const attachemnt = input.files[0];
      const timestamp = Date.now();
      this.reader.readAsDataURL(attachemnt);
      this.reader.onload = (event) => {
        this.selectedAttachments[4] = {
          name: "Attachment5",
          fileurl: this.reader.result,
          filename: `${timestamp}_${attachemnt.name}`,
          file: attachemnt,
        }
        this.assignAttachmentsForRequest(4, `${timestamp}_${attachemnt.name}`)
      }
    }
  }

  assignAttachmentsForRequest(index: any, filename: string) {
    this.filelistreq[index] = {
      name: `Attachment${index + 1}`,
      filename: filename
    }
  }

  validateAttachments(): boolean {
    this.isAttachment1Required = !this.attachment1Selected;
    this.isAttachment2Required = !this.attachment2Selected;
    this.isAttachment3Required = !this.attachment3Selected;
    this.isAttachment4Required = !this.attachment4Selected;
    this.isAttachment5Required = !this.attachment5Selected;

    return this.attachment1Selected && this.attachment2Selected && this.attachment3Selected && this.attachment4Selected && this.attachment5Selected;
  }

  removeAttachement(id: number) {
    this.deletedfilelist.push(this.selectedAttachments[id].filename)
    this.selectedAttachments[id] = {
      ...this.selectedAttachments[id],
      filename: '',
      file: '',
      fileurl: ''
    }
    this.filelistreq[id].filename = ''
  }

  revert(index: number) {
    if (index == 0) {
      this.isAttachment1Editing = true;
    }

    if (index == 1) {
      this.isAttachment2Editing = true;
    }
    if (index == 2) {
      this.isAttachment3Editing = true;
    }
    if (index == 3) {
      this.isAttachment4Editing = true;
    }
    if (index == 4) {
      this.isAttachment5Editing = true;
    }
  }

  downloadAttachment(id: number) {
    if (id == 0) {
      this.att1downloading = true;
      const filetype = this.selectedAttachments[0].filename.split('.').pop();
      if (filetype == 'jpg' || filetype == 'png' || filetype == 'jpeg') {
        this.downloadFile(id).subscribe((response: any) => {
          saveAs(response, this.selectedAttachments[0].filename);
          this.att1downloading = false;
        }),
          (error: any) => {
            this.att1downloading = false;
            this.messageService.openSnackBar('Download failed.', 'warn');
          };
      }
      else {
        var link = document.createElement('a');
        link.href = this.selectedAttachments[0].fileurl;
        link.download = this.selectedAttachments[0].filename;
        link.target = '_blank';
        link.click();
        link.remove();
        this.att1downloading = false;
      }
    }

    if (id == 1) {
      this.att2downloading = true;
      const filetype = this.selectedAttachments[1].filename.split('.').pop();
      if (filetype == 'jpg' || filetype == 'png' || filetype == 'jpeg') {
        this.downloadFile(id).subscribe((response: any) => {
          saveAs(response, this.selectedAttachments[1].filename);
          this.att2downloading = false;
        }),
          (error: any) => {
            this.att2downloading = false;
            this.messageService.openSnackBar('Download failed.', 'warn');
          };
      }
      else {
        var link = document.createElement('a');
        link.href = this.selectedAttachments[1].fileurl;
        link.download = this.selectedAttachments[1].filename;
        link.target = '_blank';
        link.click();
        link.remove();
        this.att2downloading = false;
      }
    }

    if (id == 2) {
      this.att3downloading = true;
      const filetype = this.selectedAttachments[id].filename.split('.').pop();
      if (filetype == 'jpg' || filetype == 'png' || filetype == 'jpeg') {
        this.downloadFile(id).subscribe((response: any) => {
          saveAs(response, this.selectedAttachments[id].filename);
          this.att3downloading = false;
        }),
          (error: any) => {
            this.att3downloading = false;
            this.messageService.openSnackBar('Download failed.', 'warn');
          };
      }
      else {
        var link = document.createElement('a');
        link.href = this.selectedAttachments[id].fileurl;
        link.download = this.selectedAttachments[id].filename;
        link.target = '_blank';
        link.click();
        link.remove();
        this.att3downloading = false;
      }
    }

    if (id == 3) {
      this.att4downloading = true;
      const filetype = this.selectedAttachments[id].filename.split('.').pop();
      if (filetype == 'jpg' || filetype == 'png' || filetype == 'jpeg') {
        this.downloadFile(id).subscribe((response: any) => {
          saveAs(response, this.selectedAttachments[id].filename);
          this.att4downloading = false;
        }),
          (error: any) => {
            this.att4downloading = false;
            this.messageService.openSnackBar('Download failed.', 'warn');
          };
      }
      else {
        var link = document.createElement('a');
        link.href = this.selectedAttachments[id].fileurl;
        link.download = this.selectedAttachments[id].filename;
        link.target = '_blank';
        link.click();
        link.remove();
        this.att4downloading = false;
      }
    }

    if (id == 4) {
      this.att5downloading = true;
      const filetype = this.selectedAttachments[id].filename.split('.').pop();
      if (filetype == 'jpg' || filetype == 'png' || filetype == 'jpeg') {
        this.downloadFile(id).subscribe((response: any) => {
          saveAs(response, this.selectedAttachments[id].filename);
          this.att5downloading = false;
        }),
          (error: any) => {
            this.att5downloading = false;
            this.messageService.openSnackBar('Download failed.', 'warn');
          };
      }
      else {
        var link = document.createElement('a');
        link.href = this.selectedAttachments[id].fileurl;
        link.download = this.selectedAttachments[id].filename;
        link.target = '_blank';
        link.click();
        link.remove();
        this.att5downloading = false;
      }
    }

  }

  downloadFile(id: number): any {
    return this.http.get(this.selectedAttachments[0].fileurl, { responseType: 'blob' });
  }

  viewImage(fileurl: string) {
    this.previewurl = fileurl;
  }

  downloadImage() {
    this.http.get(this.previewurl, { responseType: 'blob' }).subscribe((response: any) => {
      saveAs(response, this.selectedAttachments[0].filename);
      this.att1downloading = false;
    }),
      (error: any) => {
        this.att1downloading = false;
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  openMemberModal(refNumber: number) {
    const dialog = this.dialog.open(SelectReferrerComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        selectedReferrersList: this.selectedReferrerList,
        isLeaveOpening: false,
        referrer_number: refNumber
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {

        this.selectedReferrerList = dialogResult.data
        console.log(this.selectedReferrerList)
        if (this.selectedReferrerList.length > 0) {
          this.referrer1_name = this.selectedReferrerList[0].name
          this.referrer1.setValue(this.selectedReferrerList[0].syskey)
        }
        if (this.selectedReferrerList.length > 1) {
          this.referrer2_name = this.selectedReferrerList[1].name
          this.referrer2.setValue(this.selectedReferrerList[1].syskey)
        }

      }
    });
  }

  loadMemberInformations() {
    this.kunyekService.getKMembers({ usersyskey: this.allInOneservice.getKunyekUserId() }).subscribe(
      (res: any) => {
        if (res.datalist.length > 0) {
          this.currentMember = res.datalist[0]
          this.status = 'Edit'
          this.disableRegister = true;
          this.checkCurrentStatus()
          this.getRelationList()
        } else {
          this.currentMember = {}
        }
      }, (err: any) => {
        console.log(err)
        this.messageService.openSnackBar(err, 'warn')
      }
    )
  }

  submitRegister() {
    this.submitLoading = true;
    this.requestBodyData = this.memberForm.value
    let nrc: string;
    nrc = this.memberForm.get('nrcregioncode')?.value + '/' + this.memberForm.get('nrctownshipcode')?.value +
      '(' + this.memberForm.get('nrctype')?.value + ')' +
      this.memberForm.get('nrcnumber')?.value
    this.status = 'Edit'
    this.requestBodyData['membertypeid'] = this.currentMember.memberType ? this.currentMember.memberType.membertypeid : ""
    for (let i = 0; i >= this.selectedAttachments.length; i++) {
      this.filelistreq[i].name = this.selectedAttachments[i].name;
      this.filelistreq[i].filename = this.selectedAttachments[i].filename
    }

    this.requestBodyData.referrersList = this.selectedReferrerList
    this.requestBodyData = { ...this.requestBodyData, syskey: this.currentMember.syskey, parentid: this.currentMember.parentid ? this.currentMember.parentid : '', filelist: this.filelistreq }
    const isValid = true;

    this.requestBodyData = {
      ...this.requestBodyData,
      postcode: this.requestBodyData?.ward,
      nrc: nrc,
      birthdate: this.allInOneservice.formatDate(this.birthdate?.value),
      tempfilename: this.tempfilename,
      profileimage: this.selectedProfileImage?.name,
      filelist: this.filelistreq,
      deletedfilelist: this.deletedfilelist,
      usersyskey: this.allInOneservice.getKunyekUserId(),
      deletedprofilename: this.deletedprofilename
    }
    if (isValid) {
      this.uploadFiles()
      this.subscription = this.kunyekService.editKMember(this.requestBodyData).subscribe(
        (res: any) => {
          this.submitLoading = false;
          if (res.returncode == '300') {
            this.messageService.openSnackBar(res.message, 'success');
            this.userCreated = true;
            this.registerSubmitted = true
            this.checkCurrentStatus()
          } else {
            this.submitLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.submitLoading = false;
          this.messageService.openSnackBar(err, 'warn');
        }
      )
    } else {
      this.submitLoading = false;
      this.messageService.openSnackBar('Invalid Form', 'warn')
    }
  }

  editBmember(index: number) {
    this.status = 'Edit Relative'
    this.bmemberInfoToEdit = this.relations[index]
  }
}
