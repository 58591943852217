<div mat-dialog-content>
    <p *ngIf = "!cc && !isbold">{{cmessage}}</p>
    <p *ngIf = "cc && !isbold">Are you sure you want to save these all Class Code?</p>
    <p *ngIf="isbold">
        {{cmessage}}  <b> {{ rulename }} </b> ?
    </p>
</div>

<div mat-dialog-actions align="end">
    <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary mx-2">
        Cancel
    </button>
    <button type="button" (click)="onConfirm()" class="btn btn-custom" cdkFocusInitial>
        Ok
    </button>

</div>