<div class="container-fluid">
  <div class="content">
    <app-office-breadcrumb *ngIf="!isDetailView" s_breadcrumb="Requisition Approval">
    </app-office-breadcrumb>
    <app-office-breadcrumb *ngIf="isDetailView" s_breadcrumb="Requisition Approval"
      [t_breadcrumb]="'Ref No: ' + selectedRequest.refno" (emit)="cancel()"></app-office-breadcrumb>
    <ng-container *ngIf="isDetailView; else listView">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom mb-3" (click)="cancel()" *ngIf="!isApproving && !isRejecting">
          Cancel
        </button>
        <!-- <app-button-loader *ngIf="selectedRequest.status == '001' && !isApproving && !isRejecting" class="mb-3" (click)="submit()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader> -->
        <ng-container *ngIf="selectedRequest.status == '002' && selectedRequest.hrstatus != '002' && selectedRequest.hrstatus != '004'">
          <div class="mb-3" *ngIf="!isApproving && !isRejecting">
            <div class="line"></div>
          </div>
          <!-- <app-button-loader class="mb-3" (click)="submitApproval('003')" [button_text]="'Reject'"
            [load_button_text]="'Rejecting'" [isLoading]="isRejecting" [outlinedanger]="true">
          </app-button-loader> -->
          <app-button-loader class="mb-3" (click)="rejectReason()" [button_text]="'Reject'"
            [load_button_text]="'Rejecting'" [isLoading]="isRejecting" [outlinedanger]="true">
          </app-button-loader>
        </ng-container>
        <ng-container *ngIf="selectedRequest.status == '003'">
          <div class="mb-3" *ngIf="!isApproving && !isRejecting">
            <div class="line"></div>
          </div>
          <app-button-loader class="mb-3" (click)="submitApproval('002')" [button_text]="'Approve'"
            [load_button_text]="'Approving'" [isLoading]="isApproving" *ngIf="!isRejecting">
          </app-button-loader>
        </ng-container>
        <ng-container *ngIf="selectedRequest.status == '001'">
          <div class="mb-3" *ngIf="!isApproving && !isRejecting">
            <div class="line"></div>
          </div>
          <app-button-loader class="mb-3 me-2" (click)="rejectReason()" [button_text]="'Reject'"
            [load_button_text]="'Rejecting'" [isLoading]="isRejecting" [outlinedanger]="true" *ngIf="!isApproving">
          </app-button-loader>
          <app-button-loader class="mb-3" (click)="submitApproval('002')" [button_text]="'Approve'"
            [load_button_text]="'Approving'" [isLoading]="isApproving" *ngIf="!isRejecting">
          </app-button-loader>
        </ng-container>
        <div class="d-flex align-items-center ms-auto mb-3"
          *ngIf=" selectedRequest.status != '001' || (selectedRequest.status == '001')">
          <span [title]="selectedRequest.statusname" class="status" [ngClass]="{
                      'status-green': selectedRequest.status == '002',
                      'status-red': selectedRequest.status == '003',
                      'status-gray': selectedRequest.status == '001',
                      'status-main': selectedRequest.status == '004'}">
            <span>
              {{ selectedRequest.statusname }}
            </span>
          </span>
        </div>
      </div>
      <div>
        <div class="card card-body my-card mb-3">
          <div class="row">
            <div class="mb-3 col-md-6">
              <label for="userid" class="form-label">Ref No: </label>
              <div class="input-group">
                <input type="text" class="form-control" id="userid" name="userid"
                  [value]="selectedRequest.refno" disabled="true" />
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <label for="name" class="form-label"> Requisition Date </label>
              <input type="text" class="form-control" id="date" name="date"
                [value]="allinoneService.formatDBToShow(selectedRequest.requisitiondate)" disabled="true" />
            </div>
            <!-- <div class="mb-3 col-md-12">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="formpriority" id="critical" value="00900"
                [checked]="selectedRequest.priority =='00900' " disabled/>
                <label class="form-check-label" for="critical"> High </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input inline" type="radio" name="formpriority" id="high" value="00500"
                [checked]="selectedRequest.priority =='00500' " disabled/>
                <label class="form-check-label" for="high">
                  Medium
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input inline" type="radio" name="formpriority" id="low" value="0010"
                [checked]="selectedRequest.priority =='0010' " disabled/>
                <label class="form-check-label" for="low">
                  Low
                </label>
              </div>
            </div> -->

            <div class="mb-3 col-md-12">
              <label for="urgency" class="form-label">Urgency</label>
              <select
                class="form-select"
                name="urgency"
                id="urgency"
                [(ngModel)]="selectedRequest.priority"
                disabled
              >
                <option value="00900">High</option>
                <option value="00500">Medium</option>
                <option value="0010">Low</option>
                <!-- <option *ngFor="let shift of shiftList" [value]="shift.shiftid">
                  {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
                </option> -->
              </select>
            </div>

            <!-- <div class="mb-3 col-md-6">
              <label for="product" class="form-label"> Product </label>
              <input type="text" class="form-control" id="product" name="product"
                [value]="selectedRequest.productname" disabled="true" />
            </div> -->
            
            <div class="mb-3 col-md-6">
              <label for="department" class="form-label"> Department </label>
              <input type="text" class="form-control" id="department" name="department"
                [value]="selectedRequest.department" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
              <label for="division" class="form-label"> Division </label>
              <input type="text" class="form-control" id="division" name="division"
                [value]="selectedRequest.division" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
              <label for="project" class="form-label"> Project </label>
              <input type="text" class="form-control" id="project" name="project"
                [value]="selectedRequest.projectname" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
              <label for="targetdate" class="form-label"> Recruitment Date </label>
              <input type="text" class="form-control" id="targetdate" name="targetdate"
                [value]="allinoneService.formatDBToShow(selectedRequest.targetdate)" disabled="true" />
            </div>
            <div class="mb-3 col-md-12">
              <label for="requestreason" class="form-label"> Reason </label>
              <input type="text" class="form-control" id="requestreason" name="requestreason"
                [value]="selectedRequest.requestreasonname" disabled="true" />
            </div>
            <div class="mb-3 col-md-12">
              <label for="name" class="form-label"> Remark </label>
              <textarea class="form-control" id="remark" [value]="selectedRequest.remark" name="remark" rows="3"
               disabled="true"></textarea>
            </div>
            
          </div>

        </div>
        <!-- <div class="row"> -->
        <!-- <div *ngFor="let pos of selectedRequest.positions; let index = index">
          <div class="card card-body my-card mb-3">
            <div class="info mb-3">
              Position
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label class="form-label" [for]="pos.positionid + 'no'">No. of People</label>
                <input type="text" class="form-control" [id]="pos.positionid + 'no'" [name]="pos.positionid + 'no'"
                [value]="pos.people" disabled />
              </div>
              <div class="col-md-6 mb-3">
                <label class="form-label" [for]="pos.positionid + 'name'">Position</label>
                <input type="text" class="form-control" [id]="pos.positionid + 'name'" [name]="pos.positionid + 'name'"
                  [value]="pos.name" disabled />
              </div>
              
              <div class="col-md-6 mb-3">
                <label for="requirements" class="form-label"> Job Requirements </label>
                <textarea class="form-control" id="requirements" [value]="pos.requirements" name="requirements" rows="5"
                  disabled="true"></textarea>
              </div>
              <div class="col-md-6 mb-3">
                <label for="desc" class="form-label"> Job Description </label>
                <textarea class="form-control" id="desc" [value]="pos.descriptions" name="desc" rows="5"
                  disabled="true"></textarea>
              </div>
            </div>
          </div>
        </div> -->
        
        <div class="card card-body my-card mb-3">
          <div class="info mb-3">
            Position
          </div>
          <div class="row">
            <!-- <div class="col-md-6 mb-3">
              <label class="form-label" for="people">No. of People</label>
              <input type="number" class="form-control" id="people" name="people" #people="ngModel"
              [(ngModel)]="submitForm.people" min="0" [value]="selectedRequest.people"
              [ngClass]="{'is-invalid' : formSubmitted && people.invalid}" required/>
            </div> -->
            <div class="col-md-6 mb-3">
              <label class="form-label" for="people">No of Opening</label>
              <input type="number" class="form-control" id="people" name="people" #people="ngModel"
              [(ngModel)]="submitForm.people" min="1" [disabled]="selectedRequest.status != '001'"
              [ngClass]="{'is-invalid' : formSubmitted && people.invalid}" required/>
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label" for="position">Position</label>
              <input type="text" class="form-control" id="position" name="position"
                [value]="selectedRequest.positionname" disabled />
            </div>
            <div class="col-md-6 mb-3">
              <label for="requirements" class="form-label"> Job Requirements </label>
              <textarea class="form-control" id="requirements" [value]="selectedRequest.requirements" name="requirements" rows="5"
                disabled="true"></textarea>
            </div>
            <div class="col-md-6 mb-3">
              <label for="desc" class="form-label"> Job Description </label>
              <textarea class="form-control" id="desc" [value]="selectedRequest.descriptions" name="desc" rows="5"
                disabled="true"></textarea>
            </div>
            <!-- <div class="mb-3 col-md-12">
              <label for="name" class="form-label"> Remark </label>
              <textarea class="form-control" id="remark" [value]="selectedRequest.remark" name="remark" rows="3"
               disabled="true"></textarea>
            </div> -->
          </div>
        </div>
        <div *ngIf="selectedRequest.status == '004' || selectedRequest.status == '003' " class="card card-body my-card mb-3">
          <div class="row">
            <div *ngIf="selectedRequest.status == '003'" class="mb-3 col-md-12">
              <label for="reason" class="form-label">Reject Reason</label>
              <textarea id="reason" name="reason" class="form-control" [(ngModel)]="selectedRequest.reason" rows="3"
                disabled></textarea>
            </div>
            <div *ngIf="selectedRequest.status == '004'" class="mb-3 col-md-12">
              <label for="comment" class="form-label">Comment</label>
              <textarea id="comment" name="comment" class="form-control" [(ngModel)]="selectedRequest.hrremark" rows="3"
                disabled></textarea>
            </div>
          </div>
        </div>

        <!-- </div> -->
      </div>
    </ng-container>
    <ng-template #listView>
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search By Ref No/Positions"
            aria-label="Search By Ref No/Positions" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <div class="" [matMenuTriggerFor]="statusmenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Status&nbsp;:&nbsp;{{ submitForm.requeststatusname }}
              <mat-menu #statusmenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let status of statusList" (click)="statusChange(status)">
                  {{ status.name }}
                </button>
              </mat-menu>
            </button>
          </div>
          <div class="line"></div>
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ (approvalList | filter : searchText: 'requisitionapproval' ).length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search By Ref No/Positions" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="approvalList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-2" mat-sort-header="refno">Ref No.</td>
              <td scope="col" class="col-md-2" mat-sort-header="positions"> Position </td>
              <td scope="col" class="col-md-3" mat-sort-header="people">No of Opening</td>
              <td scope="col" class="col-md-1" mat-sort-header="priority">Priority</td>
              <td scope="col" class="col-md-2" mat-sort-header="requisitiondate">Requisition Date</td>
              <td scope="col" class="col-md-1" mat-sort-header="targetdate">Recruitment Date</td>
              <td scope="col" class="col-md-2" mat-sort-header="status">Status</td>
              <td scope="col" class="col-md-2" mat-sort-header="requestedby">Requested By</td>
              <td scope="col" class="col-md-2" mat-sort-header="remark">Remark</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingApprovals">
            <tr>
              <td colspan="9" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingApprovals">
            <tr *ngIf="approvalList.length == 0">
              <td colspan="9" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let data of approvalList| filter : searchText: 'requisitionapproval' | paginate : { itemsPerPage: itemPerPage , currentPage : currentPage };let index = index">
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.refno">{{ data.refno }}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.position">{{ data.positionname }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.people">{{ data.people }}</span>
              </td>
              <td class="td-data" (click)="viewApproval(data)">
                <span [title]="data.priority" class="priority" [ngClass]="{'priorityprimary': data.priority == '00500' ,'priorityred':data.priority == '00900' }">{{ data.priority == '0010' ? 'Low' : data.priority == '00500' ? 'Medium' :
                  'High'}}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="allinoneService.formatDBToShow(data.requisitiondate)">{{
                  allinoneService.formatDBToShow(data.requisitiondate) }}</span>
              </td>
              <td class="td-data" (click)="viewApproval(data)">
                <span [title]="allinoneService.formatDBToShow(data.targetdate)">{{
                  allinoneService.formatDBToShow(data.targetdate)
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.statusname" class="status" [ngClass]="{
                      'status-green': data.status == '002',
                      'status-red': data.status == '003',
                      'status-gray': data.status == '001',
                      'status-main': data.status == '004'}">
                  {{ data.statusname }}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.createdbyname || data.createdby">{{ data.createdbyname || data.createdby }}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.remark">{{ data.remark }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event"
        *ngIf="approvalList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-template>
  </div>
</div>