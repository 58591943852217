import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-check-in-daterange-details',
  templateUrl: './check-in-daterange-details.component.html',
  styleUrls: ['./check-in-daterange-details.component.css'],
})
export class CheckInDaterangeDetailsComponent implements OnInit, OnDestroy {
  gettingDetails: boolean = false;
  connectSocketLoading: boolean = false;
  isFocus: boolean = false;
  isAdmin: boolean = false;

  searchText: string = '';
  tempType: string = '';
  p: number = 1;
  type: number = 5;

  socket!: WebSocket;
  connectionId: string = '';
  socketConnectionStatus: string = '';
  drsocketfileurl: string = '';

  updateCId: boolean = false;

  socketReceivedMessage: any;
  socketConnectTime: any;

  detailForm = {
    startdate: '',
    enddate: '',
    type: '',
    orgid: '',
    orgname: '',
    userid: '',
    username: '',
    days: '',
    hours: '',
    iohours: '',
  };

  domain: any = '';
  detailList: any[] = [];

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private filter: FilterPipe,
    private http: HttpClient
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'hradmin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl(
            this.allinoneService.isAttendanceView ? '/attendance/hxm' : '/hxm'
          );
        }
      }
    });
  }
  ngOnDestroy(): void {}

  search() {
    this.connectWebSocket();
    this.closeSocketWithTime();
  }

  ngOnInit(): void {
    this.domain = this.allinoneService.getDomain();
    this.tempType = this.route.snapshot.params['type'];
    var tempUser = JSON.parse(
      this.allinoneService.decrypt(
        this.allinoneService.replaceAll(
          this.route.snapshot.params['userdata'],
          '###',
          '/'
        )
      )
    );
    this.detailForm.userid = tempUser.userid;
    this.detailForm.username = tempUser.username;
    this.detailForm.days = tempUser.days;
    this.detailForm.hours = tempUser.hours;
    this.detailForm.iohours = tempUser.iohours;
    this.detailForm.orgid = this.route.snapshot.params['orgid'];
    if (
      this.tempType == 'timeinout' ||
      this.tempType == 'task' ||
      this.tempType == 'io'
    ) {
      if (this.tempType == 'timeinout' || this.tempType == 'io') {
        this.detailForm.type = 'inout';
        this.type = 3;
      } else {
        this.detailForm.type = 'tasks';
        this.type = 4;
      }
    } else {
      this.router.navigate(['/home']);
    }

    const index = this.allinoneService.orgs.findIndex(
      (x: any) => x.orgid == this.detailForm.orgid
    );
    if (index == -1) {
      this.router.navigate(['/home']);
    } else {
      this.detailForm.orgname = this.allinoneService.orgs[index].name;
    }
    var tempDate = this.route.snapshot.params['date'];
    this.detailForm.startdate = tempDate.substring(0, 8);
    this.detailForm.enddate = tempDate.substring(8, 16);

    // this.getDetails();
    this.search();
  }

  downloadSheet() {
    var exdata: any = [];

    var startd = formatDate(
      new Date(
        this.allinoneService.formatDBToShowInEdit(
          this.detailForm.startdate,
          '/'
        )
      ),
      'dd-MM-yyyy',
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(
        this.allinoneService.formatDBToShowInEdit(this.detailForm.enddate, '/')
      ),
      'dd-MM-yyyy',
      'en-US'
    ).toString();
    var temp = this.detailForm.type == 'inout' ? 'Time In/Out' : 'Tasks';
    var tempname = this.detailForm.username
      ? this.detailForm.username
      : this.detailForm.userid;
    var name =
      tempname + "'s " + temp + ' (' + startd + ' - ' + endd + ').xlsx';
    var filteredDetailedList = this.filter.transform(
      this.detailList,
      this.searchText,
      this.type
    );
    if (this.detailForm.type == 'inout') {
      filteredDetailedList.map((item: any) => {
        var isUnknownLoc =
          item.location == '' && item.lat == '' && item.long == '';
        var temp = {
          'Time In/Out': item.typename,
          Date: this.allinoneService.formatDBToShow(item.date),
          Time: this.allinoneService.formatTimeToShow(item.starttime),
          Location: isUnknownLoc ? 'Unkown Location' : item.location,
          LatLong: isUnknownLoc ? '' : item.lat + ', ' + item.long,
          Description: item.description,
        };
        exdata.push(temp);
      });
    } else {
      filteredDetailedList.map((item: any) => {
        var temp = {
          Date: this.allinoneService.formatDBToShow(item.date),
          Time: item.starttime,
          'Task ID': item.autoid,
          'Task Name': item.taskdescription,
          Hours: item.hours,
        };
        exdata.push(temp);
      });
      var temp1 = {
        Date: '',
        Time: '',
        'Task ID': '',
        'Task Name': '',
        Hours: '',
      };
      exdata.push(temp1);
      var temp2 = {
        Date: '',
        Time: '',
        'Task ID': '',
        'Task Name': 'Total',
        Hours: this.detailForm.hours,
      };
      exdata.push(temp2);
    }
    this.allinoneService.exportEcecl(exdata, name);
  }

  sortInOutData(sort: Sort) {
    const data = this.detailList;
    if (!sort.active || sort.direction === '') {
      this.detailList = data;
      return;
    }

    this.detailList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'typename':
          return this.allinoneService.compare(a.typename, b.typename, isAsc);
        case 'date':
          return this.allinoneService.compare(
            a.date + a.starttime,
            b.date + b.starttime,
            isAsc
          );
        case 'loc':
          return this.allinoneService.compare(a.loc, b.loc, isAsc);
        case 'desc':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  sortTaskData(sort: Sort) {
    const data = this.detailList;
    if (!sort.active || sort.direction === '') {
      this.detailList = data;
      return;
    }

    this.detailList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return this.allinoneService.compare(
            a.date + a.starttime,
            b.date + b.starttime,
            isAsc
          );
        case 'autoid':
          return this.allinoneService.compare(a.autoid, b.autoid, isAsc);
        case 'desc':
          return this.allinoneService.compare(
            a.taskdescription,
            b.taskdescription,
            isAsc
          );
        case 'hours':
          return this.allinoneService.compare(a.hours, b.hours, isAsc);
        default:
          return 0;
      }
    });
  }

  clear() {
    this.searchText = '';
  }

  backToCIA() {
    this.router.navigate([
      (this.isAdmin
        ? this.allinoneService.isAttendanceView
          ? '/attendance/hxm'
          : '/hxm'
        : '') + '/checkinattendances',
    ]);
  }

  calEndTime(date: any, starttime: any, childdate: any, childstarttime: any) {
    var format = 'yyyy/MM/dd hh:mm:ss a';
    var datetime = date;
    var yy = datetime.slice(0, 4);
    var mm = datetime.slice(4, 6);
    var dd = datetime.slice(6, 8);
    var checkinD = yy + '/' + mm + '/' + dd + ' ' + starttime;

    var cdatetime = childdate;
    var cyy = cdatetime.slice(0, 4);
    var cmm = cdatetime.slice(4, 6);
    var cdd = cdatetime.slice(6, 8);
    var childD = cyy + '/' + cmm + '/' + cdd + ' ' + childstarttime;
    let date1 = new Date(checkinD);
    let date2 = new Date(childD);
    var diffMin = this.diff_minutes(date1, date2);
    return this.transformMinute(diffMin);
  }

  diff_minutes(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) * -1;
    var minutes = Math.floor(diff / 60000);
    return Math.abs(minutes);
  }

  transformMinute(value: number): string {
    console.log(value);
    var hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    var day = 0;
    if (hours == 0) {
      return minutes + ' mins';
    } else if (hours == 1) {
      return hours + ' hr ' + minutes + ' mins ';
    } else {
      return hours + ' hrs ' + minutes + ' mins ';
    }
  }

  getDetails() {
    this.gettingDetails = true;
    const data = {
      startdate: this.detailForm.startdate,
      enddate: this.detailForm.enddate,
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      orgid: this.detailForm.orgid,
      searchuser: this.detailForm.userid,
      types: this.detailForm.type,
      connectionid: this.connectionId,
    };
    this.kunyekService.getSummaryDetails(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.detailList = res.datalist;
          // if (this.detailForm.type == 'tasks') {
          //   this.detailList.map((item: any) => {
          //     item.hours = this.calEndTime(
          //       item.date,
          //       item.starttime,
          //       item.child[0].date,
          //       item.child[0].starttime
          //     );
          //   });
          // }
          // this.detailList.sort((a, b) => {
          //   let fa = a.sort.toLowerCase(),
          //     fb = b.sort.toLowerCase();
          //   if (fa < fb) {
          //     return -1;
          //   }
          //   if (fa > fb) {
          //     return 1;
          //   }
          //   return 0;
          // });
          // this.gettingDetails = false;
        } else {
          this.gettingDetails = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingDetails = false;
      }
    );
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.gettingDetails = true;
    this.connectSocketLoading = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      // this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionId = tempData.connectionId;

        if (this.updateCId) {
          this.updateConnectionID();
        }
        this.getDetails();
      } else {
        this.drsocketfileurl = tempData.fileurl;

        if (this.drsocketfileurl) {
          this.http.get(this.drsocketfileurl).subscribe((res: any) => {
            this.detailList = res.datalist;
            if (this.detailForm.type == 'tasks') {
              this.detailList.map((item: any) => {
                item.hours = this.calEndTime(
                  item.date,
                  item.starttime,
                  item.child[0].date,
                  item.child[0].starttime
                );
              });
            }
            this.detailList.sort((a, b) => {
              let fa = a.sort.toLowerCase(),
                fb = b.sort.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            this.gettingDetails = false;
            clearTimeout(this.socketConnectTime);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionId);
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '013',
    };

    this.kunyekService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      this.updateCId = false;
      console.log(res);
    });
  }

  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(() => {
      if (this.socketConnectionStatus != 'disconnected') {
        console.log('Time out');

        this.socket?.close(3001);
        this.connectionId = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.gettingDetails = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }
}
