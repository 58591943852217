<div class="container p-2 p-sm-4">
    <div class="row row-card-wrapper">
        <div class="col-12 col-sm-12 col-md-12 col-lg-5 column ">
            <ng-container *ngIf="!isgettingUser;else elseTemplate">

                <div class="card">
                    <!-- <div class="card-profile" *ngIf="allinoneService.getRole() != ''">
                        <img src="../../../assets/images/kunyek.png" class="card-img" alt="Kunyek">
                    </div> -->

                    <div class="card-profile" *ngIf="user">
                        <img src="../../../assets/images/profile.png" class="card-img profile-img" [alt]="appname"
                            *ngIf="user.imagename == ''">


                        <img [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="user.imagename"
                            class="card-img profile-img" [alt]="appname" *ngIf="user.imagename != ''">
                    </div>


                    <div class="card-body">
                        <!-- <ul class="list-group" *ngIf="allinoneService.getRole() != ''">
                            <li class="list-group-item">
                                <div class="row">
                                    <div class="col-sm-5">
                                        Name
                                    </div>
                                    <div class="col-sm-7 mr-text">
                                        {{allinoneService.getKunyekUserName()}}
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item">
                                <div class="row">
                                    <div class="col-sm-5">
                                        User ID
                                    </div>
                                    <div class="col-sm-7 mr-text">
                                        {{allinoneService.getKunyekUserId()}}
                                    </div>
                                </div>
                            </li>

                            <li class="list-group-item">
                                <div class="row">
                                    <div class="col-sm-5">
                                        Admin Type
                                    </div>
                                    <div class="col-sm-7 mr-text">
                                        <span *ngIf="allinoneService.getRole() == '100'">Root</span>
                                        <span *ngIf="allinoneService.getRole() == '200'">Domain</span>
                                        <span *ngIf="allinoneService.getRole() == '300'">Organization</span>

                                    </div>
                                </div>
                            </li>

                            <li class="list-group-item" *ngIf="allinoneService.getRole() == '300'">
                                <div class="row">
                                    <div class="col-sm-5 d-flex align-items-center"> Domain </div>
                                    <div class="col-sm-7 mr-text">
                                        <select class="form-select" aria-label="Select Domain" id="domain" name="domain"
                                            (change)="domainChanged($event)" [(ngModel)]="selectedDomain"
                                            *ngIf="allinoneService.domains.length > 0">
                                            <option *ngFor="let domain of allinoneService.domains"
                                                [value]="domain.domainid">
                                                {{domain.description}}</option>
                                        </select>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item" *ngIf="allinoneService.getRole() != '100'">
                                <div class="row">
                                    <div class="col-sm-5">
                                        <span *ngIf="allinoneService.getRole() == '200'"> Domain </span>
                                        <span *ngIf="allinoneService.getRole() == '300'"> Organization </span>
                                    </div>
                                    <div class="col-sm-7 mr-text">
                                        <span
                                            *ngIf="allinoneService.getRole() == '200'">{{allinoneService.getDomain().description}}</span>
                                        <span
                                            *ngIf="allinoneService.getRole() == '300'">{{allinoneService.getOrganization().name}}</span>

                                    </div>
                                </div>
                            </li>



                        </ul> -->

                        <ul class="list-group mb-2">
                            <li class="list-group-item">
                                <div class="row">
                                    <div class="col-sm-5">
                                        Name
                                    </div>
                                    <div class="col-sm-7 mr-text">
                                        <span *ngIf="!isgettingUser && user">
                                            {{user.username}}
                                        </span>

                                        <span class="spinner-border spinner-border-sm" *ngIf="isgettingUser"
                                            role="status" aria-hidden="true"></span>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item">
                                <div class="row">
                                    <div class="col-sm-5">
                                        User ID
                                    </div>
                                    <div class="col-sm-7 mr-text">
                                        <span *ngIf="!isgettingUser && user">
                                            {{user.userid}}
                                        </span>

                                        <span class="spinner-border spinner-border-sm" *ngIf="isgettingUser"
                                            role="status" aria-hidden="true"></span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div>
                            <span style="color: gray;padding: 0.5rem 1rem;">Version 1.17</span>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Loading Skeleton -->
            <ng-template #elseTemplate>
                <div class="card" style="flex-direction: column;">
                    <ngx-skeleton-loader class="card-profile" style="width: auto;" appearance="circle"
                        [theme]="{ height: '100px',width: '100px'}">
                    </ngx-skeleton-loader>

                    <ngx-skeleton-loader class=" my-2"
                        style="display: flex;flex-direction: column;align-items: center;margin-bottom: 1rem;" count="2"
                        [theme]="{ height: '20px',width: '80%'}">
                    </ngx-skeleton-loader>

                </div>

            </ng-template>

        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-7 column col-right">
            <!-- <div class="card"> -->
            <!-- <div class="card-body"> -->

            <h1>Welcome to {{appname}}</h1>
            <!-- <div class="row">
                        <div class="col">
                            <div class="custom-card">
                                <div class="icon-container">
                                         <div class="icon-status-primary">
                                         </div>
                                </div>
                                <div class="details">
                                   <div>
                                      Domain
                                   </div>
                                   <div>
                                        20
                                   </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="custom-card">
                                <div class="icon-container">
                                         <div class="icon-status-primary">
        
                                         </div>
                                </div>
                                <div class="details">
                                   <div>
                                      Organization
                                   </div>
                                   <div>
                                        20
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

            <!-- </div>
            </div> -->
        </div>
    </div>
</div>