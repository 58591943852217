import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { Sort } from '@angular/material/sort';
import { AllInOneService } from '../../shared/services/all-in-one.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {
  isFromDomain: boolean = false;
  domain: string = "";
  adminAddorNot: boolean = false;
  adminListLoading: boolean = false;
  saveLoading: boolean = false;
  isAdminValid: boolean = false;
  isEditing: boolean = false;
  adminFormSubmitted: boolean = false;
  checkingMember: boolean = false;
  domainListLoading : boolean = false;

  username = "";
  getDomainName : any;

  admins: any = [];
  domainList : any = [];
  adminForm = {
    "domain": "",
    "org": "",
    "userid": "",
  }
  p: number = 1;

  constructor(
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.getDomainList();
  }

  ngOnInit(): void {
    
  }

  sortData(sort: Sort) {
    const data = this.admins;
    if (!sort.active || sort.direction === '') {
      this.admins = data
      return;
    }

    this.admins = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid': return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username': return this.allinoneService.compare(a.username, b.username, isAsc);
        default: return 0;
      }
    });
  }

  getAdminList() {
    this.adminListLoading = true;
    this.admins = []
    this.kunyekService.getalldomainmembers(this.adminForm.domain).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.data.map((member: any) => {
          this.admins.push({
            id: member.id,
            domainid: member.domainid,
            userid: member.userid,
            username: member.username,
          })
        }
        )
        this.adminListLoading = false;
      }
      else {
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }
    )
  }

  submitAdmin() {
    this.saveLoading = true
    const data = {
      "domainid": this.adminForm.domain,
      "shortcode": this.getDomainName.shortcode,
      "description": this.getDomainName.description,
      "adduser": this.allinoneService.checkUserId(this.adminForm.userid),
    }
    this.kunyekService.addDomainAdmin(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.saveLoading = false
        this.cancel()
        this.getAdminList()
      }
      else {
        this.saveLoading = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err =>{
      this.saveLoading = false;
    }
    )
  }

  deleteAdmin(id: any, userid: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      width: "250px",
      panelClass: ['mat-typography'],
      closeOnNavigation: true
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.adminListLoading = true;
        this.kunyekService.removeDomainAdmin(id, userid).subscribe((res: any) => {
          if (res.returncode == "300") {
            this.getAdminList()
          }
          else {
            this.adminListLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        })
      }
    });
  }

  cancel() {
    this.isAdminValid = false
    this.username = ""
    this.adminAddorNot = false;
    this.adminForm = {
      "domain": this.adminForm.domain,
      "org": this.adminForm.org,
      "userid": "",
    }
  }


  useridChange() {
    this.username = ""
    this.isAdminValid = false
  }

  checkMember() {
    this.checkingMember = true;
    const data = {
      "searchuserid": this.allinoneService.checkUserId(this.adminForm.userid),
      "shortcode" : this.getDomainName.shortcode
    }
    this.kunyekService.checkUser(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.username = res.username
        this.checkingMember = false
        this.isAdminValid = true
      }
      else {
        this.checkingMember = false
        this.isAdminValid = false
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err => {
      this.checkingMember = false
      this.isAdminValid = false
    }
    )
  }
  goToDomain() {
    this.router.navigateByUrl("domains");
  }
  goToDomainEdit() {
    this.router.navigateByUrl(`domains?domain=${this.adminForm.domain}`);

  }

  getDomainList(): void {
    this.domainListLoading = true;
    this.kunyekService.getDomains().subscribe((res: any) => {
      if (res.returncode == "300") {
        this.domainList = res.domains
        this.domainListLoading = false;
        const domainid = this.route.snapshot.queryParamMap.get('domain') || "";
        if (domainid != "") {
          this.isFromDomain = true;
          this.adminForm.domain = domainid;
          this.getDomainName = this.domainList.find((domain: any) => domain.domainid == domainid);
          if (this.getDomainName) {
            this.domain = this.getDomainName.shortcode;
          }
        }
        this.getAdminList();
      }
      else {
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err => {
      this.domainListLoading = false
    })
  }
}
