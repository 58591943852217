<div class="d-flex justify-content-center">
    <div class="calendar">
        <div class="calendar-header">
            <h4>{{startdate}} - {{enddate}}</h4>
        </div>
        <table class="calendar-table">
            <thead>
                <tr>
                    <th *ngFor="let day of daysOfWeek">{{ day }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let week of weeks">
                    <td *ngFor="let day of week">
                        <div class="d-flex justify-content-center flex-column" *ngIf="day.date">
                            <div>
                                <strong>{{ day.day }}</strong>
                            </div>
                            <div class="w-100 mt-2">
                                <select class="form-select" name="monday" id="monday" [(ngModel)]="day.shift">
                                    <option value="">-</option>
                                    <option *ngFor="let shift of shifts" [value]="shift.shiftid">
                                        {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
                                    </option>
                                </select>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>