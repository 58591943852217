import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {


  gettingOrgList : boolean = false;
  postFormSubmitted : boolean = false;
  newPostOrNot : boolean = false;
  saveLoading : boolean = false;
  postListLoading : boolean = false;

  organizations : any[] = []
  imageList : any[] = []
  previewList : any[] = []
  annPosts: any[] = []
  miPosts : any[] = []

  //test
  tempImageList = [
    '../../../assets/images/Jennie.jpg',
    '../../../assets/images/maintenance.jpg',
    '../../../assets/images/server-maintenance.jpg'
  ]

  tempImageToShowList = [
    '../../../assets/images/Jennie.jpg',
    '../../../assets/images/maintenance.jpg',
  ]

  types = ['Announcement', 'Meeting Invitation']

  p: number = 1;

  postForm  = {
    "domain" : this.allinoneService.getDomain().shortcode,
    "org" : '',
    "type" : 'Announcement',
    "postnote" : '',
    "imagelist" : '',
  }

  constructor(
    public kunyekService: KunyekService,
    public allinoneService : AllInOneService,
    public messageService : MessageService,
  ) { }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.postForm.org = this.allinoneService.selectedOrg ? this.allinoneService.selectedOrg : this.organizations[0].orgid
    this.getPosts()
  }


  sortAnnPostData(sort: Sort) {
    const data = this.annPosts
    if (!sort.active || sort.direction === '') {
      this.annPosts = data
      return;
    }

    this.annPosts = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'createdby': return this.allinoneService.compare(a.createdby, b.createdby, isAsc);
        case 'createddate': return this.allinoneService.compare(a.createddate, b.createddate, isAsc);
        case 'postnote': return this.allinoneService.compare(a.postnote, b.postnote, isAsc);
        default: return 0;
      }
    });
  }

  onFileSelect(event : any){
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          var temp = {
            'file' : file,
            'previewurl' : e.target.result,
            'filename' : this.getFileName(file)
          }
          this.imageList.push(temp)
        }
        reader.readAsDataURL(file);
      }
    }
  }

  submitPost(){
    if(this.postForm.type == 'Announcement' && this.postForm.postnote == "" && this.imageList.length == 0){
      this.messageService.openSnackBar("Please add 'Post note' or 'image' to your post!", 'warn')
      return;
    }
    else{
      this.savePost()
    }
  }

  savePost(){
    this.saveLoading = true
    var nameList : any[] = []
    if(this.imageList.length != 0){
      this.imageList.map((data: any) => {
        nameList.push(data.filename)
      })
    }


    const data = {
      "domain": this.postForm.domain,
      "date": this.allinoneService.getCurrentDateToDB(),
      "time": this.allinoneService.getCurrentTimeToDB(),
      "note": this.postForm.postnote,
      "tag": [],
      "imagename": nameList,
      "type": "3",
      "orgid": this.postForm.org
    }

    this.kunyekService.createPost(data).subscribe((res: any) => {
      if(res.returncode == "300"){
        if(this.imageList.length == 0){
          this.saveLoading = false
          this.getPosts()
          this.cancel()
        }
        else{
          //Image Upload to S3
          //After upload
          // this.saveLoading = false
          // this.cancel()
        }
      }
      else{
        this.saveLoading = false
        this.messageService.openSnackBar(res.message, 'warn');
      }
    },
    err => {
      this.saveLoading = false
    }
    )
  }

  newPost(){
    this.newPostOrNot = true
  }

  cancel() {
    this.newPostOrNot = false
    this.imageList = []
    this.postForm = {
      "domain" : this.postForm.domain,
      "org" : this.postForm.org,
      "type" : this.postForm.type,
      "postnote" : '',
      "imagelist" : '',
    }
  }

  orgChanged(event: any) {
    this.postForm.org = event.target.value
    this.allinoneService.selectedOrg = this.postForm.org
    if(this.postForm.org){
      this.getPosts()
    }
  }

  typeChanged(event:any){
    this.postForm.type = event.target.value
    this.getPosts();
  }

  removeImage(index : any){
    console.log(this.imageList)
    this.imageList.splice(index, 1)
  }

  getPosts(){
    this.postListLoading = true
    if(this.postForm.type == 'Announcement'){
      this.annPosts = []
    }
    else{
      this.miPosts = []
    }

    const data = {
      "domain": this.postForm.domain,
      "count": 10,
      "posttype": this.postForm.type == "Announcement" ? "" : this.postForm.type,
      "orgid": this.postForm.org
    }
    this.kunyekService.getPosts(data).subscribe((res: any) => {
      if(res.returncode == "300"){
        if(this.postForm.type == 'Announcement'){
          this.annPosts = res.list
        }
        else{
          this.miPosts = res.list
        }
        this.postListLoading = false
      }
      else{
        this.postListLoading = false
        this.messageService.openSnackBar(res.message, 'warn');
      }
    },
    err => {
      this.postListLoading = false
    }
    )
  }

  editPost(item: any){

  }

  deletePost(id: any){}


  getFileName(file: any) {
    var name = file.name.trim()
    console.log(name)
    var splitName = name.split(".")
    console.log(splitName)
    var finalName = "";
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName = finalName + "_" + this.allinoneService.generateID() + "." + splitName[i]
      }
      else {
        finalName = finalName + splitName[i]
      }
    }
    return finalName;
  }

}
