import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AccessoriesFilterComponent } from '../accessories-filter/accessories-filter.component';

@Component({
  selector: 'app-accessories-approval',
  templateUrl: './accessories-approval.component.html',
  styleUrls: ['./accessories-approval.component.scss']
})
export class AccessoriesApprovalComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  @ViewChild('hiddenfileinput') hiddenfileinput: any;

  isMobileView: boolean = false;
  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  isFromOffice: boolean = false;

  organizations: any = [];
  tableLoading: boolean = false;

  searchText: string = '';
  orgid: string = '';

  getListSubscription!: Subscription;

  isDetailView: boolean = false;
  selectedRequest: any = '';

  isFocus: boolean = false;

  dataList: any = [];

  gettingRequestType: boolean = false;
  getRequestTypeSubscription!: Subscription;

  requestTypeList: any = []

  // requeststatus: '001',
  // requeststatusname: 'Submitted',

  filterStatus = '001';
  filterStatusName = 'Submitted';

  filterSD : any = '';
  filterED : any = '';

  approveOrRejectStatus: any = '';

  end = '';

  isApproving: boolean = false;
  isRejecting: boolean = false;

  isAdmin: boolean = false;

  imageList: any[] = [];

  generalDatePickerConfig = this.allinoneService.datePickerConfig;

  requestDateFilter = '';

  // submitForm = {
  //   requeststatus: '001',
  //   requeststatusname: 'Submitted',
  // };

  statusList = [
    { id: "001", name: "Submit" },
    { id: "002", name: "Approved" },
    { id: "003", name: "Rejected" },
  ]

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  tableShowStatus = [
    { id: '001', name: 'Submit' },
    { id: '002', name: 'Pending' },
    { id: '003', name: 'Pending' },
    { id: '004', name: 'Approved' },
    { id: '005', name: 'Rejected' },
  ]

  getTableStatus(d: any) {
    var i = this.tableShowStatus.findIndex((x: any) => x.id == d);
    if (d > -1) {
      return this.tableShowStatus[i].name;
    } else {
      return '-';
    }
  }

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe
  ) {
    this.onResize(event);
    this.route.data.subscribe((data) => {
      if (data.isFromOffice) {
        this.isFromOffice = data.isFromOffice;
      }
    })
  }

  ngOnInit(): void {

    // this.organizations = this.allinoneService.orgs;

    //   this.memberForm.org = this.allinoneService.selectedOrg
    //     ? this.allinoneService.selectedOrg
    //     : this.organizations[0].orgid;

    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.checkisAdmin();
    this.getRequestType();
    this.getHRApprovalList();
  }

  clearDate() {
    this.filterSD = '';
    this.filterED = '';
    this.refresh();
  }

  filter() {
    const dialog = this.dialog.open(AccessoriesFilterComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data : {
        isApproval : true,
        status : this.filterStatus,
        startdate : this.filterSD,
        enddate : this.filterED
      }
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if(dialogResult) {
        console.log('your dia result');
        console.log(dialogResult);
        
        this.filterStatus = dialogResult.status;
        this.filterStatusName =  dialogResult.statusname;
        
        this.filterSD = dialogResult.startdate ? dialogResult.startdate : '';
        this.filterED = dialogResult.enddate ? dialogResult.enddate : ''

        this.refresh();
      }
    })
  }

  removeImage(index: number) {
    if (this.selectedRequest.status != '004' && this.selectedRequest.status != '004') {
      this.imageList.splice(index, 1);
    }
  }

  onFileSelect(event: any) {
    let files = event.target.files;
    var filetypelist = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files) {
      for (let file of files) {
        if (filetypelist.indexOf(file.type) > -1) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            var temp = {
              file: file,
              previewurl: e.target.result,
              filename: this.allinoneService.getFileName(file),
            };
            this.imageList.push(temp);
          };
          reader.readAsDataURL(file);
        } else {
          this.messageService.openSnackBar(
            "File type '" + file.type + "' is not supported!",
            'warn'
          );
        }
      }
      this.hiddenfileinput.nativeElement.value = '';
    }
  }

  t() {

    // console.log(this.allinoneService.orgs);
    // console.log(this.allinoneService.selectedOrg);



    // console.log(this.allinoneService.orgs);

    // console.log(this.allinoneService.selectedOrg);


    // console.log(this.isFromOffice);

    // var hra = 'false';
    // var a = 'false';
    // console.log('start');
    // console.log(this.allinoneService.orgsCRm);

    // console.log(this.allinoneService.selectedOrgCRM);

    // var d = this.allinoneService.orgsCRm.findIndex((x: any) => x.orgid == this.allinoneService.selectedOrg);
    // console.log('d');
    // console.log(d);
    // if (d > -1) {
    //   a = this.allinoneService.orgsCRm[d].admin;
    //   hra = this.allinoneService.orgsCRm[d].hradmin;
    // }
    // console.log(a);
    // console.log(hra);



  }

  async approveReject(isReject: boolean) {
    if (isReject) {
      this.isRejecting = true;
    } else {
      this.isApproving = true;
    }

    const current_date = this.allinoneService.getCurrentDateToDB();
    const current_time = this.allinoneService.getCurrentTimeToDB2();
    var tmpimg = current_date.toString() + current_time.toString();

    var filenamelist: any = [];
    var imgToUpload: any = [];
    if (this.isAdmin && this.selectedRequest.status != '004' && this.selectedRequest.status != '005') {
      for (let i = 0; i < this.imageList.length; i++) {
        if (this.imageList[i].file) {
          filenamelist.push(this.imageList[i].filename);
          if (this.imageList[i].file.size >= 307200) {
            this.imageList[i].file = await this.allinoneService.compressImage(
              this.imageList[i].file
            );
            if (this.imageList[i].file == false) {
              this.messageService.openSnackBar(
                'Error while uploading your file.',
                'warn'
              );
              this.isApproving = false;
              return;
            } else {
              imgToUpload.push(this.imageList[i]);
            }
          } else {
            imgToUpload.push(this.imageList[i]);
          }
        }
      }

      if (imgToUpload.length > 0) {
        const result = await this.allinoneService.multipleUploadToAWS(
          imgToUpload,
          'office/hrrequest/' +
          this.selectedRequest.hrrequestid
        );
        if (!result) {
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          this.isApproving = false;
          return;
        }
      }
    }

    var data = {};
    if (isReject) {
      data = {
        hrrequestid: this.selectedRequest.hrrequestid,
        status: '005',
        filelist: this.isAdmin ? filenamelist : [],
        orgid: this.orgid
      }
    } else {
      data = {
        hrrequestid: this.selectedRequest.hrrequestid,
        status: this.approveOrRejectStatus,
        filelist: this.isAdmin ? filenamelist : [],
        orgid: this.orgid
      }
    }
    this.kunyekService.addAccessoriesApproval(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.messageService.openSnackBar('Successful.', 'success');
          this.refresh();
          this.cancel();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        if (isReject) {
          this.isRejecting = false;
        } else {
          this.isApproving = false;
        }
      },
      (err) => {
        if (isReject) {
          this.isRejecting = false;
        } else {
          this.isApproving = false;
        }
      }
    )
  }

  // reject() {
  //   // console.log(data);
  //   this.isRejecting = true;
  //   console.log(this.selectedRequest);
  //   var data = {
  //     hrrequestid : this.selectedRequest.hrrequestid,
  //     status : '005',
  //     filelist : [],
  //     orgid : this.orgid
  //   }
  //   this.kunyekService.addAccessoriesApproval(data).subscribe(
  //     (res:any) => {
  //       if(res.returncode == '300') {
  //         this.messageService.openSnackBar('Successful.', 'success');
  //         this.refresh();
  //         this.cancel();
  //       } else {
  //         this.messageService.openSnackBar(
  //           res.message || res.status || 'Server Error',
  //           'warn'
  //         );
  //       }
  //       this.isRejecting = false;
  //     },
  //     (err) => {
  //       this.isRejecting = false;
  //     }
  //   )
  // }

  checkisAdmin() {
    // var d = this.allinoneService.orgsCRm.findIndex((x: any) => x.orgid == this.allinoneService.selectedOrgCRM);
    var d = this.allinoneService.orgsCRm.findIndex((x: any) => x.orgid == this.allinoneService.selectedOrg);
    if (d > -1) {
      this.isAdmin = this.allinoneService.orgsCRm[d].admin == 'true'
    }
  }

  getHRApprovalList() {
    this.tableLoading = true;

    var hra = 'false';
    var a = 'false';
    var d = this.allinoneService.orgsCRm.findIndex((x: any) => x.orgid == this.allinoneService.selectedOrg);
    if (d > -1) {
      a = this.allinoneService.orgsCRm[d].admin;
      hra = this.allinoneService.orgsCRm[d].hradmin;
    }

    if (this.isFromOffice) {
      var data = {
        orgid: this.orgid,
        hradmin: false,
        orgadmin: false,
        filterstatus: this.filterStatus,
        // date : this.requestDateFilter ? this.allinoneService.parseDate(this.requestDateFilter) : '',
        requeststartdate : this.filterSD ? this.allinoneService.parseDate(this.filterSD) : '',
        requestenddate : this.filterED ? this.allinoneService.parseDate(this.filterED) : '',
        end: this.end,
      }
    } else {
      var data = {
        orgid: this.orgid,
        hradmin: hra == 'true',
        orgadmin: a == 'true',
        filterstatus: this.filterStatus,
        // date : this.requestDateFilter ? this.allinoneService.parseDate(this.requestDateFilter) : '',
        requeststartdate : this.filterSD ? this.allinoneService.parseDate(this.filterSD) : '',
        requestenddate : this.filterED ? this.allinoneService.parseDate(this.filterED) : '',
        end: this.end,
      }
    }


    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getListSubscription = this.kunyekService.getAccessoriesApproval(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.dataList = res.list;
          this.approveOrRejectStatus = res.status;
          this.end = res.end;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.tableLoading = false;
      },
      (err) => {
        this.tableLoading = false;
      }
    )

  }

  getRequestType() {
    this.gettingRequestType = true;
    // this.tableLoading = true;
    var data = {
      orgid: this.orgid,
    }
    this.getRequestTypeSubscription && this.getRequestTypeSubscription.unsubscribe();
    this.getRequestTypeSubscription = this.kunyekService.getAccessoriesRequestType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.requestTypeList = res.list;
          // this.submitForm.requesttype = this.requestTypeList[0].id;
          // this.getHRRequestList();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingRequestType = false;
        // this.tableLoading = false;
      },
      (err) => {
        this.gettingRequestType = false;
        // this.tableLoading = false;
      }
    )
  }

  getRequestTypeName(data: any) {
    var name = this.requestTypeList.find((x: any) => x.id == data);
    return name ? name.name : '';
  }

  viewApproval(data: any) {
    console.log('d');
    console.log(data);

    this.isDetailView = true;
    this.selectedRequest = data;

    if (this.isAdmin && this.selectedRequest.filelist) {
      if (this.selectedRequest.filelist.length > 0) {
        for (let i = 0; i < this.selectedRequest.filelist.length; i++) {
          this.imageList.push({
            file: '',
            previewurl: this.selectedRequest.filelist[i].fileurl,
            filename: this.selectedRequest.filelist[i].imagename,
          });
        }
      }
    }
  }

  refresh() {
    this.currentPage = 1;
    this.getHRApprovalList();
  }

  statusChange(status: any) {
    if (this.filterStatus != status.id) {
      this.filterStatus = status.id;
      this.filterStatusName = status.name;
      this.refresh();
    }
  }

  export() {
    var exdata: any = [];
    var name = 'HR Approval.xlsx';
    var filteredData = this.filterPipe.transform(
      this.dataList,
      this.searchText,
      'userid'
    );
    filteredData.map((item: any) => {
      var temp = {
        'User ID': item.userid,
        'User Name': item.username,
        'Request Type': this.getRequestTypeName(item.typeid),
        'Request Date': this.allinoneService.formatDBToShow(item.requestdate),
        'Status': this.getTableStatus(item.status),
        'Description': item.description
      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }

  sortData(sort: Sort) {
    const data = this.dataList;
    if (!sort.active || sort.direction === '') {
      this.dataList = data;
      return;
    }
    this.dataList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      var art = this.getRequestTypeName(a.typeid);
      var brt = this.getRequestTypeName(b.typeid);
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'requesttype':
          return this.allinoneService.compare(art, brt, isAsc);
        case 'requestdate':
          return this.allinoneService.compare(a.requestdate, b.requestdate, isAsc);
        case 'status':
          return this.allinoneService.compare(a.status, b.status, isAsc);
        case 'description':
          return this.allinoneService.compare(a.description, b.description, isAsc);
        default:
          return 0;
      }
    });
  }

  clear() {
    this.searchText = '';
  }

  cancel() {
    this.isDetailView = false;
    this.imageList = [];
  }

  changePageCount(event: any) { }

  searchTextChange() { }

}
