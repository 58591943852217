import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KunyekService } from '../../shared/services/kunyek.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { formatDate } from '@angular/common';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { differenceBy } from 'lodash';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {
  isEditing: boolean = false;
  jobAddorNot: boolean = false;
  jobformSubmitted: boolean = false;
  saveLoading: boolean = false;
  isMobileView: boolean = false;
  isView: boolean = false;
  gettingOrgList: boolean = false;
  gettingProjectList: boolean = false;
  memberForm: boolean = false;

  jobListLoading: boolean = false;
  isFocus: boolean = false;
  isFocus1: boolean = false;
  gettingMembers: boolean = false;

  mobileViewWidth: any = 426;
  viewWidth: any = 992;
  itemPerPage: any = 100;
  itemPerPageMember = 100;
  q: number = 1;
  p: number = 1;
  type: number = 10;
  callType: any = '';
  selectedProjDesc: string = '';

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  memberStatus = [
    {
      id: '',
      name: 'All',
    },
    {
      id: '1',
      name: 'Members',
    },
  ];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  pgMember = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  searchText: any = '';
  searchText1: any = '';
  memberSearchText: any = '';

  dateFormat = 'yyyyMMdd';
  formatToShow = 'yyyy-MM-dd';
  now = Date.now();
  oneWeekBefore = new Date(this.now).setDate(new Date(this.now).getDate() - 14);
  // currentEndDate = formatDate(
  //   Date.now(),
  //   this.formatToShow,
  //   'en-US'
  // ).toString();
  // currentStartDate = formatDate(
  //   Date.now(),
  //   this.formatToShow,
  //   'en-US'
  // ).toString();

  currentEndDate = new Date(Date.now());
  currentStartDate = new Date(Date.now());

  jobForm : any = {
    domain: '',
    org: '',
    project: '',
    jobname: '',
    jobid: '',
    startdate: this.currentStartDate,
    enddate: this.currentEndDate,
    status: 'New',
    plannedhours: '',
    description: '',
    memberstatus: '',
  };

  organizations: any = [];
  jobListToShow: any = [];
  projectList: any = [];
  finalSelectedMember: any[] = [];
  selectedMember: any[] = [];
  members: any[] = [];
  oldMemberList: any[] = [];
  statusList: any = ['New', 'Open', 'WIP', 'Completed'];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (window.innerWidth < this.viewWidth) {
      this.isView = true;
    } else {
      this.isView = false;
    }
  }

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe((data) => {
      if (data.type == 'org') {
        this.callType = '300';
        if (!this.allinoneService.isAdminOf('org')) {
          this.router.navigateByUrl('/home');
        }
      } else {
        if (!this.allinoneService.isAdminOf('contributor')) {
          this.router.navigateByUrl('/home');
        }
        this.callType = '400';
      }
    });
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    if (window.innerWidth < this.viewWidth) {
      this.isView = true;
    }
    this.jobForm.domain = this.allinoneService.getDomain().domainid;

    if (this.callType == '300') {
      this.organizations = this.allinoneService.orgs;
      this.jobForm.org = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
      this.getMemberList();
    } else {
      this.organizations = this.allinoneService.contributorOrgs;
      this.jobForm.org = this.allinoneService.selectedConOrg
        ? this.allinoneService.selectedConOrg
        : this.organizations[0].orgid;
      this.getContributorMemberList();
    }
    const data = {
      orgid: this.jobForm.org,
      domainid: this.jobForm.domain,
      role: this.callType,
    };
    if (this.callType == '300') {
      this.getAllProjects(data);
    } else {
      this.getContributorProjects(data);
    }
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  clear() {
    this.searchText = '';
  }

  sortData(sort: Sort) {
    const data = this.jobListToShow;
    if (!sort.active || sort.direction === '') {
      this.jobListToShow = data;
      return;
    }

    this.jobListToShow = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'desc':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'startdate':
          return this.allinoneService.compare(a.startdate, b.startdate, isAsc);
        case 'enddate':
          return this.allinoneService.compare(a.enddate, b.enddate, isAsc);
        case 'plannedhours':
          return this.allinoneService.compare(
            a.plannedhours,
            b.plannedhours,
            isAsc
          );

        case 'status':
          return this.allinoneService.compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  getOrganization() {
    this.gettingOrgList = true;
    this.organizations = [];
    this.kunyekService
      .getOrgByDomainId(this.jobForm.domain)
      .subscribe((res: any) => {
        if (res.returncode == '300') {
          res.datalist.map((org: any) => {
            this.organizations.push({
              orgid: org.orgid,
              appid: org.appid,
              createdby: org.createdby,
              createddate: org.createddate,
              domainid: org.domainid,
              name: org.name,
              description: org.description,
            });
          });
          this.jobForm.org =
            this.organizations.length > 0 ? this.organizations[0].orgid : '';
          const data = {
            orgid: this.jobForm.org,
            domainid: this.jobForm.domain,
            role: this.callType,
          };
          if (this.callType == '300') {
            this.getAllProjects(data);
          } else {
            this.getContributorProjects(data);
          }
          this.gettingOrgList = false;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      });
  }

  orgChanged(event?: any) {
    this.jobForm.org = event.target.value;
    if (this.jobForm.org == '') {
      this.jobListToShow = [];
      this.projectList = [];
    } else {
      if (this.callType == '300') {
        this.allinoneService.selectedOrg = this.jobForm.org;
        this.allinoneService.setSelectedOrg(this.jobForm.org);
      } else {
        this.allinoneService.selectedConOrg = this.jobForm.org;
        this.allinoneService.setSelectedConOrg(this.jobForm.org);
      }
      const data = {
        orgid: this.jobForm.org,
        domainid: this.jobForm.domain,
        role: this.callType,
      };
      if (this.callType == '300') {
        this.getAllProjects(data);
      } else {
        this.getContributorProjects(data);
        this.getContributorMemberList();
        this.finalSelectedMember = [];
      }
    }
  }

  getAllProjects(data: any) {
    this.gettingProjectList = true;
    this.projectList = [];
    this.kunyekService.getAllProjects(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.data.map((project: any) => {
            this.projectList.push({
              projectid: project.projectid,
              name: project.name,
              orgid: project.orgid,
              description: project.description,
            });
          });

          this.jobForm.project =
            this.projectList.length > 0 ? this.projectList[0].projectid : '';
          this.jobListToShow = [];
          if (this.jobForm.project != '') {
            this.selectedProjDesc = this.projectList[0].description;
            const data = {
              orgid: this.jobForm.org,
              domainid: this.jobForm.domain,
              projectid: this.jobForm.project,
              role: this.callType,
            };
            this.getJobs(data);
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingProjectList = false;
      },
      (err) => {
        this.gettingProjectList = false;
      }
    );
  }

  getContributorProjects(data: any) {
    this.gettingProjectList = true;
    this.projectList = [];
    this.kunyekService.getContributorProjects(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.data.map((project: any) => {
            this.projectList.push({
              projectid: project.projectid,
              name: project.name,
              orgid: project.orgid,
              description: project.description,
            });
          });

          this.jobForm.project =
            this.projectList.length > 0 ? this.projectList[0].projectid : '';
          this.jobListToShow = [];
          if (this.jobForm.project != '') {
            this.selectedProjDesc = this.projectList[0].description;
            const data = {
              orgid: this.jobForm.org,
              domainid: this.jobForm.domain,
              projectid: this.jobForm.project,
              role: this.callType,
            };
            this.getJobs(data);
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingProjectList = false;
      },
      (err) => {
        this.gettingProjectList = false;
      }
    );
  }

  projectChanged(event?: any) {
    this.jobForm.project = event.target.value;

    if (this.jobForm.project == '') {
      this.selectedProjDesc = '';
      this.jobListToShow = [];
    } else {
      this.selectedProjDesc = this.projectList.find((x: any) => x.projectid == this.jobForm.project).description || '';
      const data = {
        orgid: this.jobForm.org,
        domainid: this.jobForm.domain,
        projectid: this.jobForm.project,
        role: this.callType,
      };
      this.getJobs(data);
    }
  }

  statusChanged(event?: any) {
    this.jobForm.status = event.target.value;
  }

  getJobs(data: any) {
    this.jobListLoading = true;
    this.jobListToShow = [];
    this.kunyekService.getAllJobs(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.jobListToShow = res.data;
          this.pg[3].count = this.jobListToShow.length;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.jobListLoading = false;
      },
      (err) => {
        this.jobListLoading = false;
      }
    );
  }
  submitJob() {
    this.jobformSubmitted = true;
    if (
      this.jobForm.jobname == '' ||
      this.jobForm.project == '' ||
      !this.jobForm.startdate ||
      this.jobForm.startdate == undefined ||
      this.jobForm.startdate == null ||
      !this.jobForm.enddate ||
      this.jobForm.enddate == undefined ||
      this.jobForm.enddate == null ||
      this.jobForm.plannedhours == ''
    ) {
      return;
    } else if (this.jobForm.startdate > this.jobForm.enddate) {
      this.messageService.openSnackBar(
        'Please select the valid end date.',
        'warn'
      );
      return;
    } else if (parseInt(this.jobForm.plannedhours) < 1) {
      this.messageService.openSnackBar(
        'Please fill valid planned hours.',
        'warn'
      );
      return;
    } else if (
      this.jobForm.memberstatus == '1' &&
      this.finalSelectedMember.length == 0
    ) {
      this.messageService.openSnackBar(
        'Please select members for this job.',
        'warn'
      );
      return;
    } else {
      this.saveJob();
      // console.log(this.jobForm.plannedhours)
    }
  }

  saveJob() {
    this.saveLoading = true;

    var tempStartdate = this.allinoneService.parseDate(this.jobForm.startdate);
    var tempEnddate = this.allinoneService.parseDate(this.jobForm.enddate);

    var members: any = [];
    var deleteMember: any = [];
    var data;

    if (this.isEditing) {
      if (this.jobForm.memberstatus == '1') {
        this.oldMemberList.map((item: any) => {
          var index = this.finalSelectedMember
            .map((x) => x.userid)
            .indexOf(item.userid);
          if (index == -1) {
            var tempid = {
              id: item.id,
            };
            deleteMember.push(tempid);
          }
        });
        var tempFinalSelectedMember = differenceBy(
          this.finalSelectedMember,
          this.oldMemberList,
          'id'
        );
        tempFinalSelectedMember.map((item: any) => {
          var temp = {
            userid: item.userid,
          };
          members.push(temp);
        });
      }
      data = {
        orgid: this.jobForm.org,
        domainid: this.jobForm.domain,
        projectid: this.jobForm.project,
        name: this.jobForm.jobname,
        startdate: tempStartdate,
        enddate: tempEnddate,
        status: this.jobForm.status,
        plannedhours: this.jobForm.plannedhours.toString(),
        description: this.jobForm.description,
        memberstatus: this.jobForm.memberstatus,
        member: members,
        deletemember: deleteMember,
      };
    } else {
      if (this.jobForm.memberstatus == '1') {
        this.finalSelectedMember.map((item: any) => {
          var temp = {
            userid: item.userid,
          };
          members.push(temp);
        });
      }
      data = {
        orgid: this.jobForm.org,
        domainid: this.jobForm.domain,
        projectid: this.jobForm.project,
        name: this.jobForm.jobname,
        startdate: tempStartdate,
        enddate: tempEnddate,
        status: this.jobForm.status,
        plannedhours: this.jobForm.plannedhours.toString(),
        description: this.jobForm.description,
        memberstatus: this.jobForm.memberstatus,
        member: members,
      };
    }

    if (this.isEditing) {
      data = Object.assign(data, { jobid: this.jobForm.jobid });
    }
    var fun = this.isEditing
      ? this.kunyekService.updateJob(data)
      : this.kunyekService.addJob(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.cancel();
          const jobparam = {
            domainid: this.jobForm.domain,
            orgid: this.jobForm.org,
            projectid: this.jobForm.project,
            role: this.callType,
          };
          this.getJobs(jobparam);
        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  openEditForm(item: any) {
    this.jobAddorNot = !this.jobAddorNot;
    this.isEditing = true;
    this.jobForm = {
      domain: this.jobForm.domain,
      org: this.jobForm.org,
      jobname: item.name,
      jobid: item.jobid,
      project: this.jobForm.project,
      startdate: this.allinoneService.parseDateToShowInEdit(item.startdate),
      enddate: this.allinoneService.parseDateToShowInEdit(item.enddate),
      status: item.status,
      plannedhours: item.plannedhours,
      description: item.description,
      memberstatus: item.memberstatus,
    };

    this.finalSelectedMember = item.memberlist;
    this.oldMemberList = item.memberlist;
  }

  refresh() {
    const jobparam = {
      domainid: this.jobForm.domain,
      orgid: this.jobForm.org,
      projectid: this.jobForm.project,
      role: this.callType,
    };
    this.getJobs(jobparam);
  }

  deleteLocation(locid: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
      }
    });
  }

  cancel() {
    this.jobAddorNot = !this.jobAddorNot;
    this.isEditing = false;
    this.jobformSubmitted = false;
    this.jobForm = {
      domain: this.jobForm.domain,
      org: this.jobForm.org,
      project: this.jobForm.project,
      jobname: '',
      jobid: '',
      startdate: this.currentStartDate,
      enddate: this.currentEndDate,
      status: 'New',
      plannedhours: '',
      description: '',
      memberstatus: '',
    };
    this.cancelMember();
    this.finalSelectedMember = [];
    this.oldMemberList = [];
  }

  cancelMember() {
    this.memberForm = false;
    this.selectedMember = [];
  }

  openMember() {
    this.memberForm = true;
    this.selectedMember = JSON.parse(JSON.stringify(this.finalSelectedMember));
    this.pgMember[3].count = this.selectedMember.length;
  }

  getMemberList() {
    this.members = [];
    this.gettingMembers = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      domain: this.allinoneService.getDomain().shortcode,
      orgid: this.jobForm.org,
    };
    this.kunyekService.getMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.members = res.datalist;
          this.gettingMembers = false;
        } else {
          this.gettingMembers = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingMembers = false;
      }
    );
  }

  getContributorMemberList() {
    this.members = [];
    this.gettingMembers = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      domain: this.allinoneService.getDomain().shortcode,
      orgid: this.jobForm.org,
      type: '1',
    };
    this.kunyekService.getHierarchyMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.members = res.datalist;
          this.gettingMembers = false;
        } else {
          this.gettingMembers = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingMembers = false;
      }
    );
  }

  saveMember() {
    if (this.selectedMember.length == 0) {
      this.messageService.openSnackBar(
        'Please select at least one member.',
        'warn'
      );
      return;
    }
    this.finalSelectedMember = this.selectedMember;
    this.memberForm = false;
    this.selectedMember = [];
  }

  selectMember(member: any, trigger: MatAutocompleteTrigger) {
    var temp = this.selectedMember.filter(
      (x: any) => x.userid == member.userid
    );
    if (temp.length == 0) {
      this.selectedMember.push(member);
    }
    this.pgMember[3].count = this.selectedMember.length;
    console.log(this.selectedMember.length);
    console.log('---');
    console.log(this.itemPerPageMember);
    if (
      this.itemPerPageMember != 100 &&
      this.itemPerPageMember != 300 &&
      this.itemPerPageMember != 500
    ) {
      console.log('withing if');
      this.itemPerPageMember = this.selectedMember.length;
    }
    console.log(this.itemPerPageMember);
    // trigger.openPanel();
  }

  changePageCountMember(event: any) {
    this.itemPerPageMember = event.target.value;
    this.q = 1;
  }

  sortMemberData(sort: Sort) {
    const data = this.selectedMember;
    if (!sort.active || sort.direction === '') {
      this.selectedMember = data;
      return;
    }

    this.selectedMember = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        default:
          return 0;
      }
    });
  }

  removeMember(index: number) {
    this.selectedMember.splice(index, 1);
  }
}
