<!-- Start Here ------- -->
<div class="container-fluid">
  <div class="content">
    <!-- <app-breadcrumb [s_breadcrumb]="'QR 360'" *ngIf="!allinoneService.isQrModule">
      </app-breadcrumb> -->
    <!-- <ng-container>
      <app-office-breadcrumb *ngIf="!addQr" [s_breadcrumb]="'QR 360'">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="addQr" [s_breadcrumb]="'QR 360'" [t_breadcrumb]="isEditing ? 'Edit QR' : 'New QR'"
        (emit)="cancel()">
      </app-office-breadcrumb>
    </ng-container> -->
    <ng-container *ngIf="addQr; else listView">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!saveLoading">
          Cancel
        </button>
        <app-button-loader class="mb-3" (click)="submitQr()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
      <div [formGroup]="form" (ngSubmit)="submitQr()">
        <form [formGroup]="form" (ngSubmit)="submitQr()">
          <div class="card card-body my-card mb-3">
            <div class="row">
              <div class="col-md-6" *ngIf="!isEditing">
                <div class="form-group mb-3">
                  <label class="form-label" for="type"> Type </label>
                  <select formControlName="type" class="form-select" (change)="checkFile($event)" id="type" name="type">
                    <option *ngFor="let item of addTypeList" [value]="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label class="form-label" for="name"> Name </label>
                  <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" id="name"
                    name="name" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        formControl.name.invalid
                    }" />
                </div>
              </div>
              <!-- 8*** -->
              <!-- <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label" for="orgname">
                  Organization Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Organization Name"
                  formControlName="orgname"
                  id="orgname"
                  name="orgname"
                />
              </div>
            </div> -->
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label class="form-label" for="userid"> User ID </label>
                  <input type="text" class="form-control" placeholder="Enter User ID" formControlName="userid"
                    id="userid" name="userid" />
                </div>
                
              </div>
             
              

              <div class="form-group mb-3" *ngIf="formControl.type.value != '001'">
                <label class="form-label" for="file">Attachment</label>
                <!-- <i class="ms-1 fa fa-question-circle" matTooltip="Updating to different file type will also update the QR code." *ngIf="isEditing"></i> -->
                <div class="dragdrop form-control p-0" name="file" id="file" [ngClass]="{
                    'is-invalid':
                      submitted &&
                      ((!isEditing && !fileSelected) ||
                        (isEditing && !formControl.filename.value))
                  }">
                  <ng-container *ngIf="
                      fileSelected != '' ||
                        (isEditing && formControl.filename.value);
                      else uploadTemplate
                    ">
                    <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3 td-edit"
                      appDragDrop (onFileDropped)="uploadFile($event)" (click)="choosefile.click()">
                      <!-- <div class="ms-2"> -->
                      <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
                      <div>
                        <img [src]="
                            '../../../assets/images/' +
                            (formControl.filetype.value == 'pdf'
                              ? 'pdf'
                              : 'image') +
                            '.png'
                          " width="40px" />
                      </div>
                      <div class="ms-2">
                        <strong>{{ formControl.filename.value }}</strong>
                      </div>
                      <!-- </div> -->
                    </div>
                  </ng-container>
                  <ng-template #uploadTemplate>
                    <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
                      (onFileDropped)="uploadFile($event)">
                      <div class="me-3">Drag and drop file here</div>
                      <div class="me-3">or</div>
                      <div>
                        <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
                        <button type="button" class="btn btn-custom" (click)="choosefile.click()">
                          Choose File
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <small>Only .pdf, .png, .jpg, .jpeg are allowed.</small>
                <!-- <small *ngIf="isEditing"
                >Only .{{ allowedFileType }} is allowed.</small
              > -->
              </div>

              <div class="mb-3" *ngIf="formControl.type.value != '001'">
                <h3 class="mb-2">Theme</h3>
                <div class="form-check form-check-inline me-5">
                  <input class="form-check-input"  type="radio" name="template" id="light" formControlName="template" value="001" 
                    >
                  <label class="form-check-label" for="light">Light</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input"  type="radio" name="template" id="dark" formControlName="template" value="002"
                    >
                  <label class="form-check-label" for="dark">Dark</label>
                </div>
              </div>


            </div>
          </div>
          <ng-container *ngIf="formControl.type.value == '001'">
            <div class="card card-body my-card mb-3">
              <div class="row">
                <div>Profile</div>
                <div style="position: relative">
                  <div class="uploadfilecontainer" (click)="pfFileInput.click()" appDragDrop
                    (onFileDropped)="uploadFile($event)">
                    <!-- <span>Choose Image</span> -->
                    <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="
                        pfImgURL
                          ? pfImgURL
                          : '../../../assets/images/uploadphoto.png'
                      " />
                    <!-- <mat-icon class="remove-image">remove_circle</mat-icon> -->

                    <input hidden type="file" #pfFileInput accept="image/*" (change)="pfImgUpload($event)" />
                  </div>
                  <button mat-icon-button class="remove-drag-image" *ngIf="pfImgURL" (click)="removePfImage()">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
                <!-- <img [src]="imgURL" height="200" *ngIf="imgURL"> -->
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="contactname">
                      Contact Name
                    </label>
                    <input type="text" class="form-control" placeholder="Enter Contact Name"
                      formControlName="contactname" id="contactname" name="contactname" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="post"> Rank </label>
                    <input type="text" class="form-control" placeholder="Enter Rank" formControlName="post" id="post"
                      name="post" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="company"> Department </label>
                    <input type="text" class="form-control" placeholder="Enter Department" formControlName="department"
                      id="department" name="department" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="company"> Organization </label>
                    <input type="text" class="form-control" placeholder="Enter Organization" formControlName="company"
                      id="company" name="company" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="mobile"> Mobile </label>
                    <input type="text" class="form-control" placeholder="Enter Mobile" formControlName="mobile"
                      id="mobile" name="mobile" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="workphone">
                      Work Phone
                    </label>
                    <input type="text" class="form-control" placeholder="Enter Work Phone" formControlName="workphone"
                      id="workphone" name="workphone" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="email"> Email </label>
                    <input type="text" class="form-control" placeholder="Enter Email" formControlName="email" id="email"
                      name="email" />
                  </div>
                </div>


                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="website"> Website </label>
                    <input type="text" class="form-control" placeholder="Enter Website" formControlName="website"
                      id="website" name="website" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="location"> Location </label>
                    <input type="text" class="form-control" placeholder="Enter Location" formControlName="location"
                      id="location" name="location" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="googlemap"> Google Map Address </label>
                    <input type="text" class="form-control" placeholder="https://goo.gl/maps/*****"
                      formControlName="googlemap" id="googlemap" name="googlemap" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="linkedin"> LinkedIn </label>
                    <input type="text" class="form-control" placeholder="https://www.linkedin.com/in/******" formControlName="linkedin"
                      id="linkedin" name="linkedin" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="facebook"> Facebook </label>
                    <input type="text" class="form-control" placeholder="https://www.facebook.com/*******" formControlName="facebook"
                      id="facebook" name="facebook" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="messenger"> Messenger </label>
                    <input type="text" class="form-control" placeholder="https://www.messenger.com/*******" formControlName="messenger"
                      id="messenger" name="messenger" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="instagram"> Instagram </label>
                    <input type="text" class="form-control" placeholder="https://www.instagram.com/*******" formControlName="instagram"
                      id="instagram" name="instagram" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="viber"> Viber </label>
                    <input type="text" class="form-control" placeholder="+959*******" formControlName="viber"
                      id="viber" name="viber" />
                  </div>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="file">Attachment</label>
                  <!-- <i class="ms-1 fa fa-question-circle" matTooltip="Updating to different file type will also update the QR code." *ngIf="isEditing"></i> -->
                  <div class="dragdrop form-control p-0 position-relative" name="file" id="file" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        ((!isEditing && !fileSelected) ||
                          (isEditing && !formControl.filename.value))
                    }">
                    <ng-container *ngIf="
                        fileSelected != '' ||
                          (isEditing && formControl.filename.value);
                        else uploadTemplate
                      ">
                      <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3 td-edit"
                        appDragDrop (onFileDropped)="uploadFile($event)" (click)="choosefile.click()">
                        <!-- <div class="ms-2"> -->
                        <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
                        <div>
                          <img [src]="
                              '../../../assets/images/' +
                              (formControl.filetype.value == 'pdf'
                                ? 'pdf'
                                : 'image') +
                              '.png'
                            " width="40px" />
                        </div>
                        <div class="ms-2">
                          <strong>{{ formControl.filename.value }}</strong>
                        </div>
                        <!-- </div> -->
                      </div>
                      <button type="button" mat-icon-button class="remove-attachment" (click)="removeAttachment()">
                        <mat-icon>remove_circle</mat-icon>
                      </button>
                    </ng-container>
                    <ng-template #uploadTemplate>
                      <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
                        (onFileDropped)="uploadFile($event)">
                        <div class="me-3">Drag and drop file here</div>
                        <div class="me-3">or</div>
                        <div>
                          <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
                          <button type="button" class="btn btn-custom" (click)="choosefile.click()">
                            Choose File
                          </button>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <small>Only .pdf, .png, .jpg, .jpeg are allowed.</small>
                  <!-- <small *ngIf="isEditing"
                  >Only .{{ allowedFileType }} is allowed.</small
                > -->
                </div>
                <div class="mb-3">
                  <h3 class="mb-2">Theme</h3>
                  <div class="form-check form-check-inline me-5">
                    <input class="form-check-input"  type="radio" name="template" id="light" formControlName="template" value="001" 
                      >
                    <label class="form-check-label" for="light">Light</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input"  type="radio" name="template" id="dark" formControlName="template" value="002"
                      >
                    <label class="form-check-label" for="dark">Dark</label>
                  </div>
                </div>
                <!-- <div class="form-group mb-3">
                  <label for="contactfile" class="form-label">Contact File</label>
                  <div class="input-group" *ngIf="isContactFileSelected">
                    <input
                      class="form-control"
                      type="text"
                      id="contactfile"
                      readonly
                      formControlName="contactfilename"
                    />
                    <button
                      class="btn btn-custom"
                      (click)="removeContact()"
                      id="removebtn"
                    >
                      Remove
                    </button>
                  </div>
                  <ng-container *ngIf="!isContactFileSelected">
                    <input
                      class="form-control"
                      type="file"
                      id="contactfile"
                      (change)="contactFileSelect($event)"
                      accept=".vcf"
                      #contactfile
                    />
                  </ng-container>
                  <small *ngIf="!isEditing">Only .vcf is allowed.</small>
                </div> -->
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </ng-container>
    <ng-template #listView>
      <div class="d-flex flex-wrap">
        <div class="mb-3">
          <div class="form-check form-check-inline me-5">
            <input class="form-check-input" [(ngModel)]="cardListType" type="radio" name="qrtype" id="card" value="001"
              name="card">
            <label class="form-check-label" for="card">Card</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" [(ngModel)]="cardListType" type="radio" name="qrtype" id="menu" value="002"
              name="menu">
            <label class="form-check-label" for="menu">Menu</label>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-3" *ngIf="qrListLoading">
        <div class="spinner-border" role="status"> <span class="visually-hidden">Loading...</span> </div>
      </div>
      <div *ngIf="(qrList | filter : cardListType: 'qr360User').length == 0 && !qrListLoading"
        class="d-flex justify-content-center mt-3">
        <p>Empty</p>
      </div>
      <ng-container *ngIf="!qrListLoading">
        <div *ngFor="let item of qrList | filter : cardListType : 'qr360User'; let index=index">
          <div class="mb-3 rounded-lg" style="background-color: #f7f7f7; border-radius: 1rem;">
            <div class="p-3 d-flex align-items-center justify-content-between">
              <div class="d-flex flex-column">
                <div class="mb-1">
                  <b>{{ item.description }}</b>
                </div>
                <div class="qr-userid">
                  {{ item.accessuserid }}
                </div>
              </div>
              <div>
                <button class="btn btn-outline-info me-2 w-auto" (click)="openQR(item.qrurl)" title="QR Code">
                  <i class="fa fa-qrcode me-2"></i>View
                </button>

                <button class="btn btn-outline-primary me-2 w-auto" (click)="editQr(item)" title="Edit">
                  <!-- my btn -->
                  <i class="fa fa-edit me-2"></i>Edit
                </button>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #listView2>
      <div class="d-flex flex-wrap">
        <div class="mb-3">
          <button type="button" class="btn btn-custom" (click)="newQr()">
            <i class="fa fa-plus me-1"></i>New QR U
          </button>
        </div>
      </div>
      <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search with Description/Type"
            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{ 'ms-2': !isMobileView, 'ms-0': isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{ page.count }}" (change)="changePageCount($event)"
                id="pg{{ page.name }}" class="p-1" [checked]="page.count == 100" />
              <label for="pg{{ page.name }}" class="col-10 p-1">
                {{ page.name }}
              </label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="getQrList()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ qrList.length }}</span>
        </div>
      </div>
      <div class="input-group mb-2" *ngIf="isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search with Description/Type" aria-label="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
          [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu" />
        <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
          [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-1 col-md-1"></td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="type">
                Type
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="description">
                Description
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="userid">
                User ID
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="organization">
                Organization
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="contact">
                Contact File
              </td>
              <td scope="col" class="d-flex justify-content-center">Actions</td>
            </tr>
          </thead>
          <tbody *ngIf="qrListLoading">
            <tr>
              <td colspan="7" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="qrListLoading == false">
            <tr *ngIf="qrList.length == 0">
              <td colspan="7" class="nodata">Empty</td>
            </tr>
            <tr *ngFor="
                let item of qrList
                  | filter : searchText : 'qr360'
                  | paginate : { itemsPerPage: itemPerPage, currentPage: p };
                let index = index
              ">
              <td class="td-edit">
                <img [src]="
                    '../../../assets/images/' +
                    (item.filetype == 'pdf' ? 'pdf' : 'image') +
                    '.png'
                  " width="20px" />
              </td>
              <td class="td-data td-edit" (click)="editQr(item)">
                <span [title]="item.typetodisplay">{{
                  item.typetodisplay
                  }}</span>
              </td>
              <td class="td-data" style="max-width: fit-content">
                <span [title]="item.description">
                  {{ item.description }}
                </span>
              </td>
              <td class="td-data">
                <span [title]="item.accessuserid">{{ item.accessuserid }}</span>
              </td>
              <td class="td-data">
                <span [title]="item.orgname">{{ item.orgname }}</span>
              </td>
              <td class="td-data">
                <span [title]="item.contactfile">{{
                  item.contactfile == "" ? "-" : item.contactfile
                  }}</span>
              </td>

              <td class="d-flex justify-content-center">
                <button class="btn btn-outline-info me-2" (click)="openQR(item.qrurl)" title="QR Code">
                  <i class="fa fa-qrcode"></i>
                </button>

                <button class="btn btn-outline-primary me-2" (click)="editQr(item)" title="Edit">
                  <i class="fa fa-edit"></i>
                </button>

                <button class="btn btn-outline-danger" (click)="deleteQr(item.id, index)" title="Delete">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="p = $event"
        *ngIf="(qrList | filter : searchText : 'qr360').length > itemPerPage" previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-template>
  </div>
</div>