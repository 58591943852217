<div class="container-fluid">
  <div class="content">
    <app-breadcrumb [s_breadcrumb]="'Project Report'">
    </app-breadcrumb>

    <div class="mt-3 d-flex flex-wrap justify-content-between align-items-center">
      <div>
        <div class="d-flex flex-wrap align-items-center">
          <div class="mb-3">
            <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
              <img class="excel-icon" src="../../../assets/images/excel.png">
              Export
            </button>
          </div>
          <div class="mb-3">
            <div class="line"></div>
          </div>
          <div class="mb-3">
            <div class="btn btn-custom me-3" (click)="filterByDate()">
              Filter by Date
            </div>
          </div>
          <div class="mb-3">
            <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'" [isLoading]="searchLoading"
              (click)="searchByProj()">
            </app-button-loader>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex">
      <div class="mb-3 col-md-4">
        <label for="organization" class="form-label">Organization</label>
        <select class="form-select" aria-label="Select Organization" id="organization" name="organization"
          (change)="orgChanged($event)" [(ngModel)]="getcheckinForm.orgid" required>
          <option *ngFor="let org of organizations" [value]="org.orgid">{{org.name}}</option>
        </select>
      </div>
      <div class="mb-3 col-md-4">
        <label for="proj" class="form-label">Projects</label>
        <select class="form-select" aria-label="Select Project" id="proj" name="proj" (change)="projChanged($event)"
          [(ngModel)]="getcheckinForm.projindex" required #proj="ngModel"
          [ngClass]="{ 'is-invalid': formSubmitted && proj.invalid }" [disabled]="gettingProjects">
          <option value="" selected>-</option>
          <option *ngFor="let proj of projects; let i = index" [value]="i">{{proj.name}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingProjects"></mat-progress-bar>

        <div class="invalid-feedback" *ngIf="proj.invalid || (proj.dirty || proj.touched)">
          <div *ngIf="proj.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="jobs" class="form-label">Jobs</label>
        <select class="form-select" aria-label="Select Job" id="jobs" name="jobs" [(ngModel)]="getcheckinForm.jobindex"
          required [disabled]="gettingJobs">
          <option value="" selected>-</option>
          <option *ngFor="let job of jobs; let j = index" [value]="j">{{job.name}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingJobs"></mat-progress-bar>
      </div>

    </div>


    <div class="">
      <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center">
        <div class="d-flex flex-wrap">

          <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
            <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/Employee ID"
              aria-label="Search" (focus)="isFocusPJ = true" (blur)="isFocusPJ = false" [(ngModel)]="searchTextPJ"
              [ngClass]="{'c-search-input-with-clear' : searchTextPJ }" style="font-family: FontAwesome,Ubuntu;">
            <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextPJ" (click)="clear()"
              [ngClass]="{'c-clear' : isFocusPJ, 'c-clear-not-focus': !isFocusPJ}">
              <mat-icon class="c-icon">close</mat-icon>
            </button>
          </div>
          <div class="dropdown " [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
            <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
              aria-expanded="false" title="Page Size">
              <mat-icon>filter_list</mat-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <div c style="text-align: center;">Page size</div>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li class="dropdown-item" *ngFor="let page of projectPG">
                <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePjPageCount($event)"
                  id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                <label for="pg{{page.name}}" class="col-10 p-1">
                  {{page.name}}
                </label>
              </li>
            </ul>
          </div>
          <div class="line"></div>
          <div class="d-flex align-items-center">
            <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{projData.length}}</span>
          </div>
          <div class="line" *ngIf="projStartDate && projEndDate"></div>
          <div class="d-flex align-items-center">
            <span *ngIf="projStartDate && projEndDate" class="datefilter" (click)="filterByDate()">
              {{allinoneService.formatDBToShow(allinoneService.formatDate(projStartDate))}} -
              {{allinoneService.formatDBToShow(allinoneService.formatDate(projEndDate))}}
            </span>
            <mat-icon *ngIf="projStartDate && projEndDate" class="remove-date"
              style="margin-left: 10px;cursor: pointer;" (click)="removeDate()">
              close</mat-icon>
          </div>
        </div>
      </div>
      <div class="input-group mb-2" *ngIf="isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/Employee ID"
          aria-label="Search" (focus)="isFocusPJ = true" (blur)="isFocusPJ = false" [(ngModel)]="searchTextPJ"
          [ngClass]="{'c-search-input-with-clear' : searchTextPJ }" style="font-family: FontAwesome,Ubuntu;">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextPJ" (click)="clear()"
          [ngClass]="{'c-clear' : isFocusPJ, 'c-clear-not-focus': !isFocusPJ}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortProjData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="userid">User ID</td>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="username">Name</td>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="employeeid">Employee ID</td>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="hours">Total Hours</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingProjData">
            <tr>
              <td colspan="4" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingProjData">
            <tr *ngIf="projData.length==0">
              <td colspan="4" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of projData| filter: searchTextPJ:14 | paginate: { itemsPerPage: itemPerPagePJ, currentPage: q }; let index=index">
              <td class="td-data">
                <span [title]="item.userid">{{item.userid}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.username">{{item.username}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.employeeid">{{item.employeeid}} </span>
              </td>
              <td class="td-edit td-data" (click)="viewTaskDetails(item)">
                <span [title]="item.hours">{{item.hours}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="q=$event" *ngIf="projData.length> itemPerPagePJ"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </div>

  </div>
</div>