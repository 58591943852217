<div class="container-fluid">
  <div class="content">
    <div class="m-4">
      <div class="me-auto">
        <!-- <app-kmember-breadcrumb [s_breadcrumb]="'Guest'"></app-kmember-breadcrumb> -->
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

      </div>
      <div class="d-flex gap-2 mb-2">
        <button type="button" (click)="closeForm()" class="btn btn-custom">Cancel</button>
        <app-button-loader [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="isSaving"
          (click)="onSaved()"></app-button-loader>
        <!-- <button type="button" class="btn btn-custom">Submit</button> -->
      </div>
      <div class="card">
        <div class="card-body m-2">
          <form [formGroup]="guestForm" (ngSubmit)="onSaved()">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="name" class="form-label">Name<font color="red">*</font></label>
                    <input type="text" id="name" class="form-control" formControlName="name"
                      [ngClass]="{'is-invalid': name?.invalid && saved}">
                    <div class="invalid-feedback" *ngIf="name?.invalid && saved">
                      <div *ngIf="name?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="nationality" class="form-label">Nationality</label>
                    <input type="text" id="nationality" class="form-control" formControlName="nationality"
                      [ngClass]="{'is-invalid': nationality?.invalid && saved}">
                    <div class="invalid-feedback" *ngIf="nationality?.invalid && saved">
                      <div *ngIf="nationality?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="country" class="form-label">Country</label>
                    <input type="text" id="country" class="form-control" formControlName="country"
                      [ngClass]="{'is-invalid': country?.invalid && saved}">
                    <div class="invalid-feedback" *ngIf="country?.invalid && saved">
                      <div *ngIf="country?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="gender" class="form-label">Gender<font color="red">*</font></label>
                    <div class="mt-2">
                      <div class="form-check form-check-inline">
                        <input [ngClass]="{'is-invalid': gender?.invalid && saved}" type="radio" id="male" name="gender"
                          value="male" class="form-check-input" formControlName="gender">
                        <label for="male" class="form-check-label">Male</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input [ngClass]="{'is-invalid': gender?.invalid && saved}" type="radio" id="female"
                          name="gender" value="female" class="form-check-input" formControlName="gender">
                        <label for="female" class="form-check-label">Female</label>
                      </div>
                      <div *ngIf="gender?.invalid && saved">
                        <small class="text-danger text-xs" *ngIf="gender?.errors?.required">{{requiredMessage}}</small>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="dateOfBirth" class="form-label">Date of Birth<font color="red">*</font></label>
                    <my-date-picker [options]="datePickerOptions" formControlName="dateOfBirth"
                      [ngClass]="{ 'is-invalid': dateOfBirth?.invalid && saved }" placeholder="DD/MM/YYYY">
                    </my-date-picker>
                    <div class="invalid-feedback" *ngIf="dateOfBirth?.invalid && saved">
                      <div *ngIf="dateOfBirth?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="dateOfArrival" class="form-label">Date of Arrival<font color="red">*</font></label>
                    <my-date-picker [options]="datePickerOptions" formControlName="dateOfArrival"
                      [ngClass]="{ 'is-invalid': dateOfArrival?.invalid && saved }" placeholder="DD/MM/YYYY">
                    </my-date-picker>
                    <div class="invalid-feedback" *ngIf="dateOfArrival?.invalid && saved">
                      <div *ngIf="dateOfArrival?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="occupation" class="form-label">Occupation</label>
                    <input type="text" id="occupation" class="form-control" formControlName="occupation"
                      [ngClass]="{'is-invalid': occupation?.invalid && saved}">
                    <div class="invalid-feedback" *ngIf="occupation?.invalid && saved">
                      <div *ngIf="occupation?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="company" class="form-label">Company</label>
                    <input type="text" id="company" class="form-control" formControlName="company"
                      [ngClass]="{'is-invalid': company?.invalid && saved}">
                    <div class="invalid-feedback" *ngIf="company?.invalid && saved">
                      <div *ngIf="company?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="contactNo" class="form-label">Contact Phone Number<font color="red">*</font></label>
                    <input type="tel" id="contactNo" class="form-control" formControlName="contactNo"
                      [ngClass]="{'is-invalid': contactNo?.invalid && saved}">
                    <div class="invalid-feedback" *ngIf="contactNo?.invalid && saved">
                      <div *ngIf="contactNo?.errors?.required">{{requiredMessage}}</div>
                      <div *ngIf="contactNo?.errors?.numericString">This field must be a number</div>

                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="email" class="form-label">Email<font color="red">*</font></label>
                    <input type="email" id="email" class="form-control" formControlName="email"
                      [ngClass]="{'is-invalid': email?.invalid && saved}">
                    <div class="invalid-feedback" *ngIf="email?.invalid && saved">
                      <div *ngIf="email?.errors?.required">{{requiredMessage}}</div>
                      <div *ngIf="email?.errors?.email">Invalid email format</div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="options" class="form-label">Options<font color="red">*</font></label>
                    <select id="options" class="form-select" formControlName="options"
                      [ngClass]="{'is-invalid': options?.invalid && saved}">
                      <option value="" selected disabled>Plans</option>
                      <option value="1 month for 300000/person">1 MONTH FOR 300000/PERSON</option>
                      <option value="3 months for 600000/person">3 MONTHS FOR 600000/PERSON</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="options?.invalid && saved">
                      <div *ngIf="options?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="region" class="form-label">State/Division</label>
                    <div class="g-2">
                      <div class="row g-2">
                        <div class="col-4">
                          <select class="form-select" id="region" name="region" formControlName="region"
                            (change)="onRegionChange($event)">
                            <option value="" selected disabled>Select State/Division</option>
                            <option *ngFor="let regionlist of regionList" [value]="regionlist.region">
                              {{regionlist?.region}}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select class="form-select" id="town" name="town" formControlName="town"
                            (change)="onTownChange($event)">
                            <option value="" selected disabled>Select Town</option>
                            <option *ngFor="let townlist of filteredTowns" [value]="townlist.town">{{townlist?.town}}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select class="form-select" id="ward" name="ward" formControlName="ward">
                            <option value="" selected disabled>Select Ward/Village</option>
                            <option *ngFor="let wardlist of filteredWards" [value]="wardlist.postcode">
                              {{wardlist?.ward}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="address" class="form-label">Address</label>
                    <textarea type="text" id="address" class="form-control" formControlName="address"></textarea>
                  </div>
                  <div class="col-12 mb-3">
                    <label for="passportCopy" class="form-label">Photocopy of Passport</label>
                    <input type="file" id="passportCopy" class="form-control"
                      (change)="onFileChange($event, 'passportCopy')">
                  </div>

                  <div class="col-12 mb-3">
                    <label for="visaCopy" class="form-label">Visa Copy</label>
                    <input type="file" id="visaCopy" class="form-control" (change)="onFileChange($event, 'visaCopy')">
                  </div>

                  <div class="col-12 mb-3">
                    <label for="arrivalCopy" class="form-label">Last Date of Arrival Copy</label>
                    <input type="file" id="arrivalCopy" class="form-control"
                      (change)="onFileChange($event, 'arrivalCopy')">
                  </div>

                  <div class="col-12 mb-3">
                    <label for="colorPhoto1" class="form-label">Color Photo 1</label>
                    <input type="file" id="colorPhoto1" class="form-control"
                      (change)="onFileChange($event, 'colorPhoto1')">
                  </div>

                  <div class="col-12 mb-3">
                    <label for="colorPhoto2" class="form-label">Color Photo 2</label>
                    <input type="file" id="colorPhoto2" class="form-control"
                      (change)="onFileChange($event, 'colorPhoto2')">
                  </div>

                  <div class="col-12 mb-3">
                    <label for="visitingCard" class="form-label">Visiting Card</label>
                    <input type="file" id="visitingCard" class="form-control"
                      (change)="onFileChange($event, 'visitingCard')">
                  </div>

                </div>
              </div>

              <div class="col-lg-2 col-md-4 text-center mt-2">
                <div class="profile-img-container cursor-pointer" (click)="onProfileClick()">
                  <div class="uploadfilecontainer">
                    <img [src]="profileImageSrc" alt="Profile Image" class="drag-image">
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>