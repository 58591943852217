import { Component, HostListener, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-late-earlyout-form',
  templateUrl: './late-earlyout-form.component.html',
  styleUrls: ['./late-earlyout-form.component.css']
})
export class LateEarlyoutFormComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isMobileView: boolean = false;
  isFocus: boolean = false;
  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  searchText: string = '';
  orgid: string = '';

  isLateRequest: boolean = true;
  isAdding: boolean = false;
  isEditing: boolean = false;

  dataList: any = [];
  gettingData: boolean = false;
  getListSub!: Subscription;

  organizations: any[] = [];
  end: string = '';
  nextDisabled: boolean = false;
  lastPage: any = '';
  saveLoading : boolean = false;

  submitForm = {
    orgid: '',
    requesttype: ''
  }

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.data.subscribe((data: any) => {
      this.isLateRequest = data.isLateRequest
      this.submitForm.requesttype = data.requesttypeid
    })
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgsCRm;
    this.submitForm.orgid = this.allinoneService.selected_orgcrm
      ? this.allinoneService.selected_orgcrm.orgid
      : this.organizations[0].orgid;
    this.getDataList();
  }

  details() {
    if (this.isLateRequest) {
      this.router.navigateByUrl('office/late-details');
    } else {
      this.router.navigateByUrl('office/earlyout-details');
    }
  }

  save() {

  }

  getDataList() {
    this.gettingData = true;
    const data = {
      orgid: this.submitForm.orgid,
      requesttype: this.submitForm.requesttype,
      requeststatus: '',
      startdate: '',
      enddate: '',
      end: this.end,
      parentid: '',
    };
    this.getListSub && this.getListSub.unsubscribe();
    this.getListSub = this.kunyekService
      .getRequestList(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (res.requestlist.length > 0) {
              this.nextDisabled = false;
              this.end = res.end;
              if (res.requestlist.length < 10) {
                this.nextDisabled = true;
                this.lastPage = this.currentPage;
              }
            } else {
              this.currentPage = this.currentPage - 1;
              this.nextDisabled = true;
              this.lastPage = this.currentPage;
            }

          } else {
            this.messageService.openSnackBar(
              res.error || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingData = false;
        },
        (err) => {
          this.gettingData = false;
        }
      );
  }

  newRequest() {
    this.isAdding = true;
  }

  editData(data: any) {

  }

  deleteData(data: any) {

  }

  pageChange(event: any) {
    if (this.nextDisabled == true) {
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      if (event == this.lastPage && this.lastPage != '') {
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        this.currentPage = event;
        if (event > this.dataList.length / this.itemPerPage) {
          this.getDataList();
        }
      }
    }
  }

  refresh() {
    this.end = '';
    this.lastPage = '';
    this.getDataList();
  }

  sortData(sort: Sort) {
    const data = this.dataList;
    if (!sort.active || sort.direction === '') {
      this.dataList = data;
      return;
    }
    this.dataList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'name') {
        return this.allinoneService.compare(a.name, b.name, isAsc);
      } else if (sort.active == 'description') {
        return this.allinoneService.compare(a.description, b.description, isAsc);
      }
      return 0;
    })
  }

  clear() {
    this.searchText = ""
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  cancel() {
    this.isEditing = false;
    this.isAdding = false;
  }

}
