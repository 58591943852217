<div class="content">
  <app-breadcrumb s_breadcrumb="Counts">
  </app-breadcrumb>
    <div class="row row-custom">
        <div class="mb-3 col-md-6" style="padding: 4%;">
            <div class="my-card">
                <div>
                    <img class="tit-logo" [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="domain.imageurl ? domain.imageurl : '../../../assets/images/post_admin.png'">
                    <!-- <img class="tit-logo" [src]="domain.imageurl"> -->
                    <p class="tit-name">{{domain.description}}</p>
                </div>
                <!-- <hr> -->
                <div class="card-bdy" *ngIf="!gettingDomainCount">
                    <div class="d-flex justify-content-evenly legend">
                        <div class="d-flex flex-column">
                            <p class="bdy-subtit">Today</p>
                            <p class="bdy-count-left">{{domainCount[1]}}</p>
                        </div>
                        <div class="d-flex flex-column">
                            <p class="bdy-subtit">Total</p>
                            <p class="bdy-count-right">{{domainCount[0]}}</p>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center align-items-center">
                        <canvas baseChart [data]="pieChartDomainData" [labels]="pieChartLabels"
                            [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                            [legend]="pieChartLegend" [colors]="pieChartColors">
                        </canvas>
                    </div>

                </div>
                <div class="card-bdy d-flex justify-content-center align-items-center" *ngIf="gettingDomainCount">
                    <span class="spinner-border" role="status" aria-hidden="true"></span>
                </div>
            </div>
        </div>
        <!-- /// -->
        <div class="mb-6 col-md-6" style="padding: 4%;">
            <div class="my-card">
                <div>
                    <img class="tit-logo" [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="selectedOrg.imageurl ? selectedOrg.imageurl : '../../../assets/images/post_admin.png'">
                    <div style="float: right;" *ngIf="!gettingOrgList">
                        <p class="tit-name" data-bs-toggle="dropdown" aria-expanded="false">
                            {{selectedOrg.name}} <i class="fas fa-angle-down org-down ms-2"></i>
                        </p>
                        <ul class="dropdown-menu">
                            <li *ngFor="let organization of orgList; let index = index"  style="cursor: pointer;" (click) = "orgChanged(index)">
                                <span class="dropdown-item">{{organization.name}}</span>
                            </li>
                        </ul>
                    </div>

                </div>
                <!-- <hr> -->
                <div class="card-bdy" *ngIf="!gettingOrgCount">
                    <div class="d-flex justify-content-evenly legend">
                        <div class="d-flex flex-column">
                            <p class="bdy-subtit">Today</p>
                            <p class="bdy-count-left">{{orgCount[1]}}</p>
                        </div>
                        <div class="d-flex flex-column">
                            <p class="bdy-subtit">Total</p>
                            <p class="bdy-count-right">{{orgCount[0]}}</p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                        <canvas baseChart [data]="pieChartOrgData" [labels]="pieChartLabels" [chartType]="pieChartType"
                            [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend"
                            [colors]="pieChartColors">
                        </canvas>
                    </div>
                </div>
                <div class="card-bdy d-flex justify-content-center align-items-center" *ngIf="gettingOrgCount">
                    <span class="spinner-border" role="status" aria-hidden="true"></span>
                </div>
            </div>
        </div>
    </div>
</div>