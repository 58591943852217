<section *ngIf="!verified">
    <div class="signup-wrapper otp-wrapper">
        <div class="mb-2">
            <!-- <img src="assets/images/khub.png" alt="" class="navbar-brand-logo"> -->
            <img [src]="allinOneService.isQrModule ? 'assets/images/qr360.png' :'assets/images/kunyek1.png'" alt=""
                class="navbar-brand-logo">
            <h3 style="font-weight: 600;" class="mb-2">Verification
                <span style="    font-size: 0.7rem;
                opacity: 0.8;">( {{userid}} )</span>
            </h3>
            <span>Enter verification code</span>
        </div>
        <ng-otp-input class="mb-1" (inputStyles)="{'width': '20px'}" #ngOtpInput (onInputChange)="onOtpChange($event)"
            *ngIf="showOtpComponent" [config]="config">
        </ng-otp-input>
        <span *ngIf="!userid.startsWith('+959')" style="font-size: smaller;
        margin-top: 10px;">
            Please check <span style="color: var(--main-color)">{{userid}}</span> for Verification Code. If not found, please double check spam or junk mails.
        </span>
        <div class="d-flex align-items-center">
            <strong class="text-danger my-3" (click)="resend()">Resend Code?</strong>
            <span class="spinner-border spinner-border-sm" *ngIf="isStillResend" role="status"
                aria-hidden="true"></span>
        </div>
        <app-button-loader (click)="verify()" [isLoading]="isStillVerifying" [button_text]="'Verify'"
            [load_button_text]="'Verifying'" [width]="'100'">
        </app-button-loader>
    </div>
</section>


<!-- 

<section *ngIf="verified" style="height: 80vh;">
    <div class="signup-wrapper">
        <div class="mb-3  verified-wrapper">
            <h3 style="font-weight: 600;" class="mb-4">You have successfully verified account.</h3>
        </div>
    </div>
</section> -->