<div class="container-fluid">
    <div class="content">
        <app-team-breadcrumb *ngIf="!isAdding" [s_breadcrumb]="'Rooms'"></app-team-breadcrumb>
        <app-team-breadcrumb *ngIf="isAdding" [s_breadcrumb]="'Rooms'"
            [t_breadcrumb]="isEditing ? 'Edit Room' : 'New Room'" (emit)="cancel()"></app-team-breadcrumb>
        <ng-container *ngIf="isAdding; else listView">
            <div class="d-flex align-items-center">
                <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()">
                    Cancel
                </button>
                <app-button-loader class="mb-3" (click)="submit()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="saveLoading">
                </app-button-loader>
            </div>
            <div style="max-width: max-content;">
                <div class="card card-body my-card w-100">
                    <div class="d-flex align-items-start justify-content-start flex-wrap">
                        <div class="me-3 mb-3">
                            <label for="name" class="form-label">Name</label>
                            <input type="text" class="form-control" id="name" [(ngModel)]="submitForm.name" name="name"
                                #name="ngModel" [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" required />
                            <div class="invalid-feedback" *ngIf="
                                name.invalid ||
                                ((name.dirty || name.touched) && formSubmitted)
                              ">
                                <div *ngIf="name.errors?.required">
                                    {{ allinoneService.input_err_msg }}
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="maxpeople" class="form-label">Max People</label>
                            <input type="number" class="form-control" id="maxpeople" [(ngModel)]="submitForm.maxpeople"
                                name="maxpeople" #maxpeople="ngModel"
                                [ngClass]="{ 'is-invalid': formSubmitted && maxpeople.invalid }" min="1" required />
                            <div class="invalid-feedback" *ngIf="
                            maxpeople.invalid ||
                                ((maxpeople.dirty || maxpeople.touched) && formSubmitted)
                              ">
                                <div *ngIf="maxpeople.errors?.required">
                                    {{ allinoneService.input_err_msg }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #listView>
            <div class="d-flex align-items-center mb-3">
                <button class="btn btn-custom me-3" (click)="new()">
                    <i class="fa fa-plus me-1"></i> New
                </button>
                <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
                    <img class="excel-icon" src="../../../assets/images/excel.png">
                    Export
                </button>
            </div>
            <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
                <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
                    <input type="text" class="form-control" placeholder="&#xf002; Search by Name"
                        aria-label="Search by Name" (focus)="isFocus = true" (blur)="isFocus = false"
                        (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
                        [ngClass]="{ 'c-search-input-with-clear': searchText }"
                        style="font-family: FontAwesome, Ubuntu">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>
                <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false" title="Page Size">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
                        <li>
                            <div style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}"
                                (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                [checked]="page.count==100">
                            <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
                        </li>
                    </ul>
                </div>
                <div class="ms-2">
                    <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
                <div class="line"></div>
                <div class="d-flex align-items-center">
                    <span class="total-count-text">Total:&nbsp;</span>
                    <span class="total-count-num">{{ (roomList | filter : searchText: 'room' ).length }}</span>
                </div>

                <div class="input-group mt-2" *ngIf="isMobileView">
                    <input type="text" class="form-control" placeholder="&#xf002; Search by Name" aria-label="Search"
                        (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                        [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
                        (ngModelChange)="searchTextChange()">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>
                <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
                    *ngIf="roomList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
                </pagination-controls>
            </div>
            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-md-5" mat-sort-header="name">Name</td>
                            <td scope="col" class="col-md-6" mat-sort-header="maxpeople">Max People</td>
                            <td scope="col" class="d-flex justify-content-center">Action</td>
                        </tr>
                    </thead>
                    <tbody *ngIf="tableLoading">
                        <tr>
                            <td colspan="2" class="nodata">
                                <div class="spinner-border" entitle="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!tableLoading">
                        <tr *ngIf="roomList.length == 0">
                            <td colspan="2" class="nodata">Empty</td>
                        </tr>
                        <tr
                            *ngFor="let data of roomList | filter : searchText : 'room' | paginate : { itemsPerPage: itemPerPage , currentPage : currentPage };let index = index">
                            <td class="td-data td-edit" (click)="editRoom(data)">
                                <span [title]="data.name">{{ data.name }}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="data.maxpeople">{{
                                    data.maxpeople
                                    }}</span>
                            </td>
                            <td class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary me-2" (click)="editRoom(data)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-outline-danger" (click)="deleteRoom(data.roomid)">
                                    <i class="fa fa-trash-o"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination-controls class="paginate" (pageChange)="currentPage = $event"
                *ngIf="roomList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
            </pagination-controls>
        </ng-template>
    </div>
</div>