<div class="title-container mb-3">
  <div class="title">
      Share
  </div>
  <div class="close-btn" (click)="close()">
      <mat-icon>close</mat-icon>
  </div>
</div>
<hr>
<div class = "list-container">
  <div *ngFor="let chat of groups;let index = index; trackBy: trackBySort">
    <div class="profile-item">
      <label class = "d-flex align-items-center w-100" [for]="chat.channelid" style="cursor: pointer;">
        <div class="me-3">
          <div *ngIf="chat.userlist.length > 2" class="conversation-summary-avatar conversation-summary-avatar-group"
            data-number-of-thumbnails="3">
            <div class="conversation-summary-avatar-group-member-wrapper">
              <img class="conversation-summary-avatar-group-member-wrapper" [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="chat.userlist[0].imagename ? chat.userlist[0].imagename : '../../../assets/images/profile.png' ">
            </div>
    
            <div class="conversation-summary-avatar-group-member-wrapper">
              <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="chat.userlist[1].imagename ? chat.userlist[1].imagename : '../../../assets/images/profile.png' ">
            </div>
    
            <div class="conversation-summary-avatar-group-member-wrapper">
              <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="chat.userlist[2].imagename ? chat.userlist[2].imagename : '../../../assets/images/profile.png' ">
            </div>
          </div>
          <div *ngIf="chat.userlist.length == 2" class="conversation-summary-avatar conversation-summary-avatar-group"
            data-number-of-thumbnails="2">
            <div class="conversation-summary-avatar-group-member-wrapper">
              <img class="conversation-summary-avatar-group-member-wrapper" [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="chat.userlist[0].imagename ? chat.userlist[0].imagename : '../../../assets/images/profile.png' ">
            </div>
    
            <div class="conversation-summary-avatar-group-member-wrapper">
              <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="chat.userlist[1].imagename ? chat.userlist[1].imagename : '../../../assets/images/profile.png' ">
            </div>
          </div>
        </div>
        <div class="profile-container">
          <div class="nametime">
            <font class="name">{{chat.channelname}} </font>
          </div>
        </div>
      </label>
      <div class = "me-2">
        <input class="form-check-input" type="radio" name="group" [id]="chat.channelid" [(ngModel)]="channelid" [value]="chat.channelid">
      </div>
    </div>
  </div>
</div>

<div class="mt-3" >
  <app-button-loader (click)="share()" [button_text]="'Share'"
      [load_button_text]="'Sharing'" [isLoading]="saveLoading" [width]="'100'">
  </app-button-loader>
</div>