import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
        const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
        if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
            return 'Just now';
        var intervals: any = {
            // 'year': 31536000,
            // 'month': 2592000,
            'w': 604800,
            'd': 86400,
            'h': 3600,
            'm': 60,
            's': 1
        };
        const myintervals = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
        let counter;
        for (const i in intervals) {
            counter = 1;
            counter = Math.floor(seconds / intervals[i]);
            if (counter > 0)
                if (counter === 1) {
                    return counter + '' + i; // singular (1 day ago)
                } else {
                    return counter + '' + i; // plural (2 days ago)
                }
        }
    }
    return value;
}

}

