import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-voted-list',
  templateUrl: './voted-list.component.html',
  styleUrls: ['./voted-list.component.scss']
})
export class VotedListComponent implements OnInit {

  roundid : string = "";
  cid: string = "";
  summary: any = "";
  filterType: string = '';
  summaryInterval : any;


  gettingSummary : boolean = false;
  noRoundError: boolean = false;
  isError: boolean = false;
  refreshing: boolean = false;
  list: any[] = []

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    const temp_cid = this.route.snapshot.paramMap.get('camid');
    if (temp_cid) {
      this.cid = decodeURIComponent(temp_cid);
    }

    const temp_vrid = this.route.snapshot.paramMap.get('roundid');
    if (temp_vrid) {
      this.roundid = decodeURIComponent(temp_vrid);
    } else {
      this.back()
    }

    if (!this.allinoneService.getAtoken()) {
      this.back()
    }
    this.allinoneService.navbar = false
  }

  ngOnDestroy(){
    this.allinoneService.navbar = true
  }

  ngOnInit(): void {
    this.getVotersDetails()
  }

  back(){
    const camid = encodeURIComponent(this.cid)
    const roundid = encodeURIComponent(this.roundid)
    this.router.navigate([`/admin/campaign/${camid}/${roundid}/summary`])
  }

  reload(){
    window.location.reload()
  }

  filterTypeChange(event:any){
    this.filterType = event.target.value
  }

  sortData(sort: Sort) {
    const data = this.list;
    if (!sort.active || sort.direction === '') {
      this.list = data;
      return;
    }

    this.list = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(
            a.name,
            b.name,
            isAsc
          );
        case 'votecount':
          return this.allinoneService.compare(a.votecount, b.votecount, isAsc);
        case 'voted':
          return this.allinoneService.compare(a.voted, b.voted, isAsc);
        default:
          return 0;
      }
    });
  }


  getVotersDetails(){
    this.gettingSummary = true
    const data = {
      "camid" : this.cid,
      "roundid" : this.roundid
    }
    this.kunyekService.getVotersDetails(data).subscribe((res: any) => {
      if(res.returncode == "300"){
        this.summary = res
        this.list = res.datalist
        this.gettingSummary = false
      }else{
        this.isError = true
        if(res.returncode == "240"){
          this.noRoundError = true
        }
        this.gettingSummary = false
        this.messageService.openSnackBar(res.message, 'warn')
      }
    }, err=> {
      this.isError = true
      this.gettingSummary = false
    }
    )
  }

}
