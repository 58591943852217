import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-logistics-users',
  templateUrl: './logistics-users.component.html',
  styleUrls: ['./logistics-users.component.scss']
})
export class LogisticsUsersComponent implements OnInit, OnDestroy {
  searchingCustomer: boolean = false;
  searchCustomerCancel: boolean = false;
  customerSearch = '';
  customerList: any = [];
  selectedUserList: any = [];
  gettingCustomerList: boolean = false;

  subscription!: Subscription;
  mytimeout: any;
  private dialogData: any;
  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<LogisticsUsersComponent>,
    private injector: Injector,
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.selectedUserList = JSON.parse(JSON.stringify(this.dialogData.userlist));
  }
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.homesearchCustomer();
  }

  searchChange() {
    clearTimeout(this.mytimeout);
    this.mytimeout = setTimeout(() => {
      this.homesearchCustomer();
    }, 500);
  }

  homesearchCustomer() {
    this.customerList = [];
    this.gettingCustomerList = true;
    const data = {
      newdesign: true,
      contact: [],
      count: 25,
      search: this.customerSearch,
      orgid: this.allinoneService.selectedOrg,
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.kunyekService.getContactList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.customerList = res.list;
          var temp = res.list;
          if (this.selectedUserList.length > 0) {
            for (var i = 0; i < temp.length; i++) {
              var res = this.selectedUserList.filter(
                (x: any) => x.syskey == temp[i].syskey && x.checked
              );
              if (res.length > 0) {
                temp[i].checked = true;
              }
            }
          } else {
            temp = temp.map((item: any) => ({
              ...item,
              checked: false,
            }));
          }
          this.customerList.push.apply(this.customerList, temp);

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingCustomerList = false;
      },
      (err) => {
        this.gettingCustomerList = false;
      }
    );
  }


  selectUser(event: any, user: any) {
    var index = this.customerList.findIndex((cus: any) => cus.syskey == user.syskey);
    console.log(index)
    if (index != -1) {
      this.customerList[index].checked = !this.customerList[index].checked;
    }
    if (event.target.checked) {
      var index = this.selectedUserList.findIndex(
        (seluser: any) => seluser.syskey == user.syskey
      );
      if (index == -1) {
        user.checked = true;
        this.selectedUserList.push(user);
      } else {
        this.selectedUserList[index].checked = true;
      }
    } else {
      var index = this.selectedUserList.findIndex(
        (seluser: any) => seluser.syskey == user.syskey
      );
      if (index != -1) {
        this.selectedUserList[index].checked = false;
      }
    }
    console.log(this.selectedUserList)
  }

  close(res: any) {
    this.dialogRef.close({ data: res });
  }
  done() {
    let finalList = []
    finalList = this.selectedUserList.filter((user: { checked: boolean; }) => {
      return user.checked == true;
    });
    this.dialogRef.close({ data: finalList });
  }
}

