<div class="d-flex align-items-center justify-content-between mb-3">
  <div>
    <h3 class="mb-0">Filter</h3>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="row">
  <div class="mb-3 col-md-12">
    <label for="requeststatus" class="form-label">Status</label>
    <select class="form-select" [(ngModel)]="searchForm.requeststatus" id="requeststatus" name="requeststatus">
      <option *ngFor="let item of statusList" [value]="item.value">
        {{item.name}}
      </option>
    </select>
  </div>
  <!-- <div class="mb-3 col-md-6">
    <label for="startdate" class="form-label">
      Start Date
    </label>
    <div class="input-group">
      <input type="text" id='startdate' name='startdate' class="form-control datepicker-readonly"
        placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" placement="right"
        [bsValue]="searchForm.startdate" [(ngModel)]="searchForm.startdate" readonly
        [bsConfig]="{showClearButton: true}">
    </div>
  </div>
  <div class="mb-3 col-md-6">
    <label for="enddate" class="form-label">
      End Date
    </label>
    <div class="input-group">
      <input type="text" id='startdate' name='startdate' class="form-control datepicker-readonly"
        placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" placement="left"
        [bsValue]="searchForm.enddate" [(ngModel)]="searchForm.enddate" readonly [bsConfig]="{showClearButton: true}"
        [minDate]="searchForm.startdate">
    </div>
  </div> -->
  <ng-container *ngIf="searchForm.requeststatus == '002'">
    <div class="mb-3 col-md-6">
      <label for="approvedstartdate" class="form-label">
        Approved Start Date
      </label>
      <div class="input-group">
        <input type="text" id='approvedstartdate' name='approvedstartdate' class="form-control datepicker-readonly"
          placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" placement="right"
          [bsValue]="searchForm.approvedstartdate" [(ngModel)]="searchForm.approvedstartdate" readonly
          [bsConfig]="{showClearButton: true}">
      </div>
    </div>
    <div class="mb-3 col-md-6">
      <label for="approvedenddate" class="form-label">
        Approved End Date
      </label>
      <div class="input-group">
        <input type="text" id='approvedenddate' name='approvedenddate' class="form-control datepicker-readonly"
          placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" placement="left"
          [bsValue]="searchForm.approvedenddate" [(ngModel)]="searchForm.approvedenddate" readonly
          [bsConfig]="{showClearButton: true}">
      </div>
    </div>
  </ng-container>
  <div class="mb-3 col-md-6" *ngIf="!hideRequest && !claimRoleView">
    <label for="requesttype" class="form-label">Request Type</label>

    <select class="form-select" [(ngModel)]="searchForm.requesttype" id="requesttype" name="requesttype"
      (change)="requestTypeChange()">
      <ng-container *ngFor="let req of requestTypeList">
        <option [value]="req.requesttypeid">{{req.requesttypename}}
        </option>
      </ng-container>
    </select>
  </div>
  <!-- <div class="mb-3 col-md-6"
    *ngIf="searchForm.requesttype == 'NCMLEIWHRNVIE' || searchForm.requesttype == 'WOEIEPPLMXNW'">
    <label for="type" class="form-label">
      {{searchForm.requesttype == 'NCMLEIWHRNVIE' ? 'Leave Type' : 'Claim Type'}}</label>
    <select class="form-select" [(ngModel)]="searchForm.requestsubtype" id="type" name="type">
      <option value="">All</option>
      <option *ngFor="let rsub of requestSubTypeList" [value]="rsub.requesttypeid">{{rsub.requesttypename}}</option>
    </select>
  </div> -->
  <div class="mb-3 col-md-6"  *ngIf="checkSubType(this.searchForm.requesttype)">
    <div *ngFor="let i of requestTypeList">
      <div *ngIf="i['requesttypeid'] == this.searchForm.requesttype">
        <div *ngIf="i['subtype']">
          <label for="type" class="form-label">
            {{i.requesttypename}} Type</label>
          <select class="form-select" [(ngModel)]="searchForm.requestsubtype" id="type" name="type">
            <option value="">All</option>
            <option *ngFor="let rsub of requestSubTypeList" [value]="rsub.requesttypeid">{{rsub.requesttypename}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3 col-md-6"
    *ngIf="searchForm.requesttype == 'WOEIEPPLMXNW' && (searchForm.requeststatus == '002' || searchForm.requeststatus == '004')">
    <label for=" paymentstatus" class="form-label">Payment Status</label>
    <select class="form-select" [(ngModel)]="searchForm.paymentstatus" id="paymentstatus" name="paymentstatus">
      <option value="">All</option>
      <option value="1">Paid</option>
      <option value="2">Unpaid</option>
    </select>
  </div>
  <div class="mb-3 col-md-6" *ngIf="!hideRequest">
    <label for="type" class="form-label">Type</label>
    <select class="form-select" [(ngModel)]="searchForm.teammember" id="type" name="type">
      <option *ngFor="let type of teamMember" [value]="type.value">{{type.name}}</option>
    </select>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button type="button" class="btn btn-outline-secondary me-2" mat-dialog-close>
    Cancel
  </button>
  <app-button-loader (click)="search()" [button_text]=" 'Search'" [load_button_text]=" ''"
    [isLoading]="exportingresult"></app-button-loader>
</div>