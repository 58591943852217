<perfect-scrollbar [ngClass]="{'event-container':  checkModeal}" [config]="config" [scrollIndicators]="true"
    fxFlex="auto" #perfectScroll>
    <div class="container-fluid">

        <div class="content" *ngIf="!memberform">
            <div class="d-flex justify-content-between align-items-center mb-2" *ngIf="checkModeal">
                <div class="label title" *ngIf="!projAddOrNot" style="font-size: 17px;">Projects</div>
                <div class="label title" *ngIf="projAddOrNot" style="font-size: 17px;">Add Project</div>
                <div class="close-btn">
                    <button mat-icon-button mat-dialog-close [disabled]="saveLoading" (click)="Close()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <app-team-breadcrumb *ngIf="!checkModeal  && routesarch !='crm'" [s_breadcrumb]="'Projects'"
                [t_breadcrumb]="projAddOrNot ? isEditing ? projForm.name:  'New Project': ''" (emit)="cancel()">
            </app-team-breadcrumb>
            <app-crm-breadcrumb *ngIf="!checkModeal && routesarch =='crm'" [s_breadcrumb]="'Projects'"
                (emit)="cancelFunction()">
            </app-crm-breadcrumb>


            <div class="row row-custom header">
                <div class="col-md-12 mb-3 d-flex">
                    <ng-container *ngIf="!projAddOrNot">
                        <button type="button" class="btn btn-custom" (click)="projAddOrNot = !projAddOrNot">
                            <i class="fa fa-plus me-1"></i>New Project
                        </button>

                    </ng-container>

                    <div *ngIf="projAddOrNot" class="close-save-button">
                        <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                            Cancel
                        </button>
                        <app-button-loader (click)="submitProject()" [button_text]="'Save'"
                            [load_button_text]="'Saving'" [isLoading]="saveLoading"></app-button-loader>


                        <!-- <app-button-loader (click)="test()" [button_text]="'T'"
                            [load_button_text]="'T..'" [isLoading]="saveLoading"></app-button-loader> -->

                    </div>
                    <div class="line">
                    </div>
                    <button type="button" class="btn btn-custom me-2" (click)="openStatusForm()" title="Status">
                        Status
                    </button>

                    <div class="ms-auto input-group" style="width: 300px !important;"
                        *ngIf="!projAddOrNot && checkModeal">
                        <input class="form-control" type="text" placeholder="&#xf002; Search with Name/Description"
                            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false"
                            [(ngModel)]="searchText" [ngClass]="{'c-search-input-with-clear' : searchText }"
                            style="font-family: FontAwesome,Ubuntu;">
                        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                            <mat-icon class="c-icon">close</mat-icon>
                        </button>
                    </div>

                </div>
                <div *ngIf="!projAddOrNot && callType == '400' && !checkModeal" class="row col-md-6  g-0 mb-3">
                    <label for="organization" class="col-form-label"
                        [ngClass]="{'col-3 col-md-3': !projAddOrNot && !isMobileView}">Organization</label>
                    <div [ngClass]="{'col-9 col-md-9': !projAddOrNot && !isMobileView}">
                        <select class="form-select" aria-label="Select Organization" id="organization"
                            name="organization" (change)="orgChanged($event)" [(ngModel)]="projForm.org" required
                            #org="ngModel" [ngClass]="{ 'is-invalid': projFormSubmitted && org.invalid }"
                            [disabled]="gettingOrgList">
                            <option selected value=""> {{projAddOrNot ? 'Select Organization' : '-'}}</option>
                            <option *ngFor="let organization of organizations" value="{{organization.orgid}}">
                                {{organization.name}}</option>
                        </select>
                        <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="org.invalid || (org.dirty || org.touched) && projFormSubmitted">
                        <div *ngIf="org.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <form> -->
            <div *ngIf="projAddOrNot">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="name" class="form-label">Project Name</label>
                        <input type="text" class="form-control" [(ngModel)]="projForm.name" id="name" name="name"
                            placeholder="Enter Project Name" required #name="ngModel"
                            [ngClass]="{ 'is-invalid': projFormSubmitted && name.invalid }"
                            (keyup.enter)="submitProject()" (change)="generateShortCode()">

                        <div class="invalid-feedback"
                            *ngIf="name.invalid || (name.dirty || name.touched) && projFormSubmitted">
                            <div *ngIf="name.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">

                        <label for="shortcode" class="form-label">Short Code</label>
                        <div class="input-group">
                            <span class="input-group-text" id="prefix">P-</span>
                            <input type="text" class="form-control" [(ngModel)]="projForm.shortcode" id="shortcode"
                                name="shortcode" placeholder="" #shortcode="ngModel"
                                [ngClass]="{ 'is-invalid': projFormSubmitted && shortcode.invalid }"
                                (keyup.enter)="submitProject()" required minlength="3" maxlength="5">
                            <div class="invalid-feedback"
                                *ngIf="(shortcode.invalid || shortcode.dirty || shortcode.touched) && projFormSubmitted">
                                <div *ngIf="shortcode.errors?.required">
                                    {{allinoneService.input_err_msg}}
                                </div>
                                <div *ngIf="shortcode.errors?.minlength">
                                    Short code should be 3-5 letters.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="mb-3" *ngIf="callType == '400'">
                    <label for="organization" class="col-form-label"
                        [ngClass]="{'col-3 col-md-3': !projAddOrNot && !isMobileView}">Organization</label>
                    <div [ngClass]="{'col-9 col-md-9': !projAddOrNot && !isMobileView}">
                        <select class="form-select" aria-label="Select Organization" id="organization"
                            name="organization" (change)="orgChanged($event)" [(ngModel)]="projForm.org" required
                            #org="ngModel" [ngClass]="{ 'is-invalid': projFormSubmitted && org.invalid }"
                            [disabled]="gettingOrgList">
                            <option selected value=""> {{projAddOrNot ? 'Select Organization' : '-'}}</option>
                            <option *ngFor="let organization of organizations" value="{{organization.orgid}}">
                                {{organization.name}}</option>
                        </select>
                        <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="org.invalid || (org.dirty || org.touched) && projFormSubmitted">
                        <div *ngIf="org.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
                <div class="row g-3 mb-3 ">
                    <div class="col-md-6">
                        <label for="startdate" class="form-label">Start Date</label>
                        <!-- <input type="date" class="form-control" id="startdate" name="startdate"
                            [(ngModel)]="projForm.startdate" required #startdate="ngModel"
                            (ngModelChange)="startDateChange()"
                            [ngClass]="{ 'is-invalid': projFormSubmitted && startdate.invalid }" max="9999-12-31"
                            step="1"> -->
                        <input type="text" class="form-control datepicker-readonly" name="startdate"
                            placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" readonly
                            [(ngModel)]="projForm.startdate" style="min-width: 200px;" required #startdate="ngModel"
                            [ngClass]="{ 'is-invalid': projFormSubmitted && startdate.invalid }"
                            (ngModelChange)="startDateChange()">

                        <div class="invalid-feedback"
                            *ngIf="startdate.invalid || (startdate.dirty || startdate.touched) && projFormSubmitted">
                            <div *ngIf="startdate.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="enddate" class="form-label">End Date</label>
                        <!-- <input type="date" class="form-control" id="enddate" name="enddate"
                            [(ngModel)]="projForm.enddate" required #enddate="ngModel"
                            [ngClass]="{ 'is-invalid': projFormSubmitted && enddate.invalid }" max="9999-12-31"
                            [min]="projForm.startdate" step="1"> -->
                        <input type="text" name="enddate" class="form-control datepicker-readonly"
                            placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" readonly
                            [(ngModel)]="projForm.enddate" [minDate]="projForm.startdate" style="min-width: 200px;"
                            required #enddate="ngModel"
                            [ngClass]="{ 'is-invalid': projFormSubmitted && enddate.invalid }">

                        <div class="invalid-feedback"
                            *ngIf="enddate.invalid || (enddate.dirty || enddate.touched) && projFormSubmitted">
                            <div *ngIf="enddate.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <label for="memberstatus" class="form-label">Type</label>
                        <select class="form-select" id="memberstatus" name="memberstatus"
                            [(ngModel)]="projForm.memberstatus" required #ms="ngModel">
                            <option *ngFor="let ms of memberStatus" value="{{ms.id}}">
                                {{ms.name}}</option>
                        </select>
                        <div *ngIf="projForm.memberstatus == '1'" style="width: fit-content;">
                            <div class="mt-2 d-flex align-items-center select-member" (click)="openMember()">
                                <span>
                                    Selected Member - {{finalSelectedMember.length}}
                                </span>
                                <span>
                                    <mat-icon class="ms-2 member-edit d-flex">edit</mat-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="status-container card" *ngIf="statusUiList.length > 0">

                    <ng-container *ngFor="let statusdata of statusUiList; let i=index; let first=first">
                        <div class="status-input-container">
                            <div class="status-input">
                                <label *ngIf="first" [attr.for]="'status'+i" class="form-label can-click cc-label"
                                    (click)="openStatusForm()">Status</label>
                                <select class="form-select" aria-label="Select Status" [attr.id]="'status'+i"
                                    [attr.name]="'status'+i" [(ngModel)]="statusdata.psid"
                                    [disabled]="gettingStatusList || projForm.completed">
                                    <option value=''>-</option>
                                    <option *ngFor="let status of projectStatusList; let index =  index"
                                        [value]="status.psid">{{status.name}}
                                    </option>
                                </select>
                                <mat-progress-bar mode="indeterminate" *ngIf="gettingStatusList"></mat-progress-bar>
                            </div>
                            <div class="status-input">
                                <label *ngIf="first" [attr.for]="'sd'+i" class="form-label"> Start Date </label>
                                <!-- <input type="date" class="form-control" [attr.id]="'sd'+i" [attr.name]="'sdn'+i"
                                    [(ngModel)]="statusdata.startdate" max="9999-12-31" [disabled]="projForm.completed"> -->
                                <input type="text" [attr.id]="'sd'+i" [attr.name]="'sdn'+i"
                                    class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
                                    [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="statusdata.startdate"
                                    style="min-width: 200px;" required #startdate="ngModel"
                                    [disabled]="projForm.completed">
                            </div>
                            <div class="status-input">
                                <label *ngIf="first" [attr.for]="'ed'+i" class="form-label"> End Date </label>
                                <!-- <input type="date" class="form-control" [attr.id]="'ed'+i" [attr.name]="'ed'+i"
                                    [(ngModel)]="statusdata.enddate" [min]="statusdata.startdate" max="9999-12-31"
                                    [disabled]="projForm.completed"> -->
                                <input type="text" [attr.id]="'ed'+i" [attr.name]="'ed'+i"
                                    class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
                                    [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="statusdata.enddate"
                                    style="min-width: 200px;" required #enddate="ngModel"
                                    [disabled]="projForm.completed">
                            </div>
                            <div class="">
                                <button class="btn prize-btn remove-btn-custom" [disabled]="projForm.completed"
                                    (click)="projForm.completed ? '' : removeSubmitConfig(i)">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </div>
                        </div>

                    </ng-container>
                    <div *ngIf="!projForm.completed" class="" style="margin-top: 5px;">
                        <div class="">
                            <button class="btn btn-custom" (click)="addNewStatus()">
                                <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="isEditing" class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" id="completed" name="completed"
                        [(ngModel)]="projForm.completed">
                    <label class="form-check-label" for="completed">
                        Completed
                    </label>
                </div>
                <!-- <div>
                        <ng-container *ngFor="let x of statusUiList; let i=index; let first=first">
                            <div class="col-md-5 mb-3">
                                <label [attr.for]="'status'+i" class="form-label">Status</label>
                                <select class="form-select" aria-label="Select Status" [attr.id]="'status'+i"
                                    [attr.name]="'status'+i" [(ngModel)]="x.statusid" required #status="ngModel"
                                    [ngClass]="{ 'is-invalid': projFormSubmitted && status.invalid }">
                                    <option
                                        *ngFor="let status of (isEditing? statusList : statusListForNewProj); let index =  index"
                                        [value]="status">{{status}}
                                    </option>
                                </select>

                                <div class="invalid-feedback"
                                    *ngIf="status.invalid || (status.dirty || status.touched) && projFormSubmitted">
                                    <div *ngIf="status.errors?.required">
                                        {{allinoneService.input_err_msg}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label [attr.for]="'sd'+i" class="form-label"> Start Date </label>
                                <input type="text" class="form-control" [attr.id]="'sd'+i" [attr.name]="'sd'+i"
                                    placeholder="Start Date" />
                            </div>
                            <div class="col-md-3 mb-3">
                                <label [attr.for]="'ed'+i" class="form-label"> End Date </label>
                                <input type="text" class="form-control" [attr.id]="'ed'+i" [attr.name]="'ed'+i"
                                    placeholder="End Date" />
                            </div>
                            <div class="col-md-1 mb-3">
                                <button class="btn btn-custom prize-btn" (click)="removeSubmitConfig(i)">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div> -->
                <!-- <div class="mb-3 col-md-4">
                        <label for="status" class="form-label">Status</label>
                        <select class="form-select" aria-label="Select Status" id="status" name="status"
                            [(ngModel)]="projForm.status" required #status="ngModel"
                            [ngClass]="{ 'is-invalid': projFormSubmitted && status.invalid }">
                            <option
                                *ngFor="let status of (isEditing? statusList : statusListForNewProj); let index =  index"
                                [value]="status">{{status}}
                            </option>
                        </select>

                        <div class="invalid-feedback"
                            *ngIf="status.invalid || (status.dirty || status.touched) && projFormSubmitted">
                            <div *ngIf="status.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 col-md-4">
                        <label for="name" class="form-label"> Start Date </label>
                        <input type="text" class="form-control" id="startdate" name="startdate"
                            placeholder="Start Date" />
                    </div>
                    <div class="mb-3 col-md-4">
                        <label for="name" class="form-label"> End Date </label>
                        <input type="text" class="form-control" id="enddate" name="enddate"
                            placeholder="End Date" />
                    </div> -->

                <div class="mb-3">
                    <label for="description" class="form-label">Description</label>
                    <textarea class="form-control" id="description" [(ngModel)]="projForm.description"
                        name="description" rows="5" placeholder="Enter Description for Your Project"></textarea>
                </div>
            </div>
            <!-- </form> -->
            <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center"
                *ngIf="projAddOrNot == false && !checkModeal">
                <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
                    <input class="form-control" type="text" placeholder="&#xf002; Search with Name/Description"
                        aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                        [ngClass]="{'c-search-input-with-clear' : searchText }"
                        style="font-family: FontAwesome,Ubuntu;">
                    <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>
                <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false" title="Page Size">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <div c style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}"
                                (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                [checked]="page.count==100">
                            <label for="pg{{page.name}}" class="col-10 p-1">
                                {{page.name}}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="ms-2">
                    <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
                <div class="line"></div>
                <div class="d-flex align-items-center">
                    <span class="total-count-text">Total:&nbsp;</span> <span
                        class="total-count-num">{{projects.length}}</span>
                </div>
            </div>
            <div class="input-group mb-2" *ngIf="isMobileView && !projAddOrNot">
                <input class="form-control" type="text" placeholder="&#xf002; Search with Name/Description"
                    aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                    [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                    [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                    <mat-icon class="c-icon">close</mat-icon>
                </button>
            </div>
            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless" *ngIf="projAddOrNot == false">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="name">Project Name</td>
                            <td scope="col" class="col-lg-3 col-md-2" mat-sort-header="projectshortcode">Shortcode</td>
                            <td scope="col" class="col-lg-3 col-md-2" mat-sort-header="desc">Description</td>
                            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="startdate">Start Date</td>
                            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="enddate">End Date</td>
                            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="status">Status</td>
                            <td scope="col" class="d-flex justify-content-center">Action</td>
                        </tr>
                    </thead>
                    <tbody *ngIf="projListLoading">
                        <tr>
                            <td colspan="7" class="nodata">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="projListLoading == false">
                        <tr *ngIf="projects.length == 0">
                            <td colspan="7" class="nodata">Empty</td>
                        </tr>
                        <tr
                            *ngFor="let item of projects|filter : searchText:9 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index">
                            <td class="td-data td-edit" (click)="editProject(item)">
                                <span [title]="item.name">{{item.name}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="item.projectshortcode">{{item.projectshortcode}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="item.description">{{item.description}}</span>
                            </td>
                            <td class="td-data">
                                <span
                                    [title]="allinoneService.formatDBToShow(item.startdate)">{{allinoneService.formatDBToShow(item.startdate)}}</span>
                            </td>
                            <td class="td-data">
                                <span
                                    [title]="allinoneService.formatDBToShow(item.startdate)">{{allinoneService.formatDBToShow(item.enddate)}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="item.completed ? 'Completed' : item.status">{{item.completed ?
                                    'Completed' : item.status}}</span>
                            </td>
                            <td class="d-flex justify-content-center">
                                <button class="btn btn-outline-primary" (click)="editProject(item)">
                                    <i class='fa fa-edit'></i>
                                </button>
                                <!-- <button class="btn btn-outline-danger" (click)="deleteProject(item.id)" [disabled] = "item.role == '300' && item.userid == allinoneService.getKunyekUserId()">
                                <i class='fa fa-trash-o'></i>
                            </button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination-controls class="paginate" (pageChange)="p = $event"
                *ngIf="projects.length > itemPerPage && !projAddOrNot" previousLabel="Prev" nextLabel="Next">
            </pagination-controls>
        </div>
        <div class="content" *ngIf="memberform">
            <app-breadcrumb *ngIf="!checkModeal && routesarch != 'crm'" [s_breadcrumb]="'Projects'"
                [t_breadcrumb]="projAddOrNot ? isEditing ? projForm.name:  'New Project': ''" [f_breadcrumb]="'Members'"
                (emit)="cancel()" (emit1)="cancelMember()">

            </app-breadcrumb>
            <app-crm-breadcrumb *ngIf="!checkModeal && routesarch =='crm'" [s_breadcrumb]="'Projects'"
                [t_breadcrumb]="projAddOrNot ? isEditing ? projForm.name:  'New Project': ''" [f_breadcrumb]="'Members'"
                (emit)="cancel()" (emit1)="cancelMember()">

            </app-crm-breadcrumb>

            <div class="close-save-button mb-3">
                <button type="button" class="btn btn-custom me-2" (click)="cancelMember()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader (click)="saveMember()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="saveLoading"></app-button-loader>
            </div>
            <div>
                <div class="mb-3">
                    <input type="text" placeholder="Select Member" aria-label="Number" matInput class="form-control"
                        [matAutocomplete]="auto1" [(ngModel)]="memberSearchText" name="memberselect" id="memberselect"
                        [disabled]="gettingMembers" #trigger="matAutocompleteTrigger">
                    <mat-progress-bar mode="indeterminate" *ngIf="gettingMembers"></mat-progress-bar>
                    <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option *ngFor="let member of members| filter : memberSearchText:16 "
                            (onSelectionChange)="selectMember(member, trigger)" (click)="selectMember(member, trigger)">
                            <!-- {{member.username}} {{member.userid}} -->
                            {{member.userid}}{{ member.newuserid ? ', ' + member.newuserid : '' }}{{member.username ? '
                            - ' + member.username : ''}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <div class="mb-2 d-flex flex-wrap justify-content-end align-items-center">
                    <div class="d-flex align-items-center">
                        <span class="total-count-text">Total:&nbsp;</span> <span
                            class="total-count-num">{{selectedMember.length}}</span>
                    </div>
                    <div class="line"></div>
                    <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                            (focus)="isFocus1 = true" (blur)="isFocus1 = false" [(ngModel)]="searchText1"
                            [ngClass]="{'c-search-input-with-clear' : searchText1 }"
                            style="font-family: FontAwesome,Ubuntu;">
                        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText1" (click)="clear()"
                            [ngClass]="{'c-clear' : isFocus1, 'c-clear-not-focus': !isFocus1}">
                            <mat-icon class="c-icon">close</mat-icon>
                        </button>
                    </div>
                    <div class="dropdown ms-2">
                        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false" title="Page Size">
                            <mat-icon>filter_list</mat-icon>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div c style="text-align: center;">Page size</div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li class="dropdown-item" *ngFor="let page of pgMember">
                                <input type="radio" name="pg-count" value="{{page.count}}"
                                    (change)="changePageCountMember($event)" id="pg{{page.name}}" class="p-1"
                                    [checked]="page.count==100">
                                <label for="pg{{page.name}}" class="col-10 p-1">
                                    {{page.name}}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="input-group mb-2" *ngIf="isMobileView">
                    <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                        (focus)="isFocus1 = true" (blur)="isFocus1 = false" [(ngModel)]="searchText1"
                        [ngClass]="{'c-search-input-with-clear' : searchText1 }"
                        style="font-family: FontAwesome,Ubuntu;">
                    <button class="btn btn-outline-secondary " type="button" *ngIf="searchText1" (click)="clear()"
                        [ngClass]="{'c-clear' : isFocus1, 'c-clear-not-focus': !isFocus1}">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>
                <div>
                    <div class="table-responsive" matSort (matSortChange)="sortMemberData($event)">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="userid">User ID</td>
                                    <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="username">Name</td>
                                    <td scope="col" class="d-flex justify-content-center">Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="selectedMember.length == 0">
                                    <td colspan="3" class="nodata">Empty</td>
                                </tr>
                                <tr
                                    *ngFor="let member of selectedMember| filter : searchText1:16 | paginate: { itemsPerPage: itemPerPageMember, currentPage: q };let index = index">
                                    <td>
                                        {{member.userid}}
                                    </td>
                                    <td>
                                        {{member.username}}
                                    </td>
                                    <td class="d-flex justify-content-center">
                                        <button class="btn btn-outline-danger" (click)="removeMember(index)">
                                            <i class='fa fa-trash-o'></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination-controls class="paginate" (pageChange)="q = $event"
                        *ngIf="selectedMember.length > itemPerPageMember" previousLabel="Prev" nextLabel="Next">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</perfect-scrollbar>