<!-- <p>accessories-request-history works!</p> -->
<div class="d-flex justify-content-between align-items-center mb-2">
<h3 style="margin: 0;padding: 0;">History</h3>
<div class="close-btn mb-2">
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
</div>
<div>
    <div class="table-responsive">
        <table class="table table-responsive table-borderless">
            <thead class="table-header">
                <tr>
                    <td scope="col" class="col-md-4">User ID</td>
                    <td scope="col" class="col-md-4">Status</td>
                    <td scope="col" class="col-md-4">Date</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="historyList.length == 0">
                    <td colspan="3" class="nodata">No Record Found</td>
                  </tr>
                  <tr *ngFor="let data of historyList;let index = index">
                    <td class="td-data">
                        <span>{{ data.userid }}</span>
                      </td>
                      <td class="td-data">
                        <span class="status" [ngClass]="{
                            'status-gray': data.status == '001',
                            'status-main': data.status == '002' || data.status == '003', 
                            'status-green': data.status == '004',
                            'status-red': data.status == '005'
                          }">{{ getTableStatus(data.status) }}</span>
                      </td>
                      <td class="td-data">
                        <span>{{ allinoneService.formatDBToShow(data.date) }}</span>
                      </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <div class="d-flex justify-content-center align-items-center" *ngIf="historyList.length == 0" style="height:200px;">
        <p>No Record Found</p>
    </div> -->
</div>
<!-- <div mat-dialog-actions align="end">
    <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary">
        Close
    </button>
</div> -->