import { Component, OnDestroy, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';

@Component({
  selector: 'app-server-maintenance',
  templateUrl: './server-maintenance.component.html',
  styleUrls: ['./server-maintenance.component.scss'],
})
export class ServerMaintenanceComponent implements OnInit, OnDestroy {
  constructor(private allinoneService: AllInOneService) {
    this.allinoneService.navbar = false;
  }
  ngOnDestroy(): void {
    this.allinoneService.navbar = true;
  }

  ngOnInit(): void {
  }
}
