<div class='container-fluid'>
  <div class="content" *ngIf="isWfhPolicyForm">
    <app-office-leave-policy [isWfhPolicy]="true" [wfhData]="wfhData"
      (cancelEvent)="cancelWfhPolicy($event)"></app-office-leave-policy>
  </div>
  <div class="content" *ngIf="!isWfhPolicyForm">

    <!-- <ng-container *ngIf="isAdmin">
      <app-team-breadcrumb *ngIf="!isviewornew" s_breadcrumb="Request Type">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="isviewornew" s_breadcrumb="Request Type"
        [t_breadcrumb]="isEditing ? 'Edit Request Sub Type' : isParentReqEditing ? 'Edit Request Type' : 'New Request Type'"
        (emit)="cancel()">
      </app-team-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb *ngIf="!isviewornew" [s_breadcrumb]="'Request Type'">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="isviewornew" [s_breadcrumb]="'Request Type'"
        [t_breadcrumb]="isEditing ? 'Edit Request Sub Type' : isParentReqEditing ? 'Edit Request Type' : 'New Request Type'"
        (emit)="cancel()">
      </app-office-breadcrumb>
    </ng-container> -->
    <ng-container *ngIf="allinoneService.appView == '002'">
      <app-team-breadcrumb *ngIf="!isviewornew" s_breadcrumb="Request Type">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="isviewornew" s_breadcrumb="Request Type"
        [t_breadcrumb]="isEditing ? 'Edit Request Sub Type' : isParentReqEditing ? 'Edit Request Type' : 'New Request Type'"
        (emit)="cancel()">
      </app-team-breadcrumb>
    </ng-container>

    <ng-container *ngIf="allinoneService.appView == '001' && isAdmin">
      <app-hcm-breadcrumb *ngIf="!isviewornew" s_breadcrumb="Request Type">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="isviewornew" s_breadcrumb="Request Type"
        [t_breadcrumb]="isEditing ? 'Edit Request Sub Type' : isParentReqEditing ? 'Edit Request Type' : 'New Request Type'"
        (emit)="cancel()">
      </app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="allinoneService.appView == '001' && !isAdmin">
      <app-office-breadcrumb *ngIf="!isviewornew" s_breadcrumb="Request Type">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="isviewornew" s_breadcrumb="Request Type"
        [t_breadcrumb]="isEditing ? 'Edit Request Sub Type' : isParentReqEditing ? 'Edit Request Type' : 'New Request Type'"
        (emit)="cancel()">
      </app-office-breadcrumb>
    </ng-container>

    <div class="close-save-button mb-3" *ngIf="isviewornew">
      <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
        Cancel
      </button>
      <app-button-loader (click)="submitReqType()" [button_text]="'Save'" [load_button_text]="'Saving'"
        [isLoading]="saveLoading">
      </app-button-loader>
    </div>

    <div class="row row-custom header" *ngIf="!isviewornew">
      <div class="col-md-6 mb-4 d-flex">
        <button class="btn btn-custom me-2" (click)="newReqType()">
          <i class="fa fa-plus me-1"></i> Request Type
        </button>
      </div>


    </div>

    <div *ngIf="isviewornew">
      <!-- <form action=""> -->

      <div class="card card-body my-card mb-3">
        <div class="row">
          <div class="mb-3" [ngClass]="{'col-md-6': !isParentReqEditing, 'col-md-12': isParentReqEditing}">
            <label for="type" class="form-label">
              Type
            </label>
            <select class="form-select" id="type" name="type" (change)="typeChange($event)"
              [(ngModel)]="reqTypeForm.typeid" required #type="ngModel"
              [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && type.invalid }"
              [disabled]="requestTypeListLoading || isEditing || isParentReqEditing">
              <ng-container *ngFor="let tl of typeList; trackBy: trackById">
                <ng-container *ngIf="!isParentReqEditing">
                  <option value="{{tl.id}}"
                    *ngIf="tl.id != 'BCNDUWO85SCGQW' && tl.id != 'VMAOWEFAMSVNAOWI' && tl.issubtype"
                    [selected]="tl.id == reqTypeForm.typeid">{{tl.name}}
                  </option>
                </ng-container>
                <ng-container *ngIf="isParentReqEditing">
                  <option value="{{tl.id}}" *ngIf="tl.id == reqTypeForm.id" [selected]="tl.id == reqTypeForm.typeid">
                    {{tl.name}}
                  </option>
                </ng-container>

              </ng-container>

            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="requestTypeListLoading"></mat-progress-bar>

            <div class="invalid-feedback" *ngIf="type.invalid || (type.dirty || type.touched) && reqTypeFormSubmitted">
              <div *ngIf="type.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>

          <div class="mb-3 col-md-6" *ngIf="!isParentReqEditing">
            <label for="name" class="form-label">Sub Type</label>
            <input type="text" class="form-control" id="name" name="name" [(ngModel)]="reqTypeForm.subtype" required
              #name="ngModel" [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && name.invalid }">
            <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && reqTypeFormSubmitted">
              <div *ngIf="name.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>
        </div>

        <!-- <button (click)="t()">test</button> -->

        <!-- now -->
        <div *ngIf="!isParentReqEditing" class="d-flex align-items-center">
          <div class="form-check mb-3 me-5"> <input class="form-check-input" type="radio" name="approvaltype"
              id="default" value="001" [(ngModel)]="approvaltype" (ngModelChange)="approvalTypeChange('001')">
            <label class="form-check-label" for="default">
              Default
            </label>
          </div>
          <div class="form-check mb-3"> <input class="form-check-input" type="radio" name="approvaltype" id="stepbystep"
              value="002" [(ngModel)]="approvaltype" (ngModelChange)="approvalTypeChange('002')">
            <label class="form-check-label" for="stepbystep">
              Step by step
            </label>
          </div>
        </div>

        <div class="mb-2 d-flex" *ngIf="approvaltype == '001'">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="ro" [(ngModel)]="reqTypeForm.ro" name="ro">
            <label class="form-check-label mb-1 ms-1 mr-2" for="ro">
              RO
            </label>
          </div>
          <!-- <div class="ms-5 form-check" *ngIf="reqTypeForm.typeid == leaveid">
            <input class="form-check-input" type="checkbox" id="unlimited" [(ngModel)]="reqTypeForm.unlimited"
              name="unlimited">
            <label class="form-check-label mb-1 ms-1 mr-2" for="unlimited">
              Unlimited
            </label>
          </div> -->
        </div>

        <div class="mb-3" *ngIf="approvaltype == '001'">
          <label class="form-label cc-label" (click)="addRoleList()">
            Role
          </label>
          <ng-select [ngModelOptions]="{standalone: true}" class="role-select" [items]="roleList" bindLabel="name"
            placeholder="Select Role" appendTo="body" [multiple]="true" [(ngModel)]="reqTypeForm.roleList">
          </ng-select>
          <mat-progress-bar mode="indeterminate" *ngIf="roleListLoading"></mat-progress-bar>
        </div>

        <div class="mb-3" *ngIf="approvaltype == '002'">
          <ng-container *ngFor="let pre of approvalTypeSubmitConfig; let i=index; let first=first">
            <div class="d-flex gap-3 align-items-end">
              <div class="mb-3 flex-fill">

                <select class="form-select" [attr.name]="'approvaltype' + i" [attr.id]="'approvaltype' + i"
                  [(ngModel)]="pre.status">
                  <option value="">-</option>
                  <option *ngFor="let plc of approvalTypeList" [value]="plc.step"
                    [disabled]="checkApprovalSubmitConfig(plc.step)">
                    {{plc.name}}
                  </option>
                </select>
              </div>



              <div class="mb-3"> <button class="btn btn-custom prize-btn"
                  style="width:50px !important; background-color: rgb(200, 21, 21) !important;"
                  (click)="removeApprovalConfig(i)"> <mat-icon>remove_circle</mat-icon> </button> </div>
            </div>

            <div class="ms-3 mb-3" style="margin-right: 65px;" *ngIf="checkShowRoleSelect(i)">
              <!-- <ng-select [ngModelOptions]="{standalone: true}" class="role-select" [items]="roleList.roleid"
                bindLabel="name" placeholder="Select Role" appendTo="body" [multiple]="true"
                [(ngModel)]="approvalTypeSubmitConfig[i].value">
              </ng-select> -->

              <ng-select [ngModelOptions]="{standalone: true}" class="role-select" bindLabel="name"
                placeholder="Select Role" appendTo="body" [multiple]="true"
                [(ngModel)]="approvalTypeSubmitConfig[i].value">
                <ng-option *ngFor="let role of roleList" [value]="role.roleid">{{role.name}}</ng-option>
              </ng-select>

              <!-- <ng-select placeholder="Select locations" [multiple]="true" [(ngModel)]="selectedLocation" name="location"
                id="location">
                <ng-option *ngFor="let loc of locations" [value]="loc.locid">{{loc.name}}</ng-option>
              </ng-select> -->

              <mat-progress-bar mode="indeterminate" *ngIf="roleListLoading"></mat-progress-bar>
            </div>

          </ng-container>
          <div class="row">
            <div class="col-md-3"> <button class="btn btn-custom mb-3" (click)="addApprovalConfig()">
                <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span> </button> </div>
          </div>
        </div>

        <div *ngIf="reqTypeForm.typeid == transportationid">
          <div class="d-flex align-items-center">
            <div class="form-check mb-3 me-5"> <input class="form-check-input" type="radio" name="transportationtype"
                id="ferry" value="001" [(ngModel)]="reqTypeForm.trantype">
              <label class="form-check-label" for="ferry">
                Ferry
              </label>
            </div>
            <div class="form-check mb-3"> <input class="form-check-input" type="radio" name="transportationtype"
                id="daily" value="002" [(ngModel)]="reqTypeForm.trantype">
              <label class="form-check-label" for="daily">
                Daily
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="reqTypeForm.typeid == reservationid">
          <div class="d-flex align-items-center">
            <div class="form-check mb-3 me-5"> <input class="form-check-input" type="radio" name="myreservationtype"
                id="room" value="001" [(ngModel)]="reqTypeForm.reservationtype">
              <label class="form-check-label" for="room">
                Room
              </label>
            </div>
            <div class="form-check mb-3"> <input class="form-check-input" type="radio" name="myreservationtype"
                id="desk" value="002" [(ngModel)]="reqTypeForm.reservationtype">
              <label class="form-check-label" for="desk">
                Desk
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="reqTypeForm.typeid == claimid">
          <div class="d-flex align-items-center">
            <div class="form-check mb-3">
              <input class="form-check-input" type="checkbox" name="taxifare" id="taxifare"
                [(ngModel)]="reqTypeForm.taxifare">
              <label class="form-check-label" for="taxifare">
                Taxi Fare
              </label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="reqTypeForm.typeid == leaveid && !attendanceView">
          <div class="mb-3">
            <label class="form-label" for="policy">Leave Policy</label>
            <select class="form-select" name="policy" id="policy" [(ngModel)]="reqTypeForm.policy"
              [disabled]="gettingPolicyList">
              <option value="" [selected]="reqTypeForm.policy == ''">-</option>
              <option *ngFor="let plc of policyList" [value]="plc.policyid"
                [selected]="reqTypeForm.policy == plc.policyid">
                {{plc.name}}
              </option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingPolicyList"></mat-progress-bar>
          </div>
        </ng-container>

        <div class="form-check" *ngIf="reqTypeForm.typeid == leaveid">
          <input class="form-check-input" type="checkbox" id="unlimited" [(ngModel)]="reqTypeForm.unlimited"
            name="unlimited">
          <label class="form-check-label mb-1 ms-1 mr-2" for="unlimited">
            Unlimited
          </label>
        </div>

      </div>

      <ng-container *ngIf="reqTypeForm.typeid == leaveid && x">
        <div class="card card-body my-card">
          <div class="row">
            <div class="col-md-6 mb-3"> <label for="serviceyear" class="form-label">Service Year (Monthly)</label>
              <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="serviceyear"
                name="serviceyear" [(ngModel)]="reqTypeForm.serviceyear" #serviceyear="ngModel" min="0">
            </div>
            <div class="col-md-6 d-flex align-items-center mb-3" style="padding-top: 1.95rem;">
              <div class="me-5 form-check"> <input class="form-check-input" type="checkbox" id="carry"
                  [(ngModel)]="reqTypeForm.carry" name="carry"> <label class="form-check-label ms-1" for="carry"> Carry
                </label> </div>
              <div class="form-check"> <input class="form-check-input" type="checkbox" id="attachfile"
                  [(ngModel)]="reqTypeForm.attachfile" name="attachfile"> <label class="form-check-label ms-1"
                  for="attachfile"> Attach File
                </label> </div>
            </div>
            <div class="col-md-6 mb-3" *ngIf="reqTypeForm.carry">
              <label for="carryyear" class="form-label">Carry
                Year</label>
              <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="carryyear"
                name="carryyear" [(ngModel)]="reqTypeForm.carryyear" #carryyear="ngModel" min="0">
            </div>
            <div class="col-md-6 mb-3">
              <label for="maxleavepermonth" class="form-label">Max Leave Taken Per
                Month</label>
              <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="maxleavepermonth"
                name="maxleavepermonth" [(ngModel)]="reqTypeForm.maxleavepermonth" #maxleavepermonth="ngModel" min="0">
            </div>
            <div class="col-md-6 mb-3"> <label for="maxleaveperday" class="form-label">Max Leave Taken Day At
                Once</label> <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                id="maxleaveperday" name="maxleaveperday" [(ngModel)]="reqTypeForm.maxleaveperday"
                #maxleaveperday="ngModel" min="0"> </div>
            <div class="col-md-6 mb-3">
              <label for="minleaveperday" class="form-label">Min Leave Taken Day At Once</label>
              <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="minleaveperday"
                name="minleaveperday" [(ngModel)]="reqTypeForm.minleaveperday" #minleaveperday="ngModel" min="0">
            </div>
            <div class="col-md-12 mb-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="prorated" [(ngModel)]="reqTypeForm.prorated"
                  name="prorated"> <label class="form-check-label ms-1" for="prorated"> Use Prorated Calculation
                </label>
              </div>
            </div>
            <ng-container *ngIf="reqTypeForm.prorated">
              <div class="col-md-12 mb-4">
                <select class="form-select" name="proratedmethod" id="proratedmethod"
                  [(ngModel)]="reqTypeForm.proratedmethod" [disabled]="gettingProratedMethods"
                  [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && reqTypeForm.proratedmethod == ''}">
                  <option value="" [selected]="reqTypeForm.proratedmethod == ''">-</option>
                  <option *ngFor="let method of proratedMethods" [value]="method.id"
                    [selected]="reqTypeForm.proratedmethod == method.id">
                    {{method.name}}
                  </option>
                </select>
                <mat-progress-bar mode="indeterminate" *ngIf="gettingProratedMethods"></mat-progress-bar>
              </div>
            </ng-container>

            <div class="col-md-12 mb-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="roundingpolicy"
                  [(ngModel)]="reqTypeForm.roundingpolicy" name="roundingpolicy">
                <label class="form-check-label ms-1" for="roundingpolicy">
                  Use Rounding Policy
                </label>
              </div>
            </div>
            <ng-container *ngIf="reqTypeForm.roundingpolicy">
              <div class="col-md-12 mb-4">
                <select class="form-select" name="roundingpolicylist" id="roundingpolicylist"
                  [(ngModel)]="reqTypeForm.roundingpolicylist" [disabled]="gettingProratedMethods"
                  [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && reqTypeForm.roundingpolicylist == ''}">
                  <option value="" [selected]="reqTypeForm.roundingpolicylist == ''">-</option>
                  <option *ngFor="let method of roundingMethods" [value]="method.id"
                    [selected]="reqTypeForm.roundingpolicylist == method.id">
                    {{method.name}}
                  </option>
                </select>
                <mat-progress-bar mode="indeterminate" *ngIf="gettingProratedMethods"></mat-progress-bar>
              </div>
            </ng-container>
            <div class="col-md-12 mb-3">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="workingday" [(ngModel)]="reqTypeForm.workingday"
                  name="workingday"> <label class="form-check-label ms-1" for="workingday"> Working Day
                </label>
              </div>
            </div>
            <!-- Working Day -->
            <ng-container *ngIf="reqTypeForm.workingday">
              <div class="col-md-12 card card-body my-card m-3 mt-0">
                <div class="info mb-3"> Working Day
                </div>
                <div class="row">
                  <ng-container *ngFor="let month of workingDayMonthData">
                    <div class="col-md-3 col-6 mb-3">
                      <label [attr.for]="month.name" class="form-label">{{month.name}}</label>
                      <!-- [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (month.value == '' || month.value == null || monthname.errors?.max) }" -->
                      <input type="number" required (keypress)="$event.charCode != 45" class="form-control"
                        [attr.id]="month.name" [attr.name]="month.name" [(ngModel)]="month.value" min="0"
                        [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (month.value == '' || month.value == null || monthname.errors?.max) }"
                        [max]="month.max" #monthname="ngModel">
                      <div>
                        {{monthname?.errors?.max}}
                      </div>
                    </div>

                  </ng-container>
                </div>
                <div class="row mb-3">
                  <label class="form-label"> Not Counted Leave</label>
                  <ng-select class="role-select" [items]="leaveList" bindLabel="name" placeholder="Select Type"
                    appendTo="body" [multiple]="true" [(ngModel)]="reqTypeForm.workingdayleavetype">
                  </ng-select>
                  <mat-progress-bar mode="indeterminate" *ngIf="workingDayLeaveTypeLoading"></mat-progress-bar>
                </div>
                <div class="d-flex align-items-center">
                  <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox"
                      name="workingdaygazaettedholiday" id="workingdaygazaettedholiday"
                      [(ngModel)]="reqTypeForm.workingdaygazaettedholiday"> <label class="form-check-label"
                      for="workingdaygazaettedholiday"> Gazaetted Holiday
                    </label> </div>
                  <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox"
                      name="workingdaysaturday" id="workingdaysaturday" [(ngModel)]="reqTypeForm.workingdaysaturday">
                    <label class="form-check-label" for="workingdaysaturday"> Saturday
                    </label>
                  </div>
                  <div class="form-check mb-3"> <input class="form-check-input" type="checkbox" name="workingdaysunday"
                      id="workingdaysunday" [(ngModel)]="reqTypeForm.workingdaysunday">
                    <label class="form-check-label" for="workingdaysunday"> Sunday
                    </label>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 d-flex">
              <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="presubmit"
                  [(ngModel)]="reqTypeForm.presubmit" name="presubmit" (ngModelChange)="submitTypeChange(false)"
                  #presubmit="ngModel"> <label class="form-check-label ms-1" for="presubmit"> Pre Submit
                </label> </div>
              <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="postsubmit"
                  [(ngModel)]="reqTypeForm.postsubmit" name="postsubmit" (ngModelChange)="submitTypeChange(false)"
                  #postsubmit="ngModel"> <label class="form-check-label ms-1" for="postsubmit"> Post Submit
                </label> </div>
              <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="todaysubmit"
                  [(ngModel)]="reqTypeForm.todaysubmit" name="todaysubmit" (ngModelChange)="submitTypeChange(true)"
                  #todaysubmit="ngModel"> <label class="form-check-label ms-1" for="todaysubmit"> Today Submit
                </label> </div>
            </div> <ng-container *ngIf="reqTypeForm.presubmit">
              <div class="col-md-12 card card-body my-card m-3 mt-0 mt-0">
                <div class="info mb-3">Pre Submit</div>
                <div class="d-flex align-items-center">
                  <div class="form-check mb-3 me-5"> <input class="form-check-input" type="radio" name="presubmit"
                      id="presubmitfix" value="1" [(ngModel)]="reqTypeForm.presubmittype"> <label
                      class="form-check-label" for="presubmitfix"> Fix
                    </label> </div>
                  <div class="form-check mb-3"> <input class="form-check-input" type="radio" name="presubmit"
                      id="presubmitcustom" value="2" [(ngModel)]="reqTypeForm.presubmittype"> <label
                      class="form-check-label" for="presubmitcustom"> Custom
                    </label> </div>
                </div>
                <div class="row" *ngIf="reqTypeForm.presubmittype == '1'">
                  <div class="col-md-4 mb-3"> <label for="presubmitday" class="form-label">Pre Submit Day</label> <input
                      type="number" (keypress)="$event.charCode != 45" class="form-control" id="presubmitday"
                      name="presubmitday" [(ngModel)]="reqTypeForm.presubmitday" min="0"
                      [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.presubmitday == '' || reqTypeForm.presubmitday == null) }">
                  </div>
                </div>
                <div *ngIf="reqTypeForm.presubmittype == '2'"> <ng-container
                    *ngFor="let pre of preSubmitConfig; let i=index; let first=first">
                    <div class="d-flex gap-3 align-items-end">
                      <div class="mb-3 flex-fill"> <label *ngIf="first" [attr.for]="'takenday'+i"
                          class="form-label">Taken day</label> <input type="number" (keypress)="$event.charCode != 45"
                          class="form-control" [attr.id]="'takenday'+i" [attr.name]="'takenday'+i"
                          [(ngModel)]="pre.from"
                          [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (preSubmitConfig[i].from == '' || preSubmitConfig[i].from == null) }"
                          min="0"> </div>
                      <div class="mb-3 flex-fill"> <label *ngIf="first" [attr.for]="'to'+i"
                          class="form-label">To</label> <input type="number" (keypress)="$event.charCode != 45"
                          class="form-control" [attr.id]="'to'+i" [attr.name]="'to'+i" [(ngModel)]="pre.to"
                          [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (preSubmitConfig[i].to == '' || preSubmitConfig[i].to == null) }"
                          min="0"> </div>
                      <div class="mb-3 flex-fill"> <label *ngIf="first" [attr.for]="'presubmitday'+i"
                          class="form-label">Pre submit day</label> <input type="number"
                          (keypress)="$event.charCode != 45" class="form-control" [attr.id]="'presubmitday'+i"
                          [attr.name]="'presubmitday'+i" [(ngModel)]="pre.day"
                          [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (preSubmitConfig[i].day == '' || preSubmitConfig[i].day == null) }"
                          min="0"> </div>
                      <div class="mb-3"> <button class="btn btn-custom prize-btn"
                          style="width:50px !important; background-color: rgb(200, 21, 21) !important;"
                          (click)="removeSubmitConfig(i, 'pre')"> <mat-icon>remove_circle</mat-icon> </button> </div>
                    </div>
                  </ng-container>
                  <div class="row">
                    <div class="col-md-3"> <button class="btn btn-custom mb-3" (click)="addSubmitConfig('pre')">
                        <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span> </button> </div>
                  </div>
                </div>
                <div class="col-md12 d-flex">
                  <div class="form-check mb-3 me-5"> <input type="checkbox" class="form-check-input" id="pregazholiday"
                      name="pregazholiday" [(ngModel)]="reqTypeForm.pregazaettedholiday" #pregazaettedholiday="ngModel">
                    <label for="pregazholiday">Gazaetted Holiday</label>
                  </div>
                  <div class="form-check mb-3 me-5"> <input type="checkbox" class="form-check-input" id="presaturday"
                      name="presaturday" [(ngModel)]="reqTypeForm.presaturday" #presaturday="ngModel"> <label
                      for="presaturday">Saturday</label> </div>
                  <div class="form-check mb-3 me-5"> <input type="checkbox" class="form-check-input" id="presunday"
                      name="presunday" [(ngModel)]="reqTypeForm.presunday" #presunday="ngModel"> <label
                      for="presunday">Sunday</label> </div>
                </div>
              </div>
            </ng-container> <ng-container *ngIf="reqTypeForm.postsubmit">
              <div class="col-md-12 card card-body my-card m-3 mt-0 mt-0">
                <div class="info mb-3">Post Submit</div>
                <div class="d-flex align-items-center">
                  <div class="form-check mb-3 me-5"> <input class="form-check-input" type="radio" name="postsubmit"
                      id="postsubmitfix" value="1" [(ngModel)]="reqTypeForm.postsubmittype"> <label
                      class="form-check-label" for="postsubmitfix"> Fix
                    </label> </div>
                  <div class="form-check mb-3"> <input class="form-check-input" type="radio" name="postsubmit"
                      id="postsubmitcustom" value="2" [(ngModel)]="reqTypeForm.postsubmittype"> <label
                      class="form-check-label" for="postsubmitcustom"> Custom
                    </label> </div>
                </div>
                <div class="row" *ngIf="reqTypeForm.postsubmittype == '1'">
                  <div class="col-md-4 mb-3"> <label for="postsubmitday" class="form-label">Post Submit Day</label>
                    <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="postsubmitday"
                      name="postsubmitday" [(ngModel)]="reqTypeForm.postsubmitday"
                      [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.postsubmitday == '' || reqTypeForm.postsubmitday == null) }"
                      min="0">
                  </div>
                </div>
                <div *ngIf="reqTypeForm.postsubmittype == '2'"> <ng-container
                    *ngFor="let post of postSubmitConfig; let i=index; let first=first">
                    <div class="d-flex gap-3 align-items-end">
                      <div class="mb-3 flex-fill"> <label *ngIf="first" [attr.for]="'takenday'+i"
                          class="form-label text-end">Taken day</label> <input type="number"
                          (keypress)="$event.charCode != 45" class="form-control" [attr.id]="'takenday'+i"
                          [attr.name]="'takenday'+i" [(ngModel)]="post.from"
                          [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (postSubmitConfig[i].from == '' || postSubmitConfig[i].from == null) }"
                          min="0"> </div>
                      <div class="mb-3 flex-fill"> <label *ngIf="first" [attr.for]="'to'+i"
                          class="form-label">To</label> <input type="number" (keypress)="$event.charCode != 45"
                          class="form-control" [attr.id]="'to'+i" [attr.name]="'to'+i" [(ngModel)]="post.to"
                          [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (postSubmitConfig[i].to == '' || postSubmitConfig[i].to == null) }"
                          min="0"> </div>
                      <div class="mb-3 flex-fill"> <label *ngIf="first" [attr.for]="'postsubmitday'+i"
                          class="form-label">Post submit day</label> <input type="number"
                          (keypress)="$event.charCode != 45" class="form-control" [attr.id]="'postsubmitday'+i"
                          [attr.name]="'postsubmitday'+i" [(ngModel)]="post.day"
                          [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (postSubmitConfig[i].day == '' || postSubmitConfig[i].day == null) }"
                          min="0"> </div>
                      <div class="mb-3"> <button class="btn btn-custom prize-btn"
                          style="width:50px !important; background-color: rgb(200, 21, 21) !important;"
                          (click)="removeSubmitConfig(i, 'post')"> <mat-icon>remove_circle</mat-icon> </button> </div>
                    </div>
                  </ng-container>
                  <div class="row">
                    <div class="col-md-3"> <button class="btn btn-custom mb-3" (click)="addSubmitConfig('post')">
                        <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span> </button> </div>
                  </div>
                </div>
                <div class="col-md12 d-flex">
                  <div class="form-check mb-3 me-5"> <input type="checkbox" class="form-check-input" id="postgazholiday"
                      name="postgazholiday" [(ngModel)]="reqTypeForm.postgazaettedholiday"
                      #postgazaettedholiday="ngModel"> <label for="postgazholiday">Gazaetted Holiday</label> </div>
                  <div class="form-check mb-3 me-5"> <input type="checkbox" class="form-check-input" id="postsaturday"
                      name="postsaturday" [(ngModel)]="reqTypeForm.postsaturday" #postsaturday="ngModel"> <label
                      for="postsaturday">Saturday</label> </div>
                  <div class="form-check mb-3 me-5"> <input type="checkbox" class="form-check-input" id="postsunday"
                      name="postsunday" [(ngModel)]="reqTypeForm.postsunday" #postsunday="ngModel"> <label
                      for="postsunday">Sunday</label> </div>
                </div>
              </div>
            </ng-container>
            <!-- <ng-container *ngIf="reqTypeForm.presubmit">              <div class="col-md-12 card card-body my-card m-3 mt-0 mt-0">                <div class="info mb-3">Pre Submit</div>                <div class="d-flex align-items-center">                  <div class="form-check mb-3 me-5">                    <input class="form-check-input" type="radio" name="presubmit" id="presubmitfix" value="1"
              [(ngModel)]="reqTypeForm.presubmittype">                    <label class="form-check-label" for="presubmitfix">                      Fix
            </label>                  </div>                  <div class="form-check mb-3">                    <input class="form-check-input" type="radio" name="presubmit" id="presubmitcustom" value="2"
              [(ngModel)]="reqTypeForm.presubmittype">                    <label class="form-check-label" for="presubmitcustom">                      Custom
            </label>                  </div>                </div>                <div class="row" *ngIf="reqTypeForm.presubmittype == '1'">                  <div class="col-md-4 mb-3">                    <label for="presubmitday" class="form-label">Pre Submit Day</label>                    <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="presubmitday" name="presubmitday"
              [(ngModel)]="reqTypeForm.presubmitday" min="0">                  </div>                </div>              </div>            </ng-container>            <ng-container *ngIf="reqTypeForm.postsubmit">              <div class="col-md-12 card card-body my-card m-3 mt-0 mt-0">                <div class="info mb-3">Post Submit</div>                <div class="d-flex align-items-center">                  <div class="form-check mb-3 me-5">                    <input class="form-check-input" type="radio" name="postsubmit" id="postsubmitfix" value="1"
              [(ngModel)]="reqTypeForm.postsubmittype">                    <label class="form-check-label" for="postsubmit">                      Fix
            </label>                  </div>                  <div class="form-check mb-3">                    <input class="form-check-input" type="radio" name="postsubmit" id="postsubmitcustom" value="2"
              [(ngModel)]="reqTypeForm.postsubmittype">                    <label class="form-check-label" for="postsubmitcustom">                      Custom
            </label>                  </div>                </div>                <div class="row" *ngIf="reqTypeForm.postsubmittype == '1'">                  <div class="col-md-4 mb-3">                    <label for="postsubmitday" class="form-label">Post Submit Day</label>                    <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="postsubmitday" name="postsubmitday"
              [(ngModel)]="reqTypeForm.postsubmitday" min="0">                  </div>                </div>              </div>            </ng-container> -->
            <div class="col-md-12 d-flex">
              <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="pairleave"
                  [(ngModel)]="reqTypeForm.pairleave" name="pairleave"> <label class="form-check-label ms-1"
                  for="pairleave"> Pair Leave
                </label> </div>
            </div> <ng-container *ngIf="reqTypeForm.pairleave">
              <div class="col-md-12 card card-body my-card m-3 mt-0 mt-0">
                <div class="info mb-3">Pair Leave</div>
                <div class="d-flex align-items-center">
                  <div class="form-check mb-3 me-5"> <input class="form-check-input" type="radio" name="pairleavetype"
                      id="pairleavetypeall" value="1" [(ngModel)]="reqTypeForm.pairleavetype"> <label
                      class="form-check-label" for="pairleavetypeall"> All
                    </label> </div>
                  <div class="form-check mb-3"> <input class="form-check-input" type="radio" name="pairleavetype"
                      id="pairleavetypecustom" value="2" [(ngModel)]="reqTypeForm.pairleavetype"> <label
                      class="form-check-label" for="pairleavetypecustom"> Custom
                    </label> </div>
                </div>
                <div class="row" *ngIf="reqTypeForm.pairleavetype == '2'">
                  <div class="col-md-12"> <ng-select class="role-select" [items]="leaveList" bindLabel="name"
                      placeholder="Select Type" appendTo="body" [multiple]="true"
                      [(ngModel)]="reqTypeForm.pairleavecustomtype"
                      [ngClass]="{ 'role-select-invalid': reqTypeFormSubmitted && (reqTypeForm.pairleavecustomtype.length == 0 || reqTypeForm.pairleavecustomtype == null) }">
                    </ng-select> <mat-progress-bar mode="indeterminate"
                      *ngIf="workingDayLeaveTypeLoading"></mat-progress-bar> </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 card card-body my-card m-3 mt-0 mt-0">
              <div class="info mb-3">Leave Counting</div>
              <div class="d-flex align-items-center">
                <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox"
                    name="leavecountingpreoff" id="leavecountingpreoff" [(ngModel)]="reqTypeForm.leavecountingpreoff">
                  <label class="form-check-label" for="leavecountingpreoff"> Pre Off
                  </label>
                </div>
                <div class="form-check mb-3"> <input class="form-check-input" type="checkbox"
                    name="leavecountingpostoff" id="leavecountingpostoff"
                    [(ngModel)]="reqTypeForm.leavecountingpostoff"> <label class="form-check-label"
                    for="leavecountingpostoff"> Post Off
                  </label> </div>
              </div>
              <div> <label class="form-label">Leave Duration</label>
                <div class="d-flex align-items-center">
                  <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox"
                      name="leavedurationgazaettedholiday" id="leavedurationgazaettedholiday"
                      [(ngModel)]="reqTypeForm.leavedurationgazaettedholiday"> <label class="form-check-label"
                      for="leavedurationgazaettedholiday"> Gazaetted Holiday
                    </label> </div>
                  <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox"
                      name="leavedurationsaturday" id="leavedurationsaturday"
                      [(ngModel)]="reqTypeForm.leavedurationsaturday"> <label class="form-check-label"
                      for="leavedurationsaturday"> Saturday
                    </label> </div>
                  <div class="form-check mb-3"> <input class="form-check-input" type="checkbox"
                      name="leavedurationsunday" id="leavedurationsunday" [(ngModel)]="reqTypeForm.leavedurationsunday">
                    <label class="form-check-label" for="leavedurationsunday"> Sunday
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 d-flex">
              <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox"
                  id="decreaseserviceyear" [(ngModel)]="reqTypeForm.decreaseserviceyear" name="decreaseserviceyear">
                <label class="form-check-label ms-1" for="decreaseserviceyear"> Decrease Service Year
                </label>
              </div>
            </div> <ng-container *ngIf="reqTypeForm.decreaseserviceyear">
              <div class="col-md-12 card card-body my-card m-3 mt-0 mt-0">
                <div class="info mb-3">Decrease Service Year</div>
                <div class="row">
                  <div class="col-md-6 mb-3"> <label for="leavecount" class="form-label">Leave Count</label> <input
                      type="number" (keypress)="$event.charCode != 45" class="form-control" id="leavecount"
                      name="leavecount" [(ngModel)]="reqTypeForm.leavecount"
                      [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.leavecount == '' || reqTypeForm.leavecount == null) }"
                      min="0"> </div>
                  <div class="col-md-6 mb-3"> <label for="serviceyearday" class="form-label">Service Year (Day)</label>
                    <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="serviceyearday"
                      name="serviceyearday" [(ngModel)]="reqTypeForm.serviceyearday"
                      [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.serviceyearday == '' || reqTypeForm.serviceyearday == null) }"
                      min="0">
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 d-flex">
              <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="limitationday"
                  [(ngModel)]="reqTypeForm.limitationday" name="limitationday"> <label class="form-check-label ms-1"
                  for="limitationday"> Limitations Day
                </label> </div>
            </div> <ng-container *ngIf="reqTypeForm.limitationday">
              <div class="col-md-12 card card-body my-card m-3 mt-0 mt-0">
                <div class="info mb-3">Limitations Day</div>
                <div class="d-flex align-items-center">
                  <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox"
                      name="limitationdaypreoff" id="limitationdaypreoff" [(ngModel)]="reqTypeForm.limitationdaypreoff">
                    <label class="form-check-label" for="limitationdaypreoff"> Pre Off
                    </label>
                  </div>
                  <div class="form-check mb-3"> <input class="form-check-input" type="checkbox"
                      name="limitationdaypostoff" id="limitationdaypostoff"
                      [(ngModel)]="reqTypeForm.limitationdaypostoff"> <label class="form-check-label"
                      for="limitationdaypostoff"> Post Off
                    </label> </div>
                </div>
                <div class="row">
                  <div class="d-flex flex-wrap align-items-center"> <ng-container
                      *ngFor="let day of weekDay;let i = index; let first = first">
                      <div class="form-check mb-3 me-5" [ngClass]="{'col-md-12' : first}">
                        <input class="form-check-input" type="checkbox" [attr.name]="day.name" [attr.id]="day.name"
                          (change)="addLimitations($event,day.value)"
                          [checked]="reqTypeForm.limitationdayweekday?.includes(day.value)">
                        <label class="form-check-label" [attr.for]="day.name"> {{day.name}}</label>
                      </div>
                    </ng-container> </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 d-flex">
              <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="maternity"
                  [(ngModel)]="reqTypeForm.maternity" name="maternity"> <label class="form-check-label ms-1"
                  for="maternity"> Maternity
                </label> </div>
            </div> <ng-container *ngIf="reqTypeForm.maternity">
              <div class="col-md-12 card card-body my-card m-3 mt-0 mt-0">
                <div class="info mb-3">Maternity</div>
                <div class="row">
                  <div class="col-md-6 mb-3"> <label for="maternitybeforeweek" class="form-label">Before Week</label>
                    <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                      id="maternitybeforeweek" name="maternitybeforeweek" [(ngModel)]="reqTypeForm.maternitybeforeweek"
                      [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.maternitybeforeweek == '' || reqTypeForm.maternitybeforeweek == null) }"
                      min="0">
                  </div>
                  <div class="col-md-6 mb-3"> <label for="maternityafterweek" class="form-label">After Week</label>
                    <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="maternityafterweek"
                      name="maternityafterweek" [(ngModel)]="reqTypeForm.maternityafterweek"
                      [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.maternityafterweek == '' || reqTypeForm.maternityafterweek == null) }"
                      min="0">
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 d-flex">
              <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="paternity"
                  [(ngModel)]="reqTypeForm.paternity" name="paternity"> <label class="form-check-label ms-1"
                  for="paternity"> Paternity
                </label> </div>
            </div> <ng-container *ngIf="reqTypeForm.paternity">
              <div class="col-md-12 card card-body my-card m-3 mt-0 mt-0">
                <div class="info mb-3">Paternity</div>
                <div class="row">
                  <div class="col-md-6 mb-3"> <label for="paternitybeforeweek" class="form-label">Before Week</label>
                    <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                      id="paternitybeforeweek" name="paternitybeforeweek" [(ngModel)]="reqTypeForm.paternitybeforeweek"
                      [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.paternitybeforeweek == '' || reqTypeForm.paternitybeforeweek == null) }"
                      min="0">
                  </div>
                  <div class="col-md-6 mb-3"> <label for="paternityafterweek" class="form-label">After Week</label>
                    <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="paternityafterweek"
                      name="paternityafterweek" [(ngModel)]="reqTypeForm.paternityafterweek"
                      [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.paternityafterweek == '' || reqTypeForm.paternityafterweek == null) }"
                      min="0">
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 d-flex align-items-center">
              <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="compassionate"
                  [(ngModel)]="reqTypeForm.compassionate" name="compassionate"> <label class="form-check-label ms-1"
                  for="compassionate"> Compassionate
                </label> </div>
              <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="encashmenton"
                  [(ngModel)]="reqTypeForm.encashmenton" name="encashmenton"> <label class="form-check-label ms-1"
                  for="encashmenton"> Encashment On
                </label> </div>
            </div> <ng-container *ngIf="reqTypeForm.compassionate">
              <div class="col-md-12"> <label class="form-label" for="remark"> Remark
                </label> <textarea class="form-control" name="remark" id="remark" [(ngModel)]="reqTypeForm.remark"
                  [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.remark == '' || reqTypeForm.remark == null) }"
                  rows="4">                </textarea> </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!-- </form> -->
    </div>

    <div *ngIf="!isviewornew">

      <div *ngIf="!requestTypeListLoading" class="row">
        <div class="col-12 col-md-6" *ngFor="let rt of reqtypeList; let rtindex = index">

          <div class="mb-3 accordion" id="accordionContainer" *ngIf="rt.subtype">
            <div class="accordion-item">
              <h3 class="accordion-header" [id]="'heading' + rtindex">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#collapse' + rtindex" aria-expanded="true"
                  [attr.aria-controls]="'collapse' + rtindex">
                  <div class="d-flex align-items-center justify-content-between">
                    <div>
                      Type:&nbsp;{{rt.requesttypename}}
                    </div>
                    <div *ngIf="rt.requesttypeid == leaveid && !attendanceView" class="cc-label" (click)="policyForm()">
                      Policies
                    </div>
                  </div>

                  <div> Count:&nbsp;{{rt.child.length}}</div>
                </button>

              </h3>
              <div [id]="'collapse' + rtindex" class="accordion-collapse collapse"
                [attr.aria-labelledby]="'heading' + rtindex">
                <div class="accordion-body">
                  <div class="mb-3" *ngFor="let rtc of rt.child; let rtcindex = index;">
                    <div class="d-flex p-2 justify-content-between" style="background-color: rgb(244 244 244);">
                      <div class="d-flex flex-column justify-content-center flex-fill td-edit">
                        <div class="grid-container">
                          <div (click)="editReqType(rt.requesttypeid,rtc)" class="mb-1 req-title">
                            {{rtc.requesttypename}}
                          </div>
                          <div class="action-icons">
                            <i (click)="editReqType(rt.requesttypeid,rtc)" class='fa fa-edit'></i>
                            &nbsp;&nbsp;&nbsp;
                            <i (click)="delReqType(rtc.requesttypeid)" class='fa fa-trash-o'></i>
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3 accordion" id="accordionContainer" *ngIf="!rt.subtype">
            <div class="accordion-item">
              <h3 [id]="'heading' + rtindex">
                <button class="accordion-button" type="button" (click)="editParentReqType(rt.requesttypeid, rtindex)"
                  style="min-height: 84px;">
                  Type:&nbsp;{{rt.requesttypename}}
                  <!-- <span class="float-end">
                    <i class="fas fa-chevron-right"></i>
                  </span> -->
                  <span class="float-end" *ngIf="rt.requesttypeid != wfhid">
                    <i class="fas fa-chevron-right"></i>
                  </span>
                  <div *ngIf="rt.requesttypeid == wfhid && !attendanceView" class="cc-label float-end"
                    (click)="policyForm(true, rt.policy, rt.requesttypeid)">
                    Policy
                  </div>
                </button>
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="requestTypeListLoading" class="row">
        <div class="col-6" *ngFor="let i of [1,2]">
          <ng-container>
            <div *ngFor="let i of [1,2,3]" class="d-flex align-items-center flex-row">
              <div class="me-3">
                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
              'width.px' : 80, 
              'height.px': 80}"></ngx-skeleton-loader>
              </div>
              <div class="d-flex flex-column">
                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
              'width.px' : 150, 
              'height.px': 10}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                'width.px' : 100, 
                'height.px': 10}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                  'width.px' : 80, 
                  'height.px': 10}"></ngx-skeleton-loader>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="!requestTypeListLoading && reqtypeList.length == 0"
        class="d-flex align-items-center justify-content-center" style="height: 200px; width: 100%;">
        <div>
          No request type.
        </div>
      </div>
    </div>


  </div>