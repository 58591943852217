<div class="container-fluid">
    <div class="content">
        <app-hcm-breadcrumb s_breadcrumb="Pay Peroid" [t_breadcrumb]="editData ? ' Edit' : 'New'"
            (emit)="closeEdit()"></app-hcm-breadcrumb>
        <div class="card card-body my-card p-4">
            <div class="d-flex">
                <button class="btn btn-custom me-3" (click)="closeEdit()">
                    Cancel
                </button>
                <button class="btn btn-custom" (click)="handleSave()">
                    <span *ngIf="saveLoading" class="spinner-border spinner-border-sm button-spinner me-2" role="status"
                        aria-hidden="true"></span>
                    Save
                </button>
            </div>

            <div class="row mb-3 mt-4">
                <div class="col">
                    <label class="form-label">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="periodForm.description" #desc="ngModel"
                        required [ngClass]="{ 'is-invalid': submitted && desc.errors }" />
                    <div class="invalid-feedback" *ngIf="desc.invalid || ((desc.dirty || desc.touched) && submitted)">
                        This field may not be blank
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md mb-3">
                    <label class="form-label">Year</label>
                    <input type="text" class="form-control datepicker-readonly" bsDatepicker [minMode]="'year'" readonly
                        #year="ngModel" required [(ngModel)]="periodForm.year" style="min-width: 200px"
                        [bsConfig]="{ dateInputFormat: 'YYYY' }"
                        [ngClass]="{ 'is-invalid': submitted && year.errors }" />
                    <div class="invalid-feedback" *ngIf="year.invalid || ((year.dirty || year.touched) && submitted)">
                        This field may not be blank
                    </div>
                </div>
                <div class="col-md">
                    <label class="form-label">Month</label>
                    <select class="form-select" [(ngModel)]="periodForm.month">
                        <option *ngFor="let month of months">{{ month }}</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="year.invalid || ((year.dirty || year.touched) && submitted)">
                        This field may not be blank
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md mb-3">
                    <label class="form-label">Start Date</label>
                    <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
                        [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="periodForm.startdate"
                        style="min-width: 200px" #start="ngModel" required
                        [ngClass]="{'is-invalid' : submitted && start.invalid}" />
                    <div class="invalid-feedback"
                        *ngIf="start.invalid || ((start.dirty || start.touched) && submitted)">
                        This field may not be blank
                    </div>
                </div>
                <div class="col-md">
                    <label class="form-label">End Date</label>
                    <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
                        [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="periodForm.enddate"
                        style="min-width: 200px" #end="ngModel" [ngClass]="{'is-invalid': submitted && end.invalid}"
                        required />
                    <div class="invalid-feedback" *ngIf="end.invalid || ((end.dirty || end.touched) && submitted)">
                        This field may not be blank
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>