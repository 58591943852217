<!-- <div class="container-fluid" style="display: flex; justify-content: center;">
  <div class="content" style="max-width: 580px; min-width: 580px;" *ngIf="memberinfo">
    <div class="card">
      <div class="card-body">
        <h3 class="text-center">Payment</h3>
        <div class="mt-2">
          <table>
            <tr>
              <th class="text-muted" style="padding-right: 130px; font-size: 13px;" scope="row">Name</th>
              <td style="font-size: 13px;">{{ memberinfo?.name }} </td>
            </tr>
            <tr>
              <th class=" text-muted pe-8" scope="row" style="padding-right: 130px; font-size: 13px;">Phone</th>
              <td style="font-size: 13px;">{{ memberinfo?.userid }} </td>
            </tr>
            <tr>
              <th class=" text-muted pe-8" scope="row" style="padding-right: 130px; font-size: 13px;">Member Type</th>
              <td style="font-size: 13px;">{{ memberinfo?.memberType.name }} </td>
            </tr>
          </table>

        </div>

        <div class="row mt-4">
          <div *ngFor="let fee of fees">
            <span style="font-weight: bold; font-size: 15px;">{{ fee.description }}</span>
            <ul class="row  mt-2">
              <table>
                <tr *ngFor="let detail of fee.monthly_detail">
                  <th style="width: 26px;">&bull;</th>
                  <th class=" text-muted" style="font-size: 13px; min-width: 300px; max-width: 300px;" scope="row">{{
                    detail.feeType.description }}</th>
                  <td style="font-size: 13px;"> {{ detail.amount | number }} </td>
                </tr>
              </table>
            </ul>
          </div>
        </div>
        <hr />
        <div class="row mt-4">
          <div>
            <ul class="row  mt-2">
              <table>
                <tr>
                  <th style="width: 26px;"></th>
                  <th class=" text-muted" style="font-size: 13px; min-width: 300px; max-width: 300px;" scope="row">Total
                    Fees</th>
                  <td style="font-size: 13px;"> {{ totalAmount | number }} </td>
                </tr>
              </table>
            </ul>
          </div>
        </div>

      </div>
    </div>
    <div class="mt-4 d-flex justify-content-end">
      <button class="btn btn-custom ">Pay</button>
    </div>
  </div>
  <div *ngIf="!memberinfo" class="not-found">
    <span class=" text-muted">User Not Found</span>
    <div class="">
      <div class="line"></div>
    </div>
    <button class="btn btn-custom">Register</button>
  </div>
</div> -->


<div class="container-fluid">
  <div class="content" *ngIf="!isOpenForm && !isHistoryForm && !isOpenRegisterForm">
    <div class="me-auto">
      <!-- <app-kmember-breadcrumb s_breadcrumb="Payment"></app-kmember-breadcrumb> -->
      <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    </div>

    <div class="d-flex">
      <div class="input-group mb-2 me-2" style="width: 300px !important">
        <input class="form-control" type="text" placeholder="&#xf002; Search with Name" aria-label="Search"
          style="font-family: FontAwesome, Ubuntu" [(ngModel)]="searchName" (keyup.enter)="getMembers()">
        <button class="btn btn-custom me-2" matTooltip="Refresh" (click)="getMembers()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="align-items-center ms-2" [matMenuTriggerFor]="statusmenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Status&nbsp;:&nbsp; {{ selectePaystatusName }}
          <mat-menu #statusmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let status of payStatus" (click)="handlePaySelected(status)">
              {{ status.name }}
            </button>
          </mat-menu>
        </button>
      </div>
      <!-- <button class="btn btn-custom mb-2" (click)="newMember()">New</button> -->
    </div>
    <table class="table table-responsive table-borderless">
      <thead class="table-header">
        <tr>
          <th>အမည်</th>
          <th>မှတ်ပုံတင်အမှတ်</th>
          <th>ကဒ်အမျိုးအစား</th>
          <th>မွေးနေ့</th>
          <th>နေရပ်လိပ်စာ</th>
          <th>ဖုန်းနံပါတ်</th>
          <th>Status</th>
          <th>Payment Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody *ngIf="!gettingMemberList && memberList.length>0">
        <tr *ngFor="let member of memberList " class="py-4" style="cursor: pointer;">
          <td (click)="editMember(member)">
            {{member.name}}
          </td>
          <td (click)="editMember(member)">
            {{member.nrc}}
          </td>
          <td (click)="editMember(member)">
            {{member.memberType?.name}}
          </td>
          <td (click)="editMember(member)">
            {{allInOneService.formatDBToShow(member.birthdate)}}
          </td>
          <td (click)="editMember(member)">
            {{member.current_address}}
          </td>
          <td (click)="editMember(member)">
            {{member.phone}}
          </td>
          <td class="td-edit td-data-2">
            <span class="status" [ngClass]="{
                          'status-green': member.formstatus == '002',
                          'status-red': member.formstatus == '003',
                          'status-gray': member.formstatus == '001',
                          'status-main': member.formstatus == '004'
                      }">
              {{member.formstatus=="001" ? "Submitted" : ( member.formstatus=="003" ? "Rejected":
              "Approved" )}}
            </span>

          </td>
          <td class="td-edit td-data-2">
            <span class="status" [ngClass]="{
                          'status-green': member.paymentstatus == true,
                          'status-red': member.paymentstatus == false
                      }">
              {{member.paymentstatus==true? "Paid": "Unpaid"}}
            </span>
          </td>
          <td class="justify-contents-end" *ngIf="member.paymentstatus && member.memberType?.name=='A Member'">
            <button class="btn btn-outline-primary me-2" (click)="openHistory(member)">
              <i class="fa fa-history"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!gettingMemberList && memberList.length == 0">
        <tr>
          <td colspan="9" class="nodata">
            Empty
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="gettingMemberList">
        <tr>
          <td colspan="9" class="nodata">
            <div class="spinner-border" entitle="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="content" *ngIf="isOpenRegisterForm">
    <app-member-card-registor (closeFormHandler)="closeForm()"
      [currentMember]="currentMember"></app-member-card-registor>
  </div>
  <div class="content" *ngIf="isOpenForm && !isHistoryForm">
    <app-member-paymentform (closeForm)="closeForm()" [currentMember]="currentMember"></app-member-paymentform>
  </div>
  <div class="content" *ngIf="isHistoryForm">
    <app-member-payment-history (closeForm)="closeForm()" [currentMember]="currentMember"></app-member-payment-history>
  </div>
</div>