import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { min } from 'moment';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-incomplete-report',
  templateUrl: './incomplete-report.component.html',
  styleUrls: ['./incomplete-report.component.scss'],
})
export class IncompleteReportComponent implements OnInit, OnDestroy {
  mobileViewWidth: any = 426;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  itemPerPage: number = 100;
  currentPage: number = 1;
  searchText: string = '';
  searchLoading: boolean = false;
  orgid: string = '';
  getList!: Subscription;

  incompleteReportList: any = [];
  organizations: any = [];

  gettingMemberPosition: boolean = false;
  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];
  monthlist: any[] = [];
  openfilter: boolean = false;

  // websockect
  connectSocketLoading: boolean = true;
  private socket: WebSocket | undefined;
  public socketConnectionStatus: string = 'disconnected';
  public socketReceivedMessage: string = '';
  connectionId: string = '';
  drsocketfileurl: any = '';
  updateCId: boolean = false;
  socketConnectTime: any;

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  formatToShow = 'yyyy-MM-dd';
  dateRangeStartDate: any = '';
  minDate = new Date();
  date = new Date();
  firstDay = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  lastDay = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);

  currentStartDate: any = this.datePipe.transform(this.firstDay, 'yyyy-MM-dd');
  currentEndDate: any = this.datePipe.transform(this.lastDay, 'yyyy-MM-dd');
  getMemberSub!: Subscription;

  userStatusFilterList = this.allinoneService.userStatusFilterList;

  submitForm = {
    startdate: this.firstDay,
    enddate: this.lastDay,
    department: 'All',
    division: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
    status: '001'
  };

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  subscriptions = new SubSink();

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe,
    private datePipe: DatePipe,
    private http: HttpClient
  ) {
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
  }

  ngOnInit(): void {
    var addDays = 35;
    this.dateRangeStartDate = this.currentStartDate;
    var maxEndDate = new Date(this.dateRangeStartDate);
    maxEndDate.setTime(maxEndDate.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(
    //   maxEndDate,
    //   this.formatToShow,
    //   'en-US'
    // ).toString();
    this.minDate = maxEndDate;
    // this.monthlist = this.getCurrentAndPreviousYearMonths();
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
  }

  ngOnDestroy(): void {
    this.socket?.close(3001);
    this.getList && this.getList.unsubscribe();
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.subscriptions.unsubscribe();
    clearTimeout(this.socketConnectTime);
  }

  search() {
    this.connectWebSocket();
    this.closeSocketWithTime();
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '001',
    };
    this.kunyekService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      this.updateCId = false;
      console.log(res);
    });
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.searchLoading = true;
    this.connectSocketLoading = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      // this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);

      console.log('Changed data');
      console.log(tempData);

      if (tempData.connectionId) {
        this.connectionId = tempData.connectionId;

        if (this.updateCId) {
          this.updateConnectionID();
        }
        this.getIncompleteReport();
      } else {
        this.drsocketfileurl = tempData.fileurl;

        if (this.drsocketfileurl) {
          this.http.get(this.drsocketfileurl).subscribe((res: any) => {
            console.log('Your get data res');
            console.log(res);
            this.incompleteReportList = res;
            this.searchLoading = false;
            this.connectionId = '';
            this.socket?.close(3001);
            clearTimeout(this.socketConnectTime);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionId);
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });
  }

  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(() => {
      if (this.socketConnectionStatus != 'disconnected') {
        this.socket?.close(3001);
        this.connectionId = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoading = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  getIncompleteReport() {
    if (!this.submitForm.startdate) {
      return this.messageService.openSnackBar(
        'Start Date cannot be blank.',
        'warn'
      );
    }
    if (!this.submitForm.enddate) {
      return this.messageService.openSnackBar(
        'End Date cannot be blank.',
        'warn'
      );
    }
    this.searchLoading = true;
    var data = {
      startdate: this.allinoneService.parseDate(this.submitForm.startdate),
      enddate: this.allinoneService.parseDate(this.submitForm.enddate),
      orgid: this.orgid,
      department:
        !this.openfilter || this.submitForm.department == 'All'
          ? ''
          : this.submitForm.department,
      division:
        !this.openfilter || this.submitForm.division == 'All'
          ? ''
          : this.submitForm.division,
      teamid:
        !this.openfilter || this.submitForm.teamid == 'All'
          ? ''
          : this.submitForm.teamid,
      costcenter:
        !this.openfilter || this.submitForm.costcenter == 'All'
          ? ''
          : this.submitForm.costcenter,
      subdivision:
        !this.openfilter || this.submitForm.subdivision == 'All'
          ? ''
          : this.submitForm.subdivision,
          activestatus: this.openfilter ? this.submitForm.status : '001',
      connectionid: this.connectionId,
    };
    this.getList && this.getList.unsubscribe();
    this.getList = this.kunyekService.getIncompleteReport(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.incompleteReportList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.searchLoading = false;
        }
      },
      (err: any) => {
        this.searchLoading = false;
      }
    );
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.subscriptions.sink = this.kunyekService
      .getMemberPosition(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            // this.departmentList = res.departmentlist;
            // this.divisionList = res.divisionlist;
            // this.teamidList = res.teamidlist;
            for (var i = 0; i < res.departmentlist.length; i++) {
              this.departmentList.push(res.departmentlist[i]);
            }
            for (var i = 0; i < res.divisionlist.length; i++) {
              this.divisionList.push(res.divisionlist[i]);
            }
            for (var i = 0; i < res.teamidlist.length; i++) {
              this.teamidList.push(res.teamidlist[i]);
            }
            for (var i = 0; i < res.costcenterlist.length; i++) {
              this.costCenterList.push(res.costcenterlist[i]);
            }
            for (var i = 0; i < res.subdivision.length; i++) {
              this.subDivisionList.push(res.subdivision[i]);
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingMemberPosition = false;
        },
        (err) => {
          this.gettingMemberPosition = false;
        }
      );
  }

  dateChange() {
    var addDays = 35;
    this.dateRangeStartDate = this.submitForm.startdate;
    var maxEndDate = new Date(this.dateRangeStartDate);
    maxEndDate.setTime(maxEndDate.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(
    //   maxEndDate,
    //   this.formatToShow,
    //   'en-US'
    // ).toString();
    this.minDate = maxEndDate;

    if (this.submitForm.enddate > this.minDate) {
      this.submitForm.enddate = this.minDate;
    }

    if (this.submitForm.enddate < this.submitForm.startdate) {
      this.submitForm.enddate = this.submitForm.startdate;
    }
  }

  toggleFilter() {
    if (
      this.departmentList.length < 2
      //  ||
      // this.divisionList.length < 2 ||
      // this.teamidList.length < 2 ||
      // this.costCenterList.length < 2 ||
      // this.subDivisionList.length < 2
    ) {
      this.getMemberPosition();
    }
    this.openfilter = !this.openfilter;
  }

  departmentChange(dep: any) {
    if (this.submitForm.department != dep) {
      this.submitForm.department = dep;
      this.submitForm.division = 'All';
      this.submitForm.teamid = 'All';
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  divisionChange(divi: any) {
    if (this.submitForm.division != divi) {
      this.submitForm.division = divi;
      this.submitForm.teamid = 'All';
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  teamidChange(teamid: any) {
    if (this.submitForm.teamid != teamid) {
      this.submitForm.teamid = teamid;
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  costCenterChange(costcenter: any) {
    if (this.submitForm.costcenter != costcenter) {
      this.submitForm.costcenter = costcenter;
      this.submitForm.subdivision = 'All';
    }
  }

  subDivisionChange(subdivision: any) {
    if (this.submitForm.subdivision != subdivision) {
      this.submitForm.subdivision = subdivision;
    }
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  clear() {
    this.searchText = '';
  }

  sortData(sort: Sort) {
    const data = this.incompleteReportList;
    if (!sort.active || sort.direction === '') {
      this.incompleteReportList = data;
      return;
    }
    this.incompleteReportList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'userid') {
        return this.allinoneService.compare(a.userid, b.userid, isAsc);
      } else if (sort.active == 'employeeid') {
        return this.allinoneService.compare(a.employeeid, b.employeeid, isAsc);
      } else if (sort.active == 'username') {
        return this.allinoneService.compare(a.username, b.username, isAsc);
      } else if (sort.active == 'department') {
        return this.allinoneService.compare(a.department, b.department, isAsc);
      } else if (sort.active == 'division') {
        return this.allinoneService.compare(a.division, b.division, isAsc);
      } else if (sort.active == 'teamid') {
        return this.allinoneService.compare(a.teamid, b.teamid, isAsc);
      } else if (sort.active == 'shift') {
        return this.allinoneService.compare(a.shiftname, b.shiftname, isAsc);
      } else if (sort.active == 'starttime') {
        return this.allinoneService.compare(a.starttime, b.starttime, isAsc);
      } else if (sort.active == 'endtime') {
        return this.allinoneService.compare(a.endtime, b.endtime, isAsc);
      } else if (sort.active == 'date') {
        return this.allinoneService.compare(a.date, b.date, isAsc);
      } else if (sort.active == 'timein') {
        return this.allinoneService.compare(a.timein, b.timein, isAsc);
      }

      return 0;
    });
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  downloadSheet() {
    var exdata: any = [];
    const startdate = this.allinoneService.formatDBToShow(
      this.allinoneService.parseDate(this.submitForm.startdate)
    );
    const enddate = this.allinoneService.formatDBToShow(
      this.allinoneService.parseDate(this.submitForm.enddate)
    );
    var name = 'Incomplete Report (' + startdate + ' - ' + enddate + ').xlsx';
    var filteredData = this.filterPipe.transform(
      this.incompleteReportList,
      this.searchText,
      'absentreport'
    );

    filteredData.map((item: any) => {
      var temp = {
        'Employee ID': item.employeeid,
        'User ID': item.userid,
        Name: item.username,
        Department: item.department,
        Division: item.division,
        'Team ID': item.teamid,
        'Cost Center': item.costcenter,
        Shift: item.shiftname,
        'WP Time In': item.starttime,
        'WP Time Out': item.endtime,
        Date: this.allinoneService.formatDBToShow(item.date),
        'Time In': this.allinoneService.formatTimeToShow(item.timein),
        // 'Total Incomplete Days': item.incompletecount,
        // 'Absent Date': item.strdate,
      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }

  viewDetails(data: any) {
    // const dialog = this.dialog.open(AbsentreportDetailsComponent, {
    //   maxWidth: '95vw',
    //   width: '800px',
    //   maxHeight: '95vh',
    //   panelClass: ['mat-typography'],
    //   closeOnNavigation: true,
    //   autoFocus: false,
    //   data: {
    //     data: data,
    //   },
    // });
    // dialog.afterClosed().subscribe((dialogResult: any) => {});
  }
}
