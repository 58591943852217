import { Component } from '@angular/core';
import { Router } from '@angular/router';
import ListConfig, { Filter } from 'src/app/shared/interfaces/listconfig';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-member-approval-list',
  templateUrl: './member-approval-list.component.html',
  styleUrls: ['./member-approval-list.component.scss']
})
export class MemberApprovalListComponent{
  filter:Filter = {
    default: -1,
     text:'Status',
    property: 'formstatus',
    choices: [
      {
        name: 'All',
        value: -1
      },
      {
        name: 'Saved',
        value: '004'
      },
      {
        name: 'Submitted',
        value: '006'
      },
      {
        name: 'Approved by 1st step',
        value: '001'
      },
      {
        name: 'Approved by 2nd step',
        value: '005'
      },
      {
        name: 'Approved',
        value: '002'
      }
    ]
  }
  configs: ListConfig[] = [
    { title: 'အမည်', property: 'name' },
    { title: 'မှတ်ပုံတင်အမှတ်', property: 'nrc' },
    { title: 'ကဒ်အမျိုးအစား', property: 'memberType.name' },
    { title: 'မွေးနေ့', property: 'birthdate',type:'date' },
    { title: 'နေရပ်လိပ်စာ', property: 'current_address' },
    { title: 'ဖုန်းနံပါတ်', property: 'phone' },
    { title: 'Status', property: 'formstatus',
      status:{
      id: 'approval_status',
      list:[
        {text:'Saved',type:'default',value:'004'},
        {text:'Submitted',type:'default',value:'006'},
        {text: 'Approved by 1st step',type:'default',value:'001'},
        {text: 'Approved by 2nd step',type:'default',value:'005'},
        {text: 'Approved',type:'ok',value:'002'}
      ]
    } },
    { title: 'Payment Status', property: 'paymentstatus',status:{
      id: 'payment_status',
      list: [
        {text: 'Paid',type:'ok',value:true},
        {text: 'Unpaid',type:'danger',value:false},
      ]
    }}
];

  url = ['/members/approval']
  searchName: string = '';
  searchText = "Search Approval"
  cb = this.kunyekService.getApproveMembers.bind(this.kunyekService)
  breadcrumbs: any[] = [
    { name: 'Members', url: '', isActive: true },
    { name: 'Approval', url: '', isActive: false }
  ];

  constructor(
    private kunyekService: KunyekService,
  ) { }
}








