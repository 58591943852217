import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { differenceBy } from 'lodash';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-hierarchy-delete',
  templateUrl: './hierarchy-delete.component.html',
  styleUrls: ['./hierarchy-delete.component.scss'],
})
export class HierarchyDeleteComponent implements OnInit {
  node: any;

  isEdited: boolean = false;
  isParentValid: boolean = false;
  isUserValid: boolean = false;
  hcFormSubmitted: boolean = false;
  saveLoading: boolean = false;
  deletingHierarchy: boolean = false;

  hcForm = {
    pid: '',
    peid: '',
    cid: '',
    ceid: '',
    type: '',
    orgid: '',
    order: 0,
    primary: false,
    deletetype: '1',
  };

  parentList: any[] = [];
  positions: any[] = [];
  parentPositions: any[] = [];
  hierarchyTypes: any[] = [];
  childrenList: any = [];
  filterparentList: any = [];
  selectedUserid: any = '';
  selectedUserName: any = '';
  selectedEmployeeid: any = '';
  selectedPUserid: any = '';
  selectedPUserName: any = '';
  selectedPEmployeeid: any = '';
  searchText: any = '';
  searchText1: any = '';
  childPosition: any = '';

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<HierarchyDeleteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.hcForm.orgid = data.orgid;
    this.filterparentList = data.parentList;
    this.parentList = data.parentList;

    this.hcForm.type = data.selectedType;
    this.node = data.node;
    this.hcForm.cid = this.node.positionid;
    this.hcForm.ceid = this.node.employeeid;
    this.hcForm.order = this.node.order;
    this.hcForm.primary = this.node.primary == '1';
    if (this.node.department && this.node.division) {
      this.childPosition =
        this.node.positionname +
        ', ' +
        this.node.department +
        ', ' +
        this.node.division;
    } else if (!this.node.department && this.node.division) {
      this.childPosition =
        this.node.positionname + ', - , ' + this.node.division;
    } else if (this.node.department && !this.node.division) {
      this.childPosition = this.node.positionname + ', ' + this.node.department;
    } else {
      this.childPosition = this.node.positionname;
    }
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.getChildrenList(this.node);

    this.filterparentList = differenceBy(
      this.filterparentList,
      this.childrenList,
      'id'
    );

    const index = this.filterparentList.findIndex(
      (x: any) =>
        x.employeeid == this.node.peid && x.positionid == this.node.pid
    );
    this.parentChange(this.filterparentList[index]);
  }

  getChildrenList(node: any) {
    const temp = {
      id: node.id,
      userid: node.userid,
      positionid: node.positionid,
      peid: node.peid,
      pid: node.pid,
      employeeid: node.employeeid,
      parentuserid: node.parentuserid,
    };
    this.childrenList.push(temp);
    if (node.children && node.children.length > 0) {
      for (var i = 0; i < node.children.length; i++) {
        var item = node.children[i];
        this.getChildrenList(item);
      }
    }
  }

  close() {
    if(this.deletingHierarchy){
      return;
    }
    this.dialogRef.close();
  }

  parentChange(data: any) {
    if (data) {
      this.isParentValid = true;
      this.selectedPUserName = data.username;
      this.selectedPUserid = data.userid;
      this.selectedPEmployeeid = data.employeeid;
      this.parentPositions = [];
      var temp1 = {
        appid: '',
        createdby: '',
        department: data.department.toString().trim(),
        description: '',
        division: data.division.toString().trim(),
        domainid: '',
        id: '',
        name: data.positionname.toString().trim(),
        orgid: '',
        positionid: data.positionid,
      };
      this.parentPositions.push(temp1);
      this.hcForm.pid = data.positionid;
      this.hcForm.peid = data.employeeid;
      this.searchText1 = this.hcForm.peid;
    }
  }

  peidChange() {
    this.selectedPEmployeeid = '';
    this.selectedPUserName = '';
    this.selectedPUserid = '';
    this.hcForm.pid = '';
    this.hcForm.peid = '';
    this.isParentValid = false;
  }

  onConfirm() {
    if(this.deletingHierarchy){
      return;
    }
    if(this.hcForm.deletetype == '1' && (this.isParentValid == false || this.hcForm.pid == '')){
      return this.messageService.openSnackBar('Please choose a valid RO.', 'warn');
    }
    const message =
      this.hcForm.deletetype == '0'
        ? 'Are you sure to delete this employee id (' +
          this.node.employeeid +
          ') and related children?'
        : 'Are you sure to delete this employee id (' +
          this.node.employeeid +
          ') and move related children to this employee id (' +
          this.selectedPEmployeeid +
          ')?';
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '350px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        cmessage: message,
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.dialogRef.disableClose = true;
        this.deletingHierarchy = true;
        const data = {
          domainid: this.allinoneService.getDomain().domainid,
          orgid: this.hcForm.orgid,
          cid: this.node.positionid,
          ceid: this.node.employeeid,
          pid: this.node.pid,
          peid: this.node.peid,
          newpid: this.hcForm.deletetype == '1' ? this.hcForm.pid : '',
          newpeid:  this.hcForm.deletetype == '1' ? this.hcForm.peid : '',
          type: '1',
        };
        this.kunyekService.deleteHierarchy(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.dialogRef.close(true);
            } else {
              this.deletingHierarchy = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.dialogRef.disableClose = false;
          },
          (err) => {
            this.deletingHierarchy = false;
            this.dialogRef.disableClose = false;
          }
        );
      }
    });
  }
}
