import { Component, HostListener, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.scss']
})
export class ReasonComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;
  orgid: string = '';
  organizations: any = [];

  saveLoading : boolean = false;
  isAdding : boolean = false;
  isEditing : boolean = false;
  isFocus : boolean = false;
  isMobileView: boolean = false;
  gettingReasonList : boolean = false;
  domainid = this.allinoneService.getDomain().domainid;

  searchText : string = '';

  reasonList : any = [];

  formSubmitted : boolean = false;

  subscriptions = new SubSink();

  typefiltername = 'All';
  typefilterlist = [
    {
      name : 'All',
      value : '',
    },
    {
      name : 'Request',
      value : '001',
    },
    {
      name : 'Reject',
      value : '002',
    }
  ]

  submitForm = {
    reasonid : '',
    description : '',
    other : false,
    reasontype : ""
  }

  constructor(
    private allinoneService : AllInOneService,
    private router : Router,
    private kunyekService : KunyekService,
    private messageService : MessageService,
  ) { }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.getReasonList();
  }

  getReasonList() {
    this.gettingReasonList = true;
    var data = {
      "domainid": this.domainid,
      "orgid": this.orgid,
      "reasontype" : this.submitForm.reasontype
    }
    this.kunyekService.getRequisitionReason(data).subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          this.reasonList = res.datalist;
        }else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingReasonList = false;
      },
      (err : any) => {
        this.messageService.openSnackBar('Server Error',
          'warn'
        );
        this.gettingReasonList = false;
      }
    )
  }

  submitReason() {
    this.formSubmitted = true;
    if(this.submitForm.description == '' || this.submitForm.description == null ) {
      this.messageService.openSnackBar("Description cannot be blank.", 'warn')
      return
    }
    this.saveLoading = true;
    var data = {
      orgid : this.orgid,
      domainid : this.domainid,
      reasonid : this.submitForm.reasonid,
      text : this.submitForm.description,
      type : this.submitForm.other ? "002" : "001",
      reasontype : this.submitForm.reasontype
    }

    var fun = this.isEditing ?
    this.kunyekService.updateRequisitionReason(data)
    :this.kunyekService.addRequisitionReason(data)
    this.subscriptions.sink = fun.subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          if(this.isEditing) {
            var index = this.reasonList.findIndex(
              (x:any) => x.reasonid == this.submitForm.reasonid
            );
            if(index > -1) {
              this.reasonList[index].text = data.text;
              this.reasonList[index].type = data.type;
              this.reasonList[index].reasontype = data.reasontype;
            } else {
              this.refresh();
            }

          } else {
            var tempData = { ...data, reasonid : res.reasonid };
            this.reasonList.push(tempData);
          }
          this.cancel()
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    )
  }

  typefilterChange(data : any) {
    console.log(data);
    
    this.submitForm.reasontype = data.value;
    this.typefiltername = data.name;
    this.getReasonList();
  } 

  editReason(data : any) {
    this.isEditing = true;
    this.isAdding = true;
    this.submitForm = {
      reasonid : data.reasonid,
      description : data.text,
      other : data.type == "002" ? true : false,
      reasontype : data.reasontype
    }
  }

  addReason() {
    this.isAdding = true;
    this.submitForm.reasontype = '001'
  }

  sortData(sort: Sort) {
    const data = this.reasonList;
    if (!sort.active || sort.direction === '') {
      this.reasonList = data;
      return;
    }
    this.reasonList = data.sort((a : any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if(sort.active == 'text') {
        return this.allinoneService.compare(a.text, b.text, isAsc);
      } else if(sort.active == 'reasontype') {
        return this.allinoneService.compare(a.reasontype, b.reasontype, isAsc);
      }
      return 0;
    })
  }

  refresh() {
    this.currentPage = 1;
    this.getReasonList();
  }

  goToRecruitment(){
    this.router.navigate(["/teams/recruitment"])
  }

  clear() {
    this.searchText = '';
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.saveLoading = false;
    this.submitForm = {
      reasonid : '',
      description : '',
      other : false,
      reasontype : ""
    }
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

}
