import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-team-dashboard',
  templateUrl: './team-dashboard.component.html',
  styleUrls: ['./team-dashboard.component.scss'],
})
export class TeamDashboardComponent implements OnInit {
  teamsMenu: any = [];
  title: string = '';
  isSetting: boolean = false;

  gettingHrRequestApprovalCount: boolean = false;

  subscriptions = new SubSink();

  hrRequestCount: number = 0;
  hrApprovalCount: number = 0;

  orgid: string = '';
  organizations: any = [];

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private titleService: Title,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;

    this.titleService.setTitle('Neo Teams');
    this.allinoneService.changeFavicon('assets/images/neoteams.ico');
    this.getTeamsMenuList();
    this.getTitle();

    var r = this.router.url;

    if (r == '/requestapproval') {
      this.getHrRequestApprovalCount();
    }
  }

  getHrRequestApprovalCount() {
    this.gettingHrRequestApprovalCount = true;

    var hra = 'false';
    var a = 'false';
    var d = this.allinoneService.orgsCRm.findIndex(
      (x: any) => x.orgid == this.allinoneService.selectedOrg
    );
    if (d > -1) {
      a = this.allinoneService.orgsCRm[d].admin;
      hra = this.allinoneService.orgsCRm[d].hradmin;
    }

    var data = {
      orgid: this.orgid,
      hradmin: hra == 'true',
      orgadmin: a == 'true',
    };
    this.subscriptions.sink = this.kunyekService
      .getHrCount(data)
      .subscribe((res: any) => {
        if (res.returncode == '300') {
          this.hrApprovalCount = res.approvalcount;
          this.hrRequestCount = res.requestcount;
          this.gettingHrRequestApprovalCount = false;
        }
      });
  }

  goToRoute(item: any) {
    this.router.navigateByUrl(item.path);
  }

  getTitle() {
    console.log('call title fun');
    var r = this.router.url;
    if (r == '/timeandattendance') {
      this.title = 'Time and Attendance';
    } else if (r == '/requestapproval') {
      this.title = 'Request Approval';
    } else if (r == '/employeecentral') {
      this.title = 'Employee Central';
    } else if (r == '/references') {
      this.title = 'References';
    } else if (r == '/rolemanagement') {
      this.title = 'Role Management';
    } else {
      this.title = 'Teams';
    }
  }

  getTeamsMenuList() {

    var m = this.allinoneService.menus;
    var r = this.router.url;
    var s = this.allinoneService.settingmenus;

    if (r == '/references' || r == '/rolemanagement') {
      this.isSetting = true;

      for (var a = 0; a < s.length; a++) {
        if (s[a].path == r) {
          this.teamsMenu = s[a].list;
        }
      }
    } else {
      for (var i = 0; i < m.length; i++) {

        if (m[i].path == r) {
          this.teamsMenu = m[i].list;
        }
      }
      console.log('team', m)
    }
    
    // let newItem = {
    //   icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/usermapping.png',
    //   name: 'User ID Mapping',
    //   open: '1',
    //   order: '14',
    //   path: '/teams/useridmapping',
    //   syskey: '590873441',
    // };
    // if (this.teamsMenu[1].name === 'Organizaton References') {
    //   const itemExists = this.teamsMenu[1].list.some(
    //     (item: { name: string; path: string }) =>
    //       item.name === newItem.name || item.path === newItem.path
    //   );

    //   if (!itemExists) {
    //     this.teamsMenu[1].list.push(newItem);
    //   } else {
    //     console.log('Item already exists, not pushing again.');
    //   }
    // }

    // console.log(`Menus : ${JSON.stringify(this.teamsMenu)}`);
  }
}
