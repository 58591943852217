import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-approval-filter',
  templateUrl: './approval-filter.component.html',
  styleUrls: ['./approval-filter.component.scss'],
})
export class ApprovalFilterComponent implements OnInit {
  requestTypeList: any[] = [];
  requestSubTypeList: any[] = [];
  teamMember: any[] = [];

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  searchForm = {
    startdate: this.currentDate,
    enddate: this.currentDate,
    requesttype: '',
    requestsubtype: '',
    teammember: false,
  };

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<ApprovalFilterComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.requestTypeList = data.requestList;
    this.teamMember = data.teamMemberList;
    if (data.startdate) {
      this.searchForm.startdate = data.startdate;
      this.searchForm.enddate = data.enddate;
      this.searchForm.requesttype = data.requesttype;
      this.searchForm.requestsubtype = data.requestsubtype;
      this.searchForm.teammember = data.teammember;
    }
  }

  ngOnInit(): void {
    if (
      this.searchForm.requesttype == 'NCMLEIWHRNVIE' ||
      this.searchForm.requesttype == 'WOEIEPPLMXNW'
    ) {
      this.requestSubTypeList = this.requestTypeList.find(
        (x: any) => x.requesttypeid == this.searchForm.requesttype
      ).child;
    } else {
      this.requestSubTypeList = [];
      this.searchForm.requestsubtype = '';
    }
  }

  requestTypeChange() {
    if (
      this.searchForm.requesttype == 'NCMLEIWHRNVIE' ||
      this.searchForm.requesttype == 'WOEIEPPLMXNW'
    ) {
      this.requestSubTypeList = this.requestTypeList.find(
        (x: any) => x.requesttypeid == this.searchForm.requesttype
      ).child;
      this.searchForm.requestsubtype = '';
    } else {
      this.requestSubTypeList = [];
      this.searchForm.requestsubtype = '';
    }
  }

  search() {
    this.dialogRef.close(this.searchForm);
  }
}
