import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';

@Component({
  selector: 'app-leave-absence-detail-view',
  templateUrl: './leave-absence-detail-view.component.html',
  styleUrls: ['./leave-absence-detail-view.component.scss'],
})
export class LeaveAbsenceDetailViewComponent implements OnInit {
  typeName: string = '';
  listData: any;
  userid : string = '';
  username : string = '';
  emid : string = '';
  dateListName : string = '';
  from : string = '';
  absenceList : any = [];
 
  constructor(
    // public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    // public messageService: MessageService,
    private dialogRef: MatDialogRef<LeaveAbsenceDetailViewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log("Your data");
    
    console.log(data);
    this.typeName = data.type;
    this.from = data.from;
    this.dateListName = data.datelistname;
    this.listData = data.list;
    this.username = this.listData.username;
    this.userid = this.listData.userid;
    this.emid = this.listData.employeeid;
    // this.absenceList = [...this.listData.absencedatalist, ...this.listData.halfdayabsentlist]
    if(this.typeName == 'Absence') {
      this.listData.absencedatalist.map((x:any) => {
        this.absenceList.push({
          date:x,
          type: '',
          halfdayabsense : false
        })
      })
      this.absenceList = [...this.absenceList, ...this.listData.halfdayabsentlist]
      console.log(this.absenceList);   
      this.absenceList = this.absenceList.sort((a:any, b:any) => {
        return this.allinoneService.compare(a.date, b.date, true);
      });
      console.log(this.absenceList);
    }
    
    
    
    // var a = {}
    // for(var i =0 ; this.listData.absencedatalist.length; i++) {
    //   a = Object.assign({
    //     date: this.listData.absencedatalist[i]
    //   })
    // }
    // console.log(a);
    
    // console.log('a');
    
    // console.log(a);
    
    // this.searchForm.orgid = data.orgid;
    // this.searchForm.domainid = data.domainid;
    // if (data.startdate) {
    //   this.searchForm.startdate = data.startdate;
    //   this.searchForm.enddate = data.enddate;
    //   this.searchForm.requesttype = data.requesttype;
    //   this.searchForm.requestsubtype = data.requestsubtype;
    //   this.searchForm.teammember = data.teammember;
    // }
  }

  ngOnInit(): void {}

  sortDate(sort : Sort) {
    const data = this.absenceList;
    this.absenceList = data.sort((a:any, b:any)=> {

    })
  }

  sortProjData(sort: Sort) {
    const data = this.listData[this.dateListName];
    if (!sort.active || sort.direction === '') {
      this.listData[this.dateListName] = data;
      return;
    }
    this.listData[this.dateListName] = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      if(sort.active == 'date') {
        return this.allinoneService.compare(a.date, b.date, isAsc);
      } else if(sort.active == 'timein') {
        return this.allinoneService.compare(a.timein, b.timein, isAsc);
      }
      return 0;
    })
  }

  // sortProjData(sort: Sort) {
    // const data = this.projData;
    // if (!sort.active || sort.direction === '') {
    //   this.projData = data;
    //   return;
    // }

    // this.projData = data.sort((a: any, b: any) => {
    //   const isAsc = sort.direction === 'asc';
    //   switch (sort.active) {
    //     case 'userid':
    //       return this.allinoneService.compare(a.userid, b.userid, isAsc);
    //     case 'username':
    //       return this.allinoneService.compare(a.username, b.username, isAsc);
    //     case 'hours':
    //       return this.allinoneService.compare(a.hours, b.hours, isAsc);
    //     default:
    //       return 0;
    //   }
    // });
  // }
}
