import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { formatDate } from '@angular/common';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';


@Component({
  selector: 'app-gym-member-preview',
  templateUrl: './gym-member-preview.component.html',
  styleUrls: ['./gym-member-preview.component.scss']
})
export class GymMemberPreviewComponent implements OnInit {
  gymmember: any;
  memberName: any;
  id!: string;
  todaydate: any;
  breadcrumbs: any[] = [
    { name: 'Gym', url: 'gym', isActive: true },
    { name: 'Gym Member', url: 'members/gymlist', isActive: true },
    { name: '', url: '', isActive: false }
  ];
  constructor(
    private router: Router,
    private kunyek: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public allInOneService: AllInOneService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(value => this.id = value.get('id') || '')
    this.getGymMemberByID();
  }

  getGymMemberByID() {
    this.kunyek.gymMember().get({ id: this.id }).subscribe({
      next: (res) => {
        this.gymmember = res.data;
        this.memberName = res.data.name;
        this.breadcrumbs[2].name = this.memberName;
      },
      error: (err) => {
        this.messageService.openSnackBar('Failed to get gym member preview', 'warn');
      }
    });
  }

  closeForm() {
    this.router.navigate(['members', 'gymlist']);
  }

  goBackToList() {
    this.router.navigateByUrl('members/gymlist');
  }

  printPage() {
    const printContent = document.getElementById('printableCard');
    if (printContent) {
      const printClone = printContent.cloneNode(true);
      const printContainer = document.createElement('div');
      printContainer.style.position = 'absolute';
      printContainer.style.left = '-9999px';
      printContainer.appendChild(printClone);
      document.body.appendChild(printContainer);
      const style = document.createElement('style');
      style.innerHTML = `
        @media print {
          .me-auto, .navbar, .side-nav, .side-nav-expand, .btn-action {
            display: none !important;
          }
          #print-container, #print-container * {
            visibility: visible;
          }
          #print-container {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `;
      document.head.appendChild(style);
      printContainer.id = 'print-container';
      window.print();
      document.body.removeChild(printContainer);
      document.head.removeChild(style);
    } else {
      console.error('printableCard element not found');
    }
  }



  exportPDF() {
    const name = this.memberName + "-" || '';
    const data = document.getElementById('printableCard');
    if (data) {
      html2canvas(data).then(canvas => {
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 0;

        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(`${name}-gym-form.pdf`);

      }).catch(error => {
        console.error('Error generating PDF:', error);
      });
    } else {
      console.error('printableCard element not found');
    }
  }

  formatDate(date: string) {
    if (date == '') return '';
    const pattern = 'dd-MM-yyyy';
    const date1 = formatDate(new Date(date), pattern, 'en-US');
    return date1;
  }
}
