<div class="cc-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="label title">Edit Details
        </div>
        <div class="close-btn">
            <button mat-icon-button [disabled]="saveLoading" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <div class = "mb-3">
            <label for="ceid" class="form-label">Member ({{node.employeeid}} - {{node.username? node.username : node.userid}})</label>
            <input type="text" placeholder="0001, 0002" aria-label="Number" class="form-control"
                [(ngModel)]="node.employeeid" name="ceid" disabled
                id="ceid">
        </div>
        <div class="mb-3">
            <label for="cid" class="col-form-label">Position (Post , Department , Division)</label>

            <div>
                <select class="form-select" aria-label="Select Position" id="cid" name="cid" [(ngModel)]="hcForm.cid"
                    required #cid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && cid.invalid }"
                    [disabled] = "gettingPositionList"
                    >
                    <option value="">-</option>
                    <option *ngFor="let position of positions; let index = index" [value]="position.positionid"
                        [selected]="hcForm.cid == position.positionid">
                    <span *ngIf = "position.name && position.department && position.division">
                        {{position.name}}, {{position.department}}, {{position.division}}
                    </span>
                    <span *ngIf = "position.name && position.department && !position.division">
                        {{position.name}}, {{position.department}}
                    </span>
                    <span *ngIf = "position.name && !position.department && position.division">
                        {{position.name}}, - , {{position.division}}
                    </span>
                    <span *ngIf = "position.name && !position.department && !position.division">
                        {{position.name}}
                    </span>
                    </option>
                </select>
                <mat-progress-bar mode="indeterminate" *ngIf="gettingPositionList"></mat-progress-bar>
            </div>
            <div class="invalid-feedback" *ngIf="cid.invalid || (cid.dirty || cid.touched) && hcFormSubmitted">
                <div *ngIf="cid.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
        <div class ="mb-3" *ngIf="node.htype == '1'">
            <label for="order" class="form-label">Order</label>
            <input type="number" class="form-control" id="order" name="order" placeholder="Enter order"
                [(ngModel)]="hcForm.order" required #order="ngModel"
                [ngClass]="{ 'is-invalid': hcFormSubmitted && order.invalid }"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
    
            <div class="invalid-feedback" *ngIf="order.invalid || (order.dirty || order.touched) && hcFormSubmitted">
                <div *ngIf="order.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="btn-footer">
    <button class="btn btn-primary my-btn" type="button" (click)="submitHC()" [disabled]= "saveLoading">
        <mat-icon *ngIf="!saveLoading">check</mat-icon>
        <div *ngIf="saveLoading" class="spinner-border" style="width: 25px; height: 25px;color: white;" role="status">
        </div>
    </button>
</div>