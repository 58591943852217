import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-accessories-filter',
  templateUrl: './accessories-filter.component.html',
  styleUrls: ['./accessories-filter.component.scss']
})
export class AccessoriesFilterComponent implements OnInit {

  currentDate = new Date(Date.now());

  generalDatePickerConfig = this.allinoneService.datePickerConfig;

  isApproval : boolean = true;

  searchForm : any = {
    requeststatus: '',
    requeststatusname: '',
    startdate: '',
    enddate: '',
  };

  approvalStatusList = [
    { id: "001", name: "Submit" },
    { id: "002", name: "Approved" },
    { id: "003", name: "Rejected" },
  ]

  requestStatusList = [
    {id: "",name: "All"},
    { id: "001", name: "Submit" },
    {id: "pending", name: "Pending"},
    { id: "004", name: "Approved" },
    { id: "005", name: "Rejected" },
  ]

  constructor(public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<AccessoriesFilterComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.isApproval = data.isApproval;
      this.searchForm.requeststatus = data.status;
      this.searchForm.startdate = data.startdate;
      this.searchForm.enddate = data.enddate;
    }

  ngOnInit(): void {
  }

  search() {

    if( (!this.searchForm.startdate && this.searchForm.enddate) || (this.searchForm.startdate && !this.searchForm.enddate) 
      || (this.searchForm.enddate < this.searchForm.startdate) ) {
      this.messageService.openSnackBar('Invalid Date.', 'warn');
      return;
    }

    var d = {
      status : this.searchForm.requeststatus,
      statusname : this.getStatusName(this.searchForm.requeststatus),
      startdate : this.searchForm.startdate,
      enddate : this.searchForm.enddate
    }
    this.dialogRef.close(d);
  }

  getStatusName(id:any) {
    if(this.isApproval) {
      var a = this.approvalStatusList.findIndex((x:any) => x.id == id );
      if(a > -1) {
        return this.approvalStatusList[a].name;
      } else {
        return '';
      }
    } else {
      var a = this.requestStatusList.findIndex((x:any) => x.id == id );
      if(a > -1) {
        return this.requestStatusList[a].name;
      } else {
        return '';
      }
    }
  }

}
