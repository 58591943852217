import { Component, HostListener, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-usermapping',
  templateUrl: './usermapping.component.html',
  styleUrls: ['./usermapping.component.scss'],
})
export class UsermappingComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  mappingOrNot: boolean = false;
  invalidUserId: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  userListLoading: boolean = false;
  userFormSubmitted: boolean = false;
  isFocus: boolean = false;
  isMobileView: boolean = false;

  userList: any[] = [];
  organizations: any[] = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  p: number = 1;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  prefix: string = '';
  searchText: string = '';

  userForm = {
    id: '',
    orgid: '',
    uuid: '',
    defaultotp: '0',
    mobile: '',
    email: '',
  };

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (!this.allinoneService.isAdminOf('org')) {
      this.router.navigateByUrl('/home');
    }
  }

  refresh() {
    this.getUserList();
  }

  getUserList() {
    this.userListLoading = true;
    const data = {
      orgid: this.userForm.orgid,
    };
    this.kunyekService.getUserMapping(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.userList = res.datalist;
          this.pg[3].count = this.userList.length;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.userListLoading = false;
      },
      (err) => {
        this.userListLoading = false;
      }
    );
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    this.organizations = this.allinoneService.orgs;
    this.userForm.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.prefix =
      this.organizations.find((x: any) => x.orgid == this.userForm.orgid)
        .shortcode + '#';
    this.getUserList();
  }

  userAdd() {
    this.mappingOrNot = true;
  }

  editUser(user: any) {
    this.mappingOrNot = true;
    this.isEditing = true;
    this.userForm = {
      id: user.id,
      orgid: this.userForm.orgid,
      uuid: user.uuid,
      defaultotp: user.defaultotp,
      mobile: user.mobile,
      email: user.email,
    };
  }

  cancel() {
    this.mappingOrNot = false;
    this.isEditing = false;
    this.userFormSubmitted = false;
    this.invalidUserId = false;
    this.userForm = {
      id: '',
      orgid: this.userForm.orgid,
      uuid: '',
      defaultotp: '0',
      mobile: '',
      email: '',
    };
  }

  submitUser() {
    this.userFormSubmitted = true;
    const mobile = this.userForm.mobile.toString().trim();
    const email = this.userForm.email.toString().trim();
    if (!this.isEditing && this.hasWhiteSpace(this.userForm.uuid)) {
      this.invalidUserId = true;
      return this.messageService.openSnackBar(
        'User ID cannot contain space.',
        'warn'
      );
    }
    if (this.userForm.defaultotp == '0' && mobile == '') {
      return;
    }
    if (this.userForm.defaultotp == '1' && email == '') {
      return;
    }
    if (
      this.userForm.defaultotp == '1' &&
      email != '' &&
      !this.allinoneService.validateMail(email)
    ) {
      return this.messageService.openSnackBar('Invalid email.', 'warn');
    }
    this.saveLoading = true;
    const finalMobile =
      mobile != '' ? this.allinoneService.checkUserId(mobile) : '';
    const data = {
      id: this.userForm.id,
      orgid: this.userForm.orgid,
      uuid: this.isEditing
        ? this.userForm.uuid
        : this.prefix + this.userForm.uuid,
      defaultotp: this.userForm.defaultotp,
      mobile: finalMobile,
      email: email,
      status: '1',
    };
    var fun = this.isEditing
      ? this.kunyekService.editUserMapping(data)
      : this.kunyekService.createUserMapping(data);
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (this.isEditing) {
            var index = this.userList.findIndex(
              (x: any) => x.id == this.userForm.id
            );
            if (index > -1) {
              this.userList[index].defaultotp = this.userForm.defaultotp;
              this.userList[index].mobile = finalMobile;
              this.userList[index].email = email;
            }
          } else {
            var temp = {
              id: res.id,
              uuid: this.prefix + this.userForm.uuid,
              defaultotp: this.userForm.defaultotp,
              mobile: finalMobile,
              email: email,
            };
            this.userList.push(temp);
            this.pg[3].count = this.userList.length;
          }
          this.cancel();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  sortData(sort: Sort) {
    const data = this.userList;
    if (!sort.active || sort.direction === '') {
      this.userList = data;
      return;
    }

    this.userList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'uuid':
          return this.allinoneService.compare(a.uuid, b.uuid, isAsc);
        case 'mobile':
          return this.allinoneService.compare(a.mobile, b.mobile, isAsc);
        case 'email':
          return this.allinoneService.compare(a.email, b.email, isAsc);
        case 'otp':
          return this.allinoneService.compare(
            a.defaultotp,
            b.defaultotp,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  hasWhiteSpace(s: string) {
    return /\s/g.test(s);
  }
}
