<div class = "d-flex align-items-end">
  <!-- <div class="btn-group dropup me-2" style="margin-bottom: 5px !important;"> -->
    <!-- <i class="fas fa-paperclip" data-bs-toggle="dropdown" aria-expanded="false"></i> -->
    <!-- <mat-icon data-bs-toggle="dropdown" aria-expanded="false" (click)="hiddenfileinput.click()">add_circle</mat-icon>
    <input style="display: none" type="file" accept="image/png, image/jpeg" (change)="onMultipleFileSelect($event)"
          #hiddenfileinput multiple="multiple"> -->
    <!-- <ul class="dropdown-menu">
      <li>
        <input style="display: none" type="file" accept="image/png, image/jpeg" (change)="onMultipleFileSelect($event)"
          #hiddenfileinput multiple="multiple">
        <a class="dropdown-item" (click)="hiddenfileinput.click()"><i class="fa fa-image me-2"></i>Image</a>
      </li>
    </ul> -->
  <!-- </div> -->

  <div class="img-textarea">
    <div class = "d-flex" *ngIf="selectedImageList.length != 0" style="overflow: auto;width: 100%;">
      <div class="image-box" *ngFor="let image of selectedImageList;let i = index;" style="display: inline-block;">
        <div style="position: relative; margin-right: 10px;">
          <div>
            <img [src]="image.previewurl" alt="" style="width: auto;height: 100px;object-fit: cover;">
          </div>
          <div class="close" (click)="closeImage(i)">
            <mat-icon>close</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="edit-input-box">
      <div id="editor-container" class="edit-textarea" (paste)="onPaste($event)">

      </div>
    </div>
  </div>

</div>
<div class="close-save-button justify-content-between mt-3">
  <div>
    <input style="display: none" type="file" accept="image/png, image/jpeg" (change)="onMultipleFileSelect($event)"
          #hiddenfileinput multiple="multiple">
    <button class="btn btn-custom my-1" *ngIf="!editLoading" (click)="hiddenfileinput.click()">
      <i class = "fa fa-image me-1"></i>Image
  </button>
  </div>
  <div class = "d-flex align-items-center">
    <button type="button" class="btn btn-outline-secondary me-2 my-1" (click)="cancel()"
      [disabled]="editLoading">
      Cancel
  </button>
  <button class="btn btn-custom my-1" *ngIf="!editLoading" (click)="submitEditMessage()">
      Save
  </button>
  <button class="btn btn-custom" *ngIf="editLoading" disabled>
      <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
      &nbsp;
      Saving
  </button>
  </div>

</div>