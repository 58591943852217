<div class="container-fluid">
    <div class="content">
        <!-- <app-breadcrumb [s_breadcrumb]="'Root Admins'" [t_breadcrumb]="rootAdminAddorNot ? 'New Admin': ''"
            (emit)="cancel()">
        </app-breadcrumb> -->
        <app-team-breadcrumb [s_breadcrumb]="'Root Admins'" [t_breadcrumb]="rootAdminAddorNot ? 'New Admin': ''"
            (emit)="cancel()">
        </app-team-breadcrumb>

        <div class="mb-3 d-flex justify-content-between header">
            <div>
                <button *ngIf="!rootAdminAddorNot" type="button" class="btn btn-custom" (click)="addRootAdmin()">
                    <i class="fa fa-plus me-1"></i>New Admin
                </button>
                <div *ngIf="rootAdminAddorNot" class="close-save-button">
                    <button type="button" class="btn btn-custom me-2 my-1" (click)="cancel()" *ngIf="!saveLoading">
                        Cancel
                    </button>
                    <app-button-loader *ngIf="isadminValid" (click)="submitRootadmin()" [button_text]="'Save'"
                        class="my-1" [load_button_text]="'Saving'" [isLoading]="saveLoading"></app-button-loader>
                    <button *ngIf="!isadminValid" class="btn btn-custom  my-1" type="button" disabled>
                        Save
                    </button>
                </div>
            </div>
        </div>

        <form class="mb-4">
            <div *ngIf="rootAdminAddorNot == true">
                <div class="mb-3">
                    <label for="rootadmin" class="form-label">
                        Root Admin
                        <span *ngIf="isadminValid">( Name - {{username}} )</span>
                    </label>
                    <div class="input-group">
                        <input type="text" class="form-control" [(ngModel)]="rootAdminForm.userid" id="rootadmin"
                            name="rootadmin" placeholder="john@rootadmin.com, +959********" required
                            #rootadmin="ngModel" (ngModelChange)="useridChange()">
                        <app-button-loader *ngIf="rootAdminForm.userid.length != 0" (click)="checkMember()"
                            [button_text]="'Check'" [load_button_text]="'Checking'" [isLoading]="checkingMember">
                        </app-button-loader>
                        <button *ngIf="rootAdminForm.userid.length == 0" type="button" class="btn btn-custom"
                            disabled>Check</button>
                    </div>

                    <!-- <div class="invalid-feedback"
                        *ngIf="rootadmin.invalid || (rootadmin.dirty || rootadmin.touched) && rootadminFormSubmitted">
                        <div *ngIf="rootadmin.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div> -->
                </div>
            </div>
        </form>

        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
            <table class="table table-responsive table-borderless" *ngIf="rootAdminAddorNot == false">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-5 col-md-5" mat-sort-header="userid">User ID</td>
                        <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="username">Name</td>
                        <td scope="col" class="d-flex justify-content-center">Action</td>
                    </tr>
                </thead>
                <tbody *ngIf="rootadminListLoading">
                    <tr>
                        <td colspan="4" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rootadminListLoading == false">
                    <tr *ngIf="rootadminListToShow.length == 0">
                        <td colspan="4" class="nodata">Empty</td>
                    </tr>
                    <tr
                        *ngFor="let item of rootadminListToShow | paginate: { itemsPerPage: 10, currentPage: p }; let index = index">
                        <td class="td-data">
                            <span [title]="item.userid">{{item.userid}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.username">{{item.username}}</span>
                        </td>
                        <td class="d-flex justify-content-center">
                            <button class="btn btn-outline-danger" title="Remove from admin"
                                (click)="removeAdmin(item.id, item.userid)">
                                <i class='fa fa-trash-o'></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination-controls class="paginate" (pageChange)="p = $event"
            *ngIf="rootadminListToShow.length > 10 && !rootAdminAddorNot" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
    </div>
</div>