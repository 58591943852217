import { Component, OnDestroy, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-employee-central',
  templateUrl: './employee-central.component.html',
  styleUrls: ['./employee-central.component.scss'],
})
export class EmployeeCentralComponent implements OnInit, OnDestroy {
  menus: any[] = [];
  gettingApprovalCount: boolean = false;

  approvalCount: number = 0;

  isHRAdmin: boolean = true;
  gettingRequisitionCount: boolean = false;
  subscriptions: any;
  requisitionCount: number = 0

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService
  ) {
    this.isHRAdmin = this.allinoneService.isAdminOf('hr');
  }
  ngOnDestroy(): void {
    // this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (this.allinoneService.menus) {
      // this.menus = this.allinoneService.menus.hradmin;
      if (this.allinoneService.isAttendanceView) {
        this.menus = this.allinoneService.menus.hradmin[0].list;
      } else {
        this.menus = this.allinoneService.hrPolicies;
      }
    }
    if (this.allinoneService.isAdminOf('org')) {
      this.getApprovalCount();
      // this.getRequisitionCount();
    }
  }
  callFromWhere(role: any) {
    sessionStorage.removeItem('callType');
    sessionStorage.setItem('callType', role);
  }

  isArrayFun(data: any) {
    return Array.isArray(data);
  }

  getRequisitionCount() {
    this.gettingRequisitionCount = true;
    var data = {
      orgid: this.allinoneService.selectedOrg,
      role: '700'
    };
    this.kunyekService
      .getRequisitionCount(data)
      .subscribe((res: any) => {
        if (res.returncode == '300') {
          this.requisitionCount = res.requestcounts;
          this.gettingRequisitionCount = false;
        }
      });
  }

  getApprovalCount() {
    this.gettingApprovalCount = true;
    var data = {
      orgid: this.allinoneService.selectedOrg,
      roleid: '',
      byadmin: true,
    };
    this.kunyekService.getApprovalCount(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        this.approvalCount = res.approval;
        this.gettingApprovalCount = false;
      }
    });
  }
}
