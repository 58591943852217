import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-members-role',
  templateUrl: './members-role.component.html',
  styleUrls: ['./members-role.component.scss']
})
export class MembersRoleComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild('tbody') tbody!: ElementRef;

  ccFormSubmitted: boolean = false;
  editFormSubmitted: boolean = false;
  gettingCcList: boolean = false;
  isEditing: boolean = false;
  disable: boolean = true;
  saveLoading: boolean = false;
  fromOrg: boolean = false;
  fromHCM: boolean = false;
  classCodeAddOrNot: boolean = false;

  ccList: any[] = [];
  organizations: any[] = [];
  membershiptype: any = '';
  domainid: string = '';

  p: number = 1;

  ccForm = {
    orgid: '',
    name: '',
    description: '',
    roleid: '',
  };

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<MembersRoleComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.fromOrg = data.fromOrg;
    this.fromHCM = data.fromHCM ? data.fromHCM : false;
    if (this.fromOrg) {
      console.log(data.org);
      this.organizations.push(data.org);
      this.ccForm.orgid = data.org.orgid;
    } else {
      this.ccForm.orgid = data.orgid;
    }
  }

  ngOnInit(): void {
    // if (!this.fromOrg) {
    //   this.organizations = this.allinoneService.orgs;
    //   // this.ccForm.orgid = this.allinoneService.selectedOrg ? this.allinoneService.selectedOrg : this.organizations[0].orgid
    // }
    // if(this.fromHCM){
    //   this.organizations = this.allinoneService.hrOrgs;
    //   console.log(this.organizations);
    // }
      this.organizations = this.allinoneService.orgs;
    const index = this.organizations.findIndex(
      (x: any) => x.orgid == this.ccForm.orgid
    );
    this.membershiptype = this.organizations[index].membership;
    this.domainid = this.allinoneService.getDomain().domainid;
    this.getRoleList();
  }

  sortData(sort: Sort) {
    const data = this.ccList;
    if (!sort.active || sort.direction === '') {
      this.ccList = data;
      return;
    }

    this.ccList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  cancel() {
    this.isEditing = false;
    this.classCodeAddOrNot = false;
    this.ccFormSubmitted = false;
    this.ccForm = {
      name: '',
      description: '',
      roleid: '',
      orgid: this.ccForm.orgid,
    };
  }


  editClasscode(item: any) {
    this.isEditing = true;
    this.classCodeAddOrNot = true;
    this.ccForm = {
      name: item.name,
      description: item.description,
      roleid: item.roleid,
      orgid: this.ccForm.orgid,
    };
  }


  submitCc() {
    this.ccFormSubmitted = true;
    if (this.ccForm.name.trim() == '') {
      return;
    } else {
      this.saveCc();
    }
  }

  saveCc() {
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var data = {
      domainid: this.domainid,
      orgid: this.ccForm.orgid,
      name: this.ccForm.name,
      description: this.ccForm.description,
    };

    if (this.isEditing) {
      data = Object.assign(data, { roleid: this.ccForm.roleid });
    }

    const fun = this.isEditing
      ? this.kunyekService.updateUserRole(data)
      : this.kunyekService.addUserRole(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.getRoleList();
          this.cancel();
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar('Saved successfully.', 'success');
        } else {
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.ccFormSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
        this.ccFormSubmitted = false;
      }
    );
  }

  getRoleList() {
    this.gettingCcList = true;
    const data = {
      orgid: this.ccForm.orgid,
      domainid: this.domainid,
    };
    this.kunyekService.getUserRole(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.ccList = res.datalist;
          this.gettingCcList = false;
        } else {
          this.gettingCcList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingCcList = false;
      }
    );
  }


  


  inputChange() {
    if (this.ccForm.name.trim() == '') {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  checkOverflow(element: any) {
    return element.offsetHeight < element.scrollHeight;
  }

}
