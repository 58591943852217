<div>
    <div class="me-auto">
        <!-- <app-kmember-breadcrumb [s_breadcrumb]="currentMember.name"></app-kmember-breadcrumb> -->
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    </div>

    <div class="d-flex gap-2 mb-2">

        <app-button-loader (click)="close()" [button_text]="'Cancel'">
        </app-button-loader>
        <app-button-loader (click)="handleRegister()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="saveLoading">
        </app-button-loader>

    </div>

    <div class="d-flex flex-wrap">
        <div class="card" [class.col-md-12]="!showIdCardContainer || !isAlreadyMember"
            [class.col-md-9]="showIdCardContainer">
            <div class="card-body m-2">
                <div class="row mb-2">
                    <div class="col-md-12 mb-2">
                        <label class="form-label">Member ID</label>
                        <input type="text" class="form-control" id="membercard_id" name="membercard_id"
                            [(ngModel)]="memberForm.membercard_id">
                        <mat-progress-bar mode="indeterminate" *ngIf="getting"></mat-progress-bar>
                    </div>

                    <div class="col-md-12 mb-2">
                        <label class="form-label">Name</label>
                        <input type="text" class="form-control" [(ngModel)]="currentMember.name" readonly>
                    </div>

                    <div class="col-md-12 mb-2">
                        <label class="form-label">Start Date</label>
                        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy"
                            bsDatepicker [bsConfig]="generalDatePickerConfig" [(ngModel)]="memberForm.startdate"
                            [bsConfig]="{showClearButton: true}" readonly [disabled]="isAlreadyMember">
                    </div>

                    <div class="col-md-12 mb-2">
                        <label class="form-label">End Date</label>
                        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy"
                            bsDatepicker [bsConfig]="generalDatePickerConfig" [(ngModel)]="memberForm.enddate"
                            [bsConfig]="{showClearButton: true}" readonly [disabled]="isAlreadyMember">
                    </div>
                    <div class="col-md-12 mb-2" *ngIf="attachment">
                        <div class="d-flex flex-column">
                            <label class="form-label mt-3">Payment Attachment</label>
                            <img [src]="attachment.fileurl" width="250px" height="200px" class="gap-2 mb-2"
                                (click)="viewAttachment()" alt="payment attachment">
                        </div>
                        <div class="d-flex gap-2">
                            <button type="button" class="btn btn-sm btn-custom" (click)="viewAttachment()">View
                                Attachment</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3" *ngIf="showIdCardContainer && isAlreadyMember">
            <div id="idCardContainer" class="card-container">
                <div id="idCard" class="card text-center"
                    style="width: 20rem; padding: 20px; background-image: url('../../../assets/images/crmback.png'); margin: 10px; border: 1px solid #00796b; border-radius: 10px;">
                    <div style="text-align: center; padding-bottom: 8px;">
                        <img src="../../../assets/images/post_admin.png" alt="logo" style="width: 50px; height: 50px;">
                        <div style="font-size: 20px; color: #090f0f; font-weight: bold;">Kokine Swimming Club</div>
                    </div>
                    <div class="card-body" style="text-align: center;">
                        <img class="drag-image me-3" [defaultImage]="'../../../assets/images/member.png'"
                            [lazyLoad]="getProfileImage()" alt="member image" class="card-img"
                            style="width: 100px; height: 100px; border-radius: 50%;" />
                        <p class="card-title" style="font-size: 16px;">
                            {{ currentMember.name }} ({{ currentMember.membercard_id? currentMember.membercard_id:
                            currentMember.registration.membercard_id}})
                        </p>

                        <p class="card-text" style="font-size: 14px;">{{ currentMember.membertype?
                            currentMember.membertype: currentMember.data.memberType.name }}</p>
                        <p class="card-text" style="font-size: 14px;"><span class="fa fa-phone me-2"></span>{{
                            currentMember.data.phone }}</p>
                        <p class="card-text" style="font-size: 14px;">{{ currentMember?.data?.nrc }}</p>
                        <p class="card-text" style="font-size: 12px;">{{ currentMember.startdate }} {{
                            currentMember?.enddate }}</p>

                    </div>
                </div>
            </div>
            <div class="d-flex btn-action">
                <app-button-loader class="me-2" [button_text]="'Download PNG'"
                    (click)="downloadCard()"></app-button-loader>
                <app-button-loader [button_text]="'Download PDF'" (click)="downloadPDF()"></app-button-loader>
            </div>
        </div>
    </div>
</div>