import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AllInOneService } from './services/all-in-one.service';
@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'filter',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  constructor(private allinoneService: AllInOneService) {}

  /**
   * Transform
   *
   * @param {any[]} items
   * @param {any} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string, type: any): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    var returnItems: any[];

    switch (type) {
      //search in chat list
      case 'campaign':
        returnItems = items.filter((it) => {
          return it.camname.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;
      case 'round':
        returnItems = items.filter((it) => {
          return (
            it.roundname.toString().toLocaleLowerCase().includes(searchText) ||
            it.desc.toString().toLocaleLowerCase().includes(searchText) ||
            it.createdby.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'batch':
        returnItems = items.filter((it) => {
          return (
            it.createdby.toString().toLocaleLowerCase().includes(searchText) ||
            it.reference.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'coupon':
        returnItems = items.filter((it) => {
          return (
            it.coupon.toString().toLocaleLowerCase().includes(searchText) ||
            it.userid.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'couponrule':
        returnItems = items.filter((it) => {
          return it.rulename
            .toString()
            .toLocaleLowerCase()
            .includes(searchText);
        });
        return returnItems;
      case 'winnerdetail':
        returnItems = items.filter((it) => {
          return (
            it.price.toString().toLocaleLowerCase().includes(searchText) ||
            it.coupon.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'member':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'deviceinfo':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.mobile?.toString().toLocaleLowerCase().includes(searchText) ||
            it.appname?.toString().toLocaleLowerCase().includes(searchText) ||
            it.uuid?.toString().toLocaleLowerCase().includes(searchText) ||
            it.devicename
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.createddate
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.appversion
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.version?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'merchant' || 'postList' || 'org':
        returnItems = items.filter((it) => {
          return (
            it.name?.toString().toLocaleLowerCase().includes(searchText) ||
            it.description?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'user':
        returnItems = items.filter((it) => {
          return (
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.createddate.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'notification':
        returnItems = items.filter((it) => {
          return (
            it.title.toString().toLocaleLowerCase().includes(searchText) ||
            it.description.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'instantWinning':
        returnItems = items.filter((it) => {
          return it.price.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;
      case 'gpriceopening':
        returnItems = items.filter((it) => {
          return it.price.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;
      case 'gwinner':
        returnItems = items.filter((it) => {
          return (
            it.price.toString().toLocaleLowerCase().includes(searchText) ||
            it.coupon.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'redeemprice':
        returnItems = items.filter((it) => {
          return (
            it.price.toString().toLocaleLowerCase().includes(searchText) ||
            it.coupon.toString().toLocaleLowerCase().includes(searchText) ||
            it.userid.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'userdetails':
        returnItems = items.filter((it) => {
          return it.coupon.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;
      case 1:
        returnItems = items.filter((it) => {
          if (it.type == '2') {
            return it.channelname
              .toString()
              .toLocaleLowerCase()
              .includes(searchText);
          } else {
            return (
              (it.userlist[0].username
                ?.toString()
                .toLocaleLowerCase()
                .includes(searchText) ||
                it.userlist[0].userid
                  .toString()
                  .toLocaleLowerCase()
                  .includes(searchText)) &&
              it.block != 'true'
            );
          }
        });
        return returnItems;
      //search in contacts
      case 2:
        var tempResultList: any = [];
        for (let i = 0; i < items.length; i++) {
          var tempList: any = [];
          for (let j = 0; j < items[i].contacts.length; j++) {
            if (
              items[i].contacts[j].username
                ?.toString()
                .toLocaleLowerCase()
                .includes(searchText) ||
              items[i].contacts[j].userid
                .toString()
                .toLocaleLowerCase()
                .includes(searchText)
            ) {
              tempList.push(items[i].contacts[j]);
            }
          }
          if (tempList.length != 0) {
            tempResultList.push({
              key: items[i].key,
              contacts: tempList,
            });
          }
        }
        returnItems = tempResultList;
        return returnItems;
      //search attendance report daterange total days
      case 3:
        returnItems = items.filter((it) => {
          return (
            it.typename.toString().toLocaleLowerCase().includes(searchText) ||
            it.date.toString().toLocaleLowerCase().includes(searchText) ||
            it.starttime.toString().toLocaleLowerCase().includes(searchText) ||
            it.location?.toString().toLocaleLowerCase().includes(searchText) ||
            it.lat?.toString().toLocaleLowerCase().includes(searchText) ||
            it.long?.toString().toLocaleLowerCase().includes(searchText) ||
            it.description?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      //search attendance report daterange total hours
      case 4:
        returnItems = items.filter((it) => {
          return (
            it.autoid.toString().toLocaleLowerCase().includes(searchText) ||
            it.taskdescription
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.hours.toString().toLocaleLowerCase().includes(searchText) ||
            it.date.toString().toLocaleLowerCase().includes(searchText) ||
            it.starttime.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      //search attendance report summary details
      case 5:
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.date.toString().toLocaleLowerCase().includes(searchText) ||
            it.starttime.toString().toLocaleLowerCase().includes(searchText) ||
            it.location?.toString().toLocaleLowerCase().includes(searchText) ||
            it.lat?.toString().toLocaleLowerCase().includes(searchText) ||
            it.long?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      //search attendance report daterange total project/jobs hours
      case 6:
        returnItems = items.filter((it) => {
          return (
            it.jobname.toString().toLocaleLowerCase().includes(searchText) ||
            it.autoid.toString().toLocaleLowerCase().includes(searchText) ||
            it.taskdescription
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.hours.toString().toLocaleLowerCase().includes(searchText) ||
            (this.allinoneService.formatDBToShow(it.date) + ' ' + it.starttime)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });
        return returnItems;
      //search Locations
      case 7:
        returnItems = items.filter((it) => {
          return (
            it.userid?.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.name?.toString().toLocaleLowerCase().includes(searchText) ||
            it.range?.toString().toLocaleLowerCase().includes(searchText) ||
            it.description
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.lat?.toString().toLocaleLowerCase().includes(searchText) ||
            it.lng?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      //search Events
      case 8:
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            it.startdatetoshow
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.enddatetoshow
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });
        return returnItems;
      //search projects
      case 9:
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            this.allinoneService
              .formatDBToShow(it.startdate)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            this.allinoneService
              .formatDBToShow(it.enddate)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.status?.toString().toLocaleLowerCase().includes(searchText) ||
            it.description?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      //search Jobs
      case 10:
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            this.allinoneService
              .formatDBToShow(it.startdate)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            this.allinoneService
              .formatDBToShow(it.enddate)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.description
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.plannedhours
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.status.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      //search summary details group
      case 11:
        returnItems = items.filter((it) => {
          return (
            it.iolist[0].userid
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.iolist[0].employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.iolist[0].username
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });
        return returnItems;
      //Member filter in Hierarchy add
      case 12:
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.newuserid?.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid.toString().toLocaleLowerCase().includes(searchText)
          );
        });

        return returnItems;
      //Search in hierarchy children for insert between
      case 13:
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid.toString().toLocaleLowerCase().includes(searchText) ||
            it.positionname
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.department
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.division?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      //Search in check in attendance project data
      case 14:
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.hours.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      //Search in check in attendance daterange data
      case 15:
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.days?.toString().toLocaleLowerCase().includes(searchText) ||
            it.iohours?.toString().toLocaleLowerCase().includes(searchText) ||
            it.totalhours?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'absentreport':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      //Member filter in Projects
      case 16:
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.name?.toString().toLocaleLowerCase().includes(searchText)
          );
        });

        return returnItems;
      //CRM Lead Search
      case 17:
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            it.mobile.toString().toLocaleLowerCase().includes(searchText) ||
            it.statusname.toString().toLocaleLowerCase().includes(searchText) ||
            (it.createdbyname ? it.createdbyname : it.createdby)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });

        return returnItems;
      //CRM Customer Search
      case 18:
        returnItems = items.filter((it) => {
          return (
            it.customername
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.customermobile
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.typename.toString().toLocaleLowerCase().includes(searchText) ||
            it.statusname.toString().toLocaleLowerCase().includes(searchText) ||
            (it.createdbyname ? it.createdbyname : it.createdby)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });

        return returnItems;
      //CRM Deal Search
      case 19:
        returnItems = items.filter((it) => {
          return (
            it.customername
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.stagename.toString().toLocaleLowerCase().includes(searchText) ||
            it.statusname.toString().toLocaleLowerCase().includes(searchText) ||
            it.targetdate.toString().toLocaleLowerCase().includes(searchText) ||
            it.closingdate
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            (it.createdbyname ? it.createdbyname : it.createdby)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });

        return returnItems;
      //CRM Pipeline Search
      case 20:
        returnItems = items.filter((it) => {
          return (
            it.customername
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.targetdate.toString().toLocaleLowerCase().includes(searchText) ||
            it.closingdate
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.amount.toString().toLocaleLowerCase().includes(searchText) ||
            it.currencyname
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            (it.createdbyname ? it.createdbyname : it.createdby)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });
        return returnItems;

      //CRM Prduct filter
      case 21:
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            it.skucode?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      //CRM Deal Create Customer autocomplete
      case 22:
        returnItems = items.filter((it) => {
          return (
            it.customername
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.statusname.toString().toLocaleLowerCase().includes(searchText) ||
            it.customermobile
              .toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });

        return returnItems;
      //CRM Deal Create Customer autocomplete
      case 23:
        if (searchText == '') {
          return items;
        } else {
          if (searchText == '1') {
            returnItems = items.filter((it) => {
              return it.voted > 0;
            });
          } else {
            returnItems = items.filter((it) => {
              return it.voted == 0;
            });
          }
          return returnItems;
        }
      //Campaign filter
      case 24:
        returnItems = items.filter((it) => {
          return (
            it.description
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            (it.status == '001' ? 'Open' : 'Closed')
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            (it.summarystatus == '001' ? 'On' : 'Off')
              .toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });

        return returnItems;
      case 25:
        returnItems = items.filter((it) => {
          return (
            it.title.toString().toLocaleLowerCase().includes(searchText) ||
            it.description?.toString().toLocaleLowerCase().includes(searchText)
          );
        });

        return returnItems;
      case 26:
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid?.toString().toLocaleLowerCase().includes(searchText)
          );
        });

        return returnItems;
      case 27:
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'office-member':
        returnItems = items.filter((it) => {
          return it.classname
            .toString()
            .toLocaleLowerCase()
            .includes(searchText);
        });
        return returnItems;

      case 'officeleaveentitle':
        returnItems = items.filter((it) => {
          return it.templatename
            .toString()
            .toLocaleLowerCase()
            .includes(searchText);
        });
        return returnItems;
      case 'leaveTemplateList':
        returnItems = items.filter((it) => {
          return it.name.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;

      case 'leaveTemplateTypeList':
        returnItems = items.filter((it) => {
          return it.requesttypename
            .toString()
            .toLocaleLowerCase()
            .includes(searchText);
        });
        return returnItems;
      case 'calendarList':
        returnItems = items.filter((it) => {
          return it.year.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;
      case 'approvalList':
        returnItems = items.filter((it) => {
          return (
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'memberDepartmentAutocomplete':
        returnItems = items.filter((it) => {
          return it.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;
      case 'hierarchy':
        returnItems = items.filter((it) => {
          return (
            it.parentusername
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.parentuserid
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.peid.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'leaveopening':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      case 'leaveopeninghistory':
        returnItems = items.filter((it) => {
          return (
            it.modifiedby.toString().toLocaleLowerCase().includes(searchText) ||
            it.leavetype.toString().toLocaleLowerCase().includes(searchText) ||
            it.modifieddate.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'usermapping':
        returnItems = items.filter((it) => {
          return (
            it.uuid.toString().toLocaleLowerCase().includes(searchText) ||
            it.mobile?.toString().toLocaleLowerCase().includes(searchText) ||
            it.email?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      case 'qr360':
        returnItems = items.filter((it) => {
          return (
            it.typetodisplay
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.description?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'positioncontrol':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            this.allinoneService
              .formatDBToShow(it.effectivedate)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.branch?.toString().toLocaleLowerCase().includes(searchText) ||
            it.department
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.division?.toString().toLocaleLowerCase().includes(searchText) ||
            it.post?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employmenttypename
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.transactiontypename
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.remark?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      //Simple name search

      case 'LeaveSummaryReport':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.department
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.division?.toString().toLocaleLowerCase().includes(searchText) ||
            it.postname?.toString().toLocaleLowerCase().includes(searchText) ||
            it.leavetype?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'requestList':
        returnItems = items.filter((it) => {
          return (
            it.requestrefno
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.requesttypename
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.requeststatusname
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });
        return returnItems;
      case 'shiftList':
        returnItems = items.filter((it) => {
          return (
            it.name?.toString().toLocaleLowerCase().includes(searchText) ||
            it.description
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.starttime?.toString().toLocaleLowerCase().includes(searchText) ||
            it.endtime?.toString().toLocaleLowerCase().includes(searchText) ||
            it.breakhours
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.workinghours?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'wpList':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.refno?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.type?.toString().toLocaleLowerCase().includes(searchText) ||
            it.department
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.division?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'memberList':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.type?.toString().toLocaleLowerCase().includes(searchText) ||
            it.department
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.division?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'vehicle':
        returnItems = items.filter((it) => {
          return (
            it.carno.toString().toLocaleLowerCase().includes(searchText) ||
            it.maxpassenger
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.carname?.toString().toLocaleLowerCase().includes(searchText) ||
            it.typename?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'pickup':
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            it.usercounts?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'driver':
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            it.mobile?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'carway':
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            it.description
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.vehicleno.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'requests':
        returnItems = items.filter((it) => {
          return (
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.requestrefno
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.remark?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'ferryrequest':
        returnItems = items.filter((it) => {
          console.log(it);
          return (
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.requestrefno
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.remark?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'dailyRequest':
        returnItems = items.filter((it) => {
          return (
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.requestrefno
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.remark?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'userdropoff':
        returnItems = items.filter((it) => {
          return (
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.userid.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'searhrequest':
        returnItems = items.filter((it) => {
          return (
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.userid?.toString().toLocaleLowerCase().includes(searchText) ||
            it.requestrefno
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.user?.toString().toLocaleLowerCase().includes(searchText) ||
            it.trantype?.toString().toLocaleLowerCase().includes(searchText) ||
            it.type?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'attendanceId':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username?.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.attendanceid.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'qr360User':
        returnItems = items.filter((it) => {
          return it.type.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;
      case 'attendanceQr':
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            it.locname.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'lateandearlyout':
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            it.description.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'lateandearlyoutreport':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'requisitionrequest':
        returnItems = items.filter((it) => {
          return it.refno.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;

      case 'requisitionapproval':
        returnItems = items.filter((it) => {
          return (
            it.refno.toString().toLocaleLowerCase().includes(searchText) ||
            it.positionlists.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      case 'room':
        returnItems = items.filter((it) => {
          return it.name.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;

      case 'desk':
        returnItems = items.filter((it) => {
          return it.floorname
            .toString()
            .toLocaleLowerCase()
            .includes(searchText);
        });
        return returnItems;

      case 'timecardreport':
        returnItems = items.filter((it) => {
          return (
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.employeeid.toString().toLocaleLowerCase().includes(searchText) ||
            it.department.toString().toLocaleLowerCase().includes(searchText) ||
            it.division.toString().toLocaleLowerCase().includes(searchText) ||
            it.teamid.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      case 'rules':
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            it.description.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      case 'useridmapping':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.parentid.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      case 'tranView':
        returnItems = items.filter((it) => {
          return (
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.userid.toString().toLocaleLowerCase().includes(searchText) ||
            it.date.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      case 'hierarchyPosition':
        returnItems = items.filter((it) => {
          return (
            it.name.toString().toLocaleLowerCase().includes(searchText) ||
            it.department.toString().toLocaleLowerCase().includes(searchText) ||
            it.division.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'alldailyRequest':
        returnItems = items.filter((it) => {
          return (
            it.userid?.toString().toLocaleLowerCase().includes(searchText) ||
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.remark?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'allferryRequest':
        returnItems = items.filter((it) => {
          return (
            it.userid?.toString().toLocaleLowerCase().includes(searchText) ||
            it.username.toString().toLocaleLowerCase().includes(searchText) ||
            it.remark?.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'pickuppoint':
        returnItems = items.filter((it) => {
          return it.name?.toString().toLocaleLowerCase().includes(searchText);
        });

        return returnItems;

      case 'requisitionreason':
        returnItems = items.filter((it) => {
          return it.text.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;

      case 'latedetails':
        returnItems = items.filter((it) => {
          return (
            this.allinoneService
              .formatDBToShow(it.date)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.shift.toString().toLocaleLowerCase().includes(searchText) ||
            it.actual_timein
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.late_time.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      case 'earlyoutdetails':
        returnItems = items.filter((it) => {
          return (
            this.allinoneService
              .formatDBToShow(it.date)
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.shift.toString().toLocaleLowerCase().includes(searchText) ||
            it.actual_timeout
              .toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            it.earlyout_time.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;
      case 'userid':
        returnItems = items.filter((it) => {
          return it.userid.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;

      case 'hrapproval':
        returnItems = items.filter((it) => {
          return (
            it.userid.toString().toLocaleLowerCase().includes(searchText),
            it.username.toString().toLocaleLowerCase().includes(searchText)
          );
        });
        return returnItems;

      default:
        returnItems = items.filter((it) => {
          return it.name.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;
    }
  }
}
