<div class="title-container">
    <div class="title">
        History
    </div>
    <div class="close-btn">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<hr>
<table class="table table-responsive table-borderless">
    <thead class="table-header">
        <tr>
            <th>Status</th>
            <th>By</th>
            <th>Date</th>
            <th>Remark</th>
        </tr>
    </thead>
    <tbody *ngIf="!getting && approvedhistory.length>0">
        <tr *ngFor="let history of approvedhistory " class="py-4">
            <td>
                <span class="status" [ngClass]="{
                        'status-green': history.status == '002',
                        'status-red': history.status == '003',
                        'status-gray': history.status == '001',
                        'status-main': history.status == '004'
                    }">
                    {{history.status=="001" ? "Submitted" : ( history.status=="003" ? "Rejected":
                    "Approved" )}}
                </span>
            </td>
            <td>{{ history.name }}</td>
            <td>{{ history?.datetime }} </td>
            <td>{{ history?.remark }}</td>
        </tr>
    </tbody>
    <tbody *ngIf="!getting && approvedhistory.length == 0">
        <tr>
            <td colspan="5" class="nodata">
                Empty
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="getting">
        <tr>
            <td colspan="9" class="nodata">
                <div class="spinner-border" entitle="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </td>
        </tr>
    </tbody>
</table>