<div class="container-fluid">
    <div class="content">


        <app-transportation-breadcrumb s_breadcrumb="Car Way Schedule" [t_breadcrumb]="'Detail'"
            (emit)="cancelFunction()">
        </app-transportation-breadcrumb>


        <div style="width: 100%; height: 50vh;" *ngIf="gettingData" class="loading-container">

            <div class="spinner-border" role="status" style="text-align: center;
        justify-content: center;margin-top: auto;
        margin-bottom: auto;">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>



        <div *ngIf="!gettingData" class="d-flex">
            <div class="close-save-button mb-3 me-2">
                <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()">
                    Cancel
                </button>


            </div>
            <div>
                <button type="button" class="btn btn-custom" (click)="downloadSheet()"
                    matTooltip="Export Carway Detail">
                    <img class="excel-icon" src="../../../assets/images/excel.png">Export
                </button>

            </div>

        </div>
        <div *ngIf="!gettingData">

            <div class="card card-body my-card mb-3 ">

                <div class="info mb-1">
                    Car Way Info


                    <span class="status">{{carwayForm.carwaytype == '001' ? 'Ferry' :
                        'Day Car Way'}}</span>
                </div>
                <div class="row ">
                    <div class="mb-3  col-md-6">
                        <label for="carno" class="form-label">Name</label>
                        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="carwayForm.name"
                            disabled>

                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="drivername" class="form-label">Description</label>
                        <input type="text" class="form-control" id="description" name="description"
                            [(ngModel)]="carwayForm.description" disabled>
                    </div>
                </div>
                <div class="d-flex" *ngIf="carwayForm.carwaytype == '002'">
                    <div class="mb-2 me-4 ">
                        <!-- *ngIf="checkgoing" -->
                        <div class="form-check cus">
                            <input class="form-check-input cus" type="checkbox" id="go" name="gp"
                                [(ngModel)]="checkgoing" disabled>
                            <label class="form-check-label cus mb-1 ms-1" for="go">
                                Going
                            </label>
                        </div>

                    </div>
                    <div class="mb-2 ">
                        <div class="form-check cus">
                            <input class="form-check-input cus" [(ngModel)]="checkreturn" type="checkbox" id="return1"
                                name="return1" disabled>
                            <label class="form-check-label cus mb-1 ms-1" for="return1">
                                Return
                            </label>
                        </div>
                        <!-- <div class="form-check cus" *ngIf="!checkreturn">
                            <input class="form-check-input cus" type="checkbox" id="return" name="return"
                                (change)="checkReturn($event)">
                            <label class="form-check-label cus mb-1 ms-1" for="return">
                                Return
                            </label>
                        </div> -->
                    </div>

                </div>

                <div class="row ">
                    <div class="mb-2 col-md-12">

                        <label for="vehicle" class="form-label">Vehicle {{carwayForm.type == '001' ? '( Car ) ' : '(
                            Taxi )'}}</label>
                        <input type="text" class="form-control" id="description" name="description"
                            [(ngModel)]="carwayForm.vehicle" disabled>

                    </div>

                </div>
                <div class="row " *ngIf="carwayForm.type == '001'">
                    <div class="mb-3 col-md-6">
                        <label for="drivername" class="form-label">Driver Name</label>
                        <input type="text" class="form-control" id="startdatetime" [(ngModel)]="carwayForm.drivername"
                            name="drivername" disabled>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="driverphone" class="form-label">Driver Phone</label>
                        <input type="text" class="form-control" id="enddate" [(ngModel)]="carwayForm.driverphone"
                            name="driverphone" disabled>


                    </div>

                </div>
               
                <div>
                    <div class="mb-3 col-md-12">
                        <label for="startdate" class="form-label">Date</label>
                        <input type="date" class="form-control" id="startdate" [(ngModel)]="carwayForm.date"
                            name="startdate" disabled>
                    </div>
                </div>

            </div>

            <div class="card card-body my-card mb-3 ">

                <div class="info mb-1">
                    Car Way Schedule


                </div>
                <div *ngIf="carwayForm.carwaytype == '002'" class="table-responsive" matSort
                    (matSortChange)="sortData($event)">
                    <table class="table table-responsive table-borderless">
                        <thead class="table-header">
                            <tr>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="userid">User ID
                                </td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="username">User Name
                                </td>
                                <td scope="col" class="col-lg-2 col-md-3" mat-sort-header="pickuppoint">Pickup Point
                                </td>
                                <td scope="col" class="col-lg-2 col-md-3" mat-sort-header="pickuptime">Pickup Time
                                </td>
                                <td scope="col" class="col-lg-2 col-md-3" mat-sort-header="dropoffpoint">Drop off Point
                                </td>
                                <td scope="col" class="col-lg-2 col-md-3" mat-sort-header="dropofftime">Drop off Time
                                </td>
                            </tr>
                        </thead>
                        <tbody *ngIf="gettingData== false">
                            <tr *ngIf="carwaydataList.length == 0">
                                <td colspan="6" class="nodata">Empty</td>
                            </tr>
                            <tr *ngFor="let item of carwaydataList">
                                <td class="td-data" style="cursor: pointer;">
                                    <span [title]="item.userid">{{item.userid}}</span>
                                </td>
                                <td class="td-data" style="cursor: pointer;">
                                    <span [title]="item.username">{{item.username}}</span>
                                </td>
                                <td class="td-data" style="cursor: pointer;">
                                    <span [title]="item.pickuppoint">{{item.pickuppoint}}</span>
                                </td>
                                <td class="td-data" style="cursor: pointer;">
                                    <span [title]="item.pickuptime">{{item.pickuptime}}</span>
                                </td>
                                <td class="td-data" style="cursor: pointer;">
                                    <span [title]="item.dropoffpoint">{{item.dropoffpoint}}</span>
                                </td>
                                <td class="td-data" style="cursor: pointer;">
                                    <span [title]="item.dropofftime">{{item.dropofftime}}</span>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="carwayForm.carwaytype == '001'" class="table-responsive" matSort
                    (matSortChange)="sortData($event)">
                    <table class="table table-responsive table-borderless">
                        <thead class="table-header">
                            <tr>
                                <td scope="col" class="col-lg-3 col-md-2" mat-sort-header="userid">User ID
                                </td>
                                <td scope="col" class="col-lg-3 col-md-2" mat-sort-header="userid">User Name
                                </td>
                                <td scope="col" class="col-lg-4 col-md-3" mat-sort-header="pickuppoint">Pickup Point
                                </td>
                                <td scope="col" class="col-lg-4 col-md-3" mat-sort-header="pickuptime">Pickup Time
                                </td>

                            </tr>
                        </thead>
                        <tbody *ngIf="gettingData== false">
                            <tr *ngIf="carwaydataList.length == 0">
                                <td colspan="4" class="nodata">Empty</td>
                            </tr>
                            <tr *ngFor="let item of carwaydataList">
                                <td class="td-data" style="cursor: pointer;">
                                    <span [title]="item.userid">{{item.userid}}</span>
                                </td>
                                <td class="td-data" style="cursor: pointer;">
                                    <span [title]="item.username">{{item.username}}</span>
                                </td>
                                <td class="td-data" style="cursor: pointer;">
                                    <span [title]="item.pickuppoint">{{item.pickuppoint}}</span>
                                </td>
                                <td class="td-data" style="cursor: pointer;">
                                    <span [title]="item.pickuptime">{{item.pickuptime}}</span>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>









    </div>