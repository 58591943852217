import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-social-posts-images',
  templateUrl: './social-posts-images.component.html',
  styleUrls: ['./social-posts-images.component.scss']
})
export class SocialPostsImagesComponent implements OnInit {

  @Input() images: any[]= [];
  constructor() { }

  ngOnInit(): void {
  }

}
