<div class="wrapper" *ngIf="loading">
  <ul class="list-group">
    <li class="list-group-item" *ngFor="let i of [1,2,3,4,5]">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
          'height.px': 10,'width.px' : 100}"></ngx-skeleton-loader>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
            'height.px': 10,'width.px' : 150}"></ngx-skeleton-loader>
        </div>
      </div>
    </li>
  </ul>
</div>
<ng-container *ngIf="!loading">
  <div class="wrapper" *ngIf="msg.type == 'checkin'">
    <div class="d-flex justify-content-between align-items-center" mat-dialog-title>
      <div class="d-flex align-items-baseline">
        <i class="fa fa-circle me-2" aria-hidden="true" style="color: red;" *ngIf="msg.message=='Time Out'"></i>
        <i class="fa fa-circle me-2" aria-hidden="true" style="color: green;" *ngIf="msg.message=='Time In'"></i>
        <i class="fa fa-circle me-2" aria-hidden="true" style="color: grey;" *ngIf="msg.message=='Check In'"></i>
        {{msg.username ? msg.username : msg.userid}}'s {{msg.message}}
      </div>
      <div>
        <mat-icon (click)="dismiss()">close</mat-icon>
      </div>
    </div>
    <ul class="list-group">
      <li class="list-group-item">
        <div class="row">
          <div class="row col-6">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              Location
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
              <a [href]="locationUrl" target="_blank">
                <span class="text-decoration-underline" *ngIf="msg.location">@{{msg.location}}</span>
                <span class="text-decoration-underline" *ngIf="!msg.location">@{{msg.lat}},{{msg.long}}</span>
              </a>
            </div>
          </div>
          <div class="row col-6">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              Organization
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
              <span [ngClass]="{'badge' : checkin.orgname}">
                {{checkin.orgname ? checkin.orgname : "-"}}
              </span>
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="row col-6">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              Date
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
              {{allinoneService.formatDBToShow(checkin.date)}}
            </div>
          </div>
          <div class="row col-6">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              Time
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
              {{checkin.starttime}}
            </div>
          </div>
        </div>
      </li>
      <!-- <li class="list-group-item">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            Date
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
            {{allinoneService.formatDBToShow(checkin.date)}}
          </div>
        </div>
      </li> -->
      <li class="list-group-item">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            Description
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
            {{checkin.description || '-'}}
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="wrapper" *ngIf="msg.type == 'activity'">
    <div class="d-flex align-items-center justify-content-between" mat-dialog-title>
      <div>
        {{msg.username ? msg.username : msg.userid}}'s {{checkin.typename}}
      </div>
      <div>
        <div>
          <mat-icon (click)="dismiss()">close</mat-icon>
        </div>
      </div>
    </div>
    <ul class="list-group">

      <li class="list-group-item">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            {{(checkin.enddate && checkin.date != checkin.enddate) ? 'Start Date' : 'Date'}}
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
            {{allinoneService.formatDBToShow(checkin.date)}}
          </div>
        </div>
      </li>
      <li class="list-group-item" *ngIf="checkin.enddate && checkin.date != checkin.enddate">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            End Date
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
            {{allinoneService.formatDBToShow(checkin.enddate)}}
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="row col-6">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              Start Time
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
              {{checkin.starttime | slice:0:5}}&nbsp;{{checkin.starttime | slice:-2}}
            </div>
          </div>
          <div class="row col-6">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              End Time
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
              {{checkin.endtime | slice:0:5}}&nbsp;{{checkin.endtime | slice:-2}}
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item" *ngIf="checkin.orgid">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            Organization
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
            <span class="badge ">
              {{checkin.orgname}}
            </span>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            Description
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
            {{checkin.description || '-'}}
          </div>
        </div>
      </li>
      <li class="list-group-item" *ngIf="checkin.ticketno?.length > 0">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            Tickets
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text py-1 d-flex">
            <div *ngFor="let ticket of checkin.ticketno" class="me-2 priority" (click)="openTicketDetails(ticket)">
              {{ticket}}
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="wrapper" *ngIf="msg.type == 'eventqr'">
    <div class="d-flex align-items-center justify-content-between" mat-dialog-title>
      <div>
        Event Details
      </div>
      <div>
        <mat-icon (click)="dismiss()">close</mat-icon>
      </div>
    </div>
    <ul class="list-group">
      <li class="list-group-item">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            Name
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
            {{msg.eventname}}
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            Location
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
            <a [href]="locationUrl" target="_blank">
              <span class="text-decoration-underline" *ngIf="msg.eventlocation">@{{msg.eventlocation}}</span>
              <span class="text-decoration-underline"
                *ngIf="!msg.eventlocation">@{{msg.eventlat}},{{msg.eventlong}}</span>
            </a>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="row col-6">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              Start Time
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
              {{msg.eventstartdate ? (msg.eventstartdate | date : 'dd/MM/yy, hh:mm a') : '-'}}
            </div>
          </div>
          <div class="row col-6">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              End Time
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
              {{msg.eventenddate ? (msg.eventenddate | date : 'dd/MM/yy, hh:mm a') : '-'}}
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            Description
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
            {{msg.eventdescription ? msg.eventdescription : '-'}}
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-container>