import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-anniversary',
  templateUrl: './office-anniversary.component.html',
  styleUrls: ['./office-anniversary.component.scss']
})
export class OfficeAnniversaryComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
      this.isTabletView = false;
    } else if (
      window.innerWidth > this.mobileViewWidth &&
      window.innerWidth < this.tabletViewWidth
    ) {
      this.isMobileView = false;
      this.isTabletView = true;
    } else {
      this.isMobileView = false;
      this.isTabletView = false;
    }
  }

  mobileViewWidth: number = 490;
  tabletViewWidth: number = 992;
  itemPerPage: number = 10;
  currentPage: number = 1;

  isAdmin: boolean = false;
  isMobileView : boolean = false;
  isTabletView: boolean = false;
  isFocus: boolean = false;
  userListLoading: boolean = false;

  userList: any[] = [];
  organizations: any[]= [];

  searchText: string = '';
  orgid: string = '';

  month: string = '';
  monthName : string = '';
  day: string = '';

  maxDay: number = 31;

  monthList = [
    {
      "name" : "January",
      "value" : "01"
    },
    {
      "name" : "February",
      "value" : "02"
    },
    {
      "name" : "March",
      "value" : "03"
    },
    {
      "name" : "April",
      "value" : "04"
    },
    {
      "name" : "May",
      "value" : "05"
    },
    {
      "name" : "June",
      "value" : "06"
    },
    {
      "name" : "July",
      "value" : "07"
    },
    {
      "name" : "August",
      "value" : "08"
    },
    {
      "name" : "September",
      "value" : "09"
    },
    {
      "name" : "October",
      "value" : "10"
    },
    {
      "name" : "November",
      "value" : "11"
    },
    {
      "name" : "December",
      "value" : "12"
    },
  ]

  dayList: any = [];

  subscriptions = new SubSink();

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private filter: FilterPipe
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
    for(let i = 1; i <= this.maxDay; i++){
      this.dayList.push(
        i
      )
    }
  }
  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;
    
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.month = '0' + (new Date().getMonth() + 1).toString().slice(-2);
    this.day = new Date().getDate().toString();
    console.log(this.month);
    // this.organizations = this.allinoneService.orgs;
    // this.orgid = this.allinoneService.selectedOrg
    //   ? this.allinoneService.selectedOrg
    //   : this.organizations[0].orgid;
    for(var i = 0; i < this.monthList.length; i++) {
      if(this.month == this.monthList[i].value) {
        this.monthName = this.monthList[i].name;
      }
    }

    if (this.isAdmin) {
      // this.organizations = this.allinoneService.orgs;
      // this.orgid = this.allinoneService.selectedOrg
      //   ? this.allinoneService.selectedOrg
      //   : this.organizations[0].orgid;
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getUserList();
  }

  getUserList(){
    this.userListLoading = true;
    var searchdate = this.month;
    if(this.day.length == 1){
      searchdate = this.month + '0' + this.day.toString();
    }
    else{
      searchdate = this.month + this.day.toString();
    }
    const data = {
      "orgid": this.orgid,
      "searchdate": searchdate,
      // "searchdate": '1207'
    }
    this.subscriptions.sink = this.kunyekService.getAnniversaryMember(data).subscribe((res: any) => {
      if(res.returncode == '300'){
        this.userList = res.list;
        this.pg[3].count = this.userList.length;
        this.userListLoading = false;

      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
        this.userListLoading = false;
      }
    }, err => {
      this.userListLoading = false;
    })
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

  clear(){
    this.searchText = '';
  }

  refresh() {
    this.currentPage = 1;
    this.getUserList();
  }

  sortData(sort: Sort) {
    const data = this.userList;
    if (!sort.active || sort.direction === '') {
      this.userList = data;
      return;
    }
    this.userList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(a.employeeid, b.employeeid, isAsc);
        case 'post':
          return this.allinoneService.compare(a.type, b.type, isAsc);
        case 'department':
          return this.allinoneService.compare(a.department, b.department, isAsc);
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        case 'year':
          return this.allinoneService.compare(a.year, b.year, isAsc);
        default:
          return 0;
      }
    });
  }

  export(){
    var dataToExport = this.filter.transform(
      this.userList,
      this.searchText,
      'LeaveSummaryReport'
    );
    var data: any = [];
    var name = 'Anniversary.xlsx';
    dataToExport.map((user: any) => {
      data.push({
        'Name': user.username,
        'Employee ID': user.employeeid,
        'Post': user.type,
        'Department': user.department,
        'Division': user.division,
        'Year': user.year,
      });
    });

    this.allinoneService.exportEcecl(data, name);
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  monthChange(month : any){
    this.month = month.value;
    this.monthName = month.name;
    if(this.month == '02'){
      this.maxDay = 29;
      this.dayList = [];
      for(let i = 1; i <= this.maxDay; i++){
        this.dayList.push(
          i
        )
      }
    }
    else if(this.month == '04' || this.month == '06' || this.month == '09' || this.month == '11'){
      this.maxDay = 30;
      this.dayList = [];
      for(let i = 1; i <= this.maxDay; i++){
        this.dayList.push(
          i
        )
      }
    }
    else{
      this.maxDay = 31;
      this.dayList = [];
      for(let i = 1; i <= this.maxDay; i++){
        this.dayList.push(
          i
        )
      }
    }
    if(parseInt(this.day) > this.maxDay){
      this.day = this.maxDay.toString();
    }
    this.refresh();
  }

  dayChange(day:any) {
    this.day = day;
    this.refresh();
  }

  // monthChange(){
  //   if(this.month == '02'){
  //     this.maxDay = 29;
  //     this.dayList = [];
  //     for(let i = 1; i <= this.maxDay; i++){
  //       this.dayList.push(
  //         i
  //       )
  //     }
  //   }
  //   else if(this.month == '04' || this.month == '06' || this.month == '09' || this.month == '11'){
  //     this.maxDay = 30;
  //     this.dayList = [];
  //     for(let i = 1; i <= this.maxDay; i++){
  //       this.dayList.push(
  //         i
  //       )
  //     }
  //   }
  //   else{
  //     this.maxDay = 31;
  //     this.dayList = [];
  //     for(let i = 1; i <= this.maxDay; i++){
  //       this.dayList.push(
  //         i
  //       )
  //     }
  //   }
  //   if(parseInt(this.day) > this.maxDay){
  //     this.day = this.maxDay.toString();
  //   }
  //   this.refresh();
  // }

}
