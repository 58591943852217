import { Component, OnInit } from '@angular/core';
import { AllInOneService } from '../shared/services/all-in-one.service';

@Component({
  selector: 'app-rms-privacy',
  templateUrl: './rms-privacy.component.html',
  styleUrls: ['./rms-privacy.component.scss'],
})
export class RmsPrivacyComponent implements OnInit {
  constructor(private allinoneService: AllInOneService) {
    this.allinoneService.navbar = false;
  }
  ngOnInit(): void {}

  ngOnDestroy() {
    this.allinoneService.navbar = true;
  }
}
