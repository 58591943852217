<div class="container-fluid">
  <div class="content">
    <!-- <app-hcm-breadcrumb *ngIf="!isDetailView" s_breadcrumb="Recruitment" t_breadcrumb="Requisitions" (emit)="goToRecruitment()">
        </app-hcm-breadcrumb>
        <app-hcm-breadcrumb *ngIf="isDetailView" s_breadcrumb="Recruitment" t_breadcrumb="Requisitions" [f_breadcrumb]="'Ref No: ' + selectedRequest.refno" (emit)="goToRecruitment()" (emit1)="cancel()"></app-hcm-breadcrumb> -->
    <app-team-breadcrumb *ngIf="!isDetailView" s_breadcrumb="Recruitment" t_breadcrumb="Requisitions"
      (emit)="goToRecruitment()">
    </app-team-breadcrumb>
    <app-team-breadcrumb *ngIf="isDetailView" s_breadcrumb="Recruitment" t_breadcrumb="Requisitions"
      [f_breadcrumb]="'Ref No: ' + selectedRequest.refno" (emit)="goToRecruitment()"
      (emit1)="cancel()"></app-team-breadcrumb>

    <ng-container *ngIf="isDetailView; else listView">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom mb-3" (click)="cancel()" *ngIf="!isApproving && !isRejecting">
          Cancel
        </button>
        <!-- <app-button-loader *ngIf="selectedRequest.hrstatus == '001' && !isApproving && !isRejecting" class="mb-3" (click)="submit()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="saveLoading">
              </app-button-loader> -->
        <!-- <ng-container *ngIf="selectedRequest.hrstatus == '002'">
            <div class="mb-3" *ngIf="!isApproving && !isRejecting">
              <div class="line"></div>
            </div>
            <app-button-loader class="mb-3" (click)="rejectReason()" [button_text]="'Reject'"
              [load_button_text]="'Rejecting'" [isLoading]="isRejecting" [outlinedanger]="true">
            </app-button-loader>
          </ng-container> -->
        <ng-container *ngIf="selectedRequest.hrstatus == '002'">
          <div class="mb-3" *ngIf="!isApproving && !isRejecting">
            <div class="line"></div>
          </div>
          <app-button-loader class="mb-3 me-2" (click)="submitApproval('004')" [button_text]="'Complete'"
            [load_button_text]="'Submitting'" [isLoading]="completeLoading">
          </app-button-loader>
        </ng-container>

        <ng-container *ngIf="selectedRequest.hrstatus == '003'">
          <div class="mb-3" *ngIf="!isApproving && !isRejecting">
            <div class="line"></div>
          </div>
          <!-- <app-button-loader class="mb-3" (click)="submitApproval('002')" [button_text]="'Approve'"
              [load_button_text]="'Approving'" [isLoading]="isApproving" *ngIf="!isRejecting">
            </app-button-loader> -->
          <app-button-loader class="mb-3 me-2" (click)="submitApproval('002')" [button_text]="'Process'"
            [load_button_text]="'Processing'" [isLoading]="isApproving" *ngIf="!isRejecting">
          </app-button-loader>
        </ng-container>
        <ng-container *ngIf="selectedRequest.hrstatus == '001'">
          <div class="mb-3" *ngIf="!isApproving && !isRejecting">
            <div class="line"></div>
          </div>
          <app-button-loader class="mb-3 me-2" (click)="rejectReason()" [button_text]="'Reject'"
            [load_button_text]="'Rejecting'" [isLoading]="isRejecting" [outlinedanger]="true" *ngIf="!isApproving">
          </app-button-loader>
          <app-button-loader class="mb-3 me-2" (click)="submitApproval('002')" [button_text]="'Process'"
            [load_button_text]="'Processing'" [isLoading]="isApproving" *ngIf="!isRejecting">
          </app-button-loader>

        </ng-container>
        <!-- <ng-container *ngIf="selectedRequest.status == '002'">
            <div class="mb-3" *ngIf="!isApproving && !isRejecting">
              <div class="line"></div>
            </div>
            <app-button-loader class="mb-3" (click)="submitApproval('003')" [button_text]="'Reject'"
              [load_button_text]="'Rejecting'" [isLoading]="isRejecting" [outlinedanger]="true">
            </app-button-loader>
          </ng-container> -->
        <!-- <ng-container *ngIf="selectedRequest.status == '003'">
            <div class="mb-3" *ngIf="!isApproving && !isRejecting">
              <div class="line"></div>
            </div>
            <app-button-loader class="mb-3" (click)="submitApproval('002')" [button_text]="'Approve'"
              [load_button_text]="'Approving'" [isLoading]="isApproving" *ngIf="!isRejecting">
            </app-button-loader>
          </ng-container> -->
        <!-- <ng-container *ngIf="selectedRequest.status == '001'">
            <div class="mb-3" *ngIf="!isApproving && !isRejecting">
              <div class="line"></div>
            </div>
            <app-button-loader class="mb-3 me-3" (click)="submitApproval('002')" [button_text]="'Approve'"
              [load_button_text]="'Approving'" [isLoading]="isApproving" *ngIf="!isRejecting">
            </app-button-loader>
            <app-button-loader class="mb-3" (click)="submitApproval('003')" [button_text]="'Reject'"
              [load_button_text]="'Rejecting'" [isLoading]="isRejecting" [outlinedanger]="true" *ngIf="!isApproving">
            </app-button-loader>
          </ng-container> -->
        <!-- <div class="d-flex align-items-center ms-auto mb-3"
            *ngIf=" selectedRequest.status != '001' || (selectedRequest.status == '001')">
            <span [title]="selectedRequest.statusname" class="status" [ngClass]="{
                        'status-green': selectedRequest.status == '002',
                        'status-red': selectedRequest.status == '003',
                        'status-gray': selectedRequest.status == '001',
                        'status-main': selectedRequest.status == '004'}">
              <span>
                {{ selectedRequest.statusname + ' : ' + selectedRequest.approvedbyname || selectedRequest.approvedby }}
              </span>
            </span>
          </div> -->
      </div>
      <div>
        <div class="card card-body my-card mb-3">
          <div class="row">
            <div class="mb-3 col-md-6">
              <label for="userid" class="form-label">Ref No: </label>
              <div class="input-group">
                <input type="text" class="form-control" id="userid" name="userid" placeholder="Ref No"
                  [value]="selectedRequest.refno" disabled="true" />
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <label for="name" class="form-label"> Requisition Date </label>
              <input type="text" class="form-control" id="date" name="date" placeholder="Requisition Date"
                [value]="allinoneService.formatDBToShow(selectedRequest.requisitiondate)" disabled="true" />
            </div>
            <!-- <div class="mb-3 col-md-12">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="formpriority" id="critical" value="00900"
                  [checked]="selectedRequest.priority =='00900' " disabled/>
                  <label class="form-check-label" for="critical"> High </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input inline" type="radio" name="formpriority" id="high" value="00500"
                  [checked]="selectedRequest.priority =='00500' " disabled/>
                  <label class="form-check-label" for="high">
                    Medium
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input inline" type="radio" name="formpriority" id="low" value="0010"
                  [checked]="selectedRequest.priority =='0010' " disabled/>
                  <label class="form-check-label" for="low">
                    Low
                  </label>
                </div>
              </div> -->

            <div class="mb-3 col-md-12">
              <label for="urgency" class="form-label">Urgency</label>
              <select class="form-select" name="urgency" id="urgency" [(ngModel)]="selectedRequest.priority" disabled>
                <option value="00900">High</option>
                <option value="00500">Medium</option>
                <option value="0010">Low</option>
                <!-- <option *ngFor="let shift of shiftList" [value]="shift.shiftid">
                    {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
                  </option> -->
              </select>
            </div>

            <!-- <div class="mb-3 col-md-6">
                <label for="product" class="form-label"> Product </label>
                <input type="text" class="form-control" id="product" name="product"
                  [value]="selectedRequest.productname" disabled="true" />
              </div> -->

            <div class="mb-3 col-md-6">
              <label for="department" class="form-label"> Department </label>
              <input type="text" class="form-control" id="department" name="department"
                [value]="selectedRequest.department" disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
              <label for="division" class="form-label"> Division </label>
              <input type="text" class="form-control" id="division" name="division" [value]="selectedRequest.division"
                disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
              <label for="project" class="form-label"> Project </label>
              <input type="text" class="form-control" id="project" name="project" [value]="selectedRequest.projectname"
                disabled="true" />
            </div>
            <div class="mb-3 col-md-6">
              <label for="targetdate" class="form-label"> Recruitment Date </label>
              <input type="text" class="form-control" id="targetdate" name="targetdate"
                [value]="allinoneService.formatDBToShow(selectedRequest.targetdate)" disabled="true" />
            </div>

            <div class="mb-3 col-md-12">
              <label for="requestreason" class="form-label"> Reason </label>
              <input type="text" class="form-control" id="requestreason" name="requestreason"
                [value]="selectedRequest.requestreasonname" disabled="true" />
            </div>

            <div class="mb-3 col-md-12">
              <label for="remark" class="form-label"> Remark </label>
              <textarea class="form-control" id="remark" [value]="selectedRequest.remark" name="remark" rows="3"
                placeholder="Remark" disabled="true"></textarea>
            </div>

          </div>

        </div>
        <!-- <div class="row"> -->
        <!-- <div *ngFor="let pos of selectedRequest.positions; let index = index">
            <div class="card card-body my-card mb-3">
              <div class="info mb-3">
                Position {{index+1}}
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label class="form-label" [for]="pos.positionid + 'no'">No. of People</label>
                  <input type="text" class="form-control" [id]="pos.positionid + 'no'" [name]="pos.positionid + 'no'"
                    placeholder="No. of People" [value]="pos.people" disabled />
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label" [for]="pos.positionid + 'name'">Position</label>
                  <input type="text" class="form-control" [id]="pos.positionid + 'name'" [name]="pos.positionid + 'name'"
                    placeholder="Position Name" [value]="pos.name" disabled />
                </div>
                
                <div class="col-md-6 mb-3">
                  <label for="requirements" class="form-label"> Job Requirements </label>
                  <textarea class="form-control" id="requirements" [value]="pos.requirements" name="requirements" rows="5"
                    placeholder="Job Requirements" disabled="true"></textarea>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="desc" class="form-label"> Job Description </label>
                  <textarea class="form-control" id="desc" [value]="pos.descriptions" name="desc" rows="5"
                    placeholder="Job Description" disabled="true"></textarea>
                </div>
              </div>
            </div>
          </div> -->
        <div class="card card-body my-card mb-3">
          <div class="info mb-3">
            Position
          </div>
          <div class="row">
            <!-- <div class="col-md-6 mb-3">
                <label class="form-label" for="people">No. of People</label>
                <input type="text" class="form-control" id="people" name="people"
                [value]="selectedRequest.people" disabled />
              </div> -->
            <div class="col-md-6 mb-3">
              <label *ngIf="selectedRequest.hrstatus != '004'" class="form-label" for="people">No of Opening</label>
              <label *ngIf="selectedRequest.hrstatus == '004'" class="form-label can-click"
                (click)="openMemberInfo(selectedRequest)" for="people">No of Opening</label>
              <input type="number" class="form-control" id="people" name="people" #people="ngModel"
                onclick="openMemberInfo(selectedRequest)" [(ngModel)]="submitForm.people" min="1"
                [disabled]="selectedRequest.hrstatus != '001'"
                [ngClass]="{'is-invalid' : formSubmitted && people.invalid}" required />
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label" for="position">Position</label>
              <input type="text" class="form-control" id="position" name="position"
                [value]="selectedRequest.positionname" disabled />
            </div>
            <div class="col-md-6 mb-3">
              <label for="requirements" class="form-label"> Job Requirements </label>
              <textarea class="form-control" id="requirements" [value]="selectedRequest.requirements"
                name="requirements" rows="5" disabled="true"></textarea>
            </div>
            <div class="col-md-6 mb-3">
              <label for="desc" class="form-label"> Job Description </label>
              <textarea class="form-control" id="desc" [value]="selectedRequest.descriptions" name="desc" rows="5"
                disabled="true"></textarea>
            </div>
            <!-- <div class="mb-3 col-md-12">
                <label for="remark" class="form-label"> Remark </label>
                <textarea class="form-control" id="remark" [value]="selectedRequest.remark" name="remark" rows="3"
                  placeholder="Remark" disabled="true"></textarea>
              </div> -->
          </div>
        </div>
        <div *ngIf="selectedRequest.hrstatus == '002' || selectedRequest.hrstatus == '004'"
          class="card card-body my-card mb-3">
          <div class="row">
            <div class="col-md-12" #commentBox>
              <label for="hrremark" class="form-label"> Comment </label>
              <!-- <textarea class="form-control" id="descriptions" name="descriptions" #description="ngModel"
              [(ngModel)]="submitForm.descriptions" rows="5" [disabled]="isEditing && submitForm.readonly"
              [ngClass]="{'is-invalid' : formSubmitted && description.invalid}" required></textarea> -->
              <textarea class="form-control" id="hrremark" [value]="submitForm.hrremark" name="hrremark"
                #hrremark="ngModel" [(ngModel)]="submitForm.hrremark" rows="3" placeholder="Comment"
                [disabled]="selectedRequest.hrstatus == '004'"
                [ngClass]="{'is-invalid' : formSubmitted && hrremark.invalid}" required></textarea>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </ng-container>
    <ng-template #listView>
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search By Ref No/Positions"
            aria-label="Search By Ref No/Positions" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>

        <div class="ms-2">
          <button class="btn btn-custom" (click)="filter()" title="Filter">
            <i class="fa fa-filter"></i>
          </button>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="" [matMenuTriggerFor]="statusmenu">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Status&nbsp;:&nbsp;{{ submitForm.requeststatusname }}
            <mat-menu #statusmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let status of statusList" (click)="statusChange(status)">
                {{ status.name }}
              </button>
            </mat-menu>
          </button>
        </div>

        <div class="line"></div>
        <button type="button" class="btn btn-custom" (click)="export()" title="Export To Excel"
          [disabled]="approvalList.length == 0">
          <img class="excel-icon" src="../../../assets/images/excel.png" />
          Export
        </button>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ (approvalList | filter : searchText: 'requisitionapproval' ).length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search By Ref No/Positions" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="approvalList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div class="d-flex flex-row flex-wrap" *ngIf="isFiltering">
        <div class="me-2 btn-group mb-3 d-flex align-items-center" role="group">
          <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
            Urgency: {{ searchPriorityName}}
          </button>
          <button (click)="clearFilter()" type="button" class="btn btn-secondary advanced-filter-btn">
            <img src="assets/images/icon.png" alt="" style="width: 11px; height: 11px; margin-top: -3px" />
          </button>
        </div>
        <div class="me-2 btn-group mb-3 d-flex align-items-center" role="group">
          <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
            Position: {{ searchPositionName}}
          </button>
          <button (click)="clearFilter()" type="button" class="btn btn-secondary advanced-filter-btn">
            <img src="assets/images/icon.png" alt="" style="width: 11px; height: 11px; margin-top: -3px" />
          </button>
        </div>
        <div class="me-2 btn-group mb-3 d-flex align-items-center" role="group">
          <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
            Department: {{ searchForm.department ? searchForm.department : 'All'}}
          </button>
          <button (click)="clearFilter()" type="button" class="btn btn-secondary advanced-filter-btn">
            <img src="assets/images/icon.png" alt="" style="width: 11px; height: 11px; margin-top: -3px" />
          </button>
        </div>
        <div class="me-2 btn-group mb-3 d-flex align-items-center" role="group"
          *ngIf="searchForm.startdate && searchForm.enddate">
          <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
            Requisition Date: {{ allinoneService.formatDBToShow(searchForm.startdate) + ' - ' +
            allinoneService.formatDBToShow(searchForm.enddate) }}
          </button>
          <button (click)="clearFilter()" type="button" class="btn btn-secondary advanced-filter-btn">
            <img src="assets/images/icon.png" alt="" style="width: 11px; height: 11px; margin-top: -3px" />
          </button>
        </div>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-2" mat-sort-header="refno">Ref No.</td>
              <td scope="col" class="col-md-2" mat-sort-header="positions"> Position </td>
              <td scope="col" class="col-md-3" mat-sort-header="people">No of Opening</td>
              <td scope="col" class="col-md-3" mat-sort-header="priority">Priority</td>
              <td scope="col" class="col-md-2" mat-sort-header="requisitiondate">Requisition Date</td>
              <td scope="col" class="col-md-2" mat-sort-header="targetdate">Recruitment Date</td>
              <td scope="col" class="col-md-2" mat-sort-header="hrstatus">Status</td>
              <td scope="col" class="col-md-2" mat-sort-header="requestedby">Requested By</td>
              <td scope="col" class="col-md-2" mat-sort-header="approved">Approved By</td>
              <td
                *ngIf="this.submitForm.requeststatus == '004' || this.submitForm.requeststatus == '002' || this.submitForm.requeststatus == '' "
                scope="col" class="col-md-2" mat-sort-header="comstatusChangepleteby">Completed By</td>
              <td scope="col" class="col-md-2" mat-sort-header="remark">Remark</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingApprovals">
            <tr>
              <td colspan="11" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingApprovals">
            <tr *ngIf="approvalList.length == 0">
              <td colspan="11" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let data of approvalList| filter : searchText: 'requisitionapproval' | paginate : { itemsPerPage: itemPerPage , currentPage : currentPage };let index = index">
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.refno">{{ data.refno }}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.positionname">{{ data.positionname }}</span>
              </td>
              <td class="td-data" [ngClass]="data.hrstatus == '004' ? 'can-click' : '' ">
                <span *ngIf="data.hrstatus == '004'" (click)="openMemberInfo(data)" [title]="data.people">{{ data.people
                  }}</span>
                <span *ngIf="data.hrstatus != '004'" [title]="data.people">{{ data.people }}</span>
              </td>
              <td class="td-data" (click)="viewApproval(data)">
                <span [title]="data.priority" class="priority"
                  [ngClass]="{'priorityprimary': data.priority == '00500' ,'priorityred':data.priority == '00900' }">{{
                  data.priority == '0010' ? 'Low' : data.priority == '00500' ? 'Medium' :
                  'High'}}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="allinoneService.formatDBToShow(data.requisitiondate)">{{
                  allinoneService.formatDBToShow(data.requisitiondate) }}</span>
              </td>
              <td class="td-data" (click)="viewApproval(data)">
                <span [title]="allinoneService.formatDBToShow(data.targetdate)">{{
                  allinoneService.formatDBToShow(data.targetdate)
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.statusname" class="status" [ngClass]="{
                        'status-green': data.hrstatus == '002',
                        'status-red': data.hrstatus == '003',
                        'status-gray': data.hrstatus == '001',
                        'status-main': data.hrstatus == '004'}">
                  {{ data.statusname }}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.createdbyname || data.createdby">{{ data.createdbyname || data.createdby }}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.approvedbyname || data.approvedby">{{ data.approvedbyname || data.approvedby
                  }}</span>
              </td>
              <td
                *ngIf="this.submitForm.requeststatus == '004' || this.submitForm.requeststatus == '002' || this.submitForm.requeststatus == '' "
                class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.completedbyname || data.completedby">{{ data.completedbyname || data.completedby ||
                  '-' }}</span>
              </td>
              <td class="td-data td-edit" (click)="viewApproval(data)">
                <span [title]="data.remark">{{ data.remark }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event"
        *ngIf="approvalList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-template>
  </div>
</div>