import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-member',
  templateUrl: './select-member.component.html',
  styleUrls: ['./select-member.component.scss']
})
export class SelectMemberComponent implements OnInit {
  memberSearch = '';
  memberList: any = [];
  selectedMemberList: any = [];
  gettingMemberList: boolean = true;
  feetypeName: string = '';
  feetypeTitle: string = '';
  subscription!: Subscription;
  private dialogData: any;

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<SelectMemberComponent>,
    private injector: Injector,
    private router: Router,
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.feetypeName = this.dialogData.feetypeName;
  }

  ngOnInit(): void {
    this.homesearchMember();
  }

  searchChange() {
    this.memberList.filter = this.memberSearch.trim().toLowerCase();
  }


  homesearchMember() {
    this.memberList = [];
    const data = {
      searchName: this.memberSearch
    };

    this.gettingMemberList = true;

    let serviceMethod;
    switch (this.feetypeName) {
      case "Member Monthly Fee":
        this.feetypeTitle = "Monthly Member";
        const amember_data = {
          formstatus: '002',
          searchName: this.memberSearch
        }
        serviceMethod = this.kunyekService.getKMembers(amember_data);
        break;
      case "Summer Swim Fee":
        this.feetypeTitle = "Summer Swimmer";
        serviceMethod = this.kunyekService.getSummerSwimmerList(data);
        break;
      case "Special Swim Fee":
        this.feetypeTitle = "Special Swimmer";
        serviceMethod = this.kunyekService.getSpecialSwimmerList(data);
        break;
      case "Guest Swim Fee":
        this.feetypeTitle = "Guest Swimmer";
        serviceMethod = this.kunyekService.guestMember().get(data);
        break;
      case "Gym Member Fee":
        this.feetypeTitle = "Gym Member";
        serviceMethod = this.kunyekService.gymMember().get(data);
        break;
      default:
        this.messageService.openSnackBar("Invalid Fee Type", 'warn');
        this.gettingMemberList = false;
        return;
    }

    this.subscription = serviceMethod.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (res.type === "Summer Swimmer") {
            this.memberList = res.datalist.map((summer: any) => ({
              ...summer,
              name: summer.nameinenglish
            }));
          } else {
            this.memberList = res.datalist;
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingMemberList = false;
      },
      (err) => {
        this.messageService.openSnackBar("Error fetching members", 'error');
        this.gettingMemberList = false;
      }
    );
  }

  selectMember(member: any) {
    this.memberList.forEach((m: any) => {
      if (m.syskey !== member.syskey) {
        m.selected = false;
      }
    });

    member.selected = !member.selected;

    this.selectedMemberList = member.selected ? [member] : [];

    const senderInput = document.getElementById('sender') as HTMLInputElement;
    if (senderInput) {
      senderInput.value = member.selected ? member.name : '';
    }

    this.dialogRef.close({ data: this.selectedMemberList });
  }


  close() {
    this.dialogRef.close();
  }

  done() {
    this.dialogRef.close({ data: this.selectedMemberList });
  }

  searchByName() {
    this.homesearchMember();
  }
}
